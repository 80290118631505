/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { styleLibrary } from 'app/constants';

export const chartMargins = {
  bottom: 25,
  right: 25
};

export const tick = {
  fontSize: styleLibrary.fontSizes.smallBody
};
