/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import styled from 'styled-components';

import { styleLibrary } from 'app/constants';
import { colourToRGBA } from 'app/utils';

export const ModalWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${colourToRGBA(styleLibrary.darkBg, 0.8)};
`;

export const ModalBox = styled.div.attrs({
  className: 'd-flex align-items-center flex-column p-4 text-center'
})`
  width: ${props => props.modalWidth || '400px'};
  background-color: ${styleLibrary.lightBg};
`;

export const Spacer = styled.div.attrs({ className: 'py-3' })``;
export const Link = styled.a`
  color: ${styleLibrary.brand.highlight};
`;
