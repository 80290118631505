/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import styled from 'styled-components';
import { TableCell as MuiTableCell } from '@material-ui/core';

export const TableCell = styled(MuiTableCell)`
  position: sticky;
  background-color: #fff;
`;

export const BarPart = styled.div`
  height: 20px;
  width: ${props => props.width}px;
  background-color: ${props => props.color};
`;

export const TotalWrapper = styled.span.attrs({
  className: 'ml-2'
})`
  white-space: nowrap;
`;

export { CustomTableHeader } from './custom-table-header';
export { OnePartBar } from './one-part-bar';
export { TwoPartBar } from './two-part-bar';
export { default as TableExport } from './table-export';
export { default as StatefulTable } from './stateful-table';
export { default as CustomMuiDatatable } from './custom-mui-datatable';
export { SeverityColorBox } from './severity-color-box';
