/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { FaIcon } from 'app/components/icons';
import {
  CustomTableHeader,
  OnePartBar,
  TwoPartBar
} from 'app/components/tables';
import { bandwidthUnits, styleLibrary } from 'app/constants';
import { StatusBodyRenderer } from 'app/modules/inventory/components/table-components';
import {
  HeaderBlockLabel,
  HeaderColorBlock
} from 'app/modules/overview/components/top-clients/components';
import { castToNumber, getScaledDisplayValue, getSortResult } from 'app/utils';
import { cloneDeep, isEmpty } from 'lodash';
import React from 'react';

export const convertToMB = (value, unit) => {
  const conversionFactors = {
    MB: 1,
    GB: 1024,
    TB: 1024 * 1024,
    PB: 1024 * 1024 * 1024
  };
  return value * (conversionFactors[unit] || 1);
};


export const getTopAPsColumns = (maxUsage, showApdetailsMqttOrSyslog) => {
  const { bandwidth, clients } = maxUsage;

  const bandwidthHeader = (column, sortFn) => {
    const isSorted = column.sortDirection !== 'none';
    const dir = isSorted ? column.sortDirection : 'asc';

    return (
      <CustomTableHeader
        key={column.name}
        columnIndex={column.index}
        isSorted={isSorted}
        sortDirection={dir}
        sortFn={sortFn}
      >
        <span className="mr-4">{column.label}</span>
        <HeaderColorBlock color={styleLibrary.downloadColor} />
        <HeaderBlockLabel>Down</HeaderBlockLabel>
        <HeaderColorBlock color={styleLibrary.uploadColor} />
        <HeaderBlockLabel>Up</HeaderBlockLabel>
      </CustomTableHeader>
    );
  };

  const bandwidthColumn = (value, rowMeta) => {
    return (
      <TwoPartBar
        barWidth={250}
        left={{
          value: rowMeta.rowData[8],
          color: styleLibrary.downloadColor,
          indicator: (
            <FaIcon
              icon="angle-double-down"
              style={{ color: styleLibrary.downloadColor }}
            />
          )
        }}
        max={bandwidth}
        right={{
          value: rowMeta.rowData[9],
          color: styleLibrary.uploadColor,
          indicator: (
            <FaIcon
              icon="angle-double-up"
              style={{ color: styleLibrary.uploadColor }}
            />
          )
        }}
        totalFormatter={val => getScaledDisplayValue(val, bandwidthUnits, 0, 1)}
      />
    );
  };

  const clientsColumn = value => (
    <OnePartBar
      barWidth={60}
      display={{
        value: castToNumber(value),
        color: styleLibrary.clientsColor
      }}
      max={clients}
    />
  );

  return [
    { name: 'APName', label: 'AP Name' },
    {
      name: 'status',
      label: 'Status',
      options: { customBodyRender: StatusBodyRenderer }
    },
    { name: 'model', label: 'Model' },
    { name: 'ipAddress', label: 'IP Addr' },
    { name: showApdetailsMqttOrSyslog ? 'apmac' : 'APMAC', label: 'AP MAC' },
    { name: 'switchName', label: 'Switch Name' },
    { name: 'switchPort', label: 'Switch Port' },
    {
      name: 'bandwidthSumInMB',
      label: 'Traffic',
      options: {
        customHeadRender: bandwidthHeader,
        customBodyRender: bandwidthColumn,
        sortDirection: 'desc'
      }
    },
    { name: showApdetailsMqttOrSyslog ? 'bandwidthRXInMB' : 'bandwidthRX', options: { display: 'false' } },
    { name: showApdetailsMqttOrSyslog ? 'bandwidthTXInMB' : 'bandwidthTX', options: { display: 'false' } },
    {
      name: 'clientCount',
      label: 'Clients',
      options: { customBodyRender: clientsColumn }
    }
  ];
};

export const sortAPsTable = (data, col, order) => {
  return data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];

    if (col === 1) {
      valA = a.data[col] === 'Connected' ? 1 : -1;
      valB = b.data[col] === 'Connected' ? 1 : -1;
    }
    // Ensure values are treated as numbers for sorting
    if (col === 7) {
      valA = a.data[8] + a.data[9];
      valB = b.data[8] + b.data[9];
    }
    // Convert to lowercase for string comparison
    if (typeof valA === 'string' && typeof valB === 'string') {
      valA = valA.toLowerCase();
      valB = valB.toLowerCase();
    }
    // Convert to numbers if they are not already
    if (!isNaN(valA)) {
      valA = parseFloat(valA);
    }
    if (!isNaN(valB)) {
      valB = parseFloat(valB);
    }
    return getSortResult(valA, valB, order);
  });
};

export const createPropertyAPDetailsExportDataDefinition = (data, showApdetailsMqttOrSyslog) => {
  const transformedData = cloneDeep(data);
  transformedData.forEach(d => {
    // Conditionally convert bandwidth columns based on showApdetailsMqttOrSyslog flag
    const bandwidthRXKey = showApdetailsMqttOrSyslog ? 'bandwidthRXInMB' : 'bandwidthRX';
    const bandwidthTXKey = showApdetailsMqttOrSyslog ? 'bandwidthTXInMB' : 'bandwidthTX';
    const bandwidthRXinMB = convertToMB(d[bandwidthRXKey], d['bandwidthRXUnit']);
    const bandwidthTXinMB = convertToMB(d[bandwidthTXKey], d['bandwidthTXUnit']);
    d['bandwidthSumInMB'] = bandwidthRXinMB + bandwidthTXinMB;
    d['switchName'] = isEmpty(d['switchName']) ? '' : `'${d['switchName']}'`;
    d['switchPort'] = isEmpty(d['switchPort']) ? '' : `'${d['switchPort']}'`;
  });

  return [
    {
      name: 'deviceType',
      data: transformedData,
      columns: [
        { label: 'AP Name', name: 'APName' },
        { label: 'Status', name: 'status' },
        { label: 'Model', name: 'model' },
        { label: 'IP Addr', name: 'ipAddress' },
        { label: 'AP MAC', name: showApdetailsMqttOrSyslog ? 'apmac' : 'APMAC' },
        { label: 'Switch Name', name: 'switchName' },
        { label: 'Switch Port', name: 'switchPort' },
        {
          label: 'Traffic (MB)',
          name: 'bandwidthSumInMB',
          options: { sortDirection: 'desc' }
        },
        { label: 'Clients', name: 'clientCount' }
      ]
    }
  ];
};
