/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isAjaxTimeoutError } from 'app/custom-errors';
import { createApiBodySelector } from 'app/redux/hierarchy';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';
import { ajaxPost, debounceSaga } from 'app/redux/util-sagas';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import {
  aleSessionsSummarySelector,
  fetchAleSessionsSummaryComplete,
  fetchAleSessionsSummaryError,
  updateAleSessionSummaryFetchState
} from '.';

const activeAleSessionsUrl = '/api/fastapi/getActiveALESessions';

export function* fetchAleSessionsSummarySaga() {
  yield put(updateAleSessionSummaryFetchState(pendingFetchState));
  yield call(waitForHierarchySaga);

  const currentDataSet = yield select(aleSessionsSummarySelector);
  yield call(debounceSaga, currentDataSet);

  const apiBody = yield select(createApiBodySelector(activeAleSessionsUrl));

  const onTimeout = () =>
    put(updateAleSessionSummaryFetchState(timeoutNotifyFetchState));

  try {
    const { data } = yield ajaxPost(activeAleSessionsUrl, apiBody, {
      onTimeout
    });

    yield put(fetchAleSessionsSummaryComplete(data));
    yield put(updateAleSessionSummaryFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchAleSessionsSummaryError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(updateAleSessionSummaryFetchState(updatedFetchState));
  }
}

export default function createFetchAleSessionsSagas(types) {
  return [
    takeLatest(types.fetchAleSessionsSummary, fetchAleSessionsSummarySaga)
  ];
}
