/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isEmpty } from 'lodash';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getScaledValue } from 'app/utils';

const TooltipContainer = styled.div.attrs({
  className: 'col'
})`
  color: ${props => props.color};
`;

const TooltipDataValue = styled.div.attrs({
  className: 'd-inline-block text-right'
})`
  width: auto;
  margin-right: 10px;
  font-weight: bold;
`;

const TooltipDataName = styled.div.attrs({
  className: 'd-inline-block'
})``;

const TooltipBlock = styled.div.attrs({
  className: 'd-inline-block mr-2'
})`
  width: 12px;
  height: 12px;
  background-color: ${props => props.color};
`;

const getDisplayDetails = (item, scale, units) => {
  if (scale) {
    const { result, unit } = getScaledValue(item.value, units);
    if (unit !== '') {
      return {
        displayValue: result,
        displayUnit: `(${unit})`
      };
    } else {
      return {
        displayValue: result,
        displayUnit: ''
      };
    }
  } else {
    return {
      displayValue: item.value && item.value.toLocaleString(),
      displayUnit: ''
    };
  }
};

const TooltipItem = props => {
  const { item, scale, units } = props;
  const { name, stroke } = item;
  const { displayValue, displayUnit } = getDisplayDetails(item, scale, units);

  return (
    <TooltipContainer color={props.textColorOverride || item.stroke}>
      {!isEmpty(item) && (
        <Fragment>
          <TooltipBlock color={stroke} />
          <TooltipDataValue>{displayValue}</TooltipDataValue>
          <TooltipDataName>
            {displayUnit} {name}
          </TooltipDataName>
        </Fragment>
      )}
    </TooltipContainer>
  );
};

TooltipItem.defaultProps = {
  item: {},
  scale: false
};

TooltipItem.propTypes = {
  item: PropTypes.object,
  scale: PropTypes.bool,
  textColorOverride: PropTypes.string,
  units: PropTypes.array
};

export default TooltipItem;
