/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils.js';
import { apiDateFormat } from 'app/constants';
import {
  getAllNetworksFromSummary,
  getAllPropertiesFromSummary
} from 'app/redux/inventory/utils';
import { getSortResult } from 'app/utils';
import { toString } from 'lodash';
import moment from 'moment';

export const getSortedSummaryItems = (inventory, path = {}) => {
  let properties = getAllPropertiesFromSummary(inventory);

  if (isSpecificNodeType(path, 'customer')) {
    const networks = getAllNetworksFromSummary(inventory);
    properties = properties.concat(networks);
  }

  return properties;
};

export const isActiveTab = (selection, tab) => {
  const { levelType, deviceType } = selection;
  const { level, type } = tab;
  return levelType === level && deviceType === type;
};

export const getDetailsOfInventoryItem = ({
  firmwareVersion,
  hostName,
  ipAddress,
  lastRefreshTime,
  level: location,
  macAddress,
  model,
  serialNumber,
  status,
  uptime
}) => [
  hostName,
  model,
  firmwareVersion,
  serialNumber,
  macAddress,
  ipAddress,
  location,
  status,
  uptime,
  lastRefreshTime
];

export const getDetailsOfAPItem = ({
  model,
  apType,
  total,
  refreshTimestamp,
  connected,
  disconnected,
  Active,
  Inactive
}) => [
  toString(model),
  apType,
  total,
  refreshTimestamp,
  connected,
  disconnected,
  Active,
  Inactive
];

export const getFullDetailsOfAPItem = ({
  APName,
  apmac,
  firmwareVersion,
  ipAddress,
  model,
  serialNumber,
  status,
  switchName,
  switchPort,
  uptime,
  zonename
}) => [
  APName,
  model,
  firmwareVersion,
  serialNumber,
  apmac,
  ipAddress,
  switchName,
  switchPort,
  zonename,
  status,
  uptime
];

export const createTabClickHandler = (props, tab) => () => {
  const { updateInventoryFilter } = props;
  const { level: levelType, type: deviceType } = tab;
  return updateInventoryFilter({ levelType, deviceType });
};

export const getSeconds = time =>
  time ? moment.duration(time.replace(/ day(s?), /, '.')).as('seconds') : 0;

export const calculateMaxUptime = (uptimes = []) =>
  uptimes.reduce((max, current) => Math.max(getSeconds(current), max), 0);

export const calculateAPCounts = totals => {
  return totals.map(c => {
    const hasActiveInactive = c.Active > 0 || c.Inactive > 0;
    return {
      connected: hasActiveInactive ? c.Active : c.connected,
      disconnected: hasActiveInactive ? c.Inactive : c.disconnected,
      total: hasActiveInactive ? c.Active + c.Inactive : c.total
    };
  });
};

export const sortNonAPInventoryTable = (data, col, order) => {
  return data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];

    if (col === 4) {
      valA = valA.toString();
      valB = valB.toString();
    } else if (col === 8) {
      valA = getSeconds(valA);
      valB = getSeconds(valB);
    } else if (col === 9) {
      valA = moment(valA, apiDateFormat).valueOf();
      valB = moment(valB, apiDateFormat).valueOf();
    }

    return getSortResult(valA, valB, order);
  });
};

export const sortAPInventoryTable = (data, col, order) => {
  return data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];
    return getSortResult(valA, valB, order);
  });
};

export const sortAPDetailsTable = (data, col, order) => {
  return data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];

    if (col === 4) {
      valA = valA.toString();
      valB = valB.toString();
    } else if (col === 10) {
      valA = getSeconds(valA);
      valB = getSeconds(valB);
    }

    return getSortResult(valA, valB, order);
  });
};
