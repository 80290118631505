/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import {
  getAllOfTypeFromHierarchy,
  getDecimalPlaceParityNumber
} from 'app/utils';
import { fitBounds } from 'google-map-react/utils';
import { findKey, get, has, isEmpty, isEqual, reduce } from 'lodash';

export const calculateMapDefaults = (bounds, { width, height }) => {
  const { minLat, minLng, maxLat, maxLng } = bounds;
  if (isMinMaxLatLngEqual(bounds)) {
    return {
      center: { lat: maxLat, lng: maxLng },
      zoom: 18
    };
  } else {
    const adjustedBounds = {
      ne: { lat: maxLat, lng: maxLng },
      sw: { lat: minLat, lng: minLng }
    };
    const { center, zoom } = fitBounds(adjustedBounds, { width, height });
    return { center, zoom: Math.min(zoom - 1, 17) };
  }
};

const isMinMaxLatLngEqual = ({ minLat, minLng, maxLat, maxLng }) => {
  const latitudes = [minLat, maxLat];
  const longitudes = [minLng, maxLng];
  const latDecimalPlaceParityNum = getDecimalPlaceParityNumber(latitudes);
  const lngDecimalPlaceParityNum = getDecimalPlaceParityNumber(longitudes);

  const [minLatFormatted, maxLatFormatted] = latitudes.map(lat =>
    lat.toFixed(latDecimalPlaceParityNum)
  );
  const [minLngFormatted, maxLngFormatted] = latitudes.map(lng =>
    lng.toFixed(lngDecimalPlaceParityNum)
  );
  return (
    maxLatFormatted === minLatFormatted && maxLngFormatted === minLngFormatted
  );
};

/**
 * This function calculates how to display markers and where to apply clustering based on
 * marker position and map zoom level.
 *
 * @method parseMarkers
 * @param {Array} data The list of markers to be parsed
 * @param {Number} zoom The current map zoom level
 * @param {Object} selected The current selected hierarchy path
 * @param {Object} hierarchy The hierarchy object
 * @returns {Object} An object mapping marker names to an array of markers to be displayed at that position
 */
export const parseMarkers = (data = [], zoom, selected, hierarchy) => {
  let markers = {};
  const latLngDistances = {};
  const unwantedNodeNames = [
    '',
    'NA',
    'N/A',
    'N./A.',
    'N/A.',
    'N./A',
    'N.A.',
    'NA.',
    'N.A'
  ];

  if (!isEmpty(data)) {
    data.forEach(d => {
      // Handle 'NA' node names by showing the 'displayName' instead of 'name'
      if (unwantedNodeNames.includes(d['name'].toUpperCase())) {
        const possibleNodes = getAllOfTypeFromHierarchy(
          d['type'],
          hierarchy,
          selected,
          false
        );
        const hierarchyNodeDisplayName = get(
          possibleNodes.find(n => n.value === d['name']),
          'text',
          ''
        );
        if (!isEmpty(hierarchyNodeDisplayName)) {
          d['displayName'] = hierarchyNodeDisplayName;
        }
      }
    });

    if (isSpecificNodeType(selected, 'zonename') && has(data[0], 'zoneName')) {
      markers = reduce(
        data,
        (r, v) => {
          if (!isEmpty(r)) {
            const matchKey = findKey(
              r,
              m =>
                isEqual(m[0].latitude, v.latitude) &&
                isEqual(m[0].longitude, v.longitude)
            );
            if (matchKey) {
              r[matchKey].push(v);
            } else {
              r[v.name] = [v];
            }
          } else {
            r[v.name] = [v];
          }
          return r;
        },
        {}
      );
    } else {
      data.forEach((item, idx) => {
        // Work out how far each marker is from the other. This will help us later to work out which
        // markers need to be overlaid for sensible displaying
        latLngDistances[item.name] = [];
        data.forEach((compareItem, jdx) => {
          if (
            Object.keys(latLngDistances).includes(compareItem.name) &&
            jdx !== idx
          ) {
            latLngDistances[item.name].push({
              name: compareItem.name,
              lat: Math.abs(item.latitude - compareItem.latitude),
              lng: Math.abs(item.longitude - compareItem.longitude)
            });
          }
        });

        // If this current marker is located within a certain range of another (based on map zoom)
        // combine it with that existing marker
        let markerName = item.name;
        const maxDist = 4 / zoom; // This is a bit arbitrary, but we need smaller distances at higher zoom levels
        const nearbyMarker = latLngDistances[item.name].find(
          d => d.lat < maxDist || d.lng < maxDist
        );

        if (nearbyMarker) {
          markerName = nearbyMarker.name;
        }

        if (markers[markerName]) {
          markers[markerName].push(item);
        } else {
          markers[markerName] = [item];
        }
      });
    }
  }

  return markers;
};
