/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isAjaxTimeoutError } from 'app/custom-errors';
import {
  dateRangeFilterSelector,
  filterParamsSelector
} from 'app/redux/filters';
import {
  createApiBodySelector,
  hierarchySelector,
  selectedPathSelector
} from 'app/redux/hierarchy';
import { ajaxGet, ajaxPost, debounceSaga } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { getGranularityForDateRange, getPropertyParent } from 'app/utils';
import { get, merge } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchRogueMACCountComplete,
  fetchRogueMACCountError,
  fetchRogueTrendComplete,
  fetchRogueTrendError,
  rogueMACCountSelector,
  rogueTrendSelector,
  updateRogueMACCountFetchState,
  updateRogueTrendFetchState
} from './';

export function* fetchRogueMACCountSaga({ payload }) {
  const url = '/api/device/getSPRogueMACCount';
  yield put(updateRogueMACCountFetchState(pendingFetchState));

  const currentDataSet = yield select(rogueMACCountSelector);
  yield call(debounceSaga, currentDataSet);

  const filterParams = yield select(filterParamsSelector);
  const apiBody = yield select(createApiBodySelector(url));

  const options = yield {
    params: merge(
      {},
      filterParams,
      get(payload, 'hourlyData', false) ? { hourlyData: true } : {}
    ),
    onTimeout: () => put(updateRogueMACCountFetchState(timeoutNotifyFetchState))
  };

  try {
    const { data } = yield ajaxPost(url, apiBody, options);
    yield put(fetchRogueMACCountComplete(data));
    yield put(updateRogueMACCountFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchRogueMACCountError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(updateRogueMACCountFetchState(updatedFetchState));
  }
}

export function* fetchRogueTrendSaga({ payload }) {
  yield put(updateRogueTrendFetchState(pendingFetchState));

  const currentDataSet = yield select(rogueTrendSelector);
  yield call(debounceSaga, currentDataSet);

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const dateRangeFilter = yield select(dateRangeFilterSelector);

  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');
  const granularity = yield getGranularityForDateRange(dateRangeFilter);

  const params = yield merge({}, filters, payload, {
    zone: get(payload, 'property.name', selectedPropertyName),
    wlanName: 'all',
    granularity: get(payload, 'granularity', granularity)
  });

  const url = '/api/fastapi/getSPRogueMACCount';
  const paramNames = [
    'granularity',
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'vertical',
    'wlanName',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updateRogueTrendFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchRogueTrendComplete(data));
    yield put(updateRogueTrendFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchRogueTrendError, err));
    yield put(updateRogueTrendFetchState(failedFetchState));
  }
}

export default function createFetchRogueMACCountSagas(types) {
  return [
    takeLatest([types.fetchRogueMACCount], fetchRogueMACCountSaga),
    takeLatest([types.fetchRogueTrend], fetchRogueTrendSaga)
  ];
}
