/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { merge } from 'lodash';
import { call, put, all } from 'redux-saga/effects';

import {
  pendingFetchState,
  failedFetchState,
  completeFetchState,
  timeoutFetchState,
  createErrorAction
} from 'app/redux/utils';
import { isAjaxTimeoutError } from 'app/custom-errors';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';
import { buildApiRequestArgs } from '../ajax-saga-utils';

import { ajaxPost } from './';

export function* genericAjaxPostSaga(
  urls,
  { updateFetchState, completeAction, errorAction },
  requestOptions = {},
  responseOptions = {}
) {
  const { customBody, dateOffset = 0, ...otherOpts } = requestOptions;
  const { addToResponse = {} } = responseOptions;

  yield put(updateFetchState(merge({}, pendingFetchState, addToResponse)));
  yield call(waitForHierarchySaga);

  try {
    const requestArgs = yield call(
      buildApiRequestArgs,
      urls,
      updateFetchState,
      { dateOffset, ...otherOpts }
    );

    if (customBody) {
      requestArgs.forEach(x => {
        x.body = customBody;
      });
    }

    const requests = requestArgs.map(({ url, body, options }) =>
      ajaxPost(url, body, options)
    );
    const responses = yield all(requests);

    const retrievedData = responses.reduce((allData, { data }) => {
      const { info, ...rest } = data;
      const infoKey = Object.keys(rest)[0] + 'Info';

      return { ...allData, [infoKey]: info, ...rest };
    }, {});

    yield put(completeAction(merge(retrievedData, addToResponse)));
    yield put(updateFetchState(merge({}, completeFetchState, addToResponse)));
  } catch (err) {
    yield put(createErrorAction(errorAction, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(updateFetchState(merge(updatedFetchState, addToResponse)));
  }
}
