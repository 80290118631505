/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  MuiTableOptions,
  MuiTableTheme,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import { merge } from 'lodash';

export const customTableOptions = merge({}, MuiTableOptions, {
  filterType: 'checkbox',
  selectableRows: 'none',
  viewColumns: true,
  responsive: 'stacked',
  search: true,
  serverSide: false,
  fixedHeaderOptions: { yAxis: true }
});

export const customMuiTableTheme = merge({}, MuiTableTheme, {
  overrides: {
    MuiTableCell: {
      head: {
        borderWidth: '1px 0 1px 0'
      },
      root: {
        padding: '15px 15px'
      }
    },
    MUIDataTableToolbar: {
      root: {
        display: 'flex'
      }
    },
    MuiPopover: {
      paper: {
        width: '15%'
      }
    }
  }
});
export const customMuiTableThemeWithCursor = merge(
  {},
  customMuiTableTheme,
  customMuiTableThemeWithDefualtCursor
);

export const sfdcSummaryTableColumns = [
  { name: 'accountname', label: 'Account Name', options: { display: false } },
  { name: 'aptsuite', label: 'Apartment', options: { display: false } },
  { name: 'building', label: 'Building', options: { display: false } },
  { name: 'actions', label: 'Actions', options: { display: false } },
  { name: 'casenumber', label: 'Case Number' },
  { name: 'casetype', label: 'Case Type' },
  { name: 'id', label: 'Case ID', options: { display: false } },
  {
    name: 'connectiontype',
    label: 'Connection Type',
    options: { display: false }
  },
  { name: 'contactname', label: 'Contact' },
  {
    name: 'createdbyname',
    label: 'Created By Name',
    options: { display: false }
  },
  {
    name: 'createdbyteam',
    label: 'Created By Team',
    options: { display: false }
  },
  {
    name: 'createddatetime',
    label: 'Created On',
    options: { sortDirection: 'desc' }
  },
  {
    name: 'custcasenumber',
    label: 'Customer Case Number',
    options: { display: false }
  },
  { name: 'description', label: 'Description' },
  { name: 'devicesimpacted', label: 'Devices Impacted' },
  {
    name: 'enduserdevicemac',
    label: 'End User Device MAC',
    options: { display: false }
  },
  {
    name: 'enduserdevicemanf',
    label: 'End User Device Manufacturer',
    options: { display: false }
  },
  {
    name: 'enduserdevicemodel',
    label: 'End User Device Model',
    options: { display: false }
  },
  {
    name: 'enduserdeviceos',
    label: 'End User Device OS',
    options: { display: false }
  },
  {
    name: 'engagementtype',
    label: 'Engagement Type',
    options: { display: false }
  },
  { name: 'escalatedon', label: 'Escalated On', options: { display: false } },
  { name: 'floor', label: 'Floor', options: { display: false } },
  { name: 'impact', label: 'Impact', options: { display: false } },
  { name: 'isescalated', label: 'Is Escalated', options: { display: false } },
  { name: 'issuestart', label: 'Issue Start' },
  {
    name: 'issuereported',
    label: 'Issue Reported',
    options: { display: false }
  },
  {
    name: 'issueresolved',
    label: 'Issue Resolved',
    options: { display: false }
  },
  { name: 'jiraid', label: 'Jira ID', options: { display: false } },
  {
    name: 'lastmodifieddatetime',
    label: 'Last Modified DateTime',
    options: { display: false }
  },
  { name: 'origin', label: 'Origin', options: { display: false } },
  { name: 'ownerteam', label: 'Owner Team', options: { display: false } },
  { name: 'parentid', label: 'Parent ID', options: { display: false } },
  { name: 'contactphone', label: 'Phone', options: { display: false } },
  { name: 'priority', label: 'Priority' },
  { name: 'zonename', label: 'Property', options: { display: false } },
  { name: 'propertyid', label: 'Property ID', options: { display: false } },
  { name: 'problemtype', label: 'Problem Type', options: { display: false } },
  { name: 'realm', label: 'Realm', options: { display: false } },
  { name: 'reason', label: 'Reason', options: { display: false } },
  {
    name: 'resolutiontype',
    label: 'Resolution Type',
    options: { display: false }
  },
  { name: 'rootcause', label: 'Root Cause', options: { display: false } },
  {
    name: 'serviceprovider',
    label: 'Service Provider',
    options: { display: false }
  },
  { name: 'servicetype', label: 'Service Type', options: { display: false } },
  { name: 'severity', label: 'Severity' },
  { name: 'status', label: 'Status' },
  { name: 'subject', label: 'Subject' },
  { name: 'truckroll', label: 'Truck Roll', options: { display: false } },
  { name: 'vertical', label: 'Vertical', options: { display: false } }
];

export const caseActivityTableColumns = [
  { name: 'id', label: 'ID' },
  { name: 'status', label: 'Status' },
  { name: 'subject', label: 'Subject' },
  { name: 'createddatetime', label: 'Created On' },
  { name: 'description', label: 'Description' },
  {
    name: 'completeddatetime',
    label: 'Actual End'
  },
  {
    name: 'createdbyname',
    label: 'Created By'
  },
  { name: 'priority', label: 'Priority' },
  { name: 'ownername', label: 'Case Owner' }
];

export const caseCommentscolumnNames = [
  { name: 'createdbyname', label: 'Created By' },
  {
    name: 'commentbody',
    label: 'Comments',
  },
  { name: 'createddatetime', label: 'Created Date' },
  {
    name: 'lastmodifiedbyname',
    label: 'Last Modified By'
  },
  { name: 'ispublished', label: 'Is Published' }
];

export const noDataMessageStyle = {
  background: 'none',
  position: 'relative'
};
