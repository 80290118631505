/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isEmpty } from 'lodash';
import React from 'react';
import { dateRangeNames } from 'app/components/filters/constants';
import { styleLibrary } from 'app/constants';
import { SessionsYAxisLabel, AuthClientYAxisLabel } from './components';

const hourRE = /^\d{1,2}:\d{2}$/;
const monthRE = /^\d{1,2}-\d{2,4}$/;

const inferDateLabelFromChartData = chartData => {
  if (!chartData.length) {
    return '';
  }
  const { label } = chartData[0];
  if (hourRE.test(label)) {
    return 'Hour';
  }
  return monthRE.test(label) ? 'Month' : 'Date';
};

export const xAxisLabel = (chartData, info, displayTwoLabels) => {
  const dateLabel = inferDateLabelFromChartData(chartData);
  return {
    value: `${dateLabel}${
      displayTwoLabels || isEmpty(info) ? '' : ' (' + info.timezone + ')'
    }`,
    position: 'bottom',
    offset: displayTwoLabels ? 25 : 0,
    fontWeight: 'bold',
    fontSize: styleLibrary.fontSizes.body
  };
};

export const customxAxisLabel = (chartData, info, displayTwoLabels, dateRangeFilterLabel) => {
  const dateLabel = dateRangeFilterLabel === dateRangeNames.LAST_24_HOURS
    ? 'Hour'
    : inferDateLabelFromChartData(chartData);

  return {
    value: `${dateLabel}${
      displayTwoLabels || isEmpty(info) ? '' : ' (' + info.timezone + ')'
    }`,
    position: 'bottom',
    offset: displayTwoLabels ? 25 : 0,
    fontWeight: 'bold',
    fontSize: styleLibrary.fontSizes.body
  };
};


export const trafficLabel = {
  value: 'Traffic',
  angle: -90,
  position: 'insideLeft',
  offset: -5,
  dy: 35,
  fontSize: styleLibrary.fontSizes.body,
  fill: styleLibrary.trafficColor
};

export const sessionsLabel = {
  content: <SessionsYAxisLabel />,
  angle: -90,
  position: 'insideRight',
  offset: 10,
  dy: -55,
  fontSize: styleLibrary.fontSizes.body,
  fill: styleLibrary.sessionsColor
};

export const authClientLabel = {
  content: <AuthClientYAxisLabel />,
  angle: -90,
  position: 'insideRight',
  offset: 10,
  dy: -55,
  fontSize: styleLibrary.fontSizes.body,
  fill: styleLibrary.sessionsColor
};
