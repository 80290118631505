/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  BasicTooltip,
  ChartFlexContainer,
  ChartLabel,
  ChartLegend,
  ChartModeSwitcher
} from 'app/components/charts';
import {
  BlockContainer,
  NoDataToShow,
  SubTitle,
  TitleBar
} from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { chartModeSuffix, styleLibrary } from 'app/constants';
import { chartModesSelector, updateChartDisplayMode } from 'app/redux/app';
import { filterParamsSelector, updateVertSSIDFilter } from 'app/redux/filters';
import {
  inventorySummaryFetchStateSelector,
  propertyInventoryCountSelector
} from 'app/redux/inventory';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  applyLegendToggles,
  calculateChartHeight,
  createColourFactory
} from 'app/utils';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { chartMargins, xAxisLabel, xAxisTick, yAxisTick } from './constants';

const getFillColour = createColourFactory();
const modeKey = `properties${chartModeSuffix}`;

const PieChartWrapper = styled.div.attrs({
  className: 'd-flex align-items-center justify-content-around'
})`
  width: 100%;
`;

export class PropertiesChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: []
    };
  }

  // This is necessary because of all the re-renders caused by parent component prop updates. It actually
  // interferes with the chart animation, and using a `PureComponent` doesn't seem to solve the issue
  // either. We may have something that needs investigating, but right now this works and performance is
  // acceptable.
  shouldComponentUpdate(nextProps, nextState) {
    const propChanged =
      Object.keys(nextProps).filter(k => !isEqual(this.props[k], nextProps[k]))
        .length > 0;
    const stateChanged =
      Object.keys(nextState).filter(k => !isEqual(this.state[k], nextState[k]))
        .length > 0;
    return propChanged || stateChanged;
  }

  handleChangeToggled = label => {
    const { toggled } = this.state;
    // Create a copy or we'll end up modifying state and our shouldComponentUpdate will break
    let newToggled = [...toggled];

    if (newToggled.includes(label)) {
      newToggled.splice(newToggled.indexOf(label), 1);
    } else {
      newToggled.push(label);
    }

    this.setState({ toggled: newToggled });
  };

  handleChangeVertical = data => {
    const { propertyType } = data.payload;
    const { filterParams, updateVertSSIDFilter } = this.props;

    updateVertSSIDFilter({
      ...filterParams,
      vertical: propertyType
    });
  };

  setMode = mode => {
    this.props.updateChartDisplayMode({ key: modeKey, mode });
  };

  shouldShowProperty = property => {
    const { propertyType } = property;
    const { vertical } = this.props.filterParams;

    return vertical === 'all' ? true : propertyType === vertical;
  };

  render() {
    const { chartData, chartModes = {}, fetchState } = this.props;
    const { toggled } = this.state;
    const { pending, complete, timeout } = fetchState;

    const hasChartData = !isEmpty(chartData);
    const noDataToShow = !pending && complete && !hasChartData;
    const dataToRender =
      timeout || pending ? [] : chartData.filter(this.shouldShowProperty);
    const mode = chartModes[modeKey] || 'pie';
    const { chartHeight, containerHeight } = calculateChartHeight(
      dataToRender.length,
      300,
      100
    );
    const propertyCount = dataToRender.reduce(
      (prev, current) => prev + current.propertyCount,
      0
    );

    let pieData;
    if (mode === 'pie') {
      pieData = applyLegendToggles(
        dataToRender,
        toggled,
        'propertyType',
        'propertyCount'
      );
    }

    return (
      <BlockContainer>
        <TitleBar
          leftChildren={
            <Fragment>
              Properties
              <SubTitle data-test-label="properties-sub-title">
                {hasChartData && (
                  <Fragment>
                    Total: <b>{propertyCount}</b>
                  </Fragment>
                )}
                &nbsp;
              </SubTitle>
            </Fragment>
          }
        />
        {noDataToShow ? <NoDataToShow /> : null}
        <Fragment>
          <FailedFetchStateHandler fetchState={fetchState}>
            <div
              className={classNames(
                'pb-4',
                pending || noDataToShow ? 'fetch-state-pending' : ''
              )}
            >
              {!isEmpty(dataToRender) && (
                <Fragment>
                  <ChartModeSwitcher onClick={this.setMode} mode={mode} />
                  <div className="d-flex justify-content-around">
                    {mode === 'bar' ? (
                      <ChartFlexContainer width="95%" height={containerHeight}>
                        <ComposedChart
                          margin={chartMargins}
                          height={chartHeight}
                          data={dataToRender}
                          layout="vertical"
                        >
                          <CartesianGrid vertical={true} horizontal={false} />
                          <XAxis
                            type="number"
                            label={xAxisLabel}
                            allowDecimals={false}
                            tick={xAxisTick}
                            tickMargin={1}
                          />
                          <YAxis
                            type="category"
                            yAxisId="propertyCount"
                            dataKey="propertyType"
                            stroke={styleLibrary.darkText}
                            tick={yAxisTick}
                          />
                          <Tooltip
                            content={<BasicTooltip maxValue={propertyCount} />}
                            contentStyle={{
                              fontSize: styleLibrary.fontSizes.body
                            }}
                            offset={0}
                            wrapperStyle={{ marginLeft: 80 }}
                          />
                          <Bar
                            dataKey="propertyCount"
                            dot={false}
                            onClick={this.handleChangeVertical}
                            name="Properties"
                            strokeWidth={1.5}
                            type="monotone"
                            yAxisId="propertyCount"
                          >
                            {dataToRender.map(({ propertyType }) => (
                              <Cell
                                key={propertyType}
                                fill={getFillColour(propertyType)}
                              />
                            ))}
                          </Bar>
                        </ComposedChart>
                      </ChartFlexContainer>
                    ) : (
                      <PieChartWrapper>
                        <PieChart width={140} height={140}>
                          <Pie
                            data={pieData}
                            dataKey="propertyCount"
                            innerRadius="50%"
                            nameKey="propertyType"
                            onClick={this.handleChangeVertical}
                            outerRadius="100%"
                          >
                            <LabelList
                              content={<ChartLabel />}
                              dataKey="propertyType"
                            />
                            {dataToRender.map(({ propertyType }) => (
                              <Cell
                                key={propertyType}
                                fill={getFillColour(propertyType)}
                              />
                            ))}
                          </Pie>
                          <Tooltip
                            content={<BasicTooltip maxValue={propertyCount} />}
                            contentStyle={{
                              fontSize: styleLibrary.fontSizes.body
                            }}
                            offset={0}
                            wrapperStyle={{ marginLeft: 80 }}
                          />
                        </PieChart>
                        <ChartLegend
                          data={dataToRender}
                          getFillColour={getFillColour}
                          onToggle={this.handleChangeToggled}
                          textProperty="propertyType"
                          toggled={toggled}
                          countProperty="propertyCount"
                          totalCount={propertyCount}
                          textWidth={40}
                          countWidth={15}
                        />
                      </PieChartWrapper>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
            {pending && <LoadingIconPlaceholder />}
          </FailedFetchStateHandler>
        </Fragment>
      </BlockContainer>
    );
  }
}

PropertiesChart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object),
  chartModes: PropTypes.object,
  filterParams: PropTypes.object,
  fetchState: fetchStatePropTypes,
  updateChartDisplayMode: PropTypes.func.isRequired,
  updateVertSSIDFilter: PropTypes.func.isRequired
};

const mapStateToProps = createSelector(
  propertyInventoryCountSelector,
  chartModesSelector,
  inventorySummaryFetchStateSelector,
  filterParamsSelector,
  (chartData, chartModes, fetchState, filterParams) => ({
    chartData,
    chartModes,
    fetchState,
    filterParams
  })
);

export default connect(mapStateToProps, {
  updateChartDisplayMode,
  updateVertSSIDFilter
})(PropertiesChart);
