/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createAction, handleActions } from 'redux-actions';
import { defaultTo, get, isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import createInitAppSagas from './sagas/initialize-app-saga';
import createUserLoginSagas from './sagas/user-login-sagas';
import createJWTSagas from './sagas/jwt-sagas';
import createPdfSagas from './sagas/pdf-saga';
import createCsvSagas from './sagas/csv-saga';
import createChartDisplayModeSagas from './sagas/chart-display-mode-saga';
import createConfigSagas from './sagas/config-sagas';
import pruneAppCacheSaga from './sagas/prune-app-cache-saga';

import { createTypes, createAsyncTypes } from '../utils';
import { CONFIG_BUCKETFILTER_KEY } from './constants';

export const nameSpace = 'app';

export const types = createTypes(
  [
    'appMount',
    'appReset',
    'initApp',

    'reportUIError',

    'updateAppCache',

    'updateUserLoginFetchState',
    'updateUserToken',
    ...createAsyncTypes('userLogin'),
    'userSignOut',
    'userFoundInStorage',
    'noUserFoundInStorage',
    'validUserFoundInStorage',
    'generatePdfFromHtml',
    'generatePdfFromDataDefinition',
    'exportDataError',
    'generateCsvFromDataDefinition',

    'storeChartDisplayMode',
    'updateChartDisplayMode',
    'chartModesFoundInStorage',

    'getConfig',
    'setConfig'
  ],
  nameSpace
);

export const sagas = [
  ...createInitAppSagas(types),
  ...createUserLoginSagas(types),
  ...createJWTSagas(types),
  ...createPdfSagas(types),
  ...createCsvSagas(types),
  ...createChartDisplayModeSagas(types),
  ...createConfigSagas(types),
  pruneAppCacheSaga()
];

export const appMount = createAction(types.appMount);
export const appReset = createAction(types.appReset);
export const initApp = createAction(types.initApp);

export const reportUIError = createAction(types.reportUIError);

export const updateAppCache = createAction(types.updateAppCache);

export const updateUserToken = createAction(types.updateUserToken);
export const userLogin = createAction(types.userLogin);
export const userLoginComplete = createAction(types.userLoginComplete);
export const userLoginError = createAction(types.userLoginError);

export const userSignOut = createAction(types.userSignOut);
export const updateUserLoginFetchState = createAction(
  types.updateUserLoginFetchState
);
export const userFoundInStorage = createAction(types.userFoundInStorage);
export const noUserFoundInStorage = createAction(types.noUserFoundInStorage);
export const validUserFoundInStorage = createAction(
  types.validUserFoundInStorage
);

export const generatePdfFromHtml = createAction(types.generatePdfFromHtml);
export const generatePdfFromDataDefinition = createAction(
  types.generatePdfFromDataDefinition
);
export const generateCsvFromDataDefinition = createAction(
  types.generateCsvFromDataDefinition
);
export const exportDataError = createAction(types.exportDataError);

export const chartModesFoundInStorage = createAction(
  types.chartModesFoundInStorage
);
export const updateChartDisplayMode = createAction(
  types.updateChartDisplayMode
);
export const storeChartDisplayMode = createAction(types.storeChartDisplayMode);

export const appMountedSelector = state => state[nameSpace].appMounted;
export const appCacheSelector = state => state[nameSpace].appCache;
export const isManualLogOutSelector = state => state[nameSpace] && state[nameSpace].isManualLogOut ? state[nameSpace].isManualLogOut : false;

export const userSelector = state => defaultTo(state[nameSpace] && state[nameSpace].user ? state[nameSpace].user : {}, {});
export const userGroupsSelector = createSelector(userSelector, user =>
  defaultTo(user.groups, [])
);
export const isSignedInSelector = state => !isEmpty(userSelector(state));
export const loginFetchStateSelector = state =>
  state[nameSpace].loginFetchState;
export const chartModesSelector = state => state[nameSpace].chartModes;
export const userNameSelector = createSelector(userSelector, user =>
  `${user.firstName || ''} ${user.lastName || ''}`.trim()
);

export const getConfig = createAction(types.getConfig);
export const setConfig = createAction(types.setConfig);
export const configSelector = state => state[nameSpace].config;
export const createConfigSelector = key =>
  createSelector(configSelector, config => config[key]);

export const hiddenTabsConfigSelector = createSelector(
  createConfigSelector(CONFIG_BUCKETFILTER_KEY),
  config => get(config, 'customer[0].hideTabs', {})
);
export const createShowPanelSelector = panelId =>
  createSelector(
    hiddenTabsConfigSelector,
    hiddenTabs => !hiddenTabs[`is${panelId}`]
  );

const initialState = {
  appCache: {},
  appMounted: false,
  chartModes: {},
  config: {},
  loginFetchState: {},
  isManualLogOut: false,
  user: {}
};

export const reducer = handleActions(
  {
    [types.appMount]: state => ({
      ...state,
      appMounted: true
    }),

    [types.appReset]: state => ({
      ...initialState,
      isManualLogOut: state.isManualLogOut,
      appMounted: true
    }),

    [types.updateUserLoginFetchState]: (state, { payload }) => ({
      ...state,
      loginFetchState: payload
    }),

    [types.userFoundInStorage]: (state, { payload }) => ({
      ...state,
      user: payload
    }),

    [types.validUserFoundInStorage]: state => ({
      ...state,
      loginComplete: true
    }),

    [types.userLoginComplete]: (state, { payload }) => ({
      ...state,
      isManualLogOut: false,
      user: payload
    }),

    [types.userSignOut]: (state, { payload = {} }) => {
      const { isManualLogOut = false } = payload;
      return {
        ...initialState,
        appMounted: state.appMounted,
        isManualLogOut
      };
    },

    [types.updateUserToken]: (state, { payload }) => ({
      ...state,
      user: { ...state.user, auth: payload }
    }),

    [types.updateAppCache]: (state, { payload }) => ({
      ...state,
      appCache: payload
    }),

    [types.chartModesFoundInStorage]: (state, { payload }) => ({
      ...state,
      chartModes: payload
    }),

    [types.updateChartDisplayMode]: (state, { payload }) => {
      const { key, mode } = payload;
      const { chartModes } = state;

      return {
        ...state,
        chartModes: { ...chartModes, [key]: mode }
      };
    },

    [types.setConfig]: (state, { payload }) => {
      const { key, config } = payload;
      return {
        ...state,
        config: {
          ...state.config,
          [key]: config
        }
      };
    }
  },
  initialState
);
