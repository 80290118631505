/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import {
  BlockContainer,
  NoDataToShow,
  TitleBar
} from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { CustomMuiDatatable } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import { MuiTableOptions } from 'app/constants';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  fetchIncidentDetails,
  incidentByPropertyDataSelector,
  incidentDetailsFetchStateSelector
} from 'app/redux/incidents';
import { fetchStatePropTypes } from 'app/redux/utils';
import { isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { customMuiTableThemeWithCursor } from './utils';

class IncidentByPropertyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate = prevProps => {
    const {
      dateRangeFilterLabel,
      fetchIncidentDetails,
      selectedPath
    } = this.props;
    const {
      dateRangeFilterLabel: prevDateRange,
      selectedPath: prevSelectedPath
    } = prevProps;

    const isNodeCustomerOrZone = isSpecificNodeType(selectedPath, 'customer');

    const pathChanged =
      prevSelectedPath && prevSelectedPath.id !== selectedPath.id;
    const dateRangeChanged = prevDateRange !== dateRangeFilterLabel;

    if ((isNodeCustomerOrZone && pathChanged) || dateRangeChanged) {
      fetchIncidentDetails();
    }
  };

  render = () => {
    const {
      fetchIncidentDetails,
      incidentByproperty = [],
      fetchState,
      selectedPath
    } = this.props;

    const { pending, complete, failed } = fetchState;
    const hasData = isArray(incidentByproperty) && !isEmpty(incidentByproperty);
    const noDataToShow = !pending && complete && !hasData;

    const isNodeCustomer = isSpecificNodeType(selectedPath, 'customer');

    const keys = incidentByproperty.reduce((acc, obj) => {
      for (let key of Object.keys(obj)) {
        acc.add(key);
      }
      return acc;
    }, new Set());
    const columns = [...keys].map(key => ({
      name: key,
      label: key,
      options: {
        display: true,
        sort: true,
        sortThirdClickReset: true
      }
    }));

    columns.forEach(column => {
      if (column.name === 'realmid') {
        column.options.display = false;
      }
      if (column.name === 'zonename') {
        column.label = 'Property';
        column.order = 1;
      }
      if (column.name === 'total') {
        column.label = '#Incidents';
        column.order = 2;
      }
    });

    const resultColumn = columns.sort((a, b) => {
      if (
        (a.name === 'zonename' || a.name === 'total') &&
        (b.name === 'zonename' || b.name === 'total')
      ) {
        return a.order - b.order;
      }
      if (a.name === 'zonename' || a.name === 'total') {
        return -1;
      }
      if (b.name === 'zonename' || b.name === 'total') {
        return 1;
      }
      if (a.name.startsWith('P') && b.name.startsWith('P')) {
        const aNumber = parseInt(a.name.match(/P(\d+)/)[1], 10);
        const bNumber = parseInt(b.name.match(/P(\d+)/)[1], 10);
        return aNumber - bNumber;
      }
      if (a.name.startsWith('P')) {
        return 1;
      }
      if (b.name.startsWith('P')) {
        return -1;
      }
      return 0;
    });

    const tableOptions = {
      ...MuiTableOptions,
      rowsPerPage: 5,
      rowsPerPageOptions: [5, 10, 15, 100]
    };

    return (
      <BlockContainer>
        <TitleBar leftChildren="By Property" padUnderTitle={false} />
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={fetchIncidentDetails}
        >
          {pending ? (
            <LoadingIconPlaceholder />
          ) : noDataToShow || failed ? (
            <NoDataToShow
              message={
                failed
                  ? 'There has been a problem fetching the incidents property'
                  : 'No incidents available'
              }
              style={{ background: 'none', position: 'relative' }}
            />
          ) : (
            isNodeCustomer && (
              <div
                className={pending || noDataToShow ? 'fetch-state-pending' : ''}
              >
                {complete && hasData && (
                  <MuiThemeProvider theme={createMuiTheme(customMuiTableThemeWithCursor)}>
                    <CustomMuiDatatable
                      tableKey="incident-by-property-table"
                      data={incidentByproperty}
                      columns={resultColumn}
                      options={tableOptions}
                    />
                  </MuiThemeProvider>
                )}
              </div>
            )
          )}
        </FailedFetchStateHandler>
      </BlockContainer>
    );
  };
}

IncidentByPropertyTable.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  fetchIncidentDetails: PropTypes.func,
  incidentByproperty: PropTypes.array,
  fetchState: fetchStatePropTypes
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  incidentByPropertyDataSelector,
  incidentDetailsFetchStateSelector,
  (dateRangeFilterLabel, selectedPath, incidentByproperty, fetchState) => ({
    dateRangeFilterLabel,
    selectedPath,
    incidentByproperty,
    fetchState
  })
);

const mapDispatchToProps = { fetchIncidentDetails };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentByPropertyTable);
