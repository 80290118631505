/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState
} from 'app/redux/utils';
import { get } from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';
import { ajaxGet } from '../util-sagas';
import {
  fetchSwitchDetailsComplete,
  fetchSwitchDetailsError,
  updateSwitchDetailsFetchState
} from './';

const url = '/api/fastapi/getListOfSwitchDetails';
const queryParams = ['switchName', 'zone'];

export function* fetchSwitchDetailsSaga({ payload }) {
  yield put(updateSwitchDetailsFetchState(pendingFetchState));

  try {
    const queryStringParams = yield queryParams
      .map(p => `${p}=${get(payload, p, '')}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`);

    yield put(fetchSwitchDetailsComplete(data));
    yield put(updateSwitchDetailsFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchSwitchDetailsError, e));
    yield put(updateSwitchDetailsFetchState(failedFetchState));
  }
}
export default function createFetchSwitchDetailsSagas(types) {
  return [takeLatest(types.fetchSwitchDetails, fetchSwitchDetailsSaga)];
}
