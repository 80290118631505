/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { styleLibrary } from 'app/constants';
import { prefixNavigationPath, zeroProtection } from 'app/utils';

import { DeviceRow } from './';

const CategoryHeading = styled.div.attrs({
  className: 'px-3 py-1'
})`
  background-color: rgba(77, 77, 77, 0.1);
  font-weight: bold;
  font-size: ${styleLibrary.fontSizes.secondaryTitle}px;
  border-bottom: 1px solid ${styleLibrary.containerBorderColor};
`;
CategoryHeading.displayName = 'CategoryHeading';

const createDeviceClickHandler = (props, device) => e => {
  e.preventDefault();
  const {
    category: levelType,
    history: { push },
    updateInventoryFilter
  } = props;
  const { type: deviceType } = device;
  updateInventoryFilter({ levelType, deviceType });
  return push(prefixNavigationPath('/inventory'));
};

export const createCalculateBarWidthForNumberOfDevices = props => noOfDevices => {
  const {
    calculated: { maxNumberOfDevices }
  } = props;
  const percentageOfMaxDevices = Math.floor(
    (noOfDevices / zeroProtection(maxNumberOfDevices)) * 100
  );
  return Math.max(percentageOfMaxDevices, 1);
};

const ElementsList = props => {
  const {
    category,
    calculated: { displayElementsMap, maxNumberOfDevices }
  } = props;
  const displayElements = displayElementsMap[category];
  const calculateBarWidthForNumberOfDevices = createCalculateBarWidthForNumberOfDevices(
    props
  );
  return (
    displayElements.length > 0 && (
      <div>
        <CategoryHeading>{category} Elements</CategoryHeading>
        {displayElements.map(device => {
          const deviceKey = device.type.replace(' ', '-').toLowerCase();

          return (
            <DeviceRow
              calculateBarWidthForNumberOfDevices={
                calculateBarWidthForNumberOfDevices
              }
              category={category}
              device={device}
              key={`${deviceKey}-${device.level}`}
              maxNumberOfDevices={maxNumberOfDevices}
              onClick={createDeviceClickHandler(props, device)}
            />
          );
        })}
      </div>
    )
  );
};

ElementsList.propTypes = {
  calculated: PropTypes.object,
  category: PropTypes.string.isRequired
};

export default withRouter(ElementsList);
