/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  accClientsTrendFetchStateSelector,
  accClientsTrendSelector,
  fetchAccClientsTrend
} from 'app/redux/network';
import { fetchStatePropTypes } from 'app/redux/utils';
import { get, isArray, isEmpty, last } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';

const CardDeckWrapper = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center m-3'
})``;

const CardDeck = styled.div.attrs({ className: 'card-deck' })``;

const Card = styled.div.attrs({
  className: 'card rounded border-0 shadow text-center text-white mx-1'
})`
  background-color: ${props => props.color};
  width: 150px;
  height: auto;
`;

const CardBody = styled.div.attrs({ className: 'card-body p-1' })`
  padding: 2px;
  margin-top: 10px;
`;

const CardTitle = styled.span.attrs({ className: 'card-title mb-0 h2' })``;

const CardText = styled.p.attrs({ className: 'card-text' })``;

const Tooltip = styled.div`
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  color: #000;
  padding: 8px;
  border-radius: 5px !important;
  display: ${props => (props.show ? 'block' : 'none')};
  width: 210px;
  height: 65px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-style: italic;
  font-weight: 500;
`;

class PropertyCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false
    };
  }

  handleMouseEnter = () => {
    this.setState({ showTooltip: true });
  };

  handleMouseLeave = () => {
    this.setState({ showTooltip: false });
  };

  render = () => {
    const { name, color, count, tooltip } = this.props;
    const { showTooltip } = this.state;

    return (
      <Card
        color={color}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <CardBody>
          <CardTitle>{count}</CardTitle>
          <CardText>{name}</CardText>
        </CardBody>
        <Tooltip show={showTooltip}>{tooltip}</Tooltip>
      </Card>
    );
  };
}

PropertyCard.defaultProps = { name: '', color: '#6c757d', count: 0 };
PropertyCard.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltip: PropTypes.node
};

export class PropertyCards extends Component {
  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilterLabel,
      fetchAccClientsTrend
    } = this.props;
    const {
      selectedPath: prevSelectedPath,
      dateRangeFilterLabel: prevDateRangeFilter
    } = prevProps;

    if (
      (prevSelectedPath && prevSelectedPath.id !== selectedPath.id) ||
      prevDateRangeFilter !== dateRangeFilterLabel
    ) {
      fetchAccClientsTrend({ zone: selectedPath.name });
    }
  };

  getCardData = dataArray => {
    const lastestDataItem = last(
      get(dataArray, '[0].countOfAccClientsInfo', {})
    );
    return [
      get(lastestDataItem, 'totalClients', 0),
      get(lastestDataItem, 'totalAccounts', 0),
      get(lastestDataItem, 'timestamp', 0),
      get(dataArray, '[0].totalBeds', 0),
      get(dataArray, '[0].totalVlans', 0)
    ];
  };

  MultilineTooltip = ({ text }) => (
    <div style={{ whiteSpace: 'pre-line' }}>
      {text}
    </div>
  );

  render = () => {
    const {
      fetchState,
      data: { aleAccClientsTrend = [] },
      fetchAccClientsTrend,
      selectedPath
    } = this.props;

    const { pending, complete } = fetchState;
    const hasData = isArray(aleAccClientsTrend) && !isEmpty(aleAccClientsTrend);

    const [clients, users, latestDate, beds, vlans] =
      complete && hasData
        ? this.getCardData(aleAccClientsTrend)
        : [0, 0, 0, 0, 0];

    return (
      <FailedFetchStateHandler
        fetchState={fetchState}
        retry={() => fetchAccClientsTrend({ zone: selectedPath.name })}
      >
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : (
          <CardDeckWrapper>
            <CardDeck>
              <PropertyCard
                name="Clients"
                count={clients}
                tooltip={<this.MultilineTooltip text={`As on ${latestDate}\nNo. of Clients / Devices: ${clients}`}
                />}
              />
              <PropertyCard
                name="Users"
                count={users}
                tooltip={<this.MultilineTooltip text={`As on ${latestDate}\nNo. of Users / Accounts: ${users}`} />}
              />
              <PropertyCard
                name="Beds"
                count={beds}
                tooltip={<this.MultilineTooltip text={`As on ${latestDate}\nNo. of Beds: ${beds}`}/>}
              />
              <PropertyCard
                name="VLANs"
                count={vlans}
                tooltip={<this.MultilineTooltip text= {`As on ${latestDate}\nNo. of VLANs Provisioned: ${vlans}`}/>}
              />
            </CardDeck>
          </CardDeckWrapper>
        )}
      </FailedFetchStateHandler>
    );
  };
}

PropertyCards.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  fetchAccClientsTrend: PropTypes.func,
  data: PropTypes.object,
  fetchState: fetchStatePropTypes
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  accClientsTrendFetchStateSelector,
  accClientsTrendSelector,
  (dateRangeFilterLabel, selectedPath, fetchState, data) => ({
    dateRangeFilterLabel,
    selectedPath,
    fetchState,
    data
  })
);

const mapDispatchToProps = { fetchAccClientsTrend };

export default connect(mapStateToProps, mapDispatchToProps)(PropertyCards);
