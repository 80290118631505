import PropTypes from 'prop-types';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  fetchInventorySummary,
  inventorySummaryFetchStateSelector,
  inventorySummarySelector
} from 'app/redux/inventory';
import { fetchStatePropTypes } from 'app/redux/utils';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  CardDeckWrapper,
  CardDeck,
  NoDataToShow
} from 'app/components/elements';
import { CardSummary } from 'app/components/elements/card';
import { isArray, isEmpty, isEqual, sumBy } from 'lodash';

const nameAbbreviations = {
  'Wireless Access Gateway': 'WAG',
  'Router VMs': 'Routers',
  Firewall: 'Firewalls'
};
export class InventoryCardSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.fetchInventorySummary();
  };

  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilterLabel,
      fetchInventorySummary
    } = this.props;
    const {
      selectedPath: prevSelectedPath,
      dateRangeFilterLabel: prevDateRangeFilter
    } = prevProps;
    const propertyChanged = !isEqual(prevSelectedPath.id, selectedPath.id);
    const dateRangeChanged = !isEqual(
      prevDateRangeFilter,
      dateRangeFilterLabel
    );
    if (propertyChanged || dateRangeChanged) {
      fetchInventorySummary();
    }
  };

  render = () => {
    const { fetchState, inventorySummary, fetchInventorySummary } = this.props;
    const { summary = [], apsummary } = inventorySummary;
    const { pending, complete } = fetchState;

    const hasInventoryCount =
      isArray(summary) && (!isEmpty(summary) || !isEmpty(apsummary));
    const noDataToShow = !pending && complete && !hasInventoryCount;

    const cardData = summary.map(item => ({
      name: item.type,
      count: item.total
    }));

    const apsCount = sumBy(apsummary, 'total');

    return (
      <Fragment>
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() => fetchInventorySummary()}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message="No data available"
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            complete &&
            hasInventoryCount && (
              <>
                <CardDeckWrapper>
                  <CardDeck>
                    {cardData.map(({ name, count }) => (
                      <CardSummary key={name} name={nameAbbreviations[name] || name} count={count} />
                    ))}
                    <CardSummary name={'APs'} count={apsCount} />
                  </CardDeck>
                </CardDeckWrapper>
              </>
            )
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

InventoryCardSummary.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  calculated: PropTypes.object,
  fetchState: fetchStatePropTypes,
  inventorySummary: PropTypes.object,
  fetchInventorySummary: PropTypes.func.isRequired
};

const mapStateToProps = createSelector(
  inventorySummarySelector,
  inventorySummaryFetchStateSelector,
  selectedPathSelector,
  dateRangeFilterLabelSelector,
  (inventorySummary, fetchState, selectedPath, dateRangeFilterLabel) => ({
    inventorySummary,
    fetchState,
    selectedPath,
    dateRangeFilterLabel
  })
);

const mapDispatchToProps = { fetchInventorySummary };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryCardSummary);
