/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { styleLibrary } from 'app/constants';

export const chartMargins = {
  left: 0,
  bottom: 25,
  right: 20
};
export const xAxisLabel = {
  value: 'Properties',
  position: 'bottom',
  offset: 0,
  fontWeight: 'bold',
  fontSize: styleLibrary.fontSizes.body
};
export const xAxisTick = {
  fontSize: styleLibrary.fontSizes.smallBody
};
export const yAxisTick = {
  fill: styleLibrary.darkText,
  fontSize: styleLibrary.fontSizes.body
};
