/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { styleLibrary } from '../../constants';

import MessageOverlay from './message-overlay';
import { MaterialIcon } from '../icons';

export const NoDataTestSelector = '[data-test-label="no-data-overlay"]';

const MessageWrapper = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center my-4 py-4'
})`
  color: ${styleLibrary.containerBorderColor};
`;

const NoDataToShow = props => {
  const { icon, message, mode = 'chart' } = props;

  return (
    <MessageOverlay data-test-label="no-data-overlay" {...props}>
      <MessageWrapper>
        <span className="d-flex align-self-center mr-3">
          <MaterialIcon icon={icon} style={{ fontSize: 32 }} />
        </span>
        <span className="text-left">
          {message || (
            <span>
              We need some more data to display this {mode}
              <br />
              <i>
                <small>
                  We&apos;ll keep capturing activity and show this {mode} when
                  possible
                </small>
              </i>
            </span>
          )}
        </span>
      </MessageWrapper>
    </MessageOverlay>
  );
};

NoDataToShow.defaultProps = {
  icon: 'grid_off'
};

NoDataToShow.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
  mode: PropTypes.oneOf(['chart', 'grid'])
};

export default NoDataToShow;
