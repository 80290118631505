/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { filterParamsSelector } from 'app/redux/filters';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import { ajaxGet } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { getPropertyParent } from 'app/utils';
import { get, merge } from 'lodash';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchCBRsDruidAPsTableComplete,
  fetchCBRsDruidAPsTableError,
  fetchCBRsDruidTrafficTrendComplete,
  fetchCBRsDruidTrafficTrendError,
  fetchCBRsInventorySummaryComplete,
  fetchCBRsInventorySummaryError,
  updateCBRsDruidAPsTableFetchState,
  updateCBRsDruidTrafficTrendFetchState,
  updateCBRsInventorySummaryFetchState
} from '.';

export function* fetchCBRsInventorySummarySaga({ payload }) {
  yield put(updateCBRsInventorySummaryFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');
  const params = yield merge({}, filters, payload, {
    zoneName: get(payload, 'property.name', selectedPropertyName)
  });

  const url = '/api/fastapi/getCBRSInventory';

  const paramNames = [
    'operator',
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'zoneName'
  ];

  try {
    const onTimeout = () =>
      put(updateCBRsInventorySummaryFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(
        p =>
          `${p}=${
            params[p] !== undefined
              ? encodeURIComponent(params[p])
              : 'Commscope'
          }`
      )
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchCBRsInventorySummaryComplete(data));
    yield put(updateCBRsInventorySummaryFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchCBRsInventorySummaryError, e));
    yield put(updateCBRsInventorySummaryFetchState(failedFetchState));
  }
}

export function* fetchCBRsDruidAPsTableSaga() {
  yield put(updateCBRsDruidAPsTableFetchState(pendingFetchState));

  const url = '/api/fastapi/getCBRSAPS';

  try {
    const onTimeout = () =>
      put(updateCBRsDruidAPsTableFetchState(timeoutNotifyFetchState));

    const { data } = yield ajaxGet(`${url}`, {
      onTimeout
    });

    yield put(fetchCBRsDruidAPsTableComplete(data));
    yield put(updateCBRsDruidAPsTableFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchCBRsDruidAPsTableError, e));
    yield put(updateCBRsDruidAPsTableFetchState(failedFetchState));
  }
}

export function* fetchCBRsDruidTrafficDataSaga({ payload }) {
  yield put(updateCBRsDruidTrafficTrendFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');
  const params = yield merge({}, filters, payload, {
    zoneName: get(payload, 'property.name', selectedPropertyName),
    subscriber: get(payload, 'subscriber', '')
  });

  const url = '/api/fastapi/getDruidTrafficData';

  const paramNames = [
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'zoneName',
    'subscriber'
  ];

  try {
    const onTimeout = () =>
      put(updateCBRsDruidTrafficTrendFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchCBRsDruidTrafficTrendComplete(data));
    yield put(updateCBRsDruidTrafficTrendFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchCBRsDruidTrafficTrendError, e));
    yield put(updateCBRsDruidTrafficTrendFetchState(failedFetchState));
  }
}

export default function createFetchCBRsInventorySagas(types) {
  return [
    takeLatest(types.fetchCBRsInventorySummary, fetchCBRsInventorySummarySaga),
    takeLatest(types.fetchCBRsDruidAPsTable, fetchCBRsDruidAPsTableSaga),
    takeLatest(types.fetchCBRsDruidTrafficTrend, fetchCBRsDruidTrafficDataSaga)
  ];
}
