/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { take, select } from 'redux-saga/effects';
import {
  hierarchyFetchStateSelector,
  types as hierarchyTypes
} from 'app/redux/hierarchy';

export default function* waitForHierarchySaga() {
  const hierarchyFetchState = yield select(hierarchyFetchStateSelector);

  if (hierarchyFetchState.pending) {
    yield take(hierarchyTypes.getHierarchyComplete);
  }
}
