/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

export class CustomMuiDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortCol: '',
      sortDir: ''
    };
  }

  handleSortChange = (colIndex, direction) => {
    this.setState({
      sortCol: colIndex,
      sortDir: direction
    });
  };

  render() {
    const { columns, data, options } = this.props;

    const displayOptions = {
      ...options,
      onColumnSortChange: this.handleSortChange,
      count: data.length
    };

    return (
      <MUIDataTable
        key={this.props.tableKey}
        columns={columns}
        data={data}
        options={displayOptions}
        title={this.props.title}
      />
    );
  }
}

CustomMuiDataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  tableKey: PropTypes.any.isRequired,
  options: PropTypes.object
};

export default CustomMuiDataTable;
