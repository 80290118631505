/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { InlineLoadingIcon } from 'app/components/icons';
import { styleLibrary } from 'app/constants';
import { colourToRGBA, contrastColour } from 'app/utils';

import { fetchStatePropTypes } from 'app/redux/utils';

const MessageWrapper = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center text-center'
})`
  background-color: ${colourToRGBA(styleLibrary.containerBg, 0.6)};
  font-size: ${styleLibrary.fontSizes.secondaryTitle}px;
  min-height: 300px;
  & button {
    background-color: ${styleLibrary.brand.primary};
    color: ${contrastColour(styleLibrary.brand.primary)};
  }
`;

const NoResponseFromApi = ({ retry }) => (
  <MessageWrapper>
    <div className="d-flex flex-column">
      We couldn&apos;t complete this request
      {retry ? (
        <button className="btn mt-2" onClick={() => retry()}>
          Retry
        </button>
      ) : null}
    </div>
    <div className="my-4 py-4" />
  </MessageWrapper>
);

NoResponseFromApi.propTypes = {
  retry: PropTypes.func
};

const LongTimeNotification = () => (
  <MessageWrapper>
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center">
        Getting this data seems to be taking longer than expected.
        <br />
        Please continue to wait...
        <div className="py-2" />
      </div>
      <InlineLoadingIcon
        style={{ fontSize: `${styleLibrary.fontSizes.panelLoadingIcon}px` }}
      />
    </div>
  </MessageWrapper>
);

const FailedFetchStateHandler = ({ fetchState, retry, children }) => {
  const { failed, timeout } = fetchState;

  if ((failed && timeout) || failed) {
    return <NoResponseFromApi retry={retry} />;
  }
  if (timeout) {
    return <LongTimeNotification />;
  }

  return children;
};

FailedFetchStateHandler.propTypes = {
  children: PropTypes.any,
  fetchState: fetchStatePropTypes,
  retry: PropTypes.func
};

export default FailedFetchStateHandler;
