/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { isNumber } from 'lodash';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';

import { types as appTypes } from 'app/redux/app';
import { types as filterTypes } from 'app/redux/filters';
import {
  createTypes,
  createAsyncTypes,
  pendingFetchState,
  createFetchStatePayloadCreator,
  updateFetchStateReducer
} from 'app/redux/utils';
import createFetchMapSummarySagas from './fetch-map-summary-saga';
import createMapMarkerSagas from './fetch-map-marker-saga';

export const nameSpace = 'map';
export const types = createTypes(
  [
    ...createAsyncTypes('fetchMapSummary'),
    ...createAsyncTypes('fetchMapMarkerDetails'),
    'updateMapSummaryFetchState'
  ],
  nameSpace
);

export const sagas = [
  ...createFetchMapSummarySagas(types),
  ...createMapMarkerSagas(types)
];

export const fetchMapSummary = createAction(types.fetchMapSummary);
export const fetchMapSummaryComplete = createAction(
  types.fetchMapSummaryComplete
);
export const fetchMapSummaryError = createAction(types.fetchMapSummaryError);

export const updateMapSummaryFetchState = createAction(
  types.updateMapSummaryFetchState,
  createFetchStatePayloadCreator('mapSummary')
);

export const fetchMapMarkerDetails = createAction(types.fetchMapMarkerDetails);
export const fetchMapMarkerDetailsComplete = createAction(
  types.fetchMapMarkerDetailsComplete
);

const fetchStatesSelector = state => state[nameSpace].fetchStates;
export const mapSummarySelector = state => state[nameSpace].mapSummary;
export const mapMarkerDetailsSelector = state =>
  state[nameSpace].mapMarkerDetails;

export const mapSummaryFetchStateSelector = state =>
  fetchStatesSelector(state).mapSummary;
export const mapSPSelector = createSelector(
  mapSummarySelector,
  map => map.ServiceProvider
);

export const mapSummaryBoundsSelector = createSelector(
  mapSPSelector,
  (providers = [{ latitude: 0, longitude: 0 }]) => {
    // Where lat/lng not available, API returns 'NA' - exclude these
    const providersWithLat = providers.filter(sp => isNumber(sp.latitude));
    const providersWithLng = providers.filter(sp => isNumber(sp.longitude));

    return {
      minLat: Math.min(...providersWithLat.map(sp => sp.latitude)),
      minLng: Math.min(...providersWithLng.map(sp => sp.longitude)),
      maxLat: Math.max(...providersWithLat.map(sp => sp.latitude)),
      maxLng: Math.max(...providersWithLng.map(sp => sp.longitude))
    };
  }
);

export const createMapMarkerDetailsSelector = name =>
  createSelector(mapMarkerDetailsSelector, mapMarkers => mapMarkers[name]);

const initialState = {
  fetchStates: {
    mapSummary: pendingFetchState
  },
  mapSummary: {},
  mapMarkerDetails: {}
};

export const reducer = handleActions(
  {
    [types.fetchMapSummaryComplete]: (state, { payload }) => ({
      ...state,
      mapSummary: payload,
      mapMarkerDetails: {}
    }),

    [types.fetchMapMarkerDetailsComplete]: (state, { payload }) => {
      const { name, ...rest } = payload;

      return {
        ...state,
        mapMarkerDetails: {
          ...state.mapMarkerDetails,
          [name]: rest
        }
      };
    },

    [types.updateMapSummaryFetchState]: updateFetchStateReducer,

    [filterTypes.updateDateRangeFilter]: state => ({
      ...state,
      mapMarkerDetails: {}
    }),

    [appTypes.appReset]: () => initialState
  },
  initialState
);
