/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';

class MaterialIcon extends Component {
  render() {
    const { icon, style } = this.props;

    return (
      <i className="material-icons" style={style}>
        {icon}
      </i>
    );
  }
}

MaterialIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  style: PropTypes.object
};

export default MaterialIcon;
