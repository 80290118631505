/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Label from './label';

class FormDropDown extends Component {
  focusField(ref = this.fieldDOMNode) {
    return ref.current.focus();
  }

  handleFieldChange = event => {
    const { target } = event;
    const updatedValue = target.value;
    this.props.handleValueChanged(this.props.fieldId, updatedValue, target);
  };

  render() {
    const {
      dataset = [],
      labelText,
      fieldId,
      fieldClasses,
      groupClasses,
      disabled,
      placeholder,
      small,
      value
    } = this.props;

    return (
      <div
        className={classNames('form-group', groupClasses)}
        data-test-label="form-drop-down"
      >
        {labelText && <Label {...this.props} />}
        <select
          className={classNames(
            'form-control',
            small && 'form-control-sm',
            ...fieldClasses
          )}
          data-test-label="form-drop-down-select"
          disabled={disabled ? 'disabled' : ''}
          id={fieldId}
          name={fieldId}
          onChange={this.handleFieldChange}
          placeholder={placeholder}
          ref={this.fieldDOMNode}
          value={value}
        >
          {!isEmpty(dataset) &&
            dataset.map(item => (
              <option
                value={item.value}
                key={item.value}
                dangerouslySetInnerHTML={{ __html: item.text }} // We need to support HTML entities so this is necessary
              />
            ))}
        </select>
      </div>
    );
  }
}

FormDropDown.defaultProps = {
  fieldClasses: [],
  labelClasses: []
};

FormDropDown.propTypes = {
  dataset: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ).isRequired,
  fieldClasses: PropTypes.array,
  fieldId: PropTypes.string.isRequired,
  groupClasses: PropTypes.array,
  handleValueChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  labelClasses: PropTypes.array,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  value: PropTypes.string.isRequired
};

export default FormDropDown;
