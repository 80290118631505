/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React, { Fragment } from 'react';

import { bandwidthUnits, dataRateUnits, styleLibrary } from 'app/constants';
import {
  formatNumericValue,
  getScaledDisplayValue,
  getScaledValue
} from 'app/utils';

export const comparisonDataSets = {
  bandwidth: {
    color: [styleLibrary.trafficColor],
    displayFn: x => getScaledDisplayValue(x[0], bandwidthUnits)
  },
  download: {
    color: [styleLibrary.downloadColor],
    displayFn: x => getScaledDisplayValue(x[0], dataRateUnits)
  },
  upload: {
    color: [styleLibrary.uploadColor],
    displayFn: x => getScaledDisplayValue(x[0], dataRateUnits)
  },
  ratio: {
    color: [styleLibrary.sessionsColor, styleLibrary.clientsColor],
    // eslint-disable-next-line react/display-name
    displayFn: x => (
      <Fragment>
        <span style={{ color: styleLibrary.sessionsColor }}>
          {formatNumericValue(x[0])}
        </span>{' '}
        :{' '}
        <span style={{ color: styleLibrary.clientsColor }}>
          {formatNumericValue(x[1])}
        </span>
      </Fragment>
    )
  },
  duration: {
    color: [styleLibrary.averageDurationColor],
    displayFn: x => `${formatNumericValue(x)} min`
  }
};

export const valueBlocks = [
  {
    color: styleLibrary.trafficColor,
    displayFn: x => getScaledValue(x, bandwidthUnits),
    icon: 'exchange-alt',
    metric: 'bandwidth',
    title: 'Traffic',
    valueFn: x => x.bandwidthTXInMB + x.bandwidthRXInMB
  },
  {
    color: styleLibrary.downloadColor,
    displayFn: x => getScaledValue(x, dataRateUnits),
    icon: 'cloud-download-alt',
    metric: 'download',
    title: 'Download Rate',
    valueFn: x => x.throughputRXInKbps
  },
  {
    color: styleLibrary.uploadColor,
    displayFn: x => getScaledValue(x, dataRateUnits),
    icon: 'cloud-upload-alt',
    metric: 'upload',
    title: 'Upload Rate',
    valueFn: x => x.throughputTXInKbps
  }
];

export const averageDurationValueBlock = {
  className: 'col border-bottom-0',
  color: styleLibrary.averageDurationColor,
  displayFn: x => ({
    result: formatNumericValue(x),
    display: `${formatNumericValue(x)} min`,
    unit: 'min'
  }),
  icon: 'stopwatch',
  metric: 'duration',
  title: 'Avg. Duration',
  valueFn: x => x.averageSessionDuration
};
