/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { delay } from 'redux-saga/effects';
import { isEmpty } from 'lodash';

import { ajaxSaga } from './ajax-saga';
import { eventChannel } from '@redux-saga/core';

export {
  getItemFromStorage,
  getItemsFromStorageByKey,
  setItemToStorage
} from './localstorage-sagas';
export const ajaxGet = ajaxSaga.bind(null, 'get');
export const ajaxPost = ajaxSaga.bind(null, 'post');

export const debounceDelay = 2000;
export function* debounceSaga(currentDataSet) {
  if (isEmpty(currentDataSet)) {
    return;
  }
  yield delay(debounceDelay);
  return;
}

export const interval = intervalTime => {
  return eventChannel(emitter => {
    const intervalId = setInterval(() => {
      emitter(true);
    }, intervalTime);
    return () => clearInterval(intervalId);
  });
};
