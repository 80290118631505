/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { ErrorModal } from 'app/components/modals';
import { StatefulTable } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import { MuiTableOptions, MuiTableTheme } from 'app/constants';
import { getReportsColumns, sortReportData } from 'app/modules/reports/utils';
import { createShowPanelSelector, userGroupsSelector } from 'app/redux/app';
import { vertSSIDFilterSelector } from 'app/redux/filters';
import {
  hierarchyNodeSelected,
  hierarchySelector,
  rootNodeSelector,
  selectedPathSelector
} from 'app/redux/hierarchy';
import {
  displayReportsListSelector,
  downloadErrorSelector,
  downloadingReportSelector,
  fetchReportsList,
  generateInProgressSelector,
  generateReport,
  getReportFile,
  reportError,
  reportsListFetchStateSelector
} from 'app/redux/reports';
import { closeModal, openModal } from 'app/redux/ui';
import classNames from 'classnames';
import { filter, first, isEmpty, isEqual, merge, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import GenerateReport from './generate-report';

const reportErrorModalKey = 'report-download-error';

class ReportsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      executive: false
    };
  }

  componentDidMount = () => {
    const { fetchReportsList, reportsList } = this.props;
    if (isEmpty(reportsList)) {
      fetchReportsList();
    }
  };

  componentDidUpdate = prevProps => {
    const { downloadError: prevDownloadError } = prevProps;
    const { downloadError, openModal } = this.props;
    if (!isEqual(downloadError, prevDownloadError) && !isEmpty(downloadError)) {
      openModal(reportErrorModalKey);
    }
  };

  closeErrorModal = () => {
    const { closeModal, reportError } = this.props;
    closeModal(reportErrorModalKey);
    reportError({});
  };

  getReportError = () => {
    const {
      downloadError: { action, report, type }
    } = this.props;
    if (isEqual(type, 'executive-report')) {
      const reportObj = JSON.parse(report.hierarchy);
      const reportDescription = `"${upperFirst(reportObj.type)} - ${
        reportObj.name
      }"`;

      let reportAction = `${
        action === 'delete' ? 'delete' : 'download'
      } the requested`;

      // Actions will be 'generate', 'pdf', 'csv' or 'delete'.
      if (!['pdf', 'csv', 'delete'].includes(action)) {
        reportAction = 'generate your';
      }

      return `Sorry, but it has not been possible to ${reportAction} ${reportDescription} report at this time.`;
    }
    return 'Operation failed!';
  };

  handleReportAction = (action, reportId) => {
    const { getReportFile, reportsList } = this.props;
    const report = first(filter(reportsList, { reportUniqueID: reportId }));
    getReportFile({ action, report });
  };

  toggleGenerateReport = report => () =>
    this.setState(prevState => ({ [report]: !prevState[report] }));

  render = () => {
    const {
      downloadError,
      downloadingReport,
      fetchReportsList,
      generateReport,
      reportsList,
      reportsListFetchState: fetchState,
      userGroups = [],
      showGenerateOverviewReport,
      showGenerateReportList
    } = this.props;
    const { executive } = this.state;

    const { pending, complete } = fetchState;
    const noDataToShow = !pending && complete && isEmpty(reportsList);
    const isAdmin = userGroups.includes('ADMIN');
    const hasReportError = !isEmpty(downloadError);

    const reportsTableTheme = merge({}, MuiTableTheme, {
      overrides: {
        MUIDataTableBodyRow: { root: { cursor: 'default' } },
        MuiTableRow: {
          root: {
            '&.row-error td': { color: 'red' },
            '& .report-actions div': { cursor: 'pointer' }
          }
        }
      }
    });
    const reportsTableOptions = merge({}, MuiTableOptions, {
      customSort: sortReportData,
      setRowProps: row => {
        return {
          className: classNames(row[5].toLowerCase() === 'error' && 'row-error')
        };
      }
    });

    // {/* <ReportsWrapper> */}
    // {/* <BlockContainer data-test-label="reports-list" classes={['col']}> */}
    // {/* <TitleBar leftChildren="Reports" /> */}
    return (
      <Fragment>
        {!isAdmin && (
          <Fragment>
            {showGenerateOverviewReport && (
              <GenerateReport
                {...this.props}
                handleGenerateReport={generateReport}
                showGenerate={executive}
                toggleGenerate={this.toggleGenerateReport('executive')}
              />
            )}
          </Fragment>
        )}
        {showGenerateReportList && (
          <FailedFetchStateHandler
            fetchState={fetchState}
            retry={fetchReportsList}
          >
            {noDataToShow && (
              <NoDataToShow
                message="There are no currently reports available for download"
                style={{ position: 'inherit', width: '100%', right: 0 }}
              />
            )}
            {!isEmpty(reportsList) && complete && (
              <MuiThemeProvider theme={createMuiTheme(reportsTableTheme)}>
                <StatefulTable
                  tableKey={`reports-list-${downloadingReport.id || ''}`}
                  columns={getReportsColumns(
                    isAdmin,
                    this.handleReportAction,
                    fetchReportsList,
                    downloadingReport
                  )}
                  data={reportsList}
                  options={reportsTableOptions}
                />
              </MuiThemeProvider>
            )}
            {pending && <LoadingIconPlaceholder position="relative" />}
          </FailedFetchStateHandler>
        )}
        {hasReportError && (
          <ErrorModal
            closeModal={this.closeErrorModal}
            message={this.getReportError()}
          />
        )}
      </Fragment>
    );
  };
}
// {/* </BlockContainer> */}
// {/* </ReportsWrapper> */}

ReportsTable.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  reportError: PropTypes.func.isRequired,
  hierarchyNodeSelected: PropTypes.func,
  fetchReportsList: PropTypes.func.isRequired,
  generateReport: PropTypes.func.isRequired,
  getReportFile: PropTypes.func.isRequired,
  reportsList: PropTypes.array,
  userGroups: PropTypes.array.isRequired,
  downloadError: PropTypes.object,
  downloadingReport: PropTypes.object,
  hierarchy: PropTypes.object,
  reportsListFetchState: PropTypes.object,
  selectedPath: PropTypes.object,
  rootNode: PropTypes.object,
  vertSSIDFilter: PropTypes.object.isRequired,
  showGenerateOverviewReport: PropTypes.bool.isRequired,
  showGenerateReportList: PropTypes.bool.isRequired
};

const mapStateToProps = createSelector(
  createShowPanelSelector('ReportsGenerateOverviewReport'),
  createShowPanelSelector('ReportsGenerateReportList'),
  displayReportsListSelector,
  downloadErrorSelector,
  downloadingReportSelector,
  generateInProgressSelector,
  hierarchySelector,
  reportsListFetchStateSelector,
  selectedPathSelector,
  userGroupsSelector,
  vertSSIDFilterSelector,
  rootNodeSelector,
  (
    showGenerateOverviewReport,
    showGenerateReportList,
    reportsList,
    downloadError,
    downloadingReport,
    generateInProgress,
    hierarchy,
    reportsListFetchState,
    selectedPath,
    userGroups,
    vertSSIDFilter,
    rootNode
  ) => ({
    showGenerateOverviewReport,
    showGenerateReportList,
    reportsList,
    downloadError,
    downloadingReport,
    generateInProgress,
    hierarchy,
    reportsListFetchState,
    selectedPath,
    userGroups,
    vertSSIDFilter,
    rootNode
  })
);

const mapDispatchToProps = {
  closeModal,
  fetchReportsList,
  generateReport,
  getReportFile,
  openModal,
  reportError,
  hierarchyNodeSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsTable);
