/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { PathComponent, TitleBar } from 'app/components/elements';
import Filters from 'app/components/filters';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { createShowPanelSelector } from 'app/redux/app';
import {
  dateRangeFilterLabelSelector,
  vertSSIDFilterSelector
} from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchIncidentDetails } from 'app/redux/incidents';
import { isEqual, noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  ChartByCaseType,
  ChartByDuration,
  ChartByPriority,
  IncidentSummaryTable
} from './components';
import IncidentByPropertyTable from './components/incident-by-property-table';

export class Incidents extends Component {
  componentDidMount = () => this.props.fetchIncidentDetails();

  componentDidUpdate = prevProps => {
    const {
      dateRangeFilterLabel,
      fetchIncidentDetails,
      selectedPath,
      vertSSIDFilter
    } = this.props;
    const {
      dateRangeFilterLabel: prevDateRange,
      selectedPath: prevSelectedPath,
      vertSSIDFilter: prevVertSSIDFilter
    } = prevProps;

    const isNodeCustomerOrZone =
      isSpecificNodeType(selectedPath, 'customer') ||
      isSpecificNodeType(selectedPath, 'zonename');
    const pathChanged =
      prevSelectedPath && prevSelectedPath.id !== selectedPath.id;
    const dateRangeChanged = prevDateRange !== dateRangeFilterLabel;
    const vertSSIDFilterChanged = !isEqual(prevVertSSIDFilter, vertSSIDFilter);

    if (
      (isNodeCustomerOrZone && pathChanged) ||
      dateRangeChanged ||
      vertSSIDFilterChanged
    ) {
      fetchIncidentDetails();
    }
  };

  getDynamicRowColClasses = () => {
    const {
      showChartByPriority,
      showChartByDuration,
      showChartByCaseType,
      showIncidentsByPropertyTable
    } = this.props;
    let chartByPriorityClasses = '';
    let chartByDurationClasses = '';
    let chartByCaseTypeClasses = '';
    let incidentByPropertyTableClasses = '';

    if (
      (showChartByPriority || showChartByDuration || showChartByCaseType) &&
      showIncidentsByPropertyTable
    ) {
      let pieChartCount = 0;
      [
        showChartByPriority,
        showChartByDuration,
        showChartByCaseType
      ].forEach(c => (c ? pieChartCount++ : noop));

      if (pieChartCount === 1) {
        chartByPriorityClasses = 'col-xl-4';
        chartByDurationClasses = 'col-xl-4';
        chartByCaseTypeClasses = 'col-xl-4';
        incidentByPropertyTableClasses = 'col-xl';
      } else if (pieChartCount === 3) {
        chartByPriorityClasses = 'col-xxxl';
        chartByDurationClasses = 'col-xxxl';
        chartByCaseTypeClasses = 'col-xxxl';
        incidentByPropertyTableClasses = 'col-xxxl-4';
      }
    }

    return {
      chartByPriorityClasses,
      chartByDurationClasses,
      chartByCaseTypeClasses,
      incidentByPropertyTableClasses
    };
  };

  render = () => {
    const {
      selectedPath,
      showChartByPriority,
      showChartByDuration,
      showChartByCaseType,
      showIncidentSummaryTable,
      showIncidentsByPropertyTable
    } = this.props;

    const showPieCharts =
      showChartByPriority || showChartByDuration || showChartByCaseType;
    const isCustomerLevel = isSpecificNodeType(selectedPath, 'customer');
    const {
      chartByPriorityClasses,
      chartByDurationClasses,
      chartByCaseTypeClasses,
      incidentByPropertyTableClasses
    } = this.getDynamicRowColClasses();

    return (
      <Fragment>
        <TitleBar
          id="incident-title"
          classes={['include-in-pdf']}
          dark
          leftChildren={<PathComponent />}
          rightChildren={
            <div className="d-flex justify-content-spread align-items-center">
              <Filters showVerticalFilter={true} />
            </div>
          }
        />
        <div className="row mt-4">
          {showPieCharts && (
            <Fragment>
              {showChartByPriority && (
                <div className={`col mb-4 ${chartByPriorityClasses}`}>
                  <ChartByPriority />
                </div>
              )}
              {showChartByDuration && (
                <div className={`col mb-4 ${chartByDurationClasses}`}>
                  <ChartByDuration />
                </div>
              )}
              {showChartByCaseType && (
                <div className={`col mb-4 ${chartByCaseTypeClasses}`}>
                  <ChartByCaseType />
                </div>
              )}
            </Fragment>
          )}
          {isCustomerLevel && showIncidentsByPropertyTable && (
            <div className={`col mb-4 ${incidentByPropertyTableClasses}`}>
              <IncidentByPropertyTable />
            </div>
          )}
        </div>
        {showIncidentSummaryTable && (
          <div className="row">
            <div className="col">
              <IncidentSummaryTable />
            </div>
          </div>
        )}
      </Fragment>
    );
  };
}

Incidents.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  vertSSIDFilter: PropTypes.object,
  fetchIncidentDetails: PropTypes.func,
  showChartByPriority: PropTypes.bool,
  showChartByDuration: PropTypes.bool,
  showChartByCaseType: PropTypes.bool,
  showIncidentSummaryTable: PropTypes.bool,
  showIncidentsByPropertyTable: PropTypes.bool
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  vertSSIDFilterSelector,
  createShowPanelSelector('IncidentsChartByPriority'),
  createShowPanelSelector('IncidentsChartByDuration'),
  createShowPanelSelector('IncidentsChartByCaseType'),
  createShowPanelSelector('IncidentsSummaryTable'),
  createShowPanelSelector('IncidentsByPropertyTable'),
  (
    dateRangeFilterLabel,
    selectedPath,
    vertSSIDFilter,
    showChartByPriority,
    showChartByDuration,
    showChartByCaseType,
    showIncidentSummaryTable,
    showIncidentsByPropertyTable
  ) => ({
    dateRangeFilterLabel,
    selectedPath,
    vertSSIDFilter,
    showChartByPriority,
    showChartByDuration,
    showChartByCaseType,
    showIncidentSummaryTable,
    showIncidentsByPropertyTable
  })
);

const mapDispatchToProps = { fetchIncidentDetails };

export default connect(mapStateToProps, mapDispatchToProps)(Incidents);
