/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { NoDataToShow } from 'app/components/elements';
import { defaultHostMetricSelection } from 'app/components/filters/constants';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  commonPlotProps,
  styleLibrary,
  tooltipDateDisplayFormat
} from 'app/constants';
import {
  dateRangeFilterSelector,
  hostMetricFilterSelector
} from 'app/redux/filters';
import {
  fetchZabbixDataTrend,
  zabbixDataTrendFetchStateSelector,
  zabbixDataTrendSelector
} from 'app/redux/inventory';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  createFormatAxisValue,
  formatApiDateTime,
  getAxisDomainMaxValue,
  getScaledDisplayValue
} from 'app/utils';
import { get, has, isEmpty, isEqual, maxBy } from 'lodash';
import momentTimezone from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { populateTrendChart } from '../../utils';

const PeakTrendLabel = styled.div.attrs({
  className: 'd-flex align-items-start ml-3 mb-3 p-2'
})`
  background-color: ${styleLibrary.lightBg};

  & span {
    margin: 0 8px;
  }
`;

const PeakTrendDirectionLabel = styled.span.attrs({
  className: 'd-inline-block'
})`
  width: auto;
`;

const PeakTrendValue = styled.b.attrs({
  className: 'd-inline-block text-right'
})`
  width: auto;
`;

export class TrendChart extends Component {
  constructor(props) {
    super(props);
    this.state = { hiddenPlotMap: {} };
  }

  isActivePlot = plotKey => {
    return this.state.hiddenPlotMap[plotKey] ? 'blank' : plotKey;
  };

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  getErrorMessage = (noTrendDataToShow, host, metric) => {
    if (host === '' || metric === '') {
      return 'Please select a network appliance and a report type';
    } else if (noTrendDataToShow) {
      return 'No trend data available';
    } else {
      return null;
    }
  };

  renderPeakTrend = (data, tooltipKeys, tooltipUnits) => {
    const {
      dateRangeFilter,
      trendChartData: { DataTrendInfo: info = {} }
    } = this.props;
    let maxValues = {};
    const timezone = get(info, 'timezone', 'UTC');

    tooltipKeys.forEach(k => {
      const maxDataPoint = maxBy(data, k);
      maxValues[k] = { rate: maxDataPoint[k], label: maxDataPoint['label'] };
    });

    return (
      <div>
        {tooltipKeys.map((value, index) => {
          return (
            <span
              key={index}
              style={{
                color: styleLibrary.downloadColor
              }}
            >
              <PeakTrendDirectionLabel>{value}</PeakTrendDirectionLabel>
              <PeakTrendValue>
                [max]{' '}
                {!isEqual(tooltipUnits, [''])
                  ? getScaledDisplayValue(maxValues[value].rate, tooltipUnits)
                  : maxValues[value].rate}
              </PeakTrendValue>
              <span>
                @{' '}
                {formatApiDateTime(
                  maxValues[value].label,
                  dateRangeFilter,
                  timezone,
                  true
                )}
              </span>
              <br />
            </span>
          );
        })}
      </div>
    );
  };

  render = () => {
    const { hiddenPlotMap } = this.state;
    const {
      dateRangeFilter,
      fetchZabbixDataTrend,
      trendChartData: { DataTrend: trend = [], DataTrendInfo: info = {} },
      trendChartFetchState = {},
      hostMetricFilter: { host, metric }
    } = this.props;

    const formatAxisValue = createFormatAxisValue();
    const { pending, complete } = trendChartFetchState;
    const noTrendDataToShow =
      !pending && complete && (isEmpty(trend) || has(trend[0], 'errorCode'));
    const errorMessage = this.getErrorMessage(noTrendDataToShow, host, metric);
    const timezone = get(info, 'timezone', 'UTC');

    const [
      yAxisLabel,
      xAxisTicks,
      dataToRender,
      tooltipUnits,
      tooltipKeys,
      reversed
    ] = noTrendDataToShow
      ? ['', [], [], [''], [], false]
      : populateTrendChart(this.props);

    return (
      <FailedFetchStateHandler
        fetchState={trendChartFetchState}
        retry={fetchZabbixDataTrend}
      >
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : host === '' || metric === '' || noTrendDataToShow ? (
          <NoDataToShow
            icon="error_outline"
            message={errorMessage}
            style={{ background: 'none', position: 'relative' }}
          />
        ) : (
          <div>
            <ChartFlexContainer width="99%" height={400}>
              <ComposedChart
                data={dataToRender}
                margin={{ ...chartMargins, left: 15 }}
              >
                <CartesianGrid vertical={false} />
                <Legend
                  content={
                    <TrendChartLegend onClick={this.togglePlotVisibility} />
                  }
                  inactivePlots={hiddenPlotMap}
                  verticalAlign="top"
                />
                <XAxis
                  dataKey="label"
                  label={{
                    value: `Time (${timezone})`,
                    position: 'bottom',
                    offset: 5,
                    fontWeight: 'bold',
                    fontSize: styleLibrary.fontSizes.body
                  }}
                  interval="preserveStartEnd"
                  tick={tick}
                  ticks={xAxisTicks}
                  tickFormatter={t =>
                    formatApiDateTime(t, dateRangeFilter, timezone)
                  }
                  scale="time"
                  type="number"
                  domain={['dataMin', 'dataMax']}
                />
                <YAxis
                  yAxisId="trend"
                  label={{
                    value: yAxisLabel,
                    angle: -90,
                    position: 'insideLeft',
                    offset: 10,
                    dy: yAxisLabel.length <= 15 ? 35 : 75,
                    fontSize: styleLibrary.fontSizes.body
                  }}
                  stroke="rgb(31, 119, 180)"
                  tick={tick}
                  domain={[
                    dataMin => (dataMin < 0 ? dataMin : 0),
                    dataMax => getAxisDomainMaxValue(dataMax)
                  ]}
                  reversed={reversed}
                  scale="linear"
                  interval="preserveStartEnd"
                  tickFormatter={value =>
                    formatAxisValue(
                      value,
                      (val, dec) =>
                        getScaledDisplayValue(val, tooltipUnits, null, dec),
                      1
                    )
                  }
                />
                {tooltipKeys.map((value, index, tooltipKeys) => {
                  if (tooltipKeys.length < 3) {
                    return (
                      <Area
                        key={index}
                        dataKey={this.isActivePlot(value)}
                        name={value}
                        stroke={styleLibrary.trendColors[index]}
                        fill={styleLibrary.trendColors[index]}
                        yAxisId="trend"
                        {...commonPlotProps}
                      />
                    );
                  } else {
                    return (
                      <Line
                        key={index}
                        dataKey={this.isActivePlot(value)}
                        name={value}
                        stroke={styleLibrary.trendColors[index]}
                        yAxisId="trend"
                        {...commonPlotProps}
                      />
                    );
                  }
                })}
                <Tooltip
                  content={
                    <MultiLineTooltip
                      labelFormatter={label =>
                        momentTimezone
                          .tz(label, timezone)
                          .format(tooltipDateDisplayFormat)
                      }
                      keys={tooltipKeys}
                      units={tooltipUnits}
                      scale={!isEqual(tooltipUnits, [''])}
                    />
                  }
                  contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
                  offset={0}
                  wrapperStyle={{ marginLeft: 80 }}
                />
              </ComposedChart>
            </ChartFlexContainer>
            {!isEmpty(dataToRender) && (
              <div
                className="d-flex flex-column align-items-start"
                style={{ width: '99%' }}
              >
                <PeakTrendLabel data-test-label="property-peak-rates">
                  {this.renderPeakTrend(
                    dataToRender,
                    tooltipKeys,
                    tooltipUnits
                  )}
                </PeakTrendLabel>
              </div>
            )}
          </div>
        )}
      </FailedFetchStateHandler>
    );
  };
}

TrendChart.defaultProps = {
  hostMetricFilter: defaultHostMetricSelection
};

TrendChart.propTypes = {
  dateRangeFilter: PropTypes.object,
  fetchZabbixDataTrend: PropTypes.func,
  trendChartData: PropTypes.object,
  trendChartFetchState: fetchStatePropTypes,
  hostMetricFilter: PropTypes.object
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  zabbixDataTrendSelector,
  zabbixDataTrendFetchStateSelector,
  hostMetricFilterSelector,
  (
    dateRangeFilter,
    trendChartData,
    trendChartFetchState,
    hostMetricFilter
  ) => ({
    dateRangeFilter,
    trendChartData,
    trendChartFetchState,
    hostMetricFilter
  })
);

const mapDispatchToProps = { fetchZabbixDataTrend };

export default connect(mapStateToProps, mapDispatchToProps)(TrendChart);
