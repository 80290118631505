/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';

import { styleLibrary } from 'app/constants';

export const SessionsYAxisLabel = ({ viewBox }) => {
  const { x, y } = viewBox;
  return (
    <text
      x={x - 171} // Chart renders @ 343px high - so move down to half-way
      y={y}
      dy={45} // Offset distance of label from the actual axis
      fill={styleLibrary.darkText}
      transform={`rotate(-90, ${x}, ${y})`}
      textAnchor="middle"
    >
      <tspan fill={styleLibrary.clientsColor}>Clients</tspan> /{' '}
      <tspan fill={styleLibrary.sessionsColor}>Sessions</tspan>
    </text>
  );
};

SessionsYAxisLabel.propTypes = {
  viewBox: PropTypes.object
};
export const AuthClientYAxisLabel = ({ viewBox }) => {
  const { x, y } = viewBox;
  return (
    <text
      x={x - 171}
      y={y}
      dy={45}
      fill={styleLibrary.darkText}
      transform={`rotate(-90, ${x}, ${y})`}
      textAnchor="middle"
    >
      <tspan fill={styleLibrary.clientsColor}>CDR</tspan> /{' '}
      <tspan fill={styleLibrary.sessionsColor}>MQTT</tspan>
    </text>
  );
};

AuthClientYAxisLabel.propTypes = {
  viewBox: PropTypes.object
};
