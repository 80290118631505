/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { bandwidthUnits, commonPlotProps, styleLibrary } from 'app/constants';
import {
  fetchPropertyUserBandwidthTrend,
  propertyUserBandwidthTrendFetchStateSelector,
  propertyUserBandwidthTrendSelector
} from 'app/redux/clients';
import {
  dateRangeFilterLabelSelector,
  dateRangeFilterSelector
} from 'app/redux/filters';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  createFormatAxisValue,
  formatApiDateTime,
  getScaledDisplayValue
} from 'app/utils';
import { get, isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import { createXAxisTicks, formatDataPoints } from '../utils';

export class UserBandwidthTrendChart extends Component {
  constructor(props) {
    super(props);
    this.state = { hiddenPlotMap: {} };
  }

  componentDidMount = () => {
    const { fetchPropertyUserBandwidthTrend, userName } = this.props;
    fetchPropertyUserBandwidthTrend({ userName });
  };

  componentDidUpdate = prevProps => {
    const { fetchPropertyUserBandwidthTrend, userName } = this.props;
    const { userName: prevUserName } = prevProps;
    const userChanged = userName !== '' && userName !== prevUserName;

    if (userChanged) {
      fetchPropertyUserBandwidthTrend({ userName });
    }
  };

  isActivePlot = plotKey => {
    return this.state.hiddenPlotMap[plotKey]
      ? 'blank'
      : plotKey.replace(' ', '').toLowerCase();
  };

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  renderBandwidthChart = () => {
    const {
      dateRangeFilter,
      propertyUserBandwidthTrend: { data = [], info = {} }
    } = this.props;

    const timezone = get(info, 'timezone', 'UTC');
    const dataToRender = formatDataPoints(
      data,
      timezone
      // undefined,
      // 'DD-MMM-YYYY HH:mm'
    );
    const xAxisTicks = createXAxisTicks(
      data,
      dateRangeFilter,
      timezone
      // undefined,
      // 'DD-MMM-YYYY HH:mm'
    );
    const formatAxisValue = createFormatAxisValue();

    return (
      <ChartFlexContainer width="99%" height={300}>
        <ComposedChart
          data={dataToRender}
          margin={{ ...chartMargins, left: 15 }}
        >
          <CartesianGrid vertical={false} />
          <Legend
            content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
            inactivePlots={this.state.hiddenPlotMap}
            verticalAlign="top"
          />
          <XAxis
            dataKey="label"
            label={{
              value: `Time (${timezone})`,
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            interval="preserveStartEnd"
            tick={tick}
            ticks={xAxisTicks}
            tickFormatter={t => formatApiDateTime(t, dateRangeFilter, timezone)}
            scale="time"
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            yAxisId="userBandwidthTrend"
            label={{
              value: 'Traffic',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body,
              fill: styleLibrary.trafficColor
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            tickFormatter={value =>
              formatAxisValue(
                value,
                (val, dec) =>
                  getScaledDisplayValue(val, bandwidthUnits, null, dec),
                1
              )
            }
          />
          <Area
            dataKey={this.isActivePlot('Download')}
            name="Download"
            stroke={styleLibrary.downloadColor}
            fill={styleLibrary.downloadColor}
            yAxisId="userBandwidthTrend"
            {...commonPlotProps}
          />
          <Area
            dataKey={this.isActivePlot('Upload')}
            name="Upload"
            stroke={styleLibrary.uploadColor}
            fill={styleLibrary.uploadColor}
            yAxisId="userBandwidthTrend"
            {...commonPlotProps}
          />
          <Line
            dataKey={this.isActivePlot('Total')}
            name="Total"
            stroke="rgb(31, 119, 180)"
            yAxisId="userBandwidthTrend"
            {...commonPlotProps}
          />
          <Tooltip
            content={
              <MultiLineTooltip
                labelFormatter={label =>
                  formatApiDateTime(label, dateRangeFilter, timezone, true)
                }
                keys={['download', 'upload', 'total']}
                units={bandwidthUnits}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
          />
        </ComposedChart>
      </ChartFlexContainer>
    );
  };

  render = () => {
    const {
      fetchState,
      propertyUserBandwidthTrend: { data = [] },
      fetchPropertyUserBandwidthTrend,
      userName
    } = this.props;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <FailedFetchStateHandler
        fetchState={fetchState}
        retry={() => fetchPropertyUserBandwidthTrend({ userName })}
      >
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow ? (
          <NoDataToShow
            icon="error_outline"
            message="No data available"
            style={{ background: 'none', position: 'relative', height: 'auto' }}
          />
        ) : (
          complete && hasData && this.renderBandwidthChart()
        )}
      </FailedFetchStateHandler>
    );
  };
}

UserBandwidthTrendChart.propTypes = {
  dateRangeFilter: PropTypes.object,
  dateRangeFilterLabel: PropTypes.string,
  fetchPropertyUserBandwidthTrend: PropTypes.func,
  propertyUserBandwidthTrend: PropTypes.object,
  fetchState: fetchStatePropTypes,
  userName: PropTypes.string
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  dateRangeFilterLabelSelector,
  propertyUserBandwidthTrendFetchStateSelector,
  propertyUserBandwidthTrendSelector,
  (
    dateRangeFilter,
    dateRangeFilterLabel,
    fetchState,
    propertyUserBandwidthTrend
  ) => ({
    dateRangeFilter,
    dateRangeFilterLabel,
    fetchState,
    propertyUserBandwidthTrend
  })
);

const mapDispatchToProps = { fetchPropertyUserBandwidthTrend };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBandwidthTrendChart);
