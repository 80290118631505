/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import styled from 'styled-components';

import { styleLibrary } from 'app/constants';

export const InfoBoxContainer = styled.div.attrs({
  className: 'map-hover-container d-flex justify-content-center'
})`
  position: relative;
  width: 300px;
  top: -${props => props.offset + 10}px;
  left: -152px;
  z-index: ${props => props.zIndex || 10};
`;

export const HoverBox = styled.div.attrs({
  className: 'map-hover-box'
})`
  display: inline-block;
  font-size: ${props => props.fontSize}px;
  background: #fff;
  padding: 8px 10px;
  white-space: nowrap;
  border: 2px solid ${styleLibrary.containerBorderColor};

  /* These before and after styles are to draw the arrow/pointer under the box */
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    left: 50%;
    margin-left: -12px;
    margin-top: -2px;
  }

  &:after {
    margin-left: -9px;
    border-color: #fff transparent transparent transparent;
    border-width: 10px;
  }

  &:before {
    border-color: ${styleLibrary.containerBorderColor} transparent transparent
      transparent;
    border-width: 13px;
  }
`;

export { default as MapMarker } from './map-marker';
export { default as MapContainer } from './map-container';
export { default as InfoWindow } from './info-window';
