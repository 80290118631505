/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';

const Overlay = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center'
})`
  position: absolute;
  background-color: ${styleLibrary.containerBg};
  height: calc(100% - 105px);
  width: calc(100% - 40px);
  bottom: 1px;
  left: 20px;
  z-index: 500;
`;

const Wrapper = styled.div.attrs({
  className: 'text-center'
})`
  font-size: ${styleLibrary.fontSizes.mobileTitle}px;
  color: ${styleLibrary.darkText};
`;

const MessageOverlay = ({ children, style }) => (
  <Overlay style={style}>
    <Wrapper>{children}</Wrapper>
  </Overlay>
);

MessageOverlay.propTypes = {
  children: PropTypes.any,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  )
};

export default MessageOverlay;
