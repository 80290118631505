/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { commonPlotProps, styleLibrary } from 'app/constants';
import { defaultDateSelection } from 'app/components/filters/constants';
import DateRangeFilter from 'app/components/filters/components/date-range-filter';
import {
  dateRangeFilterSelector
} from 'app/redux/filters';
import {
  accClientsTrendFetchStateSelector,
  accClientsTrendSelectorTransform,
  fetchAccClientsTrend
} from 'app/redux/network';
import { fetchStatePropTypes } from 'app/redux/utils';
import { formatApiDateTime } from 'app/utils';
import { isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  CartesianGrid,
  Legend,
  Area,
  AreaChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import {
  createXAxisTicks,
  formatDataPoints
} from 'app/modules/properties/components/utils';

const plotKeyLabelMap = ['Consumed VLANs (%)'];

const initialState = {
  isDateOpen: false,
  dateRange: defaultDateSelection
};

export class VlanConsumptionTrend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenPlotMap: {},
      ...initialState,
      dateRange: props.dateRangeFilter || initialState.dateRange
    };
  }

  componentDidMount = () => {
    const { fetchAccClientsTrend, zoneName } = this.props;
    const { dateRange } = this.state;
    fetchAccClientsTrend({ zone: zoneName, dateRange });
  };

  componentDidUpdate = prevProps => {
    const {
      dateRangeFilter,
      fetchAccClientsTrend,
      zoneName
    } = this.props;
  
    const { zoneName: prevZoneName } = prevProps;
    const propertyChanged = zoneName !== '' && zoneName !== prevZoneName;

    if (propertyChanged) {
      const dateRange = dateRangeFilter || initialState.dateRange;
      this.setState({ ...initialState, dateRange }, () =>
        fetchAccClientsTrend({ zone: zoneName, dateRange })
      );
    }
  };

  isActivePlot = plotKey =>
    this.state.hiddenPlotMap[plotKey] ? 'blank' : plotKey;

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  renderVlanConsumptionTrend = () => {
    const {
      aleAccClientsTrend: { data = [], info = {} }
    } = this.props;

    const { dateRange } = this.state;

    const dataToRender = formatDataPoints(
      data,
      info,
      'label',
      'YYYY-MM-DD HH:mm'
    );
    const xAxisTicks = createXAxisTicks(
      data,
      dateRange,
      info,
      'label',
      'YYYY-MM-DD HH:mm'
    );
    return (
      <ChartFlexContainer width="99%" height={300}>
        <AreaChart data={dataToRender} margin={{ ...chartMargins, left: 15 }}>
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#758BFD" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#758BFD" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="label"
            label={{
              value: `Time (${info})`,
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            interval="preserveStartEnd"
            tick={tick}
            ticks={xAxisTicks}
            tickFormatter={t => formatApiDateTime(t, dateRange, info)}
            scale="time"
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            yAxisId="vlanReportsTrend"
            label={{
              value: 'Percent',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            allowDecimals={false}
            interval="preserveStartEnd"
          />
          <Tooltip
            content={
              <MultiLineTooltip
                labelFormatter={label =>
                  formatApiDateTime(label, dateRange, info, true)
                }
                keys={plotKeyLabelMap}
                units={['']}
                showAtSign={true}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
          />
          <Legend
            content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
            inactivePlots={this.state.hiddenPlotMap}
            verticalAlign="top"
          />
          {plotKeyLabelMap.map((key, index) => (
            <Area
              key={`vlanReportsTrend-${key}`}
              yAxisId="vlanReportsTrend"
              dataKey={this.isActivePlot(key)}
              name={key}
              stroke={styleLibrary.vlanConsumptionTrendColors[index]}
              {...commonPlotProps}
              fill="url(#color)"
            />
          ))}
        </AreaChart>
      </ChartFlexContainer>
    );
  };

  handleDateChanged = dateRange => {
    const { fetchAccClientsTrend, zoneName } = this.props;
    this.setState({ ...initialState, dateRange }, () => {
      fetchAccClientsTrend({
        zone: zoneName,
        dateRange
      });
    });
  };

  renderDropdowns = () => (
    <div className="d-flex justify-content-end align-items-center mx-2">
      <div className="d-flex align-items-center">
        <DateRangeFilter
          dateRangeFilter={this.state.dateRange}
          isDropDownOpen={this.state.isDateOpen}
          updateDateRangeFilter={this.handleDateChanged}
          updateIsOpen={isDateOpen => this.setState({ isDateOpen })}
          buttonStyle={{
            backgroundColor: styleLibrary.containerBg,
            border: '1px solid #ced4da'
          }}
        />
      </div>
    </div>
  );

  render = () => {
    const {
      fetchState,
      aleAccClientsTrend: { data = [] },
      fetchAccClientsTrend,
      zoneName
    } = this.props;
    const { dateRange } = this.state;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <Fragment>
        {this.renderDropdowns()}
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() => fetchAccClientsTrend({ zone: zoneName, dateRange })}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              message="No data available"
              style={{ background: 'none', position: 'relative' }}
            />
          ) : (
            complete && hasData && this.renderVlanConsumptionTrend()
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

VlanConsumptionTrend.propTypes = {
  dateRangeFilter: PropTypes.object,
  fetchAccClientsTrend: PropTypes.func,
  aleAccClientsTrend: PropTypes.object,
  fetchState: fetchStatePropTypes,
  zoneName: PropTypes.any
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  accClientsTrendFetchStateSelector,
  accClientsTrendSelectorTransform,
  (
    dateRangeFilter,
    fetchState,
    aleAccClientsTrend
  ) => ({
    dateRangeFilter,
    fetchState,
    aleAccClientsTrend
  })
);

const mapDispatchToProps = { fetchAccClientsTrend };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VlanConsumptionTrend);
