/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

export const HierarchyFilterTypes = [
  { value: '', text: '-- Filter by Specific --' },
  { value: 'customer', text: 'Customer' },
  { value: 'subcustomer', text: 'Sub-customer' },
  { value: 'region', text: 'Region' },
  { value: 'state', text: 'State' },
  { value: 'city', text: 'City' },
  { value: 'vertical', text: 'Vertical' },
  { value: 'zonename', text: 'Property' },
  { value: 'wlan', text: 'SSID' }
];

export const simplePayloadAPIs = [
  '/api/network/getServiceProviderDetails',
  '/api/session/getSessionDurationByAP',
  '/api/network/getSPBandwidthBusyHour',
  '/api/network/getSPClientBandwidthDetails',
  '/api/network/getSPClientBandwidthInfo',
  '/api/session/getSPClientSessionDetails',
  '/api/device/getSPDeviceTypes',
  '/api/device/getSPPoESwitches',
  '/api/subscriber/getSPSubscriberCategoryCount',
  '/api/subscriber/getSPSubscriberTypeCount',
  '/api/network/getSPThroughputByAP',
  '/api/network/getSPUsageByAP'
];
