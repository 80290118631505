/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { NoDataToShow } from 'app/components/elements';
import { dateRangeNames } from 'app/components/filters/constants';
import {
  ExportCsvIcon,
  FaIcon,
  LoadingIconPlaceholder,
  StyledExportButton
} from 'app/components/icons';
import { StatefulTable } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import { MuiTableOptions, MuiTableTheme, styleLibrary } from 'app/constants';
import {
  dateRangeFilterSelector,
  vertSSIDFilterSelector
} from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  downloadRogueSummary,
  fetchRogueSummary,
  rogueSummaryFetchStateSelector,
  rogueSummaryJSONSelector,
  rogueSummarySelector,
  updateRogueSummaryJSON
} from 'app/redux/rogue';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  cloneDeep,
  filter,
  includes,
  isArray,
  isEmpty,
  isString,
  map,
  merge,
  some,
  split,
  toLower,
  toString,
  unset
} from 'lodash';
import moment from 'moment';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

const CommaSeparatedValuesBodyRenderer = values => {
  if (isEmpty(values) || !isString(values) || !includes(values, ',')) {
    return values;
  }
  return map(split(values, ','), (v, i) => (
    <Fragment key={`${v}-${i}`}>
      {i === 0 ? null : <br />}
      <span>{v}</span>
    </Fragment>
  ));
};

class RogueSummaryTable extends Component {
  constructor(props) {
    super(props);

    this.state = { searchValue: '', isParsing: false };
  }

  componentDidUpdate = prevProps => {
    const {
      rogueSummaryFetchState: { pending: prevPending }
    } = prevProps;
    const {
      rogueSummaryFetchState: { pending, complete },
      rogueSummary
    } = this.props;

    if (prevPending && !pending && complete && rogueSummary instanceof Blob) {
      this.convertCSVToJSON(rogueSummary);
    }
  };

  transformData = data => {
    if (!isArray(data) || isEmpty(data)) {
      return [];
    }

    const {
      dateRangeFilter: { start, end, label }
    } = this.props;

    const isDateRange24Hours =
      label === dateRangeNames.LAST_24_HOURS ||
      moment(start).isSame(moment(end), 'day');

    if (!isDateRange24Hours) {
      data.forEach(d => {
        d['timestamp'] = moment(d['timestamp']).format('YYYY-MM-DD');
      });
    }

    return data;
  };

  convertCSVToJSON = data => {
    const file = new File([data], 'rogue-summary.csv');
    this.setState({ isParsing: true });
    Papa.parse(file, {
      header: true,
      worker: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: results => {
        this.props.updateRogueSummaryJSON(this.transformData(results.data));
        this.setState({ isParsing: false });
      },
      error: () => {
        this.props.updateRogueSummaryJSON([]);
        this.setState({ isParsing: false });
      }
    });
  };

  filterBySearch = data =>
    isEmpty(this.state.searchValue)
      ? data
      : filter(data, row =>
        some(row, value =>
          includes(toLower(toString(value)), toLower(this.state.searchValue))
        )
      );

  getRogueSummaryTableColumns = () => [
    {
      name: 'timestamp',
      label: 'Date/Time',
      options: {
        sortDirection: 'desc'
      }
    },
    {
      name: 'roguemac',
      label: 'Rogue MAC'
    },
    {
      name: 'manufacturer',
      label: 'Manufacturer'
    },
    {
      name: 'ssid',
      label: 'SSID'
    },
    {
      name: 'radio',
      label: 'Radio'
    },
    {
      name: 'channel',
      label: 'Channel'
    },
    {
      name: 'roguetype',
      label: 'Rogue Type',
      options: {
        customBodyRender: CommaSeparatedValuesBodyRenderer
      }
    },
    {
      name: 'roguerulename',
      label: 'Rogue Rule',
      options: {
        customBodyRender: CommaSeparatedValuesBodyRenderer
      }
    },
    {
      name: 'roguepolicyname',
      label: 'Rogue Policy',
      options: {
        customBodyRender: CommaSeparatedValuesBodyRenderer
      }
    },
    {
      name: 'apmacconnectedcount',
      label: 'Unique Rogue AP Count'
    },
    {
      name: 'apmac',
      label: 'Detecting AP MAC',
      options: {
        display: false,
        customBodyRender: CommaSeparatedValuesBodyRenderer
      }
    },
    {
      name: 'apname',
      label: 'AP Names',
      options: {
        customBodyRender: CommaSeparatedValuesBodyRenderer
      }
    },
    {
      name: 'aplocation',
      label: 'AP Location',
      options: {
        customBodyRender: CommaSeparatedValuesBodyRenderer
      }
    }
  ];

  renderHeaderAndActions = renderActions => (
    <div className="d-flex justify-content-between align-items-center m-2">
      <h4 className="d-flex align-items-center mb-0">Rogue Summary</h4>
      {renderActions && (
        <div className="d-flex align-items-center">
          <FaIcon icon="search" classes={['mr-2']} />
          <label className="sr-only">Search</label>
          <input
            className="p-1"
            id="rogue-summary-search"
            onChange={e => this.setState({ searchValue: e.target.value })}
            placeholder="Search..."
            style={{ width: 250 }}
            type="text"
            value={this.state.searchValue}
          />
          <StyledExportButton
            onClick={() =>
              this.props.downloadRogueSummary({
                columns: this.getRogueSummaryTableColumns()
              })
            }
            title="Generate CSV file containing the Rogue Summary table"
          >
            <ExportCsvIcon className="ml-3" />
          </StyledExportButton>
        </div>
      )}
    </div>
  );

  render = () => {
    const {
      fetchRogueSummary,
      rogueSummary,
      rogueSummaryJSON,
      rogueSummaryFetchState
    } = this.props;
    const { isParsing } = this.state;

    const { pending, complete } = rogueSummaryFetchState;
    const hasData = rogueSummary instanceof Blob && !isEmpty(rogueSummaryJSON);
    const noDataToShow = !pending && complete && !hasData;

    let rogueTableTheme = cloneDeep(MuiTableTheme);
    unset(rogueTableTheme, 'typography.fontSize');
    rogueTableTheme = merge({}, rogueTableTheme, {
      overrides: {
        MUIDataTableBodyRow: {
          root: {
            cursor: 'default'
          }
        },
        MuiTableCell: {
          root: {
            padding: '0 10px'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            fontSize: styleLibrary.fontSizes.smallBody
          }
        }
      }
    });

    return (
      <Fragment>
        {this.renderHeaderAndActions(complete && hasData)}
        <FailedFetchStateHandler
          fetchState={rogueSummaryFetchState}
          retry={fetchRogueSummary}
        >
          {pending || isParsing ? (
            <LoadingIconPlaceholder position="relative" />
          ) : !isParsing && noDataToShow ? (
            <NoDataToShow
              message="No data available."
              style={{ background: 'none', position: 'relative' }}
            />
          ) : (
            complete &&
            hasData && (
              <Fragment>
                {this.renderActions(complete && hasData)}
                <MuiThemeProvider theme={createMuiTheme(rogueTableTheme)}>
                  <StatefulTable
                    columns={this.getRogueSummaryTableColumns()}
                    data={this.filterBySearch(rogueSummaryJSON)}
                    options={{ ...MuiTableOptions }}
                    tableKey="rogue-summary-table"
                  />
                </MuiThemeProvider>
              </Fragment>
            )
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

RogueSummaryTable.propTypes = {
  dateRangeFilter: PropTypes.object,
  selectedPath: PropTypes.object,
  vertSSIDFilter: PropTypes.object,
  fetchRogueSummary: PropTypes.func,
  rogueSummary: PropTypes.object,
  rogueSummaryJSON: PropTypes.array,
  rogueSummaryFetchState: fetchStatePropTypes,
  updateRogueSummaryJSON: PropTypes.func,
  downloadRogueSummary: PropTypes.func
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  selectedPathSelector,
  vertSSIDFilterSelector,
  rogueSummarySelector,
  rogueSummaryJSONSelector,
  rogueSummaryFetchStateSelector,
  (
    dateRangeFilter,
    selectedPath,
    vertSSIDFilter,
    rogueSummary,
    rogueSummaryJSON,
    rogueSummaryFetchState
  ) => ({
    dateRangeFilter,
    selectedPath,
    vertSSIDFilter,
    rogueSummary,
    rogueSummaryJSON,
    rogueSummaryFetchState
  })
);

const mapDispatchToProps = {
  fetchRogueSummary,
  updateRogueSummaryJSON,
  downloadRogueSummary
};

export default connect(mapStateToProps, mapDispatchToProps)(RogueSummaryTable);
