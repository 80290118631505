/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { styleLibrary } from 'app/constants';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const Card = styled.div.attrs(props => ({
  className: classNames(
    'card mx-1 rounded border-0 shadow text-center',
    props.color === styleLibrary.alarms.avg ? 'text-dark' : 'text-white'
  )
}))`
  background-color: ${props => props.color};
`;

const CardBody = styled.div.attrs({
  className: 'card-body p-1'
})``;

const CardTitle = styled.span.attrs({
  className: 'card-title mb-0 h2'
})``;

const CardText = styled.p.attrs({
  className: 'card-text'
})``;

export class AlarmCard extends Component {
  render = () => {
    const { name, count, color } = this.props;
    return (
      <Card color={color}>
        <CardBody>
          <CardTitle>{count}</CardTitle>
          <CardText>{name}</CardText>
        </CardBody>
      </Card>
    );
  };
}

AlarmCard.defaultProps = {
  name: '',
  count: 0,
  color: styleLibrary.alarms.default
};

AlarmCard.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number,
  color: PropTypes.string
};

export default AlarmCard;
