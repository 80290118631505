/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { types as appTypes } from 'app/redux/app';
import {
  createAsyncTypes,
  createFetchStatePayloadCreator,
  createTypes,
  pendingFetchState,
  updateFetchStateReducer
} from 'app/redux/utils';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import createFetchIncidentActivitySagas from './fetch-incident-activity-saga';
import createFetchIncidentDetailsSagas from './fetch-incident-details-saga';

export const nameSpace = 'incidents';

export const types = createTypes(
  [
    ...createAsyncTypes('fetchIncidentDetails'),
    ...createAsyncTypes('fetchIncidentActivity'),
    ...createAsyncTypes('fetchSfdcCaseDetails'),
    ...createAsyncTypes('fetchSfdcActivity'),
    ...createAsyncTypes('fetchSfdcComments'),
    'updateIncidentDetailsFetchState',
    'updateIncidentActivityFetchState',
    'updateSfdcCaseDetailsFetchState',
    'updateSfdcActivityFetchState',
    'updateSfdcCommentsFetchState',
  ],
  nameSpace
);

export const sagas = [
  ...createFetchIncidentDetailsSagas(types),
  ...createFetchIncidentActivitySagas(types)
];

export const fetchIncidentDetails = createAction(types.fetchIncidentDetails);
export const fetchIncidentDetailsComplete = createAction(
  types.fetchIncidentDetailsComplete
);
export const fetchIncidentDetailsError = createAction(
  types.fetchIncidentDetailsError
);
export const updateIncidentDetailsFetchState = createAction(
  types.updateIncidentDetailsFetchState,
  createFetchStatePayloadCreator('incidentDetails')
);

export const fetchSfdcCaseDetails = createAction(types.fetchSfdcCaseDetails);
export const fetchSfdcCaseDetailsComplete = createAction(
  types.fetchSfdcCaseDetailsComplete
);
export const fetchSfdcCaseDetailsError = createAction(
  types.fetchSfdcCaseDetailsError
);
export const updateSfdcCaseDetailsFetchState = createAction(
  types.updateSfdcCaseDetailsFetchState,
  createFetchStatePayloadCreator('sfdcCaseDetails')
);

export const fetchIncidentActivity = createAction(types.fetchIncidentActivity);
export const fetchIncidentActivityComplete = createAction(
  types.fetchIncidentActivityComplete
);
export const fetchIncidentActivityError = createAction(
  types.fetchIncidentActivityError
);
export const updateIncidentActivityFetchState = createAction(
  types.updateIncidentActivityFetchState,
  createFetchStatePayloadCreator('incidentActivity')
);

export const fetchSfdcActivity = createAction(types.fetchSfdcActivity);
export const fetchSfdcActivityComplete = createAction(
  types.fetchSfdcActivityComplete
);
export const fetchSfdcActivityError = createAction(
  types.fetchSfdcActivityError
);
export const updateSfdcActivityFetchState = createAction(
  types.updateSfdcActivityFetchState,
  createFetchStatePayloadCreator('sfdcActivity')
);

export const fetchSfdcComments = createAction(types.fetchSfdcComments);
export const fetchSfdcCommentsComplete = createAction(
  types.fetchSfdcCommentsComplete
);
export const fetchSfdcCommentsError = createAction(
  types.fetchSfdcCommentsError
);
export const updateSfdcCommentsFetchState = createAction(
  types.updateSfdcCommentsFetchState,
  createFetchStatePayloadCreator('sfdcCommentsDetails')
);

const incidentDetailsSelector = state => {
  const {
    etiDetails = [],
    priorityList = [],
    caseTypeList = [],
    durationList = [],
    propertyList = []
  } = state[nameSpace].incidentDetails;
  return { etiDetails, priorityList, durationList, caseTypeList, propertyList };
};
export const incidentSummarySelector = createSelector(
  incidentDetailsSelector,
  ({ etiDetails }) => etiDetails
);
export const incidentPriorityDataSelector = createSelector(
  incidentDetailsSelector,
  ({ priorityList }) => priorityList
);
export const incidentCaseTypeDataSelector = createSelector(
  incidentDetailsSelector,
  ({ caseTypeList }) => caseTypeList
);
export const incidentByDurationDataSelector = createSelector(
  incidentDetailsSelector,
  ({ durationList }) => durationList
);
export const incidentByPropertyDataSelector = createSelector(
  incidentDetailsSelector,
  ({ propertyList }) => propertyList
);

export const incidentActivitySelector = state => {
  const { activityDetails = [] } = state[nameSpace].incidentActivity;
  return activityDetails;
};
export const sfdcActivitySelector = state => {
  const { caseActivityDetails = [] } = state[nameSpace].sfdcActivity;
  return caseActivityDetails;
};

const sfdcCaseDetailsSelector = state => {
  const {
    caseDetails = [],
    priorityList = [],
    caseTypeList = [],
    propertyList = []
  } = state[nameSpace].sfdcCaseDetails;
  return { caseDetails, priorityList, caseTypeList, propertyList };
};
export const sfdcSummarySelector = createSelector(
  sfdcCaseDetailsSelector,
  ({ caseDetails }) => caseDetails
);
export const sfdcPriorityDataSelector = createSelector(
  sfdcCaseDetailsSelector,
  ({ priorityList }) => priorityList
);
export const sfdcCaseTypeDataSelector = createSelector(
  sfdcCaseDetailsSelector,
  ({ caseTypeList }) => caseTypeList
);
export const sfdcByPropertyDataSelector = createSelector(
  sfdcCaseDetailsSelector,
  ({ propertyList }) => propertyList
);

export const sfdcCommentsDetailsSelector = state => {
  const { caseCommentDetails = [] } = state[nameSpace].sfdcCommentsDetails;
  return caseCommentDetails;
};

const fetchStatesSelector = state => state[nameSpace].fetchStates;
export const incidentDetailsFetchStateSelector = state =>
  fetchStatesSelector(state).incidentDetails;
export const sfdcCaseDetailsFetchStateSelector = state =>
  fetchStatesSelector(state).sfdcCaseDetails;
export const incidentActivityFetchStateSelector = state =>
  fetchStatesSelector(state).incidentActivity;
export const sfdcActivityFetchStateSelector = state =>
  fetchStatesSelector(state).sfdcActivity;
export const sfdcCommentsFetchStateSelector = state =>
  fetchStatesSelector(state).sfdcCommentsDetails;

const initialState = {
  fetchStates: {
    incidentDetails: pendingFetchState,
    incidentActivity: pendingFetchState,
    sfdcCaseDetails: pendingFetchState,
    sfdcActivity: pendingFetchState,
    sfdcCommentsDetails: pendingFetchState,
  },
  incidentDetails: {},
  incidentActivity: {},
  sfdcCaseDetails: {},
  sfdcActivity: {},
  sfdcCommentsDetails: {},
};

export const reducer = handleActions(
  {
    [types.fetchIncidentDetailsComplete]: (state, { payload }) => ({
      ...state,
      incidentDetails: payload
    }),

    [types.fetchIncidentActivityComplete]: (state, { payload }) => ({
      ...state,
      incidentActivity: payload
    }),

    [types.fetchSfdcCaseDetailsComplete]: (state, { payload }) => ({
      ...state,
      sfdcCaseDetails: payload
    }),
    [types.fetchSfdcActivityComplete]: (state, { payload }) => ({
      ...state,
      sfdcActivity: payload
    }),
    [types.fetchSfdcCommentsComplete]: (state, { payload }) => ({
      ...state,
      sfdcCommentsDetails: payload
    }),

    [types.updateIncidentDetailsFetchState]: updateFetchStateReducer,
    [types.updateIncidentActivityFetchState]: updateFetchStateReducer,
    [types.updateSfdcCaseDetailsFetchState]: updateFetchStateReducer,
    [types.updateSfdcActivityFetchState]: updateFetchStateReducer,
    [types.updateSfdcCommentsFetchState]: updateFetchStateReducer,

    [appTypes.appReset]: () => initialState
  },
  initialState
);
