/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { HR } from 'app/components/elements';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { createShowPanelSelector } from 'app/redux/app';
import { selectedPathSelector } from 'app/redux/hierarchy';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
// import InventoryAPsCount from './inventory-ap-count-trend';
// import InventoryFirewallCountTrend from './inventory-firewall-count-trend';
// import InventoryRouterCountTrend from './inventory-router-count-trend';
// import InventoryServerCountTrend from './inventory-server-count-trend';
// import InventorySwitchCountTrend from './inventory-switch-count-trend';
import PropertyCountTrend from './property-count-trend';
import InventoryModelTrend from './inventory-model-trend';
import InventoryCardSummary from './inventory-card-summary';

const PropertyInventoryTrends = ({
  // showInventorySwitchCount,
  // showInventoryAPsCount,
  // showInventoryServerCount,
  // showInventoryRouterCount,
  // showInventoryFirewallCount,
  showPropertyCount,
  showInventoryCards,
  showInventoryModelCount,
  selectedPath
}) => {
  const isShowPropertyCount =
    showPropertyCount &&
    (isSpecificNodeType(selectedPath, 'customer') ||
      isSpecificNodeType(selectedPath, 'vertical'));

  return (
    <Fragment>
      {showInventoryCards && <InventoryCardSummary />}
      {showInventoryCards && showInventoryModelCount && <HR />}
      {showInventoryModelCount && (
        <InventoryModelTrend excludeLast24Hours={true} />
      )}
      {showInventoryModelCount && isShowPropertyCount && <HR />}
      {isShowPropertyCount && <PropertyCountTrend excludeLast24Hours={true} />}
      {/* {isShowPropertyCount && showInventorySwitchCount && <HR />}
      {showInventorySwitchCount && (
        <InventorySwitchCountTrend isComponentTopmost={!showPropertyCount} />
      )}
      {showInventorySwitchCount && showInventoryAPsCount && <HR />}
      {showInventoryAPsCount && <InventoryAPsCount />}
      {showInventoryAPsCount && showInventoryServerCount && <HR />}
      {showInventoryServerCount && <InventoryServerCountTrend />}
      {showInventoryServerCount && showInventoryRouterCount && <HR />}
      {showInventoryRouterCount && <InventoryRouterCountTrend />}
      {showInventoryRouterCount && showInventoryFirewallCount && <HR />}
      {showInventoryFirewallCount && <InventoryFirewallCountTrend />} */}
    </Fragment>
  );
};

PropertyInventoryTrends.propTypes = {
  showInventorySwitchCount: PropTypes.bool,
  showInventoryAPsCount: PropTypes.bool,
  showInventoryServerCount: PropTypes.bool,
  showInventoryRouterCount: PropTypes.bool,
  showInventoryFirewallCount: PropTypes.bool,
  showPropertyCount: PropTypes.bool,
  showInventoryCards: PropTypes.bool,
  showInventoryModelCount: PropTypes.bool,
  selectedPath: PropTypes.object
};

const mapStateToProps = createSelector(
  selectedPathSelector,
  createShowPanelSelector('ReportsInventorySwitchTrend'),
  createShowPanelSelector('ReportsInventoryAPsTrend'),
  createShowPanelSelector('ReportsInventoryServerTrend'),
  createShowPanelSelector('ReportsInventoryRouterTrend'),
  createShowPanelSelector('ReportsInventoryFirewallTrend'),
  createShowPanelSelector('ReportsPropertyTrend'),
  createShowPanelSelector('ReportsInventoryCardsTrend'),
  createShowPanelSelector('ReportsInventoryModelTrend'),
  (
    selectedPath,
    showInventorySwitchCount,
    showInventoryAPsCount,
    showInventoryServerCount,
    showInventoryRouterCount,
    showInventoryFirewallCount,
    showPropertyCount,
    showInventoryCards,
    showInventoryModelCount
  ) => ({
    selectedPath,
    showInventorySwitchCount,
    showInventoryAPsCount,
    showInventoryServerCount,
    showInventoryRouterCount,
    showInventoryFirewallCount,
    showPropertyCount,
    showInventoryCards,
    showInventoryModelCount
  })
);

export default connect(mapStateToProps)(PropertyInventoryTrends);
