/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createApiBodySelector } from 'app/redux/hierarchy';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';
import { ajaxPost } from 'app/redux/util-sagas';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { updateUserGuideDownloadStatus } from './';

const url = '/api/network/getDocs';
const queryParams = { fileName: 'Network_Intelligence_User_Guide.pdf' };

export function* downloadUserGuideSaga() {
  yield put(updateUserGuideDownloadStatus({ inProgress: true, failed: false }));

  yield call(waitForHierarchySaga);

  const body = yield select(createApiBodySelector(url));

  const onTimeout = () => put(updateUserGuideDownloadStatus({ failed: true }));

  try {
    const { data } = yield ajaxPost(url, body, {
      params: queryParams,
      onTimeout
    });

    if (data && data.status === 'SUCCESS' && data.result) {
      // Convert the base64 encoded PDF data to a Blob
      const binary = yield atob(data.result);
      const arr = yield new Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        arr[i] = binary.charCodeAt(i);
      }
      const byteArray = yield new Uint8Array(arr);
      const pdfBlob = yield new Blob([byteArray], { type: 'application/pdf' });

      const saveFileAs = yield getContext('saveFileAs');
      yield call(saveFileAs, pdfBlob, queryParams.fileName);
    }

    yield put(updateUserGuideDownloadStatus({ failed: false }));
  } catch (err) {
    yield put(updateUserGuideDownloadStatus({ failed: true }));
  } finally {
    yield put(updateUserGuideDownloadStatus({ inProgress: false }));
  }
}

export default function createUserGuideSagas(types) {
  return [takeLatest(types.downloadUserGuide, downloadUserGuideSaga)];
}
