/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { NoDataToShow } from 'app/components/elements';
import { FaIcon, LoadingIconPlaceholder } from 'app/components/icons';
import { StatefulTable, TableExport } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  fetchRogueDetails,
  rogueDetailsFetchStateSelector,
  rogueApmacSummarySelector
} from 'app/redux/rogue';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  isArray,
  isEmpty,
  some,
  toString
} from 'lodash';

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  createRogueApmacSummaryExportDataDefinition,
  getRogueApmacSummaryTableColumns
} from '../utils';
import {
  customTableOptions,
  customMuiTableThemeWithCursor
} from 'app/modules/incidents/components/utils';

class RogueApDetailsTable extends Component {
  constructor(props) {
    super(props);

    this.state = { searchValue: '' };
  }

  filterBySearch = data => {
    const { searchValue } = this.state;

    const searchData = searchValue
      .toLowerCase()
      .trim()
      .replace(/:/g, '');

    return searchValue !== ''
      ? data.filter(row =>
        some(row, value => {
          value = toString(value);
          const valueString = value.toLowerCase().replace(/:/g, '');
          return (
            valueString === searchData || valueString.includes(searchData)
          );
        }
        )
      )
      : data;
  }

  renderActions = () => {
    const {
      rogueDetails: { data = [] }
    } = this.props;
    const { searchValue } = this.state;

    return (
      <div className="d-flex justify-content-between m-2">
        <span className="d-flex justify-content-start align-items-center">
          {/* <h3>AP Rogue Summary</h3> */}
        </span>

        <div className="d-flex justify-content-end align-items-center">
          <FaIcon icon="search" classes={['mr-2']} />
          <label className="sr-only">Search...</label>
          <input
            className="p-1"
            id="property-rogue-table-search"
            onChange={e => this.setState({ searchValue: e.target.value })}
            placeholder="Search..."
            style={{ width: 250 }}
            type="text"
            value={searchValue}
          />
          <TableExport
            hidePdfExport={true}
            exportName="Rogue Apmac Summary"
            exportDataDefinitions={createRogueApmacSummaryExportDataDefinition(
              data
            )}
          />
        </div>
      </div>
    );
  };

  render = () => {
    const {
      fetchRogueDetails,
      rogueDetails: { data = [] },
      fetchState,
      selectedPath
    } = this.props;

    const { pending, complete, failed } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    const columns = getRogueApmacSummaryTableColumns();
    return (
      <FailedFetchStateHandler
        fetchState={fetchState}
        retry={fetchRogueDetails}
      >
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow || failed ? (
          <NoDataToShow
            message={
              failed
                ? 'There has been a problem fetching the rogue ap summmary'
                : 'No data available for the rogue ap summmary'
            }
            style={{ background: 'none', position: 'relative' }}
          />
        ) : (
          <Fragment>
            {!pending && hasData && this.renderActions()}
            <div
              className={pending || noDataToShow ? 'fetch-state-pending' : ''}
            >
              {complete && (
                <MuiThemeProvider
                  theme={createMuiTheme(customMuiTableThemeWithCursor)}
                >
                  <StatefulTable
                    tableKey={`${selectedPath.id}-property-rogue`}
                    columns={columns}
                    data={this.filterBySearch(data)}
                    options={customTableOptions}
                  />
                </MuiThemeProvider>
              )}
            </div>
          </Fragment>
        )}
      </FailedFetchStateHandler>
    );
  };
}

RogueApDetailsTable.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  rogueDetails: PropTypes.object,
  fetchState: fetchStatePropTypes,
  fetchRogueDetails: PropTypes.func
};

const mapStateToProps = createSelector(
  selectedPathSelector,
  rogueApmacSummarySelector,
  rogueDetailsFetchStateSelector,
  (
    selectedPath,
    rogueDetails,
    fetchState
  ) => ({
    selectedPath,
    rogueDetails,
    fetchState
  })
);

const mapDispatchToProps = { fetchRogueDetails };

export default connect(mapStateToProps, mapDispatchToProps)(RogueApDetailsTable);
