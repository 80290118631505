/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { types as appTypes } from 'app/redux/app';
import { createTypes } from 'app/redux/utils';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import createDownloadAuditInfoSagas from './audit-info-saga';
import createModalSagas from './modal-sagas';
import createUserGuideSagas from './user-guide-saga';

export const nameSpace = 'ui';
const types = createTypes(
  [
    'openModal',
    'closeModal',

    'setTableState',

    'downloadAuditInfo',
    'setAuditInfoDownloadStatus',

    'downloadUserGuide',
    'updateUserGuideDownloadStatus'
  ],
  nameSpace
);

export const sagas = [
  ...createModalSagas(types),
  ...createDownloadAuditInfoSagas(types),
  ...createUserGuideSagas(types)
];

export const openModal = createAction(types.openModal);
export const closeModal = createAction(types.closeModal);
export const setTableState = createAction(types.setTableState);
export const downloadAuditInfo = createAction(types.downloadAuditInfo);
export const setAuditInfoDownloadStatus = createAction(
  types.setAuditInfoDownloadStatus
);

const modalOpenMapSelector = state => state[nameSpace].isModalOpenMap;
export const tableStatesSelector = state => state[nameSpace].tableStates;
export const createTableStateSelector = key => {
  return createSelector(tableStatesSelector, tableStates => tableStates[key]);
};

export const createIsModalOpenSelector = modalKey =>
  createSelector(
    modalOpenMapSelector,
    isModalOpenMap => !!isModalOpenMap[modalKey]
  );

export const auditInfoDownloadStatusSelector = state =>
  state[nameSpace].auditInfoDownloadStatus;

export const downloadUserGuide = createAction(types.downloadUserGuide);
export const updateUserGuideDownloadStatus = createAction(
  types.updateUserGuideDownloadStatus
);
export const userGuideDownloadStatusSelector = state =>
  state[nameSpace].userGuideDownloadStatus;

const initialState = {
  auditInfoDownloadStatus: { inProgress: false, failed: false },
  isModalOpenMap: {},
  tableStates: {},
  userGuideDownloadStatus: { inProgress: false, failed: false }
};

export const reducer = handleActions(
  {
    [types.openModal]: (state, { payload: modalKey }) => ({
      ...state,
      isModalOpenMap: {
        ...state.isModalOpenMap,
        [modalKey]: true
      }
    }),

    [types.closeModal]: (state, { payload: modalKey }) => ({
      ...state,
      isModalOpenMap: {
        ...state.isModalOpenMap,
        [modalKey]: false
      }
    }),

    [types.setTableState]: (state, { payload }) => {
      const { key, ...rest } = payload;

      return {
        ...state,
        tableStates: {
          ...state.tableStates,
          [key]: { ...rest }
        }
      };
    },

    [types.setAuditInfoDownloadStatus]: (state, { payload }) => ({
      ...state,
      auditInfoDownloadStatus: {
        ...state.auditInfoDownloadStatus,
        ...payload
      }
    }),

    [types.updateUserGuideDownloadStatus]: (state, { payload }) => ({
      ...state,
      userGuideDownloadStatus: {
        ...state.userGuideDownloadStatus,
        ...payload
      }
    }),

    [appTypes.appReset]: () => initialState
  },
  initialState
);
