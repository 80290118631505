/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { put, takeEvery } from 'redux-saga/effects';

import { chartModeSuffix } from 'app/constants';
import {
  getItemsFromStorageByKey,
  setItemToStorage
} from 'app/redux/util-sagas';
import { chartModesFoundInStorage } from 'app/redux/app';

function* chartDisplayModeSaga({ payload }) {
  const { key, mode } = payload;

  yield setItemToStorage(key, mode);
}

export function* getChartDisplayModesSaga() {
  const chartModes = yield getItemsFromStorageByKey(chartModeSuffix);
  if (Object.keys(chartModes).length > 0) {
    yield put(chartModesFoundInStorage(chartModes));
  }
}

export default function createChartDisplayModeSagas(types) {
  return [takeEvery(types.updateChartDisplayMode, chartDisplayModeSaga)];
}
