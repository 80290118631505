/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React, { Fragment } from 'react';
import AleSessionDetails from './ale-session-details';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { createShowPanelSelector } from 'app/redux/app';

export const PropertyAleSession = ({ showAleSessionsTable }) => (
  <Fragment>
    <span style={{ marginTop: '10px' }}></span>
    {showAleSessionsTable && <AleSessionDetails />}
  </Fragment>
);

PropertyAleSession.propTypes = {
  showAleSessionsTable: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('PropertyAleSessionDetails'),
  showAleSessionsTable => ({ showAleSessionsTable })
);

export default connect(mapStateToProps)(PropertyAleSession);
