/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { styleLibrary } from 'app/constants';

export const DisplayRatio = ({ left, right }) => (
  <Fragment>
    <span
      style={{
        color: styleLibrary.sessionsColor
      }}
    >
      {left}
    </span>{' '}
    <span
      style={{
        color: styleLibrary.darkText
      }}
    >
      :
    </span>{' '}
    <span
      style={{
        color: styleLibrary.clientsColor
      }}
    >
      {right}
    </span>
  </Fragment>
);

DisplayRatio.propTypes = { left: PropTypes.node, right: PropTypes.node };
