/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { NoDataToShow } from 'app/components/elements';
import DateRangeFilter from 'app/components/filters/components/date-range-filter';
import SsidWlanFilter from 'app/components/filters/components/ssid-wlan-filter';
import { defaultDateSelection } from 'app/components/filters/constants';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { bandwidthUnits, commonPlotProps, styleLibrary } from 'app/constants';
import {
  fetchPropertyClientBandwidthTrend,
  propertyClientBandwidthTrendFetchStateSelector,
  propertyClientBandwidthTrendSelector
} from 'app/redux/clients';
import { dateRangeFilterSelector } from 'app/redux/filters';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  createFormatAxisValue,
  formatApiDateTime,
  getScaledDisplayValue
} from 'app/utils';
import { get, isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import { createXAxisTicks, formatDataPoints } from '../utils';

const initialState = {
  isDateOpen: false,
  dateRange: defaultDateSelection,
  wlan: 'all'
};

export class ClientBandwidthTrendChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hiddenPlotMap: {},
      ...initialState,
      dateRange: props.dateRangeFilter || initialState.dateRange
    };
  }

  componentDidMount = () => {
    const { fetchPropertyClientBandwidthTrend, clientMAC } = this.props;
    const { dateRange, wlan } = this.state;
    fetchPropertyClientBandwidthTrend({ clientMAC, dateRange, wlanName: wlan });
  };

  componentDidUpdate = prevProps => {
    const { clientMAC } = this.props;
    const { clientMAC: prevClientMAC } = prevProps;
    const clientChanged = clientMAC !== '' && clientMAC !== prevClientMAC;

    if (clientChanged) {
      const { fetchPropertyClientBandwidthTrend, dateRangeFilter } = this.props;
      const dateRange = dateRangeFilter || initialState.dateRange;
      this.setState({ ...initialState, dateRange }, () =>
        fetchPropertyClientBandwidthTrend({
          clientMAC,
          dateRange,
          wlanName: initialState.wlan
        })
      );
    }
  };

  isActivePlot = plotKey => {
    return this.state.hiddenPlotMap[plotKey]
      ? 'blank'
      : plotKey.replace(' ', '').toLowerCase();
  };

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  renderBandwidthChart = () => {
    const {
      propertyClientBandwidthTrend: { data = [], info = {} }
    } = this.props;
    const { dateRange } = this.state;

    const timezone = get(info, 'timezone', 'UTC');
    const dataToRender = formatDataPoints(
      data,
      timezone
      // undefined,
      // 'DD-MMM-YYYY HH:mm'
    );
    const xAxisTicks = createXAxisTicks(
      data,
      dateRange,
      timezone
      // undefined,
      // 'DD-MMM-YYYY HH:mm'
    );
    const formatAxisValue = createFormatAxisValue();

    return (
      <ChartFlexContainer width="99%" height={300}>
        <ComposedChart
          data={dataToRender}
          margin={{ ...chartMargins, left: 15 }}
        >
          <CartesianGrid vertical={false} />
          <Legend
            content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
            inactivePlots={this.state.hiddenPlotMap}
            verticalAlign="top"
          />
          <XAxis
            dataKey="label"
            label={{
              value: `Time (${timezone})`,
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            interval="preserveStartEnd"
            tick={tick}
            ticks={xAxisTicks}
            tickFormatter={t => formatApiDateTime(t, dateRange, timezone)}
            scale="time"
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            yAxisId="clientBandwidthTrend"
            label={{
              value: 'Traffic',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body,
              fill: styleLibrary.trafficColor
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            tickFormatter={value =>
              formatAxisValue(
                value,
                (val, dec) =>
                  getScaledDisplayValue(val, bandwidthUnits, null, dec),
                1
              )
            }
          />
          <Area
            dataKey={this.isActivePlot('Download')}
            name="Download"
            stroke={styleLibrary.downloadColor}
            fill={styleLibrary.downloadColor}
            yAxisId="clientBandwidthTrend"
            {...commonPlotProps}
          />
          <Area
            dataKey={this.isActivePlot('Upload')}
            name="Upload"
            stroke={styleLibrary.uploadColor}
            fill={styleLibrary.uploadColor}
            yAxisId="clientBandwidthTrend"
            {...commonPlotProps}
          />
          <Line
            dataKey={this.isActivePlot('Total')}
            name="Total"
            stroke="rgb(31, 119, 180)"
            yAxisId="clientBandwidthTrend"
            {...commonPlotProps}
          />
          <Tooltip
            content={
              <MultiLineTooltip
                labelFormatter={label =>
                  formatApiDateTime(label, dateRange, timezone, true)
                }
                keys={['download', 'upload', 'total']}
                units={bandwidthUnits}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
          />
        </ComposedChart>
      </ChartFlexContainer>
    );
  };

  handleDateChanged = dateRange => {
    const { fetchPropertyClientBandwidthTrend, clientMAC } = this.props;
    const { wlan } = this.state;
    this.setState({ ...initialState, dateRange, wlan }, () =>
      fetchPropertyClientBandwidthTrend({
        clientMAC,
        dateRange,
        wlanName: wlan
      })
    );
  };

  handleWlanChanged = selectedWlan => {
    const { fetchPropertyClientBandwidthTrend, clientMAC } = this.props;
    const { dateRange } = this.state;
    this.setState({ ...initialState, dateRange, wlan: selectedWlan }, () =>
      fetchPropertyClientBandwidthTrend({
        clientMAC,
        dateRange,
        wlanName: selectedWlan
      })
    );
  };

  renderDropdowns = () => (
    <div className="d-flex justify-content-end align-items-center mx-2">
      <div className="d-flex align-items-center mr-2">
        <SsidWlanFilter
          wlan={this.state.wlan}
          handleWlanChanged={this.handleWlanChanged}
        />
      </div>
      <div className="d-flex align-items-center">
        <DateRangeFilter
          dateRangeFilter={this.state.dateRange}
          isDropDownOpen={this.state.isDateOpen}
          updateDateRangeFilter={this.handleDateChanged}
          updateIsOpen={isDateOpen => this.setState({ isDateOpen })}
          buttonStyle={{
            backgroundColor: styleLibrary.containerBg,
            border: '1px solid #ced4da'
          }}
        />
      </div>
    </div>
  );

  render = () => {
    const {
      fetchState,
      propertyClientBandwidthTrend: { data = [] },
      fetchPropertyClientBandwidthTrend,
      clientMAC
    } = this.props;
    const { dateRange, wlan } = this.state;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <Fragment>
        {this.renderDropdowns()}
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() =>
            fetchPropertyClientBandwidthTrend({
              clientMAC,
              dateRange,
              wlanName: wlan
            })
          }
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message="No data available"
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            complete && hasData && this.renderBandwidthChart()
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

ClientBandwidthTrendChart.propTypes = {
  dateRangeFilter: PropTypes.object,
  fetchPropertyClientBandwidthTrend: PropTypes.func,
  propertyClientBandwidthTrend: PropTypes.object,
  fetchState: fetchStatePropTypes,
  clientMAC: PropTypes.string
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  propertyClientBandwidthTrendFetchStateSelector,
  propertyClientBandwidthTrendSelector,
  (dateRangeFilter, fetchState, propertyClientBandwidthTrend) => ({
    dateRangeFilter,
    fetchState,
    propertyClientBandwidthTrend
  })
);

const mapDispatchToProps = { fetchPropertyClientBandwidthTrend };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientBandwidthTrendChart);
