/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { NoDataToShow } from 'app/components/elements';
import { FaIcon, LoadingIconPlaceholder } from 'app/components/icons';
import { StatefulTable, TableExport } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  styleLibrary,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import { customTableOptions } from 'app/modules/incidents/components/utils';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  fetchRogueDetailsForRogueMAC,
  rogueDetailsForRogueMACFetchStateSelector,
  rogueDetailsForRogueMACSelector
} from 'app/redux/rogue';
import { fetchStatePropTypes } from 'app/redux/utils';
import classNames from 'classnames';
import {
  filter,
  includes,
  isArray,
  isEmpty,
  isEqual,
  merge,
  some,
  toLower,
  toString,
  uniqBy
} from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import {
  createRogueDetailsForRogueMACExportDataDefinition,
  customMuiTableTheme,
  getRogueTimelineAPTableColumns,
  getRogueTimelineDetailsTableColumns
} from '../utils';

const TitleBar = styled.div.attrs({
  className: 'd-flex justify-content-between align-items-center m-2'
})``;

const Title = styled.span.attrs({
  className: 'd-flex justify-content-start align-items-center'
})`
  font-size: ${styleLibrary.fontSizes.secondaryTitle}px;
`;

const TableWrapper = styled.div.attrs({ className: 'row no-gutters' })``;

const ApTableWrapper = styled.div.attrs({
  className: 'col-6 col-xl'
})`
  border-right: 1px solid ${styleLibrary.containerBorderColor};
`;

const TimelineTableWrapper = styled.div.attrs({ className: 'col-6 col-xl' })``;

const TableHelperTitle = styled.span.attrs({
  className: 'd-block text-center m-2'
})``;

export class RogueTimelineTable extends Component {
  constructor(props) {
    super(props);
    this.state = { searchValue: '', selectedAP: '' };
  }

  componentDidMount = () => {
    const { fetchRogueDetailsForRogueMAC, rogueMAC } = this.props;
    fetchRogueDetailsForRogueMAC({ rogueMAC });
  };

  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilterLabel,
      fetchRogueDetailsForRogueMAC,
      rogueMAC
    } = this.props;
    const {
      selectedPath: prevPath,
      dateRangeFilterLabel: prevDateRange,
      rogueMAC: prevRogueMAC
    } = prevProps;

    const propertyChanged = !isEqual(prevPath.id, selectedPath.id);
    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilterLabel);
    const rogueMACChanged = !isEqual(prevRogueMAC, rogueMAC);

    if (propertyChanged || dateRangeChanged || rogueMACChanged) {
      this.setState({ selectedAP: '' }, () =>
        fetchRogueDetailsForRogueMAC({ rogueMAC })
      );
    }
  };

  filterBySearch = data =>
    isEmpty(this.state.searchValue)
      ? data
      : filter(data, row =>
        some(row, value =>
          includes(toLower(toString(value)), toLower(this.state.searchValue))
        )
      );

  filterByAp = data =>
    isEmpty(this.state.selectedAP)
      ? data
      : filter(data, ['apname', this.state.selectedAP]);

  handleTableRowClick = row => {
    const newSelectedAP = row[0];
    this.setState(({ selectedAP }) => ({
      selectedAP: newSelectedAP === selectedAP ? '' : newSelectedAP
    }));
  };

  setRowProps = row => {
    const { selectedAP } = this.state;
    const newSelectedAP = row[0];
    return {
      className:
        selectedAP !== '' &&
        classNames(
          newSelectedAP === selectedAP ? 'row-selected' : 'row-unselected'
        )
    };
  };

  renderActions = () => {
    const {
      rogueDetails: { data = [], info },
      rogueMAC
    } = this.props;
    const { searchValue } = this.state;

    return (
      <div className="d-flex justify-content-end align-items-center">
        <FaIcon icon="search" classes={['mr-2']} />
        <label className="sr-only">Search...</label>
        <input
          className="p-1"
          id="property-rogue-mac-details-table-search"
          onChange={e => this.setState({ searchValue: e.target.value })}
          placeholder="Search..."
          style={{ width: 250 }}
          type="text"
          value={searchValue}
        />
        <TableExport
          hidePdfExport={true}
          exportName={`Rogue timeline details For Rogue MAC ${rogueMAC.replaceAll(
            ':',
            ''
          )}`}
          exportDataDefinitions={createRogueDetailsForRogueMACExportDataDefinition(
            data,
            info
          )}
        />
      </div>
    );
  };

  render = () => {
    const {
      fetchRogueDetailsForRogueMAC,
      rogueDetails: { data = [], info },
      fetchState,
      selectedPath,
      rogueMAC
    } = this.props;
    const { selectedAP } = this.state;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    const tableOptions = {
      ...customTableOptions,
      onRowClick: this.handleTableRowClick,
      search: false,
      viewColumns: false,
      setRowProps: this.setRowProps
    };

    const newMuiTableThemeWithCursor = merge(
      {},
      customMuiTableTheme,
      customMuiTableThemeWithDefualtCursor
    );

    return (
      <Fragment>
        <TitleBar>
          <Title>
            Timeline events for Rogue MAC&nbsp;<b>{rogueMAC}</b>
          </Title>
          {complete && hasData && this.renderActions()}
        </TitleBar>
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() => fetchRogueDetailsForRogueMAC({ rogueMAC })}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              message="No data available"
              style={{ background: 'none', position: 'relative' }}
            />
          ) : (
            complete &&
            hasData && (
              <TableWrapper>
                <ApTableWrapper>
                  <TableHelperTitle>
                    Click on any row to filter the rogue timeline events on the
                    adjoining table.
                  </TableHelperTitle>
                  <MuiThemeProvider theme={createMuiTheme(customMuiTableTheme)}>
                    <StatefulTable
                      tableKey={`${selectedPath.id}-property-rogue-timeline-ap`}
                      columns={getRogueTimelineAPTableColumns}
                      data={uniqBy(data, 'apname')}
                      options={tableOptions}
                    />
                  </MuiThemeProvider>
                </ApTableWrapper>
                <TimelineTableWrapper>
                  <TableHelperTitle>
                    Rogue timeline events
                    {selectedAP !== '' && (
                      <Fragment>
                        {' '}
                        for AP <b>{selectedAP}</b>
                      </Fragment>
                    )}
                  </TableHelperTitle>
                  <MuiThemeProvider
                    theme={createMuiTheme(newMuiTableThemeWithCursor)}
                  >
                    <StatefulTable
                      tableKey={`${selectedPath.id}-property-rogue-timeline-details`}
                      columns={getRogueTimelineDetailsTableColumns(info)}
                      data={this.filterBySearch(this.filterByAp(data))}
                      options={{
                        ...customTableOptions,
                        search: false,
                        viewColumns: false
                      }}
                    />
                  </MuiThemeProvider>
                </TimelineTableWrapper>
              </TableWrapper>
            )
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

RogueTimelineTable.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  rogueMAC: PropTypes.string,
  rogueDetails: PropTypes.object,
  fetchState: fetchStatePropTypes,
  fetchRogueDetailsForRogueMAC: PropTypes.func
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  rogueDetailsForRogueMACSelector,
  rogueDetailsForRogueMACFetchStateSelector,
  (dateRangeFilterLabel, selectedPath, rogueDetails, fetchState) => ({
    dateRangeFilterLabel,
    selectedPath,
    rogueDetails,
    fetchState
  })
);

const mapDispatchToProps = { fetchRogueDetailsForRogueMAC };

export default connect(mapStateToProps, mapDispatchToProps)(RogueTimelineTable);
