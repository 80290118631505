/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import DateRangeFilter from 'app/components/filters/components/date-range-filter';
import { FaIcon } from 'app/components/icons';
import { defaultDisplayDateFormat, styleLibrary } from 'app/constants';
import { getTimeUnit, getUniqueVerticals, getWlans } from 'app/utils';
import { filter, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { inProgressList } from '../../constants';
import {
  // GenerateReportExpandCollapse,
  // GenerateReportWrapper,
  ReportFilterWrapper
} from '../../utils';

class GenerateReport extends Component {
  constructor(props) {
    super(props);

    const { vertical, wlanName: wlan } = props.vertSSIDFilter;

    this.state = {
      dateOpen: false,
      label: 'This Month',
      start: moment()
        .date(1)
        .startOf('day')
        .toDate(),
      end: moment()
        .subtract(1, 'days')
        .endOf('day')
        .toDate(),
      unit: 'month',
      vertical,
      wlan
    };
  }

  componentDidUpdate = prevProps => {
    if (!isEqual(this.props.vertSSIDFilter, prevProps.vertSSIDFilter)) {
      const { vertical, wlanName: wlan } = this.props.vertSSIDFilter;
      this.setState({ vertical, wlan });
    }
  };

  generateReport = () => {
    const { handleGenerateReport, selectedPath } = this.props;
    const { start, end, unit, vertical, wlan } = this.state;
    handleGenerateReport({
      end,
      selectedPath,
      start,
      unit,
      vertical,
      wlan
    });
  };

  getReportDescription = () => {
    const { selectedPath } = this.props;
    const { start, end, unit, vertical, wlan } = this.state;
    const { name, type } = selectedPath;

    const verticalDescription =
      vertical === 'all' ? 'all verticals' : `vertical '${vertical}'`;
    const wlanDescription = wlan === 'all' ? 'all SSIDs' : `SSID '${wlan}'`;
    const unitDescription = () => {
      switch (unit) {
        case 'month':
        case 'week':
          return 'daily';
        case '24Hours':
          return 'hourly';
        default:
          return 'monthly';
      }
    };

    return (
      <Fragment>
        <p>
          A new overview report will be created for the{' '}
          <b>
            {type} {name}
          </b>
          , covering <b>{verticalDescription}</b> and <b>{wlanDescription}</b>{' '}
          over dates{' '}
          <b>
            {moment(start).format(defaultDisplayDateFormat)} -{' '}
            {moment(end).format(defaultDisplayDateFormat)}
          </b>{' '}
          with <b>{unitDescription()}</b> data points.
        </p>
        <p>
          Please use the tree in the left sidebar to change scope or the fields
          above to adjust filters. Click the button below to proceed.
        </p>
      </Fragment>
    );
  };

  handleDateSelection = selectedDate => {
    const { label, start, end } = selectedDate;
    const startDate = moment(start);
    let endDate = moment(end);

    if (startDate.diff(endDate, 'days') === 0) {
      endDate.add(1, 'days');
    }

    const unit = getTimeUnit(label, startDate, endDate);

    this.setState({
      label,
      start: startDate.toDate(),
      end: endDate.toDate(),
      unit
    });
  };

  handleFieldChange = field => e => this.setState({ [field]: e.target.value });

  toggleDate = dateOpen => this.setState({ dateOpen });

  render() {
    const {
      generateInProgress,
      hierarchy,
      reportsList,
      selectedPath
      // showGenerate,
      // toggleGenerate
    } = this.props;
    const { dateOpen, label, start, end, vertical, wlan } = this.state;
    const hasInProgress =
      filter(reportsList, x => inProgressList.includes(x.status)).length > 0;

    const ddVerticals = getUniqueVerticals(hierarchy, selectedPath);
    const ddWlans = getWlans(hierarchy, selectedPath);

    return (
      <Fragment>
        {/* <GenerateReportExpandCollapse onClick={toggleGenerate}>
          <FaIcon
            icon={showGenerate ? 'angle-down' : 'angle-right'}
            classes={['mr-2']}
            style={{ fontSize: styleLibrary.fontSizes.inlineIcon }}
          />
          Generate Overview Report
        </GenerateReportExpandCollapse> */}
        {!isEmpty(selectedPath) &&
          (hasInProgress ? (
            <p className="text-center">
              Report generation is currently in progress. Please wait for all
              &quot;In-Progress&quot; jobs to complete before generating a new
              report.
            </p>
          ) : (
            <Fragment>
              <div className="d-flex justify-content-around align-items-center my-3 report-properties">
                <ReportFilterWrapper>
                  <label
                    className="col-form-label-sm pr-2"
                    htmlFor="vertical-filter"
                  >
                    Vertical:
                  </label>
                  <select
                    className="form-control"
                    id="vertical-filter"
                    onChange={this.handleFieldChange('vertical')}
                    value={vertical}
                  >
                    {ddVerticals.map(({ value, text }) => (
                      <option key={`vertical-${value}`} value={value}>
                        {text}
                      </option>
                    ))}
                  </select>
                </ReportFilterWrapper>
                <ReportFilterWrapper>
                  <label
                    className="col-form-label-sm pr-2"
                    htmlFor="ssid-filter"
                  >
                    SSID:
                  </label>
                  <select
                    className="form-control"
                    id="ssid-filter"
                    onChange={this.handleFieldChange('wlan')}
                    value={wlan}
                  >
                    {ddWlans.map(({ value, text }) => (
                      <option key={`wlan-${value}`} value={value}>
                        {text}
                      </option>
                    ))}
                  </select>
                </ReportFilterWrapper>
                <ReportFilterWrapper>
                  <label className="col-form-label-sm pr-2">Date Range:</label>
                  <DateRangeFilter
                    dateRangeFilter={{
                      label,
                      start,
                      end
                    }}
                    isDropDownOpen={dateOpen}
                    updateDateRangeFilter={this.handleDateSelection}
                    updateIsOpen={this.toggleDate}
                    buttonStyle={{
                      backgroundColor: styleLibrary.containerBg,
                      border: '1px solid #ced4da'
                    }}
                  />
                </ReportFilterWrapper>
              </div>
              <div className="text-center">
                {this.getReportDescription()}
                <p>
                  <button
                    className="btn btn-primary"
                    disabled={generateInProgress}
                    onClick={this.generateReport}
                  >
                    {generateInProgress ? (
                      <span>
                        <FaIcon icon="circle-notch" spin classes={['mr-2']} />
                        Generating...
                      </span>
                    ) : (
                      'Generate Overview Report'
                    )}
                  </button>
                </p>
              </div>
            </Fragment>
          ))}
      </Fragment>
    );
  }
}

GenerateReport.propTypes = {
  generateInProgress: PropTypes.bool.isRequired,
  handleGenerateReport: PropTypes.func.isRequired,
  hierarchy: PropTypes.object.isRequired,
  reportsList: PropTypes.array.isRequired,
  selectedPath: PropTypes.object,
  // showGenerate: PropTypes.bool.isRequired,
  toggleGenerate: PropTypes.func.isRequired,
  vertSSIDFilter: PropTypes.object.isRequired
};

export default GenerateReport;
