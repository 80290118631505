/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { FaIcon } from 'app/components/icons';
import { styleLibrary } from 'app/constants/style-library';

const StyledNavLink = styled(NavLink)`
  width: ${styleLibrary.headerButtonWidth};
  height: ${styleLibrary.headerHeight}px;
  color: ${styleLibrary.lightText};
  margin: 0 5px;

  &:hover {
    color: ${styleLibrary.brand.highlight};
    text-decoration: none;
  }

  &.header-button-selected {
    background-color: ${styleLibrary.brand.highlight};
    color: ${styleLibrary.darkBg};
    cursor: default;
  }
`;

const IconText = styled.span`
  font-size: ${styleLibrary.fontSizes.body}px;
`;

const HeaderButton = ({ to, icon, title }) => (
  <StyledNavLink
    activeClassName="header-button-selected"
    className="d-flex flex-column justify-content-center text-center"
    data-test-label={`header-${title}-button`}
    to={to}
  >
    <FaIcon icon={icon} style={{ fontSize: styleLibrary.fontSizes.navIcon }} />
    <IconText>{title}</IconText>
  </StyledNavLink>
);

HeaderButton.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  to: PropTypes.string.isRequired
};

export default HeaderButton;
