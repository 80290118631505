/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { isEqual, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { BlockContainer, TitleBar } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { StatefulTable } from 'app/components/tables';
import { MuiTableOptions, customMuiTableThemeWithDefualtCursor, styleLibrary } from 'app/constants';
import {
  clientSessionsFetchStateSelector,
  clientSessionsSelector,
  fetchClientSessions,
  maxClientSessionValuesSelector
} from 'app/redux/clients';

import { fetchStatePropTypes } from 'app/redux/utils';
import { getSessionColumns, sortClientSessions } from '../utils';

export class SessionsForClient extends Component {
  componentDidMount() {
    const { selectedClient, fetchClientSessions } = this.props;
    fetchClientSessions(selectedClient);
  }

  componentDidUpdate(prevProps) {
    const { selectedClient, fetchClientSessions } = this.props;

    if (!isEqual(selectedClient, prevProps.selectedClient)) {
      fetchClientSessions(selectedClient);
    }
  }

  render() {
    const {
      clientSessions = {},
      clientSessionsFetchState: { pending },
      dateRangeFilterLabel,
      maxSessionValues,
      selectedClient
    } = this.props;

    const { details = [], info = {} } = clientSessions;
    const { clientMAC = '' } = selectedClient;

    const sessionTableTheme = merge({}, customMuiTableThemeWithDefualtCursor);
    const clientsTableOptions = merge({}, MuiTableOptions, {
      customSort: sortClientSessions
    });

    return (
      <div className="row no-gutters mb-4 include-in-pdf">
        <BlockContainer classes={['col']} style={{ minHeight: 632 }}>
          <TitleBar
            leftChildren="Sessions for Selected Client"
            rightChildren={
              <span style={{ fontSize: styleLibrary.fontSizes.secondaryTitle }}>
                {dateRangeFilterLabel}
              </span>
            }
          />
          {!pending ? (
            <MuiThemeProvider theme={createMuiTheme(sessionTableTheme)}>
              <StatefulTable
                tableKey={clientMAC}
                columns={getSessionColumns(maxSessionValues)}
                data={details.map(session => ({
                  ...session,
                  ...info
                }))}
                options={clientsTableOptions}
              />
            </MuiThemeProvider>
          ) : (
            <LoadingIconPlaceholder />
          )}
        </BlockContainer>
      </div>
    );
  }
}

SessionsForClient.propTypes = {
  clientSessions: PropTypes.object,
  clientSessionsFetchState: fetchStatePropTypes,
  dateRangeFilterLabel: PropTypes.string.isRequired,
  fetchClientSessions: PropTypes.func.isRequired,
  maxSessionValues: PropTypes.object,
  selectedClient: PropTypes.object.isRequired
};

const mapStateToProps = createSelector(
  clientSessionsSelector,
  clientSessionsFetchStateSelector,
  maxClientSessionValuesSelector,
  (clientSessions, clientSessionsFetchState, maxSessionValues) => ({
    clientSessions,
    clientSessionsFetchState,
    maxSessionValues
  })
);

const mapDispatchToProps = {
  fetchClientSessions
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionsForClient);
