/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  filterParamsSelector,
  offsetFilterParamsSelector
} from 'app/redux/filters';
import {
  createApiBodySelector,
  createNodeByIdSelector,
  selectedPathSelector
} from 'app/redux/hierarchy';
import { timeoutNotifyFetchState } from 'app/redux/utils';
import { isEmpty, merge } from 'lodash';
import { all, call, put, select } from 'redux-saga/effects';
// import moment from 'moment';
// import { apiDateFormat } from '../constants';

export function* buildApiRequestArgs(urls, updateFetchState, options = {}) {
  const {
    dateOffset = 0,
    additionalParams = {},
    additionalBodyParams = {},
    ...otherOpts
  } = options;

  const filterParams = yield select(
    dateOffset !== 0
      ? offsetFilterParamsSelector(dateOffset)
      : filterParamsSelector
  );
  const calls = yield call(getApiCallProperties, urls, filterParams);
  const onTimeout = () => put(updateFetchState(timeoutNotifyFetchState));

  // If the currently selected tree node has a `subcustomer` parent, apply
  // that as a filter on the body
  const selectedPath = yield select(selectedPathSelector);
  let filterBody = {};
  const { subcustomer = '' } = selectedPath;
  if (subcustomer !== '') {
    const subCustomerNode = yield select(createNodeByIdSelector(subcustomer));
    filterBody = {
      filter: { column: 'subcustomer', values: [subCustomerNode.name] }
    };
  }

  return urls.map((url, index) => {
    const { params } = calls[index];
    let { apiBody } = calls[index];

    if (
      url === '/api/fastapi/getBandwidthTrend' ||
      url === '/api/fastapi/getUniqueClientsTrend'
    ) {
      // Add additional parameters specific to this URL
      if (params.timeUnit) {
        switch (params.timeUnit) {
          case '24Hours':
            params.granularity = 'Hourly';
            break;
          case 'week':
            params.granularity = 'daily';
            break;
          case 'day':
            if (params.timeUnitFrom === params.timeUnitTo) {
              params.granularity = 'Hourly';
            } else {
              params.granularity = 'daily';
            }
            break;
          case 'month':
            params.granularity = 'daily';
            break;
          case 'year':
            params.granularity = 'daily';
            break;
          default:
            params.granularity = 'Hourly';
        }
      }
    }

    if (!isEmpty(filterBody) || !isEmpty(additionalBodyParams)) {
      apiBody = JSON.stringify(
        merge({}, JSON.parse(apiBody), filterBody, additionalBodyParams)
      );
    }

    return {
      url,
      body: apiBody,
      options: {
        params: { ...params, ...additionalParams },
        onTimeout,
        ...otherOpts
      }
    };
  });
}

function* getApiCallProperties(urls, params) {
  return yield all(urls.map(url => call(getBodyAndParams, url, params)));
}

function* getBodyAndParams(url, params) {
  let { timeUnit, timeUnitFrom, timeUnitTo, ...rest } = params;

  const apiBody = yield select(createApiBodySelector(url));
  //   const fromMoment = moment(timeUnitFrom, apiDateFormat);
  //   const toMoment = moment(timeUnitTo, apiDateFormat);
  //   const sameMonth = fromMoment.month() === toMoment.month();
  //   const fullMonth =
  //     sameMonth &&
  //     fromMoment.date() === 1 &&
  //     toMoment.date() === toMoment.daysInMonth();

  // [SK: 22/8/19]: This is a really hacky workaround for the way aggregation
  // APIs calculate monthly values. For our UI to work in any sort of
  // predictable, sensible, user-friendly way, we need this in place. I hate it.
  // [SK: 17/8/20]: Tweaking this because it still wasn't quite right. Has to
  // apply to the first of the month, too, but not full month ranges
  // [GS: 12/02/21]: This workaround is being removed per request in https://etrack.arrisi.com/browse/WIFISOL-8567

  /**
  if (
    !url.includes('Trend') && // The only way to know if it's a non-trend API
    timeUnit === 'month' && // The timeUnit is already set as month
    !fullMonth // The date range doesn't encapsulate the full selected month
  ) {
    timeUnit = 'day';
  }
  */

  return {
    apiBody,
    params: { timeUnit, timeUnitFrom, timeUnitTo, ...rest }
  };
}
