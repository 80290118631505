/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { bandwidthUnits, commonPlotProps, styleLibrary } from 'app/constants';
import {
  createFormatAxisValue,
  formatApiDateTime,
  getScaledDisplayValue
} from 'app/utils';
import { createXAxisTicks, formatDataPoints } from '../utils';
import { BlockContainer, TitleBar } from 'app/components/elements';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  dateRangeFilterLabelSelector,
  dateRangeFilterSelector
} from 'app/redux/filters';
import {
  cbrsDruidTrafficTrendFetchStateSelector,
  cbrsDruidTrafficTrendSelector,
  fetchCBRsDruidTrafficTrend
} from 'app/redux/clients';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { isEmpty, isEqual, isArray } from 'lodash';
import SubscriberDropdown from './cbrs-subscribers-dropdown';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';

export class DruidTrafficDataTrend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hiddenPlotMap: {},
      selectedRealm: '315010999900001'
    };
  }

  componentDidMount = () => {
    const { fetchCBRsDruidTrafficTrend } = this.props;

    fetchCBRsDruidTrafficTrend({ subscriber: this.state.selectedRealm });
  };

  componentDidUpdate = prevProps => {
    const {
      fetchCBRsDruidTrafficTrend,
      dateRangeFilter,
      selectedPath
    } = this.props;
    const { selectedRealm } = this.state;
    const {
      dateRangeFilter: prevDateRange,
      selectedPath: prevSelectedPath
    } = prevProps;

    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilter);
    const propertyChanged =
      !isEqual(prevSelectedPath.id, selectedPath.id) ||
      isSpecificNodeType(selectedPath, 'zone');
    if (dateRangeChanged || propertyChanged) {
      this.setState({ subscriber: selectedRealm }, () =>
        fetchCBRsDruidTrafficTrend({ subscriber: selectedRealm })
      );
    }
  };

  isActivePlot = plotKey => {
    return this.state.hiddenPlotMap[plotKey]
      ? 'blank'
      : plotKey.replace(' ', '').toLowerCase();
  };

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };
  renderDruidSessionTrend = () => {
    const data = this.props.propertyCBRsDruidTrafficTrend.TrafficTrendData;
    const { dateRangeFilter } = this.props;
    const { selectedRealm } = this.state;

    if (!selectedRealm || !data.some(entry => entry.realm === selectedRealm)) {
     
      return (
        <NoDataToShow
          icon="error_outline"
          message="No data available for selected subscriber"
          style={{
            background: 'none',
            position: 'relative',
            height: 'auto'
          }}
        />
      );
    }

    const selectedRealmData = data.find(entry => entry.realm === selectedRealm);

    const arrayData = selectedRealmData ? selectedRealmData.trafficList : [];

    const transformedData = arrayData.map(entry => ({
      label: entry.timestamp,
      download: entry.rx_MB,
      upload: entry.tx_MB,
      total: entry.rx_MB + entry.tx_MB
    }));

    const timezone = 'UTC';
    const dataToRender = formatDataPoints(
      transformedData,
      timezone,
      'label',
      'YYYY-MM-DD HH:mm'
    );
    const xAxisTicks = createXAxisTicks(
      transformedData,
      dateRangeFilter,
      timezone,
      'label',
      'YYYY-MM-DD HH:mm'
    );
    const formatAxisValue = createFormatAxisValue();

    return (
      <ChartFlexContainer width="99%" height={300}>
        <ComposedChart
          data={dataToRender}
          margin={{ ...chartMargins, left: 15 }}
        >
          <CartesianGrid vertical={false} />
          <Legend
            content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
            inactivePlots={this.state.hiddenPlotMap}
            verticalAlign="top"
          />
          <XAxis
            dataKey="label"
            label={{
              value: `Time (${timezone})`,
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            interval="preserveStartEnd"
            tick={tick}
            ticks={xAxisTicks}
            tickFormatter={t => formatApiDateTime(t, dateRangeFilter, timezone)}
            scale="time"
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            yAxisId="cbrSessionTrend"
            label={{
              value: 'Traffic',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body,
              fill: styleLibrary.trafficColor
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            tickFormatter={value =>
              formatAxisValue(
                value,
                (val, dec) =>
                  getScaledDisplayValue(val, bandwidthUnits, null, dec),
                1
              )
            }
          />
          <Area
            dataKey={this.isActivePlot('Download')}
            name="Download"
            stroke={styleLibrary.downloadColor}
            fill={styleLibrary.downloadColor}
            yAxisId="cbrSessionTrend"
            {...commonPlotProps}
          />
          <Area
            dataKey={this.isActivePlot('Upload')}
            name="Upload"
            stroke={styleLibrary.uploadColor}
            fill={styleLibrary.uploadColor}
            yAxisId="cbrSessionTrend"
            {...commonPlotProps}
          />
          <Line
            dataKey={this.isActivePlot('Total')}
            name="Total"
            stroke="rgb(31, 119, 180)"
            yAxisId="cbrSessionTrend"
            {...commonPlotProps}
          />
          <Tooltip
            content={
              <MultiLineTooltip
                labelFormatter={label =>
                  formatApiDateTime(label, dateRangeFilter, timezone, true)
                }
                keys={['download', 'upload', 'total']}
                units={bandwidthUnits}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
          />
        </ComposedChart>
      </ChartFlexContainer>
    );
  };

  handleSubscriberChange = selectedRealm => {
    const { fetchCBRsDruidTrafficTrend } = this.props;
    this.setState({ selectedRealm }, () => {
      fetchCBRsDruidTrafficTrend({ subscriber: selectedRealm });
    });
  };

  render = () => {
    const {
      fetchState,
      fetchCBRsDruidTrafficTrend,
      propertyCBRsDruidTrafficTrend
    } = this.props;
    const { selectedRealm } = this.state;

    const data = propertyCBRsDruidTrafficTrend.TrafficTrendData;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <BlockContainer>
        <TitleBar leftChildren="Session Data" />
        <div className="d-flex justify-content-start align-items-center mx-2 mt-2 mb-0">
          <SubscriberDropdown
            data={data}
            onRealmChange={this.handleSubscriberChange}
            selectedRealm={this.state.selectedRealm}
          />
        </div>
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() =>
            fetchCBRsDruidTrafficTrend({
              subscriber: selectedRealm
            })
          }
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <>
              <NoDataToShow
                icon="error_outline"
                message="No data available for selected subscriber"
                style={{
                  background: 'none',
                  position: 'relative',
                  height: 'auto'
                }}
              />
            </>
          ) : (
            complete && hasData && <>{this.renderDruidSessionTrend()}</>
          )}
        </FailedFetchStateHandler>
      </BlockContainer>
    );
  };
}

DruidTrafficDataTrend.propTypes = {
  propertyCBRsDruidTrafficTrend: PropTypes.object,
  realm: PropTypes.string,
  selectedPath: PropTypes.object,
  dateRangeFilter: PropTypes.object,
  dateRangeFilterLabel: PropTypes.string,
  fetchState: fetchStatePropTypes,
  fetchCBRsDruidTrafficTrend: PropTypes.func
};

const mapStateToProps = createSelector(
  selectedPathSelector,
  dateRangeFilterSelector,
  dateRangeFilterLabelSelector,
  cbrsDruidTrafficTrendFetchStateSelector,
  cbrsDruidTrafficTrendSelector,
  (
    selectedPath,
    dateRangeFilter,
    dateRangeFilterLabel,
    fetchState,
    propertyCBRsDruidTrafficTrend
  ) => ({
    selectedPath,
    dateRangeFilter,
    dateRangeFilterLabel,
    fetchState,
    propertyCBRsDruidTrafficTrend
  })
);

const mapDispatchToProps = {
  fetchCBRsDruidTrafficTrend
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DruidTrafficDataTrend);
