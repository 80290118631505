/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
export const noDataRateChartVerticals = ['BaaH', 'HaaH', 'PWF', 'VEN'];
export const gatewayVerticals = ['BaaH', 'HaaH'];

export const DATA_RATE_API_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const showUserTabForVerticals = ['MDU'];
