/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { CloseButton } from 'app/constants';
import { Parser } from 'json2csv';
import {
  BlockContainer,
  NoDataToShow,
  TitleBar
} from 'app/components/elements';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import {
  ExportCsvIcon,
  FaIcon,
  LoadingIconPlaceholder,
  StyledExportButton
} from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { fetchStatePropTypes } from 'app/redux/utils';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { StatefulTable } from 'app/components/tables';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  caseCommentscolumnNames,
  customMuiTableThemeWithCursor,
  customTableOptions
} from './utils';
import {
  sfdcCommentsDetailsSelector,
  sfdcCommentsFetchStateSelector,
  fetchSfdcComments
} from 'app/redux/incidents';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isArray, isEmpty } from 'lodash';

export class CaseCommentsDetails extends Component {
  componentDidMount = () => {
    const { fetchSfdcComments, caseid } = this.props;
    fetchSfdcComments({ caseid });
  };

  componentDidUpdate = prevProps => {
    const { fetchSfdcComments, caseid } = this.props;
    const { caseid: prevCaseId } = prevProps;

    const caseIdChanged = caseid !== '' && prevCaseId !== caseid;

    if (caseIdChanged) {
      fetchSfdcComments({ caseid });
    }
  };

  handleCSVExport = () => {
    const { sfdcComments = [], selectedPath } = this.props;
    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');

    const customHeader = caseCommentscolumnNames.map(c => ({
      label:
        c.name === 'createddatetime'
          ? isPropertyLevel
            ? c.label
            : `${c.label} (UTC)`
          : c.label,
      value: row => row[c.name]
    }));
    const parser = new Parser({ fields: customHeader });
    const csv = parser.parse(sfdcComments);
    const blob = new Blob([csv], {
      type: 'text/csv'
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Case-Comments.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  //getColumnNames() will modify the width of the "commentbody" column header (<th>) and its cells to 300px (or whatever width you define).
  getColumnNames = () => {
    const { selectedPath } = this.props;
    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');

    const columns = caseCommentscolumnNames.map(col => {
      if (col.name === 'commentbody') {
        return {
          ...col,
          options: {
            setCellProps: () => ({
              style: { width: '300px' }
            }),
            setCellHeaderProps: () => ({
              // set custom styles for the header (<th>) of the column, including width
              style: { width: '40%' }
            })
          }
        };
      }
      if (col.name === 'createddatetime') {
        return {
          ...col,
          label: isPropertyLevel ? col.label : `${col.label} (UTC)`
        };
      }
      return col;
    });

    return columns;
  };

  render = () => {
    const {
      caseNumber,
      sfdcComments = [],
      toggleActivityTable,
      fetchSfdcComments,
      fetchState
    } = this.props;

    const { pending, complete, failed } = fetchState;
    const hasData = isArray(sfdcComments) && !isEmpty(sfdcComments);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <BlockContainer classes={['mb-4', 'h-auto']}>
        <TitleBar
          leftChildren={`Case Comments for ${caseNumber}`}
          rightChildren={
            <Fragment>
              {complete && hasData && (
                <StyledExportButton
                  title="Generate CSV"
                  onClick={this.handleCSVExport}
                >
                  <ExportCsvIcon />
                </StyledExportButton>
              )}
              <CloseButton
                onClick={() => toggleActivityTable('comments')}
                title="Close Comments"
              >
                <FaIcon icon="times" />
              </CloseButton>
            </Fragment>
          }
        />
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={fetchSfdcComments}
        >
          {pending ? (
            <LoadingIconPlaceholder position="inherit" />
          ) : noDataToShow || failed ? (
            <NoDataToShow
              message={
                failed
                  ? 'There has been a problem fetching the case comments'
                  : 'No  case comments details available'
              }
              style={{ background: 'none', position: 'relative' }}
            />
          ) : (
            complete &&
            hasData && (
              <MuiThemeProvider
                theme={createMuiTheme(customMuiTableThemeWithCursor)}
              >
                <StatefulTable
                  tableKey="case-comments"
                  data={sfdcComments}
                  columns={this.getColumnNames()}
                  options={{
                    ...customTableOptions,
                    filterType: 'none'
                  }}
                />
              </MuiThemeProvider>
            )
          )}
        </FailedFetchStateHandler>
      </BlockContainer>
    );
  };
}

CaseCommentsDetails.propTypes = {
  caseNumber: PropTypes.string,
  caseid: PropTypes.string,
  selectedPath: PropTypes.object,
  fetchSfdcComments: PropTypes.func.isRequired,
  sfdcComments: PropTypes.array,
  fetchState: fetchStatePropTypes,
  toggleActivityTable: PropTypes.func
};

const mapStateToProps = createSelector(
  sfdcCommentsDetailsSelector,
  sfdcCommentsFetchStateSelector,
  selectedPathSelector,
  (sfdcComments, fetchState, selectedPath) => ({
    sfdcComments,
    fetchState,
    selectedPath
  })
);

export default connect(mapStateToProps, { fetchSfdcComments })(
  CaseCommentsDetails
);
