/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import moment from 'moment';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';

import { defaultDateFormat } from '../../constants';
import { ajaxGet } from '../util-sagas';

import { setAuditInfoDownloadStatus } from './';

export function* downloadAuditInfoSaga() {
  yield put(setAuditInfoDownloadStatus({ inProgress: true, failed: false }));

  try {
    const saveFileAs = yield getContext('saveFileAs');
    const dateFrom = moment()
      .subtract(1, 'months')
      .startOf('month')
      .format(defaultDateFormat);
    const dateTo = moment()
      .subtract(1, 'months')
      .endOf('month')
      .format(defaultDateFormat);

    const { data } = yield ajaxGet(
      `/api/audit/auditInfo?timeUnitFrom=${dateFrom}&timeUnitTo=${dateTo}`,
      {
        headers: {
          Accept: 'application/octet-stream'
        },
        responseType: 'blob'
      }
    );

    const filename = `audit_info_${dateFrom}_${dateTo}.csv`;
    yield call(saveFileAs, data, filename);
    yield put(setAuditInfoDownloadStatus({ inProgress: false }));
  } catch (err) {
    yield put(setAuditInfoDownloadStatus({ inProgress: false, failed: true }));
  }
}

export default function createDownloadAuditInfoSagas(types) {
  return [takeLatest(types.downloadAuditInfo, downloadAuditInfoSaga)];
}
