/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';

const RowContainer = styled.div.attrs({
  className: 'd-flex justify-content-between align-items-center py-1 pr-1'
})`
  font-size: ${styleLibrary.fontSizes.overviewTileText}px;
  border-bottom: 1px solid ${styleLibrary.containerBorderColor};
  cursor: pointer;

  &:hover {
    background-color: ${styleLibrary.hoverColour};
    font-weight: bold;
  }
`;

const StatusContainer = styled.div.attrs({
  className: 'px-1'
})`
  color: ${styleLibrary.inventoryCountColor};
`;

const StatusWrapper = styled.div.attrs({
  className: 'd-flex align-items-center justify-content-end'
})`
  min-width: 120px;
  line-height: 16px;
`;
StatusWrapper.displayName = 'StatusWrapper';

const StatusBar = styled.div.attrs({
  className: 'd-inline-block ml-1'
})`
  height: 14px;
  width: ${props => props.width}%;
  background-color: ${props => props.color};
`;

const TotalContainer = styled.div.attrs({
  className: 'text-right'
})`
  color: ${styleLibrary.inventoryCountColor};
  font-size: ${styleLibrary.fontSizes.sectionTitle}px;
  font-weight: bold;
  width: ${props => props.max.toString().length * 15 + 15}px;
`;
TotalContainer.displayName = 'TotalContainer';

const DeviceRow = props => {
  const {
    calculateBarWidthForNumberOfDevices,
    device,
    maxNumberOfDevices,
    onClick
  } = props;
  const {
    numberConnected: connected,
    numberDisconnected: disconnected,
    total,
    type
  } = device;

  return (
    <RowContainer onClick={onClick} data-test-label="inventory-device-row">
      <div
        className="device-type pl-3 flex-grow-1"
        data-test-label="inventory-device-row-type"
      >
        {type}
      </div>
      <StatusContainer>
        <StatusWrapper>
          {connected}
          <StatusBar
            width={calculateBarWidthForNumberOfDevices(connected)}
            color={styleLibrary.connectedColor}
          />
        </StatusWrapper>
        <StatusWrapper>
          {disconnected}
          <StatusBar
            width={calculateBarWidthForNumberOfDevices(disconnected)}
            color={styleLibrary.disconnectedColor}
          />
        </StatusWrapper>
      </StatusContainer>
      <TotalContainer max={maxNumberOfDevices}>{total}</TotalContainer>
    </RowContainer>
  );
};

DeviceRow.propTypes = {
  calculateBarWidthForNumberOfDevices: PropTypes.func.isRequired,
  device: PropTypes.shape({
    numberConnected: PropTypes.number.isRequired,
    numberDisconnected: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }),
  maxNumberOfDevices: PropTypes.number,
  onClick: PropTypes.func.isRequired
};

export default DeviceRow;
