/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  // fetchPropertyClientStatus,
  // propertyClientStatusFetchStateSelector,
  // propertyClientStatusSelector,
  fetchPropertyAPUniqueClientTrend,
  propertyAPUniqueClientTrendFetchStateSelector,
  propertyAPUniqueClientTrendSelector
} from 'app/redux/clients';
import { dateRangeFilterLabelSelector, dateRangeFilterSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import { isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import APUniqueClientTrend from './ap-unique-client-trend';
// import ConnectedClientsTrendChart from '../../property-clients/connected-clients-trend-chart';

export class APConnectedClientsTrend extends Component {

  componentDidMount = () => {
    const { fetchPropertyAPUniqueClientTrend, apMAC } = this.props;
    fetchPropertyAPUniqueClientTrend({ apMAC });
  };

  componentDidUpdate = prevProps => {
    const {
      fetchPropertyAPUniqueClientTrend,
      dateRangeFilterLabel,
      apMAC
    } = this.props;
    const { dateRangeFilterLabel: prevDateLabel, apMAC: prevApMAC } = prevProps;
    const apChanged = apMAC !== '' && apMAC !== prevApMAC;
    const dateChanged = dateRangeFilterLabel !== prevDateLabel;

    if (apChanged || dateChanged) {
      fetchPropertyAPUniqueClientTrend({ apMAC });
    }
  };

  render = () => {
    const {
      fetchState,
      apUniqueClientData: { data = [], Info = {} },
      fetchPropertyAPUniqueClientTrend,
      apMAC,
      dateRangeFilter
    } = this.props;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <Fragment>
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow ? (
          <NoDataToShow
            icon="error_outline"
            message={'No data available'}
            style={{
              background: 'none',
              position: 'relative'
            }}
          />
        ) : (
          <FailedFetchStateHandler
            fetchState={fetchState}
            retry={() => fetchPropertyAPUniqueClientTrend({ apMAC })}
          >
            <div
              className={pending || noDataToShow ? 'fetch-state-pending' : ''}
            >
              {complete && hasData && (
                // <ConnectedClientsTrendChart data={data} info={Info} />
                <APUniqueClientTrend data={data} info={Info} dateRage={dateRangeFilter}/>
              )}
            </div>
          </FailedFetchStateHandler>
        )}
      </Fragment>
    );
  };
}

APConnectedClientsTrend.propTypes = {
  dateRangeFilter: PropTypes.object,
  dateRangeFilterLabel: PropTypes.string,
  // fetchPropertyClientStatus: PropTypes.func,
  // propertyClientStatus: PropTypes.object,
  fetchPropertyAPUniqueClientTrend: PropTypes.func.isRequired,
  apUniqueClientData: PropTypes.object,
  fetchState: fetchStatePropTypes,
  selectedPath: PropTypes.object,
  apMAC: PropTypes.string
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  // propertyClientStatusFetchStateSelector,
  // propertyClientStatusSelector,
  propertyAPUniqueClientTrendFetchStateSelector,
  propertyAPUniqueClientTrendSelector,
  (
    dateRangeFilter,
    dateRangeFilterLabel,
    selectedPath,
    fetchState,
    // propertyClientStatus,
    apUniqueClientData
  ) => ({
    dateRangeFilter,
    dateRangeFilterLabel,
    selectedPath,
    fetchState,
    // propertyClientStatus,
    apUniqueClientData
  })
);

const mapDispatchToProps = {
  // fetchPropertyClientStatus,
  fetchPropertyAPUniqueClientTrend
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(APConnectedClientsTrend);
