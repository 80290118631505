/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { defaultDateFormat } from 'app/constants';
import { getTimeUnit } from 'app/utils';
import moment from 'moment';

export const dateRangeFilterTransform = ({ label, start, end }) => {
  const fromDate = moment(start);
  let toDate = moment(end);
  const today = moment();

  const endOfDateRange = toDate.isAfter(today) ? today : toDate;
  const formattedToDate = endOfDateRange.format(defaultDateFormat);
  const formattedFromDate = fromDate.format(defaultDateFormat);

  return {
    timeUnit: getTimeUnit(label, fromDate, endOfDateRange),
    timeUnitFrom: formattedFromDate,
    timeUnitTo: formattedToDate
  };
};
