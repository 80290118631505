/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';
import { FaIcon } from 'app/components/icons';

const ExpandCollapseArrow = props => {
  const { className, expanded, indent, onClick, showIcon } = props;

  return (
    <div className={className} onClick={onClick}>
      {showIcon && indent > 1 && (
        <FaIcon
          data-test-label="hierarchy-caret"
          icon={expanded ? 'caret-down' : 'caret-right'}
        />
      )}
    </div>
  );
};

ExpandCollapseArrow.propTypes = {
  className: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  indent: PropTypes.number,
  showIcon: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default styled(ExpandCollapseArrow)`
  margin: 0 ${props => (props.indent > 1 ? '5px' : 0)} 0
    ${props => (props.indent - 1) * (props.showIcon ? 10 : 8)}px;
  display: inline-block;
  width: 10px;
  color: ${styleLibrary.lightText};
  cursor: pointer;
  text-align: center;

  .default-tree-link & {
    color: ${styleLibrary.lightTextFaded};
  }
  .default-tree-link.in-path > & {
    color: ${styleLibrary.lightText};
  }
`;
