/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isPropertyNodeOrUnder } from 'app/components/layout/components/sidebar/utils';
import { buildApiRequestArgs } from 'app/redux/ajax-saga-utils';
import { selectedPathSelector } from 'app/redux/hierarchy';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';
import { ajaxPost, debounceSaga } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState
} from 'app/redux/utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchMapSummaryComplete,
  fetchMapSummaryError,
  mapSummarySelector,
  updateMapSummaryFetchState
} from './';

const summaryRequestUrl = ['/api/network/getServiceProviderDetails'];

export function* fetchMapSummarySaga() {
  yield put(updateMapSummaryFetchState(pendingFetchState));

  yield call(waitForHierarchySaga);

  const currentDataSet = yield select(mapSummarySelector);
  yield call(debounceSaga, currentDataSet);

  try {
    const [requestArgs] = yield call(
      buildApiRequestArgs,
      summaryRequestUrl,
      updateMapSummaryFetchState
    );
    const { url, options } = requestArgs;
    let { body } = requestArgs;

    const selectedNode = yield select(selectedPathSelector);
    if (
      !isPropertyNodeOrUnder(selectedNode) ||
      selectedNode.id.includes('DOCSIS')
    ) {
      // Force the "summary" mode on for this call to `getServiceProviderDetails` - performance optimisation
      body = JSON.parse(body);
      body.summary = true;
    }

    const { data } = yield ajaxPost(url, body, options);

    yield put(fetchMapSummaryComplete(data));
    yield put(updateMapSummaryFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchMapSummaryError, err));
    yield put(updateMapSummaryFetchState(failedFetchState));
  }
}

export default function createFetchMapSummarySagas(types) {
  return [takeLatest(types.fetchMapSummary, fetchMapSummarySaga)];
}
