/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { SubTitle, TitleBar } from 'app/components/elements';
import { styleLibrary } from 'app/constants';

const StyledChartTitleBar = styled(TitleBar)`
  @media (max-width: 1400px) {
    & {
      height: 100px;
    }
  }
`;

export const ChartTitleBar = props => {
  const { dateRange, hasData, title, total } = props;

  return (
    <StyledChartTitleBar
      leftChildren={
        <Fragment>
          {title}
          <SubTitle>
            {hasData && total && (
              <span>
                Total clients: <b>{total}</b>
              </span>
            )}
            <span>&nbsp;</span>
          </SubTitle>
        </Fragment>
      }
      rightChildren={
        <span style={{ fontSize: styleLibrary.fontSizes.secondaryTitle }}>
          {dateRange}
        </span>
      }
    />
  );
};

ChartTitleBar.propTypes = {
  dateRange: PropTypes.string,
  hasData: PropTypes.bool.isRequired,
  total: PropTypes.number,
  title: PropTypes.string.isRequired
};
