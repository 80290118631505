/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import {
  MuiTableOptions,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import { StatefulTable, TableExport } from 'app/components/tables';
import { FaIcon } from 'app/components/icons';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, includes, isArray, isEmpty, toLower } from 'lodash';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  cbrsDruidAPsTableFetchStateSelector,
  cbrsDruidAPsTableSelector,
  fetchCBRsDruidAPsTable
} from 'app/redux/clients';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';

const colorStyle = {
  color: '#00adef',
  marginRight: 7
};

const createCustomIconRenderer = (iconClass, includeValue = true) => (
  value,
  unit
) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <i className={`fas ${iconClass}`} style={colorStyle} />
    {includeValue ? (
      <span>
        <strong>{value.toFixed(2)}</strong> {unit}
      </span>
    ) : (
      <span>{value}</span>
    )}
  </div>
);

const customArrowIconsDown = createCustomIconRenderer('fa-angle-double-down');
const customArrowIconsUp = createCustomIconRenderer('fa-angle-double-up');
const sessionIcon = createCustomIconRenderer('fa-user', false);

const columnsNames = () => [
  { name: 'name', label: 'Name' },
  { name: 'oper_state', label: 'Opr State' },
  { name: 'admin_state', label: 'Admin State' },
  { name: 'sctp_address', label: 'SCTP Address' },
  { name: 'identity', label: 'Identity', options: {} },
  { name: 'serialnumber', label: 'Serial Number' },
  {
    name: 'downloadRateKbps',
    label: 'Download Rate',
    options: {
      customBodyRender: value => customArrowIconsDown(value, 'Kbps')
    }
  },
  {
    name: 'uploadRateKbps',
    label: 'Upload Rate',
    options: {
      customBodyRender: value => customArrowIconsUp(value, 'Kbps')
    }
  },
  {
    name: 'session',
    label: 'Sessions',
    options: {
      customBodyRender: value => sessionIcon(value),
      sortDirection: 'desc'
    }
  }
];

class DruidAPsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: ''
    };
  }

  componentDidMount = () => {
    this.props.fetchCBRsDruidAPsTable();
  };

  filterBySearch = data => {
    const { searchValue } = this.state;

    if (isEmpty(searchValue)) {
      return data;
    }
    const lowerSearchValue = toLower(searchValue);

    return data.filter(row =>
      Object.values(row).some(value =>
        includes(toLower(String(value)), lowerSearchValue)
      )
    );
  };

  renderActions = (data, columns) => {
    return (
      <div className="d-flex justify-content-between m-2">
        <div className="d-flex justify-content-start align-items-center">
          <h3>CBRS AP Details</h3>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <FaIcon icon="search" classes={['mr-2']} />
          <label className="sr-only">Search...</label>
          <input
            className="p-1"
            id="property-cbrs-table-search"
            onChange={e => this.setState({ searchValue: e.target.value })}
            placeholder="Search..."
            style={{ width: 250 }}
            type="text"
            value={this.state.searchValue}
          />
          <TableExport
            hidePdfExport={false}
            exportName="Property CBRS APs Details"
            exportDataDefinitions={this.createDruidAPsTableExxportDataDefinition(
              data,
              columns
            )}
          />
        </div>
      </div>
    );
  };

  createDruidAPsTableExxportDataDefinition = data => {
    const exportDataDefinitions = [];
    const columns = columnsNames();

    let transformedData = cloneDeep(data);
    transformedData = JSON.parse(
      JSON.stringify(
        transformedData,
        columns.map(c => c.name)
      )
    );
    transformedData = transformedData.map(Object.values);

    exportDataDefinitions.push({
      name: 'CBR APs Summary',
      data: transformedData,
      columns
    });

    return exportDataDefinitions;
  };

  render = () => {
    const {
      fetchState,
      propertyCBRsDruidAPsData,
      fetchCBRsDruidAPsTable
    } = this.props;
    let data = propertyCBRsDruidAPsData.data;

    const { pending, complete, failed } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    const columns = columnsNames();

    return (
      <Fragment>
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() => fetchCBRsDruidAPsTable({})}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message={
                failed
                  ? 'There has been a problem fetching CBRS AP Details'
                  : 'No data available for CBRs AP Details'
              }
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            <Fragment>
              {!pending && hasData && this.renderActions(data, columns)}
              <div
                className={pending || noDataToShow ? 'fetch-state-pending' : ''}
              >
                {complete && (
                  <MuiThemeProvider
                    theme={createMuiTheme(customMuiTableThemeWithDefualtCursor)}
                  >
                    <StatefulTable
                      tableKey={'property-cbrs-aps'}
                      columns={columns}
                      data={this.filterBySearch(data)}
                      options={MuiTableOptions}
                    />
                  </MuiThemeProvider>
                )}
              </div>
            </Fragment>
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

DruidAPsTable.propTypes = {
  propertyCBRsDruidAPsData: PropTypes.object,
  fetchState: fetchStatePropTypes,
  fetchCBRsDruidAPsTable: PropTypes.func
};

const mapStateToProps = createSelector(
  cbrsDruidAPsTableFetchStateSelector,
  cbrsDruidAPsTableSelector,
  (fetchState, propertyCBRsDruidAPsData) => ({
    fetchState,
    propertyCBRsDruidAPsData
  })
);
const mapDispatchToProps = {
  fetchCBRsDruidAPsTable
};

export default connect(mapStateToProps, mapDispatchToProps)(DruidAPsTable);
