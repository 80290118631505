/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  reducer as alarms,
  nameSpace as alarmsNameSpace
} from 'app/redux/alarms';
import { reducer as app, nameSpace as appNameSpace } from 'app/redux/app';
import {
  reducer as clients,
  nameSpace as clientsNameSpace
} from 'app/redux/clients';
import {
  reducer as filters,
  nameSpace as filtersNameSpace
} from 'app/redux/filters';
import {
  reducer as hierarchy,
  nameSpace as hierarchyNameSpace
} from 'app/redux/hierarchy';
import {
  reducer as incidents,
  nameSpace as incidentsNameSpace
} from 'app/redux/incidents';
import {
  reducer as inventory,
  nameSpace as inventoryNameSpace
} from 'app/redux/inventory';
import { reducer as map, nameSpace as mapNameSpace } from 'app/redux/map';
import {
  reducer as network,
  nameSpace as networkNameSpace
} from 'app/redux/network';
import {
  reducer as reports,
  nameSpace as reportsNameSpace
} from 'app/redux/reports';
import { reducer as rogue, nameSpace as rogueNameSpace } from 'app/redux/rogue';
import { reducer as ui, nameSpace as uiNameSpace } from 'app/redux/ui';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  [appNameSpace]: app,
  [clientsNameSpace]: clients,
  [filtersNameSpace]: filters,
  [hierarchyNameSpace]: hierarchy,
  [inventoryNameSpace]: inventory,
  [mapNameSpace]: map,
  [networkNameSpace]: network,
  [reportsNameSpace]: reports,
  [uiNameSpace]: ui,
  [alarmsNameSpace]: alarms,
  [rogueNameSpace]: rogue,
  [incidentsNameSpace]: incidents
});

export default rootReducer;
