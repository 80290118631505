/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { NoDataToShow } from 'app/components/elements';
import {
  ExportCsvIcon,
  FaIcon,
  LoadingIconPlaceholder,
  StyledExportButton
} from 'app/components/icons';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { StatefulTable } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  MuiTableOptions,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  downloadAPDetails,
  fetchInventoryAPDetails,
  inventoryAPDetailsFetchStateSelector,
  inventoryAPDetailsJSONSelector,
  inventoryAPDetailsSelector,
  updateInventoryAPDetailsJSON
} from 'app/redux/inventory';
import { fetchStatePropTypes } from 'app/redux/utils';
import { some, toString } from 'lodash';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  CloseButton,
  TableActions,
  TableContainer,
  TableHeader,
  TableTitle,
  TableWrapper
} from '.';
import { getAPDetailsTableData } from '../get-inventory-columns';
import { sortAPDetailsTable } from '../utils';

class APDetailsTable extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      rowsPerPage: 10,
      searchValue: '',
      rowsSelected: []
    };
  }

  componentDidMount = () => {
    const { fetchInventoryAPDetails, selectedPath } = this.props;
    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');
    fetchInventoryAPDetails({ skipCache: isPropertyLevel });
  };

  componentDidUpdate = prevProps => {
    const {
      fetchState: { pending: prevPending }
    } = prevProps;
    const {
      fetchState: { pending, complete },
      apDetails
    } = this.props;

    if (prevPending && !pending && complete && apDetails instanceof Blob) {
      this.convertCSVToJSON(apDetails);
    }
  };

  convertCSVToJSON = data => {
    const file = new File([data], 'ap-details.csv');
    Papa.parse(file, {
      header: true,
      worker: true,
      skipEmptyLines: true,
      complete: results =>
        this.props.updateInventoryAPDetailsJSON(results.data),
      error: () => this.props.updateInventoryAPDetailsJSON([])
    });
  };

  handleSearchChange = e => this.setState({ searchValue: e.target.value });

  handleRowsPerPageChange = rowsPerPage => this.setState({ rowsPerPage });

  filterRows = search => row => {
    const searchLower = search.toLowerCase();
    return some(row, value => {
      const lowerValue = toString(value).toLowerCase();
      const searchWithoutColon = searchLower.replace(/:/g, '');
      const valueWithoutColon = lowerValue.replace(/:/g, '');
      if (lowerValue.includes(':')) {
        return valueWithoutColon.includes(searchWithoutColon);
      } else {
        return lowerValue.includes(searchLower);
      }
    });
  };

  filterDevicesBySearch = deviceRows => {
    const { searchValue } = this.state;
    return !searchValue
      ? deviceRows
      : deviceRows.filter(this.filterRows(searchValue));
  };

  renderSearchBox = () => (
    <Fragment>
      <FaIcon icon="search" classes={['mr-2']} />
      <label className="sr-only" htmlFor="apDetails-search">
        Search
      </label>
      <input
        className="p-1"
        id="apDetails-search"
        onChange={this.handleSearchChange}
        placeholder="Search..."
        type="text"
        value={this.state.searchValue}
      />
    </Fragment>
  );

  render = () => {
    const {
      apDetails,
      fetchInventoryAPDetails,
      fetchState,
      downloadAPDetails,
      showAPDetailsTable,
      apDetailsJSON,
      selectedPath
    } = this.props;
    const { rowsPerPage, rowsSelected } = this.state;

    const { pending, complete, failed } = fetchState;
    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');
    const hasData = apDetails instanceof Blob;
    const noDataToShow = !pending && complete && !hasData;
    const csvTooltip = 'Generate CSV file containing the AP Details table';

    const { detailsForType, columns, uptimes } = getAPDetailsTableData(
      apDetailsJSON
    );

    const tableOptions = {
      ...MuiTableOptions,
      customSort: sortAPDetailsTable,
      onChangeRowsPerPage: this.handleRowsPerPageChange,
      rowsPerPage,
      rowsSelected
    };

    return (
      <FailedFetchStateHandler
        fetchState={fetchState}
        retry={() => fetchInventoryAPDetails({ skipCache: isPropertyLevel })}
      >
        <MuiThemeProvider
          theme={createMuiTheme(customMuiTableThemeWithDefualtCursor)}
        >
          <TableContainer>
            <TableWrapper>
              <TableHeader>
                <TableTitle>AP Details</TableTitle>
                <TableActions>
                  {!pending && hasData && this.renderSearchBox()}
                  {!pending && hasData && (
                    <StyledExportButton
                      onClick={() => downloadAPDetails({ columns })}
                      title={csvTooltip}
                    >
                      <ExportCsvIcon className="ml-3" />
                    </StyledExportButton>
                  )}
                  <CloseButton
                    onClick={() => showAPDetailsTable(false)}
                    title="Close"
                  >
                    <FaIcon icon="times" />
                  </CloseButton>
                </TableActions>
              </TableHeader>
              {pending ? (
                <LoadingIconPlaceholder position="relative" height={250} />
              ) : noDataToShow || failed ? (
                <NoDataToShow
                  message={
                    failed
                      ? 'There has been a problem downloading the list of APs.'
                      : 'There are currently no devices at this level.'
                  }
                  style={{
                    background: 'none',
                    position: 'relative'
                  }}
                />
              ) : (
                <StatefulTable
                  tableKey={`AP-Details-${toString(uptimes)}`}
                  columns={columns}
                  data={this.filterDevicesBySearch(detailsForType)}
                  options={tableOptions}
                />
              )}
            </TableWrapper>
          </TableContainer>
        </MuiThemeProvider>
      </FailedFetchStateHandler>
    );
  };
}

APDetailsTable.displayName = 'APDetailsTable';
APDetailsTable.propTypes = {
  fetchInventoryAPDetails: PropTypes.func,
  downloadAPDetails: PropTypes.func,
  showAPDetailsTable: PropTypes.func,
  apDetails: PropTypes.object,
  fetchState: fetchStatePropTypes,
  updateInventoryAPDetailsJSON: PropTypes.func,
  apDetailsJSON: PropTypes.array,
  selectedPath: PropTypes.object
};

const mapStateToProps = createSelector(
  selectedPathSelector,
  inventoryAPDetailsSelector,
  inventoryAPDetailsFetchStateSelector,
  inventoryAPDetailsJSONSelector,
  (selectedPath, apDetails, fetchState, apDetailsJSON) => ({
    selectedPath,
    apDetails,
    fetchState,
    apDetailsJSON
  })
);

const mapDispatchToProps = {
  fetchInventoryAPDetails,
  downloadAPDetails,
  updateInventoryAPDetailsJSON
};

export default connect(mapStateToProps, mapDispatchToProps)(APDetailsTable);
