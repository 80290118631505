/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import {
  BlockContainer,
  NoDataToShow,
  TitleBar
} from 'app/components/elements';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import {
  ExportCsvIcon,
  FaIcon,
  LoadingIconPlaceholder,
  StyledExportButton
} from 'app/components/icons';
import { StatefulTable } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import { styleLibrary } from 'app/constants';
import {
  fetchIncidentActivity,
  incidentActivityFetchStateSelector,
  incidentActivitySelector
} from 'app/redux/incidents';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import { Parser } from 'json2csv';
import { isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import {
  customMuiTableThemeWithCursor,
  customTableOptions,
  incidentActivityTableColumns,
  incidentActivityTransform
} from './utils';

const CloseButton = styled.button.attrs({
  className: 'btn btn-outline-light my-1 ml-3'
})`
  font-size: ${styleLibrary.fontSizes.titleButton}px;
`;

class IncidentActivityTable extends Component {
  componentDidMount = () => {
    const { fetchIncidentActivity, incidentId } = this.props;
    fetchIncidentActivity({ incidentId });
  };

  componentDidUpdate = prevProps => {
    const { fetchIncidentActivity, incidentId } = this.props;
    const { incidentId: prevIncidentId } = prevProps;

    const incidentIdChanged =
      incidentId !== '' && prevIncidentId !== incidentId;

    if (incidentIdChanged) {
      fetchIncidentActivity({ incidentId });
    }
  };

  renderCustomSearch = (searchText, handleSearch) => (
    // <CustomSearch>
    <div className="d-flex justify-content-start">
      {/* <h5 style={{ marginRight: '30em' }}>Incidents Summary</h5> */}
      <FaIcon icon="search" classes={['mr-2', 'mt-2']} />
      <label className="sr-only">Search</label>
      <input
        className="p-1"
        id="incident-activity-search"
        onChange={e => handleSearch(e.target.value)}
        placeholder="Search Activity Details..."
        style={{ width: 250 }}
        type="text"
        value={searchText || ''}
      />
    </div>
    // </CustomSearch>
  );

  handleCSVExport = () => {
    const { incidentActivity = [] } = this.props;
    const customHeader = incidentActivityTableColumns.map(c => ({
      label: c.label,
      value: row => {
        if (c.name === 'description') {
          return row[c.name].trim();
        } else {
          return row[c.name];
        }
      }
    }));
    const parser = new Parser({ fields: customHeader });
    const csv = parser.parse(incidentActivity);
    const blob = new Blob([csv], {
      type: 'text/csv'
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Incidents-Activity.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // renderCustomToolbar = () => (
  //   <StyledExportButton title="Generate CSV" onClick={this.handleCSVExport}>
  //     <ExportCsvIcon />
  //   </StyledExportButton>
  // );

  render() {
    const {
      caseNumber,
      fetchIncidentActivity,
      incidentActivity = [],
      fetchState,
      toggleIncidentActivityTable,
      selectedPath
    } = this.props;

    const { pending, complete, failed } = fetchState;
    const hasData = isArray(incidentActivity) && !isEmpty(incidentActivity);
    const noDataToShow = !pending && complete && !hasData;
    const transformedIncidentActivity = incidentActivityTransform(
      incidentActivity
    );

    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');

    const columns = incidentActivityTableColumns.map(column => {
      if (
        column.name === 'datetimeOpened' ||
        column.name === 'datatimeLastModified' ||
        column.name === 'actualEnd'
      ) {
        return {
          ...column,
          label: isPropertyLevel ? column.label : `${column.label} (UTC)`
        };
      }
      return column;
    });

    return (
      <BlockContainer classes={['mb-4', 'h-auto']}>
        <TitleBar
          leftChildren={`Activity Details for ${caseNumber}`}
          rightChildren={
            <Fragment>
              {complete && hasData && (
                <StyledExportButton
                  title="Generate CSV"
                  onClick={this.handleCSVExport}
                >
                  <ExportCsvIcon />
                </StyledExportButton>
              )}
              <CloseButton onClick={toggleIncidentActivityTable} title="Close">
                <FaIcon icon="times" />
              </CloseButton>
            </Fragment>
          }
        />
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={fetchIncidentActivity}
        >
          {pending ? (
            <LoadingIconPlaceholder position="inherit" />
          ) : noDataToShow || failed ? (
            <NoDataToShow
              message={
                failed
                  ? 'There has been a problem fetching the incident activity'
                  : 'No incidents activity details available'
              }
              style={{ background: 'none', position: 'relative' }}
            />
          ) : (
            complete &&
            hasData && (
              <MuiThemeProvider
                theme={createMuiTheme(customMuiTableThemeWithCursor)}
              >
                <StatefulTable
                  tableKey="incident-activity-table"
                  // title={`Activity Details for ${caseNumber}`}
                  data={transformedIncidentActivity}
                  columns={columns}
                  options={{
                    ...customTableOptions,
                    customSearchRender: this.renderCustomSearch
                    // search: false,
                    // customToolbar: this.renderCustomToolbar
                  }}
                />
              </MuiThemeProvider>
            )
          )}
        </FailedFetchStateHandler>
      </BlockContainer>
    );
  }
}

IncidentActivityTable.propTypes = {
  caseNumber: PropTypes.string,
  incidentId: PropTypes.string,
  selectedPath: PropTypes.object,
  fetchIncidentActivity: PropTypes.func,
  incidentActivity: PropTypes.array,
  fetchState: fetchStatePropTypes,
  toggleIncidentActivityTable: PropTypes.func
};

const mapStateToProps = createSelector(
  incidentActivitySelector,
  incidentActivityFetchStateSelector,
  selectedPathSelector,
  (incidentActivity, fetchState, selectedPath) => ({
    incidentActivity,
    fetchState,
    selectedPath
  })
);

const mapDispatchToProps = { fetchIncidentActivity };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IncidentActivityTable);
