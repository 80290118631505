/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  bandwidthUnits,
  commonPlotProps,
  dataRateUnits,
  styleLibrary
} from 'app/constants';
import { createShowPanelSelector } from 'app/redux/app';
import {
  dateRangeFilterLabelSelector,
  dateRangeFilterSelector
} from 'app/redux/filters';
import {
  apUsageDetailsFetchStateSelector,
  apUsageTrendSelector,
  fetchApUsageDetails,
  propertyAPsFetchStateSelector
} from 'app/redux/network';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  createFormatAxisValue,
  formatApiDateTime,
  getScaledDisplayValue
} from 'app/utils';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import { SubChartsTitle } from '../../utils';
import APConnectedClientsTrend from './ap-connected-clients-trend';

const apChartNoDataStyle = {
  background: 'none',
  bottom: '20%',
  height: 'calc(100% - 25px)',
  position: 'static'
};

class ApCharts extends Component {
  constructor(props) {
    super(props);
    this.state = { hiddenPlotMap: {} };
  }

  componentDidMount = () => this.fetchApDetails({});

  componentDidUpdate = prevProps => this.fetchApDetails(prevProps);

  fetchApDetails = prevProps => {
    const { ap, dateRangeFilterLabel, fetchApUsageDetails, zone } = this.props;
    const apChanged = ap !== '' && ap !== prevProps.ap;
    const dateChanged = dateRangeFilterLabel !== prevProps.dateRangeFilterLabel;

    if (apChanged || dateChanged) {
      fetchApUsageDetails({ apMAC: ap, zone });
    }
  };

  isActivePlot = plotKey =>
    this.state.hiddenPlotMap[plotKey]
      ? 'blank'
      : plotKey.replace(' ', '').toLowerCase();

  togglePlotVisibility = key =>
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));

  renderBandwidthChart = () => {
    const {
      apUsageDetailsFetchState,
      apUsageTrend,
      fetchApUsageDetails,
      dateRangeFilter,
      ap,
      zone
    } = this.props;
    const { pending, complete } = apUsageDetailsFetchState;
    const { bandwidth } = apUsageTrend;
    const noDataToShow = !pending && complete && isEmpty(bandwidth);
    const formatAxisValue = createFormatAxisValue();

    return (
      <FailedFetchStateHandler
        fetchState={apUsageDetailsFetchState}
        retry={() => fetchApUsageDetails({ apMAC: ap, zone })}
      >
        {noDataToShow ? (
          <NoDataToShow style={apChartNoDataStyle} />
        ) : (
          <ChartFlexContainer width="99%" height={350}>
            <ComposedChart
              data={bandwidth}
              margin={{ ...chartMargins, left: 15 }}
            >
              <CartesianGrid vertical={false} />
              <Legend
                content={
                  <TrendChartLegend onClick={this.togglePlotVisibility} />
                }
                inactivePlots={this.state.hiddenPlotMap}
                verticalAlign="top"
              />
              <XAxis
                dataKey="label"
                label={{
                  value: 'Time',
                  position: 'bottom',
                  offset: 5,
                  fontWeight: 'bold',
                  fontSize: styleLibrary.fontSizes.body
                }}
                interval="preserveStartEnd"
                tick={tick}
                tickFormatter={t => formatApiDateTime(t, dateRangeFilter)}
                scale="point"
              />
              <YAxis
                yAxisId="bandwidthTrend"
                label={{
                  value: 'Traffic',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 5,
                  dy: 35,
                  fontSize: styleLibrary.fontSizes.body,
                  fill: styleLibrary.trafficColor
                }}
                stroke="rgb(31, 119, 180)"
                tick={tick}
                tickFormatter={value =>
                  formatAxisValue(
                    value,
                    (val, dec) =>
                      getScaledDisplayValue(val, bandwidthUnits, null, dec),
                    0
                  )
                }
              />
              <Area
                dataKey={this.isActivePlot('Download')}
                name="Download"
                stroke={styleLibrary.downloadColor}
                fill={styleLibrary.downloadColor}
                yAxisId="bandwidthTrend"
                {...commonPlotProps}
              />
              <Area
                dataKey={this.isActivePlot('Upload')}
                name="Upload"
                stroke={styleLibrary.uploadColor}
                fill={styleLibrary.uploadColor}
                yAxisId="bandwidthTrend"
                {...commonPlotProps}
              />
              <Line
                dataKey={this.isActivePlot('Total')}
                name="Total"
                stroke="rgb(31, 119, 180)"
                yAxisId="bandwidthTrend"
                {...commonPlotProps}
              />
              <Tooltip
                content={
                  <MultiLineTooltip
                    keys={['total', 'download', 'upload']}
                    units={bandwidthUnits}
                  />
                }
                contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
                offset={0}
                wrapperStyle={{ marginLeft: 80 }}
              />
            </ComposedChart>
          </ChartFlexContainer>
        )}
        {pending && <LoadingIconPlaceholder />}
      </FailedFetchStateHandler>
    );
  };

  renderDataRateChart = () => {
    const {
      apUsageDetailsFetchState,
      apUsageTrend,
      fetchApUsageDetails,
      dateRangeFilter,
      ap,
      zone
    } = this.props;
    const { pending, complete } = apUsageDetailsFetchState;
    const { dataRate } = apUsageTrend;
    const noDataToShow = !pending && complete && isEmpty(dataRate);
    const formatAxisValue = createFormatAxisValue();

    return (
      <FailedFetchStateHandler
        fetchState={apUsageDetailsFetchState}
        retry={() => fetchApUsageDetails({ apMAC: ap, zone })}
      >
        {noDataToShow ? (
          <NoDataToShow style={apChartNoDataStyle} />
        ) : (
          <ChartFlexContainer width="99%" height={350}>
            <ComposedChart
              data={dataRate}
              margin={{ ...chartMargins, left: 15 }}
            >
              <CartesianGrid vertical={false} />
              <Legend
                content={
                  <TrendChartLegend onClick={this.togglePlotVisibility} />
                }
                inactivePlots={this.state.hiddenPlotMap}
                verticalAlign="top"
              />
              <XAxis
                dataKey="label"
                label={{
                  value: 'Time',
                  position: 'bottom',
                  offset: 5,
                  fontWeight: 'bold',
                  fontSize: styleLibrary.fontSizes.body
                }}
                interval="preserveStartEnd"
                tick={tick}
                tickFormatter={t => formatApiDateTime(t, dateRangeFilter)}
                scale="point"
              />
              <YAxis
                yAxisId="dataRateTrend"
                label={{
                  value: 'Transfer Rate',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 5,
                  dy: 35,
                  fontSize: styleLibrary.fontSizes.body,
                  fill: styleLibrary.trafficColor
                }}
                stroke="rgb(31, 119, 180)"
                tick={tick}
                tickFormatter={value =>
                  formatAxisValue(
                    value,
                    (val, dec) =>
                      getScaledDisplayValue(val, dataRateUnits, null, dec),
                    0
                  )
                }
              />
              <Area
                dataKey={this.isActivePlot('Download Rate')}
                name="Download Rate"
                stroke={styleLibrary.downloadColor}
                fill={styleLibrary.downloadColor}
                yAxisId="dataRateTrend"
                {...commonPlotProps}
              />
              <Area
                dataKey={this.isActivePlot('Upload Rate')}
                name="Upload Rate"
                stroke={styleLibrary.uploadColor}
                fill={styleLibrary.uploadColor}
                yAxisId="dataRateTrend"
                {...commonPlotProps}
              />
              <Tooltip
                content={
                  <MultiLineTooltip
                    keys={['downloadrate', 'uploadrate']}
                    units={dataRateUnits}
                  />
                }
                contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
                offset={0}
                wrapperStyle={{ marginLeft: 80 }}
              />
            </ComposedChart>
          </ChartFlexContainer>
        )}
        {pending && <LoadingIconPlaceholder />}
      </FailedFetchStateHandler>
    );
  };

  render = () => {
    const {
      ap,
      propertyAPsFetchState: { complete },
      showUsageTrend,
      showDataRateTrend,
      showAPConnectedClientsTrend
    } = this.props;

    const subChartsTitle =
      (showUsageTrend ? 'Usage' : '') +
      (showUsageTrend && showDataRateTrend ? ' &' : '') +
      (showDataRateTrend ? ' Data Rate' : '') +
      ((showUsageTrend || showDataRateTrend) && showAPConnectedClientsTrend
        ? ' &'
        : '') +
      (showAPConnectedClientsTrend ? ' Connected Clients' : '') +
      ' for AP MAC ';

    return (
      <Fragment>
        <SubChartsTitle>
          {subChartsTitle}&nbsp;<b>{ap}</b>
        </SubChartsTitle>
        {(showUsageTrend || showDataRateTrend) && complete && (
          <div className="row mt-2">
            {showUsageTrend && (
              <div className="col">{this.renderBandwidthChart()}</div>
            )}
            {showDataRateTrend && (
              <div className="col">{this.renderDataRateChart()}</div>
            )}
          </div>
        )}
        {showAPConnectedClientsTrend && (
          <div className="row mt-2">
            <div className="col position-relative h-auto">
              <APConnectedClientsTrend apMAC={ap} />
            </div>
          </div>
        )}
      </Fragment>
    );
  };
}

ApCharts.propTypes = {
  ap: PropTypes.string,
  apUsageTrend: PropTypes.shape({
    bandwidth: PropTypes.arrayOf(PropTypes.object),
    bandwidthInfo: PropTypes.object,
    dataRate: PropTypes.arrayOf(PropTypes.object),
    dataRateInfo: PropTypes.object
  }),
  apUsageDetailsFetchState: fetchStatePropTypes,
  dateRangeFilterLabel: PropTypes.string,
  dateRangeFilter: PropTypes.object,
  fetchApUsageDetails: PropTypes.func,
  propertyAPsFetchState: fetchStatePropTypes,
  zone: PropTypes.string,
  showUsageTrend: PropTypes.bool,
  showDataRateTrend: PropTypes.bool,
  showAPConnectedClientsTrend: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('PropertyAPUsageTrend'),
  createShowPanelSelector('PropertyAPDataRateTrend'),
  createShowPanelSelector('PropertyAPConnectedClientsTrend'),
  apUsageDetailsFetchStateSelector,
  apUsageTrendSelector,
  dateRangeFilterLabelSelector,
  propertyAPsFetchStateSelector,
  dateRangeFilterSelector,
  (
    showUsageTrend,
    showDataRateTrend,
    showAPConnectedClientsTrend,
    apUsageDetailsFetchState,
    apUsageTrend,
    dateRangeFilterLabel,
    propertyAPsFetchState,
    dateRangeFilter
  ) => ({
    showUsageTrend,
    showDataRateTrend,
    showAPConnectedClientsTrend,
    apUsageDetailsFetchState,
    apUsageTrend,
    dateRangeFilterLabel,
    propertyAPsFetchState,
    dateRangeFilter
  })
);

const mapDispatchToProps = { fetchApUsageDetails };

export default connect(mapStateToProps, mapDispatchToProps)(ApCharts);
