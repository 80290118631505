/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { prefixNavigationPath } from 'app/utils';
import Layout from 'app/components/layout';

const ProtectedRoute = ({
  component: Component,
  isLoggedIn,
  isManualLogOut,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Layout>
          {Component ? (
            <Component {...props} />
          ) : (
            <Redirect to={prefixNavigationPath('/overview')} />
          )}
        </Layout>
      ) : (
        <Redirect
          to={prefixNavigationPath(
            `/login${
              isManualLogOut
                ? ''
                : `?redirect=${getEncodedPathname(props.location.pathname)}`
            }`
          )}
        />
      )
    }
  />
);

export const getEncodedPathname = (pathname = '') =>
  encodeURIComponent(pathname.slice());

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
  isLoggedIn: PropTypes.bool.isRequired,
  isManualLogOut: PropTypes.bool,
  location: PropTypes.object
};

export default ProtectedRoute;
