/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { NoDataToShow } from 'app/components/elements';
import {
  ExportCsvIcon,
  FaIcon,
  LoadingIconPlaceholder,
  StyledExportButton
} from 'app/components/icons';
import { StatefulTable } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  MuiTableOptions,
  MuiTableTheme,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import { createShowPanelSelector } from 'app/redux/app';
import {
  fetchVLANConsumptionReport,
  vlanConsumptionReportFetchStateSelector,
  vlanConsumptionReportSelector
} from 'app/redux/reports';
import { findIndex, includes, isArray, isEmpty, toLower } from 'lodash';
import Papa from 'papaparse';
import { PropTypes } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { scrollToBottom } from 'app/utils';
import classNames from 'classnames';
import { SubChartsTitle } from 'app/modules/properties/components/utils';
import VlanConsumptionTrend from './vlan-consumption-trend';

const VlanColorBox = styled.div.attrs({
  className: 'rounded-circle mr-2'
})`
  height: 20px;
  width: 20px;
  background-color: ${props => props.color};
`;

const colorChange = value => {
  let color = 'green';
  if (value < 80) {
    return color;
  } else if (value >= 80 && value < 90) {
    color = '#FFC107';
  } else if (value >= 90) {
    color = '#FF3632';
  }
  return color;
};

const shouldRenderNA = (mduType, wiredOnly, value) => {
  return (
    (mduType === 'mdu' || mduType === 'ew') &&
    (wiredOnly === 'wireless' || wiredOnly === 'wired') &&
    value === 0
  );
};

const customBodyRender = (value, tableMeta, columnName) => {
  const mduTypeIndex = findIndex(
    vlanConsumptionColumnsName,
    c => c.name === 'mduType'
  );

  const wiredOnlyIndex = findIndex(
    vlanConsumptionColumnsName,
    c => c.name === 'wiredOnly'
  );

  const mduType = String(tableMeta.rowData[mduTypeIndex]).toLowerCase();
  const wiredOnly = String(tableMeta.rowData[wiredOnlyIndex]).toLowerCase();

  if (
    columnName === 'provisionedVLANs' &&
    shouldRenderNA(mduType, wiredOnly, value)
  ) {
    return 'NA';
  }

  if (
    columnName === 'perVLansConsumed' &&
    shouldRenderNA(mduType, wiredOnly, value)
  ) {
    return 'NA';
  }

  if (columnName === 'perVLansConsumed') {
    return (
      <div className="d-flex justify-content-start">
        <VlanColorBox color={colorChange(value)} />
        {value}
      </div>
    );
  }

  return value;
};

const vlanConsumptionColumnsName = [
  { name: 'date', label: 'Date', options: { display: false } },
  { name: 'Name', label: 'Name' },
  { name: 'realm', label: 'Realm' },
  { name: 'mduType', label: 'MDU Type', options: { display: true } },
  { name: 'wiredOnly', label: 'Wired/Wireless ' },
  {
    name: 'authDisabled',
    label: 'Auth Disabled',
    options: { display: false }
  },
  { name: 'bedsCount', label: 'No. of Beds' },
  {
    name: 'provisionedVLANs',
    label: 'Provisioned VLANs',
    options: {
      customBodyRender: (value, tableMeta) =>
        customBodyRender(value, tableMeta, 'provisionedVLANs')
    }
  },
  {
    name: 'consumendVlans',
    label: 'Consumed VLANs',
    options: {
      display: false
    }
  },
  {
    name: 'perVLansConsumed',
    label: '%VLANs Consumed',
    options: {
      customBodyRender: (value, tableMeta) =>
        customBodyRender(value, tableMeta, 'perVLansConsumed'),
      sortDirection: 'desc'
    }
  },
  { name: 'Accounts', label: 'Users' },
  { name: 'Devices', label: 'Devices' },
  { name: 'accountsWithNoDevice', label: 'Accounts with NO Device' },
  { name: 'avgDevicePerTenant', label: 'Avg Device Per Tenant' },
  {
    name: 'UserInactivity_Last1Day',
    label: 'UserInactivity (Last1Day)',
    options: { display: false }
  },
  {
    name: 'UserInactivity_Last7Days',
    label: 'Inactive Users (Last 7 Days)',
    options: { display: false }
  },
  {
    name: 'UserInactivity_Last30Days',
    label: 'Inactive Users (Last 30 Days)',
    options: { display: false }
  },
  {
    name: 'UserInactivity_above30Days',
    label: 'Inactive Users (Above 30 Days)',
    options: { display: false }
  }
];

class VlanConsumption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      parsedData: [],
      isParsing: false,
      selectedZone: ''
    };
  }

  componentDidUpdate = prevProps => {
    const {
      vlanConsumptionReportFetchState: { inProgress: prevInProgress }
    } = prevProps;
    const {
      vlanConsumptionReportFetchState: { inProgress, failed },
      vlanConsumptionReport
    } = this.props;

    if (
      prevInProgress &&
      !inProgress &&
      !failed &&
      vlanConsumptionReport instanceof Blob
    ) {
      this.convertCSVToJSON(vlanConsumptionReport);
    }
  };

  convertCSVToJSON = data => {
    this.setState({ isParsing: true });
    const file = new File([data], 'vlanConsumptionReport.csv');
    Papa.parse(file, {
      header: true,
      worker: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: results =>
        this.setState({ isParsing: false, parsedData: results.data }),
      error: () => this.setState({ isParsing: false, parsedData: [] })
    });
  };

  filterBySearch = data => {
    const { searchValue } = this.state;

    if (isEmpty(searchValue)) {
      return data;
    }
    const lowerSearchValue = toLower(searchValue);

    return data.filter(row =>
      Object.values(row).some(value =>
        includes(toLower(String(value)), lowerSearchValue)
      )
    );
  };

  handleExportCSV = () => {
    const {
      vlanConsumptionReport,
      vlanConsumptionReportFetchState: { inProgress, failed }
    } = this.props;

    if (inProgress || failed) {
      return 'Unable to export CSV. Data fetching is in progress or failed.';
    }

    if (!(vlanConsumptionReport instanceof Blob)) {
      return 'Invalid data format for CSV export';
    }

    this.convertCSVToJSON(vlanConsumptionReport);

    const { parsedData } = this.state;

    if (parsedData.length === 0) {
      return 'No data available for CSV export';
    }

    const headers = vlanConsumptionColumnsName
      .filter(column => !column.options || column.options.display !== false)
      .map(column => column.label);

    const filteredData = parsedData.map(row =>
      vlanConsumptionColumnsName
        .filter(column => !column.options || column.options.display !== false)
        .reduce((acc, columnInfo) => {
          const columnName = columnInfo.name;
          const lowercaseWiredOnly = String(row.wiredOnly).toLowerCase();

          if (columnName === 'perVLansConsumed' && row[columnName] === 0) {
            acc[columnInfo.label] = 'NA';
          } else if (
            columnName === 'provisionedVLANs' &&
            (row.mduType === 'MDU' || row.mduType === 'EW') &&
            (lowercaseWiredOnly === 'wired' ||
              lowercaseWiredOnly === 'wireless') &&
            (row[columnName] === 0 || row[columnName === 'NA'])
          ) {
            acc[columnInfo.label] = 'NA';
          } else {
            acc[columnInfo.label] = row[columnName];
          }

          return acc;
        }, {})
    );

    const transformedData = filteredData.filter(
      row => !/horsham/i.test(row.Name)
    );

    const csvData = Papa.unparse({ fields: headers, data: transformedData });
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'vlanConsumptionReport.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return '';
  };

  renderActions = () => {
    const { parsedData, searchValue } = this.state;
    const sortedData = parsedData.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
    const latestDate = sortedData[0] ? sortedData[0].date : null;

    return (
      <div className="d-flex justify-content-between align-items-center m-2">
        <span className="d-flex align-items-center">
          VLAN consumption details as on&nbsp;<b>{latestDate}</b> &nbsp;
        </span>
        <div className="d-flex align-items-center">
          <span>VLANs consumption :</span>
          <VlanColorBox className="ml-3" color="green" />
          <b> &lt; 80%</b>
          <VlanColorBox className="ml-3" color="#FFC107" />
          <b> &lt; 90%</b>
          <VlanColorBox className="ml-3" color="#FF3632" />
          <b> &ge; 90%</b>
        </div>
        <div className="d-flex align-items-center">
          <FaIcon icon="search" classes={['mr-2']} />
          <label className="sr-only">Search...</label>
          <input
            className="p-1"
            id="reports-vlan-consumption-table-search"
            onChange={e => this.setState({ searchValue: e.target.value })}
            placeholder="Search..."
            style={{ width: 250 }}
            type="text"
            value={searchValue}
          />
          <StyledExportButton
            title="Generate CSV file containing the VLANs Consumption Table"
            onClick={() => this.handleExportCSV(parsedData)}
          >
            <ExportCsvIcon className="ml-2" />
          </StyledExportButton>
        </div>
      </div>
    );
  };

  handleTableRowClick = (rowData, zoneColumnIndex) => {
    const newZone = rowData[zoneColumnIndex];
    if (newZone === this.state.selectedZone) {
      this.setState({ selectedZone: '' });
    } else {
      this.setState({ selectedZone: newZone }, scrollToBottom);
    }
  };

  setRowProps = (row, zoneColumnIndex) => {
    const { selectedZone } = this.state;
    const newZone = row[zoneColumnIndex];
    return {
      className:
        selectedZone !== '' &&
        classNames(newZone === selectedZone ? 'row-selected' : 'row-unselected')
    };
  };

  render = () => {
    const zoneColumnIndex = findIndex(vlanConsumptionColumnsName, [
      'name',
      'Name'
    ]);

    const {
      vlanConsumptionReport,
      fetchVLANConsumptionReport,
      vlanConsumptionReportFetchState: { inProgress, failed },
      showVlanConsumptionTrend
    } = this.props;
    const { parsedData, isParsing, selectedZone } = this.state;

    const transformedData = parsedData.filter(
      row => !/horsham/i.test(row.Name)
    );

    const hasData =
      vlanConsumptionReport instanceof Blob &&
      isArray(transformedData) &&
      !isEmpty(transformedData);
    const noDataToShow = !inProgress && !isParsing && !hasData;

    const vlansTableOptions = {
      ...MuiTableOptions,
      responsive: 'stacked',
      ...(showVlanConsumptionTrend
        ? {
          onRowClick: rowData =>
            this.handleTableRowClick(rowData, zoneColumnIndex),
          setRowProps: row => this.setRowProps(row, zoneColumnIndex)
        }
        : {})
    };

    const VlansMuiTableTheme = showVlanConsumptionTrend
      ? createMuiTheme(MuiTableTheme)
      : createMuiTheme(customMuiTableThemeWithDefualtCursor);

    return (
      <FailedFetchStateHandler
        fetchState={{ pending: inProgress, complete: !inProgress, failed }}
        retry={fetchVLANConsumptionReport}
      >
        {inProgress || isParsing ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow ? (
          <NoDataToShow
            message="No data available"
            style={{ background: 'none', position: 'relative' }}
          />
        ) : (
          !inProgress &&
          !isParsing &&
          hasData && (
            <Fragment>
              {this.renderActions()}
              {showVlanConsumptionTrend && (
                <div>
                  <p className="hide-in-pdf ml-2">
                    Click on any row to view the VLANs Consumption for the
                    selected property
                  </p>
                </div>
              )}
              <MuiThemeProvider theme={VlansMuiTableTheme}>
                <StatefulTable
                  tableKey="vlan-consumption"
                  columns={vlanConsumptionColumnsName}
                  data={this.filterBySearch(transformedData)}
                  options={vlansTableOptions}
                />
              </MuiThemeProvider>
              {showVlanConsumptionTrend && selectedZone !== '' && (
                <Fragment>
                  <SubChartsTitle>
                    VLAN Consumption for Property&nbsp;<b>{selectedZone}</b>
                  </SubChartsTitle>
                  <VlanConsumptionTrend zoneName={selectedZone} />
                </Fragment>
              )}
            </Fragment>
          )
        )}
      </FailedFetchStateHandler>
    );
  };
}

VlanConsumption.propTypes = {
  showVlanConsumptionTrend: PropTypes.bool,
  vlanConsumptionReport: PropTypes.object,
  vlanConsumptionReportFetchState: PropTypes.object,
  fetchVLANConsumptionReport: PropTypes.func
};

const mapStateToProps = createSelector(
  createShowPanelSelector('ReportsVlanConsumptionTrend'),
  vlanConsumptionReportFetchStateSelector,
  vlanConsumptionReportSelector,
  (
    showVlanConsumptionTrend,
    vlanConsumptionReportFetchState,
    vlanConsumptionReport
  ) => ({
    showVlanConsumptionTrend,
    vlanConsumptionReportFetchState,
    vlanConsumptionReport
  })
);

const mapDispatchToProps = { fetchVLANConsumptionReport };

export default connect(mapStateToProps, mapDispatchToProps)(VlanConsumption);
