/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { ChartFlexContainer } from 'app/components/charts';
import { styleLibrary } from 'app/constants';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Cell, Label, Legend, Pie, PieChart, Text } from 'recharts';

export class AlarmDoughnut extends Component {
  getLabelClasses = () => {
    const { count } = this.props;
    return count > 999
      ? count > 9999
        ? count > 99999
          ? count > 999999
            ? count > 9999999
              ? 'h6'
              : 'h5'
            : 'h4'
          : 'h3'
        : 'h2'
      : 'h1';
  };

  getColor = () => {
    const { color, disabled } = this.props;
    return disabled ? color + '25' : color;
  };

  renderLabelContent = props => {
    const {
      viewBox: { cx, cy }
    } = props;
    const { count } = this.props;
    return (
      <Text
        className={this.getLabelClasses()}
        x={cx}
        y={cy}
        textAnchor="middle"
        verticalAnchor="middle"
        fill={this.getColor()}
      >
        {count}
      </Text>
    );
  };

  formatLegendText = (value, entry) => {
    const { color } = entry;

    return (
      <span
        style={{ color, fontSize: styleLibrary.fontSizes.alarmDoughnutLegend }}
      >
        {value}
      </span>
    );
  };

  render = () => {
    const {
      nameKey,
      name,
      count,
      totalCount,
      handleSeverityFilter
    } = this.props;
    const data = [
      { count: count },
      { count: (totalCount ? totalCount : 100) - count }
    ];
    const fillColor = [this.getColor(), styleLibrary.alarms.default];
    return (
      <ChartFlexContainer width="100%" height={150}>
        <PieChart
          onClick={() => handleSeverityFilter(nameKey)}
          style={{ cursor: 'pointer' }}
        >
          <Pie
            data={data}
            dataKey="count"
            innerRadius="80%"
            outerRadius="90%"
            startAngle={210}
            endAngle={-30}
            paddingAngle={count && count !== totalCount ? 5 : 0}
          >
            <Label content={this.renderLabelContent} />

            {fillColor.map((color, index) => (
              <Cell key={index} fill={color} />
            ))}
          </Pie>

          {!isEmpty(name) && (
            <Legend
              layout="horizontal"
              align="center"
              verticalAlign="bottom"
              payload={[
                {
                  value: name,
                  color: this.getColor()
                }
              ]}
              formatter={this.formatLegendText}
              iconSize={0}
              wrapperStyle={{
                left: 8,
                bottom: 25
              }}
            />
          )}
        </PieChart>
      </ChartFlexContainer>
    );
  };
}

AlarmDoughnut.defaultProps = {
  nameKey: '',
  name: '',
  count: 0,
  totalCount: 100,
  color: styleLibrary.alarms.default,
  disabled: false
};

AlarmDoughnut.propTypes = {
  nameKey: PropTypes.string,
  name: PropTypes.string,
  count: PropTypes.number,
  totalCount: PropTypes.number,
  color: PropTypes.string,
  handleSeverityFilter: PropTypes.func,
  disabled: PropTypes.bool
};

export default AlarmDoughnut;
