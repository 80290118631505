/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isAjaxTimeoutError } from 'app/custom-errors';
import { buildApiRequestArgs } from 'app/redux/ajax-saga-utils';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';
import { ajaxPost } from 'app/redux/util-sagas';
import { genericAjaxPostSaga } from 'app/redux/util-sagas/generic-ajax-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutFetchState
} from 'app/redux/utils';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchInventoryDetailsComplete,
  fetchInventoryDetailsError,
  fetchInventorySummaryComplete,
  fetchInventorySummaryError,
  inventoryDetailsSelector,
  inventorySelectionSelector,
  updateInventoryDetailsFetchState,
  updateInventoryFilter,
  updateInventorySummaryFetchState
} from './';
import { getFirstPropertyFromSummary, getSummary } from './utils';

const getInventorySummaryUrls = ['/api/device/getInventorySummary'];
const getInventoryDetailsUrls = ['/api/device/getInventoryDetails'];

// WIFISOL-10603 THIS METHOD HAS BEEN COMMENTED AS IT TRIGGERS
// BOTH THE APIS SIMULTANEOUSLY IN PLACES WHERE IT IS NOT NEEDED

// export function* scheduleSagas() {
//   const window = yield getContext('window');
//   const {
//     location: { pathname }
//   } = window;

//   if (pathname.startsWith('/inventory')) {
//     yield all([
//       call(fetchInventorySummarySaga),
//       call(fetchInventoryDetailsSaga)
//     ]);
//     return;
//   }

//   yield call(fetchInventorySummarySaga);
//   yield call(fetchInventoryDetailsSaga);
//   return;
// }

export function* fetchInventorySummarySaga({ payload }) {
  const skipCache = get(payload, 'skipCache', false);

  yield put(updateInventorySummaryFetchState(pendingFetchState));
  yield call(waitForHierarchySaga);

  const additionalOptions = yield {
    skipCache,
    headers: skipCache ? { 'hot-refresh': true } : {},
    additionalBodyParams: skipCache ? { refresh: true } : {}
  };

  try {
    const [requestArgs] = yield call(
      buildApiRequestArgs,
      getInventorySummaryUrls,
      updateInventorySummaryFetchState,
      additionalOptions
    );
    const { url, body, options } = yield requestArgs;
    const { data: summaryData } = yield ajaxPost(url, body, options);
    const { type: deviceType } = yield getFirstPropertyFromSummary(summaryData);

    yield put(fetchInventorySummaryComplete(summaryData));

    // Check if the results contain the currently selected device type which
    // will mean that tab remains highlighted on refresh rather than reverting
    // back to the default (which is a jarring user experience)
    const { deviceType: currentDeviceType } = yield select(
      inventorySelectionSelector
    );
    const resultsHaveSelectedDeviceType = yield getSummary(summaryData).some(
      device => device.type === currentDeviceType
    );

    if (!resultsHaveSelectedDeviceType) {
      yield put(updateInventoryFilter({ levelType: 'Property', deviceType }));
    }

    yield put(updateInventorySummaryFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchInventorySummaryError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(updateInventorySummaryFetchState(updatedFetchState));
  }
}

export function* fetchInventoryDetailsSaga({ payload }) {
  const skipCache = get(payload, 'skipCache', false);

  const sagaOptions = {
    completeAction: fetchInventoryDetailsComplete,
    errorAction: fetchInventoryDetailsError,
    currentDataSelector: inventoryDetailsSelector,
    updateFetchState: updateInventoryDetailsFetchState
  };

  const requestOptions = yield {
    skipCache,
    headers: skipCache ? { 'hot-refresh': true } : {},
    additionalBodyParams: skipCache ? { refresh: true } : {}
  };

  yield call(
    genericAjaxPostSaga,
    getInventoryDetailsUrls,
    sagaOptions,
    requestOptions
  );
}

export default function createFetchInventorySagas(types) {
  return [
    // takeLatest(
    //   [types.fetchInventorySummary, types.fetchInventoryDetails],
    //   scheduleSagas
    // )
    takeLatest(types.fetchInventorySummary, fetchInventorySummarySaga),
    takeLatest(types.fetchInventoryDetails, fetchInventoryDetailsSaga)
  ];
}
