/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { styleLibrary } from 'app/constants';
import PropTypes from 'prop-types';
import React from 'react';

const getDisplayValues = count => {
  if (count > 9) {
    return {
      displayCount: '>9',
      startX: 7.5
    };
  } else {
    return {
      displayCount: count,
      startX: 11.3
    };
  }
};

export const MapMarkerSvg = props => {
  const { providers = [{}] } = props;
  const count = providers.length;

  // If we're currently displaying a collection of APs, check for any disconnected
  // and use that to prioritise the colour of the marker
  const fillColor = providers[0].APName
    ? providers.some(p => p.connectionStatus === 'Disconnect')
      ? styleLibrary.disconnectedColor
      : styleLibrary.connectedColor
    : styleLibrary.brand.highlight;

  const { displayCount, startX } = getDisplayValues(count);

  return (
    <svg width="30px" height="50px" viewBox="0 0 30 50">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle fill="#FFFFFF" cx="16.5" cy="17" r="12" />
        <g fill={fillColor} fillRule="nonzero" stroke={styleLibrary.darkText}>
          <path d="M15,0.568181818 C22.9813778,0.568181818 29.4444444,7.34338523 29.4444444,15.6960227 C29.4444444,18.1137205 28.9016144,20.4515239 27.8752611,22.5580091 L14.9999856,49.0019705 L14.5026844,47.9805273 C14.4048033,47.779483 14.4048033,47.779483 13.9643433,46.874775 C13.3770633,45.6684875 13.3770633,45.6684875 12.5450833,43.9595466 C11.0768822,40.9437511 9.60868222,37.9278307 8.23836111,35.1127955 C8.23763556,35.1113045 8.23763556,35.1113045 8.23691,35.1098136 C4.19497111,26.8065057 1.99240222,22.2804068 1.96851556,22.2274523 C1.04299889,20.208608 0.555555556,17.9869761 0.555555556,15.6960227 C0.555555556,7.34387955 7.01954,0.568181818 15,0.568181818 Z M15,26 C20.4553921,26 24.888889,21.38844 24.888889,15.6960225 C24.888889,10.003605 20.4553921,5.392045 15,5.392045 C9.54347068,5.392045 5.111111,10.0029527 5.111111,15.6960225 C5.111111,21.3890923 9.54347068,26 15,26 Z" />
        </g>
        {displayCount !== '' && (
          <text
            fontFamily="Monaco,Courier New"
            fontSize="12"
            fontWeight="bold"
            fill="#000000"
          >
            <tspan x={startX} y="19">
              {displayCount}
            </tspan>
          </text>
        )}
      </g>
    </svg>
  );
};

MapMarkerSvg.propTypes = {
  providers: PropTypes.arrayOf(PropTypes.object).isRequired
};
