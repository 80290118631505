/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { styleLibrary } from 'app/constants';
import classNames from 'classnames';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const LegendWrapper = styled.div.attrs({
  className: 'd-flex justify-content-center mb-5'
})`
  cursor: pointer;
`;

export const LegendBlock = styled.div.attrs(props => ({
  className: classNames('d-inline-block mr-3', !props.active && 'inactive')
}))`
  font-weight: bold;
  font-size: ${styleLibrary.fontSizes.body}px;
  margin-left: ${props => (props.name === 'Sessions' ? 120 : 20)}px;

  &.inactive {
    color: ${styleLibrary.disabledText};
    text-decoration: line-through;
  }
`;

export const LegendIcon = styled.svg.attrs({
  className: 'd-inline-block mr-1',
  width: 16,
  height: 16
})``;

const TrendChartLegend = props => {
  const { inactivePlots = [], onClick } = props;

  return (
    <LegendWrapper>
      {props.payload.map(({ dataKey, value, payload }) => {
        const active = !inactivePlots[value];

        return (
          <LegendBlock
            key={value}
            dataKey={dataKey}
            data-test-label={`legend-block-${value}`}
            name={value}
            active={active}
            onClick={() => onClick(value)}
          >
            <LegendIcon>
              <rect
                strokeWidth={2}
                fill={active ? payload.stroke : styleLibrary.lightText}
                stroke={payload.stroke}
                x={0}
                y={0}
                width={16}
                height={16}
              />
            </LegendIcon>
            {value}
          </LegendBlock>
        );
      })}
    </LegendWrapper>
  );
};

TrendChartLegend.defaultProps = { onClick: noop };

TrendChartLegend.propTypes = {
  inactivePlots: PropTypes.objectOf(PropTypes.bool),
  onClick: PropTypes.func,
  payload: PropTypes.array
};

export default TrendChartLegend;
