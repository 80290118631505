/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import { TextLink } from 'app/components/elements';
import { styleLibrary } from 'app/constants';

import { getAllNodePaths } from '../../utils';

import { isEmpty } from 'lodash';
import ExpandCollapseArrow from './expand-collapse-arrow';

const TreeNodeLink = styled.li.attrs(props => ({
  className: props.pathLength > 2 ? 'default-tree-link' : ''
}))`
  &.default-tree-link,
  &.default-tree-link a {
    color: ${styleLibrary.lightTextFaded};
  }
  &.default-tree-link.in-path,
  &.default-tree-link.in-path > a {
    color: ${styleLibrary.lightText};
  }

  & .tree-link-wlan {
    font-style: italic;
  }
`;

class TreeNode extends Component {
  constructor(props) {
    super(props);

    const { autoExpandChildren, expanded } = props;
    const inPath = this.isSelectedInPath();

    this.state = {
      expanded: expanded || inPath,
      autoExpand: expanded && autoExpandChildren,
      isInSelectedPath: inPath
    };

    this.subTrees = new Map();
  }

  componentDidUpdate(prevProps) {
    const { selected: prevSelected = {} } = prevProps;
    const { selected: currentSelected = {} } = this.props;

    const currentId = currentSelected.id || '';

    // If we detect a change in selection in the tree, set the state of
    // the tree node accordingly
    if (currentId !== prevSelected.id) {
      if (this.isSelectedInPath()) {
        this.setState({
          expanded: true,
          isInSelectedPath: true
        });
      } else {
        this.setState({
          isInSelectedPath: false
        });
      }
    }
  }

  isSelectedInPath() {
    const { hierarchy, node, selected = {} } = this.props;
    return getAllNodePaths(hierarchy, node).includes(selected.id);
  }

  handleToggle() {
    this.setState(prevState => ({
      expanded: !prevState.expanded
    }));
  }

  forceCollapse(includeThisNode) {
    if (includeThisNode) {
      this.setState({
        expanded: false,
        inSelectedPath: false
      });
    }

    this.subTrees.forEach(tree => {
      if (tree) {
        tree.forceCollapse(true);
      }
    });
  }

  handleLinkClick = event => {
    event.preventDefault();
    const { node } = this.props;
    return this.props.onClick(node.id);
  };

  render() {
    const { hierarchy, node, selected } = this.props;
    const { autoExpand, expanded, isInSelectedPath } = this.state;

    const hasChildren = node.children && node.children.length > 0;
    const isThisNodeSelected = selected && selected.id === node.id;

    let childNodes = node.children.map(child => hierarchy[child]);

    return (
      <TreeNodeLink
        pathLength={selected.id.split('-').length}
        className={classNames({
          'in-path': isInSelectedPath
        })}
      >
        <ExpandCollapseArrow
          expanded={expanded}
          indent={node.ancestors.length + 1}
          showIcon={hasChildren}
          onClick={this.handleToggle.bind(this)}
        />
        <TextLink
          className={classNames(
            {
              selected: isThisNodeSelected
            },
            `tree-link-${node.type}`
          )}
          onClick={this.handleLinkClick}
          title={node.name}
          to={`#${node.name}`}
        >
          {isEmpty(node.displayName) ? node.name : node.displayName}
        </TextLink>
        <ul className={classNames({ expanded: expanded })}>
          {hasChildren &&
            expanded &&
            childNodes.map((child, i) => (
              <TreeNode
                ref={ref => this.subTrees.set(i, ref)}
                {...this.props}
                key={child.name}
                expanded={autoExpand}
                node={child}
              />
            ))}
        </ul>
      </TreeNodeLink>
    );
  }
}

TreeNode.defaultProps = {
  autoExpandChildren: true,
  expanded: false
};

TreeNode.propTypes = {
  autoExpandChildren: PropTypes.bool,
  expanded: PropTypes.bool,
  hierarchy: PropTypes.object,
  node: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.object.isRequired
};

export default TreeNode;
