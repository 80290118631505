/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  BlockContainer,
  NoDataToShow,
  SubTitle,
  TitleBar
} from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { FailedFetchStateHandler } from 'app/components/utility';
import { inventoryCategories } from 'app/modules/inventory/constants';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  calculatedInventoryValuesSelector,
  fetchInventorySummary,
  inventorySummaryFetchStateSelector,
  inventorySummarySelector,
  updateInventoryFilter
} from 'app/redux/inventory';
import { fetchStatePropTypes } from 'app/redux/utils';
import { toFormattedDateTime } from 'app/utils';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { ElementsList, HeaderPlugs } from './components';

export const Inventory = props => {
  const {
    calculated,
    fetchState,
    inventorySummary,
    fetchInventorySummary,
    selected = {},
    updateInventoryFilter
  } = props;
  const { summary = [], apsummary } = inventorySummary;
  const { pending, complete, timeout } = fetchState;
  const { latestDate } = calculated;

  const hasInventory = !timeout && (!isEmpty(summary) || !isEmpty(apsummary));
  const noDataToShow = !pending && complete && !hasInventory;

  const displayCategories = inventoryCategories.slice();
  if (!isSpecificNodeType(selected, 'customer')) {
    displayCategories.pop();
  }
  const isPropertyLevel = isSpecificNodeType(selected, 'zonename');

  return (
    <BlockContainer>
      <TitleBar
        leftChildren={
          <Fragment>
            Inventory
            <SubTitle data-test-label="last-refresh-text">
              {hasInventory && `As of ${toFormattedDateTime(latestDate)}`}
              &nbsp;
            </SubTitle>
          </Fragment>
        }
        padUnderTitle={false}
        rightChildren={<HeaderPlugs />}
      />
      <div style={{ minHeight: '300px' }}>
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() => fetchInventorySummary({ skipCache: isPropertyLevel })}
        >
          {noDataToShow ? <NoDataToShow /> : null}
          {hasInventory && (
            <div
              className={hasInventory && pending ? 'fetch-state-pending' : ''}
              data-test-label="inventory-tile-device-list"
            >
              {displayCategories.map(category => (
                <ElementsList
                  key={category}
                  calculated={calculated}
                  category={category}
                  updateInventoryFilter={updateInventoryFilter}
                />
              ))}
            </div>
          )}
          {pending && <LoadingIconPlaceholder />}
        </FailedFetchStateHandler>
      </div>
    </BlockContainer>
  );
};

Inventory.propTypes = {
  calculated: PropTypes.object,
  fetchState: fetchStatePropTypes,
  inventorySummary: PropTypes.object,
  fetchInventorySummary: PropTypes.func.isRequired,
  selected: PropTypes.object,
  updateInventoryFilter: PropTypes.func.isRequired
};

const mapStateToProps = createSelector(
  inventorySummarySelector,
  inventorySummaryFetchStateSelector,
  calculatedInventoryValuesSelector,
  selectedPathSelector,
  (inventorySummary, fetchState, calculated, selected) => ({
    inventorySummary,
    fetchState,
    calculated,
    selected
  })
);

const mapDispatchToProps = { fetchInventorySummary, updateInventoryFilter };

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
