/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { styleLibrary } from 'app/constants';
import PropTypes from 'prop-types';
import React from 'react';

const Statistics = ({ data, colors }) => (
  <div className="d-flex justify-content-center align-items-center mt-2">
    {data.map(({ title = undefined, max, min, avg }, index) => (
      <div
        className="d-flex align-items-center mx-2 p-2"
        style={{ background: styleLibrary.lightBg }}
        key={title || `T${index + 1}`}
      >
        <span className="mx-2" style={{ color: colors[index] }}>
          {title || `T${index + 1}`}
        </span>
        <strong className="mx-2">[max]: {max}</strong>
        <strong className="mx-2">[min]: {min}</strong>
        <strong className="mx-2">[avg]: {avg}</strong>
      </div>
    ))}
  </div>
);

Statistics.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string, // Optional title for each set of statistics
      max: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string]),
      min: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string]),
      avg: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string])
    })
  ).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string)
};

export default Statistics;
