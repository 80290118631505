/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import About from './about';
import AlarmActions from './alarm-actions';
import Error from './error';
import Loading from './loading';

function ensureModalRoot() {
  const root = document.getElementById('modal-root');
  if (!root) {
    const modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', 'modal-root');
    document.body.appendChild(modalRoot);
    return document.getElementById('modal-root');
  }
  return root;
}

class ModalPortal extends Component {
  constructor(props) {
    super(props);
    this.modalMountPoint = document.createElement('div');
  }

  getRoot = () => ensureModalRoot();

  componentDidMount() {
    this.modalMountPoint.style.position = 'fixed';
    this.modalMountPoint.style.top = 0;

    this.getRoot().appendChild(this.modalMountPoint);
  }

  componentWillUnmount() {
    this.getRoot().removeChild(this.modalMountPoint);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.modalMountPoint);
  }
}

ModalPortal.propTypes = {
  children: PropTypes.any
};

export const AboutModal = props => (
  <ModalPortal>
    <About {...props} />
  </ModalPortal>
);

export const ErrorModal = props => (
  <ModalPortal>
    <Error {...props} />
  </ModalPortal>
);

export const LoadingModal = props => (
  <ModalPortal>
    <Loading {...props} />
  </ModalPortal>
);

export const AlarmActionsModal = props => (
  <ModalPortal>
    <AlarmActions {...props} />
  </ModalPortal>
);
