/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { uniqBy } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { FormDropDown } from 'app/components/forms';

// These are hand-picked time zones representing all the zones across the globe. We need
// to choose a named value to build the drop-down so that our `moment` library can calculate
// current timezones, labels and offsets correctly.
const timezones = [
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'America/St_Johns',
  'America/Halifax',
  'America/Argentina/Buenos_Aires',
  'America/Noronha',
  'Etc/UTC',
  'Europe/London',
  'Europe/Paris',
  'Europe/Sofia',
  'Europe/Moscow',
  'Asia/Tehran',
  'Asia/Baku',
  'Asia/Kabul',
  'Asia/Karachi',
  'Asia/Kolkata',
  'Asia/Almaty',
  'Asia/Yangon',
  'Asia/Bangkok',
  'Asia/Hong_Kong',
  'Asia/Tokyo',
  'Australia/Adelaide',
  'Australia/Sydney',
  'Antarctica/Macquarie',
  'Pacific/Auckland'
];

const dropDownEntries = timezones.map(t => {
  const zone = moment().tz(t);
  const offsetFormat = zone.format('Z');
  const nameFormat = zone.format('z');
  let offset = parseInt(zone.format('Z'), 10);

  const additionalOffset = offsetFormat.includes(':30') ? 0.5 : 0;
  if (offset > 0) {
    offset += additionalOffset;
  } else {
    offset -= additionalOffset;
  }

  return {
    value: offset.toString(),
    text: `(UTC ${offsetFormat}) ${/[A-Z]/.test(nameFormat) ? nameFormat : ''}`
  };
});

export const TimeZoneSelector = props => {
  const { local, onValueChanged } = props;

  return (
    <FormDropDown
      fieldId="local-time-zone"
      groupClasses={['mb-0']}
      value={local.toString()}
      handleValueChanged={(ignore, value, src) => onValueChanged(value, src)}
      dataset={uniqBy(dropDownEntries, 'value')}
      small
    />
  );
};

TimeZoneSelector.propTypes = {
  local: PropTypes.number,
  onValueChanged: PropTypes.func
};
