/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { cloneDeep, countBy, isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { AlarmDoughnut, alarmSeverityTypes } from './';

const DoughnutStack = styled.div.attrs({
  className: 'position-relative d-flex mx-auto w-75'
})``;

// const CardDeck = styled.div.attrs({
//   className: 'card-deck my-2 mx-auto'
// })`
//   width: 60%;
// `;

export const AlarmsStatistics = ({
  alarms,
  severity,
  handleSeverityFilter
}) => {
  let totalCount = 0;

  const alarmTypes = cloneDeep(alarmSeverityTypes);
  alarmTypes.forEach(a => (a['count'] = 0));

  if (isArray(alarms) && !isEmpty(alarms)) {
    totalCount = alarms.length;

    const {
      Disaster = 0,
      High = 0,
      Average = 0,
      Warning = 0,
      Information = 0,
      ...rest
    } = countBy(alarms, a => a.severity);

    alarmTypes.forEach(a => {
      switch (a.name) {
        case 'disaster':
          a['count'] = Disaster;
          break;
        case 'high':
          a['count'] = High;
          break;
        case 'avg':
          a['count'] = Average;
          break;
        case 'warn':
          a['count'] = Warning;
          break;
        case 'info':
          a['count'] = Information;
          break;
        case 'other':
          a['count'] = isEmpty(rest)
            ? 0
            : Object.values(rest).reduce((r, v) => r + v, 0);
          break;
        default:
          a['count'] = 0;
          break;
      }
    });
  }

  return (
    <DoughnutStack>
      {alarmTypes.map(alarm => (
        <AlarmDoughnut
          key={`${alarm.key || alarm.name}-${alarm.count}-${totalCount}`}
          nameKey={alarm.key || alarm.name}
          name={alarm.label}
          count={alarm.count}
          totalCount={totalCount}
          color={alarm.color}
          handleSeverityFilter={handleSeverityFilter}
          disabled={severity.size > 0 && !severity.has(alarm.key || alarm.name)}
        />
      ))}
    </DoughnutStack>
  );
  // return (
  //   <CardDeck>
  //     <AlarmCard
  //       name="Disaster"
  //       count={alarmsCount.disaster}
  //       color={styleLibrary.alarms.disaster}
  //     />
  //     <AlarmCard
  //       name="High"
  //       count={alarmsCount.high}
  //       color={styleLibrary.alarms.high}
  //     />
  //     <AlarmCard
  //       name="Average"
  //       count={alarmsCount.avg}
  //       color={styleLibrary.alarms.avg}
  //     />
  //     <AlarmCard
  //       name="Warning"
  //       count={alarmsCount.warn}
  //       color={styleLibrary.alarms.warn}
  //     />
  //     <AlarmCard
  //       name="Information"
  //       count={alarmsCount.info}
  //       color={styleLibrary.alarms.info}
  //     />
  //     <AlarmCard
  //       name="Other"
  //       count={alarmsCount.other}
  //       color={styleLibrary.alarms.other}
  //     />
  //   </CardDeck>
  // );
};

AlarmsStatistics.defaultProps = {
  alarms: [],
  severity: new Set()
};

AlarmsStatistics.propTypes = {
  alarms: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  severity: PropTypes.object,
  handleSeverityFilter: PropTypes.func
};

export default AlarmsStatistics;
