/*
 * Copyright 2018-2022 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FaIcon } from 'app/components/icons';
import { allSelection } from 'app/constants';
import { getSubCustomers, getUniqueVerticals } from 'app/utils';
import { isEmpty, isEqual } from 'lodash';
import { ReportFilterWrapper } from 'app/modules/reports/utils';
// import SwitchReportTable from './switch-report-table';

class GenerateSwitchPortReport extends Component {
  constructor(props) {
    super(props);

    const { vertical } = props.vertSSIDFilter;
    this.state = {
      ddVerticals: allSelection,
      ddSubCustomers: allSelection,
      vertical,
      subcustomer: 'all',
      showTable: false
    };
  }

  componentDidMount = () => {
    const {
      hierarchy,
      selectedPath,
      isTableReportGenerated,
      updateTableReportStatus
    } = this.props;
    const ddVerticals = getUniqueVerticals(hierarchy, selectedPath);
    const ddSubCustomers = getSubCustomers(hierarchy, selectedPath);

    this.setState({
      ddVerticals,
      ddSubCustomers,
      vertical: ddVerticals[0].value,
      subcustomer: ddSubCustomers[0].value
    });
    if (isTableReportGenerated) {
      this.setState({ showTable: true });
    } else {
      updateTableReportStatus(false);
    }
  };

  componentDidUpdate = prevProps => {
    const { selectedPath, hierarchy } = this.props;
    const { selectedPath: prevselectedPath } = prevProps;

    if (!isEqual(selectedPath, prevselectedPath)) {
      const ddVerticals = getUniqueVerticals(hierarchy, selectedPath);
      const ddSubCustomers = getSubCustomers(hierarchy, selectedPath);
      this.setState({
        ddVerticals,
        ddSubCustomers,
        vertical: ddVerticals[0].value,
        subcustomer: ddSubCustomers[0].value
      });
    }
  };

  generateReport = () => {
    const {
      handleGenerateReport,
      selectedPath,
      updateTableReportStatus
    } = this.props;
    const { vertical, subcustomer } = this.state;

    handleGenerateReport({
      property: selectedPath,
      vertical,
      subcustomer
    });
    this.setState({ showTable: true }, () => {
      updateTableReportStatus(true);
    });
  };

  getSwitchReportDescription = () => {
    const { selectedPath } = this.props;
    const { vertical, subcustomer } = this.state;
    const { name, type } = selectedPath;
    const subcustomerDescription =
      subcustomer === 'all'
        ? 'all subcustomers'
        : `subcustomer '${subcustomer}'`;
    const verticalDescription =
      vertical === 'all' ? 'all verticals' : `vertical '${vertical}'`;
    return (
      <Fragment>
        <p>
          A new switch port report will be created for the{' '}
          <b>
            {type} {name}
          </b>
          , covering <b>{subcustomerDescription}</b> and{' '}
          <b>{verticalDescription}</b>.
        </p>
        <p>
          Please use the tree in the left sidebar to change scope or the fields
          above to adjust filters. Click the button below to proceed.
        </p>
      </Fragment>
    );
  };

  handleFieldChange = field => e => {
    const fieldValue = e.target.value;
    this.setState(
      prevState => ({
        [field]: fieldValue,
        showTable: prevState.showTable
      }),
      () => {
        this.props.updateTableReportStatus(false);
      }
    );
  };

  render = () => {
    const {
      switchPortReportFetchState: { inProgress },
      selectedPath,
      hierarchy,
      isTableReportGenerated
    } = this.props;
    const { vertical, subcustomer, showTable } = this.state;

    const ddVerticals = getUniqueVerticals(hierarchy, selectedPath);
    const ddSubCustomers = getSubCustomers(hierarchy, selectedPath);
    const buttonDisabled = inProgress || isTableReportGenerated;

    return (
      <Fragment>
        <>
          {!isEmpty(selectedPath) && (
            <Fragment>
              <div className="d-flex justify-content-around align-items-center my-3 report-properties mr-4">
                <ReportFilterWrapper>
                  <label
                    className="col-form-label-sm pr-2"
                    htmlFor="subcustomer-filter"
                    style={{ whiteSpace: 'pre' }}
                  >
                    Sub-customer:
                  </label>
                  <select
                    className="form-control"
                    id="subcustomer-filter"
                    onChange={this.handleFieldChange('subcustomer')}
                    value={subcustomer}
                  >
                    {ddSubCustomers.map(({ value, text }) => (
                      <option key={`subcustomer-${value}`} value={value}>
                        {text}
                      </option>
                    ))}
                  </select>
                </ReportFilterWrapper>
                <span className="mr-4" />
                <ReportFilterWrapper>
                  <label
                    className="col-form-label-sm pr-2"
                    htmlFor="vertical-filter"
                  >
                    Vertical:
                  </label>
                  <select
                    className="form-control"
                    id="vertical-filter"
                    onChange={this.handleFieldChange('vertical')}
                    value={vertical}
                  >
                    {ddVerticals.map(({ value, text }) => (
                      <option key={`vertical-${value}`} value={value}>
                        {text}
                      </option>
                    ))}
                  </select>
                </ReportFilterWrapper>
              </div>
              <div className="text-center">
                {this.getSwitchReportDescription()}
                <p>
                  <button
                    className="btn btn-primary"
                    disabled={buttonDisabled}
                    onClick={this.generateReport}
                  >
                    {inProgress ? (
                      <span>
                        <FaIcon icon="circle-notch" spin classes={['mr-2']} />
                        Generating...
                      </span>
                    ) : (
                      'Generate Switch Port Report'
                    )}
                  </button>
                </p>
              </div>
            </Fragment>
          )}
        </>
        {buttonDisabled || showTable ? <div /> : <div />}
      </Fragment>
    );
  };
}

GenerateSwitchPortReport.propTypes = {
  isTableReportGenerated: PropTypes.bool,
  switchPortReportFetchState: PropTypes.object,
  hierarchy: PropTypes.object,
  selectedPath: PropTypes.object,
  vertSSIDFilter: PropTypes.object,
  handleGenerateReport: PropTypes.func,
  updateTableReportStatus: PropTypes.func
};

export default GenerateSwitchPortReport;
