/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { ComposedChart, Line, Tooltip, XAxis, YAxis } from 'recharts';

import { ChartFlexContainer } from 'app/components/charts';
import { tick } from 'app/components/charts/constants';
import {
  bandwidthUnits,
  commonPlotProps,
  dataRateUnits,
  styleLibrary
} from 'app/constants';
import {
  createFormatAxisValue,
  getReadableTimeFormat,
  getScaledDisplayValue
} from 'app/utils';

import { ComparisonTooltip } from './';
import { comparisonDataSets } from '../constants';

const componentId = 'compare-trends-chart';

export const CompareTrendsChart = props => {
  const { data, metric, lineColor } = props;

  // This forces the data values into a structure that will render in the chart
  const displayData = (data[metric] || []).map(x =>
    Array.isArray(x.value) ? x : { label: x.label, value: [x.value] }
  );

  const numberOfLines = isEmpty(displayData) ? 0 : displayData[0].value;
  const maxDataValue = Math.max(...displayData.map(x => Math.max(...x.value)));
  const formatAxisValue = createFormatAxisValue();

  return (
    <ChartFlexContainer data-test-label={componentId} width="99%" height={200}>
      <ComposedChart data={displayData} margin={{ top: 15, right: 20 }}>
        <XAxis
          dataKey="label"
          tick={tick}
          scale="point"
          tickFormatter={t => getReadableTimeFormat(t)}
        />
        <YAxis
          tick={tick}
          tickCount={Math.min(maxDataValue + 1, 5)}
          tickFormatter={t => {
            if (['bandwidth', 'download', 'upload'].includes(metric)) {
              const units =
                metric === 'bandwidth' ? bandwidthUnits : dataRateUnits;
              return formatAxisValue(
                t,
                (v, d) => getScaledDisplayValue(v, units, null, d),
                1
              );
            } else {
              return getScaledDisplayValue(
                t,
                ['', 'k', 'm', 'b'],
                null,
                0,
                1000
              );
            }
          }}
        />
        {numberOfLines &&
          [...numberOfLines].map((x, idx) => (
            <Line
              dataKey={`value[${idx}]`}
              isAnimationActive={false}
              key={`chart-line-${idx}`}
              stroke={lineColor[idx]}
              {...commonPlotProps}
            />
          ))}
        <Tooltip
          content={
            <ComparisonTooltip
              displayFn={comparisonDataSets[metric].displayFn}
            />
          }
          contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
          offset={0}
          wrapperStyle={{ marginLeft: 80 }}
        />
      </ComposedChart>
    </ChartFlexContainer>
  );
};

CompareTrendsChart.propTypes = {
  data: PropTypes.object.isRequired,
  metric: PropTypes.string.isRequired,
  lineColor: PropTypes.arrayOf(PropTypes.string)
};
