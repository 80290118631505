/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { get, has, isEmpty } from 'lodash';

const zeroSessionClientDataPoint = { activeClients: 0, activeSessions: 0 };
const result = {};

export const networkDetailsTransform = (dataDetail, dataSummary = {}) => {
  if (!isEmpty(dataDetail)) {
    result['sessionCountByAPinZone'] = get(
      dataDetail,
      'SPClientSessionTrend.sessionCountByAPinZone',
      []
    );
    result['sessionCountByAPinZoneInfo'] = get(
      dataDetail,
      'SPClientSessionTrend.info',
      {}
    );
  }

  if (!isEmpty(dataSummary)) {
    result['bandwidthByAPinZone'] = get(
      dataSummary,
      'SPTrendUsage.bandwidthByAPinZone',
      []
    );
    result['bandwidthByAPinZoneInfo'] = get(
      dataSummary,
      'SPTrendUsage.info',
      {}
    );
  }
  const { bandwidthByAPinZone = [], sessionCountByAPinZone = [] } = result;

  return bandwidthByAPinZone.map((dataPoint, i) => {
    const clientSessDataPoint =
      sessionCountByAPinZone[i] || zeroSessionClientDataPoint;

    return {
      label: dataPoint.timestamp,
      download: dataPoint.bandwidthRXInMB,
      upload: dataPoint.bandwidthTXInMB,
      total: dataPoint.bandwidthTXInMB + dataPoint.bandwidthRXInMB,
      clients: clientSessDataPoint.activeClients,
      sessions: clientSessDataPoint.activeSessions
    };
  });
};

export const customNetworkTransform = (dataDetail, dataSummary = {}) => {
  if (!isEmpty(dataDetail)) {
    result['sessionCountByAPinZone'] = get(dataDetail, 'data', []);
    result['sessionCountByAPinZoneInfo'] = get(dataDetail, 'info', {});
  }

  if (!isEmpty(dataSummary)) {
    result['bandwidthByAPinZone'] = get(dataSummary, 'BandwidthTrend', []);
    result['bandwidthByAPinZoneInfo'] = get(
      dataSummary,
      'BandwidthTrendInfo',
      {}
    );
  }
  const { bandwidthByAPinZone = [], sessionCountByAPinZone = [] } = result;

  return bandwidthByAPinZone.map((dataPoint, i) => {
    const clientSessDataPoint = sessionCountByAPinZone[i] || {
      cdr: 0,
      mqtt: 0,
      timestamp: dataPoint.timestamp
    };
    const bandwidthRX = dataPoint.bandwidthRXInKB / 1024;
    const bandwidthTX = dataPoint.bandwidthTXInKB / 1024;

    return {
      label: dataPoint.timestamp,
      download: bandwidthRX,
      upload: bandwidthTX,
      total: bandwidthRX + bandwidthTX,
      cdr: clientSessDataPoint.cdr,
      mqtt: clientSessDataPoint.mqtt
    };
  });
};

export const usageSummaryThroughputTransform = (data = {}) => {
  if (!isEmpty(data)) {
    data['throughputByAPinZone'] = get(
      data,
      'SPThroughputTrend.throughputByAPinZone',
      []
    );
    data['throughputByAPinZoneInfo'] = get(data, 'SPThroughputTrend.info', {});
  }
  const { throughputByAPinZone = [] } = data;

  return throughputByAPinZone.map(dataPoint => {
    return {
      downloadrate: dataPoint.throughputRXInKbps,
      uploadrate: dataPoint.throughputTXInKbps,
      label: dataPoint.timestamp
    };
  });
};

export const throughputTransform = (data = {}) => {
  const { DeviceBandwidthTrend = [] } = data;

  return DeviceBandwidthTrend.filter(d => has(d, 'receivedInbps')).map(
    dataPoint => {
      return {
        downloadrate: dataPoint.receivedInbps,
        uploadrate: dataPoint.transmittedInbps,
        label: dataPoint.time
      };
    }
  );
};
