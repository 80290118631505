/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { cloneDeep, findIndex } from 'lodash';

export const applyLegendToggles = (srcData, toggles, textField, valueField) => {
  const chartData = cloneDeep(srcData);
  toggles.forEach(x => {
    const idx = findIndex(chartData, { [textField]: x });
    chartData[idx] = {}; // assigning an empty object to chartData[idx] before setting its properties
    chartData[idx][valueField] = 0;
  });

  return chartData;
};
