/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import {
  BlockContainer,
  NoDataToShow,
  TitleBar
} from 'app/components/elements';
import { customDateRangeNames } from 'app/components/filters/constants';
import {
  ExportCsvIcon,
  ExportPdfIcon,
  FaIcon,
  LoadingIconPlaceholder,
  StyledExportButton
} from 'app/components/icons';
import { AlarmActionsModal } from 'app/components/modals';
import { SeverityColorBox, StatefulTable } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  MuiTableOptions,
  customMuiTableThemeWithDefualtCursor,
  styleLibrary
} from 'app/constants';
import {
  downloadAlarmsAsCsv,
  downloadAlarmsAsPdf,
  fetchZabbixAlarms,
  updateZabbixAlarmActions,
  zabbixAlarmActionsSelector,
  zabbixAlarmsFetchStateSelector,
  zabbixAlarmsSelector
} from 'app/redux/alarms';
import {
  dateRangeFilterLabelSelector,
  dateRangeFilterSelector,
  vertSSIDFilterSelector
} from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { closeModal, openModal } from 'app/redux/ui';
import { fetchStatePropTypes } from 'app/redux/utils';
import { getPrettyDurationFormat, getSortResult } from 'app/utils';
import {
  filter,
  find,
  findIndex,
  get,
  has,
  includes,
  isArray,
  isEmpty,
  isEqual,
  isString,
  keys,
  some
} from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import { createSelector } from 'reselect';
import { alarmSeverityTypes, AlarmsStatistics } from '.';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import moment from 'moment';

const alarmActionsModalKey = 'alarm-actions';

export class AlarmsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      severityFilter: new Set(),
      includeResolved: false,
      alarmSearch: ''
    };
  }

  componentDidMount = () => {
    const { fetchZabbixAlarms, dateRangeFilterLabel } = this.props;
    const { includeResolved } = this.state;
    fetchZabbixAlarms({
      includeResolved:
        dateRangeFilterLabel === customDateRangeNames.SHOW_ALL_OPEN_ITEMS
          ? false
          : includeResolved
    });
  };

  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilter,
      dateRangeFilterLabel,
      fetchZabbixAlarms,
      vertSSIDFilter,
      alarmActions,
      openModal
    } = this.props;
    const {
      selectedPath: prevSelectedPath,
      dateRangeFilter: prevDateRangeFilter,
      vertSSIDFilter: prevVertSSIDFilter,
      alarmActions: prevAlarmActions
    } = prevProps;
    const { includeResolved } = this.state;
    const isCustomMonths = /^\d{2}\/\d{2}\/\d{4} - \d{2}\/\d{2}\/\d{4}$/.test(
      dateRangeFilterLabel
    );
    const isOpenItems =
      dateRangeFilterLabel === customDateRangeNames.SHOW_ALL_OPEN_ITEMS;
    const [startDate, endDate] = dateRangeFilterLabel.split(' - ');
    const durationInDays = moment(endDate).diff(moment(startDate), 'days');
    let resolvedIncluded = includeResolved;

    if ((isCustomMonths && durationInDays > 31) || isOpenItems) {
      resolvedIncluded = false;
    }

    if (
      (prevSelectedPath && prevSelectedPath.id !== selectedPath.id) ||
      !isEqual(prevDateRangeFilter, dateRangeFilter) ||
      !isEqual(prevVertSSIDFilter, vertSSIDFilter)
    ) {
      this.setState(
        { alarmSearch: '', includeResolved: resolvedIncluded },
        () => {
          fetchZabbixAlarms({
            includeResolved: resolvedIncluded
          });
        }
      );
    }

    if (!isEqual(prevAlarmActions, alarmActions) && !isEmpty(alarmActions)) {
      openModal(alarmActionsModalKey);
    }
  };

  getErrorMessage = () => {
    const { selectedPath } = this.props;
    return `No alarms found ${
      selectedPath.type === 'zonename' ? 'for this property' : ''
    }`;
  };

  toggleIncludeResolved = checked => {
    const { fetchZabbixAlarms } = this.props;
    this.setState({ includeResolved: checked }, () =>
      fetchZabbixAlarms({ includeResolved: checked })
    );
  };

  handleOnClickSeverityFilter = severity => {
    const { severityFilter } = this.state;
    severityFilter.has(severity)
      ? severityFilter.delete(severity)
      : severityFilter.add(severity);
    if (isEqual(severityFilter.size, alarmSeverityTypes.length)) {
      severityFilter.clear();
    }
    this.setState({ severityFilter });
  };

  filterAlarmsBySearch = alarms => {
    const { alarmSearch } = this.state;
    if (!isEmpty(alarmSearch)) {
      alarms = filter(
        alarms,
        a =>
          !isEmpty(
            find(
              keys(a),
              key =>
                isString(a[key]) &&
                includes(a[key].toLowerCase(), alarmSearch.toLowerCase())
            )
          )
      );
    }
    return alarms;
  };

  filterAlarmsBySeverity = alarms => {
    const { severityFilter } = this.state;

    if (!severityFilter.size) {
      return alarms;
    }

    const mainTypes = alarmSeverityTypes
      .filter(a => !isEqual(a.name, 'other'))
      .map(a => a.key);

    const filteredAlarms = alarms.filter(
      i => has(i, 'severity') && severityFilter.has(i.severity.toLowerCase())
    );
    const otherAlarms = !severityFilter.has('other')
      ? []
      : alarms.filter(
        i =>
          !has(i, 'severity') ||
            !includes(mainTypes, i.severity.toLowerCase())
      );
    return [...filteredAlarms, ...otherAlarms];
  };

  closeAlarmActionsModal = () => {
    const { updateZabbixAlarmActions, closeModal } = this.props;
    closeModal(alarmActionsModalKey);
    updateZabbixAlarmActions([]);
  };

  severityBodyRenderer = value => {
    const type = find(alarmSeverityTypes, ['label', value]);
    const color = type ? type.color : styleLibrary.alarms.other;
    return <SeverityColorBox label={value} color={color} />;
  };

  actionsBodyRenderer = value => {
    if (isEmpty(value)) {
      return '';
    }
    return (
      <button
        className="btn btn-sm"
        onClick={() => this.props.updateZabbixAlarmActions(value)}
      >
        <FaIcon
          icon="exclamation-triangle"
          style={{
            fontSize: 22,
            textAlign: 'center',
            color: styleLibrary.primaryButtonBg
          }}
        />
      </button>
    );
  };

  getZabbixAlarmsTableColumns = () => {
    const {
      zabbixAlarms: { alarms },
      selectedPath
    } = this.props;
    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');
    const displayProperty = keys(alarms[0]).includes('property');
    const displayActions = some(
      alarms,
      a => has(a, 'actions') && isArray(a['actions']) && !isEmpty(a['actions'])
    );

    return [
      { name: 'time', label: isPropertyLevel ? 'Date/Time' : 'Date/Time (UTC)' },
      { name: 'alarm_type', label: 'Alarm Type' },
      {
        name: 'property',
        label: 'Property',
        options: { display: displayProperty }
      },
      { name: 'host', label: 'Host' },
      { name: 'problem', label: 'Problem' },
      {
        name: 'severity',
        label: 'Severity',
        options: {
          customBodyRender: this.severityBodyRenderer,
          sortDirection: 'asc'
        }
      },
      {
        name: 'duration',
        label: 'Duration',
        options: { customBodyRender: getPrettyDurationFormat }
      },
      { name: 'acknowledged', label: 'Ack' },
      { name: 'status', label: 'Status' },
      {
        name: 'actions',
        label: 'Comments',
        options: {
          display: displayActions,
          sort: false,
          customBodyRender: this.actionsBodyRenderer
        }
      }
    ];
  };

  renderAlarmsActions = () => {
    const { downloadAlarmsAsCsv, downloadAlarmsAsPdf } = this.props;
    const { alarmSearch } = this.state;

    return (
      <div className="d-flex justify-content-between p-3">
        <div />
        <div className="d-flex justify-content-end align-items-center">
          <FaIcon icon="search" classes={['mr-2']} />
          <label className="sr-only">Search Alarms</label>
          <input
            className="p-1"
            id="alarm-search"
            onChange={e => this.setState({ alarmSearch: e.target.value })}
            placeholder="Search alarms..."
            style={{ width: 250 }}
            type="text"
            value={alarmSearch}
          />
          <StyledExportButton
            title="Generate PDF file containing the Alarms table"
            onClick={() =>
              downloadAlarmsAsPdf({
                columns: this.getZabbixAlarmsTableColumns().filter(c =>
                  get(c, 'options.display', true)
                )
              })
            }
          >
            <ExportPdfIcon className="ml-3" />
          </StyledExportButton>
          <StyledExportButton
            title="Generate CSV file containing the Alarms table"
            onClick={() =>
              downloadAlarmsAsCsv({
                columns: this.getZabbixAlarmsTableColumns().filter(c =>
                  get(c, 'options.display', true)
                )
              })
            }
          >
            <ExportCsvIcon className="ml-3" />
          </StyledExportButton>
        </div>
      </div>
    );
  };

  sortAlarmsTable = (data, col, order) => {
    data.sort((a, b) => {
      let valA = a.data[col];
      let valB = b.data[col];
      if (col === 5) {
        valA = findIndex(alarmSeverityTypes, ['label', valA]);
        valB = findIndex(alarmSeverityTypes, ['label', valB]);
      }
      return getSortResult(valA, valB, order);
    });
    return data;
  };

  render = () => {
    const {
      showAlarmsStatistics,
      dateRangeFilterLabel,
      selectedPath,
      zabbixAlarms: { alarms },
      zabbixAlarmsFetchState,
      alarmActions
    } = this.props;
    const { severityFilter, includeResolved } = this.state;

    const { pending, complete } = zabbixAlarmsFetchState;

    const hasData = !isEmpty(alarms) && typeof alarms !== 'string';
    const noDataToShow = !pending && complete && !hasData;

    const isNodeCustomer = isSpecificNodeType(selectedPath, 'customer');
    const isCustomMonths = /^\d{2}\/\d{2}\/\d{4} - \d{2}\/\d{2}\/\d{4}$/.test(
      dateRangeFilterLabel
    );
    const isOpenItems =
      dateRangeFilterLabel === customDateRangeNames.SHOW_ALL_OPEN_ITEMS;
    const [startDate, endDate] = dateRangeFilterLabel.split(' - ');
    const durationInDays = moment(endDate).diff(moment(startDate), 'days');
    let showIncludeResolvedSwitch = true;
    if (isNodeCustomer) {
      showIncludeResolvedSwitch = !(
        (isCustomMonths && durationInDays > 31) ||
        isOpenItems
      );
    } else {
      showIncludeResolvedSwitch = !isOpenItems;
    }

    const tableOptions = {
      ...MuiTableOptions,
      customSort: this.sortAlarmsTable
    };

    return (
      <BlockContainer classes={['col', 'mt-3']}>
        <TitleBar
          leftChildren="Alarms"
          padUnderTitle={false}
          rightChildren={
            <div className="d-flex justify-content-spread align-items-center">
              {showIncludeResolvedSwitch && (
                <Fragment>
                  <span style={{ fontSize: 14 }}>Include Resolved</span>
                  <Switch
                    onChange={this.toggleIncludeResolved}
                    checked={includeResolved}
                    height={25}
                    width={50}
                    className="ml-1 mr-5"
                  />
                </Fragment>
              )}
              <span style={{ fontSize: styleLibrary.fontSizes.secondaryTitle }}>
                {dateRangeFilterLabel}
              </span>
            </div>
          }
        />
        {showAlarmsStatistics && (
          <AlarmsStatistics
            alarms={complete ? this.filterAlarmsBySearch(alarms) : []}
            severity={severityFilter}
            handleSeverityFilter={this.handleOnClickSeverityFilter}
          />
        )}
        {!pending && hasData && this.renderAlarmsActions()}
        {noDataToShow ? (
          <NoDataToShow
            message={this.getErrorMessage()}
            style={{ background: 'none', position: 'relative' }}
          />
        ) : (
          <FailedFetchStateHandler
            fetchState={zabbixAlarmsFetchState}
            retry={() =>
              fetchZabbixAlarms({
                includeResolved:
                  dateRangeFilterLabel ===
                  customDateRangeNames.SHOW_ALL_OPEN_ITEMS
                    ? false
                    : includeResolved
              })
            }
          >
            <div
              className={pending || noDataToShow ? 'fetch-state-pending' : ''}
            >
              {complete && (
                <MuiThemeProvider
                  theme={createMuiTheme(customMuiTableThemeWithDefualtCursor)}
                >
                  <StatefulTable
                    columns={this.getZabbixAlarmsTableColumns()}
                    data={this.filterAlarmsBySeverity(
                      this.filterAlarmsBySearch(alarms)
                    )}
                    options={tableOptions}
                    tableKey={selectedPath.id}
                  />
                </MuiThemeProvider>
              )}
            </div>
          </FailedFetchStateHandler>
        )}
        {pending && <LoadingIconPlaceholder />}
        {!isEmpty(alarmActions) && (
          <AlarmActionsModal
            actions={alarmActions}
            closeModal={this.closeAlarmActionsModal}
          />
        )}
      </BlockContainer>
    );
  };
}

AlarmsTable.propTypes = {
  showAlarmsStatistics: PropTypes.bool,
  dateRangeFilter: PropTypes.object,
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  fetchZabbixAlarms: PropTypes.func,
  zabbixAlarms: PropTypes.object,
  zabbixAlarmsFetchState: fetchStatePropTypes,
  vertSSIDFilter: PropTypes.object,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  updateZabbixAlarmActions: PropTypes.func,
  alarmActions: PropTypes.array,
  downloadAlarmsAsCsv: PropTypes.func,
  downloadAlarmsAsPdf: PropTypes.func
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  zabbixAlarmsSelector,
  zabbixAlarmsFetchStateSelector,
  vertSSIDFilterSelector,
  zabbixAlarmActionsSelector,
  (
    dateRangeFilter,
    dateRangeFilterLabel,
    selectedPath,
    zabbixAlarms,
    zabbixAlarmsFetchState,
    vertSSIDFilter,
    alarmActions
  ) => ({
    dateRangeFilter,
    dateRangeFilterLabel,
    selectedPath,
    zabbixAlarms,
    zabbixAlarmsFetchState,
    vertSSIDFilter,
    alarmActions
  })
);

const mapDispatchToProps = {
  fetchZabbixAlarms,
  openModal,
  closeModal,
  updateZabbixAlarmActions,
  downloadAlarmsAsCsv,
  downloadAlarmsAsPdf
};

export default connect(mapStateToProps, mapDispatchToProps)(AlarmsTable);
