/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  OverviewLegendtoolTip
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import {
  BlockContainer,
  NoDataToShow,
  TitleBar
} from 'app/components/elements';
import { dateRangeNames } from 'app/components/filters/constants';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { isPropertyNodeOrUnder } from 'app/components/layout/components/sidebar/utils';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  bandwidthUnits,
  commonPlotProps,
  styleLibrary,
  userGroupList,
  formatTraffic
} from 'app/constants';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  inventorySummaryFetchStateSelector,
  inventorySummarySelector
} from 'app/redux/inventory';
import {
  createNetworkDetailsChartInfoSelector,
  fetchNetworkDetails,
  networkDetailsChartDataSelector,
  networkDetailsFetchStateSelector,
  customNetworkDataSelector,
  networkSummarySelector
} from 'app/redux/network';
import { userGroupsSelector, createShowPanelSelector } from 'app/redux/app';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  calculateTimeZoneOffset,
  createFormatAxisValue,
  getReadableTimeFormat,
  getScaledDisplayValue
} from 'app/utils';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import {
  TimeZoneSelector,
  TrafficSummaryTooltip,
  XAxisTick,
  CsutomXAxisTick
} from './components';
import {
  sessionsLabel,
  trafficLabel,
  xAxisLabel,
  customxAxisLabel
} from './constants';
import Statistics from 'app/modules/properties/components/statistics';

const plotKeyLabelMap = {
  cdr: 'AAA Auth Clients',
  mqtt: 'Wireless Clients'
};

export class NetworkSummary extends Component {
  constructor(props) {
    super(props);

    const userTz = moment.tz.guess();
    const formattedUserZone = moment.tz(moment(), userTz).format('Z');
    let userOffset = parseInt(formattedUserZone, 10);

    if (formattedUserZone.includes(':30')) {
      if (userOffset < 0) {
        userOffset -= 0.5;
      } else {
        userOffset += 0.5;
      }
    }

    this.state = {
      hiddenPlotMap: {},
      titleToolTip: '',
      localTimeZone: userOffset,
      timeZoneName: moment.tz(moment(), userTz).format('z')
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedPath = {} } = this.props;
    if (
      prevState.selectedTab === 'throughput' &&
      selectedPath.type !== 'zonename'
    ) {
      this.setState({
        selectedTab: 'traffic'
      });
    }
  }

  isActivePlot = plotKey => {
    return this.state.hiddenPlotMap[plotKey]
      ? 'blank'
      : plotKey.replace(' ', '').toLowerCase();
  };

  clientActivePlot = plotKey => {
    const label = plotKeyLabelMap[plotKey];
    return this.state.hiddenPlotMap[label] ? 'blank' : plotKey;
  };

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  handleMouseOver = value => {
    switch (value) {
      case 'Clients':
        value = 'Unique Clients';
        break;
      case 'Sessions':
        value = 'Unique Sessions';
        break;
      case plotKeyLabelMap.cdr:
        value = 'AAA Auth Clients';
        break;
      case plotKeyLabelMap.mqtt:
        value = 'Wireless Clients';
        break;
      default:
        value = '';
    }
    this.setState({
      titleToolTip: value
    });
  };

  formatLeftTickValue = (value, axisFormatter) => {
    return axisFormatter(
      value,
      (v, d) => getScaledDisplayValue(v, bandwidthUnits, null, d),
      1,
      true
    );
  };
  formatRightTickValue = value => {
    return getScaledDisplayValue(value, ['', 'k', 'm', 'b'], null, 1, 1000);
  };

  handleTimeZoneChanged = (value, srcElement) => {
    const srcElementText = srcElement.options[
      srcElement.selectedIndex
    ].text.substring(12);

    this.setState({
      localTimeZone: parseFloat(value),
      timeZoneName: srcElementText
    });
  };

  renderTrafficSummary() {
    const {
      hiddenPlotMap,
      localTimeZone,
      timeZoneName,
      titleToolTip
    } = this.state;
    const {
      chartData,
      dateRangeFilterLabel,
      fetchNetworkDetails,
      info,
      selectedPath,
      trafficFetchState = {}
    } = this.props;
    const serverTimeZone = moment.tz(info.timezone).format('z');
    let serverOffset = 0;
    if (serverTimeZone !== 'UTC') {
      serverOffset = calculateTimeZoneOffset(info.timezone);
    }
    const { pending, complete, timeout } = trafficFetchState;
    const formatAxisValue = createFormatAxisValue();

    const noDataToShow = !pending && complete && isEmpty(chartData);
    const dataToRender = timeout || pending ? [] : chartData;
    const displayTwoHours =
      !isPropertyNodeOrUnder(selectedPath) &&
      dateRangeFilterLabel === dateRangeNames.LAST_24_HOURS;
    const maxRightAxisVal = Math.max(
      ...dataToRender.map(x => Math.max(x.clients, x.sessions))
    );

    return (
      <FailedFetchStateHandler
        fetchState={trafficFetchState}
        retry={() => fetchNetworkDetails()}
      >
        {noDataToShow ? (
          <NoDataToShow
            style={{
              height: 'calc(100% - 60px)',
              left: 0
            }}
          />
        ) : null}
        <ChartFlexContainer width="99%" height={550}>
          <ComposedChart
            data={dataToRender}
            margin={{
              ...chartMargins,
              left: 35,
              right: 15,
              bottom: displayTwoHours ? 40 : 35
            }}
          >
            <CartesianGrid vertical={false} />
            <Legend
              content={
                <OverviewLegendtoolTip
                  onClick={this.togglePlotVisibility}
                  onMouseOver={this.handleMouseOver}
                  title={titleToolTip}
                />
              }
              inactivePlots={hiddenPlotMap}
              verticalAlign="top"
            />
            <XAxis
              dataKey="label"
              label={xAxisLabel(dataToRender, info, displayTwoHours)}
              interval="preserveStartEnd"
              tick={
                displayTwoHours ? (
                  <XAxisTick
                    offset={localTimeZone}
                    label={timeZoneName}
                    serverTimeZone={serverTimeZone}
                    serverOffset={serverOffset}
                  />
                ) : (
                  true
                )
              }
              tickFormatter={t => getReadableTimeFormat(t)}
              scale="point"
            />
            <YAxis
              yAxisId="traffic"
              label={trafficLabel}
              stroke="rgb(31, 119, 180)"
              tick={tick}
              tickCount={8}
              tickFormatter={t => this.formatLeftTickValue(t, formatAxisValue)}
            />
            <YAxis
              yAxisId="sessions"
              label={sessionsLabel}
              orientation="right"
              stroke={styleLibrary.darkText}
              tick={tick}
              tickCount={Math.min(maxRightAxisVal + 1, 8)}
              tickFormatter={this.formatRightTickValue}
            />
            <Area
              dataKey={this.isActivePlot('Download')}
              name="Download"
              stroke={styleLibrary.downloadColor}
              fill={styleLibrary.downloadColor}
              yAxisId="traffic"
              {...commonPlotProps}
            />
            )
            <Area
              dataKey={this.isActivePlot('Upload')}
              name="Upload"
              stroke={styleLibrary.uploadColor}
              fill={styleLibrary.uploadColor}
              yAxisId="traffic"
              {...commonPlotProps}
            />
            <Line
              dataKey={this.isActivePlot('Total')}
              name="Total"
              stroke="rgb(31, 119, 180)"
              yAxisId="traffic"
              {...commonPlotProps}
            />
            <Line
              dataKey={this.isActivePlot('Sessions')}
              name="Sessions"
              stroke={styleLibrary.sessionsColor}
              yAxisId="sessions"
              strokeDasharray="0 5 5"
              {...commonPlotProps}
            />
            <Line
              dataKey={this.isActivePlot('Clients')}
              name="Clients"
              stroke={styleLibrary.clientsColor}
              yAxisId="sessions"
              strokeDasharray="5 5"
              {...commonPlotProps}
            />
            <Tooltip
              content={<TrafficSummaryTooltip />}
              contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
              offset={0}
              wrapperStyle={{ marginLeft: 80 }}
            />
          </ComposedChart>
        </ChartFlexContainer>
        {pending && <LoadingIconPlaceholder />}
        {displayTwoHours && (
          <div
            style={{ whiteSpace: 'nowrap' }}
            className="d-flex justify-content-start align-items-center my-3 ml-3"
          >
            <span className="mr-2">Select your timezone:</span>
            <TimeZoneSelector
              local={localTimeZone}
              onValueChanged={this.handleTimeZoneChanged}
            />
          </div>
        )}
      </FailedFetchStateHandler>
    );
  }

  renderNetworkClientTrends() {
    const {
      hiddenPlotMap,
      localTimeZone,
      timeZoneName,
      titleToolTip
    } = this.state;
    const {
      networkChart,
      dateRangeFilterLabel,
      fetchNetworkDetails,
      info,
      selectedPath,
      trafficFetchState = {}
    } = this.props;

    const serverTimeZone = moment.tz(info.timezone).format('z');
    let serverOffset = 0;
    if (serverTimeZone !== 'UTC') {
      serverOffset = calculateTimeZoneOffset(info.timezone);
    }
    const { pending, complete, timeout } = trafficFetchState;
    const formatAxisValue = createFormatAxisValue();

    const noDataToShow = !pending && complete && isEmpty(networkChart);
    const dataToRender = timeout || pending ? [] : networkChart;

    const displayTwoHours =
      !isPropertyNodeOrUnder(selectedPath) &&
      dateRangeFilterLabel === dateRangeNames.LAST_24_HOURS;
    const maxRightAxisVal = Math.max(
      ...dataToRender.map(x => Math.max(x.cdr, x.mqtt))
    );

    return (
      <FailedFetchStateHandler
        fetchState={trafficFetchState}
        retry={() => fetchNetworkDetails()}
      >
        {noDataToShow ? (
          <NoDataToShow
            style={{
              height: 'calc(100% - 60px)',
              left: 0
            }}
          />
        ) : null}
        <ChartFlexContainer width="99%" height={550}>
          <ComposedChart
            data={dataToRender}
            margin={{
              ...chartMargins,
              left: 35,
              right: 15,
              bottom: displayTwoHours ? 40 : 35
            }}
          >
            <CartesianGrid vertical={false} />
            <Legend
              content={
                <OverviewLegendtoolTip
                  onClick={this.togglePlotVisibility}
                  onMouseOver={this.handleMouseOver}
                  title={titleToolTip}
                />
              }
              inactivePlots={hiddenPlotMap}
              verticalAlign="top"
            />
            <XAxis
              dataKey="label"
              label={customxAxisLabel(
                dataToRender,
                info,
                displayTwoHours,
                dateRangeFilterLabel
              )}
              interval="preserveStartEnd"
              tick={
                displayTwoHours ? (
                  <CsutomXAxisTick
                    offset={localTimeZone}
                    label={timeZoneName}
                    serverTimeZone={serverTimeZone}
                    serverOffset={serverOffset}
                    displayTwoHours={displayTwoHours}
                    localTimeZone={localTimeZone}
                  />
                ) : (
                  true
                )
              }
              tickFormatter={t => getReadableTimeFormat(t)}
              scale="point"
            />
            <YAxis
              yAxisId="traffic"
              label={trafficLabel}
              stroke="rgb(31, 119, 180)"
              tick={tick}
              tickCount={8}
              tickFormatter={t => this.formatLeftTickValue(t, formatAxisValue)}
            />
            <YAxis
              yAxisId="authcdr"
              label={''}
              orientation="right"
              stroke={styleLibrary.darkText}
              tick={tick}
              tickCount={Math.min(maxRightAxisVal + 1, 8)}
              tickFormatter={this.formatRightTickValue}
            />
            <Area
              dataKey={this.isActivePlot('Download')}
              name="Download"
              stroke={styleLibrary.downloadColor}
              fill={styleLibrary.downloadColor}
              yAxisId="traffic"
              {...commonPlotProps}
            />
            )
            <Area
              dataKey={this.isActivePlot('Upload')}
              name="Upload"
              stroke={styleLibrary.uploadColor}
              fill={styleLibrary.uploadColor}
              yAxisId="traffic"
              {...commonPlotProps}
            />
            <Line
              dataKey={this.isActivePlot('Total')}
              name="Total"
              stroke="rgb(31, 119, 180)"
              yAxisId="traffic"
              {...commonPlotProps}
            />
            {Object.entries(plotKeyLabelMap).map(([key, label], index) => (
              <Line
                key={`${label}-${index}`}
                yAxisId="authcdr"
                dataKey={this.clientActivePlot(key)}
                name={label}
                stroke={styleLibrary.uniqueClientsColors[index]}
                strokeDasharray="5 5"
                {...commonPlotProps}
              />
            ))}
            <Tooltip
              content={<TrafficSummaryTooltip />}
              contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
              offset={0}
              wrapperStyle={{ marginLeft: 80 }}
            />
          </ComposedChart>
        </ChartFlexContainer>
        {pending && <LoadingIconPlaceholder />}
        <div>
          {this.renderBandwidthStats()}
          {displayTwoHours && (
            <div
              style={{ whiteSpace: 'nowrap' }}
              className="d-flex justify-content-start align-items-center my-3 ml-3"
            >
              <span className="mr-2">Select your timezone:</span>
              <TimeZoneSelector
                local={localTimeZone}
                onValueChanged={this.handleTimeZoneChanged}
              />
            </div>
          )}
        </div>
      </FailedFetchStateHandler>
    );
  }

  renderBandwidthStats = () => {
    const { summary } = this.props;

    let maxValueTX = 0;
    let minValueTX = 0;
    let avgValueTX = 0;
    let maxValueRX = 0;
    let minValueRX = 0;
    let avgValueRX = 0;

    if (summary && summary.BandwidthStats) {
      maxValueTX = summary.BandwidthStats.maxBandwidthTXUsageInMB || 0;
      minValueTX = summary.BandwidthStats.minBandwidthTXUsageInMB || 0;
      avgValueTX = summary.BandwidthStats.avgBandwidthTXUsageInMB || 0;
      maxValueRX = summary.BandwidthStats.maxBandwidthRXUsageInMB || 0;
      minValueRX = summary.BandwidthStats.minBandwidthRXUsageInMB || 0;
      avgValueRX = summary.BandwidthStats.avgBandwidthRXUsageInMB || 0;
    }

    const statsArray = [
      {
        title: 'Upload Stats:→',
        max: formatTraffic(maxValueTX),
        min: formatTraffic(minValueTX),
        avg: formatTraffic(avgValueTX)
      },
      {
        title: 'Download Stats:→',
        max: formatTraffic(maxValueRX),
        min: formatTraffic(minValueRX),
        avg: formatTraffic(avgValueRX)
      }
    ];

    return (
      <div className="mb-4">
        <Statistics
          data={statsArray}
          colors={styleLibrary.uniqueClientsColors}
        />
      </div>
    );
  };

  render() {
    const {
      dateRangeFilterLabel,
      userGroups = [],
      showNetworkTrendForLAC
    } = this.props;

    const isUserLAC = userGroupList.some(item => userGroups.includes(item));

    const renderNetworkTrends =
      showNetworkTrendForLAC && isUserLAC
        ? this.renderNetworkClientTrends()
        : this.renderTrafficSummary();

    return (
      <BlockContainer classes={['col']}>
        <TitleBar
          leftChildren="Network Summary"
          rightChildren={
            <span style={{ fontSize: styleLibrary.fontSizes.secondaryTitle }}>
              {dateRangeFilterLabel}
            </span>
          }
        />
        {renderNetworkTrends}
      </BlockContainer>
    );
  }
}

NetworkSummary.propTypes = {
  summary: PropTypes.object,
  networkChart: PropTypes.arrayOf(PropTypes.object),
  chartData: PropTypes.arrayOf(PropTypes.object),
  dateRangeFilterLabel: PropTypes.string,
  fetchNetworkDetails: PropTypes.func.isRequired,
  info: PropTypes.object,
  inventorySummaryFetchState: fetchStatePropTypes,
  inventorySummary: PropTypes.object,
  selectedPath: PropTypes.object,
  userGroups: PropTypes.array,
  showNetworkTrendForLAC: PropTypes.bool,
  trafficFetchState: fetchStatePropTypes
};

const mapStateToProps = createSelector(
  createShowPanelSelector('OverviewNetworkSummary'),
  createNetworkDetailsChartInfoSelector('throughputByAPinZone'),
  networkDetailsChartDataSelector,
  networkDetailsFetchStateSelector,
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  inventorySummaryFetchStateSelector,
  inventorySummarySelector,
  customNetworkDataSelector,
  networkSummarySelector,
  userGroupsSelector,
  (
    showNetworkTrendForLAC,
    info,
    chartData,
    trafficFetchState,
    dateRangeFilterLabel,
    selectedPath,
    inventorySummaryFetchState,
    inventorySummary,
    networkChart,
    summary,
    userGroups
  ) => ({
    showNetworkTrendForLAC,
    info,
    chartData,
    trafficFetchState,
    dateRangeFilterLabel,
    selectedPath,
    inventorySummaryFetchState,
    inventorySummary,
    networkChart,
    summary,
    userGroups
  })
);

export default connect(mapStateToProps, {
  fetchNetworkDetails
})(NetworkSummary);
