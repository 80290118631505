/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React from 'react';

import connected from 'resources/images/plug-connected.svg';
import disconnected from 'resources/images/plug-disconnected.svg';
import disabled from 'resources/images/plug-disabled.svg';

export const ConnectedIcon = props => (
  <img alt="Connected" src={connected} title="Connected" {...props} />
);

export const DisconnectedIcon = props => (
  <img alt="Disconnected" src={disconnected} title="Disconnected" {...props} />
);
export const DisabledIcon = props => (
  <img alt="Disabled" src={disabled} title="Disabled" {...props} />
);
