/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { styleLibrary } from 'app/constants';
import { getReadableTimeFormat } from 'app/utils';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

class AleUsersTrendChart extends Component {
  constructor(props) {
    super(props);
    this.state = { hiddenPlotMap: {} };
  }

  isActivePlot = plotKey => {
    return this.state.hiddenPlotMap[plotKey] ? 'blank' : plotKey;
  };

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  render() {
    const { data } = this.props;
    const dataSize = data.length;

    const availableWidth = 500;
    const minBarSize = 15;
    const maxBarSize = 20;
    const dynamicBarSize = Math.max(
      minBarSize,
      Math.min(maxBarSize, availableWidth / dataSize)
    );

    return (
      <Fragment>
        <ChartFlexContainer width="99%" height={300}>
          <BarChart
            data={data}
            margin={{ ...chartMargins, left: 15 }}
            barSize={dynamicBarSize}
            barCategoryGap={data.length <= 2 ? '10%' : '20%'}
            barGap={data.length <= 2 ? '5%' : '10%'}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              xAxisId="timeSeries"
              dataKey="label"
              label={{
                value: 'Date',
                position: 'bottom',
                offset: 5,
                fontWeight: 'bold',
                fontSize: styleLibrary.fontSizes.body
              }}
              interval="preserveStartEnd"
              tick={tick}
              tickFormatter={t => getReadableTimeFormat(t)}
              domain={['dataMin', 'dataMax']}
              // type="category"
              // scale="point"
            />
            <YAxis
              yAxisId="aleUsers"
              label={{
                value: 'Count',
                angle: -90,
                position: 'insideLeft',
                offset: 10,
                dy: 35,
                fontSize: styleLibrary.fontSizes.body
              }}
              stroke="rgb(31, 119, 180)"
              tick={tick}
              allowDecimals={false}
              interval="preserveStartEnd"
              type="number"
            />
            <Tooltip
              content={
                <MultiLineTooltip
                  labelFormatter={label => getReadableTimeFormat(label)}
                  keys={['Total Users', 'New Users']}
                  units={['']}
                  showAtSign={false}
                />
              }
              contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
              offset={0}
              wrapperStyle={{ marginLeft: 80 }}
              cursor={{ fill: 'rgba(0, 0, 255, 0.1)' }}
            />
            <Legend
              verticalAlign="top"
              height={36}
              inactivePlots={this.state.hiddenPlotMap}
              content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
            />
            <Bar
              yAxisId="aleUsers"
              xAxisId="timeSeries"
              dataKey={this.isActivePlot('Total Users')}
              name="Total Users"
              fill="#82ca9d"
              stroke={'#82ca9d'}
              stackId="a"
            />
            <Bar
              yAxisId="aleUsers"
              xAxisId="timeSeries"
              dataKey={this.isActivePlot('New Users')}
              name="New Users"
              fill="#8884d8"
              stroke={'#8884d8'}
              stackId="a"
            />
          </BarChart>
        </ChartFlexContainer>
      </Fragment>
    );
  }
}

AleUsersTrendChart.propTypes = { data: PropTypes.array };

export default AleUsersTrendChart;
