/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { NoDataToShow } from 'app/components/elements';
import {
  defaultHostDropdown,
  defaultHostMetricSelection,
  defaultMetricDropdown
} from 'app/components/filters/constants';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { userNameSelector } from 'app/redux/app';
import {
  dateRangeFilterLabelSelector,
  dateRangeFilterSelector,
  hostDropdownSelector,
  hostMetricFilterSelector,
  metricDropdownSelector,
  updateHostDropdown,
  updateHostMetricFilter,
  updateMetricDropdown
} from 'app/redux/filters';
import {
  fetchZabbixDataTrend,
  fetchZabbixElementReports,
  zabbixDataTrendSelector,
  zabbixElementReportsFetchStateSelector,
  zabbixElementReportsSelector
} from 'app/redux/inventory';
import { fetchStatePropTypes } from 'app/redux/utils';
import { defaultTo, isEmpty, isEqual, keys, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { defaultHostRegex, defaultMetricRegex } from '../utils';
import { HostMetricDropdowns, TrendChart } from './components';

export class PropertyDataTrend extends Component {
  componentDidUpdate = prevProps => {
    const {
      property,
      dateRangeFilter,
      zreports,
      fetchZabbixElementReports,
      fetchZabbixDataTrend,
      hostDropdown,
      metricDropdown
    } = this.props;
    const {
      property: prevProperty,
      dateRangeFilter: prevDateRangeFilter,
      zreports: prevZreports
    } = prevProps;

    const propertyChanged = property && prevProperty.id !== property.id;
    const dateRangeChanged = prevDateRangeFilter !== dateRangeFilter;
    const hasZreports = !isEmpty(zreports) && typeof zreports !== 'string';
    const zreportsChanged = !isEqual(prevZreports, zreports);
    const hasDefaultHostMetricDropdowns =
      isEqual(hostDropdown, defaultHostDropdown) &&
      isEqual(metricDropdown, defaultMetricDropdown);

    if (propertyChanged) {
      fetchZabbixElementReports({ property });
    }
    if (dateRangeChanged) {
      fetchZabbixDataTrend();
    }
    if (hasZreports && (zreportsChanged || hasDefaultHostMetricDropdowns)) {
      this.populateHostDropdown();
      this.populateDefaults(defaultHostRegex, defaultMetricRegex);
    }
  };

  populateHostDropdown = () => {
    const { zreports, updateHostMetricFilter, updateHostDropdown } = this.props;
    const hostDropdown = map(zreports, (v, k) => ({ value: k, text: k }));
    updateHostMetricFilter(defaultHostMetricSelection);
    updateHostDropdown(hostDropdown);
  };

  populateMetricDropdown = host => {
    const { zreports, updateMetricDropdown } = this.props;
    let metricDropdown = [];
    if (host !== '' && !isEmpty(zreports[host])) {
      metricDropdown = map(zreports[host], v => ({ value: v, text: v }));
    }
    updateMetricDropdown(metricDropdown);
  };

  populateDefaults = (hostNameRegexString, metricNameRegexString) => {
    const {
      zreports,
      fetchZabbixDataTrend,
      updateHostMetricFilter
    } = this.props;
    let host = '';
    let metric = '';
    const hostNameRegex = new RegExp(hostNameRegexString, 'i');
    const metricNameRegex = new RegExp(metricNameRegexString, 'i');
    const hostNameList = keys(zreports);
    if (!isEmpty(hostNameList)) {
      host = defaultTo(
        hostNameList.find(h => hostNameRegex.test(h)),
        hostNameList[0]
      );
      if (!isEmpty(zreports[host])) {
        this.populateMetricDropdown(host);
        metric = defaultTo(
          zreports[host].find(m => metricNameRegex.test(m)),
          zreports[host][0]
        );
      }
    }
    if (host !== '') {
      if (metric === '') {
        updateHostMetricFilter({ host });
      } else {
        updateHostMetricFilter({ host, metric });
        fetchZabbixDataTrend();
      }
    }
  };

  render() {
    const {
      property,
      zreports,
      zreportsFetchState = {},
      fetchZabbixElementReports
    } = this.props;

    const { pending, complete } = zreportsFetchState;
    const noZReportsToShow =
      !pending &&
      complete &&
      (isEmpty(zreports) || typeof zreports === 'string');

    return (
      <div className="include-in-pdf">
        <FailedFetchStateHandler
          fetchState={zreportsFetchState}
          retry={() => fetchZabbixElementReports({ property })}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noZReportsToShow ? (
            <NoDataToShow
              icon="error_outline"
              message="No data available"
              style={{
                background: 'none',
                position: 'relative'
              }}
            />
          ) : (
            <Fragment>
              <HostMetricDropdowns
                populateMetricDropdown={this.populateMetricDropdown}
                property={property}
              />
              <hr />
              <TrendChart />
            </Fragment>
          )}
        </FailedFetchStateHandler>
      </div>
    );
  }
}

PropertyDataTrend.defaultProps = {
  hostMetricFilter: defaultHostMetricSelection
};

PropertyDataTrend.propTypes = {
  dateRangeFilter: PropTypes.object,
  dateRangeFilterLabel: PropTypes.string,
  fetchZabbixElementReports: PropTypes.func,
  fetchZabbixDataTrend: PropTypes.func,
  property: PropTypes.object,
  zreports: PropTypes.object,
  zreportsFetchState: fetchStatePropTypes,
  trendChartData: PropTypes.object,
  userName: PropTypes.string,
  updateHostMetricFilter: PropTypes.func,
  updateHostDropdown: PropTypes.func,
  updateMetricDropdown: PropTypes.func,
  hostMetricFilter: PropTypes.object,
  hostDropdown: PropTypes.array,
  metricDropdown: PropTypes.array
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  dateRangeFilterLabelSelector,
  zabbixElementReportsSelector,
  zabbixElementReportsFetchStateSelector,
  zabbixDataTrendSelector,
  userNameSelector,
  hostMetricFilterSelector,
  hostDropdownSelector,
  metricDropdownSelector,
  (
    dateRangeFilter,
    dateRangeFilterLabel,
    zreports,
    zreportsFetchState,
    trendChartData,
    userName,
    hostMetricFilter,
    hostDropdown,
    metricDropdown
  ) => ({
    dateRangeFilter,
    dateRangeFilterLabel,
    zreports,
    zreportsFetchState,
    trendChartData,
    userName,
    hostMetricFilter,
    hostDropdown,
    metricDropdown
  })
);

const mapDispatchToProps = {
  fetchZabbixElementReports,
  fetchZabbixDataTrend,
  updateHostMetricFilter,
  updateHostDropdown,
  updateMetricDropdown
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDataTrend);
