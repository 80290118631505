import { createSelector } from 'reselect';
import { dateRangeFilterSelector } from 'app/redux/filters';
import { fetchAbuseSessionDetails } from 'app/redux/reports';
import Filters from 'app/components/filters';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ClientmacSearchForm from './clientmac-search-form';
import ClientmacSearchTable from './clientmac-search-table-details';
import moment from 'moment';
import TimeRangeInput from './time-range-input';

export class ClientmacDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionMAC: '',
      publicIP: '',
      nasPort: '',
      timeUnitFrom: '',
      timeUnitTo: '',
      fromTime: '12:00 AM',
      toTime: '12:00 AM',
      isValidTimeRange: true,
      isValidSessionMAC: true,
      isValidPublicIP: true,
      isValidNasPort: true,
      showTable: false,
      showDateRangeError: false
    };
  }

  validateInputs = (
    sessionMAC,
    publicIP,
    nasPort,
    timeUnitFrom,
    timeUnitTo
  ) => {
    let isValidSessionMAC = true;
    let isValidPublicIP = true;
    let isValidNasPort = true;
    let isValidTimeRange = true;

    if (
      sessionMAC === '' &&
      publicIP === '' &&
      nasPort === '' &&
      timeUnitFrom === '' &&
      timeUnitTo === ''
    ) {
      this.setState({
        isValidSessionMAC: false,
        isValidPublicIP: true,
        isValidNasPort: true,
        isValidTimeRange: true
      });
      return false;
    }

    const validateSessionMAC = mac => {
      if (mac === '') {
        return true;
      }
      const regex = /^([0-9a-fA-F]{2}[:-]){5}[0-9a-fA-F]{2}$/;
      return regex.test(mac);
    };

    const validatePublicIP = ip => {
      if (ip === '') {
        return true;
      }
      const regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^([0-9a-fA-F:]+:[0-9a-fA-F]*)$/;
      return regex.test(ip);
    };

    const validateNasPort = port => {
      if (port === '') {
        return true;
      }
      const regex = /^[0-9]+$/;
      if (regex.test(port)) {
        const portInt = parseInt(port, 10);
        if (portInt >= 1024 && portInt <= 1535) {
          return true;
        }
      }
      return false;
    };

    const validateTimeRange = (from, to) => {
      if (from === '' && to === '') {
        return true;
      }
      const isValid =
        moment(from).isValid() &&
        moment(to).isValid() &&
        (moment(from).isSameOrBefore(to) || moment(from).isSameOrAfter(to));

      return isValid;
    };

    isValidSessionMAC = validateSessionMAC(sessionMAC);
    isValidPublicIP = validatePublicIP(publicIP);
    isValidNasPort = validateNasPort(nasPort);
    isValidTimeRange = validateTimeRange(timeUnitFrom, timeUnitTo);

    this.setState({
      isValidSessionMAC,
      isValidPublicIP,
      isValidNasPort,
      isValidTimeRange
    });

    return (
      isValidSessionMAC && isValidPublicIP && isValidNasPort && isValidTimeRange
    );
  };

  handleFieldChange = field => e => this.setState({ [field]: e.target.value });

  renderSubmitButton = () => {
    const { fetchAbuseSessionDetails } = this.props;
    const {
      sessionMAC,
      publicIP,
      nasPort,
      timeUnitFrom,
      timeUnitTo,
      fromTime,
      toTime
    } = this.state;
    const isButtonDisabled =
      sessionMAC === '' &&
      publicIP === '' &&
      nasPort === '' &&
      timeUnitFrom === '' &&
      timeUnitTo === '';

    const handleOnSubmit = () => {
      const formattedTimeRange = {
        timeUnitFrom: timeUnitFrom.includes('T')
          ? timeUnitFrom
          : moment(timeUnitFrom).format('YYYY-MM-DDT') +
            moment(fromTime, 'hh:mm A').format('HH:mm:ss[Z]'),
        timeUnitTo: timeUnitTo.includes('T')
          ? timeUnitTo
          : moment(timeUnitTo).format('YYYY-MM-DDT') +
            moment(toTime, 'hh:mm A').format('HH:mm:ss[Z]')
      };

      if (!timeUnitFrom || !timeUnitTo) {
        return this.setState({ showDateRangeError: true });
      }

      const areInputsValid = this.validateInputs(
        sessionMAC,
        publicIP,
        nasPort,
        timeUnitFrom,
        timeUnitTo
      );
      this.setState({ showTable: areInputsValid }, () => {
        if (areInputsValid) {
          fetchAbuseSessionDetails({
            sessionMAC,
            publicIP,
            nasPort,
            ...formattedTimeRange
          });
        }
      });
      return '';
    };

    return (
      <div className="form-group align-items-center row">
        <div className="col-sm" />
        <div className="col-sm-8 d-flex justify-content-center">
          {/* <div className="d-flex col-sm-12"> */}
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleOnSubmit}
            disabled={isButtonDisabled}
          >
            Get Session Details
          </button>
        </div>
        <div className="col-sm-3" />
      </div>
    );
  };

  handleTimeChange = (field, value) => {
    this.setState({ [field]: value });
  };

  handleTimeUnitChange = (selectedTime, timeType) => {
    const { timeUnitFrom, timeUnitTo } = this.state;
    const updatedTimeUnit = { ...this.state, [timeType]: selectedTime };

    const isValid =
      moment(timeUnitFrom).isValid() &&
      moment(timeUnitTo).isValid() &&
      moment(timeUnitFrom).isBefore(updatedTimeUnit.timeUnitTo);

    this.setState({
      ...updatedTimeUnit,
      isValidTimeUnit: isValid
    });
  };

  render = () => {
    const {
      sessionMAC,
      publicIP,
      nasPort,
      isValidSessionMAC,
      isValidPublicIP,
      isValidNasPort,
      isValidTimeRange,
      timeUnitFrom,
      timeUnitTo,
      showDateRangeError
    } = this.state;

    const isDateRangeFilled = timeUnitFrom && timeUnitTo;

    return (
      <Fragment>
        <div className="d-flex justify-content-end align-items-center mx-2">
          {/* <label className="col-form-label-sm mb-0 mr-2">Date Range:</label> */}
          <Filters
            showVerticalFilter={false}
            showDateRangeFilter={false}
            applyButtonStyle={true}
          />
        </div>
        {/* <div className="d-flex justify-content-center align-items-center my-3"> */}
        <div className="my-3">
          <form>
            <ClientmacSearchForm
              id="client-mac"
              value={sessionMAC}
              onChange={this.handleFieldChange('sessionMAC')}
              placeholder="Client MAC"
              label="Client MAC"
              helperText="[ MAC Address could be separated by colon (:) or hyphen (-) ]"
              isValidInput={isValidSessionMAC}
            />
            <ClientmacSearchForm
              id="public-ip"
              value={publicIP}
              onChange={this.handleFieldChange('publicIP')}
              placeholder="Public IP Address"
              label="Public IP"
              helperText="[ Enter a valid IPv4 or IPv6 address ]"
              isValidInput={isValidPublicIP}
            />
            <ClientmacSearchForm
              id="time-from"
              value={timeUnitFrom}
              onChange={e => {
                this.handleTimeUnitChange(e.target.value, 'timeUnitFrom');
                this.handleFieldChange('timeUnitFrom')(e);
              }}
              placeholder="yyyy-mm-dd"
              label="Date Range"
              helperText="[ Enter a valid date [GMT] ]"
              isValidInput={isValidTimeRange}
            />
            <ClientmacSearchForm
              id="time-to"
              value={timeUnitTo}
              onChange={e => {
                this.handleTimeUnitChange(e.target.value, 'timeUnitTo');
                this.handleFieldChange('timeUnitTo')(e);
              }}
              placeholder="yyyy-mm-dd"
              label="To"
              helperText=""
              isValidInput={isValidTimeRange}
            />
            {showDateRangeError && !isDateRangeFilled && (
              <div className="d-flex justify-content-center text-danger align-items-center mb-2 mr-4 text-md">
                Please Enter Valid Date Range.
              </div>
            )}
            <TimeRangeInput
              dateRange={{
                fromTime: this.state.timeUnitFrom,
                toTime: this.state.timeUnitTo
              }}
              onChange={this.handleTimeChange}
            />

            <ClientmacSearchForm
              id="nas-port-num"
              value={nasPort}
              onChange={this.handleFieldChange('nasPort')}
              placeholder="NAS Port Number"
              label="NAS Port Number"
              helperText="[ Port number could be in range [1024-1535] ]"
              isValidInput={isValidNasPort}
            />

            {this.renderSubmitButton()}
          </form>
        </div>
        {this.state.showTable && (
          <Fragment>
            <hr />
            <ClientmacSearchTable
              sessionMAC={sessionMAC}
              publicIP={publicIP}
              nasPort={nasPort}
              timeUnitFrom={timeUnitFrom}
              timeUnitTo={timeUnitTo}
            />
          </Fragment>
        )}
      </Fragment>
    );
  };
}

ClientmacDetails.propTypes = {
  dateRange: PropTypes.object,
  dateRangeFilter: PropTypes.object,
  fetchAbuseSessionDetails: PropTypes.func,
  onChange: PropTypes.func
};
const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  dateRangeFilter => ({
    dateRangeFilter
  })
);

export default connect(mapStateToProps, { fetchAbuseSessionDetails })(
  ClientmacDetails
);
