/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React, { Fragment } from 'react';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';

import { ConnectedIcon, DisconnectedIcon } from 'app/components/icons';

const assetWidth = 14;

const IndicatorBox = styled.div.attrs(props => ({
  className: 'mr-2',
  style: props.style
}))`
  width: ${assetWidth}px;
  height: ${assetWidth}px;
`;

const HeaderPlugs = () => (
  <Fragment>
    <div className="d-flex mb-1 mt-2">
      <IndicatorBox style={{ backgroundColor: styleLibrary.connectedColor }} />
      <ConnectedIcon width={assetWidth} />
    </div>
    <div className="d-flex pr-3">
      <IndicatorBox
        style={{ backgroundColor: styleLibrary.disconnectedColor }}
      />
      <DisconnectedIcon width={assetWidth} />
    </div>
  </Fragment>
);

export default HeaderPlugs;
