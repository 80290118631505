/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { delay, getContext, takeEvery } from 'redux-saga/effects';

function* scrollToSelectedHierarchyNode() {
  const document = yield getContext('document');

  // Wait for the tree node to be selected in the DOM before trying to locate it
  yield delay(250);
  const linkTop = document.querySelector('.in-path a.selected');
  const treeDiv = document.querySelector('#tree-container');

  if (linkTop && treeDiv) {
    treeDiv.scrollTop =
      linkTop.offsetTop - treeDiv.offsetTop - treeDiv.offsetHeight / 2;
  }
}

export default function createHierarchyChangedSagas(types) {
  return [
    takeEvery(
      [types.hierarchyChangedByTypeahead, types.hierarchyNodeSelected],
      scrollToSelectedHierarchyNode
    )
  ];
}
