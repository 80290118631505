/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { FaIcon } from '../icons';

const ChartModeSwitcher = ({ mode, onClick }) => (
  <div className="chart-switcher d-flex justify-content-end pr-3 mb-4">
    <div className="btn-group">
      <button
        className={classNames(
          'btn btn-sm',
          mode === 'pie' ? 'btn-dark' : 'btn-light'
        )}
        onClick={() => onClick('pie')}
      >
        <FaIcon icon="chart-pie" />
      </button>
      <button
        className={classNames(
          'btn btn-sm',
          mode === 'bar' ? 'btn-dark' : 'btn-light'
        )}
        onClick={() => onClick('bar')}
      >
        <FaIcon icon="chart-bar" />
      </button>
    </div>
  </div>
);

ChartModeSwitcher.propTypes = {
  mode: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ChartModeSwitcher;
