/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { isAjaxTimeoutError } from 'app/custom-errors';
import { buildApiRequestArgs } from 'app/redux/ajax-saga-utils';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';
import { ajaxPost, debounceSaga } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutFetchState
} from 'app/redux/utils';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchZabbixAlarmsComplete,
  fetchZabbixAlarmsError,
  updateZabbixAlarmsFetchState,
  zabbixAlarmsSelector
} from './';

const zabbixAlarmsURL = '/api/device/getZabbixAlarms';

export function* fetchZabbixAlarmsSaga({
  payload: { includeResolved = false }
}) {
  yield put(updateZabbixAlarmsFetchState(pendingFetchState));

  yield call(waitForHierarchySaga);

  const currentDataSet = yield select(zabbixAlarmsSelector);
  yield call(debounceSaga, currentDataSet);

  const additionalOptions = yield {
    additionalParams: { includeResolved },
    additionalBodyParams: { clientVisible: ['True'] }
  };
  const [{ url, body, options }] = yield call(
    buildApiRequestArgs,
    [zabbixAlarmsURL],
    updateZabbixAlarmsFetchState,
    additionalOptions
  );

  try {
    const { data } = yield ajaxPost(url, body, options);
    yield put(fetchZabbixAlarmsComplete(data));
    yield put(updateZabbixAlarmsFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchZabbixAlarmsError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(updateZabbixAlarmsFetchState(updatedFetchState));
  }
}

export default function createFetchZabbixAlarmsSagas(types) {
  return [takeLatest([types.fetchZabbixAlarms], fetchZabbixAlarmsSaga)];
}
