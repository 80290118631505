/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

export const styleLibrary = {
  brand: {
    primary: '#4d4d4d',
    highlight: '#00adef',
    link: '#0039c1',
    feature: '#ff8000'
  },
  fontSizes: {
    actionIcon: 24,
    alarmDoughnutLegend: 15,
    body: 13,
    dropDownMenu: 14,
    inlineIcon: 20,
    mobileTitle: 18,
    navIcon: 28,
    overviewSummaryFaIcon: 56,
    overviewSummaryLabel: 24,
    overviewSummaryMaterialIcon: 72,
    overviewSummaryValue: 28,
    overviewTileText: 14,
    panelLoadingIcon: 54,
    secondaryTitle: 16,
    sectionTitle: 21,
    smallBody: 11,
    title: 24,
    titleButton: 14
  },
  alarms: {
    disaster: '#dc3545',
    high: '#ff8000',
    avg: '#ffc107',
    warn: '#17a2b8',
    info: '#007bff',
    other: '#6c757d',
    default: '#e4e6e7'
  },
  averageDurationColor: 'purple',
  clientsColor: '#bc1a8c',
  connectedColor: '#87da33',
  containerBg: '#FFFFFF',
  containerBorderColor: '#6d6e71',
  darkBg: '#333',
  darkText: '#333',
  disabledText: '#a8a8a8',
  disconnectedColor: '#FF6F80',
  downloadColor: 'rgb(59,177,219)',
  filterOpenBg: '#4CBCFE',
  formControlBorder: '#ced4da',
  headerHeight: 80,
  headerButtonWidth: '80px',
  hoverColour: 'rgba(0, 0, 0, 0.07)',
  gradientBg: '#3c3c3c linear-gradient(to right, #333 0%, #3c3c3c 230px)',
  inlineIconHeight: 16,
  inventoryCountColor: '#0070b2',
  lightBg: '#ECF0F5',
  lightText: '#FFFFFF',
  lightTextFaded: 'rgba(255, 255, 255, 0.4)',
  mapHeight: 800,
  networkTabBgColour: 'rgba(0, 0, 0, 0.22)',
  primaryButtonBg: '#00adef',
  propertyTabBgColour: 'rgba(0, 0, 0, 0.11)',
  sessionsColor: 'orange',
  sessionDurationColor: '#6a1499',
  sidebarWidth: '230px',
  tooltipBg: '#FFFFFF',
  trafficColor: '#0070b2',
  trendColors: [
    'rgb(59,177,219)',
    'rgb(46,126,158)',
    'rgb(204,0,204)',
    'rgb(0,204,102)',
    'rgb(204,102,0)',
    'rgb(204,204,0)',
    'rgb(102,0,0)'
  ],
  uniqueClientsColors: ['#bc1a8c', '#ff8000'],
  uniqueUsersColor: '#bfa900',
  uploadColor: 'rgb(46,126,158)',
  uptimeColor: '#4aa54b',
  usageSummarySelectedBg: 'rgba(0, 112, 178, 0.05)',
  // propertyCountColors: [
  //   '#ffc658',
  //   '#bf94e4',
  //   '#00ADEF',
  //   '#4aa54b',
  //   '#ff8000',
  //   '#007bff',
  //   '#6a1499',
  //   '#0070b2'
  // ],
  propertyTrendColors: [
    '#3498db',
    '#323B81',
    '#00FFFF',
    '#1578CF',
    '#00a8ff',
    '#60CEED',
    '#3A63AD'
  ],
  vlanConsumptionTrendColors: ['#758BFD', '#76F7BF']
};
