/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { NoDataToShow } from 'app/components/elements';
import { FaIcon, LoadingIconPlaceholder } from 'app/components/icons';
import { StatefulTable, TableExport } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import { MuiTableOptions, MuiTableTheme } from 'app/constants';
import {
  fetchPropertyClients,
  maxPropertyClientValuesSelector,
  propertyClientsFetchStateSelector,
  propertyClientsSelector
} from 'app/redux/clients';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import { getVerticalParent, scrollToBottom } from 'app/utils';
import classNames from 'classnames';
import { findIndex, isEmpty, isEqual, merge, some, toString } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  exportPropertyClientsTableColumns,
  getPropertyClientsTableColumns,
  sortPropertyClients,
  SubChartsTitle
} from '../utils';
import ClientBandwidthTrendChart from './client-bandwidth-trend-chart';

export class PropertyClientsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { clientsSearch: '', selectedClient: '' };
  }

  componentDidUpdate = prevProps => {
    const propertyChanged = !isEqual(
      prevProps.selectedPath.id,
      this.props.selectedPath.id
    );
    const dateRangeChanged = !isEqual(
      prevProps.dateRangeFilterLabel,
      this.props.dateRangeFilterLabel
    );

    if (propertyChanged || dateRangeChanged) {
      this.setState({ selectedClient: '' });
    }
  };

  filterBySearch = data => {
    const searchQuery = this.state.clientsSearch
      .toLowerCase()
      .trim()
      .replace(/:/g, '');

    return   this.state.clientsSearch !== ''
      ? data.filter(row =>
        some(row, value => {
          toString(value);
          const valueString = toString(value).toLowerCase().replace(/:/g, '');
          return valueString === searchQuery || valueString.includes(searchQuery);
        }
        )
      )
      : data;
  }

  renderActions = (data, columns) => (
    <div className="d-flex justify-content-between m-2">
      <div className="d-flex justify-content-start align-items-center">
        Click on any row to view traffic details for the selected client.
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <FaIcon icon="search" classes={['mr-2']} />
        <label className="sr-only">Search...</label>
        <input
          className="p-1"
          id="property-clients-table-search"
          onChange={e => this.setState({ clientsSearch: e.target.value })}
          placeholder="Search..."
          style={{ width: 250 }}
          type="text"
          value={this.state.clientsSearch}
        />
        <TableExport
          hidePdfExport={false}
          exportName="Property Client Details"
          exportDataDefinitions={exportPropertyClientsTableColumns(
            data,
            columns
          )}
        />
      </div>
    </div>
  );

  handleTableRowClick = (rowData, clientMacColumnIndex) => {
    const newSelectedClient = rowData[clientMacColumnIndex];
    if (newSelectedClient === this.state.selectedClient) {
      this.setState({ selectedClient: '' });
    } else {
      this.setState({ selectedClient: newSelectedClient }, scrollToBottom);
    }
  };

  setRowProps = (row, clientMacColumnIndex) => {
    const { selectedClient } = this.state;
    const newSelectedClient = row[clientMacColumnIndex];
    return {
      className:
        selectedClient !== '' &&
        classNames(
          newSelectedClient === selectedClient
            ? 'row-selected'
            : 'row-unselected'
        )
    };
  };

  render = () => {
    const {
      hierarchy,
      selectedPath,
      fetchPropertyClients,
      clients: { data = [], info = {} },
      fetchState,
      maxValues
    } = this.props;
    const { selectedClient } = this.state;

    const { pending, complete } = fetchState;
    const noDataToShow = !pending && complete && isEmpty(data);
    const nodeVertical = getVerticalParent(hierarchy, selectedPath);
    const columns = getPropertyClientsTableColumns(
      info,
      nodeVertical,
      maxValues
    );
    const clientMacColumnIndex = findIndex(columns, ['name', 'client']);

    const tableOptions = merge({}, MuiTableOptions, {
      customSort: (data, col, order) =>
        sortPropertyClients(data, col, order, columns),
      onRowClick: rowData =>
        this.handleTableRowClick(rowData, clientMacColumnIndex),
      setRowProps: row => this.setRowProps(row, clientMacColumnIndex)
    });

    return (
      <FailedFetchStateHandler
        fetchState={fetchState}
        retry={fetchPropertyClients}
      >
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow ? (
          <NoDataToShow
            message="There are currently no clients for this property"
            mode="grid"
            style={{
              background: 'none',
              position: 'relative'
            }}
          />
        ) : (
          <Fragment>
            {!pending && !isEmpty(data) && this.renderActions(data, columns)}
            <div
              className={pending || noDataToShow ? 'fetch-state-pending' : ''}
            >
              {complete && (
                <MuiThemeProvider theme={createMuiTheme(MuiTableTheme)}>
                  <StatefulTable
                    tableKey={`${selectedPath.id}-property-clients`}
                    columns={columns}
                    data={this.filterBySearch(data)}
                    options={tableOptions}
                  />
                </MuiThemeProvider>
              )}
            </div>
            {selectedClient !== '' && (
              <Fragment>
                <SubChartsTitle>
                  Traffic for Client MAC&nbsp;<b>{selectedClient}</b>
                </SubChartsTitle>
                <ClientBandwidthTrendChart clientMAC={selectedClient} />
              </Fragment>
            )}
          </Fragment>
        )}
      </FailedFetchStateHandler>
    );
  };
}

PropertyClientsTable.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  hierarchy: PropTypes.object,
  selectedPath: PropTypes.object,
  fetchPropertyClients: PropTypes.func,
  clients: PropTypes.object,
  fetchState: fetchStatePropTypes,
  maxValues: PropTypes.object
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  hierarchySelector,
  selectedPathSelector,
  propertyClientsSelector,
  propertyClientsFetchStateSelector,
  maxPropertyClientValuesSelector,
  (
    dateRangeFilterLabel,
    hierarchy,
    selectedPath,
    clients,
    fetchState,
    maxValues
  ) => ({
    dateRangeFilterLabel,
    hierarchy,
    selectedPath,
    clients,
    fetchState,
    maxValues
  })
);

const mapDispatchToProps = { fetchPropertyClients };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyClientsTable);
