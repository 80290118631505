/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

// import { genericAjaxPostSaga } from 'app/redux/util-sagas/generic-ajax-sagas';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  fetchInventoryTrendStatusComplete,
  fetchInventoryTrendStatusError,
  fetchPropertyTrendStatusComplete,
  fetchPropertyTrendStatusError,
  updateInventoryTrendStatusFetchState,
  updatePropertyTrendStatusFetchState
} from './';

import { defaultDateSelectionForReports } from 'app/components/filters/constants';
import { dateRangeFilterTransform } from 'app/redux/filters/transforms';
import {
  pendingFetchState,
  completeFetchState,
  failedFetchState,
  timeoutNotifyFetchState,
  createErrorAction,
  timeoutFetchState
} from 'app/redux/utils';
import { ajaxPost } from 'app/redux/util-sagas';
import { filterParamsSelector } from 'app/redux/filters';
import { createApiBodySelector } from 'app/redux/hierarchy';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';
import { isAjaxTimeoutError } from 'app/custom-errors';

const propertyTrendUrl = ['/api/fastapi/getPropertyTrend'];
const inventoryTrendUrl = ['/api/fastapi/getInventoryTrend'];

export function* fetchPropertyTrendSaga({ payload }) {
  if (!payload || (payload && !payload.dateRange)) {
    return;
  }

  const { dateRange, ...restPayload } = payload;

  yield put(updatePropertyTrendStatusFetchState(pendingFetchState));

  yield call(waitForHierarchySaga);

  const filterParams = yield select(filterParamsSelector);
  const apiBody = yield select(createApiBodySelector(propertyTrendUrl));

  const dateRangeParams = yield dateRangeFilterTransform(
    dateRange || defaultDateSelectionForReports
  );

  const additionalParams = dateRange
    ? {
      timeUnit: dateRangeParams.timeUnit,
      timeUnitFrom: dateRangeParams.timeUnitFrom,
      timeUnitTo: dateRangeParams.timeUnitTo
    }
    : {};
  const onTimeout = () =>
    put(updatePropertyTrendStatusFetchState(timeoutNotifyFetchState));

  try {
    const { data } = yield ajaxPost(propertyTrendUrl, apiBody, {
      params: {
        ...filterParams,
        ...restPayload,
        ...additionalParams
      },
      onTimeout
    });
    yield put(fetchPropertyTrendStatusComplete(data));
    yield put(updatePropertyTrendStatusFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchPropertyTrendStatusError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(fetchPropertyTrendStatusError());
    yield put(updatePropertyTrendStatusFetchState(updatedFetchState));
  }
}

export function* fetchInventoryTrendSaga({ payload }) {
  if (!payload || (payload && !payload.dateRange)) {
    return;
  }

  const { dateRange, ...restPayload } = payload;

  yield put(updateInventoryTrendStatusFetchState(pendingFetchState));

  yield call(waitForHierarchySaga);

  const filterParams = yield select(filterParamsSelector);
  const apiBody = yield select(createApiBodySelector(inventoryTrendUrl));

  const dateRangeParams = yield dateRangeFilterTransform(
    dateRange || defaultDateSelectionForReports
  );

  const additionalParams = dateRange
    ? {
      timeUnit: dateRangeParams.timeUnit,
      timeUnitFrom: dateRangeParams.timeUnitFrom,
      timeUnitTo: dateRangeParams.timeUnitTo
    }
    : {};
  const onTimeout = () =>
    put(updateInventoryTrendStatusFetchState(timeoutNotifyFetchState));

  try {
    const { data } = yield ajaxPost(inventoryTrendUrl, apiBody, {
      params: {
        ...filterParams,
        ...restPayload,
        ...additionalParams
      },
      onTimeout
    });
    yield put(fetchInventoryTrendStatusComplete(data));
    yield put(updateInventoryTrendStatusFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchInventoryTrendStatusError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(fetchInventoryTrendStatusError());
    yield put(updateInventoryTrendStatusFetchState(updatedFetchState));
  }
}

export default function createFetchPropertyTrendSagas(types) {
  return [
    takeEvery(types.fetchPropertyTrendStatus, fetchPropertyTrendSaga),
    takeEvery(types.fetchInventoryTrendStatus, fetchInventoryTrendSaga)
  ];
}
