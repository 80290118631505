/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { ChartFlexContainer, MultiLineTooltip } from 'app/components/charts';
import { tick } from 'app/components/charts/constants';
import { commonPlotProps, styleLibrary } from 'app/constants';
import { getReadableTimeFormat } from 'app/utils';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

const timestampKey = 'timestamp';
// const timestampKeyLabel = 'label';
const dataKey = 'count';
const dataKeyLabel = 'Count';

// const populateChart = (data, info, { label, start, end }) => {
//   let dataToRender = [...data];

//   if (
//     every(dataToRender, d => has(d, timestampKey) && isString(d[timestampKey]))
//   ) {
// let dateFormat;
// const fromDate = moment(start);
// const toDate = moment(end);
// const today = moment();
// const dateRangeFilterLabel = getTimeUnit(
//   label,
//   fromDate,
//   toDate.isAfter(today) ? today : toDate
// );

// eslint-disable-next-line default-case
// switch (dateRangeFilterLabel) {
//   case '24Hours':
//     dateFormat = 'HH:mm';
//     break;
//   case 'week':
//     dateFormat = 'dddd';
//     break;
//   case 'month':
//   case 'day':
//     dateFormat = 'DD-MMM-YYYY';
//     break;
//   case 'year':
//     dateFormat = 'MM-YYYY';
//     break;
// }

// if (isEqual(label, dateRangeNames.LAST_24_HOURS)) {
//   dateFormat = 'HH:mm';
// } else if (isEqual(dateRangeFilterLabel, '24Hours')) {
//   dateFormat = 'YYYY-MM-DD HH:mm';
// } else if (isEqual(label, dateRangeNames.LAST_7_DAYS)) {
//   dateFormat = 'dddd';
// } else if (
//   isEqual(label, dateRangeNames.THIS_MONTH) ||
//   isEqual(label, dateRangeNames.LAST_MONTH) ||
//   isEqual(dateRangeFilterLabel, 'day')
// ) {
//   dateFormat = 'DD-MMM-YYYY';
// } else if (isEqual(dateRangeFilterLabel, 'year')) {
//   dateFormat = 'MM-YYYY';
// } else {
//   dateFormat = 'DD-MMM-YYYY';
// }

//   dataToRender = forEach(dataToRender, d => {
//     set(
//       d,
//       timestampKeyLabel,
//       momentTimezone
//         .tz(d[timestampKey], dateFormat, get(info, 'timezone', 'UTC'))
//         .valueOf()
//     );
//     set(d, dataKeyLabel, d[dataKey]);
//     unset(d, timestampKey);
//     unset(d, dataKey);
//   });
// }

// dataToRender = sortBy(dataToRender, [timestampKeyLabel]);
// const xAxisTicks = map(dataToRender, timestampKeyLabel);
// return dataToRender;
// };

const RogueMACChart = props => {
  const {
    // dateRangeFilter,
    chartData: { data, info }
  } = props;

  // const dataToRender = populateChart(data, info, dateRangeFilter);
  const timezone = get(info, 'timezone', 'UTC');

  return (
    <ChartFlexContainer width="99%" height={250}>
      <ComposedChart
        // data={dataToRender}
        data={data}
        margin={{
          top: 35,
          bottom: 35,
          left: 15,
          right: 25
        }}
      >
        <CartesianGrid vertical={false} />

        <XAxis
          // dataKey={timestampKeyLabel}
          dataKey={timestampKey}
          label={{
            // value: `Time${
            //   has(info, 'timezone') ? ' (' + info.timezone + ')' : ''
            // }`,
            value: `Time (${timezone})`,
            position: 'bottom',
            offset: 5,
            fontWeight: 'bold',
            fontSize: styleLibrary.fontSizes.body
          }}
          tick={tick}
          interval="preserveStartEnd"
          // ticks={xAxisTicks}
          tickFormatter={t =>
            // formatApiDateTime(
            //   t,
            //   dateRangeFilter.label,
            //   get(info, 'timezone', 'UTC')
            // )
            getReadableTimeFormat(t)
          }
          // scale="time"
          scale="point"
          // type="number"
          // domain={['dataMin', 'dataMax']}
        />

        <YAxis
          yAxisId={dataKey}
          label={{
            // value: dataKeyLabel,
            value: dataKeyLabel,
            angle: -90,
            position: 'insideLeft',
            offset: 10,
            dy: 35,
            fontSize: styleLibrary.fontSizes.body,
            fill: styleLibrary.clientsColor
          }}
          stroke="rgb(31, 119, 180)"
          tick={tick}
          interval="preserveStartEnd"
        />

        <Line
          // dataKey={dataKeyLabel}
          dataKey={dataKey}
          yAxisId={dataKey}
          // name={dataKeyLabel}
          name={dataKey}
          stroke={styleLibrary.clientsColor}
          {...commonPlotProps}
        />

        <Tooltip
          content={
            <MultiLineTooltip
              labelFormatter={label =>
                // momentTimezone
                //   .tz(label, info.timezone)
                //   .format(tooltipDateDisplayFormat)
                getReadableTimeFormat(label)
              }
              // keys={[dataKeyLabel]}
              keys={[dataKey]}
              units={['']}
            />
          }
          contentStyle={{
            fontSize: styleLibrary.fontSizes.body
          }}
          offset={0}
          wrapperStyle={{ marginLeft: 80 }}
        />
      </ComposedChart>
    </ChartFlexContainer>
  );
};

RogueMACChart.propTypes = { chartData: PropTypes.object };

export default RogueMACChart;
