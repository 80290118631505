/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { HR } from 'app/components/elements';
import { createShowPanelSelector } from 'app/redux/app';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ConnectedClientsTrend from './connected-clients-trend';
import PropertyClientsTable from './property-clients-table';
import UniqueClientsTrendChart from './unique-clients-trend-chart';

export const PropertyClients = props => {
  const {
    showConnectedClientsTrend,
    showUniqueClientsTrend,
    showPropertyClientsTable
  } = props;

  return (
    <Fragment>
      {showConnectedClientsTrend && <ConnectedClientsTrend />}
      {showConnectedClientsTrend && showUniqueClientsTrend && <HR />}
      {showUniqueClientsTrend && <UniqueClientsTrendChart />}
      {showUniqueClientsTrend && showPropertyClientsTable && <HR />}
      {showPropertyClientsTable && <PropertyClientsTable />}
    </Fragment>
  );
};

PropertyClients.propTypes = {
  showConnectedClientsTrend: PropTypes.bool,
  showUniqueClientsTrend: PropTypes.bool,
  showPropertyClientsTable: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('PropertyConnectedClientsTrend'),
  createShowPanelSelector('PropertyUniqueClientsTrend'),
  createShowPanelSelector('PropertyClientsTable'),
  (
    showConnectedClientsTrend,
    showUniqueClientsTrend,
    showPropertyClientsTable
  ) => ({
    showConnectedClientsTrend,
    showUniqueClientsTrend,
    showPropertyClientsTable
  })
);

export default connect(mapStateToProps)(PropertyClients);
