/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isEqual, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  BlockContainer,
  NoDataToShow,
  PathComponent,
  TitleBar
} from 'app/components/elements';
import Filters from 'app/components/filters';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { styleLibrary } from 'app/constants';
import { dateRangeFilterSelector } from 'app/redux/filters';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  fetchMapSummary,
  mapSummaryBoundsSelector,
  mapSummaryFetchStateSelector,
  mapSummarySelector
} from 'app/redux/map';

import { MapContainer } from './components';

export class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mapLoaded: false
    };

    this.mapContainer = React.createRef();
  }

  componentDidMount() {
    this.props.fetchMapSummary();
  }

  componentDidUpdate(prevProps) {
    const { dateRangeFilter, fetchMapSummary, selected } = this.props;

    if (
      !isEqual(prevProps.selected, selected) ||
      !isEqual(prevProps.dateRangeFilter, dateRangeFilter)
    ) {
      fetchMapSummary();
    }
  }

  getRenderValues() {
    const { mapSummary, mapSummaryBounds, mapSummaryFetchState } = this.props;

    return {
      failedFetchState: mapSummaryFetchState,
      failedRetry: fetchMapSummary,
      mapData: mapSummary.ServiceProvider,
      bounds: mapSummaryBounds
    };
  }

  handleTilesLoaded = () => {
    this.setState({
      mapLoaded: true
    });
  };

  render() {
    const {
      mapSummary,
      mapSummaryFetchState,
      selected,
      hierarchy
    } = this.props;
    const { complete, pending } = mapSummaryFetchState;
    const { mapLoaded } = this.state;

    const hasSummary = !isEmpty(mapSummary);
    const noDataToShow = !pending && complete && !hasSummary;
    const hasValidData =
      hasSummary && complete && this.mapContainer.current !== null;

    const {
      failedFetchState,
      failedRetry,
      mapData,
      bounds
    } = this.getRenderValues();

    return (
      <div data-test-label="map-module">
        <TitleBar
          dark
          leftChildren={<PathComponent />}
          rightChildren={
            <div className="d-flex justify-content-spread align-items-center">
              <Filters />
            </div>
          }
        />
        <BlockContainer classes={['row', 'no-gutters']}>
          <div
            className="col"
            ref={this.mapContainer}
            style={{ height: styleLibrary.mapHeight }}
          >
            {noDataToShow && <NoDataToShow />}
            <FailedFetchStateHandler
              fetchState={failedFetchState}
              retry={failedRetry}
            >
              <MapContainer
                bounds={bounds}
                containerRef={this.mapContainer}
                doRender={mapLoaded && hasValidData}
                data={mapData}
                handleTilesLoaded={this.handleTilesLoaded}
                selected={selected}
                hierarchy={hierarchy}
              />
              {pending && <LoadingIconPlaceholder />}
            </FailedFetchStateHandler>
          </div>
        </BlockContainer>
      </div>
    );
  }
}

Map.propTypes = {
  dateRangeFilter: PropTypes.object,
  fetchMapSummary: PropTypes.func.isRequired,
  hierarchy: PropTypes.object,
  mapSummary: PropTypes.object.isRequired,
  mapSummaryBounds: PropTypes.object,
  mapSummaryFetchState: fetchStatePropTypes,
  selected: PropTypes.object
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  hierarchySelector,
  mapSummaryBoundsSelector,
  mapSummaryFetchStateSelector,
  mapSummarySelector,
  selectedPathSelector,
  (
    dateRangeFilter,
    hierarchy,
    mapSummaryBounds,
    mapSummaryFetchState,
    mapSummary,
    selected
  ) => ({
    dateRangeFilter,
    hierarchy,
    mapSummaryBounds,
    mapSummaryFetchState,
    mapSummary,
    selected
  })
);

const mapDispatchToProps = {
  fetchMapSummary
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
