/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { types as appTypes } from 'app/redux/app';
import {
  createAsyncTypes,
  createTypes,
  pendingFetchState
} from 'app/redux/utils';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import createDownloadAPDetailsSagas from './download-ap-details-saga';
import createFetchInventorySagas from './fetch-inventory-saga';
import createFetchSwitchDetailsSagas from './fetch-switch-details-saga';
import createFetchZabbixDataTrendSagas from './fetch-zabbix-data-trend-saga';
import createFetchPropertyInventorySagas from './property-inventory-saga';
import {
  getInventoryCalculatedValues,
  getPropertyInventoryCount
} from './utils';

export const nameSpace = 'inventory';

export const types = createTypes(
  [
    ...createAsyncTypes('downloadAPDetails'),
    'downloadingAPs',
    ...createAsyncTypes('fetchApDetails'),
    ...createAsyncTypes('fetchInventoryDetails'),
    ...createAsyncTypes('fetchInventorySummary'),
    ...createAsyncTypes('fetchPropertyInventory'),
    ...createAsyncTypes('fetchZabbixElementReports'),
    ...createAsyncTypes('fetchZabbixDataTrend'),
    ...createAsyncTypes('fetchInventoryAPDetails'),
    ...createAsyncTypes('fetchSwitchDetails'),
    'updateInventoryDetailsFetchState',
    'updateInventorySummaryFetchState',
    'updatePropertyInventoryFetchState',
    'updateInventoryFilter',
    'updateZabbixElementReportsFetchState',
    'updateZabbixDataTrendFetchState',
    'updateInventoryAPDetailsFetchState',
    'updateInventoryAPDetailsJSON',
    'updateSwitchDetailsFetchState'
  ],
  nameSpace
);

export const sagas = [
  ...createFetchInventorySagas(types),
  ...createDownloadAPDetailsSagas(types),
  ...createFetchPropertyInventorySagas(types),
  ...createFetchZabbixDataTrendSagas(types),
  ...createFetchSwitchDetailsSagas(types)
];

export const downloadAPDetails = createAction(types.downloadAPDetails);
export const downloadAPDetailsComplete = createAction(
  types.downloadAPDetailsComplete
);
export const downloadAPDetailsError = createAction(
  types.downloadAPDetailsError
);
export const downloadingAPs = createAction(types.downloadingAPs);

export const fetchInventorySummary = createAction(types.fetchInventorySummary);
export const fetchInventorySummaryComplete = createAction(
  types.fetchInventorySummaryComplete
);
export const fetchInventorySummaryError = createAction(
  types.fetchInventorySummaryError
);

export const fetchInventoryDetails = createAction(types.fetchInventoryDetails);
export const fetchInventoryDetailsComplete = createAction(
  types.fetchInventoryDetailsComplete
);
export const fetchInventoryDetailsError = createAction(
  types.fetchInventoryDetailsError
);

export const fetchPropertyInventory = createAction(
  types.fetchPropertyInventory
);
export const fetchPropertyInventoryComplete = createAction(
  types.fetchPropertyInventoryComplete
);
export const fetchPropertyInventoryError = createAction(
  types.fetchPropertyInventoryError
);

export const fetchApDetails = createAction(types.fetchApDetails);

export const updateInventoryDetailsFetchState = createAction(
  types.updateInventoryDetailsFetchState
);
export const updateInventorySummaryFetchState = createAction(
  types.updateInventorySummaryFetchState
);
export const updatePropertyInventoryFetchState = createAction(
  types.updatePropertyInventoryFetchState
);

export const updateInventoryFilter = createAction(types.updateInventoryFilter);

export const fetchZabbixElementReports = createAction(
  types.fetchZabbixElementReports
);
export const fetchZabbixElementReportsComplete = createAction(
  types.fetchZabbixElementReportsComplete
);
export const fetchZabbixElementReportsError = createAction(
  types.fetchZabbixElementReportsError
);
export const updateZabbixElementReportsFetchState = createAction(
  types.updateZabbixElementReportsFetchState
);

export const fetchZabbixDataTrend = createAction(types.fetchZabbixDataTrend);
export const fetchZabbixDataTrendComplete = createAction(
  types.fetchZabbixDataTrendComplete
);
export const fetchZabbixDataTrendError = createAction(
  types.fetchZabbixDataTrendError
);
export const updateZabbixDataTrendFetchState = createAction(
  types.updateZabbixDataTrendFetchState
);

export const fetchInventoryAPDetails = createAction(
  types.fetchInventoryAPDetails
);
export const fetchInventoryAPDetailsComplete = createAction(
  types.fetchInventoryAPDetailsComplete
);
export const fetchInventoryAPDetailsError = createAction(
  types.fetchInventoryAPDetailsError
);
export const updateInventoryAPDetailsFetchState = createAction(
  types.updateInventoryAPDetailsFetchState
);
export const updateInventoryAPDetailsJSON = createAction(
  types.updateInventoryAPDetailsJSON
);

export const fetchSwitchDetails = createAction(types.fetchSwitchDetails);
export const fetchSwitchDetailsComplete = createAction(
  types.fetchSwitchDetailsComplete
);
export const fetchSwitchDetailsError = createAction(
  types.fetchSwitchDetailsError
);
export const updateSwitchDetailsFetchState = createAction(
  types.updateSwitchDetailsFetchState
);

export const downloadingAPsSelector = state => state[nameSpace].downloadingAPs;

export const inventorySummaryFetchStateSelector = state =>
  state[nameSpace].summaryFetchState;
export const inventoryDetailsFetchStateSelector = state =>
  state[nameSpace].detailsFetchState;
export const propertyInventoryFetchStateSelector = state =>
  state[nameSpace].propertyInventoryFetchState;
export const zabbixElementReportsFetchStateSelector = state =>
  state[nameSpace].zabbixElementReportsFetchState;
export const zabbixDataTrendFetchStateSelector = state =>
  state[nameSpace].zabbixDataTrendFetchState;
export const inventoryAPDetailsFetchStateSelector = state =>
  state[nameSpace].inventoryAPDetailsFetchState;
export const switchDetailsFetchStateSelector = state =>
  state[nameSpace].switchDetailsFetchState;

export const inventorySummarySelector = state => state[nameSpace].summary;
export const inventoryDetailsSelector = state => {
  const { details, apdetails } = state[nameSpace];
  return { details, apdetails };
};
export const inventorySelector = createSelector(
  inventorySummarySelector,
  inventoryDetailsSelector,
  (summary, details, apdetails) => ({
    ...summary,
    ...details,
    ...apdetails
  })
);
export const propertyInventorySelector = state => {
  const { propertyInventory = {} } = state[nameSpace];
  const { summary = [] } = propertyInventory;
  return summary.map(vert => ({
    propertyType: vert.vertical,
    propertyCount: vert.counts
  }));
};

export const propertyInventoryCountSelector = createSelector(
  inventorySummarySelector,
  summary => getPropertyInventoryCount(summary)
);

export const calculatedInventoryValuesSelector = createSelector(
  inventorySummarySelector,
  inventory => getInventoryCalculatedValues(inventory)
);

export const inventorySelectionSelector = state =>
  state[nameSpace].inventoryFilter;

export const zabbixElementReportsSelector = state => state[nameSpace].zreports;
export const zabbixDataTrendSelector = state => {
  const { DataTrend = [], info: DataTrendInfo = {} } = state[nameSpace];
  return { DataTrend, DataTrendInfo };
};

export const inventoryAPDetailsSelector = state =>
  state[nameSpace].inventoryAPDetails;
export const inventoryAPDetailsJSONSelector = state =>
  state[nameSpace].inventoryAPDetailsJSON;

export const switchDetailsSelector = state => {
  const { switchList: data = [] } = state[nameSpace].switchDetails;
  return { data };
};

const initialState = {
  detailsFetchState: pendingFetchState,
  propertyInventoryFetchState: pendingFetchState,
  summaryFetchState: pendingFetchState,
  zabbixElementReportsFetchState: pendingFetchState,
  zabbixDataTrendFetchState: pendingFetchState,
  inventoryAPDetailsFetchState: pendingFetchState,
  switchDetailsFetchState: pendingFetchState,
  apdetails: [],
  details: [],
  zreports: {},
  downloadingAPs: { inProgress: false, failed: false },
  inventoryFilter: {
    levelType: '',
    deviceType: ''
  },
  propertyInventory: {},
  summary: {},
  inventoryAPDetails: {},
  inventoryAPDetailsJSON: [],
  switchDetails: {}
};

export const reducer = handleActions(
  {
    [types.downloadingAPs]: (state, { payload }) => ({
      ...state,
      downloadingAPs: {
        ...state.downloadingAPs,
        ...payload
      }
    }),

    [types.fetchInventorySummaryComplete]: (state, { payload }) => ({
      ...state,
      summary: payload
    }),

    [types.fetchInventoryDetailsComplete]: (state, { payload }) => ({
      ...state,
      ...payload
    }),

    [types.fetchPropertyInventoryComplete]: (state, { payload }) => ({
      ...state,
      propertyInventory: payload
    }),

    [types.fetchZabbixElementReportsComplete]: (state, { payload }) => ({
      ...state,
      ...payload
    }),

    [types.fetchZabbixDataTrendComplete]: (state, { payload }) => ({
      ...state,
      ...payload
    }),

    [types.fetchInventoryAPDetailsComplete]: (state, { payload }) => ({
      ...state,
      inventoryAPDetails: payload
    }),

    [types.updateInventoryAPDetailsJSON]: (state, { payload }) => ({
      ...state,
      inventoryAPDetailsJSON: payload
    }),

    [types.updateInventorySummaryFetchState]: (state, { payload }) => ({
      ...state,
      summaryFetchState: payload
    }),

    [types.updateInventoryDetailsFetchState]: (state, { payload }) => ({
      ...state,
      detailsFetchState: payload
    }),

    [types.updatePropertyInventoryFetchState]: (state, { payload }) => ({
      ...state,
      propertyInventoryFetchState: payload
    }),

    [types.updateZabbixElementReportsFetchState]: (state, { payload }) => ({
      ...state,
      zabbixElementReportsFetchState: payload
    }),

    [types.updateZabbixDataTrendFetchState]: (state, { payload }) => ({
      ...state,
      zabbixDataTrendFetchState: payload
    }),

    [types.updateInventoryAPDetailsFetchState]: (state, { payload }) => ({
      ...state,
      inventoryAPDetailsFetchState: payload
    }),

    [types.updateInventoryFilter]: (state, { payload }) => ({
      ...state,
      inventoryFilter: payload
    }),

    [types.fetchSwitchDetailsComplete]: (state, { payload }) => ({
      ...state,
      switchDetails: payload
    }),

    [types.updateSwitchDetailsFetchState]: (state, { payload }) => ({
      ...state,
      switchDetailsFetchState: payload
    }),

    [appTypes.appReset]: () => initialState
  },
  initialState
);
