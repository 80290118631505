/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ExportCsvIcon,
  ExportExcelIcon,
  ExportPdfIcon
} from 'app/components/icons/index';
import {
  generateCsvFromDataDefinition,
  generatePdfFromDataDefinition,
  generatePdfFromHtml,
  userNameSelector
} from 'app/redux/app';
import { dateRangeFilterSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';

const StyledExportButton = styled.span`
  cursor: pointer;
`;
StyledExportButton.displayName = 'StyledExportButton';

export const TableExport = props => {
  const {
    dateRangeFilter,
    exportDataDefinitions,
    htmlExportDefinition,
    exportName,
    generateCsvFromDataDefinition,
    generateExcelFromDataDefinition,
    generatePdfFromDataDefinition,
    generatePdfFromHtml,
    selectedPath,
    userName,
    hidePdfExport = false,
    hideCsvExport = false,
    hideExcelExport = true,
    exportPdfStyleDefinitions = {},
    exportTableStyleDefinitions = {}
  } = props;

  const multiple = exportDataDefinitions && exportDataDefinitions.length > 1;

  const dateString = moment().format('YYYYMMDD_hhmm');
  const csvFilename =
    exportName + '_' + dateString + (multiple ? '.zip' : '.csv');
  const pdfFilename = exportName + '_' + dateString + '.pdf';
  // const excelFileName =
  //   exportName + '_' + (selectedPath ? selectedPath.id : '') + '_' + dateString;

  const pdfTooltip = `Generate PDF report of ${
    multiple ? 'all' : ''
  } ${exportName}`;
  const csvTooltip = `Generate CSV ${multiple ? 'files' : 'file'} containing ${
    multiple ? 'all' : 'the'
  } ${exportName} ${multiple ? 'tables' : 'table'}`;
  const excelTooltip = `Generate Excel file containing ${
    multiple ? 'all' : 'the'
  } ${exportName} ${multiple ? 'tables' : 'table'}`;

  const doGenerateCsv = () => {
    generateCsvFromDataDefinition({
      exportDataDefinitions,
      exportFilename: csvFilename,
      properties: { dateRangeFilter, selectedPath }
    });
  };

  const doGeneratePdf = () => {
    //Decide which type of PDF to generate - either from HTML or Data definition
    if (htmlExportDefinition) {
      generatePdfFromHtml(htmlExportDefinition);
    } else {
      generatePdfFromDataDefinition({
        exportDataDefinitions,
        exportFilename: pdfFilename,
        title: exportName,
        userName,
        exportPdfStyleDefinitions,
        exportTableStyleDefinitions
      });
    }
  };

  return (
    <Fragment>
      <span className="ml-2 hide-in-pdf" data-test-label="table-export-module">
        {!hideCsvExport && (
          <StyledExportButton onClick={doGenerateCsv} title={csvTooltip}>
            <ExportCsvIcon
              data-test-label="table-export-link"
              className="ml-3"
            />
          </StyledExportButton>
        )}
        {!hideExcelExport && (
          <StyledExportButton
            onClick={generateExcelFromDataDefinition}
            title={excelTooltip}
          >
            <ExportExcelIcon
              data-test-label="table-export-link"
              className="ml-3"
            />
          </StyledExportButton>
        )}
        {!hidePdfExport && (
          <StyledExportButton onClick={doGeneratePdf} title={pdfTooltip}>
            <ExportPdfIcon
              data-test-label="table-export-link"
              className="ml-3"
            />
          </StyledExportButton>
        )}
      </span>
    </Fragment>
  );
};

TableExport.propTypes = {
  dateRangeFilter: PropTypes.object,
  exportDataDefinitions: PropTypes.array.isRequired,
  htmlExportDefinition: PropTypes.object,
  exportName: PropTypes.string.isRequired,
  generateCsvFromDataDefinition: PropTypes.func,
  generateExcelFromDataDefinition: PropTypes.func,
  generatePdfFromDataDefinition: PropTypes.func,
  generatePdfFromHtml: PropTypes.func,
  selectedPath: PropTypes.object,
  userName: PropTypes.string,
  hidePdfExport: PropTypes.bool,
  hideCsvExport: PropTypes.bool,
  hideExcelExport: PropTypes.bool,
  exportPdfStyleDefinitions: PropTypes.object,
  exportTableStyleDefinitions: PropTypes.object
};

const mapDispatchToProps = {
  generateCsvFromDataDefinition,
  generatePdfFromDataDefinition,
  generatePdfFromHtml
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  selectedPathSelector,
  userNameSelector,
  (dateRangeFilter, selectedPath, userName) => ({
    dateRangeFilter,
    selectedPath,
    userName
  })
);

export default connect(mapStateToProps, mapDispatchToProps)(TableExport);
