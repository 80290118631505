/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { findIndex, get, merge } from 'lodash';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { createTableStateSelector, setTableState } from 'app/redux/ui';

export class StatefulTable extends Component {

  componentDidMount() {
    // This is an acknowledged hack to work around a foible of MUI Datatables
    // where it doesn't recognise the presence of the data rows on initial
    // render, even though all debugging suggests they're there correctly.
    this.forceUpdate();
  }

  componentDidUpdate(prevProps) {
    const { tableKey, setTableState, defaultPage } = this.props;
    const { page: prevPage } = prevProps.tableState || {};
    const { page } = this.props.tableState || {};

    if (prevPage !== page) {
      // Page has changed, update the table state
      setTableState({ key: tableKey, page: 0 });
    } else if (defaultPage && defaultPage !== page) {
      // Default page has changed, reset to default page
      setTableState({ key: tableKey, page: 0 });
    }
  }
  
  handlePageChange = page => {
    this.updateTableState({ page });

    const { onPageChange } = this.props;
    if (onPageChange) {
      onPageChange(page);
    }
  };

  handlePageSizeChange = rows => {
    this.updateTableState({ pageSize: rows });
  };

  handleSortChange = (sortCol, direction) => {
    this.updateTableState({
      sortCol,
      sortDir: direction === 'ascending' ? 'asc' : 'desc'
    });

    const { onSortChange } = this.props;
    if (onSortChange) {
      onSortChange(sortCol, direction);
    }
  };

  updateTableState = updates => {
    const { setTableState, tableKey, tableState } = this.props;

    const nextTableState = {
      ...tableState,
      ...updates
    };

    setTableState({ key: tableKey, ...nextTableState });
  };

  render() {
    const { columns, data, options, tableState } = this.props;
    const { page = 0, pageSize = 10, sortCol = '', sortDir = '' } = tableState;

    if (sortCol !== '') {
      columns.forEach(c => delete get(c, 'options', {}).sortDirection);
      const col = columns[findIndex(columns, { name: sortCol })];
      merge(col, { options: { sortDirection: sortDir } });
    }

    const displayOptions = merge({}, options, {
      onChangePage: this.handlePageChange,
      onColumnSortChange: this.handleSortChange,
      onChangeRowsPerPage: this.handlePageSizeChange,
      page,
      rowsPerPage: pageSize
    });

    return (
      <MUIDataTable
        key={this.props.tableKey}
        columns={columns}
        data={data}
        options={displayOptions}
        title={this.props.title}
      />
    );
  }
}

StatefulTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  defaultPage: PropTypes.number,
  tableKey: PropTypes.any.isRequired,
  onPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  options: PropTypes.object,
  pageSize: PropTypes.number,
  setTableState: PropTypes.func.isRequired,
  tableState: PropTypes.object,
  title: PropTypes.string
};

const makeMapStateToProps = () => {
  const getFromRedux = tableKey => {
    return createSelector(
      createTableStateSelector(tableKey),
      (tableState = {}) => tableState
    );
  };

  return (state, ownProps) => {
    const tableState = getFromRedux(ownProps.tableKey)(state);
    return {
      tableState
    };
  };
};

const mapDispatchToProps = {
  setTableState
};

export default connect(makeMapStateToProps, mapDispatchToProps)(StatefulTable);
