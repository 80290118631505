/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

/**
 *
 * @method createCacheKey
 * @param {Object<String, any>} keyProperties An object of values representing a unique data request
 * @returns {String} A hashed string representation of keyProperties
 *
 * @example
 * const dataRequest = {
 *   url: 'http://foo.bar/resource/1',
 *   method: 'POST',
 *   params: { page: 1 }
 * };
 *
 * const cacheKey = createCacheKey(dataRequest); // will always be "3737441419"
 */
export function createCacheKey(keyProperties = {}) {
  const propertiesString = Object.keys(keyProperties)
    .sort()
    .reduce(
      (properties, key) =>
        properties.concat(key, JSON.stringify(keyProperties[key])),
      ''
    );
  return JSON.stringify(hash(propertiesString));
}

export function getFromCache(cache, cacheRequestProperties) {
  const cacheKey = createCacheKey(cacheRequestProperties);
  return cache[cacheKey] || null;
}

function getTTL(period) {
  return Date.now() + period;
}

// https://github.com/darkskyapp/string-hash
function hash(str) {
  let hash = 5381;
  let i = str.length;
  while (i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }
  /*
   * JavaScript does bitwise operations (like XOR, above) on 32-bit signed
   * integers. Since we want the results to be always positive, convert the
   * signed int to an unsigned by doing an unsigned bitshift.
   */
  return hash >>> 0;
}

function isValidTTL(timestamp) {
  const now = Date.now();
  return now < timestamp;
}

export function setToCache(
  cache,
  cacheRequestProperties,
  data,
  validForMilliseconds
) {
  const cacheKey = createCacheKey(cacheRequestProperties);
  return { ...cache, [cacheKey]: { data, ttl: getTTL(validForMilliseconds) } };
}
export function isValidCacheEntry(entry) {
  if (!entry) {
    return false;
  }
  const { ttl } = entry;
  return isValidTTL(ttl);
}

export function getValidCacheEntryData(entry) {
  if (!entry) {
    return null;
  }
  const { data } = entry;
  return isValidCacheEntry(entry) ? data : null;
}
