/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { commonPlotProps, styleLibrary } from 'app/constants';
import { getReadableTimeFormat } from 'app/utils';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

export class ConnectedClientsTrendChart extends Component {
  constructor(props) {
    super(props);
    this.state = { hiddenPlotMap: {} };
  }

  isActivePlot = plotKey =>
    this.state.hiddenPlotMap[plotKey] ? 'blank' : plotKey;

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  render = () => {
    const { data, info } = this.props;
    const { hiddenPlotMap } = this.state;
    const timestamp = get(info, 'timezone', 'UTC');

    return (
      <ChartFlexContainer width="99%" height={300}>
        <LineChart data={data} margin={{ ...chartMargins, left: 15 }}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="label"
            label={{
              value: `Time (${timestamp})`,
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            interval="preserveStartEnd"
            tickFormatter={t => getReadableTimeFormat(t)}
            scale="point"
            tick={tick}
          />
          <YAxis
            yAxisId="clientStatusTrend"
            label={{
              value: 'Count',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            // domain={[0, 'dataMax']}
            // scale="linear"
            allowDecimals={false}
            interval="preserveStartEnd"
          />
          <Tooltip
            content={
              <MultiLineTooltip
                labelFormatter={label => getReadableTimeFormat(label)}
                keys={['Client Joins', 'Client Disconnects']}
                units={['']}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
          />
          <Legend
            verticalAlign="top"
            inactivePlots={hiddenPlotMap}
            content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
          />
          <Line
            yAxisId="clientStatusTrend"
            dataKey={this.isActivePlot('Client Joins')}
            name="Client Joins"
            stroke={styleLibrary.connectedColor}
            {...commonPlotProps}
          />
          <Line
            yAxisId="clientStatusTrend"
            dataKey={this.isActivePlot('Client Disconnects')}
            name="Client Disconnects"
            stroke={styleLibrary.disconnectedColor}
            {...commonPlotProps}
          />
        </LineChart>
      </ChartFlexContainer>
    );
  };
}
ConnectedClientsTrendChart.propTypes = {
  data: PropTypes.array,
  info: PropTypes.object
};

export default ConnectedClientsTrendChart;
