/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  isNodeUnderSpecificLevel,
  isPropertyNodeOrUnder,
  isSpecificNodeType
} from 'app/components/layout/components/sidebar/utils';
import { styleLibrary } from 'app/constants';
import { find, isEmpty, sortBy, uniqBy } from 'lodash';
import skrolltop from 'skrolltop';
import { allSelection, verticals } from '../constants';
import { zeroProtection } from './numbers';

/**
 * This function will return an empty Array if the provided argument is not an Array
 *
 * @method ensureIsArray
 * @param {*} maybeArray An item we wish to ensure is an Array
 * @returns {Array} Either maybeArray or an empty Array
 */
export const ensureIsArray = maybeArray =>
  Array.isArray(maybeArray) ? maybeArray : [];

export const scrollToBottom = () => {
  const mainContainer = document.getElementById('main-container');
  skrolltop.scrollTo({
    element: mainContainer,
    duration: 1000,
    to: mainContainer.scrollHeight
  });
};

export const scrollToTop = () => {
  const mainContainer = document.getElementById('main-container');
  skrolltop.scrollTo({
    element: mainContainer,
    duration: 1000,
    to: 0
  });
};

export const scrollToHtmlElement = (htmlElementId, customOffset = 0) => {
  const mainContainer = document.getElementById('main-container');
  const htmlElementOffset = document.getElementById(htmlElementId).offsetTop;
  skrolltop.scrollTo({
    element: mainContainer,
    duration: 1000,
    to: htmlElementOffset + customOffset - styleLibrary.headerHeight
  });
};

/**
 * This function calculates the size to assign to a chart container to make it suit the number of rows to
 * be displays
 *
 * @method calculateChartHeight
 * @param {Number} dataLength The number of rows to be displayed
 * @param {Number} height The maximum height we want to assign to the chart data
 * @param {Number} containerPadding The amount of pixels of padding to apply to the container
 * @returns {Object} The container and chart data heights
 */
export const calculateChartHeight = (dataLength, height, containerPadding) => {
  const rowHeight = 30;
  const dataHeight = zeroProtection(dataLength) * rowHeight;

  return {
    containerHeight: Math.min(
      height + containerPadding,
      dataHeight + containerPadding
    ),
    chartHeight: Math.min(height, dataHeight)
  };
};

/**
 * This function traverses up the tree from the current node to find the specified parent node it is in.
 *
 * @method getSpecificParentOfNode
 * @param {Object} hierarchy The full hierarchy of our tree
 * @param {Object} node The currently selected node
 * @param {String} parentNodeType The type of parent node we wish to fetch
 * @returns {Object} The node from the tree representing the specified parent of this one
 */
export const getSpecificParentOfNode = (
  hierarchy,
  node,
  parentNodeType = 'customer'
) => {
  const { ancestors } = node;
  const bottomUpAncestors = ancestors.slice().reverse();
  const nodeId = find(
    bottomUpAncestors,
    id => hierarchy[id].type === parentNodeType
  );
  return hierarchy[nodeId];
};

/**
 * This function traverses up the tree from the current node to find the Property it is in.
 *
 * @method getPropertyParent
 * @param {Object} hierarchy The full hierarchy of our tree
 * @param {Object} node The currently selected node
 * @returns {Object} The node from the tree representing the Property of this one
 */
export const getPropertyParent = (hierarchy, node) => {
  if (isPropertyNodeOrUnder(node)) {
    if (isSpecificNodeType(node, 'zonename')) {
      return node;
    }
    return getSpecificParentOfNode(hierarchy, node, 'zonename');
  }
  return {};
};

/**
 * This function traverses up the tree from the current node to find the vertical it is in.
 *
 * @method getVerticalParent
 * @param {Object} hierarchy The full hierarchy of our tree
 * @param {Object} node The currently selected node
 * @returns {Object} The node from the tree representing the vertical of this one
 */
export const getVerticalParent = (hierarchy, node) => {
  // We must be below the vertical level of the tree for this to work. So check for an appropriate type
  // for the current node before trying to parse the ancestors for a vertical.
  if (!isPropertyNodeOrUnder(node)) {
    return {};
  }
  return getSpecificParentOfNode(hierarchy, node, 'vertical');
};

/**
 * @method prefixNavigationPath
 * @param {String} path A navigation path (slug) to be prefixed correctly
 * @returns {String} A correctly prefixed navigation path (slug)
 *
 * @example
 * const loginPath = prefixNavigationPath('/login');
   // In production, this could look like '/ni3/login'
   // In development, this will always be '/login'
 *
 */
export const prefixNavigationPath = path =>
  `${process.env.PUBLIC_URL || ''}${path}`;

/**
 * @method getAllOfTypeFromHierarchy
 * @param {String} nodeType The type of node to extract from the hierarchy
 * @param {Array} hierarchy The current user's hierarchy tree
 * @param {Object} selectedPath The currently selected tree node
 * @param {Boolean} includeAll Denotes whether or not to include the 'all' option
 * @returns {Array} An array of unique verticals sorted by name
 */
export const getAllOfTypeFromHierarchy = (
  nodeType,
  hierarchy,
  selectedPath = {},
  includeAll = true
) => {
  let overrideSelectedPath;

  if (
    !isSpecificNodeType(selectedPath, nodeType) &&
    isNodeUnderSpecificLevel(selectedPath, nodeType)
  ) {
    overrideSelectedPath = getSpecificParentOfNode(
      hierarchy,
      selectedPath,
      nodeType
    );
  }

  const path = isEmpty(overrideSelectedPath)
    ? selectedPath
    : overrideSelectedPath;

  const nodes = Object.keys(hierarchy)
    .filter(key => key.startsWith(isEmpty(path) ? '' : path.id))
    .map(key => hierarchy[key])
    .filter(({ type }) => type === nodeType);

  const processedNodes = sortBy(
    uniqBy(
      nodes.map(v => ({
        value: v.name,
        text: isEmpty(v.displayName) ? v.name : v.displayName
      })),
      'value'
    ),
    [wlan => wlan.value.toLowerCase()]
  );

  return (includeAll && processedNodes.length !== 1 ? allSelection : []).concat(
    processedNodes
  );
};

/**
 * @method getUniqueVerticals
 * @param {Array} hierarchy The current user's hierarchy tree
 * @param {Object} selectedPath The currently selected tree node
 * @param {Boolean} includeAll Denotes whether or not to include the 'all' option
 * @returns {Array} An array of unique verticals sorted by name
 */
export const getUniqueVerticals = (
  hierarchy,
  selectedPath = {},
  includeAll = true
) => {
  return getAllOfTypeFromHierarchy(
    'vertical',
    hierarchy,
    selectedPath,
    includeAll
  ).map(x => {
    // Try to find a matching defined vertical - if there are none, just use
    // the vertical as provided.
    const displayValue = (verticals[x.value] || {}).desc || x.value;
    return { ...x, text: displayValue };
  });
};

/**
 * @method getWlans
 * @param {Array} hierarchy The current user's hierarchy tree
 * @param {Object} selectedPath The currently selected tree node
 * @param {Boolean} includeAll Denotes whether or not to include the 'all' option
 * @returns {Array} An array of unique WLANs sorted by name
 */
export const getWlans = (hierarchy, selectedPath = {}, includeAll = true) => {
  return getAllOfTypeFromHierarchy('wlan', hierarchy, selectedPath, includeAll);
};

/**
 * @method getAllWlansForProperty
 * @param {Array} hierarchy The current user's hierarchy tree
 * @param {Object} selectedPath The currently selected tree node
 * @param {Boolean} includeAll Denotes whether or not to include the 'all' option
 * @returns {Array} An array of unique WLANs falling under the parent property and sorted by name
 */
export const getAllWlansForProperty = (
  hierarchy,
  selectedPath = {},
  includeAll = true
) => {
  const selectedProperty = getPropertyParent(hierarchy, selectedPath);
  return getWlans(hierarchy, selectedProperty, includeAll);
};

export const getSortResult = (valA, valB, direction) => {
  const factor = direction === 'asc' ? 1 : -1;
  return (valA > valB ? 1 : valB > valA ? -1 : 0) * factor;
};

/**
 * @method getSubCustomers
 * @param {Array} hierarchy The current user's hierarchy tree
 * @param {Object} selectedPath The currently selected tree node
 * @param {Boolean} includeAll Denotes whether or not to include the 'all' option
 * @returns {Array} An array of unique Sub-Customers sorted by name
 */
export const getSubCustomers = (
  hierarchy,
  selectedPath = {},
  includeAll = true
) => {
  return getAllOfTypeFromHierarchy(
    'subcustomer',
    hierarchy,
    selectedPath,
    includeAll
  );
};

export const getCustomers = (
  hierarchy,
  selectedPath = {},
  includeAll = true
) => {
  return getAllOfTypeFromHierarchy(
    'customer',
    hierarchy,
    selectedPath,
    includeAll
  );
};
