/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { types as appTypes } from 'app/redux/app';
import {
  createAsyncTypes,
  createTypes,
  pendingFetchState
} from 'app/redux/utils';
import { createAction, handleActions } from 'redux-actions';
import createDownloadAlarmsSagas from './download-alarms-saga';
import createFetchZabbixAlarmsSagas from './fetch-zabbix-alarms-saga';

export const nameSpace = 'alarms';

export const types = createTypes(
  [
    ...createAsyncTypes('fetchZabbixAlarms'),
    'updateZabbixAlarmsFetchState',
    'updateZabbixAlarmActions',
    'downloadAlarmsAsCsv',
    'downloadAlarmsAsPdf',
    'downloadingAlarms'
  ],
  nameSpace
);

export const sagas = [
  ...createFetchZabbixAlarmsSagas(types),
  ...createDownloadAlarmsSagas(types)
];

export const fetchZabbixAlarms = createAction(types.fetchZabbixAlarms);
export const fetchZabbixAlarmsComplete = createAction(
  types.fetchZabbixAlarmsComplete
);
export const fetchZabbixAlarmsError = createAction(
  types.fetchZabbixAlarmsError
);
export const updateZabbixAlarmsFetchState = createAction(
  types.updateZabbixAlarmsFetchState
);
export const updateZabbixAlarmActions = createAction(
  types.updateZabbixAlarmActions
);
export const downloadAlarmsAsCsv = createAction(types.downloadAlarmsAsCsv);
export const downloadAlarmsAsPdf = createAction(types.downloadAlarmsAsPdf);
export const downloadingAlarms = createAction(types.downloadingAlarms);
export const zabbixAlarmsFetchStateSelector = state =>
  state[nameSpace].zabbixAlarmsFetchState;
export const downloadingAlarmsSelector = state =>
  state[nameSpace].downloadingAlarms;
export const zabbixAlarmsSelector = state => {
  const {
    alarms: { alarms = [], info: alarmsInfo = {} }
  } = state[nameSpace];
  return { alarms, alarmsInfo };
};
export const zabbixAlarmActionsSelector = state =>
  state[nameSpace].alarmActions;

const initialState = {
  zabbixAlarmsFetchState: pendingFetchState,
  downloadingAlarms: { inProgress: false, failed: false },
  alarms: {},
  alarmActions: []
};

export const reducer = handleActions(
  {
    [types.fetchZabbixAlarmsComplete]: (state, { payload }) => ({
      ...state,
      alarms: payload
    }),

    [types.updateZabbixAlarmsFetchState]: (state, { payload }) => ({
      ...state,
      zabbixAlarmsFetchState: payload
    }),

    [types.updateZabbixAlarmActions]: (state, { payload }) => ({
      ...state,
      alarmActions: payload
    }),

    [types.downloadingAlarms]: (state, { payload }) => ({
      ...state,
      downloadingAlarms: {
        ...state.downloadingAlarms,
        ...payload
      }
    }),

    [appTypes.appReset]: () => initialState
  },
  initialState
);
