/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ConnectedIcon,
  DisabledIcon,
  DisconnectedIcon
} from 'app/components/icons';
import { CustomTableHeader, TwoPartBar } from 'app/components/tables';
import { styleLibrary } from 'app/constants';
import { getAPs, getDetails } from 'app/redux/inventory/utils';
import { getSortResult, toFormattedDateTime } from 'app/utils';
import {
  cloneDeep,
  filter,
  findIndex,
  get,
  isArray,
  isEmpty,
  last,
  size
} from 'lodash';
import React, { Fragment } from 'react';
import {
  LastRefreshTimeBodyRenderer,
  StatusBodyRenderer,
  createUptimeBodyRenderer,
  statusHeadRenderer
} from './components/table-components.js';
import { omitInventoryColumnsFor } from './constants';
import {
  calculateAPCounts,
  calculateMaxUptime,
  getDetailsOfAPItem,
  getDetailsOfInventoryItem,
  getFullDetailsOfAPItem
} from './utils';

const CustomHeaderRenderer = (column, sortFn) => {
  const { sortDirection } = column;
  const isSorted = column.sortDirection !== 'none';
  const dir = isSorted ? sortDirection : 'asc';

  return (
    <CustomTableHeader
      key={column.name}
      columnIndex={column.index}
      isSorted={isSorted}
      sortDirection={dir}
      sortFn={sortFn}
    >
      {column.label}
    </CustomTableHeader>
  );
};

const MacAddressBodyRenderer = addresses =>
  isArray(addresses)
    ? addresses.map((address, index) => (
      <Fragment key={`${address}-${index}`}>
        {index === 0 ? null : <br />}
        <span>{address}</span>
      </Fragment>
    ))
    : addresses;

export const getInventoryColumns = (deviceType = '', { uptimes }) => {
  const maxUptime = calculateMaxUptime(uptimes);
  const displayOptionalCols = omitInventoryColumnsFor.includes(
    deviceType.toLowerCase()
  )
    ? 'false'
    : 'true';

  return [
    { name: 'hostName', label: 'Hostname' },
    {
      name: 'model',
      label: 'Model',
      options: { display: displayOptionalCols }
    },
    {
      name: 'firmewareVersion',
      label: 'F/W Ver',
      options: { display: displayOptionalCols }
    },
    {
      name: 'serialNumber',
      label: 'Serial #',
      options: { display: displayOptionalCols }
    },
    {
      name: 'macAddress',
      label: 'MAC Addr',
      options: {
        customBodyRender: MacAddressBodyRenderer,
        display: displayOptionalCols
      }
    },
    {
      name: 'ipAddress',
      label: 'IP Addr',
      options: {
        customHeadRender: CustomHeaderRenderer,
        display: displayOptionalCols
      }
    },
    { name: 'level', label: 'Location', options: { sortDirection: 'asc' } },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: StatusBodyRenderer,
        customHeadRender: CustomHeaderRenderer
      }
    },
    {
      name: 'uptime',
      label: 'Uptime/Downtime',
      options: {
        customBodyRender: createUptimeBodyRenderer(maxUptime),
        display: displayOptionalCols
      }
    },
    {
      name: 'lastRefreshTime',
      label: 'Last Refresh',
      options: { customBodyRender: LastRefreshTimeBodyRenderer }
    }
  ];
};

export const getInventoryAPColumns = counts => {
  const apCounts = calculateAPCounts(counts);
  const maxNoOfDevices = Math.max(...apCounts.map(ap => ap.total));
  const totals = {
    connected: apCounts.reduce((p, c) => p + c.connected, 0),
    disconnected: apCounts.reduce((p, c) => p + c.disconnected, 0),
    totals: apCounts.reduce((p, c) => p + c.total, 0)
  };

  const StatusesBodyRender = (value, meta) => {
    if (!isArray(meta.rowData)) {
      return '';
    }

    const [
      connected,
      disconnected,
      active = 0,
      inactive = 0
    ] = meta.rowData.slice(-4);
    const hasActiveInactive = active > 0 || inactive > 0;

    return (
      <TwoPartBar
        barWidth={250}
        left={{
          value: hasActiveInactive ? active : connected,
          color: styleLibrary.connectedColor,
          indicator: <ConnectedIcon height={styleLibrary.inlineIconHeight} />
        }}
        max={maxNoOfDevices}
        right={{
          value: hasActiveInactive ? inactive : disconnected,
          color: styleLibrary.disconnectedColor,
          indicator: <DisconnectedIcon height={styleLibrary.inlineIconHeight} />
        }}
      />
    );
  };

  return [
    { name: 'model', label: 'Model', options: { sortDirection: 'asc' } },
    { name: 'apType', label: 'Device Type' },
    {
      name: 'total',
      label: 'Count/Total',
      options: {
        customHeadRender: statusHeadRenderer(totals),
        customBodyRender: StatusesBodyRender
      }
    },
    {
      name: 'refreshTimestamp',
      label: 'Last Refresh',
      options: { customBodyRender: LastRefreshTimeBodyRenderer }
    },
    { name: 'connected', options: { display: 'false' } },
    { name: 'disconnected', options: { display: 'false' } },
    { name: 'Active', options: { display: 'false' } },
    { name: 'Inactive', options: { display: 'false' } }
  ];
};

export const getInventoryTableDetails = (inventory, { deviceType = '' }) => {
  const isAPSelected = deviceType.toLowerCase() === 'aps';
  const selectedDevices = isAPSelected
    ? getAPs(inventory)
    : filter(getDetails(inventory), { type: deviceType });

  let detailsForType = selectedDevices.map(
    isAPSelected ? getDetailsOfAPItem : getDetailsOfInventoryItem
  );

  const counts =
    isAPSelected && selectedDevices.map(getConnectedDisconnectedStates);
  const uptimes = isAPSelected
    ? 'ap-uptimes'
    : selectedDevices.map(({ uptime }) => uptime);
  const columns = isAPSelected
    ? getInventoryAPColumns(counts)
    : getInventoryColumns(deviceType, { uptimes });

  if (isAPSelected) {
    detailsForType = detailsForType.reduce(mergeDetailsByModel, []);
  }
  return { selectedDevices, detailsForType, columns, uptimes };
};

function getConnectedDisconnectedStates({
  connected,
  disconnected,
  total,
  Active = 0,
  Inactive = 0
}) {
  return {
    connected,
    disconnected,
    total,
    Active,
    Inactive
  };
}

function mergeDetailsByModel(details, current) {
  const index = findIndex(details, ([model]) => model === current[0]);
  if (index !== -1) {
    const existing = details[index].slice();
    const [
      model,
      deviceType,
      existingTotal,
      timestamp,
      existingConn,
      existingDisconn,
      existingActive,
      existingInactive
    ] = existing;
    const [
      ,
      ,
      currentTotal,
      ,
      currentConn,
      currentDisconn,
      currentActive,
      currentInactive
    ] = current;

    details[index] = [
      model,
      deviceType,
      existingTotal + currentTotal,
      timestamp,
      existingConn + currentConn,
      existingDisconn + currentDisconn,
      existingActive + currentActive,
      existingInactive + currentInactive
    ];
    return details;
  }
  return details.concat([current]);
}

const getAPDetailsColumns = uptimes => {
  const maxUptime = calculateMaxUptime(uptimes);

  return [
    { name: 'APName', label: 'APName' },
    { name: 'model', label: 'Model' },
    { name: 'firmwareVersion', label: 'F/W Ver' },
    { name: 'serialNumber', label: 'Serial #' },
    {
      name: 'apmac',
      label: 'MAC Addr',
      options: { customBodyRender: MacAddressBodyRenderer }
    },
    {
      name: 'ipAddress',
      label: 'IP Addr',
      options: { customHeadRender: CustomHeaderRenderer }
    },
    { name: 'switchName', label: 'Switch Name' },
    { name: 'switchPort', label: 'Switch Port' },
    { name: 'zonename', label: 'Location', options: { sortDirection: 'asc' } },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: StatusBodyRenderer,
        customHeadRender: CustomHeaderRenderer
      }
    },
    {
      name: 'uptime',
      label: 'Uptime/Downtime',
      options: { customBodyRender: createUptimeBodyRenderer(maxUptime) }
    },
    { name: 'city', label: 'City', options: { display: false } },
    { name: 'description', label: 'Description', options: { display: false } },
    {
      name: 'lastRefreshTime',
      label: 'Last Refresh',
      options: { display: false }
    },
    { name: 'region', label: 'Region', options: { display: false } },
    { name: 'state', label: 'State', options: { display: false } },
    { name: 'subcustomer', label: 'Subcustomer', options: { display: false } },
    { name: 'type', label: 'Type', options: { display: false } },
    { name: 'vertical', label: 'Vertical', options: { display: false } }
  ];
};

export const getAPDetailsTableData = data => {
  const detailsForType = data.map(getFullDetailsOfAPItem);
  const uptimes = data.map(({ uptime }) => uptime);
  const columns = getAPDetailsColumns(uptimes);
  return { detailsForType, columns, uptimes };
};

const portStatusClassifier = val => {
  const connectedTypes = ['up', 'connect', 'connected'];
  const disconnectedTypes = [
    'down',
    'notconnect',
    'notconnected',
    'disconnect',
    'disconnected'
  ];
  const disabledTypes = ['', 'disable', 'disabled'];
  const trimmedVal = val.replace(' ', '').toLowerCase();

  return connectedTypes.includes(trimmedVal)
    ? 'Connected'
    : disconnectedTypes.includes(trimmedVal)
      ? 'Disconnected'
      : disabledTypes.includes(trimmedVal)
        ? 'Disabled'
        : val;
};

export const sortSwitchDetailsTable = (data, col, order) =>
  data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];

    if (col === 0) {
      const formatPort = port => {
        const pe = port.split('/');
        let le = last(pe);
        le = size(le) === 1 ? `0${le}` : le;
        pe[size(pe) - 1] = le;
        return pe.join('_');
      };
      valA = formatPort(a.data[col]);
      valB = formatPort(b.data[col]);
    }

    if (col === 1) {
      const statusA = portStatusClassifier(a.data[col]);
      const statusB = portStatusClassifier(b.data[col]);
      const sortStatus = s =>
        s === 'Connected'
          ? 0
          : s === 'Disconnected'
            ? 1
            : s === 'Disabled'
              ? 2
              : 3;
      valA = sortStatus(statusA);
      valB = sortStatus(statusB);
    }
    return getSortResult(valA, valB, order);
  });

const DeviceConnectedBodyRenderer = value => {
  const formattedValue = value
    .split(',')
    .map(mac => mac.trim())
    .join(', ');
  return <span>{formattedValue}</span>;
};

export const getSwitchDetailsTableColumns = () => {
  const PortStatusBodyRenderer = value => {
    const s = portStatusClassifier(value);
    return s === 'Connected' ? (
      <ConnectedIcon height={styleLibrary.inlineIconHeight} />
    ) : s === 'Disconnected' ? (
      <DisconnectedIcon height={styleLibrary.inlineIconHeight} />
    ) : s === 'Disabled' ? (
      <DisabledIcon height={styleLibrary.inlineIconHeight} />
    ) : (
      value
    );
  };

  return [
    { name: 'port', label: 'Switch Port' },
    {
      name: 'portStatus',
      label: 'Port Status',
      options: {
        customBodyRender: PortStatusBodyRenderer,
        customHeadRender: CustomHeaderRenderer
      }
    },
    {
      name: 'deviceConnected',
      label: 'Device Connected',
      options: {
        customBodyRender: DeviceConnectedBodyRenderer
      }
    },
    {
      name: 'accessMode',
      label: 'AcessMode/VLAN',
      options: { display: false }
    },
    { name: 'speed1', label: 'Duplex', options: { display: false } },
    { name: 'speed2', label: 'Speed' },
    { name: 'speed3', label: 'Type', options: { display: false } },
    {
      name: 'lastRefreshtime',
      label: 'Last Refresh',
      options: { customBodyRender: LastRefreshTimeBodyRenderer }
    },
    { name: 'portDescription', label: 'Port Description' }
  ];
};

export const exportSwitchDetailsTableColumns = data => {
  const columns = getSwitchDetailsTableColumns().filter(c =>
    get(c, 'options.display', true)
  );

  let transformedData = cloneDeep(data);
  transformedData = transformedData.map(d => {
    d['port'] = isEmpty(d['port']) ? '' : `'${d['port']}'`;
    d['portStatus'] = portStatusClassifier(d['portStatus']);
    d['lastRefreshtime'] = toFormattedDateTime(d['lastRefreshtime']);
    d['deviceConnected'] = d['deviceConnected']
      .split(',')
      .map(mac => mac.trim())
      .join(', ');
    return d;
  });
  transformedData = JSON.parse(
    JSON.stringify(
      transformedData,
      columns.map(c => c.name)
    )
  );
  transformedData = transformedData.map(Object.values);

  return [
    {
      name: 'Inventory Switch Details',
      data: transformedData,
      columns
    }
  ];
};
