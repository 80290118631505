/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { findIndex, first } from 'lodash';

import { sortByProperty, sumByProperty } from 'app/utils';

export const getTopPlusOther = (
  src,
  topNumber,
  displayProperty,
  sumProperty,
  sortProperty
) => {
  const total = src.reduce(sumByProperty(sumProperty), 0);

  let resultSet = src
    .sort(sortByProperty(sortProperty, true))
    .map(dataPoint => ({
      [displayProperty]: dataPoint.label,
      count: dataPoint[sumProperty]
    }));

  if (resultSet.length > topNumber) {
    const dataCopy = resultSet.slice();
    const otherIdx = findIndex(dataCopy, { [displayProperty]: 'Others' });
    const endSpliceIdx = otherIdx < topNumber ? topNumber + 1 : topNumber;
    const display = dataCopy.splice(0, endSpliceIdx);
    const totalRemaining = dataCopy.reduce((tot, cur) => tot + cur.count, 0);

    let otherEntry;

    if (otherIdx === -1) {
      // If no "Other" entry in the selected list, create a blank one and chop one off the
      // end of the display list (if necessary) to keep it consistent with other outcomes
      otherEntry = { [displayProperty]: 'Others', count: 0 };
      display.splice(topNumber, 1);
    } else if (otherIdx >= topNumber) {
      // If "Other" outside the top collection, find it and store it off
      otherEntry = first(dataCopy.splice(otherIdx - topNumber, 1));
    } else {
      otherEntry = first(display.splice(otherIdx, 1));
    }

    otherEntry.count += totalRemaining;
    display.push(otherEntry);
    resultSet = display;
  }

  const updatedOtherIdx = findIndex(resultSet, { [displayProperty]: 'Others' });
  if (updatedOtherIdx !== -1) {
    // Now force "Other" to be the last item in the array (displays at the bottom of the chart)
    resultSet.push(first(resultSet.splice(updatedOtherIdx, 1)));
  }

  return { resultSet, total };
};
