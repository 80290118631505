/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { fetchStatePropTypes } from 'app/redux/utils';
import { userLogin, loginFetchStateSelector } from 'app/redux/app';

import {
  LoginHeader,
  LoginBody,
  LoginError,
  LoginCopyright
} from './components';

import { styleLibrary } from 'app/constants';

const LoginWrapper = styled.div.attrs({
  className: 'container'
})`
  width: 600px;
  max-width: 90%;
  background-color: #fff;
  border: 1px solid ${styleLibrary.containerBorderColor};
`;

export class Login extends Component {
  constructor(props) {
    super(props);

    this.usernameField = React.createRef();

    this.state = {
      username: '',
      password: '',
      hasError: false
    };
  }
  componentDidMount() {
    this.usernameField.current.focusField();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.fetchState.failed !== this.props.fetchState.failed &&
      this.props.fetchState.failed
    ) {
      this.handleLoginError();
    }
  }

  handleLoginError = err => {
    this.setState({
      password: '',
      hasError: true,
      error: err
    });
  };

  handleLogin = e => {
    const { username, password } = this.state;
    e.preventDefault();

    if (username === '' || password === '') {
      this.handleLoginError('No credentials provided');
    } else {
      this.props.userLogin({
        username,
        password
      });
    }
  };

  updateStateOnFieldChange = (id, value) => {
    this.setState({
      [id]: value
    });
  };

  render() {
    const {
      fetchState: { failed: isLoginFailed, pending }
    } = this.props;

    const fields = [
      {
        id: 'username',
        type: 'email',
        text: 'Username',
        ref: this.usernameField,
        small: true,
        onValueChange: this.updateStateOnFieldChange,
        value: this.state.username
      },
      {
        id: 'password',
        type: 'password',
        text: 'Password',
        small: true,
        onValueChange: this.updateStateOnFieldChange,
        value: this.state.password
      }
    ];

    return (
      <Fragment>
        <LoginWrapper data-test-label="login-container">
          <LoginHeader />
          <LoginError hasError={isLoginFailed} />
          <LoginBody
            isPending={pending}
            onSubmit={this.handleLogin}
            fields={fields}
          />
        </LoginWrapper>
        <LoginCopyright />
      </Fragment>
    );
  }
}

Login.propTypes = {
  fetchState: fetchStatePropTypes,
  userLogin: PropTypes.func.isRequired
};

const mapStateToProps = createSelector(
  loginFetchStateSelector,
  fetchState => ({ fetchState })
);
export default connect(
  mapStateToProps,
  { userLogin }
)(Login);
