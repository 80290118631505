/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { types as appTypes } from 'app/redux/app';
import {
  createAsyncTypes,
  createFetchStatePayloadCreator,
  createTypes,
  pendingFetchState,
  updateFetchStateReducer
} from 'app/redux/utils';
import { filter, get } from 'lodash';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import createFetchAbuseSessionDetailsSagas from './abuse-session-details-saga';
import createInventoryReportSagas from './fetch-inventory-report-saga';
import createFetchPropertyTrendSagas from './fetch-property-trend-saga';
import createFetchReportsListSagas from './fetch-reports-saga';
import createFetchVLANConsumptionReportSagas from './fetch-vlan-consumption-report-saga';
import createReportActionSagas from './report-action-sagas';
import createSwitchPortReportSagas from './fetch-switch-port-report-saga';
import createIncidentReportSagas from './fetch-incident-report-saga';
import createClientReportSagas from './fetch-client-report-saga';

export const nameSpace = 'reports';

export const types = createTypes(
  [
    ...createAsyncTypes('fetchReportsList'),
    'downloadReport',
    'generateReport',
    'generateInProgress',
    'reportError',
    'getReportFile',
    'getReportFileError',
    'updateReportsListFetchState',
    ...createAsyncTypes('fetchInventoryReport'),
    'updateInventoryReportFetchState',
    ...createAsyncTypes('fetchSwitchPortReport'),
    'updateSwitchPortReportFetchState',
    ...createAsyncTypes('fetchVLANConsumptionReport'),
    'updateVLANConsumptionReportFetchState',
    ...createAsyncTypes('fetchAbuseSessionDetails'),
    'updateAbuseSessionDetailsFetchState',
    ...createAsyncTypes('fetchPropertyTrendStatus'),
    'updatePropertyTrendStatusFetchState',
    ...createAsyncTypes('fetchInventoryTrendStatus'),
    'updateInventoryTrendStatusFetchState',
    ...createAsyncTypes('fetchIncidentReport'),
    'updateIncidentReportFetchState',
    ...createAsyncTypes('fetchClientReport'),
    'updateClientReportFetchState',
    'SET_SWITCH_REPORT_GENERATED',
    'SET_INVENTORY_GENERATED',
    'SET_INCIDENT_REPORT_GENERATED',
    'SET_CLIENT_REPORT_GENERATED'
  ],
  nameSpace
);

export const sagas = [
  ...createFetchReportsListSagas(types),
  ...createReportActionSagas(types),
  ...createInventoryReportSagas(types),
  ...createSwitchPortReportSagas(types),
  ...createFetchAbuseSessionDetailsSagas(types),
  ...createFetchPropertyTrendSagas(types),
  ...createFetchVLANConsumptionReportSagas(types),
  ...createIncidentReportSagas(types),
  ...createClientReportSagas(types)
];

export const actionTypes = {
  SET_SWITCH_REPORT_GENERATED: 'SET_SWITCH_REPORT_GENERATED',
  SET_INVENTORY_GENERATED: 'SET_INVENTORY_GENERATED',
  SET_INCIDENT_REPORT_GENERATED: 'SET_INCIDENT_REPORT_GENERATED',
  SET_CLIENT_REPORT_GENERATED: 'SET_CLIENT_REPORT_GENERATED'
};

export const setSwitchReportGenerated = isGenerated => ({
  type: actionTypes.SET_SWITCH_REPORT_GENERATED,
  payload: isGenerated
});

export const setInventoryReportGenerated = isGenerated => ({
  type: actionTypes.SET_INVENTORY_GENERATED,
  payload: isGenerated
});

export const setIncidentReportGenerated = isGenerated => ({
  type: actionTypes.SET_INCIDENT_REPORT_GENERATED,
  payload: isGenerated
});

export const setClientReportGenerated = isGenerated => ({
  type: actionTypes.SET_CLIENT_REPORT_GENERATED,
  payload: isGenerated
});

export const fetchReportsList = createAction(types.fetchReportsList);
export const fetchReportsListComplete = createAction(
  types.fetchReportsListComplete
);
export const fetchReportsListError = createAction(types.fetchReportsListError);
export const updateReportsListFetchState = createAction(
  types.updateReportsListFetchState,
  createFetchStatePayloadCreator('reportsList')
);

export const fetchInventoryReport = createAction(types.fetchInventoryReport);
export const fetchInventoryReportComplete = createAction(
  types.fetchInventoryReportComplete
);
export const fetchInventoryReportError = createAction(
  types.fetchInventoryReportError
);
export const updateInventoryReportFetchState = createAction(
  types.updateInventoryReportFetchState
);

export const fetchSwitchPortReport = createAction(types.fetchSwitchPortReport);
export const fetchSwitchPortReportComplete = createAction(
  types.fetchSwitchPortReportComplete
);
export const fetchSwitchPortReportError = createAction(
  types.fetchInventoryReportError
);
export const updateSwitchPortReportFetchState = createAction(
  types.updateSwitchPortReportFetchState
);

export const fetchIncidentReport = createAction(types.fetchIncidentReport);
export const fetchIncidentReportComplete = createAction(
  types.fetchIncidentReportComplete
);
export const fetchIncidentReportError = createAction(
  types.fetchIncidentReportError
);
export const updateIncidentReportFetchState = createAction(
  types.updateIncidentReportFetchState
);

export const fetchVLANConsumptionReport = createAction(
  types.fetchVLANConsumptionReport
);
export const fetchVLANConsumptionReportComplete = createAction(
  types.fetchVLANConsumptionReportComplete
);
export const fetchVLANConsumptionReportError = createAction(
  types.fetchVLANConsumptionReportError
);
export const updateVLANConsumptionReportFetchState = createAction(
  types.updateVLANConsumptionReportFetchState
);

export const fetchAbuseSessionDetails = createAction(
  types.fetchAbuseSessionDetails
);
export const fetchAbuseSessionDetailsComplete = createAction(
  types.fetchAbuseSessionDetailsComplete
);
export const fetchAbuseSessionDetailsError = createAction(
  types.fetchAbuseSessionDetailsError
);
export const updateAbuseSessionDetailsFetchState = createAction(
  types.updateAbuseSessionDetailsFetchState,
  createFetchStatePayloadCreator('abuseSessionDetails')
);

export const fetchPropertyTrendStatus = createAction(
  types.fetchPropertyTrendStatus
);
export const fetchPropertyTrendStatusComplete = createAction(
  types.fetchPropertyTrendStatusComplete
);
export const fetchPropertyTrendStatusError = createAction(
  types.fetchPropertyTrendStatusError
);
export const updatePropertyTrendStatusFetchState = createAction(
  types.updatePropertyTrendStatusFetchState,
  createFetchStatePayloadCreator('propertyTrendChart')
);

export const fetchInventoryTrendStatus = createAction(
  types.fetchInventoryTrendStatus
);
export const fetchInventoryTrendStatusComplete = createAction(
  types.fetchInventoryTrendStatusComplete
);
export const fetchInventoryTrendStatusError = createAction(
  types.fetchInventoryTrendStatusError
);
export const updateInventoryTrendStatusFetchState = createAction(
  types.updateInventoryTrendStatusFetchState,
  createFetchStatePayloadCreator('inventoryTrendStatus')
);

export const fetchClientReport = createAction(types.fetchClientReport);
export const fetchClientReportComplete = createAction(
  types.fetchClientReportComplete
);
export const fetchClientReportError = createAction(
  types.fetchClientReportError
);
export const updateClientReportFetchState = createAction(
  types.updateClientReportFetchState
);

const fetchStatesSelector = state => state[nameSpace].fetchStates;
export const reportsListFetchStateSelector = state =>
  fetchStatesSelector(state).reportsList;
export const inventoryReportFetchStateSelector = state =>
  state[nameSpace].inventoryReportFetchState;
export const switchPortReportFetchStateSelector = state =>
  state[nameSpace].switchPortReportFetchState;
export const incidentReportFetchStateSelector = state =>
  state[nameSpace].incidentReportFetchState;
export const abuseSessionDataFetchStateSelector = state =>
  fetchStatesSelector(state).abuseSessionDetails;
export const propertyTrendFetchStateSelector = state =>
  fetchStatesSelector(state).propertyTrendChart;
export const inventoryTrendFetchStateSelector = state =>
  fetchStatesSelector(state).inventoryTrendStatus;
export const vlanConsumptionReportFetchStateSelector = state =>
  state[nameSpace].vlanConsumptionReportFetchState;
export const clientReportFetchStateSelector = state =>
  state[nameSpace].clientReportFetchState;

export const downloadReport = createAction(types.downloadReport);
export const getReportFile = createAction(types.getReportFile);
export const generateReport = createAction(types.generateReport);
export const generateInProgress = createAction(types.generateInProgress);
export const reportError = createAction(types.reportError);

export const reportsListSelector = state => state[nameSpace].reportsList;
export const displayReportsListSelector = createSelector(
  reportsListSelector,
  reports => filter(reports, x => x.status !== 'INACTIVE')
);

export const downloadingReportSelector = state =>
  state[nameSpace].downloadingReport;
export const generateInProgressSelector = state =>
  state[nameSpace].generateInProgress;
export const downloadErrorSelector = state => state[nameSpace].downloadError;

export const abuseSessionDataSelector = state =>
  state[nameSpace].abuseSessionDetails;

export const vlanConsumptionReportSelector = state =>
  state[nameSpace].vlanConsumptionReport;

export const propertyTrendStatusSelector = state => {
  let { propertyTrend: data = [], info = {} } = state[
    nameSpace
  ].propertyTrendChart;

  return { data, info };
};

export const inventoryTrendStatusSelector = state => {
  let {
    inventoryTrend: data = [],
    deviceModel: models = {},
    info = {}
  } = state[nameSpace].inventoryTrendStatus;
  return { data, models, info };
};

export const inventoryModelsSelector = deviceType =>
  createSelector(inventoryTrendStatusSelector, ({ models }) => {
    const selectedModels = Array.isArray(deviceType)
      ? deviceType
      : [deviceType];

    return selectedModels.reduce((acc, type) => {
      acc.push(...get(models, type, []));
      return acc;
    }, []);
  });

export const switchReportDataSelector = state =>
  state[nameSpace].switchReportData;

export const inventoryReportDataSelector = state =>
  state[nameSpace].inventoryReportData;

export const clientReportDataSelector = state =>
  state[nameSpace].clientReportData;

export const incidentReportDataSelector = state =>
  state[nameSpace].incidentReportData;

export const switchReportGeneratedSelector = state =>
  state[nameSpace].isSwitchReportGenerated;

export const inventoryReportGeneratedSelector = state =>
  state[nameSpace].isInventoryReportGenerated;

export const incidentReportGeneratedSelector = state =>
  state[nameSpace].isIncidentReportGenerated;

export const clientReportGeneratedSelector = state =>
  state[nameSpace].isClientReportGenerated;

const initialState = {
  fetchStates: {
    reportsList: pendingFetchState,
    abuseSessionDetails: pendingFetchState,
    propertyTrendChart: pendingFetchState,
    inventoryTrendStatus: pendingFetchState
  },
  downloadError: {},
  downloadingReport: {},
  generateInProgress: false,
  reportsList: [],
  inventoryReportFetchState: { inProgress: false, failed: false },
  inventoryReportData: {},
  switchPortReportFetchState: { inProgress: false, failed: false },
  switchReportData: {},
  abuseSessionDetails: [],
  propertyTrendChart: {},
  inventoryTrendStatus: {},
  vlanConsumptionReportFetchState: { inProgress: false, failed: false },
  vlanConsumptionReport: {},
  incidentReportFetchState: { inProgress: false, failed: false },
  incidentReportData: {},
  clientReportFetchState: { inProgress: false, failed: false },
  clientReportData: {},
  isSwitchReportGenerated: false,
  isInventoryReportGenerated: false,
  isIncidentReportGenerated: false,
  isClientReportGenerated: false
};

export const reducer = handleActions(
  {
    [actionTypes.SET_SWITCH_REPORT_GENERATED]: (state, { payload }) => ({
      ...state,
      isSwitchReportGenerated: payload
    }),
    [actionTypes.SET_INVENTORY_GENERATED]: (state, { payload }) => ({
      ...state,
      isInventoryReportGenerated: payload
    }),

    [actionTypes.SET_INCIDENT_REPORT_GENERATED]: (state, { payload }) => ({
      ...state,
      isIncidentReportGenerated: payload
    }),

    [actionTypes.SET_CLIENT_REPORT_GENERATED]: (state, { payload }) => ({
      ...state,
      isClientReportGenerated: payload
    }),

    [types.fetchReportsListComplete]: (state, { payload }) => ({
      ...state,
      reportsList: payload
    }),

    [types.downloadReport]: (state, { payload }) => ({
      ...state,
      downloadingReport: payload
    }),

    [types.reportError]: (state, { payload }) => ({
      ...state,
      downloadError: payload
    }),

    [types.generateInProgress]: (state, { payload }) => ({
      ...state,
      generateInProgress: payload
    }),

    [types.fetchAbuseSessionDetailsComplete]: (state, { payload }) => ({
      ...state,
      abuseSessionDetails: payload
    }),

    [types.fetchPropertyTrendStatusComplete]: (state, { payload }) => ({
      ...state,
      propertyTrendChart: payload
    }),

    [types.fetchInventoryTrendStatusComplete]: (state, { payload }) => ({
      ...state,
      inventoryTrendStatus: payload
    }),

    [types.updateReportsListFetchState]: updateFetchStateReducer,
    [types.updateAbuseSessionDetailsFetchState]: updateFetchStateReducer,
    [types.updatePropertyTrendStatusFetchState]: updateFetchStateReducer,
    [types.updateInventoryTrendStatusFetchState]: updateFetchStateReducer,

    [types.fetchInventoryReportComplete]: (state, { payload }) => ({
      ...state,
      inventoryReportData: payload
    }),
    [types.updateInventoryReportFetchState]: (state, { payload }) => ({
      ...state,
      inventoryReportFetchState: {
        ...state.inventoryReportFetchState,
        ...payload
      }
    }),

    [types.fetchSwitchPortReportComplete]: (state, { payload }) => ({
      ...state,
      switchReportData: payload
    }),
    [types.updateSwitchPortReportFetchState]: (state, { payload }) => ({
      ...state,
      switchPortReportFetchState: {
        ...state.switchPortReportFetchState,
        ...payload
      }
    }),

    [types.fetchVLANConsumptionReportComplete]: (state, { payload }) => ({
      ...state,
      vlanConsumptionReport: payload
    }),
    [types.updateVLANConsumptionReportFetchState]: (state, { payload }) => ({
      ...state,
      vlanConsumptionReportFetchState: {
        ...state.vlanConsumptionReportFetchState,
        ...payload
      }
    }),

    [types.fetchIncidentReportComplete]: (state, { payload }) => ({
      ...state,
      incidentReportData: payload
    }),
    [types.updateIncidentReportFetchState]: (state, { payload }) => ({
      ...state,
      incidentReportFetchState: {
        ...state.incidentReportFetchState,
        ...payload
      }
    }),

    [types.fetchClientReportComplete]: (state, { payload }) => ({
      ...state,
      clientReportData: payload
    }),

    [types.updateClientReportFetchState]: (state, { payload }) => ({
      ...state,
      clientReportFetchState: {
        ...state.clientReportFetchState,
        ...payload
      }
    }),

    [appTypes.userSignOut]: () => initialState,
    [appTypes.appReset]: () => initialState
  },
  initialState
);
