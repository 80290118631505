/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import styled from 'styled-components';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty, isEqual, isObject } from 'lodash';
import {
  CardDeck,
  CardDeckWrapper,
  NoDataToShow
} from 'app/components/elements';
import { CardSummary } from 'app/components/elements/card';
import { fetchStatePropTypes } from 'app/redux/utils';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { createSelector } from 'reselect';
import {
  cbrInventorySummaryFetchStateSelector,
  cbrInventorySummarySelector,
  fetchCBRsInventorySummary
} from 'app/redux/clients';

const CustomCard = styled.div`
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 110px;
  height: 100%;
  overflow: auto;
`;

const CardSection = ({ title, value, colSize, bgLight }) => (
  <div className={`col-${colSize}`}>
    <CustomCard className={`card${bgLight ? ' bg-light' : ''}`}>
      <div className="card-body">
        <p className="card-title">{title}</p>
        <h5 className="card-text">{value}</h5>
      </div>
    </CustomCard>
  </div>
);

CardSection.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colSize: PropTypes.number.isRequired,
  bgLight: PropTypes.bool
};

CardSection.defaultProps = {
  bgLight: true
};

export class CBRsInventorySummary extends Component {
  componentDidMount = () => {
    this.props.fetchCBRsInventorySummary();
  };

  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilterLabel,
      fetchCBRsInventorySummary
    } = this.props;
    const {
      selectedPath: prevSelectedPath,
      dateRangeFilterLabel: prevDateRangeFilter
    } = prevProps;
    const propertyChanged = !isEqual(prevSelectedPath.id, selectedPath.id);
    const dateRangeChanged = !isEqual(
      prevDateRangeFilter,
      dateRangeFilterLabel
    );
    if (propertyChanged || dateRangeChanged) {
      fetchCBRsInventorySummary();
    }
  };

  getCBRsDevicesCount = (data, deviceName, propertyName) => {
    const path = `CBRSInventory["LTE/ENI/Saratoga"].devices["${deviceName}"]`;
    const deviceProperties = get(data, path, []);

    if (!deviceProperties.length) {
      return 0;
    }

    const count = deviceProperties.reduce((result, property) => {
      if (property.name === propertyName) {
        // Parse the property value to ensure it's a number
        result = parseInt(property.value, 10) || 0;
      }
      return result;
    }, 0);

    return count;
  };

  getCBRsDeviceInfo = (data, deviceName) => {
    const path = `CBRSInventory["LTE/ENI/Saratoga"].devices["${deviceName}"]`;
    const devices = get(data, path, []);

    return devices.reduce((info, property) => {
      switch (property.name) {
        case 'EPC License No':
          info.licenseNo = property.value;
          break;
        case 'EPC Support End Date':
          info.supportEndDate = property.value;
          break;
        case 'System Type':
          info.systemType = property.value;
          break;
        case 'EPC Version':
          info.version = property.value;
          break;
        case 'Operational State':
          info.opState = property.value;
          break;
        case 'Solution Type':
          info.solType = property.value;
          break;
        default:
          break;
      }
      return info;
    }, {});
  };

  render = () => {
    const {
      fetchState,
      CBRSInventory: data = {},
      fetchCBRsInventorySummary
    } = this.props;

    const { pending, complete } = fetchState;
    const hasData = isObject(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    const checkDeviceKey = 'CBRS-DEMO-Suwanee-EPC';

    const maxUsers = this.getCBRsDevicesCount(data, checkDeviceKey, 'MAX Users');
    const maxAPs = this.getCBRsDevicesCount(data, checkDeviceKey, 'MAX APs');
    const totalUsers = this.getCBRsDevicesCount(
      data,
      checkDeviceKey,
      'Total Users'
    );
    const totalAPs = this.getCBRsDevicesCount(
      data,
      checkDeviceKey,
      "Total AP's"
    );
    const offlineAPs = this.getCBRsDevicesCount(
      data,
      checkDeviceKey,
      "Offline AP's"
    );
    const attachedUsers = this.getCBRsDevicesCount(
      data,
      checkDeviceKey,
      'Attached Users'
    );

    const deviceInfo = this.getCBRsDeviceInfo(data, checkDeviceKey);

    return (
      <Fragment>
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() => fetchCBRsInventorySummary()}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message="No data available"
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            complete &&
            hasData && (
              <Fragment>
                <div style={{ marginTop: '2.5rem' }}></div>
                <CardDeckWrapper>
                  <CardDeck>
                    <CardSummary
                      name={'Attached Users'}
                      count={attachedUsers}
                    />
                    <CardSummary name={'Total Users'} count={totalUsers} />
                    <CardSummary name={'MAX Users'} count={maxUsers} />
                    <CardSummary name={"Offline AP's"} count={offlineAPs} />
                    <CardSummary name={"Total AP's"} count={totalAPs} />
                    <CardSummary name={"MAX AP's"} count={maxAPs} />
                  </CardDeck>
                </CardDeckWrapper>
                <hr />
                <div className="d-flex justify-content-around">
                  <CardSection
                    title="EPC License No"
                    value={deviceInfo.licenseNo}
                    colSize={5}
                  />
                  <CardSection
                    title="EPC Support End Date"
                    value={deviceInfo.supportEndDate}
                    colSize={4}
                  />
                  <CardSection
                    title="System Type"
                    value={deviceInfo.systemType}
                    colSize={3}
                  />
                </div>
                <hr />
                <div className="d-flex justify-content-around mb-4">
                  <CardSection
                    title="EPC Version"
                    value={deviceInfo.version}
                    colSize={6}
                  />
                  <CardSection
                    title="Operational State"
                    value={deviceInfo.opState}
                    colSize={3}
                  />
                  <CardSection
                    title="Solution Type"
                    value={deviceInfo.solType}
                    colSize={3}
                  />
                </div>
              </Fragment>
            )
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

CBRsInventorySummary.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  CBRSInventory: PropTypes.object,
  fetchState: fetchStatePropTypes,
  fetchCBRsInventorySummary: PropTypes.func
};

const mapStateToProps = createSelector(
  cbrInventorySummarySelector,
  cbrInventorySummaryFetchStateSelector,
  selectedPathSelector,
  dateRangeFilterLabelSelector,
  (CBRSInventory, fetchState, selectedPath, dateRangeFilterLabel) => ({
    CBRSInventory,
    fetchState,
    selectedPath,
    dateRangeFilterLabel
  })
);

const mapDispatchToProps = {
  fetchCBRsInventorySummary
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CBRsInventorySummary);
