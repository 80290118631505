/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { filterParamsSelector } from 'app/redux/filters';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';
import { ajaxGet } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { getPropertyParent, getCustomers } from 'app/utils';
import { get, merge } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchIncidentDetailsComplete,
  fetchIncidentDetailsError,
  fetchSfdcCaseDetailsComplete,
  fetchSfdcCaseDetailsError,
  updateIncidentDetailsFetchState,
  updateSfdcCaseDetailsFetchState
} from './';

export function* fetchIncidentDetailsSaga() {
  yield put(updateIncidentDetailsFetchState(pendingFetchState));

  yield call(waitForHierarchySaga);

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const params = yield merge({}, filters, {
    zone: get(selectedProperty, 'name', 'all')
  });

  const url = '/api/fastapi/getIncidentDetails';
  const paramNames = ['timeUnit', 'timeUnitFrom', 'timeUnitTo', 'zone', 'vertical'];

  try {
    const onTimeout = () =>
      put(updateIncidentDetailsFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchIncidentDetailsComplete(data));
    yield put(updateIncidentDetailsFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchIncidentDetailsError, err));
    yield put(updateIncidentDetailsFetchState(failedFetchState));
  }
}

export function* fetchSfdcDetailsSaga() {
  yield put(updateSfdcCaseDetailsFetchState(pendingFetchState));

  yield call(waitForHierarchySaga);

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);

  const selectedOperator = yield getCustomers(hierarchy, selectedPath);
  const selectedOperatorName = selectedOperator[0].value;

  let newOperatorName;
  if (selectedOperatorName.toLowerCase() === 'arris') {
    newOperatorName = 'Altice';
  } else {
    newOperatorName = selectedOperatorName;
  }

  const params = yield merge({}, filters, {
    zone: get(selectedProperty, 'name', 'all'),
    operator: newOperatorName,
  });

  const url = '/api/cdbapi/getCaseDetails';
  const paramNames = ['operator', 'timeUnit', 'timeUnitFrom', 'timeUnitTo', 'zone', 'vertical'];

  try {
    const onTimeout = () =>
      put(updateSfdcCaseDetailsFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchSfdcCaseDetailsComplete(data));
    yield put(updateSfdcCaseDetailsFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchSfdcCaseDetailsError, err));
    yield put(updateSfdcCaseDetailsFetchState(failedFetchState));
  }
}

export default function createFetchIncidentDetailsSagas(types) {
  return [
    takeLatest(types.fetchIncidentDetails, fetchIncidentDetailsSaga),
    takeLatest(types.fetchSfdcCaseDetails, fetchSfdcDetailsSaga),
  ];
}
