/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { styleLibrary } from 'app/constants';

/**
 * @method contrastColour
 * @param {String} colour A background colour to contrast against
 * @returns {String} A contrasting colour for use against the background color
 */
// Adapted from https://stackoverflow.com/a/41491220
export const contrastColour = colour => {
  if (!colour) {
    return '';
  }
  const colours = getRedGreenBlue(colour);
  const [redLum, greenLum, blueLum] = colours
    .map(colour => colour / 255)
    .map(colour => {
      if (colour <= 0.03928) {
        return colour / 12.92;
      }
      return Math.pow((colour + 0.055) / 1.055, 2.4);
    });

  const luminance = 0.2126 * redLum + 0.7152 * greenLum + 0.0722 * blueLum;

  return luminance > 0.179 ? styleLibrary.darkText : styleLibrary.lightText;
};

/**
 * @method colourToRGBA
 * @param {String} colour A CSS colour string
 * @param {Number} alpha The required alpha value
 * @returns {String} A CSS rgba colour string with the required alpha channel
 */

export const colourToRGBA = (colour, alpha = 1) => {
  if (!colour) {
    return '';
  }
  const [red, green, blue] = getRedGreenBlue(colour);
  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

/**
 *
 * @method createColourFactory
 * @param {Number} adjustment Amount of change to apply to the colours to create a different palette
 * @param {Array[]} colours An array of colour strings
 * @returns {Function} A colour string
 */
export const createColourFactory = (adjustment = 0, colours = _colours) => {
  const colourMap = {};
  return key => {
    if (!colourMap[key]) {
      const index = Object.keys(colourMap).length % colours.length;
      const [r, g, b] = colours[index];
      colourMap[key] = `rgb(${Math.abs(r + adjustment)}, ${Math.abs(
        g + adjustment
      )}, ${Math.abs(b + adjustment)})`;
    }
    return colourMap[key];
  };
};

/**
 * This is the default array of colours to be applied to bar/pie charts
 */
const _colours = [
  [102, 177, 232],
  [250, 164, 58],
  [91, 44, 131],
  [96, 189, 104],
  [241, 124, 176],
  [140, 112, 36],
  [112, 174, 152],
  [178, 118, 178],
  [0, 178, 89],
  [241, 88, 84],
  [255, 192, 18],
  [38, 101, 124],
  [77, 77, 77]
];

export const getRedGreenBlue = colourString => {
  if (colourString.indexOf('#') === 0) {
    let hexColour = colourString.slice(1);
    if (hexColour.length === 3) {
      const [r, g, b] = hexColour.split('');
      hexColour = ''.concat(r, r, g, g, b, b);
    }
    const red = parseInt(hexColour.slice(0, 2), 16);
    const green = parseInt(hexColour.slice(2, 4), 16);
    const blue = parseInt(hexColour.slice(4, 6), 16);
    return [red, green, blue];
  }
  const [, red, green, blue] = colourString.match(
    /rgba?\((\d*),\s?(\d*),\s?(\d*)[ ),]/
  );
  return [parseInt(red, 10), parseInt(green, 10), parseInt(blue, 10)];
};
