/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ModalBox, ModalWrapper } from './components';

class Error extends Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleNonModalClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleNonModalClick);
  }

  handleNonModalClick = e => {
    if (!this.modalRef.current.contains(e.target)) {
      this.props.closeModal();
    }
  };

  render() {
    const { closeModal, message } = this.props;

    return (
      <ModalWrapper data-test-label="about-modal">
        <div
          className={
            'd-flex justify-content-center align-items-center flex-column h-100'
          }
        >
          <ModalBox ref={this.modalRef}>
            <div>{message}</div>
            <button
              className="btn btn-primary mt-3"
              data-test-label="about-modal-close-button"
              onClick={() => closeModal()}
            >
              Close
            </button>
          </ModalBox>
        </div>
      </ModalWrapper>
    );
  }
}

Error.propTypes = {
  closeModal: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired
};

export default Error;
