/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { concat } from 'lodash';
import moment from 'moment';
import { userGroupList } from 'app/constants';

export const createOverviewHtmlExportDefinition = (selected, userName) => {
  const fileName = `${selected.id}_${moment().format('MM-DD-YYYY_HHmm')}.pdf`;

  return {
    title: 'Overview',
    userName,
    selector: '.include-in-pdf',
    options: {
      filename: fileName,
      canvas: {
        styles: [
          {
            blockClass: 'block-container',
            styles: [{ attribute: 'border', value: 0 }]
          }
        ]
      }
    }
  };
};

export const createOverviewExportDataDefinition = ({
  clientOsChartData,
  inventoryCalculatedValues,
  manufacturerChartData,
  networkDetailsChartData,
  networkChart,
  propertiesChartData,
  usageSummaryThroughput,
  top10Clients,
  lactop10Clients,
  top10ClientsDurationUnit,
  selected,
  userGroups
}) => {
  const isUserLAC = userGroupList.some(item => userGroups.includes(item));
  //Turn Inventory into an array of export definitions
  const exportDataDefinitions = [];
  // Add Top 10 clients Data Definition

  const top10ColumnNams = isUserLAC
    ? [
      { label: 'Client MAC', name: 'client' },
      { label: 'Property', name: 'zonename' },
      {
        name: 'manufacturer',
        label: 'Manufacturer'
      },
      {
        name: 'port',
        label: 'Port'
      },
      {
        name: 'switch',
        label: 'Switch'
      },
      { label: 'Vertical', name: 'vertical' },
      {
        label: 'Total Traffic (MB)',
        name: 'total_traffic',
        options: { sortDirection: 'desc' }
      },
      { label: 'Download (MB)', name: 'bandwidthrx' },
      { label: 'Upload (MB)', name: 'bandwidthtx' }
    ]
    : [
      { label: 'Client MAC', name: 'clientMAC' },
      { label: 'OS', name: 'osType' },
      { label: 'Property', name: 'zoneName' },
      { label: 'Vertical', name: 'vertical' },
      {
        label: 'Total Traffic (MB)',
        name: 'bandwidthSumInMB',
        options: { sortDirection: 'desc' }
      },
      { label: 'Download (MB)', name: 'bandwidthRXInMB' },
      { label: 'Upload (MB)', name: 'bandwidthTXInMB' },
      { label: '# Sessions', name: 'sessionCount' },
      {
        label: `Total Time (${top10ClientsDurationUnit})`,
        name: 'sessionDuration'
      }
    ];
  const top10ClientsData = isUserLAC ? lactop10Clients : top10Clients;
  exportDataDefinitions.push({
    name: 'Top 10 Clients',
    columns: top10ColumnNams,
    data: top10ClientsData
  });

  // Add Inventory Data Definition
  const {
    displayElementsMap: { Network: network, Property: property }
  } = inventoryCalculatedValues;
  exportDataDefinitions.push({
    name: 'Inventory',
    columns: [
      { label: 'Level', name: 'level' },
      { label: 'Type', name: 'type' },
      { label: 'Last Refresh', name: 'lastRefreshTime' },
      { label: 'Number Connected', name: 'numberConnected' },
      { label: 'Number Disconnected', name: 'numberDisconnected' },
      { label: 'Total', name: 'total' }
    ],
    data: concat(
      [],
      property,
      isSpecificNodeType(selected, 'customer') ? network : []
    )
  });

  // Add Client OS Data Definition
  exportDataDefinitions.push({
    name: 'Client OS',
    columns: [
      { label: 'OS Type', name: 'osType' },
      { label: 'Count', name: 'count' }
    ],
    data: clientOsChartData.clientOS
  });

  // Add Client Manufacturers Data Definition
  exportDataDefinitions.push({
    name: 'Manufacturers',
    columns: [
      { label: 'Manufacturer', name: 'manufacturer' },
      { label: 'Count', name: 'count' }
    ],
    data: manufacturerChartData.clientManufacturers
  });

  // Add Network > Traffic Data Definition
  const networkCommonColumns = [
    { label: 'Time', name: 'label' },
    { label: 'Download Bandwidth (MB)', name: 'download' },
    { label: 'Upload Bandwidth (MB)', name: 'upload' },
    { label: 'Total Bandwidth (MB)', name: 'total' }
  ];
  const networkColumnsNames = isUserLAC
    ? [
      ...networkCommonColumns,
      { label: 'AAA Auth Clients', name: 'cdr' },
      { label: 'Wireless Clients', name: 'mqtt' }
    ]
    : [
      ...networkCommonColumns,
      { label: 'Clients', name: 'clients' },
      { label: 'Sessions', name: 'sessions' }
    ];
  const dataSummary = isUserLAC ? networkChart : networkDetailsChartData;

  exportDataDefinitions.push({
    name: 'Network Summary (Traffic)',
    columns: networkColumnsNames,
    data: dataSummary
  });

  // Add Network > Throughput Data Definition
  exportDataDefinitions.push({
    name: 'Usage Summary (Data Rate)',
    columns: [
      { label: 'Time', name: 'label' },
      { label: 'Download Rate (kbps)', name: 'downloadrate' },
      { label: 'Upload Rate (kbps)', name: 'uploadrate' }
    ],
    data: usageSummaryThroughput
  });

  // Add Properties Data Definition
  exportDataDefinitions.push({
    name: 'Properties',
    columns: [
      { label: 'Type', name: 'propertyType' },
      { label: 'Count', name: 'propertyCount' }
    ],
    data: propertiesChartData
  });

  return exportDataDefinitions;
};

export const rowDisplayClasses = mode => [
  'flex-column',
  'align-items-center',
  mode === 'pie' && 'display-in-rows'
];

export const getDisplayModeProperties = (
  mode,
  showGuestPrivate,
  windowWidth
) => {
  const narrowMode = showGuestPrivate || windowWidth < 1400;

  return {
    padding: narrowMode ? '3' : '4',
    classes: narrowMode ? rowDisplayClasses(mode) : 'justify-content-around'
  };
};
