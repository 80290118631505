/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { TooltipHeader, TooltipWrapper } from 'app/components/charts';
import { getReadableTimeFormat } from 'app/utils';

const ValueWrapper = styled.div`
  font-weight: bold;
`;

export const ComparisonTooltip = ({ displayFn, payload }) => {
  if (!isEmpty(payload)) {
    const [valueLine] = payload;
    const { label, value } = valueLine.payload;

    return (
      <TooltipWrapper>
        <TooltipHeader>@ {getReadableTimeFormat(label)}</TooltipHeader>
        <div className="d-flex flex-column align-items-center">
          <ValueWrapper>{displayFn(value)}</ValueWrapper>
        </div>
      </TooltipWrapper>
    );
  } else {
    return <div />;
  }
};

ComparisonTooltip.defaultProps = {
  displayFn: x => x
};

ComparisonTooltip.propTypes = {
  displayFn: PropTypes.func.isRequired,
  payload: PropTypes.array
};
