/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import JsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import 'jspdf-autotable';

import {
  applyMiddleware,
  createStore as reduxCreateStore,
  compose
} from 'redux';
import createSagaMiddleware from 'redux-saga';

import { createAjaxHandler } from 'app/utils';
import rootReducer from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware({
  context: {
    ajax: createAjaxHandler(),
    reporter: {
      /* eslint-disable no-console */
      info: console.info,
      warn: console.warn,
      error: console.error
      /* eslint-enable no-console */
    },
    window,
    document,
    pdfGenerator: (orientation, units, size) => {
      const pdfDoc = new JsPDF(orientation, units, size);
      Object.defineProperties(pdfDoc, {
        pw: { value: () => pdfDoc.internal.pageSize.getWidth() },
        ph: { value: () => pdfDoc.internal.pageSize.getHeight() }
      });
      return pdfDoc;
    },
    saveFileAs: saveAs
  }
});

export default function createStore() {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = reduxCreateStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
