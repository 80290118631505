/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { takeEvery, take, getContext } from 'redux-saga/effects';

export function* handleBackgroundBlurSaga(closeModalType) {
  const window = yield getContext('window');
  const { document } = window;
  const appRoot = document.getElementById('app-root');
  const oldAppRootFilter = appRoot.style.filter.slice();

  // Setting the body overflow to hidden causes the header to reflow
  // this was found to be a more pleasing alternative
  window.onscroll = () => window.scrollTo(0, 0);
  appRoot.style.filter = 'blur(2px)';

  yield take(closeModalType);

  window.onscroll = () => {};
  appRoot.style.filter = oldAppRootFilter;
}

export default function createModalSagas(types) {
  return [
    takeEvery(types.openModal, handleBackgroundBlurSaga, types.closeModal)
  ];
}
