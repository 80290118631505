/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { MonthView } from 'react-calendar';
import moment from 'moment';
import styled from 'styled-components';

import { FaIcon } from 'app/components/icons';

import { styleLibrary } from 'app/constants';
import { selectedDateFormat } from '../constants';

const NavIconWrapper = styled.div.attrs(() => ({
  className: 'd-flex flex-row'
}))`
  width: 50%;
`;

const MonthViewStyleWrapper = styled.div`
  & .in-range {
    background-color: rgb(53, 126, 189);
    color: #fff;
  }

  & button.react-calendar__tile {
    border: none;
    cursor: pointer;
  }
  & button.react-calendar__tile:disabled {
    text-decoration: line-through;
    cursor: not-allowed;
    color: #999;
  }
  & button.react-calendar__tile:enabled:focus {
    background-color: rgb(53, 126, 189);
    outline: none;
  }
`;

const isForwardNavigationValid = fromDate => {
  return moment(fromDate)
    .add(1, 'month')
    .isBefore(
      moment()
        .add(1, 'month')
        .startOf('month'),
      'month'
    );
};

class CalendarSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromSelection: props.start,
      toSelection: props.end,
      rightPanelDate: moment(props.end)
        .startOf('month')
        .toDate(),
      firstSelection: true,
      maxToDate: new Date()
    };

    this.createHandleChange = this.createHandleChange.bind(this);
    this.handleAbortSelection = this.handleAbortSelection.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleDatePick = this.handleDatePick.bind(this);
    this.handleNavigationClick = this.handleNavigationClick.bind(this);
  }

  createHandleChange(propertyName) {
    return ISODate => this.setState({ [propertyName]: ISODate });
  }

  handleAbortSelection(e) {
    e.preventDefault();
    return this.props.handleDateSelection({ selectionAborted: true });
  }

  handleDatePick(pickedDate) {
    const { fromSelection, firstSelection } = this.state;
    const { maxRange = 30 } = this.props;

    const pickedMoment = moment(pickedDate);
    const fromMoment = moment(fromSelection);

    if (firstSelection || pickedMoment.isBefore(fromMoment)) {
      const maxToDate = pickedMoment.add(maxRange, 'days');
      return this.setState({
        fromSelection: pickedDate,
        toSelection: pickedDate,
        firstSelection: false,
        maxToDate: moment.min(maxToDate, moment()).toDate()
      });
    }
    return this.setState({
      toSelection: pickedDate,
      firstSelection: true
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const { fromSelection: start, toSelection: end } = this.state;
    return this.props.handleDateSelection({ start, end });
  }

  handleNavigationClick(direction, dateContext) {
    const ctxMoment = moment(dateContext);
    return () => {
      if (direction === 'forwardInTime') {
        if (isForwardNavigationValid(dateContext)) {
          const plusOneMonth = ctxMoment.add(1, 'month');
          return this.setState({
            rightPanelDate: plusOneMonth.startOf('month').toDate()
          });
        }
        return null;
      }
      const minusOneMonth = ctxMoment.subtract(1, 'month');
      return this.setState({
        rightPanelDate: minusOneMonth.startOf('month').toDate()
      });
    };
  }

  renderNavigation(rightPanelDate) {
    return (
      <nav className="d-flex justify-content-between pt-2">
        <NavIconWrapper>
          <FaIcon
            onClick={this.handleNavigationClick('backInTime', rightPanelDate)}
            icon="angle-left"
            style={{ fontSize: styleLibrary.fontSizes.inlineIcon }}
          />
          <div className="p-1" />
          {moment(rightPanelDate)
            .subtract(1, 'month')
            .format('MMMM YYYY')}
        </NavIconWrapper>
        <NavIconWrapper className="justify-content-end">
          {moment(rightPanelDate).format('MMMM YYYY')}
          <div className="p-1" />
          {isForwardNavigationValid(rightPanelDate) ? (
            <FaIcon
              onClick={this.handleNavigationClick(
                'forwardInTime',
                rightPanelDate
              )}
              icon="angle-right"
              style={{ fontSize: styleLibrary.fontSizes.inlineIcon }}
            />
          ) : null}
        </NavIconWrapper>
      </nav>
    );
  }

  render() {
    const {
      fromSelection,
      toSelection,
      rightPanelDate,
      maxToDate
    } = this.state;

    const inRangeSelector = ({ date }) => {
      const queryDate = moment(date);
      const inRange =
        queryDate.isSameOrAfter(fromSelection) &&
        queryDate.isSameOrBefore(toSelection);
      return inRange ? 'in-range' : '';
    };

    return (
      <Fragment>
        <MonthViewStyleWrapper>
          {this.renderNavigation(rightPanelDate)}

          <div className="d-flex flex-row">
            <MonthView
              activeStartDate={moment(rightPanelDate)
                .subtract(1, 'month')
                .startOf('month')
                .toDate()}
              onClick={this.handleDatePick}
              tileClassName={inRangeSelector}
            />
            <div className="p-1" />
            <MonthView
              activeStartDate={rightPanelDate}
              onClick={this.handleDatePick}
              maxDate={maxToDate}
              tileClassName={inRangeSelector}
            />
          </div>
        </MonthViewStyleWrapper>
        <form
          className="form-inline justify-content-end"
          onSubmit={this.handleFormSubmit}
        >
          <div className="form-group">
            <label htmlFor="from-date-selection" className="sr-only">
              From date
            </label>
            <time id="from-date-selection" dateTime={fromSelection}>
              {moment(fromSelection).format(selectedDateFormat)}
            </time>
          </div>
          <span className="ml-1 mr-1">&ndash;</span>
          <div className="form-group">
            <label htmlFor="to-date-selection" className="sr-only">
              To date
            </label>
            <time id="to-date-selection" dateTime={toSelection}>
              {moment(toSelection).format(selectedDateFormat)}
            </time>
          </div>
          <div className="my-2">
            <button type="submit" className="btn btn-primary btn-sm mx-2">
              OK
            </button>
            <button
              onClick={this.handleAbortSelection}
              className="btn btn-light btn-sm"
            >
              Cancel
            </button>
          </div>
        </form>
      </Fragment>
    );
  }
}

CalendarSelection.displayName = 'CalendarSelection';
CalendarSelection.propTypes = {
  end: PropTypes.instanceOf(Date),
  handleDateSelection: PropTypes.func.isRequired,
  maxRange: PropTypes.number,
  start: PropTypes.instanceOf(Date)
};

export default CalendarSelection;
