/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { find } from 'lodash';

export const propertyAPsCombiner = (bw = [], client = [], propertyAPs = []) =>
  propertyAPs.map(ap => {
    const matchingClient = find(client, { APMAC: ap.apmac }) || {
      clientCount: 0
    };
    const matchingBandwidth = find(bw, { APMAC: ap.apmac }) || {
      bandwidthTXInMB: 0,
      bandwidthRXInMB: 0
    };
    const { bandwidthTXInMB: up, bandwidthRXInMB: down } = matchingBandwidth;

    return {
      ...ap,
      ...matchingBandwidth,
      bandwidthSumInMB: up + down,
      clientCount: matchingClient.clientCount
    };
  });
