/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import ErrorBoundary from 'app/components/utility/error-boundary';
import { networkIntelligence, styleLibrary, niVersion } from 'app/constants';

const Wrapper = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center flex-column'
})`
  font-family: 'Open Sans', sans-serif;
  background-color: ${styleLibrary.lightBg};
  height: 100vh;
`;

const AppErrorState = () => (
  <Wrapper data-test-label="app-error-boundary">
    <h1 className="mb-4">Application Error</h1>
    <p>
      Sorry, but the <b>{networkIntelligence}</b> application has encountered an
      error and needs to be relaunched.
    </p>
    <p>
      <i>
        If you&apos;re seeing this message repeatedly, please contact{' '}
        <a
          href="https://www.commscope.com/support/arris-support/"
          target="_blank"
          rel="noopener noreferrer"
        >
          CommScope Customer Support
        </a>{' '}
        for more assistance.
      </i>
    </p>

    <button
      className="btn btn-primary mt-4"
      onClick={() => window.location.reload(true)}
    >
      Relaunch {networkIntelligence}
    </button>

    <span className="mt-4">
      <small>
        <b>Version:</b> {niVersion}
      </small>
    </span>
  </Wrapper>
);

class AppErrorBoundary extends Component {
  render() {
    return (
      <ErrorBoundary
        boundaryName="App Level Boundary"
        renderOnError={() => <AppErrorState />}
      >
        {this.props.children}
      </ErrorBoundary>
    );
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.any
};

export default AppErrorBoundary;
