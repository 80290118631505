/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import classNames from 'classnames';
import { isEmpty, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  BlockContainer,
  TitleBar,
  NoDataToShow
} from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { StatefulTable } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  bandwidthUnits,
  MuiTableOptions,
  MuiTableTheme,
  styleLibrary,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import {
  fetchTopClients,
  maxClientValuesSelector,
  topClientsFetchStateSelector,
  top10ClientsDurationUnitSelector,
  top10ClientsSelector,
  topClientsSelector,
  totalClientValuesSelector
} from 'app/redux/clients';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { fetchStatePropTypes } from 'app/redux/utils';
import { createShowPanelSelector } from 'app/redux/app';
import {
  formatNumericValue,
  getScaledDisplayValue,
  scrollToBottom
} from 'app/utils';
import { getClientColumns, sortTopClients } from './utils';
import { SessionsForClient } from './components';

export class TopClients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedClient: {}
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchState.pending && prevProps.fetchState.complete) {
      this.setState({
        selectedClient: {}
      });
    }
  }

  handleTableRowClick = rowData => {
    const [clientMAC, , zonename] = rowData;
    const {
      selectedClient: { clientMAC: currentMAC }
    } = this.state;

    if (clientMAC === currentMAC) {
      this.setState({ selectedClient: {} });
    } else {
      this.setState({ selectedClient: { clientMAC, zonename } }, () =>
        scrollToBottom()
      );
    }
  };

  render() {
    const {
      clients,
      durationUnit,
      fetchState,
      info = {},
      maxClientValues,
      totalClientValues,
      dateRangeFilterLabel,
      fetchTopClients,
      showSessionforSelectedClient
    } = this.props;

    const { pending, complete } = fetchState;
    const { selectedClient, sortCol, sortDir } = this.state;
    const { bandwidth: total, top10Bandwidth: top10 } = totalClientValues;

    // We need to override the default theme to hide the footer on this particular table
    const clientsTableOverrides = {
      overrides: {
        MuiPaper: {
          root: {
            minHeight: 523
          }
        },
        MuiTableFooter: {
          root: {
            display: 'none'
          }
        }
      }
    };

    const clientsTableTheme = merge({}, MuiTableTheme, clientsTableOverrides);
    const clientsTableOptions = merge({}, MuiTableOptions, {
      customSort: sortTopClients,
      onRowClick: this.handleTableRowClick,
      setRowProps: row => {
        return {
          className:
            !isEmpty(selectedClient) &&
            classNames(
              row[0] === selectedClient.clientMAC
                ? 'row-selected'
                : 'row-unselected'
            )
        };
      },
      pagination: false,
      rowsPerPageOptions: []
    });

    const showDefaultCursor = showSessionforSelectedClient
      ? clientsTableTheme
      : customMuiTableThemeWithDefualtCursor;

    const hasClients = !isEmpty(clients);
    const top10Percent = formatNumericValue((top10 / total) * 100, 1);
    const top10Usage = getScaledDisplayValue(top10, bandwidthUnits);
    const totalUsage = getScaledDisplayValue(total, bandwidthUnits);
    const topClientColumns = getClientColumns(
      maxClientValues,
      sortCol,
      sortDir,
      durationUnit
    );
    const noDataToShow = !pending && complete && !hasClients;

    // Work out which clients to display based on whether a search value has been provided or not
    const clientsToDisplay = clients.map(c => ({ ...c, ...info }));

    return (
      <Fragment>
        <div className="row no-gutters mb-4 include-in-pdf">
          <BlockContainer classes={['col']}>
            <TitleBar
              leftChildren="Top 10 Unique Clients"
              rightChildren={
                <span
                  style={{ fontSize: styleLibrary.fontSizes.secondaryTitle }}
                >
                  {dateRangeFilterLabel}
                </span>
              }
            />
            {noDataToShow ? <NoDataToShow /> : null}
            <FailedFetchStateHandler
              fetchState={fetchState}
              retry={fetchTopClients}
            >
              <div
                className={pending || noDataToShow ? 'fetch-state-pending' : ''}
                style={{ minHeight: '300px' }}
              >
                {hasClients && complete && (
                  <MuiThemeProvider theme={createMuiTheme(showDefaultCursor)}>
                    <div className="d-flex justify-content-between align-items-end px-3">
                      <div>
                        <p>
                          This table includes data{' '}
                          <u>
                            <i>from completed sessions only</i>
                          </u>{' '}
                          and indicates that these clients have consumed
                          approximately <b>{top10Percent}% </b>(
                          <b>{top10Usage}</b>) of <b>{totalUsage}</b>.
                        </p>
                        {showSessionforSelectedClient && (
                          <p className="hide-in-pdf">
                            Click on any row to view full details of that
                            client&apos;s sessions.
                          </p>
                        )}
                      </div>
                    </div>
                    <StatefulTable
                      tableKey={JSON.stringify(maxClientValues)}
                      columns={topClientColumns}
                      data={clientsToDisplay}
                      options={clientsTableOptions}
                    />
                  </MuiThemeProvider>
                )}
              </div>
              {pending && <LoadingIconPlaceholder />}
            </FailedFetchStateHandler>
          </BlockContainer>
        </div>
        {showSessionforSelectedClient && !isEmpty(selectedClient) && (
          <SessionsForClient
            selectedClient={selectedClient}
            dateRangeFilterLabel={dateRangeFilterLabel}
          />
        )}
      </Fragment>
    );
  }
}

TopClients.propTypes = {
  clients: PropTypes.array.isRequired,
  dateRangeFilterLabel: PropTypes.string,
  durationUnit: PropTypes.string,
  fetchState: fetchStatePropTypes,
  fetchTopClients: PropTypes.func.isRequired,
  info: PropTypes.object,
  maxClientValues: PropTypes.object.isRequired,
  totalClientValues: PropTypes.object.isRequired,
  showSessionforSelectedClient: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('OverviewSession'),
  maxClientValuesSelector,
  top10ClientsSelector,
  top10ClientsDurationUnitSelector,
  topClientsFetchStateSelector,
  topClientsSelector,
  totalClientValuesSelector,
  dateRangeFilterLabelSelector,
  (
    showSessionforSelectedClient,
    maxClientValues,
    top10Clients,
    top10DurationUnit,
    fetchState,
    topClients,
    totalClientValues,
    dateRangeFilterLabel
  ) => ({
    showSessionforSelectedClient,
    maxClientValues,
    clients: top10Clients,
    durationUnit: top10DurationUnit,
    fetchState,
    info: topClients.info,
    totalClientValues,
    dateRangeFilterLabel
  })
);

const mapDispatchToProps = { fetchTopClients };

export default connect(mapStateToProps, mapDispatchToProps)(TopClients);
