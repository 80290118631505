/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React from 'react';

import { styleLibrary } from 'app/constants';
import { contrastColour } from 'app/utils';

const getTextWidth = (txt, font) => {
  const element = document.createElement('canvas');
  const context = element.getContext('2d');
  context.font = font;
  return context.measureText(txt).width;
};

const ChartLabel = item => {
  const textWidth = getTextWidth(
    item.value,
    styleLibrary.fontSizes.body + 'pt sans-serif'
  );
  const inBar = textWidth < item.width;

  return (
    <text
      x={item.width}
      dx={inBar ? 50 : 75}
      y={item.y}
      dy={(item.height + styleLibrary.fontSizes.body) / 2}
      textAnchor={inBar ? 'end' : 'start'}
      fill={inBar ? contrastColour(item.fill) : styleLibrary.darkText}
      fontSize={styleLibrary.fontSizes.body}
    >
      {item.value}
    </text>
  );
};

export default ChartLabel;
