/*
 * Copyright 2018-2022 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

// import { ErrorModal } from 'app/components/modals';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';

import {
  reportError,
  fetchInventoryReport,
  setInventoryReportGenerated,
  downloadErrorSelector,
  generateInProgressSelector,
  inventoryReportFetchStateSelector,
  inventoryReportGeneratedSelector
} from 'app/redux/reports';
import { createShowPanelSelector } from 'app/redux/app';
import { vertSSIDFilterSelector } from 'app/redux/filters';
import {
  hierarchyNodeSelected,
  hierarchySelector,
  rootNodeSelector,
  selectedPathSelector
} from 'app/redux/hierarchy';
// import { closeModal, openModal } from 'app/redux/ui';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import GenerateInventoryReport from './generate-inventory-report';

// const reportErrorModalKey = 'report-download-error';

class InventoryReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inventory: false
    };
  }
  // Generator Report button will be enabled when the path is changed at any hierarchy level.

  componentDidUpdate = prevProps => {
    const { selectedPath, setInventoryReportGenerated } = this.props;
    const { selectedPath: prevPath } = prevProps;
    const nodeChanged = isSpecificNodeType(selectedPath, 'zonename') && !isEqual(prevPath.id, selectedPath.id);

    if (nodeChanged) {
      this.setState({ inventory: false }, () => {
        setInventoryReportGenerated();
      });
    }
  };

  // closeErrorModal = () => {
  //   const { closeModal, reportError } = this.props;
  //   closeModal(reportErrorModalKey);
  //   reportError({});
  // };

  // getReportError = () => {
  //   const {
  //     downloadError: { type, error }
  //   } = this.props;
  //   if (isEqual(type, 'inventory-report')) {
  //     if (isEqual(get(error, 'message'), 'No data')) {
  //       return 'No Inventory Data Available!';
  //     }
  //     return 'Failed to generate inventory report!';
  //   }
  //   return 'No Inventory Data Available!';
  // };

  toggleGenerateReport = report => () =>
    this.setState(prevState => ({ [report]: !prevState[report] }));

  render = () => {
    const {
      // downloadError,
      fetchInventoryReport,
      showGenerateInventoryReport,
      setInventoryReportGenerated,
      isInventoryReportGenerated
    } = this.props;
    const { inventory } = this.state;

    // const hasReportError = !isEmpty(downloadError);

    return (
      <Fragment>
        {showGenerateInventoryReport && (
          <GenerateInventoryReport
            {...this.props}
            handleGenerateReport={fetchInventoryReport}
            showGenerate={inventory}
            toggleGenerate={this.toggleGenerateReport('inventory')}
            updateTableReportStatus={setInventoryReportGenerated}
            isInventoryReportGenerated={isInventoryReportGenerated}
          />
        )}
        {/* {hasReportError && (
          <ErrorModal
            closeModal={this.closeErrorModal}
            message={this.getReportError()}
          />
        )} */}
      </Fragment>
    );
  };
}

InventoryReports.propTypes = {
  setInventoryReportGenerated: PropTypes.func.isRequired,
  // closeModal: PropTypes.func.isRequired,
  // openModal: PropTypes.func.isRequired,
  reportError: PropTypes.func.isRequired,
  hierarchyNodeSelected: PropTypes.func,
  fetchInventoryReport: PropTypes.func.isRequired,
  downloadError: PropTypes.object,
  hierarchy: PropTypes.object,
  selectedPath: PropTypes.object,
  vertSSIDFilter: PropTypes.object.isRequired,
  rootNode: PropTypes.object,
  inventoryReportFetchState: PropTypes.object,
  showGenerateInventoryReport: PropTypes.bool,
  isInventoryReportGenerated: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('ReportsGenerateInventoryReport'),
  downloadErrorSelector,
  generateInProgressSelector,
  hierarchySelector,
  selectedPathSelector,
  vertSSIDFilterSelector,
  inventoryReportFetchStateSelector,
  rootNodeSelector,
  inventoryReportGeneratedSelector,
  (
    showGenerateInventoryReport,
    downloadError,
    generateInProgress,
    hierarchy,
    selectedPath,
    vertSSIDFilter,
    inventoryReportFetchState,
    rootNode,
    isInventoryReportGenerated
  ) => ({
    showGenerateInventoryReport,
    downloadError,
    generateInProgress,
    hierarchy,
    selectedPath,
    vertSSIDFilter,
    inventoryReportFetchState,
    rootNode,
    isInventoryReportGenerated
  })
);

const mapDispatchToProps = {
  // closeModal,
  // openModal,
  reportError,
  hierarchyNodeSelected,
  fetchInventoryReport,
  setInventoryReportGenerated
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReports);
