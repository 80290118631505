/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isAjaxTimeoutError } from 'app/custom-errors';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutFetchState
} from 'app/redux/utils';
import { cloneDeep, get, isEmpty, merge, noop } from 'lodash';
import Papa from 'papaparse';
import {
  call,
  getContext,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import { buildApiRequestArgs } from '../ajax-saga-utils';
import { ajaxPost } from '../util-sagas';
import {
  downloadAPDetailsComplete,
  downloadAPDetailsError,
  downloadingAPs,
  fetchInventoryAPDetailsComplete,
  fetchInventoryAPDetailsError,
  inventoryAPDetailsJSONSelector,
  updateInventoryAPDetailsFetchState
} from './';

const downloadAPDetailsUrls = ['/api/device/getInventoryApDetails'];

export function* fetchInventoryAPDetailsSaga({ payload }) {
  const skipCache = get(payload, 'skipCache', false);

  yield put(updateInventoryAPDetailsFetchState(pendingFetchState));
  const additionalOptions = yield {
    skipCache,
    headers: skipCache ? { 'hot-refresh': true } : {},
    additionalBodyParams: skipCache ? { refresh: true } : {}
  };

  try {
    const [requestArgs] = yield call(
      buildApiRequestArgs,
      downloadAPDetailsUrls,
      noop,
      additionalOptions
    );
    const { url, body, options } = yield requestArgs;
    const mergeBody = yield typeof body === 'string'
      ? body
      : JSON.stringify(body);

    const finalBody = yield merge({}, JSON.parse(mergeBody), {
      top: false,
      isCsv: true
    });
    const finalOptions = yield merge({}, options, {
      headers: { Accept: 'application/octet-stream' },
      onTimeout: noop,
      responseType: 'blob'
    });

    const { data } = yield ajaxPost(url, finalBody, finalOptions);
    yield put(fetchInventoryAPDetailsComplete(data));
    yield put(updateInventoryAPDetailsFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchInventoryAPDetailsError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(updateInventoryAPDetailsFetchState(updatedFetchState));
  }
}

export function* downloadAPDetailsSaga({ payload: { columns = [] } }) {
  yield put(downloadingAPs({ inProgress: true, failed: false }));
  const columnLabels = columns.map(c => c.label).sort();

  try {
    const saveFileAs = yield getContext('saveFileAs');
    const data = yield select(inventoryAPDetailsJSONSelector);
    let transformedData = yield cloneDeep(data);
    yield transformedData.forEach(d => {
      d['switchPort'] = isEmpty(d['switchPort']) ? '' : `'${d['switchPort']}'`;
    });
    transformedData = yield transformedData.map(d => {
      const ret = {};
      columns.forEach(c => (ret[c.label] = d[c.name]));
      return ret;
    });
    const csvDataString = yield Papa.unparse(transformedData, {
      quotes: true,
      header: true,
      skipEmptyLines: false,
      columns: columnLabels
    });
    const csvDataBlob = yield new Blob([csvDataString], {
      type: 'text/plain;charset=utf-8'
    });
    const filename = 'ap-details.csv';
    yield call(saveFileAs, csvDataBlob, filename);
    yield put(downloadAPDetailsComplete());
    yield put(downloadingAPs({ inProgress: false }));
  } catch (err) {
    yield put(createErrorAction(downloadAPDetailsError, err));
    yield put(downloadingAPs({ inProgress: false, failed: true }));
  }
}

export default function createDownloadAPDetailsSagas(types) {
  return [
    takeLatest(types.fetchInventoryAPDetails, fetchInventoryAPDetailsSaga),
    takeEvery(types.downloadAPDetails, downloadAPDetailsSaga)
  ];
}
