/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { CustomMuiDatatable } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  MuiTableOptions,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import {
  fetchPropertyUserDataUsage,
  propertyUserDataUsageFetchStateSelector,
  propertyUserDataUsageMaxValuesSelector,
  propertyUserDataUsageSelector
} from 'app/redux/clients';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import { isEmpty, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  customMuiTableTheme,
  getPropertyUserDataUsageByClientTableColumns,
  sortPropertyUserDataUsageByClientTable
} from '../utils';

export class UserDataUsageByClientTable extends Component {
  componentDidMount = () => {
    const { fetchPropertyUserDataUsage, userName } = this.props;
    fetchPropertyUserDataUsage({ userName });
  };

  componentDidUpdate = prevProps => {
    const { fetchPropertyUserDataUsage, userName } = this.props;
    const { userName: prevUserName } = prevProps;
    const userChanged = userName !== '' && userName !== prevUserName;

    if (userChanged) {
      fetchPropertyUserDataUsage({ userName });
    }
  };

  render = () => {
    const {
      selectedPath,
      fetchPropertyUserDataUsage,
      propertyUserDataUsage: { data = [], info = {} },
      fetchState,
      maxValues,
      userName
    } = this.props;

    const { pending, complete } = fetchState;
    const noDataToShow = !pending && complete && isEmpty(data);
    const columns = getPropertyUserDataUsageByClientTableColumns(
      info,
      maxValues
    );

    const tableOptions = {
      ...MuiTableOptions,
      customSort: (data, col, order) =>
        sortPropertyUserDataUsageByClientTable(data, col, order, columns),
      rowsPerPage: 5,
      rowsPerPageOptions: [5, 10, 15, 100]
    };

    const newMuiTableThemeWithCursor = merge(
      {},
      customMuiTableTheme,
      customMuiTableThemeWithDefualtCursor
    );

    return (
      <FailedFetchStateHandler
        fetchState={fetchState}
        retry={() => fetchPropertyUserDataUsage({ userName })}
      >
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow ? (
          <NoDataToShow
            message="No data available"
            mode="grid"
            style={{ background: 'none', position: 'relative', height: 'auto' }}
          />
        ) : (
          <div className={pending || noDataToShow ? 'fetch-state-pending' : ''}>
            {complete && (
              <MuiThemeProvider
                theme={createMuiTheme(newMuiTableThemeWithCursor)}
              >
                <CustomMuiDatatable
                  tableKey={`${selectedPath.id}-property-user-data-usage-by-client`}
                  columns={columns}
                  data={data}
                  options={tableOptions}
                />
              </MuiThemeProvider>
            )}
          </div>
        )}
      </FailedFetchStateHandler>
    );
  };
}

UserDataUsageByClientTable.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  fetchPropertyUserDataUsage: PropTypes.func,
  propertyUserDataUsage: PropTypes.object,
  fetchState: fetchStatePropTypes,
  maxValues: PropTypes.object,
  userName: PropTypes.string
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  propertyUserDataUsageSelector,
  propertyUserDataUsageFetchStateSelector,
  propertyUserDataUsageMaxValuesSelector,
  (
    dateRangeFilterLabel,
    selectedPath,
    propertyUserDataUsage,
    fetchState,
    maxValues
  ) => ({
    dateRangeFilterLabel,
    selectedPath,
    propertyUserDataUsage,
    fetchState,
    maxValues
  })
);

const mapDispatchToProps = { fetchPropertyUserDataUsage };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDataUsageByClientTable);
