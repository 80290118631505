/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Label from './label';

class FormTextField extends Component {
  constructor(props) {
    super(props);

    this.fieldDOMNode = React.createRef();
  }
  focusField() {
    this.fieldDOMNode.current.focus();
  }

  handleFieldChange(event) {
    const updatedValue = event.target.value;
    this.props.handleValueChanged(this.props.fieldId, updatedValue);
  }

  render() {
    const {
      labelText,
      fieldType,
      fieldId,
      fieldClasses,
      disabled,
      placeholder,
      small,
      value
    } = this.props;

    return (
      <div className="form-group">
        {labelText && <Label {...this.props} />}
        <input
          className={classNames(
            'form-control',
            small && 'form-control-sm',
            ...fieldClasses
          )}
          disabled={disabled ? 'disabled' : ''}
          id={fieldId}
          name={fieldId}
          onChange={this.handleFieldChange.bind(this)}
          placeholder={placeholder}
          ref={this.fieldDOMNode}
          type={fieldType || 'text'}
          value={value}
        />
      </div>
    );
  }
}

FormTextField.defaultProps = {
  fieldClasses: [],
  labelClasses: []
};

FormTextField.propTypes = {
  fieldClasses: PropTypes.array,
  fieldId: PropTypes.string.isRequired,
  fieldType: PropTypes.string,
  handleValueChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  labelClasses: PropTypes.array,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  value: PropTypes.string
};

export default FormTextField;
