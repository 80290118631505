/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { dateRangeNames } from 'app/components/filters/constants';
import { defaultDateFormat } from 'app/constants';
import { FileExportError } from 'app/custom-errors';
import { createErrorAction } from 'app/redux/utils';
import { parse } from 'json2csv';
import JSZip from 'jszip';
import { includes, isEmpty, upperFirst, values } from 'lodash';
import moment from 'moment';
import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';
import { exportDataError } from '..';

const createZipOutput = filesToZip => {
  const zip = new JSZip();

  filesToZip.forEach(outputDataItem => {
    zip.file(outputDataItem.name + '.csv', outputDataItem.data);
  });

  return zip.generateAsync({ type: 'blob', compression: 'DEFLATE' });
};

export function* csvSaga({ payload }) {
  // Create column definitions to get the fields by index.
  // Filter out columns that don't have a 'label' field - these are not to be displayed.
  const saveFileAs = yield getContext('saveFileAs');
  const { exportDataDefinitions, exportFilename, properties = {} } = payload;

  // If we have more than one output then we want to zip
  const zip = exportDataDefinitions && exportDataDefinitions.length > 1;

  try {
    const outputData = yield all(
      exportDataDefinitions.map(exportDefinition => {
        const fields = exportDefinition.columns
          .map((columnDef, columnIndex) => {
            return {
              label: columnDef.label,
              value: row => {
                const value = Array.isArray(row)
                  ? row[columnIndex]
                  : row[columnDef.name];
                // Remove empty values and format array values as comma seperated strings, i.e mac addresses
                if (Array.isArray(value)) {
                  return value
                    .filter(entry => entry.trim().length !== 0)
                    .join(', ');
                }
                return value;
              }
            };
          })
          .filter(columnDef => columnDef.label);
        let csvData = parse(exportDefinition.data, { fields });

        // If we have a selected path and date range, add those to the top of
        // the CSV so that a user has context when they open the file.
        const { dateRangeFilter = {}, selectedPath = {} } = properties;
        if (!isEmpty(dateRangeFilter) && !isEmpty(selectedPath)) {
          let dateRangeCsvLeader = `(${dateRangeFilter.label})`;

          if (includes(values(dateRangeNames), dateRangeFilter.label)) {
            const start = moment(dateRangeFilter.start).format(
              defaultDateFormat
            );
            const end = moment(dateRangeFilter.end).format(defaultDateFormat);
            dateRangeCsvLeader = `${dateRangeCsvLeader} ${start} - ${end}`;
          }

          const csvLeader = `${upperFirst(selectedPath.type)},${
            selectedPath.name
          },${dateRangeCsvLeader}`;

          csvData = `${csvLeader}\n\n${csvData}`;
        }

        return { data: csvData, name: exportDefinition.name };
      })
    );

    if (zip) {
      const zipBlob = yield call(createZipOutput, outputData);
      yield call(saveFileAs, zipBlob, exportFilename);
    } else {
      //if not a zip then we just return the first item
      const dataBlob = new Blob([outputData[0].data], {
        type: 'text/plain;charset=utf-8'
      });
      yield call(saveFileAs, dataBlob, exportFilename);
    }
  } catch (err) {
    const error = new FileExportError(
      'Unable to create CSV',
      err,
      exportFilename
    );
    yield put(createErrorAction(exportDataError, error));
  }
}

export default function createCsvSagas(types) {
  return [takeEvery(types.generateCsvFromDataDefinition, csvSaga)];
}
