/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { TableSortLabel, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { TableCell } from 'app/components/tables';

const HeaderWrapper = styled.div.attrs(props => ({
  className: classNames('d-flex align-items-center', props.active && 'active')
}))`
  cursor: pointer;

  &.active {
    color: #000;
  }
`;

export const CustomTableHeader = props => {
  const {
    children,
    columnIndex,
    isSorted,
    sortDirection,
    sortFn,
    sortTooltipPlacement = 'bottom'
  } = props;
  const direction =
    sortDirection && sortDirection !== 'none' ? sortDirection : 'desc';
  return (
    <TableCell onClick={() => sortFn(columnIndex)}>
      <Tooltip title="Sort" placement={sortTooltipPlacement} enterDelay={300}>
        <span role="button" tabIndex={0}>
          <HeaderWrapper active={isSorted}>
            {children}
            <TableSortLabel active={isSorted} direction={direction} />
          </HeaderWrapper>
        </span>
      </Tooltip>
    </TableCell>
  );
};

CustomTableHeader.defaultProps = {
  sortDirection: 'asc'
};

CustomTableHeader.propTypes = {
  children: PropTypes.any,
  columnIndex: PropTypes.number.isRequired,
  isSorted: PropTypes.bool.isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc', 'none']),
  sortFn: PropTypes.func.isRequired,
  sortTooltipPlacement: PropTypes.string
};
