/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React from 'react';
import PropTypes from 'prop-types';

const SubscriberDropdown = ({ data, onRealmChange, selectedRealm }) => {

  const hardcodedRealms = [
    '315010999900001',
    '315010999900030',
    '315010999900034'
  ];
    
  const apiRealms =
        data && data.TrafficTrendData
          ? data.TrafficTrendData.map(realmData => realmData.realm)
          : [];
    
  // Combine hardcoded realms with API realms and remove duplicates
  const allRealms = [...new Set([...hardcodedRealms, ...apiRealms])];
    
  const dropdownOptions = allRealms.map(realm => (
    <option key={realm} value={realm}>
      {realm}
    </option>
  ));
  return <div className="d-flex">
    <label htmlFor="realmDropdown" className="col-form-label-sm pr-2 mb-0">
        Subscriber:
    </label>
    <select
      id="realmDropdown"
      className="form-contol"
      value={selectedRealm}
      onChange={event => onRealmChange(event.target.value)}
      style={{ padding: '4px' }}
    >
      {dropdownOptions}
    </select>
  </div>;
};

SubscriberDropdown.propTypes = {
  data: PropTypes.array,
  selectedRealm: PropTypes.string,
  onRealmChange: PropTypes.func
};


export default SubscriberDropdown;