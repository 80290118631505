/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { MuiTableOptions, MuiTableTheme } from 'app/constants';
import { StatefulTable, TableExport } from 'app/components/tables';
import { NoDataToShow } from 'app/components/elements';
import { FaIcon, LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { scrollToBottom } from 'app/utils';
import classNames from 'classnames';
import { findIndex, isEmpty, isEqual, merge } from 'lodash';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';

import {
  fetchPropertyMqttClientDetails,
  propertyMqttClientDetailsSelector,
  propertyMqttClientDetailsFetchStateSelector,
  propertyMqttClientDetailsMaxValuesSelector
} from 'app/redux/clients';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  exportPropertyMqttClientsDetailsColumns,
  getPropertyMqttClientsTableColumns,
  sortPropertyClients,
  SubChartsTitle
} from '../utils';
import MqttClientBandwidthTrend from './mqtt-client-bandwidth-trend';

export class GetListOfMQTTClientTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      selectedClient: ''
    };
  }

  componentDidUpdate = prevProps => {
    const { selectedPath, dateRangeFilterLabel } = this.props;
    const {
      selectedPath: prevSelectedPath,
      dateRangeFilterLabel: prevDateRange
    } = prevProps;

    const propertyChanged = !isEqual(prevSelectedPath.id, selectedPath.id);
    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilterLabel);
    if (propertyChanged || dateRangeChanged) {
      this.setState({ selectedClient: '' });
    }
  };

  filterBySearch = data => {
    const { searchValue } = this.state;

    if (isEmpty(searchValue)) {
      return data;
    }

    const searchQuery = searchValue.toLowerCase().replace(/:/g, '');

    return data.filter(row =>
      Object.values(row).some(value => {
        const valueString = String(value)
          .toLowerCase()
          .replace(/:/g, '');
        return valueString.includes(searchQuery);
      })
    );
  };

  renderActions = (data, columns) => (
    <div className="d-flex justify-content-between m-2">
      <div className="d-flex justify-content-start align-items-center">
        <h3>MQTT Client Summary</h3>
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <FaIcon icon="search" classes={['mr-2']} />
        <label className="sr-only">Search...</label>
        <input
          className="p-1"
          id="property-clients-table-search"
          onChange={e => this.setState({ searchValue: e.target.value })}
          placeholder="Search..."
          style={{ width: 250 }}
          type="text"
          value={this.state.searchValue}
        />
        <TableExport
          hidePdfExport={false}
          exportName="Property MQTT Client Details"
          exportDataDefinitions={exportPropertyMqttClientsDetailsColumns(
            data,
            columns
          )}
        />
      </div>
    </div>
  );

  handleTableRowClick = (rowData, clientMacColumnIndex) => {
    const newSelectedClient = rowData[clientMacColumnIndex];
    if (newSelectedClient === this.state.selectedClient) {
      this.setState({ selectedClient: '' });
    } else {
      this.setState({ selectedClient: newSelectedClient }, scrollToBottom);
    }
  };

  setRowProps = (row, clientMacColumnIndex) => {
    const { selectedClient } = this.state;
    const newSelectedClient = row[clientMacColumnIndex];
    return {
      className:
        selectedClient !== '' &&
        classNames(
          newSelectedClient === selectedClient
            ? 'row-selected'
            : 'row-unselected'
        )
    };
  };

  render = () => {
    const {
      selectedPath,
      fetchPropertyMqttClientDetails,
      clientsData: { data = [], info = {} },
      fetchState,
      maxValues
    } = this.props;

    const { selectedClient } = this.state;

    const { pending, complete } = fetchState;
    const noDataToShow = !pending && complete && isEmpty(data);
    const columns = getPropertyMqttClientsTableColumns(info, maxValues);

    const clientMacColumnIndex = findIndex(columns, ['name', 'client']);

    const tableOptions = merge({}, MuiTableOptions, {
      customSort: (data, col, order) =>
        sortPropertyClients(data, col, order, columns),
      onRowClick: rowData =>
        this.handleTableRowClick(rowData, clientMacColumnIndex),
      setRowProps: row => this.setRowProps(row, clientMacColumnIndex)
    });

    return (
      <FailedFetchStateHandler
        fetchState={fetchState}
        retry={fetchPropertyMqttClientDetails}
      >
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow ? (
          <NoDataToShow
            message="There are currently no MQTT clients for this property"
            mode="grid"
            style={{
              background: 'none',
              position: 'relative'
            }}
          />
        ) : (
          <Fragment>
            {!pending && !isEmpty(data) && this.renderActions(data, columns)}
            <div>
              <p className="hide-in-pdf ml-2">
                Click on any row to view traffic details for the selected client
              </p>
            </div>
            <div
              className={pending || noDataToShow ? 'fetch-state-pending' : ''}
            >
              {complete && (
                <MuiThemeProvider theme={createMuiTheme(MuiTableTheme)}>
                  <StatefulTable
                    tableKey={`${selectedPath.id}-property-mqtt-clients`}
                    columns={columns}
                    data={this.filterBySearch(data)}
                    options={tableOptions}
                  />
                </MuiThemeProvider>
              )}
            </div>
            {selectedClient !== '' && (
              <Fragment>
                <SubChartsTitle>
                  Traffic for Client MAC&nbsp;<b>{selectedClient}</b>
                </SubChartsTitle>
                <MqttClientBandwidthTrend clientMAC={selectedClient} />
              </Fragment>
            )}
          </Fragment>
        )}
      </FailedFetchStateHandler>
    );
  };
}

GetListOfMQTTClientTable.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  hierarchy: PropTypes.object,
  selectedPath: PropTypes.object,
  clientsData: PropTypes.object,
  maxValues: PropTypes.object,
  fetchPropertyMqttClientDetails: PropTypes.func,
  fetchState: fetchStatePropTypes
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  hierarchySelector,
  selectedPathSelector,
  propertyMqttClientDetailsSelector,
  propertyMqttClientDetailsFetchStateSelector,
  propertyMqttClientDetailsMaxValuesSelector,
  (
    dateRangeFilterLabel,
    hierarchy,
    selectedPath,
    clientsData,
    fetchState,
    maxValues
  ) => ({
    dateRangeFilterLabel,
    hierarchy,
    selectedPath,
    clientsData,
    fetchState,
    maxValues
  })
);

const mapDispatchToProps = { fetchPropertyMqttClientDetails };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetListOfMQTTClientTable);
