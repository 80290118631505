/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { sagas as alarmsSagas } from 'app/redux/alarms';
import { sagas as appSagas } from 'app/redux/app';
import createErrorSagas from 'app/redux/app/sagas/error-saga';
import { sagas as clientsSagas } from 'app/redux/clients';
import { sagas as filterSagas } from 'app/redux/filters';
import { sagas as hierarchySagas } from 'app/redux/hierarchy';
import { sagas as incidentsSagas } from 'app/redux/incidents';
import { sagas as inventorySagas } from 'app/redux/inventory';
import { sagas as mapSagas } from 'app/redux/map';
import { sagas as networkSagas } from 'app/redux/network';
import { sagas as reportsSagas } from 'app/redux/reports';
import { sagas as rogueSagas } from 'app/redux/rogue';
import { sagas as uiSagas } from 'app/redux/ui';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    ...createErrorSagas(),
    ...appSagas,
    ...clientsSagas,
    ...filterSagas,
    ...hierarchySagas,
    ...inventorySagas,
    ...mapSagas,
    ...networkSagas,
    ...reportsSagas,
    ...uiSagas,
    ...alarmsSagas,
    ...rogueSagas,
    ...incidentsSagas
  ]);
}
