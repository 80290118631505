/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { HR, NoDataToShow } from 'app/components/elements';
import { FaIcon, LoadingIconPlaceholder } from 'app/components/icons';
import { StatefulTable, TableExport } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import { MuiTableOptions, MuiTableTheme } from 'app/constants';
import { createShowPanelSelector } from 'app/redux/app';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  fetchRogueDetails,
  rogueDetailsFetchStateSelector,
  rogueDetailsSelector
} from 'app/redux/rogue';
import { fetchStatePropTypes } from 'app/redux/utils';
import { scrollToBottom } from 'app/utils';
import classNames from 'classnames';
import {
  findIndex,
  isArray,
  isEmpty,
  isEqual,
  merge,
  some,
  toString
} from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  createRogueDetailsExportDataDefinition,
  getRogueDetailsTableColumns
} from '../utils';
import RogueTimelineTable from './rogue-timeline-table';

export class RogueDetailsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { searchValue: '', selectedRogueMAC: '' };
  }

  componentDidUpdate = prevProps => {
    const propertyChanged = !isEqual(
      prevProps.selectedPath.id,
      this.props.selectedPath.id
    );
    const dateRangeChanged = !isEqual(
      prevProps.dateRangeFilterLabel,
      this.props.dateRangeFilterLabel
    );

    if (propertyChanged || dateRangeChanged) {
      this.props.fetchRogueDetails();
      this.setState({ selectedRogueMAC: '' });
    }
  };

  filterBySearch = data => {
    const { searchValue } = this.state;

    const searchData = searchValue
      .toLowerCase()
      .trim()
      .replace(/:/g, '');

    return searchValue !== ''
      ? data.filter(row =>
        some(row, value => {
          value = toString(value);
          const valueString = value.toLowerCase().replace(/:/g, '');
          return (
            valueString === searchData || valueString.includes(searchData)
          );
        })
      )
      : data;
  };

 
  handleTableRowClick = (row, columnIndex) => {
    const newSelectedRogueMAC = row[columnIndex];
    if (newSelectedRogueMAC === this.state.selectedRogueMAC) {
      this.setState({ selectedRogueMAC: '' });
    } else {
      this.setState({ selectedRogueMAC: newSelectedRogueMAC }, scrollToBottom);
    }
  };

  setRowProps = (row, columnIndex) => {
    const { selectedRogueMAC } = this.state;
    const newSelectedRogueMAC = row[columnIndex];
    return {
      className:
        selectedRogueMAC !== '' &&
        classNames(
          newSelectedRogueMAC === selectedRogueMAC
            ? 'row-selected'
            : 'row-unselected'
        )
    };
  };

  renderActions = () => {
    const {
      rogueDetails: { data = [] }
    } = this.props;
    const { searchValue } = this.state;

    return (
      <div className="d-flex justify-content-between m-2">
        <div className="d-flex justify-content-start align-items-center">
          {/* <h3>Rogue Summary</h3> */}
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <FaIcon icon="search" classes={['mr-2']} />
          <label className="sr-only">Search...</label>
          <input
            className="p-1"
            id="property-rogue-table-search"
            onChange={e => this.setState({ searchValue: e.target.value })}
            placeholder="Search..."
            style={{ width: 250 }}
            type="text"
            value={searchValue}
          />
          <TableExport
            hidePdfExport={true}
            exportName="Rogue Summary"
            exportDataDefinitions={createRogueDetailsExportDataDefinition(data)}
          />
        </div>
      </div>
    );
  };

  render = () => {
    const {
      fetchRogueDetails,
      rogueDetails: { data = [] },
      fetchState,
      selectedPath,
      showRogueTimelineTable
    } = this.props;
    const { selectedRogueMAC } = this.state;

    const { pending, complete, failed } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    const columns = getRogueDetailsTableColumns();
    const rogueMACColumnIndex = findIndex(columns, ['name', 'roguemac']);

    const tableOptions = merge(
      {},
      MuiTableOptions,
      showRogueTimelineTable
        ? {
          onRowClick: rowData =>
            this.handleTableRowClick(rowData, rogueMACColumnIndex),
          setRowProps: row => this.setRowProps(row, rogueMACColumnIndex)
        }
        : {}
    );

    const marginStyle = { marginTop: '-25px' };

    return (
      <FailedFetchStateHandler
        fetchState={fetchState}
        retry={fetchRogueDetails}
      >
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow || failed ? (
          <NoDataToShow
            message={
              failed
                ? 'There has been a problem fetching the rogue details'
                : 'No data available for the rogue summary'
            }
            style={{ background: 'none', position: 'relative' }}
          />
        ) : (
          <Fragment>
            {!pending && hasData && this.renderActions()}
            {showRogueTimelineTable && (
              <div className="hide-in-pdf ml-2 mb-2" style={marginStyle}>
                Click on any row to view the rogue events according to timeline
                for the selected rogue device.
              </div>
            )}
            <div
              className={pending || noDataToShow ? 'fetch-state-pending' : ''}
            >
              {complete && (
                <MuiThemeProvider theme={createMuiTheme(MuiTableTheme)}>
                  <StatefulTable
                    tableKey={`${selectedPath.id}-property-rogue`}
                    columns={columns}
                    data={this.filterBySearch(data)}
                    options={tableOptions}
                  />
                </MuiThemeProvider>
              )}
            </div>
            {showRogueTimelineTable && selectedRogueMAC !== '' && (
              <Fragment>
                <HR />
                <RogueTimelineTable rogueMAC={selectedRogueMAC} />
              </Fragment>
            )}
          </Fragment>
        )}
      </FailedFetchStateHandler>
    );
  };
}

RogueDetailsTable.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  rogueDetails: PropTypes.object,
  fetchState: fetchStatePropTypes,
  fetchRogueDetails: PropTypes.func,
  showRogueTimelineTable: PropTypes.bool
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  rogueDetailsSelector,
  rogueDetailsFetchStateSelector,
  createShowPanelSelector('PropertyRogueTimelineTable'),
  (
    dateRangeFilterLabel,
    selectedPath,
    rogueDetails,
    fetchState,
    showRogueTimelineTable
  ) => ({
    dateRangeFilterLabel,
    selectedPath,
    rogueDetails,
    fetchState,
    showRogueTimelineTable
  })
);

const mapDispatchToProps = { fetchRogueDetails };

export default connect(mapStateToProps, mapDispatchToProps)(RogueDetailsTable);
