/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { FailedFetchStateHandler } from 'app/components/utility';
import { commonPlotProps, styleLibrary } from 'app/constants';
import {
  createXAxisTicks,
  formatDataPoints
} from 'app/modules/properties/components/utils';
import {
  apStatusTrendFetchStateSelector,
  apStatusTrendSelector,
  fetchAPStatusTrend
} from 'app/redux/clients';
import {
  dateRangeFilterLabelSelector,
  dateRangeFilterSelector
} from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import { formatApiDateTime } from 'app/utils';
import { get, isArray, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';

export class APStatusTrend extends Component {
  constructor(props) {
    super(props);
    this.state = { hiddenPlotMap: {} };
  }

  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilterLabel,
      fetchAPStatusTrend
    } = this.props;
    const {
      selectedPath: prevPath,
      dateRangeFilterLabel: prevDateRange
    } = prevProps;

    const propertyChanged =
      isSpecificNodeType(selectedPath, 'zonename') &&
      !isEqual(prevPath.id, selectedPath.id);
    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilterLabel);

    if (propertyChanged || dateRangeChanged) {
      fetchAPStatusTrend();
    }
  };

  isActivePlot = plotKey =>
    this.state.hiddenPlotMap[plotKey] ? 'blank' : plotKey;

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  renderTrendChart = (data, info) => {
    const { hiddenPlotMap } = this.state;
    const { dateRangeFilter } = this.props;
    const timezone = get(info, 'timezone', 'UTC');
    const dataToRender = formatDataPoints(
      data,
      timezone,
      undefined,
      'YYYY-MM-DD HH:mm:ss'
    );
    const xAxisTicks = createXAxisTicks(
      data,
      dateRangeFilter,
      timezone,
      undefined,
      'YYYY-MM-DD HH:mm:ss'
    );

    return (
      <ChartFlexContainer width="99%" height={300}>
        <ComposedChart
          data={dataToRender}
          margin={{ ...chartMargins, left: 15, top: 15 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="label"
            label={{
              value: `Time (${timezone})`,
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            interval="preserveStartEnd"
            tick={tick}
            ticks={xAxisTicks}
            tickFormatter={t => formatApiDateTime(t, dateRangeFilter, timezone)}
            scale="time"
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            yAxisId="trend-yaxis"
            label={{
              value: 'Count',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            allowDecimals={false}
          />
          <Tooltip
            content={
              <MultiLineTooltip
                labelFormatter={label =>
                  formatApiDateTime(label, dateRangeFilter, timezone, true)
                }
                keys={['Connected APs', 'Disconnected APs']}
                units={['']}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
          />
          <Legend
            content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
            inactivePlots={hiddenPlotMap}
            verticalAlign="top"
          />
          <Area
            yAxisId="trend-yaxis"
            dataKey={this.isActivePlot('Connected APs')}
            name="Connected APs"
            stroke={styleLibrary.trendColors[0]}
            fill={styleLibrary.trendColors[0]}
            {...commonPlotProps}
          />
          <Area
            yAxisId="trend-yaxis"
            dataKey={this.isActivePlot('Disconnected APs')}
            name="Disconnected APs"
            stroke={styleLibrary.trendColors[4]}
            fill={styleLibrary.trendColors[4]}
            {...commonPlotProps}
          />
        </ComposedChart>
      </ChartFlexContainer>
    );
  };

  render = () => {
    const {
      fetchState,
      apStatusTrend: { data = [], info = {} },
      fetchAPStatusTrend
    } = this.props;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <Fragment>
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow ? (
          <NoDataToShow
            icon="error_outline"
            message={'No data available'}
            style={{
              background: 'none',
              position: 'relative'
            }}
          />
        ) : (
          <FailedFetchStateHandler
            fetchState={fetchState}
            retry={fetchAPStatusTrend}
          >
            <div
              className={pending || noDataToShow ? 'fetch-state-pending' : ''}
            >
              {complete && hasData && this.renderTrendChart(data, info)}
            </div>
          </FailedFetchStateHandler>
        )}
      </Fragment>
    );
  };
}

APStatusTrend.propTypes = {
  dateRangeFilter: PropTypes.object,
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  fetchAPStatusTrend: PropTypes.func,
  apStatusTrend: PropTypes.object,
  fetchState: fetchStatePropTypes
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  apStatusTrendFetchStateSelector,
  apStatusTrendSelector,
  (
    dateRangeFilter,
    dateRangeFilterLabel,
    selectedPath,
    fetchState,
    apStatusTrend
  ) => ({
    dateRangeFilter,
    dateRangeFilterLabel,
    selectedPath,
    fetchState,
    apStatusTrend
  })
);

const mapDispatchToProps = { fetchAPStatusTrend };

export default connect(mapStateToProps, mapDispatchToProps)(APStatusTrend);
