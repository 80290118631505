/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import styled from 'styled-components';

export { default as SessionsForClient } from './sessions-for-client';

export const HeaderColorBlock = styled.div.attrs({
  className: 'd-inline-block ml-3'
})`
  background-color: ${props => props.color};
  width: 14px;
  height: 14px;
`;

export const HeaderBlockLabel = styled.div.attrs({
  className: 'd-inline-block ml-1 mr-2'
})`
  font-weight: normal;
`;
