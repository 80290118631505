/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { NoDataToShow } from 'app/components/elements';
import { FaIcon, LoadingIconPlaceholder } from 'app/components/icons';
import { StatefulTable, TableExport } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import { MuiTableOptions, MuiTableTheme } from 'app/constants';
import { createShowPanelSelector } from 'app/redux/app';
import {
  fetchPropertyUsers,
  maxPropertyUserValuesSelector,
  propertyUsersFetchStateSelector,
  propertyUsersSelector
} from 'app/redux/clients';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import { scrollToBottom } from 'app/utils';
import classNames from 'classnames';
import { findIndex, isEmpty, isEqual, merge, some, toString } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  exportPropertyUsersTableColumns,
  getPropertyUsersTableColumns,
  sortPropertyUsers,
  SubChartsTitle
} from '../utils';
import UserBandwidthTrendChart from './user-bandwidth-trend-chart';
import UserDataUsageByClientTable from './user-data-usage-by-client-table';

export class PropertyUsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = { usersSearch: '', selectedUser: '' };
  }

  componentDidUpdate = prevProps => {
    const propertyChanged = !isEqual(
      prevProps.selectedPath.id,
      this.props.selectedPath.id
    );
    const dateRangeChanged = !isEqual(
      prevProps.dateRangeFilterLabel,
      this.props.dateRangeFilterLabel
    );

    if (propertyChanged || dateRangeChanged) {
      this.setState({ selectedUser: '' });
    }
  };

  filterBySearch = data =>
    this.state.usersSearch !== ''
      ? data.filter(row =>
        some(row, value =>
          toString(value)
            .toLowerCase()
            .includes(this.state.usersSearch.toLowerCase())
        )
      )
      : data;

  renderActions = (data, columns) => (
    <div className="d-flex justify-content-between m-2">
      <div className="d-flex justify-content-start align-items-center">
        Click on any row to view traffic details for the selected user.
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <FaIcon icon="search" classes={['mr-2']} />
        <label className="sr-only">Search...</label>
        <input
          className="p-1"
          id="property-clients-table-search"
          onChange={e => this.setState({ usersSearch: e.target.value })}
          placeholder="Search..."
          style={{ width: 250 }}
          type="text"
          value={this.state.usersSearch}
        />
        <TableExport
          hidePdfExport={false}
          exportName="Property User Details"
          exportDataDefinitions={exportPropertyUsersTableColumns(data, columns)}
        />
      </div>
    </div>
  );

  handleTableRowClick = (rowData, userColumnIndex) => {
    const newSelectedUser = rowData[userColumnIndex];
    if (newSelectedUser === this.state.selectedUser) {
      this.setState({ selectedUser: '' });
    } else {
      this.setState({ selectedUser: newSelectedUser }, scrollToBottom);
    }
  };

  setRowProps = (row, userColumnIndex) => {
    const { selectedUser } = this.state;
    const newSelectedUser = row[userColumnIndex];
    return {
      className:
        selectedUser !== '' &&
        classNames(
          newSelectedUser === selectedUser ? 'row-selected' : 'row-unselected'
        )
    };
  };

  render = () => {
    const {
      selectedPath,
      fetchPropertyUsers,
      users: { data = [], info = {} },
      fetchState,
      maxValues,
      showUserDataUsageByClientTable
    } = this.props;
    const { selectedUser } = this.state;

    const { pending, complete } = fetchState;
    const noDataToShow = !pending && complete && isEmpty(data);
    const columns = getPropertyUsersTableColumns(info, maxValues);
    const userColumnIndex = findIndex(columns, ['name', 'user']);

    const tableOptions = merge({}, MuiTableOptions, {
      customSort: (data, col, order) =>
        sortPropertyUsers(data, col, order, columns),
      onRowClick: rowData => this.handleTableRowClick(rowData, userColumnIndex),
      setRowProps: row => this.setRowProps(row, userColumnIndex)
    });

    return (
      <FailedFetchStateHandler
        fetchState={fetchState}
        retry={fetchPropertyUsers}
      >
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow ? (
          <NoDataToShow
            message="There are currently no users for this property"
            mode="grid"
            style={{
              background: 'none',
              position: 'relative'
            }}
          />
        ) : (
          <Fragment>
            {!pending && !isEmpty(data) && this.renderActions(data, columns)}
            <div
              className={pending || noDataToShow ? 'fetch-state-pending' : ''}
            >
              {complete && (
                <MuiThemeProvider theme={createMuiTheme(MuiTableTheme)}>
                  <StatefulTable
                    tableKey={`${selectedPath.id}-property-users`}
                    columns={columns}
                    data={this.filterBySearch(data)}
                    options={tableOptions}
                  />
                </MuiThemeProvider>
              )}
            </div>
            {selectedUser !== '' && (
              <Fragment>
                <SubChartsTitle>
                  Traffic for User&nbsp;<b>{selectedUser}</b>
                </SubChartsTitle>
                <div className="row no-gutters mt-2">
                  <div className="col">
                    <UserBandwidthTrendChart userName={selectedUser} />
                  </div>
                  {showUserDataUsageByClientTable && (
                    <div className="col">
                      <UserDataUsageByClientTable userName={selectedUser} />
                    </div>
                  )}
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </FailedFetchStateHandler>
    );
  };
}

PropertyUsersTable.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  fetchPropertyUsers: PropTypes.func,
  users: PropTypes.object,
  fetchState: fetchStatePropTypes,
  maxValues: PropTypes.object,
  showUserDataUsageByClientTable: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('PropertyUserDataUsageByClientTable'),
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  propertyUsersSelector,
  propertyUsersFetchStateSelector,
  maxPropertyUserValuesSelector,
  (
    showUserDataUsageByClientTable,
    dateRangeFilterLabel,
    selectedPath,
    users,
    fetchState,
    maxValues
  ) => ({
    showUserDataUsageByClientTable,
    dateRangeFilterLabel,
    selectedPath,
    users,
    fetchState,
    maxValues
  })
);

const mapDispatchToProps = { fetchPropertyUsers };

export default connect(mapStateToProps, mapDispatchToProps)(PropertyUsersTable);
