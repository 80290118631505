/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import html2canvas from 'html2canvas';
import { merge } from 'lodash';
import moment from 'moment';
import { all, call, getContext, takeEvery } from 'redux-saga/effects';

import { pdfPageSettings } from 'app/constants';

import { styleLibrary } from 'app/constants';

import { toFormattedDateTime } from 'app/utils';

import ImgLogo from 'resources/images/commscope-logo.png';

import { apiDateFormat } from 'app/constants';

const defaultOptions = {
  filename: 'export.pdf',
  ...pdfPageSettings
};

const headerAndFooterDepth = 12;

const addFooter = (pdfDoc, pageNum, dateString) => {
  const tc = pdfDoc.getTextColor();
  const fs = pdfDoc.getFontSize();
  pdfDoc.setTextColor(styleLibrary.brand.highlight);
  pdfDoc.setFontSize(10);
  pdfDoc.setFillColor(styleLibrary.darkBg);
  pdfDoc.rect(
    0,
    pdfDoc.ph() - headerAndFooterDepth,
    pdfDoc.pw(),
    pdfDoc.ph(),
    'F'
  );
  pdfDoc.setDrawColor(styleLibrary.brand.highlight);
  pdfDoc.line(
    0,
    pdfDoc.ph() - headerAndFooterDepth,
    pdfDoc.pw(),
    pdfDoc.ph() - headerAndFooterDepth
  );
  pdfDoc.text(10, pdfDoc.ph() - 5, dateString);
  pdfDoc.text(pdfDoc.pw() - 15, pdfDoc.ph() - 5, 'Page ' + pageNum);
  pdfDoc.setTextColor(tc);
  pdfDoc.setFontSize(fs);
};

const addHeader = (pdfDoc, title) => {
  const tc = pdfDoc.getTextColor();
  pdfDoc.setTextColor(styleLibrary.brand.highlight);
  pdfDoc.setFillColor(styleLibrary.darkBg);
  pdfDoc.rect(0, 0, pdfDoc.pw(), headerAndFooterDepth, 'F');
  pdfDoc.addImage(ImgLogo, 'png', pdfDoc.pw() - 40, 0, 39, 13);
  pdfDoc.setDrawColor(styleLibrary.brand.highlight);
  pdfDoc.line(0, headerAndFooterDepth, pdfDoc.pw(), headerAndFooterDepth);
  pdfDoc.text(10, 8, title);
  pdfDoc.setTextColor(tc);
};

const addTitlePage = (pdfDoc, { dateString, title, userName }) => {
  const tc = pdfDoc.getTextColor();
  const fs = pdfDoc.getFontSize();
  pdfDoc.setTextColor(styleLibrary.brand.highlight);
  pdfDoc.setFillColor(styleLibrary.darkBg);
  pdfDoc.rect(0, 0, pdfDoc.pw(), pdfDoc.ph(), 'F');

  pdfDoc.setFillColor(styleLibrary.brand.feature);
  pdfDoc.rect(pdfDoc.pw() - 15, 0, pdfDoc.pw(), pdfDoc.ph(), 'F');

  pdfDoc.setFillColor(styleLibrary.brand.highlight);
  pdfDoc.rect(pdfDoc.pw() - 10, 0, pdfDoc.pw(), pdfDoc.ph(), 'F');

  pdfDoc.setFontSize(30);
  pdfDoc.text(20, pdfDoc.ph() / 3, title);
  pdfDoc.setFontSize(fs);
  pdfDoc.text(
    20,
    pdfDoc.ph() - 20,
    'Produced on ' + dateString + ' by ' + userName
  );
  pdfDoc.addImage(ImgLogo, 'png', pdfDoc.pw() - 100, 10, 78, 26);
  pdfDoc.addPage();
  pdfDoc.setTextColor(tc);
};

export function* pdfFromDataDefinitionSaga({
  payload: {
    exportDataDefinitions,
    exportFilename,
    title,
    userName,
    exportPdfStyleDefinitions = {},
    exportTableStyleDefinitions = {}
  }
}) {
  //Create column definitions to get the fields by index.
  //Filter out columns that don't have a 'label' field - these are not to be displayed.

  const PdfGenerator = yield getContext('pdfGenerator');

  const {
    orientation = 'l',
    unit = 'mm',
    format = 'letter',
    otherPdfOptions = {}
  } = exportPdfStyleDefinitions;

  const {
    theme = 'striped',
    styles = {},
    headStyles = {},
    bodyStyles = {},
    footStyles = {},
    alternateRowStyles = {},
    columnStyles = {},
    otherTableOptions = {}
  } = exportTableStyleDefinitions;

  const tableStylingOptions = {
    theme,
    styles: { cellPadding: 0.5, fontSize: 9, ...styles },
    headStyles: { ...headStyles },
    bodyStyles: { ...bodyStyles },
    footStyles: { ...footStyles },
    alternateRowStyles: { ...alternateRowStyles },
    columnStyles: { 0: { cellWidth: 20 }, ...columnStyles },
    ...otherTableOptions
  };

  const dateString = toFormattedDateTime(moment().format(apiDateFormat));

  //Now we have the data as csv, convert to PDF
  const pdfDoc = new PdfGenerator({
    orientation,
    unit,
    format,
    ...otherPdfOptions
  });

  addTitlePage(pdfDoc, { dateString, title, userName });
  let pageNum = 1;

  exportDataDefinitions.forEach((exportDefinition, exportDefinitionIndex) => {
    const outputColumns = exportDefinition.columns
      .map((columnDef, columnIndex) => {
        return {
          label: columnDef.label,
          columnIndex: columnIndex
        };
      })
      .filter(columnDef => columnDef.label);

    const outputData = [];
    exportDefinition.data.forEach(dataRow => {
      const outputRow = [];
      outputColumns.forEach(outputColumn => {
        const dataItem = dataRow[outputColumn.columnIndex];
        if (dataItem && Array.isArray(dataItem)) {
          const transformedDataItem = dataItem.join('\n');
          outputRow.push(transformedDataItem);
        } else {
          outputRow.push(dataItem);
        }
      });
      outputData.push(outputRow);
    });

    const outputHeader = [];
    outputColumns.forEach(outputColumn => {
      outputHeader.push(outputColumn.label);
    });

    pdfDoc.autoTable({
      head: [outputHeader],
      body: outputData,
      didDrawPage: function() {
        addHeader(pdfDoc, exportDefinition.name);
        addFooter(pdfDoc, pageNum++, dateString);
      },
      ...tableStylingOptions
    });

    if (exportDefinitionIndex < exportDataDefinitions.length - 1) {
      pdfDoc.addPage();
    }
  });
  yield call(pdfDoc.save, exportFilename);
}

export function* pdfFromHtmlSaga({ payload }) {
  const { selector, options, userName, title } = payload,
        pdfOptions = merge(defaultOptions, options);

  const {
    canvas: canvasOpts = {},
    filename,
    height,
    spacing,
    width
  } = pdfOptions;

  const dateString = toFormattedDateTime(moment().format(apiDateFormat));

  const PdfGenerator = yield getContext('pdfGenerator'),
        document = yield getContext('document'),
        elements = [...document.querySelectorAll(selector)];

  const rowImages = yield all(
    elements.map(el =>
      html2canvas(el, {
        // This `onclone` function doctors the elements of the screen to make them more appropriate to the generated
        // PDF document. `html2canvas` works by cloning the DOM elements and making them print friendly. We will
        // handle any passed in style overrides and also do some default handling for `show-in-pdf` and `hide-in-pdf` blocks
        onclone: document => {
          // The following line is to address a Safari html2canvas rendering bug.
          // Inserting an empty node forces the DOM to redraw.
          // No need to remove the empty node since we are working on a clone of the document.
          document.body.appendChild(document.createTextNode(''));

          if (canvasOpts.styles) {
            canvasOpts.styles.forEach(style => {
              const { blockClass, styles } = style;

              for (let block of document.getElementsByClassName(blockClass)) {
                styles.forEach(s => {
                  const { attribute, value } = s;

                  block.style[attribute] = value;
                });
              }
            });
          }

          // Default behaviour to show/hide all appropriately classed elements currently on screen
          for (let toShow of document.getElementsByClassName('show-in-pdf')) {
            toShow.style.display = 'block';
          }
          for (let toHide of document.getElementsByClassName('hide-in-pdf')) {
            toHide.style.display = 'none';
          }
        }
      })
    )
  );

  const pdf = new PdfGenerator('p', 'mm', 'letter'),
        imgWidth = width - spacing * 3;
  let position = headerAndFooterDepth + spacing;

  let pageNum = 1;
  addTitlePage(pdf, { dateString, title, userName });
  addHeader(pdf, '');
  addFooter(pdf, pageNum++, dateString);

  rowImages.forEach((canvas, idx) => {
    const img = canvas.toDataURL('image/jpeg'),
          imgHeight = (canvas.height * imgWidth) / canvas.width,
          requiredHeight = position + imgHeight + spacing;

    // If this image is going to overlap the footer or extend beyond the bottom of the page,
    // add a new page and place the image at the top. Reset the position markers.
    if (requiredHeight > height - headerAndFooterDepth * 2) {
      pdf.addPage();
      addHeader(pdf, '');
      addFooter(pdf, pageNum++, dateString);
      position = headerAndFooterDepth + spacing;
    }

    pdf.addImage(
      img,
      'JPEG',
      spacing,
      position,
      imgWidth,
      imgHeight,
      `img-${idx}`,
      'FAST'
    );
    position += imgHeight + spacing;
  });

  yield call(pdf.save, filename);
}

export default function createPdfSagas(types) {
  return [
    takeEvery(types.generatePdfFromHtml, pdfFromHtmlSaga),
    takeEvery(types.generatePdfFromDataDefinition, pdfFromDataDefinitionSaga)
  ];
}
