/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createGlobalStyle } from 'styled-components';
import { styleLibrary } from 'app/constants';

import caretDown from 'resources/images/caret-down-solid.svg';

const GlobalStyles = createGlobalStyle`
  body, h1, h2, h3, h4, h5 {
    font-family: "Open Sans", sans-serif;
  }

  * {
    border-radius: 0 !important;
  }

  /* We are over-riding the default styling of selects because we can't enforce border-radius: 0 otherwise */
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    /* The following is the base 64 encoding of a downward arrow to use a drop-down indicator in our custom SELECT tags */
    background-image: url("${caretDown}");
    background-repeat: no-repeat;
    background-position: top 50% right 10px;
    background-size: 10px auto;
    padding-right: 25px !important;
  }

  label {
    font-weight: bold;
  }
  
  .link {
    color: ${styleLibrary.brand.link};
    font-weight: bold;
    cursor: pointer;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  
  .link.disabled {
    color: ${styleLibrary.disabledText};
    cursor: not-allowed;
    text-decoration: none !important;
  }

  .main-body {
    background: ${styleLibrary.gradientBg};
    color: ${styleLibrary.lightText};
  }

  .main-body.no-background {
    background: ${styleLibrary.lightBg};
    color: ${styleLibrary.darkText};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .btn {
    border: 0;
    font-weight: bold;
  }

  .btn-primary {
    background-color: ${styleLibrary.primaryButtonBg} !important;
  }

  .btn-light {
    background: #f2f2f2;
    background: -moz-linear-gradient(top, #f2f2f2 0%, #d4d4d4 100%);
    background: -webkit-linear-gradient(top, #f2f2f2 0%,#d4d4d4 100%);
    background: linear-gradient(to bottom, #f2f2f2 0%,#d4d4d4 100%);
    border: 1px solid #979797;
  }

  .form-control {
    font-size: ${styleLibrary.fontSizes.body}px;
    background-clip: unset;
  }

  .block-container {
    border: 1px solid ${styleLibrary.containerBorderColor};
  }

  .dropdown-menu {
    padding: 0;
    min-width: 7rem;
  }

  .dropdown-item {
    padding: .25rem .75rem;
    font-size: ${styleLibrary.fontSizes.dropDownMenu}px;
    cursor: pointer;
  }

  .dropdown-item:hover,
  .daterangepicker .ranges li:hover {
    background-color: ${styleLibrary.brand.primary};
    color: ${styleLibrary.lightText};
  }

  /* Title button styles (for filters, date ranges, etc.) */
  .title-btn {
    background-color: ${styleLibrary.brand.primary};
    font-size: ${styleLibrary.fontSizes.titleButton}px;
    font-weight: normal;
    cursor: pointer;
  }

  .show .title-btn {
    background-color: ${styleLibrary.filterOpenBg};
    color: ${styleLibrary.darkText}
  }

  .title-btn span {
    margin-right: 20px;
  }

  .title-btn span:last-of-type {
    margin-right: 0;
  }

  /* fetchState specific styles */
  .fetch-state-pending {
    opacity: 0;
    min-height: 250px;
  }

  .tippy-popper .tippy-tooltip {
    box-shadow: none !important;
    border: 1px solid ${styleLibrary.darkText};
    padding: 15px 10px;
  }
  
  .recharts-tooltip-wrapper {
    z-index: 1;
  }

  .z-index-level-6 {
    z-index: 2000;
  }

  .z-index-level-5 {
    z-index: 1800;
  }

  .z-index-level-4 {
    z-index: 1600;
  }

  .z-index-level-3 {
    z-index: 1400;
  }

  .z-index-level-2 {
    z-index: 1200;
  }

  .z-index-level-1 {
    z-index: 1000;
  }
`;

export default GlobalStyles;
