/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

import { Logo } from 'app/components/elements';
import { networkIntelligence } from 'app/constants/';
import { styleLibrary } from 'app/constants';

export const HeaderText = styled.div.attrs({
  id: 'login-header-text',
  className: 'col text-right'
})`
  color: ${styleLibrary.lightText};
  font-size: ${styleLibrary.fontSizes.title};
  font-weight: bold;

  @media (max-width: 767px) {
    font-size: ${styleLibrary.fontSizes.mobileTitle};
  }
`;

class LoginHeader extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          this.props.className,
          'row',
          'align-items-center'
        )}
      >
        <Logo style={{ height: 26, margin: 15 }} />
        <HeaderText>{networkIntelligence}</HeaderText>
      </div>
    );
  }
}

LoginHeader.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(LoginHeader)`
  background: ${styleLibrary.gradientBg};
`;
