/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { LoadingIconPlaceholder } from 'app/components/icons';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { ModalBox, ModalWrapper } from './components';

class Loading extends Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentDidMount = () =>
    document.body.addEventListener('click', this.handleNonModalClick);

  componentDidUpdate = prevProps => {
    const { isLoading: prevIsLoading } = prevProps;
    const { isLoading, isError, closeModal } = this.props;
    if (!isError && !isLoading && prevIsLoading) {
      closeModal();
    }
  };

  componentWillUnmount = () =>
    document.body.removeEventListener('click', this.handleNonModalClick);

  handleNonModalClick = e => {
    if (!this.modalRef.current.contains(e.target)) {
      this.props.closeModal();
    }
  };

  render = () => {
    const {
      closeModal,
      isLoading,
      isError,
      loadingMessage,
      errorMessage
    } = this.props;

    return (
      <ModalWrapper>
        <div className="d-flex justify-content-center align-items-center flex-column h-100">
          <ModalBox ref={this.modalRef}>
            {isLoading && (
              <Fragment>
                <div className="mb-3">{loadingMessage}</div>
                <LoadingIconPlaceholder height={0} position="relative" />
              </Fragment>
            )}
            {!isLoading && isError && (
              <Fragment>
                <div>{errorMessage}</div>
                <button
                  className="btn btn-primary mt-3"
                  onClick={() => closeModal()}
                >
                  Close
                </button>
              </Fragment>
            )}
          </ModalBox>
        </div>
      </ModalWrapper>
    );
  };
}

Loading.defaultProps = { loadingMessage: 'Loading...', errorMessage: 'ERROR!' };
Loading.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  loadingMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default Loading;
