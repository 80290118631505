/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';

import { TextLink } from 'app/components/elements';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';

const BreadcrumbEntry = styled.div.attrs({
  className: 'crumb'
})`
  white-space: nowrap;
  margin-left: ${props => props.index * 5 + 5}px;
`;

const mapStateToProps = createSelector(
  hierarchySelector,
  selectedPathSelector,
  (hierarchy, selected) => ({ hierarchy, selected })
);

class Breadcrumbs extends Component {
  handleBreadcrumbClick(crumb, event) {
    event.preventDefault();
    this.props.onClick(crumb.id);
  }

  render() {
    const { rootNode, selected = {} } = this.props;
    const crumbSelected = rootNode.name === selected.name;

    return (
      rootNode.type !== 'wlan' && (
        <BreadcrumbEntry key={rootNode.name}>
          <TextLink
            className={classNames({ selected: crumbSelected })}
            onClick={this.handleBreadcrumbClick.bind(this, rootNode)}
            title={rootNode.name}
            to={`#${rootNode.name}`}
          >
            {rootNode.name}
          </TextLink>
        </BreadcrumbEntry>
      )
    );
  }
}

Breadcrumbs.propTypes = {
  hierarchy: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  rootNode: PropTypes.object.isRequired,
  selected: PropTypes.object
};

export default connect(mapStateToProps)(Breadcrumbs);
