/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';

import { TooltipWrapper } from './';

const getPercentage = (value, maxValue) =>
  ((value / maxValue) * 100).toFixed(0);

export const BasicTooltip = ({ payload, label, maxValue }) => {
  return payload && payload.length === 1 ? (
    <TooltipWrapper flex>
      <div
        className="d-inline-block text-left mr-3"
        data-test-label="basic-tooltip-label"
      >
        {label || payload[0].name}
      </div>
      <div
        className="d-inline-block text-right ml-3 font-weight-bold"
        data-test-label="basic-tooltip-value"
      >
        {payload[0].value}
        {typeof maxValue === 'number' && (
          <span>&nbsp;({getPercentage(payload[0].value, maxValue)}%)</span>
        )}
      </div>
    </TooltipWrapper>
  ) : (
    <div data-test-label="basic-tooltip-empty">&nbsp;</div>
  );
};

BasicTooltip.propTypes = {
  label: PropTypes.string,
  maxValue: PropTypes.number,
  payload: PropTypes.array
};

export default BasicTooltip;
