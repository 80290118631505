/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { defaultStringFilters } from 'app/components/filters/constants';
import {
  hierarchySelector,
  selectedNodeSelector,
  selectedPathSelector,
  types as sidebarTypes
} from 'app/redux/hierarchy';
import { getVerticalParent } from 'app/utils';
import { getAllOfTypeFromHierarchy } from 'app/utils/misc';
import { put, select, takeEvery } from 'redux-saga/effects';
import {
  updateSSIDWLANList,
  updateVertSSIDFilter,
  vertSSIDFilterSelector
} from './';

export function* deriveVertSSIDFilterFromSelectedNode() {
  const currentFilter = yield select(vertSSIDFilterSelector);
  const hierarchy = yield select(hierarchySelector);
  const nodeId = yield select(selectedNodeSelector);
  const node = hierarchy[nodeId];
  const { name, type } = node;
  const { rootNodeId } = hierarchy;
  if (nodeId === rootNodeId) {
    yield put(updateVertSSIDFilter(defaultStringFilters));
    return;
  }
  if (type === 'vertical') {
    yield put(
      updateVertSSIDFilter({
        ...defaultStringFilters,
        vertical: name,
        verticalLocked: true
      })
    );
    return;
  }

  const vertical = getVerticalParent(hierarchy, node);
  const { name: verticalName = 'all' } = vertical;
  const isSelectedNodeWlan = type === 'wlan';
  yield put(
    updateVertSSIDFilter({
      ...currentFilter,
      vertical: verticalName,
      verticalLocked: verticalName !== 'all',
      wlanName: isSelectedNodeWlan ? name : 'all',
      wlanLocked: isSelectedNodeWlan
    })
  );
  return;
}

function* deriveSSIDWLANListFromSelectedNode() {
  const node = yield select(selectedPathSelector);
  let ssidWLANList = yield [];

  if (node['type'] === 'zonename') {
    const hierarchy = yield select(hierarchySelector);
    const nodeList = yield getAllOfTypeFromHierarchy(
      'wlan',
      hierarchy,
      node,
      false
    );
    ssidWLANList = yield nodeList.map(i => ({
      wlan: i['value'],
      ssid: i['text']
    }));
  }

  yield put(updateSSIDWLANList(ssidWLANList));
  return;
}

export default function createFilterSagas() {
  return [
    takeEvery(
      sidebarTypes.hierarchyNodeSelected,
      deriveVertSSIDFilterFromSelectedNode
    ),
    takeEvery(
      sidebarTypes.hierarchyNodeSelected,
      deriveSSIDWLANListFromSelectedNode
    )
  ];
}
