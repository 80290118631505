/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { call, put, takeEvery, select } from 'redux-saga/effects';

import {
  pendingFetchState,
  completeFetchState,
  failedFetchState,
  timeoutFetchState,
  timeoutNotifyFetchState,
  createErrorAction
} from 'app/redux/utils';
import { ajaxPost } from 'app/redux/util-sagas';
import { isAjaxTimeoutError } from 'app/custom-errors';
import { filterParamsSelector } from 'app/redux/filters';
import { createApiBodySelector } from 'app/redux/hierarchy';
import {
  fetchClientSessionsComplete,
  fetchClientSessionsError,
  updateClientSessionsFetchState
} from './';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';

const getClientSessionsUrl = '/api/network/getSPClientBandwidthDetails';

export function* fetchClientSessionsSaga({ payload }) {
  const { clientMAC, zonename } = payload;
  yield put(updateClientSessionsFetchState(pendingFetchState));

  yield call(waitForHierarchySaga);

  const filterParams = yield select(filterParamsSelector);
  const apiBody = yield select(createApiBodySelector(getClientSessionsUrl));

  const onTimeout = () =>
    put(updateClientSessionsFetchState(timeoutNotifyFetchState));

  try {
    const { data } = yield ajaxPost(getClientSessionsUrl, apiBody, {
      params: {
        ...filterParams,
        clientMAC,
        zonename
      },
      onTimeout
    });
    yield put(fetchClientSessionsComplete(data));
    yield put(updateClientSessionsFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchClientSessionsError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(fetchClientSessionsError());
    yield put(updateClientSessionsFetchState(updatedFetchState));
  }
}

export default function createFetchClientSessionsSagas(types) {
  return [takeEvery(types.fetchClientSessions, fetchClientSessionsSaga)];
}
