/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { get, has, startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';
import { fetchMapMarkerDetails } from 'app/redux/map';

import { HoverBox, InfoBoxContainer, InfoWindow } from './';
import { MapMarkerSvg } from './marker-svg';

// By default, the map markers are rendering anchored to the top-left corner of the image relative to the lat/lng of
// where the marker is to be placed, rather than the bottom-center pin. This styling is to offset the marker
// so the point of the pin is visually in the right location on the map.
const MarkerContainer = styled.div`
  margin-left: -15px;
  margin-top: -50px;
`;

class MapMarker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      selected: false,
      selectedMultiple: -1
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // This function is all about deciding whether or not to call the `getServiceProviderDetails` API
    // for the currently selected provider. This is dictated by whether the marker is currently set
    // as the selected marker and whether or not this single marker represents multiple providers.
    //
    // If this is a single provider marker that has been changed to selected, go fetch the data.
    // If this is a multi-provider marker that has had the selected provider changed, go fetch the data.
    // Otherwise don't go fetch anything.
    const { providers } = this.props;
    const { selected, selectedMultiple } = this.state;
    let provider;

    if (selected) {
      if (selected !== prevState.selected && providers.length === 1) {
        provider = providers[0];
      } else if (
        selectedMultiple >= 0 &&
        selectedMultiple !== prevState.selectedMultiple
      ) {
        provider = providers[selectedMultiple];
      }
    }

    if (provider && has(provider, 'type') && provider.type !== 'APMAC') {
      this.props.fetchMapMarkerDetails({ name: provider.name });
    }
  }

  handleClick = () => {
    // If we have a multiple options markers with one of the options active, reset it
    // and redisplay the list of all sub-items.
    if (this.state.selectedMultiple >= 0) {
      this.setState({
        selectedMultiple: -1
      });
    } else {
      if (!this.state.selected) {
        this.props.onMarkerClick(this);
      }

      this.setState(prevState => ({
        hover: prevState.selected,
        selected: !prevState.selected,
        selectedMultiple: -1
      }));
    }
  };

  handleMouseOver = () => {
    this.setState(prevState => ({
      hover: !prevState.selected
    }));
  };

  handleMouseOut = () => {
    this.setState({ hover: false });
  };

  handleSelectMultiple = idx => {
    this.setState({ selectedMultiple: idx });
  };

  hide = () => {
    this.setState({
      selected: false,
      selectedMultiple: -1
    });
  };

  getPopupOffset() {
    const { selectedMultiple } = this.state;
    const { providers } = this.props;

    // Calculate the offset for the info window pop-up (depending on whether there are multiple items and if one is selected)
    let infoOffset = 229;
    const checkForClients =
      providers.length === 1 ||
      (selectedMultiple !== -1 && providers.length > 1);

    if (selectedMultiple === -1 && providers.length > 1) {
      // Yes, this is a trial and error value, but it seems to get us the offset we want!
      infoOffset = 75 + providers.length * 33;
    }

    // If we're dealing with multiple APs, our window is going to be higher so we need another row-height of offset
    // Else if there is no client info in the provider, increase the offset to account for it
    if (providers[0].connectionStatus) {
      if (
        providers.length === 1 ||
        (providers.length > 1 && selectedMultiple !== -1)
      ) {
        infoOffset += 109;
      }
    } else if (
      checkForClients &&
      get(providers, '[0].activeClients') === undefined
    ) {
      infoOffset += 76;
    }

    return infoOffset;
  }

  render() {
    const { hover, selected, selectedMultiple } = this.state;
    const { providers, text } = this.props;
    const displayType = has(providers, '[0].type')
      ? startCase(get(providers, '[0].type', ''))
      : 'APs';

    return (
      <span className="map-marker">
        <MarkerContainer
          onClick={this.handleClick}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        >
          <MapMarkerSvg providers={providers} />
        </MarkerContainer>
        {hover && (
          <InfoBoxContainer offset={95} zIndex={100}>
            <HoverBox fontSize={styleLibrary.fontSizes.titleButton}>
              {displayType}: <b>{text}</b>
            </HoverBox>
          </InfoBoxContainer>
        )}
        {selected && (
          <InfoWindow
            offset={this.getPopupOffset()}
            onCloseClick={this.hide}
            onSelectMultiple={this.handleSelectMultiple}
            providers={providers}
            selectedMultiple={selectedMultiple}
          />
        )}
      </span>
    );
  }
}

MapMarker.propTypes = {
  fetchMapMarkerDetails: PropTypes.func.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
  providers: PropTypes.array.isRequired,
  text: PropTypes.string.isRequired
};

export default connect(null, { fetchMapMarkerDetails })(MapMarker);
