/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { FaIcon } from 'app/components/icons';
import { styleLibrary } from 'app/constants';

const LoginErrorText = styled.div.attrs({
  className: 'text-center px-3'
})``;

const LoginError = props => {
  const cssClasses = [
    props.className,
    'row',
    'justify-content-center',
    'align-items-center'
  ];

  return (
    <Fragment>
      {props.hasError && (
        <div id="login-error" className={classNames(cssClasses)}>
          <FaIcon
            icon="info-circle"
            style={{ fontSize: styleLibrary.fontSizes.navIcon }}
          />
          <LoginErrorText>
            Sorry, we don’t recognize that username and password.
            <br />
            Please try again.
          </LoginErrorText>
        </div>
      )}
    </Fragment>
  );
};

LoginError.propTypes = {
  className: PropTypes.string.isRequired,
  hasError: PropTypes.bool
};

export default styled(LoginError)`
  color: #d0021b;
  margin: 20px 0 30px;
`;
