/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { filterParamsSelector } from 'app/redux/filters';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';
import { ajaxGet } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { getPropertyParent } from 'app/utils';
import { get, merge } from 'lodash';
import { put, select, takeLatest, call } from 'redux-saga/effects';
import {
  fetchIncidentActivityComplete,
  fetchIncidentActivityError,
  fetchSfdcActivityComplete,
  fetchSfdcActivityError,
  fetchSfdcCommentsComplete,
  fetchSfdcCommentsError,
  updateIncidentActivityFetchState,
  updateSfdcActivityFetchState,
  updateSfdcCommentsFetchState
} from './';


export function* fetchIncidentActivitySaga({ payload }) {
  yield put(updateIncidentActivityFetchState(pendingFetchState));

  yield call(waitForHierarchySaga);
  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);

  const params = yield merge({}, filters, payload, {
    incidentId: get(payload, 'incidentId', ''),
    zone: get(selectedProperty, 'name', 'all')
  });

  const url = '/api/fastapi/getIncidentActivity';
  const paramNames = ['incidentId', 'zone'];

  try {
    const onTimeout = () =>
      put(updateIncidentActivityFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchIncidentActivityComplete(data));
    yield put(updateIncidentActivityFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchIncidentActivityError, err));
    yield put(updateIncidentActivityFetchState(failedFetchState));
  }
}

export function* fetchSfdcActivitySaga({ payload }) {
  yield put(updateSfdcActivityFetchState(pendingFetchState));

  yield call(waitForHierarchySaga);
  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);

  const params = yield merge({}, filters, payload, {
    caseid: get(payload, 'caseid', ''),
    zone: get(selectedProperty, 'name', 'all')
  });

  const url = '/api/cdbapi/getCaseActivity';
  const paramNames = ['caseid', 'zone'];

  try {
    const onTimeout = () =>
      put(updateSfdcActivityFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchSfdcActivityComplete(data));
    yield put(updateSfdcActivityFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchSfdcActivityError, err));
    yield put(updateSfdcActivityFetchState(failedFetchState));
  }
}

export function* fetchSfdcCaseCommentsSaga({ payload }) {
  yield put(updateSfdcCommentsFetchState(pendingFetchState));

  yield call(waitForHierarchySaga);
  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);

  const params = yield merge({}, filters, payload, {
    caseid: get(payload, 'caseid', ''),
    zone: get(selectedProperty, 'name', 'all')
  });
  
  const url = '/api/cdbapi/getCaseComments';
  const paramNames = ['caseid', 'zone'];

  try {
    const onTimeout = () =>
      put(updateSfdcCommentsFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchSfdcCommentsComplete(data));
    yield put(updateSfdcCommentsFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchSfdcCommentsError, err));
    yield put(updateSfdcCommentsFetchState(failedFetchState));
  }
}

export default function createFetchIncidentActivitySagas(types) {
  return [
    takeLatest(types.fetchIncidentActivity, fetchIncidentActivitySaga),
    takeLatest(types.fetchSfdcActivity, fetchSfdcActivitySaga),
    takeLatest(types.fetchSfdcComments, fetchSfdcCaseCommentsSaga),
  ];
}
