/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Label = props => {
  const { fieldId, labelClasses, labelText } = props;

  return (
    <label htmlFor={fieldId} className={classNames(...labelClasses)}>
      {labelText}
    </label>
  );
};

Label.propTypes = {
  fieldId: PropTypes.string,
  labelClasses: PropTypes.array,
  labelText: PropTypes.string
};

export default Label;
