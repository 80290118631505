/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { castToNumber, zeroProtection } from 'app/utils';

const serviceProviderFields = ['bandwidthTXInMB', 'bandwidthRXInMB'];
// const sessionDetailFields = ['clientCount', 'sessionCount'];
const sessionDetailFields = ['sessionCount'];
const throughputFields = ['throughputRXInKbps', 'throughputTXInKbps'];

/**
 * This function analyses an API response and extracts a total of the requested fields. These dataset will be
 * used to populate the summary chart on the overview pane.
 *
 * @method getDataSetTotal
 * @param {Object} dataset A set of API results to be parsed
 * @param {Object} query The property to parse and the fields we want from it
 * @param {Boolean} [average] Denoting whether or not to average the total value for this query
 * @returns {Object} An object containing the requested fields with totals or averages as requested
 */
const getDataSetTotal = (dataset, query, average = false) => {
  const { fields, property } = query;
  let calculatedData = {};
  let dataArray = [];

  if (dataset[property]) {
    dataArray = dataset[property];

    if (!Array.isArray(dataArray)) {
      dataArray = [dataset[property]];
    }

    fields.forEach(field => {
      calculatedData[field] = dataArray.reduce((acc, cur) => {
        return acc + castToNumber(cur[field]);
      }, 0);

      if (average) {
        calculatedData[field] =
          calculatedData[field] / zeroProtection(dataArray.length);
      }
    });
  }

  return calculatedData;
};

/**
 * This transformer deals specifically with getting the average session duration, which is different from the other
 * data points because it requires a combination of summary API responses. We first calculate the total of all
 * sessions (based on the sessions/avg time for a given provider) and then divide that by the overall provider total.
 *
 * @param {Object} data The full set of summary data
 * @returns {Object} A calculated average duration in object form that can be consumed by the `createSummary` fn.
 */
const getAverageSessionDuration = data => {
  const { SPSessionDurationInfo: info = {} } = data;
  return { averageSessionDuration: info.averageSessionDurationInMin || 0 };
};

/*
 * These transformer functions are intended to take the raw data from the APIs
 * and parse them into an appropriate structure for consumption in the overview
 * charts. We have multiple data source for this section, so we combine and
 * transform as necessary to build the data in such a way as it is easy to pass
 * around the app.
 */
const createTransformer = (fn, ...transformerOptions) => {
  return dataSet => fn(dataSet, ...transformerOptions);
};

const getTraffic = createTransformer(getDataSetTotal, {
  property: 'SPUsage',
  fields: serviceProviderFields
});
const getClientsSessions = createTransformer(getDataSetTotal, {
  // property: 'clientSessionDetails',
  property: 'sessionDetails',
  fields: sessionDetailFields
});
const getThroughput = createTransformer(
  getDataSetTotal,
  { property: 'SPThroughput', fields: throughputFields },
  true
);
const getAverageDuration = createTransformer(getAverageSessionDuration);
// const getSessionRatio = createTransformer(data => {
//   const { clientSessionDetails = {} } = data;
//   const {
//     clientCount = 0,
//     sessionCount = 0,
//     sessionRatio = '0:0'
//   } = clientSessionDetails;
//   return { clientCount, sessionCount, sessionRatio };
// });

const transformers = [
  getTraffic,
  getThroughput,
  getClientsSessions,
  getAverageDuration
  // getSessionRatio
];

export const createSummary = results =>
  transformers.reduce(
    (transformedResult, fn) => ({ ...transformedResult, ...fn(results) }),
    {}
  );
