/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createSelector } from 'reselect';
import { dateRangeFilterSelector } from 'app/redux/filters';
import { fetchAbuseSessionDetails } from 'app/redux/reports';
import Filters from 'app/components/filters';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SearchTableDetails from './search-table-details';
import SessionTextInput from './session-text-input';

class GetSessionDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionMAC: '',
      publicIP: '',
      nasPort: '',
      isValidSessionMAC: true,
      isValidPublicIP: true,
      isValidNasPort: true,
      showTable: false
    };
  }

  validateInputs = (sessionMAC, publicIP, nasPort) => {
    let isValidSessionMAC = true;
    let isValidPublicIP = true;
    let isValidNasPort = true;

    if (sessionMAC === '' && publicIP === '' && nasPort === '') {
      this.setState({
        isValidSessionMAC: false,
        isValidPublicIP: true,
        isValidNasPort: true
      });
      return false;
    }

    const validateSessionMAC = mac => {
      if (mac === '') {
        return true;
      }
      const regex = /^([0-9a-fA-F]{2}[:-]){5}[0-9a-fA-F]{2}$/;
      return regex.test(mac);
    };

    const validatePublicIP = ip => {
      if (ip === '') {
        return true;
      }
      const regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^([0-9a-fA-F:]+:[0-9a-fA-F]*)$/;
      return regex.test(ip);
    };

    const validateNasPort = port => {
      if (port === '') {
        return true;
      }
      const regex = /^[0-9]+$/;
      if (regex.test(port)) {
        const portInt = parseInt(port, 10);
        if (portInt >= 1024 && portInt <= 1535) {
          return true;
        }
      }
      return false;
    };

    isValidSessionMAC = validateSessionMAC(sessionMAC);
    isValidPublicIP = validatePublicIP(publicIP);
    isValidNasPort = validateNasPort(nasPort);

    this.setState({ isValidSessionMAC, isValidPublicIP, isValidNasPort });

    return isValidSessionMAC && isValidPublicIP && isValidNasPort;
  };

  handleFieldChange = field => e => this.setState({ [field]: e.target.value });

  renderSubmitButton = () => {
    const { fetchAbuseSessionDetails } = this.props;
    const { sessionMAC, publicIP, nasPort } = this.state;
    const isButtonDisabled =
      sessionMAC === '' && publicIP === '' && nasPort === '';

    const handleOnSubmit = () => {
      const areInputsValid = this.validateInputs(sessionMAC, publicIP, nasPort);
      this.setState({ showTable: areInputsValid }, () => {
        if (areInputsValid) {
          fetchAbuseSessionDetails({
            sessionMAC,
            publicIP,
            nasPort
          });
        }
      });
    };

    return (
      <div className="form-group align-items-center row">
        <div className="col-sm-3" />
        <div className="col-sm-4 d-flex justify-content-center">
          <button
            type="button"
            className="btn btn-primary w-auto"
            onClick={handleOnSubmit}
            disabled={isButtonDisabled}
          >
            Get Session Details
          </button>
        </div>
        <div className="col-sm" />
      </div>
    );
  };

  render = () => {
    const {
      sessionMAC,
      publicIP,
      nasPort,
      isValidSessionMAC,
      isValidPublicIP,
      isValidNasPort
    } = this.state;

    return (
      <Fragment>
        <div className="d-flex justify-content-end align-items-center mx-2">
          <label className="col-form-label-sm mb-0 mr-2">Date Range:</label>
          <Filters
            showVerticalFilter={false}
            showDateRangeFilter={true}
            applyButtonStyle={true}
          />
        </div>
        <div className="my-3">
          <form>
            <SessionTextInput
              id="client-mac"
              value={sessionMAC}
              onChange={this.handleFieldChange('sessionMAC')}
              placeholder="Client MAC"
              label="Client MAC"
              helperText="[ MAC Address could be separated by colon (:) or hyphen (-) ]"
              isValidInput={isValidSessionMAC}
            />
            <SessionTextInput
              id="public-ip"
              value={publicIP}
              onChange={this.handleFieldChange('publicIP')}
              placeholder="Public IP Address"
              label="Public IP"
              helperText="[ Enter a valid IPv4 or IPv6 address ]"
              isValidInput={isValidPublicIP}
            />
            <SessionTextInput
              id="nas-port-num"
              value={nasPort}
              onChange={this.handleFieldChange('nasPort')}
              placeholder="NAS Port Number"
              label="NAS Port Number"
              helperText="[ Port number could be in range [1024-1535] ]"
              isValidInput={isValidNasPort}
            />
            {this.renderSubmitButton()}
          </form>
          {this.state.showTable && (
            <Fragment>
              <hr />
              <SearchTableDetails
                sessionMAC={sessionMAC}
                publicIP={publicIP}
                nasPort={nasPort}
              />
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  };
}

GetSessionDetails.propTypes = {
  dateRangeFilter: PropTypes.object,
  fetchAbuseSessionDetails: PropTypes.func
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  dateRangeFilter => ({
    dateRangeFilter
  })
);

export default connect(mapStateToProps, { fetchAbuseSessionDetails })(
  GetSessionDetails
);
