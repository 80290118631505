/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  call,
  cancelled,
  getContext,
  put,
  select,
  take,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';

import { configureAjaxAuth, prefixNavigationPath } from 'app/utils';
import {
  ajaxPost,
  ajaxGet,
  getItemFromStorage,
  setItemToStorage,
  interval
} from 'app/redux/util-sagas';

import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  is401ErrorAction,
  pendingFetchState
} from 'app/redux/utils';

import {
  appReset,
  types,
  updateUserLoginFetchState,
  userFoundInStorage,
  userLoginComplete,
  userLoginError,
  userSelector,
  userSignOut
} from '../';
import { CONFIG_BUCKETFILTER_KEY } from '../constants';

import { getConfigSaga } from './config-sagas';
import { getTokenFromAuth, isTokenValid } from './jwt-sagas';

const authenticateUrl = '/auth/login';
const getUserUrl = '/api/account/getCurrentUser';
const logoutUrl = '/auth/account/logout';

const userStorageKey = 'dashboard-user';

function* getUserConfig(user, headerAuth) {
  const { role } = user;
  const getConfigAction = {
    key: `${role}$${CONFIG_BUCKETFILTER_KEY}`,
    storeAs: CONFIG_BUCKETFILTER_KEY
  };

  yield call(getConfigSaga, getConfigAction, headerAuth);
}

function* loginUserSaga({ payload: credentials }) {
  yield put(updateUserLoginFetchState(pendingFetchState));
  try {
    const authResponse = yield ajaxPost(authenticateUrl, credentials);
    const {
      data: { Authorization }
    } = authResponse;

    const userResponse = yield ajaxGet(getUserUrl, {
      headers: { Authorization }
    });
    const { data: user } = userResponse;
    yield call(getUserConfig, user, {
      headers: { Authorization }
    });
    yield put(userLoginComplete({ ...user, auth: Authorization }));
    yield put(updateUserLoginFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(userLoginError, err));
    yield put(updateUserLoginFetchState(failedFetchState));
  }
}

function* logoutUserSaga() {
  try {
    yield ajaxGet(logoutUrl);
  } catch (e) {
    const user = yield getItemFromStorage(userStorageKey);
    if (user) {
      yield call(cleanUserFromClientSaga);
    }
  }
}

export function* autoLogoutSaga() {
  const intervalChannel = yield call(interval, 300000);

  try {
    while (true) {
      yield take(intervalChannel);
      const { auth = '' } = yield select(userSelector);
      const token = getTokenFromAuth(auth);

      if (!isTokenValid(token)) {
        yield put(userSignOut());
      }
    }
  } finally {
    if (yield cancelled()) {
      intervalChannel.close();
    }
  }
}

function* storeUserSaga() {
  const user = yield select(userSelector);
  yield setItemToStorage(userStorageKey, user);
  yield call(configureAjaxAuth, user.auth);
}

export function* getUserFromStorageSaga() {
  const user = yield getItemFromStorage(userStorageKey);
  if (user) {
    yield call(configureAjaxAuth, user.auth);
    yield call(getUserConfig, user, user.auth);
    yield put(userFoundInStorage(user));
  }
}

function* cleanUserFromClientSaga(action) {
  yield call(configureAjaxAuth, null);
  yield setItemToStorage(userStorageKey, null);
  yield put(appReset());

  if (
    action &&
    is401ErrorAction(action) &&
    action.type !== types.userLoginError
  ) {
    const window = yield getContext('window');
    window.location.replace(prefixNavigationPath('/'));
    return;
  }
}

export default function createUserLoginSagas(types) {
  const logoutCriteria = [types.userSignOut, is401ErrorAction];
  return [
    autoLogoutSaga(),
    takeLatest(types.userLogin, loginUserSaga),
    takeEvery([types.userLoginComplete, types.updateUserToken], storeUserSaga),
    takeEvery(logoutCriteria, cleanUserFromClientSaga),
    takeEvery(logoutCriteria, logoutUserSaga)
  ];
}
