/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { findHierarchyNodesByProperty } from '../../utils';
import FilterByType from './filter-by-type';
import TypeaheadField from './typeahead-field';

const defaultState = { type: '', content: '' };

class HierarchySearch extends Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, defaultState);

    this.filterBy = React.createRef();
    this.typeahead = React.createRef();
  }

  handleTypeChanged = value => {
    this.resetTypeahead();
    this.setState({ type: value, content: '' });
  };

  handleContentChanged = value => {
    if (value) {
      const { id } = value;
      this.setState({ content: id });

      this.props.onTypeahead(value);
    }
  };

  resetSearch = () => {
    this.resetTypeahead();
    this.setState(defaultState);
  };

  resetTypeahead = () => {
    if (this.typeahead.current) {
      this.typeahead.current.reset();
    }
  };

  render = () => {
    const { hierarchy } = this.props;

    const subcustomers = findHierarchyNodesByProperty(hierarchy, {
      type: 'subcustomer'
    });
    const hasSubCustomers = subcustomers.length > 0;

    return (
      <Fragment>
        <FilterByType
          disabled={Object.keys(hierarchy).length === 0}
          handleChange={this.handleTypeChanged}
          hasSubCustomers={hasSubCustomers}
          ref={this.filterBy}
          value={this.state.type}
        />
        <div className="form-group">
          <TypeaheadField
            classes={['hierarchy-typeahead']}
            disabled={this.state.type === ''}
            filter={this.state.type}
            handleChange={this.handleContentChanged}
            id="hierarchy-typeahead"
            labelKey="typeahead"
            placeholder={
              this.state.type === ''
                ? 'No category selected'
                : 'Click / Enter search criteria'
            }
            ref={this.typeahead}
            typeahead={hierarchy}
          />
        </div>
      </Fragment>
    );
  };
}

HierarchySearch.defaultProps = { hierarchy: {} };

HierarchySearch.propTypes = {
  onTypeahead: PropTypes.func.isRequired,
  hierarchy: PropTypes.object
};

export default HierarchySearch;
