/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { filter, keys, pick } from 'lodash';
import { call, getContext } from 'redux-saga/effects';

const storageKey = 'ni-dashboard';

export function* setItemToStorage(key, item) {
  const localStore = yield getLocalStore();
  const newStore = { ...localStore, [key]: item };
  yield setLocalStore(newStore);
}

export function* getItemsFromStorageByKey(partialKey) {
  const localStore = yield getLocalStore();
  const chartModeKeys = filter(keys(localStore), k => k.includes(partialKey));
  return pick(localStore, chartModeKeys);
}

export function* getItemFromStorage(key) {
  const localStore = yield getLocalStore();
  return localStore[key] || null;
}

function* getLocalStore() {
  const window = yield getContext('window');
  const { localStorage } = window;

  const localStoreString = localStorage.getItem(storageKey) || '{}';
  try {
    return yield call(JSON.parse, localStoreString);
  } catch (e) {
    return {};
  }
}

function* setLocalStore(store) {
  const window = yield getContext('window');
  const { localStorage } = window;
  localStorage.setItem(storageKey, JSON.stringify(store));
}
