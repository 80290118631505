/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  BasicTooltip,
  ChartFlexContainer,
  ChartLabel,
  ChartLegend,
  ChartModeSwitcher
} from 'app/components/charts';
import { chartMargins } from 'app/components/charts/constants';
import { BlockContainer, NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { chartModeSuffix, styleLibrary } from 'app/constants';
import {
  chartModesSelector,
  createShowPanelSelector,
  updateChartDisplayMode
} from 'app/redux/app';
import {
  networkSummaryFetchStateSelector,
  manufacturerChartDataSelector,
  fetchNetworkSummary
} from 'app/redux/network';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  applyLegendToggles,
  calculateChartHeight,
  createColourFactory
} from 'app/utils';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import { getDisplayModeProperties } from '../../utils';
import { xAxisLabel, xAxisTick } from '../client-os/constants';
import { ChartTitleBar, PieBarChartWrapper } from '../common';

const getFillColour = createColourFactory(-30);
const modeKey = `manufacturer${chartModeSuffix}`;

export class ManufacturersChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: []
    };
  }

  handleChangeToggled = label => {
    const { toggled } = this.state;

    if (toggled.includes(label)) {
      toggled.splice(toggled.indexOf(label), 1);
    } else {
      toggled.push(label);
    }

    this.setState({ toggled });
  };

  setMode = mode => {
    this.props.updateChartDisplayMode({ key: modeKey, mode });
  };

  render() {
    const {
      chartData = {},
      chartModes = {},
      dateRangeFilterLabel,
      fetchNetworkSummary,
      fetchState,
      showGuestPrivatePanel,
      windowWidth
    } = this.props;
    const { toggled } = this.state;

    const { pending, complete, timeout } = fetchState;
    const { clientManufacturers = [], totalClients = 0 } = chartData;
    const hasData = !isEmpty(clientManufacturers);
    const noDataToShow = !pending && complete && !hasData;
    const dataToRender = timeout || pending ? [] : clientManufacturers;
    const mode = chartModes[modeKey] || 'pie';
    const { chartHeight, containerHeight } = calculateChartHeight(
      dataToRender.length,
      400,
      150
    );

    let pieData;
    if (mode === 'pie') {
      pieData = applyLegendToggles(
        dataToRender,
        toggled,
        'manufacturer',
        'count'
      );
    }

    const {
      padding: containerPadding,
      classes: wrapperClasses
    } = getDisplayModeProperties(mode, showGuestPrivatePanel, windowWidth);

    return (
      <BlockContainer data-test-label="manufacturers-tile">
        <ChartTitleBar
          dateRange={dateRangeFilterLabel}
          hasData={!!dataToRender.length}
          total={totalClients}
          title="Client Manufacturer"
        />
        <Fragment>
          {noDataToShow ? <NoDataToShow /> : null}
          <FailedFetchStateHandler
            fetchState={fetchState}
            retry={fetchNetworkSummary}
          >
            <div
              className={classNames(
                `pb-${containerPadding}`,
                pending || noDataToShow ? 'fetch-state-pending' : ''
              )}
            >
              {!isEmpty(dataToRender) && (
                <Fragment>
                  <ChartModeSwitcher onClick={this.setMode} mode={mode} />
                  <PieBarChartWrapper classes={wrapperClasses}>
                    {mode === 'bar' ? (
                      <ChartFlexContainer width="95%" height={containerHeight}>
                        <ComposedChart
                          margin={chartMargins}
                          height={chartHeight}
                          data={dataToRender}
                          layout="vertical"
                        >
                          <CartesianGrid vertical={true} horizontal={false} />
                          <XAxis
                            type="number"
                            label={xAxisLabel}
                            allowDecimals={false}
                            tick={xAxisTick}
                            tickMargin={1}
                          />
                          <YAxis
                            type="category"
                            yAxisId="count"
                            dataKey="manufacturer"
                            stroke="rgb(31, 119, 180)"
                            tick={null}
                          />
                          <Tooltip
                            content={<BasicTooltip maxValue={totalClients} />}
                            contentStyle={{
                              fontSize: styleLibrary.fontSizes.body
                            }}
                            offset={0}
                            wrapperStyle={{ marginLeft: 80 }}
                          />
                          <Bar
                            dataKey="count"
                            name="Clients"
                            yAxisId="count"
                            dot={false}
                            type="monotone"
                            strokeWidth={1.5}
                          >
                            <LabelList
                              content={<ChartLabel />}
                              dataKey="manufacturer"
                            />
                            {dataToRender.map(({ manufacturer }) => (
                              <Cell
                                key={manufacturer}
                                fill={getFillColour(manufacturer)}
                              />
                            ))}
                          </Bar>
                        </ComposedChart>
                      </ChartFlexContainer>
                    ) : (
                      <Fragment>
                        <PieChart width={250} height={275}>
                          <Pie
                            data={pieData}
                            dataKey="count"
                            innerRadius="50%"
                            nameKey="manufacturer"
                            outerRadius="100%"
                          >
                            <LabelList
                              content={<ChartLabel />}
                              dataKey="manufacturer"
                            />
                            {dataToRender.map(({ manufacturer }) => (
                              <Cell
                                key={manufacturer}
                                fill={getFillColour(manufacturer)}
                              />
                            ))}
                          </Pie>
                          <Tooltip
                            content={<BasicTooltip maxValue={totalClients} />}
                            contentStyle={{
                              fontSize: styleLibrary.fontSizes.body
                            }}
                            offset={0}
                            wrapperStyle={{ marginLeft: 80 }}
                          />
                        </PieChart>
                        <ChartLegend
                          data={dataToRender}
                          getFillColour={getFillColour}
                          onToggle={this.handleChangeToggled}
                          textProperty="manufacturer"
                          toggled={toggled}
                          countProperty="count"
                          totalCount={totalClients}
                        />
                      </Fragment>
                    )}
                  </PieBarChartWrapper>
                </Fragment>
              )}
            </div>
            {pending && <LoadingIconPlaceholder />}
          </FailedFetchStateHandler>
        </Fragment>
      </BlockContainer>
    );
  }
}

ManufacturersChart.propTypes = {
  chartData: PropTypes.shape({
    clientManufacturers: PropTypes.arrayOf(PropTypes.object),
    topTen: PropTypes.arrayOf(
      PropTypes.shape({
        manufacturer: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired
      })
    ),
    totalClients: PropTypes.number
  }),
  chartModes: PropTypes.object,
  dateRangeFilterLabel: PropTypes.string,
  fetchNetworkSummary: PropTypes.func,
  fetchState: fetchStatePropTypes,
  showGuestPrivatePanel: PropTypes.bool,
  updateChartDisplayMode: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired
};

const mapStateToProps = createSelector(
  chartModesSelector,
  manufacturerChartDataSelector,
  networkSummaryFetchStateSelector,
  dateRangeFilterLabelSelector,
  createShowPanelSelector('GuestPrivate'),
  (
    chartModes,
    chartData,
    fetchState,
    dateRangeFilterLabel,
    showGuestPrivatePanel
  ) => ({
    chartModes,
    chartData,
    fetchState,
    dateRangeFilterLabel,
    showGuestPrivatePanel
  })
);

export default connect(mapStateToProps, {
  fetchNetworkSummary,
  updateChartDisplayMode
})(ManufacturersChart);
