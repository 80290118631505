/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { PathComponent, TitleBar } from 'app/components/elements';
import Filters from 'app/components/filters';
import { InlineLoadingIcon } from 'app/components/icons';
import { TableExport } from 'app/components/tables';
import {
  createShowPanelSelector,
  userNameSelector,
  userGroupsSelector
} from 'app/redux/app';
import {
  top10ClientsDurationUnitSelector,
  top10ClientsSelector,
  top10ClientsSelectorForLAC
} from 'app/redux/clients';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  calculatedInventoryValuesSelector,
  propertyInventoryCountSelector
} from 'app/redux/inventory';
import {
  clientOsChartDataSelector,
  manufacturerChartDataSelector,
  networkDetailsChartDataSelector,
  usageSummaryThroughputSelector,
  customNetworkDataSelector
} from 'app/redux/network';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  ClientOS,
  GuestSubscriberChart,
  Inventory,
  ManufacturersChart,
  NetworkSummary,
  PropertiesChart,
  TopClients,
  UsageSummaryChart,
  TopClientForLAC
} from './components';
import { WindowWidthWrapper } from './components/common';
import {
  createOverviewExportDataDefinition,
  createOverviewHtmlExportDefinition
} from './utils';
import { userGroupList } from 'app/constants';

export const Overview = props => {
  const { selected, showGuestPrivatePanel, userName, userGroups } = props;
  const chartColClass = `col-lg-${showGuestPrivatePanel ? '4' : '6'}`;

  const isUserLAC = userGroupList.some(item => userGroups.includes(item));

  return (
    <Fragment>
      <TitleBar
        classes={['include-in-pdf']}
        id="overview-title"
        dark
        leftChildren={<PathComponent />}
        rightChildren={
          <div className="d-flex justify-content-spread align-items-center">
            <Filters showVerticalFilter={true} />
            {selected ? (
              <TableExport
                className="hide-in-pdf"
                exportDataDefinitions={createOverviewExportDataDefinition({
                  ...props
                })}
                htmlExportDefinition={createOverviewHtmlExportDefinition(
                  selected,
                  userName
                )}
                exportName="Overview"
              />
            ) : (
              <span className="ml-3">
                <InlineLoadingIcon />
              </span>
            )}
          </div>
        }
      />
      <div className="row my-4 include-in-pdf">
        <div className="col-xxxl-6 mb-4 mb-xxxl-0">
          <UsageSummaryChart />
        </div>
        <div className="col-lg-6 col-xxxl-3 mb-4 mb-lg-0">
          <PropertiesChart />
        </div>
        <div className="col-lg-6 col-xxxl-3">
          <Inventory />
        </div>
      </div>
      <div className="row no-gutters mb-4 include-in-pdf">
        <NetworkSummary />
      </div>
      <WindowWidthWrapper
        render={windowWidth => {
          return (
            <div className="row mb-4 include-in-pdf">
              <div className={chartColClass}>
                <ClientOS windowWidth={windowWidth} />
              </div>
              <div className={chartColClass}>
                <ManufacturersChart windowWidth={windowWidth} />
              </div>
              {showGuestPrivatePanel && (
                <div className={chartColClass}>
                  <GuestSubscriberChart />
                </div>
              )}
            </div>
          );
        }}
      />
      {isUserLAC ? <TopClientForLAC /> : <TopClients />}
    </Fragment>
  );
};

Overview.propTypes = {
  inventoryCalculatedValues: PropTypes.object,
  selected: PropTypes.object,
  top10Clients: PropTypes.array,
  lactop10Clients: PropTypes.array,
  top10ClientsDurationUnit: PropTypes.string,
  userName: PropTypes.string,
  manufacturerChartData: PropTypes.object,
  clientOsChartData: PropTypes.object,
  networkDetailsChartData: PropTypes.arrayOf(PropTypes.object),
  networkChart: PropTypes.arrayOf(PropTypes.object),
  propertiesChartData: PropTypes.arrayOf(PropTypes.object),
  usageSummaryThroughput: PropTypes.arrayOf(PropTypes.object),
  showGuestPrivatePanel: PropTypes.bool,
  userGroups: PropTypes.array
};

const mapStateToProps = createSelector(
  calculatedInventoryValuesSelector,
  top10ClientsSelector,
  top10ClientsDurationUnitSelector,
  selectedPathSelector,
  userNameSelector,
  manufacturerChartDataSelector,
  clientOsChartDataSelector,
  networkDetailsChartDataSelector,
  propertyInventoryCountSelector,
  createShowPanelSelector('GuestPrivate'),
  usageSummaryThroughputSelector,
  userGroupsSelector,
  customNetworkDataSelector,
  top10ClientsSelectorForLAC,
  (
    inventoryCalculatedValues,
    top10Clients,
    top10ClientsDurationUnit,
    selected,
    userName,
    manufacturerChartData,
    clientOsChartData,
    networkDetailsChartData,
    propertiesChartData,
    showGuestPrivatePanel,
    usageSummaryThroughput,
    userGroups,
    networkChart,
    lactop10Clients
  ) => ({
    inventoryCalculatedValues,
    top10Clients,
    top10ClientsDurationUnit,
    selected,
    userName,
    manufacturerChartData,
    clientOsChartData,
    networkDetailsChartData,
    propertiesChartData,
    showGuestPrivatePanel,
    usageSummaryThroughput,
    userGroups,
    networkChart,
    lactop10Clients
  })
);

export default connect(mapStateToProps)(Overview);
