/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { styleLibrary } from 'app/constants';
import classNames from 'classnames';
import { ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

export { default as BasicTooltip } from './basic-tooltip';
export { default as ChartLabel } from './chart-label';
export { default as ChartLegend } from './chart-legend';
export { default as ChartModeSwitcher } from './chart-mode-switcher';
export { default as MultiLineTooltip } from './multi-line-tooltip';
export { default as TooltipItem } from './tooltip-item';
export { default as TrendChartLegend } from './trend-legend';
export { default as OverviewLegendtoolTip } from './overview-legend-tool-tip';

export const TooltipWrapper = styled.div.attrs(props => ({
  className: classNames(
    'justify-content-center',
    props.flex && 'd-flex align-items-center'
  )
}))`
  border: 1px solid ${styleLibrary.darkBg};
  background: ${styleLibrary.tooltipBg};
  padding: 10px;
  font-size: ${styleLibrary.fontSizes.body}px;
`;

export const FixedTooltipWrapper = styled(TooltipWrapper)`
  width: auto;
`;

export const TooltipHeader = styled.div.attrs({
  className: 'px-4 text-center'
})`
  background: ${styleLibrary.darkBg};
  color: ${styleLibrary.lightText};
  padding: 5px 0;
  margin-bottom: 10px;
`;

export const ChartFlexContainer = styled(ResponsiveContainer)`
  &.loading {
    opacity: 0.3;
  }
`;
ChartFlexContainer.displayName = 'ChartFlexContainer';
