/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { getHourMinuteTimeFormat, getReadableTimeFormat } from 'app/utils';
import { isInteger } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Text } from 'recharts';

const hourRE = /^\d{1,2}:\d{2}$/;

const calcOffsetValue = (v, diff, serverDiff) => {
  const offset = diff - serverDiff;
  let h = 0;
  let m = 0;
  let nh = 0;
  let nm = 0;
  let match;

  if (v !== '') {
    match = v.match(/^(\d{2}):(\d{2})$/);
    if (match) {
      [, h, m] = match;
      h = parseInt(h, 10);
      m = parseInt(m, 10);
      nm = h * 60 + m;
    }
  }
  if (isInteger(offset)) {
    nh = h + offset;
    nm = m;
  } else {
    nh = h + Math.floor(offset);
    nm = m + 30;
    if (nm >= 60) {
      nm = nm - 60;
      nh = nh + 1;
    }
  }

  if (nh >= 24) {
    nh = nh - 24;
  } else if (nh < 0) {
    nh = 24 + nh;
  }

  return moment()
    .hour(nh)
    .minute(nm)
    .format('HH:mm');
};

export const XAxisTick = props => {
  const {
    label = 'Selected',
    offset,
    serverTimeZone,
    serverOffset,
    payload,
    ...rest
  } = props;
  const { index, value } = payload;
  const { y, ...displayProps } = rest;

  if (!hourRE.test(value)) {
    return true;
  }

  const timeZoneLabelX = 15;
  const topLabelProps = {
    y: y + 5,
    fontSize: 12,
    fontWeight: 'bold'
  };
  const bottomLabelProps = {
    ...topLabelProps,
    y: y + 25,
    fontSize: 11,
    fontWeight: 'normal'
  };

  return (
    <Fragment>
      {index === 0 && (
        <Text
          {...displayProps}
          {...topLabelProps}
          x={timeZoneLabelX}
          textAnchor="start"
        >
          {serverTimeZone}
        </Text>
      )}
      <Text {...displayProps} {...topLabelProps}>
        {getReadableTimeFormat(value)}
      </Text>
      {index === 0 && (
        <Text
          {...displayProps}
          {...bottomLabelProps}
          x={timeZoneLabelX}
          textAnchor="start"
        >
          {label}
        </Text>
      )}
      <Text {...displayProps} {...bottomLabelProps}>
        {getReadableTimeFormat(
          calcOffsetValue(getHourMinuteTimeFormat(value), offset, serverOffset)
        )}
      </Text>
    </Fragment>
  );
};

XAxisTick.propTypes = {
  label: PropTypes.string,
  offset: PropTypes.number,
  payload: PropTypes.object,
  serverTimeZone: PropTypes.string,
  serverOffset: PropTypes.number
};

export const CsutomXAxisTick = ({
  x,
  y,
  payload,
  displayTwoHours,
  localTimeZone,
  label,
  serverTimeZone,
  serverOffset
}) => {
  const { value, index } = payload;

  if (!value) {
    return null;
  }
  const timeZoneLabelX = x;
  const topLabelProps = {
    x,
    y: y + 10,
    fontSize: 12,
    fontWeight: 'bold',
    textAnchor: 'middle'
  };
  const bottomLabelProps = {
    x,
    y: y + 30,
    fontSize: 11,
    fontWeight: 'normal',
    textAnchor: 'middle'
  };

  const formattedTime = getReadableTimeFormat(value);
  let offsetTime = '';
  if (formattedTime) {
    offsetTime = getReadableTimeFormat(
      calcOffsetValue(
        getHourMinuteTimeFormat(value),
        localTimeZone,
        serverOffset
      )
    );
  }

  return (
    <Fragment>
      {displayTwoHours && index === 0 && (
        <Fragment>
          <Text {...topLabelProps} x={timeZoneLabelX - 70} textAnchor="start">
            {serverTimeZone}
          </Text>
          <Text
            {...bottomLabelProps}
            x={timeZoneLabelX - 70}
            textAnchor="start"
          >
            {label}
          </Text>
        </Fragment>
      )}
      <Text {...topLabelProps}>{formattedTime}</Text>
      {displayTwoHours && <Text {...bottomLabelProps}>{offsetTime}</Text>}
    </Fragment>
  );
};

CsutomXAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
  displayTwoHours: PropTypes.bool,
  localTimeZone: PropTypes.number.isRequired,
  label: PropTypes.string,
  serverTimeZone: PropTypes.string,
  serverOffset: PropTypes.number
};

export default CsutomXAxisTick;
