/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React from 'react';
import PropTypes from 'prop-types';

import { styleLibrary } from 'app/constants';
import FaIcon from 'app/components/icons/fa-icon';

export default function InlineLoadingIcon({ style = {} }) {
  return (
    <FaIcon
      icon="circle-notch"
      spin
      style={{ fontSize: styleLibrary.fontSizes.inlineIcon, ...style }}
    />
  );
}

InlineLoadingIcon.propTypes = {
  style: PropTypes.objectOf(PropTypes.string)
};
