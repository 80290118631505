/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { has } from 'lodash';
import { createSelector } from 'reselect';

import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';

export const breadcrumbPathSelector = createSelector(
  hierarchySelector,
  selectedPathSelector,
  getRenderPath
);

const ellipsis = String.fromCharCode(0x2026);

/* getRenderPath is exported only for testing,
 * not to be used outside of a selector irl
 */
export function getRenderPath(hierarchy, selected) {
  if (!selected) {
    return [];
  }

  const { ancestors, id } = selected;
  const pathToUse = ancestors.concat(id);
  const displayPath =
    pathToUse.length <= 3
      ? pathToUse
      : [{ name: ellipsis }].concat(pathToUse.slice(-2));

  return displayPath.map(p => (has(p, 'name') ? p : hierarchy[p]));
}
