/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createShowPanelSelector } from 'app/redux/app';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import CBRsInventorySummary from './cbrs-inventory-summary';
import DruidAPsTable from './druid-aps-table';
import DruidTrafficDataTrend from './druid-traffic-data-trend';

export const PropertyCBRSInventory = ({
  showCBRsInventorySummary,
  showCBRsDruidAPsTable,
  showCBRsDruidTrend
}) => (
  <Fragment>
    {showCBRsInventorySummary && <CBRsInventorySummary />}
    {showCBRsInventorySummary && showCBRsDruidTrend && <hr className="mb-4" />}
    {showCBRsDruidTrend  && <DruidTrafficDataTrend /> }
    {showCBRsDruidTrend && showCBRsDruidAPsTable}
    {showCBRsDruidAPsTable && <DruidAPsTable />}
  </Fragment>
);

PropertyCBRSInventory.propTypes = {
  showCBRsInventorySummary: PropTypes.bool,
  showCBRsDruidTrend: PropTypes.bool,
  showCBRsDruidAPsTable: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('PropertyCBRsInventorySummary'),
  createShowPanelSelector('PropertyCBRsDruidTrafficTrend'),
  createShowPanelSelector('PropertyCBRsAPsTable'),
  (showCBRsInventorySummary, showCBRsDruidTrend, showCBRsDruidAPsTable) => ({
    showCBRsInventorySummary,
    showCBRsDruidTrend,
    showCBRsDruidAPsTable
  })
);

export default connect(mapStateToProps)(PropertyCBRSInventory);
