/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { filterParamsSelector } from 'app/redux/filters';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';
import { ajaxGet } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { get, merge } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchAbuseSessionDetailsComplete,
  fetchAbuseSessionDetailsError,
  updateAbuseSessionDetailsFetchState
} from './';

export function* fetchAbuseSessionDetailsSaga({ payload }) {
  yield put(updateAbuseSessionDetailsFetchState(pendingFetchState));
  yield call(waitForHierarchySaga);

  const filters = yield select(filterParamsSelector);

  const params = yield merge({}, filters, payload, {
    clientmac: get(payload, 'sessionMAC', ''),
    publicIP: get(payload, 'publicIP', ''),
    nasPort: get(payload, 'nasPort', ''),
    timeUnitFrom: get(payload, 'timeUnitFrom', ''),
    timeUnitTo: get(payload, 'timeUnitTo', ''),
  });

  const url = '/AbuseAPI/1.0/getSessions';
  const paramNames = [
    'clientmac',
    'publicIP',
    'nasPort',
    'timeUnitFrom',
    'timeUnitTo'
  ];

  try {
    const onTimeout = () =>
      put(updateAbuseSessionDetailsFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .filter(p => params[p] !== '')
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });
    yield put(fetchAbuseSessionDetailsComplete(data));
    yield put(updateAbuseSessionDetailsFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchAbuseSessionDetailsError, err));
    yield put(updateAbuseSessionDetailsFetchState(failedFetchState));
  }
}

export default function createFetchAbuseSessionDetailsSagas(types) {
  return [
    takeLatest(types.fetchAbuseSessionDetails, fetchAbuseSessionDetailsSaga)
  ];
}
