/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

export const getErrorMeta = err => err.meta || null;

export class AjaxRequestError extends Error {
  constructor(message, response, originalError, bodyText = '') {
    super(message);
    this.name = this.constructor.name;
    this.meta = {
      response,
      bodyText,
      originalError
    };

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AjaxRequestError);
    }
  }
}

const isRequestError = Symbol('isRequestError');
AjaxRequestError.prototype[isRequestError] = true;

export const isAjaxRequestError = err => !!err[isRequestError];

export class AjaxTimeoutError extends Error {
  constructor(message, requestMeta) {
    super(message);
    this.name = this.constructor.name;
    this.meta = requestMeta;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AjaxTimeoutError);
    }
  }
}
const isTimeoutError = Symbol('isTimeoutError');
AjaxTimeoutError.prototype[isTimeoutError] = true;

export const isAjaxTimeoutError = err => !!err[isTimeoutError];

export class FileExportError extends Error {
  constructor(message, originalError, filename) {
    super(message);
    this.name = this.constructor.name;

    this.meta = {
      originalError,
      filename
    };

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FileExportError);
    }
  }
}

const _isFileExportError = Symbol('isFileExportError');
export const isFileExportError = err => !!err[_isFileExportError];

FileExportError.prototype[_isFileExportError] = true;

export class ReactUIError extends Error {
  constructor(boundaryLevelName, reactError, reactInfo) {
    super(reactError.message);
    this.name = this.constructor.name;
    this.meta = {
      boundaryLevelName,
      reactError,
      reactInfo
    };

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ReactUIError);
    }
  }
}

const isUIError = Symbol('isUIError');
ReactUIError.prototype[isUIError] = true;

export const isReactUIError = err => !!err[isUIError];
