/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { dateRangeFilterSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  fetchRogueMACCount,
  rogueMACCountFetchStateSelector,
  rogueMACCountSelector
} from 'app/redux/rogue';
import { fetchStatePropTypes } from 'app/redux/utils';
import { isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import RogueMACChart from './rogue-mac-chart';

class RogueMAC extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = { hourlyData: false };
  // }

  // toggleHourlyData = checked => {
  //   const { fetchRogueMACCount } = this.props;
  //   this.setState({ hourlyData: checked }, () =>
  //     fetchRogueMACCount({ hourlyData: checked })
  //   );
  // };

  render = () => {
    const { fetchRogueMACCount, chartData, fetchState } = this.props;

    const { data = [] } = chartData;
    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <Fragment>
        <h4 className="d-flex justify-content-start align-items-center m-2">
          Rogue Count
        </h4>
        {/* <BlockContainer classes={['col', 'mt-3']} style={{ minHeight: 200 }}>
        <TitleBar
          leftChildren="Rogue MACs"
          padUnderTitle={false}
          rightChildren={
            <div className="d-flex justify-content-spread align-items-center">
              <span style={{ fontSize: 14 }}>Hourly Data</span>
              <Switch
                onChange={this.toggleHourlyData}
                checked={hourlyData}
                height={25}
                width={50}
                className="ml-1 mr-5"
              />
              <span style={{ fontSize: styleLibrary.fontSizes.secondaryTitle }}>
                {dateRangeFilterLabel}
              </span>
            </div>
          }
        /> */}
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={fetchRogueMACCount}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message="No data available"
              style={{
                background: 'none',
                position: 'relative'
              }}
            />
          ) : (
            complete && hasData && <RogueMACChart chartData={chartData} />
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

RogueMAC.propTypes = {
  dateRangeFilter: PropTypes.object,
  selectedPath: PropTypes.object,
  fetchRogueMACCount: PropTypes.func,
  chartData: PropTypes.shape({
    data: PropTypes.array,
    info: PropTypes.object
  }),
  fetchState: fetchStatePropTypes
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  selectedPathSelector,
  rogueMACCountSelector,
  rogueMACCountFetchStateSelector,
  (dateRangeFilter, selectedPath, chartData, fetchState) => ({
    dateRangeFilter,
    selectedPath,
    chartData,
    fetchState
  })
);

const mapDispatchToProps = { fetchRogueMACCount };

export default connect(mapStateToProps, mapDispatchToProps)(RogueMAC);
