/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';
import Header from './components/header';
import Sidebar from './components/sidebar';

const FlexContainer = styled.div.attrs({
  id: 'wrapper'
})`
  display: flex;
  flex: 1;
  min-height: calc(100vh - 80px);
`;

const MainContainer = styled.div.attrs({
  id: 'main-container',
  className: 'p-3'
})`
  background: ${styleLibrary.lightBg};
  color: ${styleLibrary.darkText};
  flex: 1;
  overflow-y: auto;
  height: calc(100vh - 80px);
`;

class Layout extends Component {
  render() {
    const { children } = this.props;

    return (
      <Fragment>
        <Header />
        <FlexContainer>
          <Sidebar />
          <MainContainer>{children}</MainContainer>
        </FlexContainer>
      </Fragment>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object.isRequired
};

export default Layout;

export { default as TypeaheadField } from './components/sidebar/components/search/typeahead-field';
