/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { NoDataToShow } from 'app/components/elements';
import SsidWlanFilter from 'app/components/filters/components/ssid-wlan-filter';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  fetchPropertyClientStatus,
  propertyClientStatusFetchStateSelector,
  propertyClientStatusSelector
} from 'app/redux/clients';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import { isArray, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ConnectedClientsTrendChart from './connected-clients-trend-chart';

export class ConnectedClientsTrend extends Component {
  constructor(props) {
    super(props);
    this.state = { wlan: 'all' };
  }

  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilterLabel,
      fetchPropertyClientStatus
    } = this.props;
    const {
      selectedPath: prevPath,
      dateRangeFilterLabel: prevDateRange
    } = prevProps;

    const propertyChanged =
      isSpecificNodeType(selectedPath, 'zonename') &&
      !isEqual(prevPath.id, selectedPath.id);
    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilterLabel);

    if (propertyChanged || dateRangeChanged) {
      this.setState({ wlan: 'all' }, () => fetchPropertyClientStatus());
    }
  };

  handleWlanChanged = selectedWlan => {
    const { fetchPropertyClientStatus } = this.props;
    this.setState({ wlan: selectedWlan }, () =>
      fetchPropertyClientStatus({ wlanName: selectedWlan })
    );
  };

  renderDropdowns = () => (
    <div className="d-flex justify-content-end align-items-center mx-2 mt-2 mb-0">
      <SsidWlanFilter
        wlan={this.state.wlan}
        handleWlanChanged={this.handleWlanChanged}
      />
    </div>
  );

  render = () => {
    const {
      fetchState,
      propertyClientStatus: { data = [], info = {} },
      fetchPropertyClientStatus
    } = this.props;
    const { wlan } = this.state;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <Fragment>
        {this.renderDropdowns()}
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() => fetchPropertyClientStatus({ wlanName: wlan })}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message={'No data available'}
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            complete &&
            hasData && <ConnectedClientsTrendChart data={data} info={info} />
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

ConnectedClientsTrend.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  fetchPropertyClientStatus: PropTypes.func,
  propertyClientStatus: PropTypes.object,
  fetchState: fetchStatePropTypes
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  propertyClientStatusFetchStateSelector,
  propertyClientStatusSelector,
  (dateRangeFilterLabel, selectedPath, fetchState, propertyClientStatus) => ({
    dateRangeFilterLabel,
    selectedPath,
    fetchState,
    propertyClientStatus
  })
);

const mapDispatchToProps = { fetchPropertyClientStatus };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedClientsTrend);
