/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const ColorBox = styled.div.attrs({
  className: 'rounded-circle'
})`
  height: 20px;
  width: 20px;
  background-color: ${props => props.color};
`;

const LabelWrapper = styled.span.attrs({
  className: 'ml-2'
})`
  white-space: nowrap;
`;

export const SeverityColorBox = props => {
  return (
    <div className="d-flex justify-content-start">
      <div className="d-flex justify-content-start mr-2">
        <ColorBox color={props.color} />
      </div>
      <LabelWrapper>{props.label}</LabelWrapper>
    </div>
  );
};

SeverityColorBox.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string
};
