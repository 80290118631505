/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { HierarchyFilterTypes } from 'app/redux/hierarchy/constants';
import { sortByProperty } from 'app/utils';
import {
  filter,
  flattenDeep,
  has,
  isArray,
  isEmpty,
  merge,
  toArray
} from 'lodash';

export const buildUIHierarchy = root => {
  const hierarchy = {};
  let rootNode = root.length === 1 ? [root[0]] : [{}];
  const unwantedNodeNames = [
    '',
    'NA',
    'N/A',
    'N./A.',
    'N/A.',
    'N./A',
    'N.A.',
    'NA.',
    'N.A'
  ];

  const formatNodeNames = (root, parentNodeName) => {
    if (isArray(root) && !isEmpty(root)) {
      root.forEach(node => {
        // Check if the node name is under the unwanted category,
        // If yes, then make the displayName as the parentNodeName
        if (
          unwantedNodeNames.includes(node['name'].toUpperCase()) &&
          !isEmpty(parentNodeName)
        ) {
          node['displayName'] = parentNodeName;
        }

        // Check if the node is of type 'wlan',
        // If yes, then make the displayName as ssid instead of wlan
        if (
          node['type'] === 'wlan' &&
          has(node, 'ssid') &&
          !isEmpty(node['ssid'])
        ) {
          node['displayName'] = node['ssid'];
        }

        // Recursively call the same method to cover all the children
        if (
          has(node, 'children') &&
          isArray(node['children']) &&
          !isEmpty(node['children'])
        ) {
          node['children'] = formatNodeNames(node['children'], node['name']);
        }
      });
    }
    return root;
  };

  /**
   * This function calculates the API body to be sent to the various calls. The challenge here is that some APIs require
   * a different body, depending on the level of the tree.
   *
   * None of the APIs expect the `parent` or `children` nodes beyond one level. Hence these values are trimmed.
   *
   * @param {Object} fromNode The selected node in the tree
   * @returns {Object} A correctly formatted, massaged string to be passed to the APIs as a POST body.
   */
  const getApiBodies = fromNode => {
    let node = JSON.parse(JSON.stringify(fromNode));

    // For a WLAN node, the API body needs to a) simple: the enclosing property node only b) complex: the property
    // node inside its vertical
    if (node.type === 'wlan') {
      const [grandparent, parent] = node.ancestors.slice(-2);
      const pNode = hierarchy[parent];
      const gpNode = hierarchy[grandparent];

      const complexApiBody = JSON.stringify({
        children: [
          {
            name: pNode.name,
            type: pNode.type
          }
        ],
        name: gpNode.name,
        type: gpNode.type
      });

      const simpleApiBody = JSON.stringify({
        name: pNode.name,
        type: pNode.type
      });

      return { simpleApiBody, complexApiBody };
    }

    // For a zonename node requiring the complex body, return a manually constructed body as required
    if (node.type === 'zonename') {
      const { name, type, ancestors } = node;

      const [parent] = ancestors.slice(-1);
      const pNode = hierarchy[parent];

      const complexApiBody = JSON.stringify({
        children: [{ name, type }],
        name: pNode.name,
        type: pNode.type
      });

      const simpleApiBody = JSON.stringify({
        name,
        type
      });

      return { simpleApiBody, complexApiBody };
    }

    const { name, type, children } = node;

    const standardBody = JSON.stringify({
      children: children.map(child => {
        const { name, type } = hierarchy[child];
        return { name, type };
      }),
      name,
      type
    });

    return { simpleApiBody: standardBody, complexApiBody: standardBody };
  };

  /**
   * This function flattens our complex hierarchy structure into a more manageable and efficient array. From this,
   * we should be able to build/reference exactly the node of the tree that we want at any given time and for any
   * given API call.
   *
   * The principal of this structure is that we will build an object with keys representing each node of the tree.
   * The keys will be built using node names joined. The structure of an entry will be as follows:
   *
   * ID: (e.g. 'Arris-South-Georgia-Suwannee') {
   *   name: <Individual name of the node>,
   *   type: <Type of the node>,
   *   ancestors: <An array of IDs of the nodes above this one in the tree>,
   *   children: <An array of IDs of the children under this one in the tree>,
   *   simpleApiBody: <A JSON string representing the body to be passed to a simple API>,
   *   complexApiBody: <A JSON string representing the body to be passed to a complex API>
   * }
   *
   * @param {Object} root The starting point to parse nodes from. This is recursive as we work down through the tree
   * @param {Array} path The items we have traversed to reach this point. This allows us to build IDs and ancestor lists
   * @param {String} [subCustomerId] The ID of the sub-customer node of this node
   */
  const buildUIHierarchyNode = (root, path = [], subCustomerId = '') => {
    if (root.length > 0) {
      root.forEach(node => {
        const { name, displayName, type } = node;
        let subCustomer = subCustomerId;

        const nodePath = path.concat(node);
        const nodeId = nodePath.map(n => n.name).join('-');
        const typeahead = nodePath
          .map(n => (isEmpty(n.displayName) ? n.name : n.displayName))
          .join(' > ');

        const entry = {
          id: nodeId,
          name,
          type,
          ancestors: [],
          typeahead
        };
        if (!isEmpty(displayName)) {
          entry['displayName'] = displayName;
        }

        if (type === 'subcustomer') {
          subCustomer = nodeId;
        } else if (subCustomer !== '') {
          entry.subcustomer = subCustomer;
        }

        path.reduce((prev, cur) => {
          const next = prev.concat(cur.name);
          entry.ancestors.push(next.join('-'));
          return next;
        }, []);

        if (node.children) {
          const sortedChildren = node.children.sort(sortByProperty('name'));
          entry.children = sortedChildren.map(child =>
            nodePath
              .concat(child)
              .map(n => n.name)
              .join('-')
          );
          buildUIHierarchyNode(node.children, nodePath, subCustomer);
        }

        hierarchy[nodeId] = entry;
      });
    }
  };

  // If we have more than one element at our root level, we have a misconfigured hierarchy.
  // Let's try to find the one with the most items in it...
  if (root.length !== 1) {
    const itemsInNode = root.map(n => flattenDeep(toArray(n)).length);
    rootNode = [root[itemsInNode.indexOf(Math.max(...itemsInNode))]];
  }

  let baseOfTree = [...rootNode];

  // In some set-ups, the root customer node should not be shown in the hierarchy.
  // This code handles that scenario
  if (baseOfTree.length > 0 && baseOfTree[0].display === 'False') {
    baseOfTree = rootNode[0].children;
  }

  baseOfTree = formatNodeNames(baseOfTree);

  buildUIHierarchyNode(baseOfTree);

  Object.keys(hierarchy).forEach(key => {
    const { simpleApiBody, complexApiBody } = getApiBodies(hierarchy[key]);
    merge(hierarchy[key], {
      simpleApiBody,
      complexApiBody
    });
  });

  hierarchy.rootNodeId = baseOfTree.length > 0 ? baseOfTree[0].name : '';

  // Append the list of customers onto the hierarchy array so we can display something in the UI
  // indicating the problem
  if (root.length > 1) {
    hierarchy.customers = root.map(n => n.name);
  }

  return hierarchy;
};

export const findHierarchyNodesByProperty = (obj, property) => {
  return filter(toArray(obj), property);
};

/**
 * This function simply returns whether a given tree node is considered property level (i.e. at the zone/property
 * or WLAN level).
 *
 * @method isPropertyNodeOrUnder
 * @param {Object} node A specific tree node
 * @returns {Boolean} True if this is a property node, false otherwise
 */
export const isPropertyNodeOrUnder = (node = {}) => {
  return ['zonename', 'wlan'].includes(node.type);
};

/**
 * This function simply returns whether a given tree node is a customer node.
 *
 * @method isSpecificNodeType
 * @param {Object} node A specific tree node
 * @param {String} type The type of node to check against
 * @returns {Boolean} True if this is a customer node, false otherwise
 */
export const isSpecificNodeType = (node = {}, type) => {
  return node.type === type;
};

/**
 * This function simply returns whether a given tree node falls under the specified nodeLevel/nodeType.
 *
 * @method isNodeUnderSpecificLevel
 * @param {Object} node A specific tree node
 * @param {String} level The type of node to check against
 * @returns {Boolean} True if this node falls under the specified nodeLevel/nodeType, false otherwise
 */
export const isNodeUnderSpecificLevel = (node = {}, level = '') => {
  const levels = HierarchyFilterTypes.map(t => t.value);
  return (
    levels.findIndex(n => n === node.type) >= levels.findIndex(n => n === level)
  );
};

/**
 * This function parses a node and works out all possible paths for it. The
 * purpose is to know if the currently selected path is possible for the
 * selected node and thus whether to expand it in the tree.
 *
 * @method getAllNodePaths
 * @param {Object} hierarchy The full hierarchy
 * @param {Object} node The node we wish to parse for paths
 * @returns {Array} An array of possible paths as strings
 */
export const getAllNodePaths = (hierarchy, node = {}) => {
  const getFromNode = (children = []) => {
    let result = '';

    if (children.length > 0) {
      children.forEach(x => {
        const child = hierarchy[x];
        result += `${child.id};${getFromNode(child.children)}`;
      });
    }
    return result;
  };

  const paths = `${node.id};${getFromNode(node.children)}`;
  return paths.split(';');
};
