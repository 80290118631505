/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { styleLibrary } from 'app/constants';

export { default as AlarmCard } from './alarm-card';
export { default as AlarmDoughnut } from './alarm-doughnut';
export { default as AlarmsStatistics } from './alarms-statistics';
export { default as AlarmsTable } from './alarms-table';

export const alarmSeverityTypes = [
  {
    name: 'disaster',
    key: 'disaster',
    label: 'Disaster',
    color: styleLibrary.alarms.disaster
  },
  {
    name: 'high',
    key: 'high',
    label: 'High',
    color: styleLibrary.alarms.high
  },
  {
    name: 'avg',
    key: 'average',
    label: 'Average',
    color: styleLibrary.alarms.avg
  },
  {
    name: 'warn',
    key: 'warning',
    label: 'Warning',
    color: styleLibrary.alarms.warn
  },
  {
    name: 'info',
    key: 'information',
    label: 'Information',
    color: styleLibrary.alarms.info
  },
  {
    name: 'other',
    label: 'Other',
    color: styleLibrary.alarms.other
  }
];
