/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { formatNumericValue, zeroProtection } from 'app/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { BarPart, TotalWrapper } from '.';

const TooltipHtml = props => {
  const { left, right, suffix = '', valueFormatter } = props;

  const leftValue =
    left.value >= 1 && valueFormatter
      ? valueFormatter(left.value)
      : `${left.value} ${suffix}`;
  const rightValue =
    right.value >= 1 && valueFormatter
      ? valueFormatter(right.value)
      : `${right.value} ${suffix}`;

  return (
    <div className="d-flex align-items-center">
      <div className="mr-1">
        {left.indicator ? (
          left.indicator
        ) : (
          <div style={{ width: 14, height: 14, backgroundColor: left.color }} />
        )}
      </div>
      <div className="mr-4">{leftValue}</div>
      <div className="mr-1">
        {right.indicator ? (
          right.indicator
        ) : (
          <div
            style={{ width: 14, height: 14, backgroundColor: right.color }}
          />
        )}
      </div>
      <div>{rightValue}</div>
    </div>
  );
};

TooltipHtml.propTypes = {
  left: PropTypes.shape({
    indicator: PropTypes.object,
    value: PropTypes.any,
    color: PropTypes.string
  }).isRequired,
  right: PropTypes.shape({
    indicator: PropTypes.object,
    value: PropTypes.any,
    color: PropTypes.string
  }).isRequired,
  suffix: PropTypes.string,
  valueFormatter: PropTypes.func
};

export const TwoPartBar = props => {
  const { barWidth, left, max, right, suffix, totalFormatter } = props;

  const total = left.value + right.value;
  const totalBarWidth = Math.ceil(barWidth * (total / zeroProtection(max)));
  const leftWidth = Math.ceil(
    totalBarWidth * (left.value / zeroProtection(total))
  );
  const rightWidth = Math.ceil(
    totalBarWidth * (right.value / zeroProtection(total))
  );
  const formattedTotal =
    total > 0 && totalFormatter
      ? totalFormatter(total)
      : `${formatNumericValue(total, 1)} ${suffix}`;

  return (
    <div className="d-flex justify-content-start">
      <Tooltip
        animation="fade"
        className="d-flex justify-content-start mr-2"
        duration={100}
        followCursor={true}
        html={<TooltipHtml {...props} valueFormatter={totalFormatter} />}
        inertia={true}
        position="left"
        size="small"
        theme="light"
      >
        <BarPart width={leftWidth} color={left.color} />
        <BarPart width={rightWidth} color={right.color} />
        <TotalWrapper>{formattedTotal}</TotalWrapper>
      </Tooltip>
    </div>
  );
};

TwoPartBar.defaultProps = { suffix: '' };
TwoPartBar.propTypes = {
  barWidth: PropTypes.number.isRequired,
  left: PropTypes.shape({
    value: PropTypes.number,
    color: PropTypes.string
  }).isRequired,
  max: PropTypes.number.isRequired,
  right: PropTypes.shape({
    value: PropTypes.number,
    color: PropTypes.string
  }).isRequired,
  suffix: PropTypes.string,
  totalFormatter: PropTypes.func
};
