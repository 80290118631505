/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { findHierarchyNodesByProperty } from 'app/components/layout/components/sidebar/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

class TypeaheadField extends Component {
  constructor(props) {
    super(props);
    this.typeahead = React.createRef();
  }

  componentDidMount = () => {
    const { onRef = () => {} } = this.props;
    onRef(this.typeahead);
  };

  componentWillUnmount = () => {
    const { onRef = () => {} } = this.props;
    onRef(undefined);
  };

  handleTypeahead = value => {
    const { resetOnChange } = this.props;

    if (value) {
      this.props.handleChange(value);

      if (resetOnChange) {
        // We want to clear out the text of the field here so that the autocomplete becomes active
        // the next time a user focuses on the field. This removes the need for them to actually
        // clear the value of the field before being able to select another entry.
        this.reset();
      }
    }
  };

  reset = () => {
    if (this.typeahead.current) {
      const typeaheadField = this.typeahead.current.getInstance();
      typeaheadField.clear();
      typeaheadField.blur();
    }
  };

  render = () => {
    const {
      classes,
      disabled,
      filter,
      forwardedRef,
      handleTypeaheadFocus,
      id,
      labelKey,
      placeholder,
      selected = [],
      typeahead
    } = this.props;

    let typeaheadValues = [];

    if (typeahead && filter) {
      typeaheadValues = findHierarchyNodesByProperty(typeahead, {
        type: filter
      });
    }

    return (
      <Typeahead
        align="left"
        bsSize="small"
        className={classNames(classes)}
        disabled={disabled}
        id={id}
        labelKey={labelKey}
        onChange={sel => this.handleTypeahead(sel[0])}
        onFocus={handleTypeaheadFocus}
        options={typeaheadValues}
        placeholder={placeholder}
        ref={forwardedRef || this.typeahead}
        selected={selected}
        selectHintOnEnter={true}
      />
    );
  };
}

TypeaheadField.defaultProps = {
  placeholder: 'No category selected',
  resetOnChange: true
};

TypeaheadField.propTypes = {
  classes: PropTypes.array,
  disabled: PropTypes.bool,
  filter: PropTypes.string,
  forwardedRef: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleTypeaheadFocus: PropTypes.func,
  id: PropTypes.string.isRequired,
  labelKey: PropTypes.string.isRequired,
  onRef: PropTypes.func,
  placeholder: PropTypes.string,
  resetOnChange: PropTypes.bool,
  selected: PropTypes.array,
  typeahead: PropTypes.object
};

export default TypeaheadField;
