/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';

const {
  containerBorderColor,
  networkTabBgColour,
  propertyTabBgColour
} = styleLibrary;

export const TabContainer = styled.li.attrs({
  className: 'nav-item'
})`
  border-left: 1px solid ${containerBorderColor};
  border-top: 1px solid ${containerBorderColor};
  border-bottom: ${props =>
    props.isActive ? 'none' : `1px solid ${containerBorderColor}`};

  &.nav-item {
    margin-bottom: 0;
  }

  & .nav-link.active,
  & .nav-link,
  & .nav-link:hover {
    border: none;
    border-color: transparent;
  }

  & .nav-link {
    background-color: ${props =>
    props.level === 'Property' ? propertyTabBgColour : networkTabBgColour};
    outline: none;
  }

  & .nav-link.active,
  & .nav-link:hover {
    background-color: #fff;
  }

  &:last-child {
    border-right: 1px solid ${containerBorderColor};
  }
`;
TabContainer.displayName = 'TabContainer';

export const TabInner = styled.div`
  display: flex;
  flex-direction: column;
  color: ${styleLibrary.darkText};

  & .tab-level {
    font-size: 0.8rem;
  }
`;

export const TabFiller = styled.div`
  flex-grow: 1;
  border-bottom: 1px solid ${containerBorderColor};
`;

export function Tabs(props) {
  const { onTabChanged, selected, tabs } = props;

  return (
    <div className="d-flex pt-3 px-2">
      <ul
        className="nav nav-tabs border-bottom-0"
        data-test-label="tab-container"
      >
        {tabs.map(t => {
          const { renderer } = props;
          const isActive = t.id === selected;

          return renderer ? (
            renderer(t)
          ) : (
            <TabContainer
              key={`tab-${t.id}`}
              isActive={isActive}
              onClick={() => onTabChanged(t.id)}
            >
              <button
                className={`nav-link ${isActive ? 'active' : ''}`}
                data-test-label="tab-button"
              >
                <TabInner>
                  <strong>{t.label}</strong>
                </TabInner>
              </button>
            </TabContainer>
          );
        })}
      </ul>
      <TabFiller />
    </div>
  );
}

Tabs.propTypes = {
  onTabChanged: PropTypes.func.isRequired,
  renderer: PropTypes.func,
  selected: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.any.isRequired
    })
  ).isRequired
};
