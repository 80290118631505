/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { HR } from 'app/components/elements';
import { createShowPanelSelector } from 'app/redux/app';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import PropertyCards from './property-cards';
import PropertyUsersTable from './property-users-table';
import UniqueUsersTrendChart from './unique-users-trend-chart';

export const PropertyUsers = ({
  showPropertyCards,
  showUniqueUsersTrend,
  showPropertyUsersTable
}) => (
  <Fragment>
    {showPropertyCards && <PropertyCards />}
    {showPropertyCards && showUniqueUsersTrend && <HR />}
    {showUniqueUsersTrend && <UniqueUsersTrendChart />}
    {showUniqueUsersTrend && showPropertyUsersTable && <HR />}
    {showPropertyUsersTable && <PropertyUsersTable />}
  </Fragment>
);

PropertyUsers.propTypes = {
  showPropertyCards: PropTypes.bool,
  showUniqueUsersTrend: PropTypes.bool,
  showPropertyUsersTable: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('PropertyCards'),
  createShowPanelSelector('PropertyUniqueUsersTrend'),
  createShowPanelSelector('PropertyUsersTable'),

  (showPropertyCards, showUniqueUsersTrend, showPropertyUsersTable) => ({
    showPropertyCards,
    showUniqueUsersTrend,
    showPropertyUsersTable
  })
);

export default connect(mapStateToProps)(PropertyUsers);
