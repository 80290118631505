/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { NoDataToShow } from 'app/components/elements';
import GranularityFilter from 'app/components/filters/components/granularity-filter';
import SsidWlanFilter from 'app/components/filters/components/ssid-wlan-filter';
import { granularityDropdownItems } from 'app/components/filters/constants';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { FailedFetchStateHandler } from 'app/components/utility';
import { commonPlotProps, styleLibrary } from 'app/constants';
import {
  fetchPropertyUniqueClientsTrend,
  propertyUniqueClientsTrendFetchStateSelector,
  propertyUniqueClientsTrendSelector
} from 'app/redux/clients';
import {
  dateRangeFilterSelector,
  granularityListSelector
} from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import { formatApiDateTime, getGranularityForDateRange } from 'app/utils';
import { get, isArray, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import Statistics from '../statistics';
import { createXAxisTicks, formatDataPoints } from '../utils';

const plotKeyLabelMap = {
  cdr: 'Unique Clients (CDR: Authenticated)',
  mqtt: 'Unique Clients (Wireless: Authenticated & Unauthenticated)'
};

const initialState = {
  wlan: 'all',
  granularity: granularityDropdownItems._15MinutesGranularity.value
};

export class UniqueClientsTrendChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenPlotMap: {},
      ...initialState
    };
  }

  componentDidMount() {
    const { dateRangeFilter, fetchPropertyUniqueClientsTrend } = this.props;
    const newGranularity = getGranularityForDateRange(dateRangeFilter);
    this.setState({ ...initialState, granularity: newGranularity }, () =>
      fetchPropertyUniqueClientsTrend({
        wlanName: initialState.wlan,
        granularity: newGranularity
      })
    );
  }

  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilter,
      fetchPropertyUniqueClientsTrend
    } = this.props;
    const {
      selectedPath: prevPath,
      dateRangeFilter: prevDateRange
    } = prevProps;

    const propertyChanged =
      isSpecificNodeType(selectedPath, 'zonename') &&
      !isEqual(prevPath.id, selectedPath.id);
    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilter);

    if (propertyChanged || dateRangeChanged) {
      let newGranularity = getGranularityForDateRange(dateRangeFilter);
      this.setState({ ...initialState, granularity: newGranularity }, () =>
        fetchPropertyUniqueClientsTrend({
          wlanName: initialState.wlan,
          granularity: newGranularity
        })
      );
    }
  };

  isActivePlot = plotKey => {
    const label = plotKeyLabelMap[plotKey];
    return this.state.hiddenPlotMap[label] ? 'blank' : plotKey;
  };

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  renderUniqueClientsTrendChart = () => {
    const {
      dateRangeFilter,
      propertyUniqueClientsTrend: { data = [], info = {} }
    } = this.props;
    const { hiddenPlotMap, granularity } = this.state;

    const timezone = get(info, 'timezone', 'UTC');
    const isTimestampDateAndTime = [
      granularityDropdownItems._15MinutesGranularity.value,
      granularityDropdownItems._1HourGranularity.value
    ].includes(granularity);
    const dataToRender = formatDataPoints(
      data,
      timezone,
      'timestamp',
      isTimestampDateAndTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
    );
    const xAxisTicks = createXAxisTicks(
      data,
      dateRangeFilter,
      timezone,
      'timestamp',
      isTimestampDateAndTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
    );

    return (
      <ChartFlexContainer width="99%" height={300}>
        <LineChart data={dataToRender} margin={{ ...chartMargins, left: 15 }}>
          <CartesianGrid vertical={false} />
          <Legend
            content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
            inactivePlots={hiddenPlotMap}
            verticalAlign="top"
          />
          <XAxis
            dataKey="timestamp"
            label={{
              value: `Time (${timezone})`,
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            interval="preserveStartEnd"
            tick={tick}
            ticks={xAxisTicks}
            tickFormatter={t => formatApiDateTime(t, dateRangeFilter, timezone)}
            scale="time"
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            yAxisId="uniqueClientsTrend"
            label={{
              value: 'Count',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            // domain={[0, 'dataMax']}
            // scale="linear"
            allowDecimals={false}
            interval="preserveStartEnd"
          />
          {Object.entries(plotKeyLabelMap).map(([key, label], index) => (
            <Line
              key={`${label}-${index}`}
              yAxisId="uniqueClientsTrend"
              dataKey={this.isActivePlot(key)}
              name={label}
              stroke={styleLibrary.uniqueClientsColors[index]}
              {...commonPlotProps}
            />
          ))}
          <Tooltip
            content={
              <MultiLineTooltip
                labelFormatter={label =>
                  formatApiDateTime(
                    label,
                    dateRangeFilter,
                    timezone,
                    isTimestampDateAndTime
                  )
                }
                keys={Object.keys(plotKeyLabelMap)}
                units={['']}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
          />
        </LineChart>
      </ChartFlexContainer>
    );
  };

  handleGranularityChanged = selectedGranularity => {
    const { fetchPropertyUniqueClientsTrend } = this.props;
    const { wlan } = this.state;
    this.setState({ granularity: selectedGranularity }, () =>
      fetchPropertyUniqueClientsTrend({
        wlanName: wlan,
        granularity: selectedGranularity
      })
    );
  };

  handleWlanChanged = selectedWlan => {
    const { fetchPropertyUniqueClientsTrend } = this.props;
    const { granularity } = this.state;
    this.setState({ wlan: selectedWlan }, () =>
      fetchPropertyUniqueClientsTrend({ wlanName: selectedWlan, granularity })
    );
  };

  renderDropdowns = () => (
    <div className="d-flex justify-content-between align-items-center mx-2 mt-2 mb-0">
      <GranularityFilter
        granularity={this.state.granularity}
        granularityList={this.props.granularityList}
        handleGranularityChanged={this.handleGranularityChanged}
        showLabel={false}
      />
      <SsidWlanFilter
        wlan={this.state.wlan}
        handleWlanChanged={this.handleWlanChanged}
      />
    </div>
  );

  render = () => {
    const {
      fetchState,
      propertyUniqueClientsTrend: { data = [], stats = {} },
      fetchPropertyUniqueClientsTrend
    } = this.props;
    const { wlan, granularity } = this.state;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    const statsArray = [
      {
        title: Object.values(plotKeyLabelMap)[0],
        max: get(stats, 'maxCDRClient', 0),
        min: get(stats, 'minCDRClient', 0),
        avg: get(stats, 'avgCDRClient', 0)
      },
      {
        title: Object.values(plotKeyLabelMap)[1],
        max: get(stats, 'maxMQTTClient', 0),
        min: get(stats, 'minMQTTClient', 0),
        avg: get(stats, 'avgMQTTClient', 0)
      }
    ];

    return (
      <Fragment>
        {this.renderDropdowns()}
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() =>
            fetchPropertyUniqueClientsTrend({ wlanName: wlan, granularity })
          }
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message="No data available"
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            complete &&
            hasData && (
              <Fragment>
                {this.renderUniqueClientsTrendChart()}
                <Statistics
                  data={statsArray}
                  colors={styleLibrary.uniqueClientsColors}
                />
              </Fragment>
            )
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

UniqueClientsTrendChart.propTypes = {
  dateRangeFilter: PropTypes.object,
  selectedPath: PropTypes.object,
  fetchPropertyUniqueClientsTrend: PropTypes.func,
  propertyUniqueClientsTrend: PropTypes.object,
  fetchState: fetchStatePropTypes,
  granularityList: PropTypes.array
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  selectedPathSelector,
  propertyUniqueClientsTrendFetchStateSelector,
  propertyUniqueClientsTrendSelector,
  granularityListSelector,
  (
    dateRangeFilter,
    selectedPath,
    fetchState,
    propertyUniqueClientsTrend,
    granularityList
  ) => ({
    dateRangeFilter,
    selectedPath,
    fetchState,
    propertyUniqueClientsTrend,
    granularityList
  })
);

const mapDispatchToProps = { fetchPropertyUniqueClientsTrend };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UniqueClientsTrendChart);
