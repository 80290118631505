/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { BlockContainer } from 'app/components/elements';
import { StatefulTable } from 'app/components/tables';
import { MuiTableOptions, MuiTableTheme } from 'app/constants';
import { size } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ModalBox, ModalWrapper } from './components';

class AlarmActions extends Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleNonModalClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleNonModalClick);
  }

  handleNonModalClick = e => {
    if (!this.modalRef.current.contains(e.target)) {
      this.props.closeModal();
    }
  };

  render() {
    const { closeModal, actions } = this.props;
    const isSizeThreshold = size(actions) > 10;

    return (
      <ModalWrapper>
        <div
          className={
            'd-flex justify-content-center align-items-center flex-column h-100'
          }
        >
          <ModalBox ref={this.modalRef} modalWidth="auto">
            <BlockContainer minHeight={isSizeThreshold ? '160px' : '0'}>
              <MuiThemeProvider theme={createMuiTheme(MuiTableTheme)}>
                <StatefulTable
                  tableKey="alarm-actions"
                  data={actions}
                  columns={[
                    {
                      name: 'ack_date',
                      label: 'Date'
                    },
                    {
                      name: 'name',
                      label: 'Name'
                    },
                    {
                      name: 'message',
                      label: 'Message'
                    }
                  ]}
                  options={{
                    ...MuiTableOptions,
                    pagination: isSizeThreshold,
                    rowsPerPageOptions: [10]
                  }}
                />
              </MuiThemeProvider>
            </BlockContainer>
            <button
              className="btn btn-primary mt-3"
              onClick={() => closeModal()}
            >
              Close
            </button>
          </ModalBox>
        </div>
      </ModalWrapper>
    );
  }
}

AlarmActions.propTypes = {
  closeModal: PropTypes.func.isRequired,
  actions: PropTypes.array.isRequired
};

export default AlarmActions;
