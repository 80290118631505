/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
// import TrendChartLegendForBar from 'app/components/charts/trend-legend-for-bar';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { styleLibrary } from 'app/constants';
import { vertSSIDFilterSelector } from 'app/redux/filters';
import DateRangeFilter from 'app/components/filters/components/date-range-filter';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  fetchPropertyTrendStatus,
  propertyTrendFetchStateSelector,
  propertyTrendStatusSelector
} from 'app/redux/reports';
import { fetchStatePropTypes } from 'app/redux/utils';
import { getReadableTimeFormat } from 'app/utils';
import { isArray, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import Filters from 'app/components/filters';
import { defaultDateSelectionForReports } from 'app/components/filters/constants';

const title = 'Property Count';
const plotKeyLabelMap = {
  // propertyCount: 'Property Count',
  EDU: 'EDU',
  ENT: 'ENT',
  HOS: 'HOS',
  SMB: 'SMB',
  MDU: 'MDU',
  MXU: 'MXU',
  PWF: 'PWF'
};

const initialState = {
  isDateOpen: false,
  dateRange: defaultDateSelectionForReports
};
export class PropertyCountTrend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenPlotMap: {},
      ...initialState,
      dateRange: initialState.dateRange
    };
  }

  componentDidMount = () => {
    const { dateRange } = this.state;
    this.props.fetchPropertyTrendStatus({ dateRange });
  };

  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilterLabel,
      fetchPropertyTrendStatus,
      vertSSIDFilter
    } = this.props;
    const {
      selectedPath: prevPath,
      dateRangeFilterLabel: prevDateRange,
      vertSSIDFilter: prevVertSSIDFilter
    } = prevProps;

    const nodeChanged = !isEqual(prevPath.id, selectedPath.id);
    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilterLabel);
    const vertSSIDFilterChanged = !isEqual(prevVertSSIDFilter, vertSSIDFilter);

    if (nodeChanged || dateRangeChanged || vertSSIDFilterChanged) {
      const dateRange = initialState.dateRange;
      this.setState(
        {
          ...initialState,
          dateRange
        },
        () => {
          fetchPropertyTrendStatus({ dateRange });
        }
      );
    }
  };

  isActivePlot = plotKey => {
    const label = plotKeyLabelMap[plotKey];
    return this.state.hiddenPlotMap[label] ? `hidden-${plotKey}` : plotKey;
  };

  togglePlotVisibility = dataKey => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [dataKey]: !hiddenPlotMap[dataKey]
      }
    }));
  };

  renderPropertyCountTrend = data => {
    const { hiddenPlotMap } = this.state;
    const { vertSSIDFilter } = this.props;

    const selectedVerticalValue = vertSSIDFilter['vertical'];
    let selectedVerticalKeys;

    if (selectedVerticalValue === 'all') {
      selectedVerticalKeys = Object.keys(plotKeyLabelMap);
    } else {
      selectedVerticalKeys = [selectedVerticalValue];
    }

    const filteredData = data.map(entry => ({
      ...entry,
      ...selectedVerticalKeys.reduce((acc, key) => {
        acc[key] = entry[this.isActivePlot(key)];
        return acc;
      }, {})
    }));

    const getColor = category => {
      const verticalsColor = styleLibrary.propertyTrendColors;
      const verticalLabels = ['EDU', 'ENT', 'HOS', 'SMB', 'MDU', 'MXU', 'PWF'];
      const index = verticalLabels.indexOf(category);
      if (index >= 0 && index < verticalsColor.length) {
        return verticalsColor[index];
      } else {
        return '#0070b2';
      }
    };

    const legends = selectedVerticalKeys.map(dataKey => ({
      dataKey: dataKey,
      label: plotKeyLabelMap[dataKey],
      stroke: getColor(dataKey)
    }));

    return (
      <ChartFlexContainer width="99%" height={250}>
        <BarChart
          data={filteredData}
          margin={{ ...chartMargins, left: 15, top: 15 }}
          barCategoryGap={5}
          barSize={25}
          height={data.length * 1000}
        >
          <CartesianGrid vertical={false} />
          <Legend
            content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
            inactivePlots={hiddenPlotMap}
            verticalAlign="top"
          />
          {/* <Legend
            content={
              <TrendChartLegendForBar
                onClick={this.togglePlotVisibility}
                legends={legends}
                inactivePlots={hiddenPlotMap}
              />
            }
            verticalAlign="top"
            height={36}
          /> */}
          <XAxis
            dataKey="dt"
            label={{
              value: 'Date',
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            interval="preserveStartEnd"
            tick={tick}
            tickFormatter={t => getReadableTimeFormat(t)}
            domain={['dataMin', 'dataMax']}
            type="category"
            scale="point"
            padding={{ left: 12, right: 10 }}
          />
          <YAxis
            yAxisId="propertyCountTrend"
            label={{
              value: 'Count',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            allowDecimals={false}
            interval="preserveStartEnd"
            domain={['0', 'dataMax']}
          />
          {legends.map(({ dataKey, label }) => (
            <Bar
              key={dataKey}
              yAxisId="propertyCountTrend"
              dataKey={this.isActivePlot(dataKey)}
              name={label}
              stroke={getColor(dataKey)}
              fill={getColor(dataKey)}
              stackId="propertyCount"
              maxBarSize={100}
            ></Bar>
          ))}
          <Tooltip
            content={
              <MultiLineTooltip
                labelFormatter={label => getReadableTimeFormat(label)}
                keys={Object.keys(plotKeyLabelMap)}
                units={['']}
                showAtSign={false}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
            cursor={{ fill: 'rgba(0, 0, 255, 0.1)' }}
          />
        </BarChart>
      </ChartFlexContainer>
    );
  };

  handleDateChanged = dateRange => {
    const { fetchPropertyTrendStatus } = this.props;
    this.setState({ ...initialState, dateRange }, () => {
      fetchPropertyTrendStatus({
        dateRange
      });
    });
  };

  renderDateRangeDropdowns = () => {
    const { excludeLast24Hours } = this.props;

    return (
      <div className="d-flex justify-content-end align-items-center mx-2">
        <div className="d-flex align-items-center">
          <DateRangeFilter
            dateRangeFilter={this.state.dateRange}
            isDropDownOpen={this.state.isDateOpen}
            updateDateRangeFilter={this.handleDateChanged}
            updateIsOpen={isDateOpen => this.setState({ isDateOpen })}
            buttonStyle={{
              backgroundColor: styleLibrary.containerBg,
              border: '1px solid #ced4da'
            }}
            excludeLast24Hours={excludeLast24Hours}
          />
        </div>
      </div>
    );
  };

  render = () => {
    const {
      fetchState,
      propertyTrendChart: { data = [] },
      fetchPropertyTrendStatus
    } = this.props;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <Fragment>
        <div className="d-flex justify-content-between mt-2">
          <h4 className="d-flex justify-content-start align-items-center ml-2 mt-2 mb-0">
            {title}
          </h4>
          <div className="d-flex justify-content-end mt-2">
            <Filters showVerticalFilter={true} showDateRangeFilter={false} />
            {this.renderDateRangeDropdowns()}
          </div>
        </div>
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={fetchPropertyTrendStatus}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message="No data available"
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            complete && hasData && this.renderPropertyCountTrend(data)
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

PropertyCountTrend.propTypes = {
  excludeLast24Hours: PropTypes.bool,
  propertyTrendChart: PropTypes.object,
  fetchState: fetchStatePropTypes,
  fetchPropertyTrendStatus: PropTypes.func,
  vertSSIDFilter: PropTypes.object,
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object
};

const mapStateToProps = createSelector(
  vertSSIDFilterSelector,
  propertyTrendFetchStateSelector,
  propertyTrendStatusSelector,
  selectedPathSelector,
  dateRangeFilterLabelSelector,
  (
    vertSSIDFilter,
    fetchState,
    propertyTrendChart,
    selectedPath,
    dateRangeFilterLabel
  ) => ({
    vertSSIDFilter,
    fetchState,
    propertyTrendChart,
    selectedPath,
    dateRangeFilterLabel
  })
);

const mapDispatchToProps = { fetchPropertyTrendStatus };

export default connect(mapStateToProps, mapDispatchToProps)(PropertyCountTrend);
