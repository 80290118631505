/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
/* eslint-disable camelcase */
import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { styleLibrary } from 'app/constants';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  fetchInventoryTrendStatus,
  inventoryTrendFetchStateSelector,
  inventoryTrendStatusSelector
} from 'app/redux/reports';
import { fetchStatePropTypes } from 'app/redux/utils';
import { getReadableTimeFormat } from 'app/utils';
import { isArray, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import ModelDropdownFilter from './model-dropdown-filter';
import DateRangeFilter from 'app/components/filters/components/date-range-filter';
import { defaultDateSelectionForReports } from 'app/components/filters/constants';

const deviceType = ['All', 'Switches', 'AP', 'Servers', 'Router', 'Firewall'];

const deviceTypeMapping = {
  All: 'All',
  Switches: 'Switches',
  AP: 'Access Point',
  Servers: 'Servers',
  Router: 'Routers',
  Firewall: 'Firewalls'
};

const transformedDeviceType = deviceType.map(
  type => deviceTypeMapping[type] || type
);

const plotKeyLabelMap = {
  total_device: 'All Devices',
  total_switches: 'Switches',
  total_ap: 'Access Point',
  total_router: 'Routers',
  total_servers: 'Servers',
  total_firewall: 'Firewalls'
};

const initialState = {
  isDateOpen: false,
  dateRange: defaultDateSelectionForReports
};

class InventoryModelTrend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenPlotMap: {},
      selectedModel: 'all',
      deviceModelData: [],
      selectedDeviceType: 'All',
      ...initialState,
      dateRange: initialState.dateRange
    };
  }

  componentDidMount = () => {
    const { fetchInventoryTrendStatus } = this.props;
    const { dateRange } = this.state;
    fetchInventoryTrendStatus({ dateRange });
  };

  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilterLabel,
      fetchInventoryTrendStatus
    } = this.props;
    const {
      selectedPath: prevPath,
      dateRangeFilterLabel: prevDateRange
    } = prevProps;

    const nodeChanged = !isEqual(prevPath.id, selectedPath.id);
    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilterLabel);

    if (nodeChanged || dateRangeChanged) {
      const dateRange = initialState.dateRange;
      this.setState(
        {
          selectedDeviceType: 'All',
          selectedModel: 'all',
          ...initialState,
          dateRange
        },
        () => {
          fetchInventoryTrendStatus({ dateRange });
        }
      );
    }
  };

  isActivePlot = plotKey => {
    const label = plotKeyLabelMap[plotKey];
    return this.state.hiddenPlotMap[label] ? 'blank' : plotKey;
  };

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  getModelCounts = data => {
    const { selectedDeviceType } = this.state;
    if (Array.isArray(data)) {
      const countsByDate = {};
      data.forEach(item => {
        const { dt } = item;

        if (!countsByDate[dt]) {
          countsByDate[dt] = {};
        }
        Object.keys(item).forEach(key => {
          if (key.startsWith('total_')) {
            const deviceType = key.replace('total_', '');

            // Check if the device type matches the selected device or if it's "All"
            if (
              selectedDeviceType === 'All' ||
              deviceType === selectedDeviceType.toLowerCase()
            ) {
              const totalCountKey =
                selectedDeviceType === 'All' ? 'total_device' : `total_${deviceType}`;
              countsByDate[dt][totalCountKey] =
                (countsByDate[dt][totalCountKey] || 0) + (item[key] || 0);
            }
          }
        });
      });
      // Convert the countsByDate object into an array
      const filteredResult = Object.keys(countsByDate).map(dt => {
        const obj = { dt, ...countsByDate[dt] };
        return obj;
      });
      return filteredResult;
    } else {
      return null;
    }
  };

  getDeviceLabels = data => {
    const { selectedDeviceType } = this.state;
    const labels = [];

    if (Array.isArray(data)) {
      data.forEach(item => {
        Object.keys(item).forEach(key => {
          if (key.startsWith('total_') && !labels.includes(key)) {
            // Only push labels corresponding to the selected device type
            if (
              selectedDeviceType === 'All' ||
              key.includes(selectedDeviceType.toLowerCase())
            ) {
              labels.push(key);
            }
          }
        });
      });

      // Include the 'total' label when selectedDeviceType is 'All'
      if (selectedDeviceType === 'All' && !labels.includes('total_device')) {
        labels.push('total_device');
      }
    }

    return labels;
  };

  getLabelForSelectedDevice = label => {
    const { selectedDeviceType } = this.state;
    const mappedLabel =
      selectedDeviceType === 'All'
        ? 'All Devices'
        : plotKeyLabelMap[label] || label.replace('total_', '');
    return mappedLabel;
  };

  handleDeviceTypeChange = e => {
    const newDevice = e.target.value;

    this.setState(
      { selectedDeviceType: newDevice, selectedModel: 'all' },
      () => {
        this.props.fetchInventoryTrendStatus(newDevice);
      }
    );
  };

  handleDeviceModelChange = e => {
    const selectedModel = e.target.value;
    const {
      inventoryTrendStatus: { data = [] }
    } = this.props;
    const { selectedDeviceType } = this.state;

    const deviceData = data.filter(item =>
      selectedDeviceType.includes(item.device)
    );
    const updatedData = [];
    deviceData.forEach(item => {
      const { dt } = item;
      const obj = { dt };

      if (Array.isArray(selectedDeviceType)) {
        selectedDeviceType.forEach(device => {
          obj[`total_${device.toLowerCase()}`] = item[selectedModel];
        });
      } else {
        obj[`total_${selectedDeviceType.toLowerCase()}`] = item[selectedModel];
      }
      updatedData.push(obj);
    });

    this.setState({ selectedModel, deviceModelData: updatedData });
  };

  DeviceTypeDropdown = ({ value, onChange }) => {
    return (
      <Fragment>
        <label className="col-form-label-sm pr-2 mb-0">Device:</label>
        <select
          className="form-control"
          value={value}
          onChange={onChange}
          style={{ width: '190px' }}
        >
          {transformedDeviceType.map(type => (
            <option
              key={type}
              value={deviceType.find(
                device => deviceTypeMapping[device] === type
              )}
            >
              {type}
            </option>
          ))}
        </select>
      </Fragment>
    );
  };

  renderDeviceModelCount = filteredData => {
    const { hiddenPlotMap, selectedDeviceType } = this.state;

    const deviceLabels = this.getDeviceLabels(filteredData, selectedDeviceType);

    if (
      !filteredData ||
      filteredData.every(series =>
        deviceLabels.every(label => series[label] === 0)
      )
    ) {
      return (
        <NoDataToShow
          icon="error_outline"
          message="No data available for the selected device and model"
          style={{
            background: 'none',
            position: 'relative',
            height: 'auto'
          }}
        />
      );
    }

    return (
      <ChartFlexContainer width="99%" height={250}>
        <ComposedChart
          data={filteredData}
          margin={{ ...chartMargins, left: 15 }}
        >
          <defs>
            <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#758BFD" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#758BFD" stopOpacity={0} />
            </linearGradient>
          </defs>

          <CartesianGrid vertical={false} />
          <Legend
            content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
            inactivePlots={hiddenPlotMap}
            verticalAlign="top"
          />
          <XAxis
            dataKey="dt"
            label={{
              value: 'Date',
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            interval="preserveStartEnd"
            tick={tick}
            tickFormatter={t => getReadableTimeFormat(t)}
            domain={['dataMin', 'dataMax']}
            scale="point"
            type="category"
          />
          <YAxis
            yAxisId="inventoryModelCount"
            label={{
              value: 'Count',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            domain={[0, 'auto']}
            allowDecimals={false}
          />
          {deviceLabels.map((label, index) => {
            const transformedLabel = this.getLabelForSelectedDevice(
              label,
              selectedDeviceType
            );

            return (
              <Area
                key={`${label}-${index}`}
                yAxisId="inventoryModelCount"
                dataKey={this.isActivePlot(label)}
                name={transformedLabel}
                stroke="#758BFD"
                fill="url(#color)"
              />
            );
          })}

          <Tooltip
            content={
              <MultiLineTooltip
                labelFormatter={label => getReadableTimeFormat(label)}
                keys={Object.keys(plotKeyLabelMap)}
                units={['']}
                showAtSign={false}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
          />
        </ComposedChart>
      </ChartFlexContainer>
    );
  };

  handleDateChanged = dateRange => {
    const { fetchInventoryTrendStatus } = this.props;
    this.setState(
      { ...initialState, dateRange  },
      () => {
        fetchInventoryTrendStatus({
          dateRange
        });
      }
    );
  };

  renderDateRangeDropdowns = () => {
    const { excludeLast24Hours } = this.props;

    return (
      <div className="d-flex justify-content-end align-items-center mx-2">
        <div className="d-flex align-items-center">
          <DateRangeFilter
            dateRangeFilter={this.state.dateRange}
            isDropDownOpen={this.state.isDateOpen}
            updateDateRangeFilter={this.handleDateChanged}
            updateIsOpen={isDateOpen => this.setState({ isDateOpen })}
            buttonStyle={{
              backgroundColor: styleLibrary.containerBg,
              border: '1px solid #ced4da'
            }}
            excludeLast24Hours={excludeLast24Hours}
          />
        </div>
      </div>
    );
  };

  render = () => {
    const { selectedModel, deviceModelData } = this.state;
    const {
      fetchState,
      inventoryTrendStatus: { data = [] },
      fetchInventoryTrendStatus
    } = this.props;

    const { pending, complete } = fetchState;

    const { selectedDeviceType } = this.state;

    const filteredData =
      selectedModel === 'all' ? this.getModelCounts(data) : deviceModelData;
    const hasData = isArray(filteredData) && !isEmpty(filteredData);
    const noDataToShow = !pending && complete && !hasData;

    let modelOptions;
    if (selectedDeviceType === 'All') {
      modelOptions = [];
    } else {
      // Get options specific to the selected device
      const deviceData = data.filter(
        entry => entry.device === selectedDeviceType
      );

      modelOptions =
        deviceData.length > 0
          ? Object.keys(deviceData[0]).filter(
            key =>
              key !== 'dt' &&
                key !== 'device' &&
                !key.startsWith('total_') &&
                key !== '' &&
                !key.includes('unable to resolve')
          )
          : [];
    }

    return (
      <Fragment>
        <div className="d-flex justify-content-between align-items-center mx-2 my-0 mb-2 mt-2">
          <h4 className="mr-auto">Device Count</h4>
          <div className="d-flex align-items-center">
            <this.DeviceTypeDropdown
              value={selectedDeviceType}
              onChange={this.handleDeviceTypeChange}
            />
            <span className="mr-4"></span>
            <ModelDropdownFilter
              label="Model"
              id="device-models"
              value={selectedModel}
              onChange={this.handleDeviceModelChange}
              options={modelOptions}
            />
          </div>
          {this.renderDateRangeDropdowns()}
        </div>
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={fetchInventoryTrendStatus}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message="No data available for the selected device and model"
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            complete && hasData && this.renderDeviceModelCount(filteredData)
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

InventoryModelTrend.propTypes = {
  excludeLast24Hours: PropTypes.bool,
  dateRangeFilterLabel: PropTypes.string.isRequired,
  fetchState: fetchStatePropTypes,
  fetchInventoryTrendStatus: PropTypes.func.isRequired,
  inventoryTrendStatus: PropTypes.shape({
    data: PropTypes.array
  }).isRequired,
  selectedPath: PropTypes.object.isRequired
};

const mapStateToProps = createSelector(
  inventoryTrendStatusSelector,
  inventoryTrendFetchStateSelector,
  selectedPathSelector,
  dateRangeFilterLabelSelector,
  (inventoryTrendStatus, fetchState, selectedPath, dateRangeFilterLabel) => ({
    inventoryTrendStatus,
    fetchState,
    selectedPath,
    dateRangeFilterLabel
  })
);

const mapDispatchToProps = {
  fetchInventoryTrendStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryModelTrend);
