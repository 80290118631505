/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  MuiTableOptions,
  MuiTableTheme,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import { isArray, isEmpty, merge } from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

export const titleMargin = { marginBottom: 10 };
export const heightStyle = { height: '2.3em !important' };

export const incidentStyle = {
  incidentClors: {
    disaster: '#dc3545',
    high: '#ff8000',
    normal: '#17a2b8'
  }
};

export const customTableOptions = merge({}, MuiTableOptions, {
  filterType: 'checkbox',
  selectableRows: 'none',
  viewColumns: true,
  responsive: 'stacked',
  search: true,
  // searchOpen: true,
  serverSide: false,
  fixedHeaderOptions: { yAxis: true }
});

export const customMuiTableTheme = merge({}, MuiTableTheme, {
  overrides: {
    MuiTableCell: {
      head: {
        borderWidth: '1px 0 1px 0'
      },
      root: {
        padding: '15px 15px'
      }
    },
    MUIDataTableToolbar: {
      root: {
        display: 'flex'
      }
    },
    MuiPopover: {
      paper: {
        width: '15%'
      }
    }
  }
});
export const customMuiTableThemeWithCursor = merge(
  {},
  customMuiTableTheme,
  customMuiTableThemeWithDefualtCursor
);

export const incidentSummaryTableColumns = [
  { name: 'accountid', label: 'Account ID', options: { display: false } },
  { name: 'casenumber', label: 'Case Number' },
  { name: 'caserigin', label: 'Case Origin', options: { display: false } },
  { name: 'casetitle', label: 'Case Title', options: { display: false } },
  { name: 'casetype', label: 'Case Type' },
  {
    name: 'caseopenedbyname',
    label: 'Case Opened By Name',
    options: { display: false }
  },
  {
    name: 'caseopenedbyteam',
    label: 'Case Opened By Team',
    options: { display: false }
  },
  { name: 'caseowner', label: 'Case Owner', options: { display: false } },
  { name: 'casestage', label: 'Case Stage', options: { display: false } },
  { name: 'childcases', label: 'Child Cases', options: { display: false } },
  // { name: 'customerid', label: 'Customer ID', options: { display: false } },
  { name: 'contact', label: 'Contact' },
  // { name: 'contactid', label: 'Contact ID', options: { display: false } },
  // { name: 'contactname', label: 'Contact Name', options: { display: false } },
  {
    name: 'firstname',
    label: 'Contact First Name',
    options: { display: false }
  },
  {
    name: 'lastname',
    label: 'Contact Last Name',
    options: { display: false }
  },
  {
    name: 'telephone',
    label: 'Contact Telephone',
    options: { display: false }
  },
  {
    name: 'mobilephone',
    label: 'Contact Secondary Phone',
    options: { display: false }
  },
  {
    name: 'email',
    label: 'Contact Email',
    options: { display: false }
  },
  {
    name: 'address1',
    label: 'Contact Address Line 1',
    options: { display: false }
  },
  {
    name: 'address2',
    label: 'Contact Address Line 2: Building & Floor',
    options: { display: false }
  },
  {
    name: 'address3',
    label: 'Contact Address Line 3: Unit',
    options: { display: false }
  },
  {
    name: 'city',
    label: 'Contact City',
    options: { display: false }
  },
  {
    name: 'state',
    label: 'Contact State/Province',
    options: { display: false }
  },
  {
    name: 'zip',
    label: 'Contact ZIP',
    options: { display: false }
  },
  { name: 'cursumnextaction', label: 'Current Summary And Next Actions' },
  {
    name: 'datetimeopened',
    label: 'Created On',
    options: { sortDirection: 'desc' }
  },
  { name: 'agedays', label: 'Duration', options: { display: false } },
  { name: 'description', label: 'Description', options: { display: false } },
  { name: 'devicesimpacted', label: 'Devices Impacted' },
  { name: 'engagetype', label: 'Engagement Type', options: { display: false } },
  {
    name: 'enduserdevicemac',
    label: 'End User Device MAC',
    options: { display: true }
  },
  {
    name: 'enduserdevicemanf',
    label: 'End User Device Manufacturer',
    options: { display: false }
  },
  {
    name: 'enduserdevicemodel',
    label: 'End User Device Model',
    options: { display: false }
  },
  {
    name: 'enduserdeviceos',
    label: 'End User Device OS',
    options: { display: false }
  },
  { name: 'escalationlevel', label: 'Escalation Level' },
  { name: 'escalatedon', label: 'Escalated On', options: { display: false } },
  {
    name: 'externalticket',
    label: 'External Ticket #',
    options: { display: false }
  },
  {
    name: 'firstresponseby',
    label: 'First Response By',
    options: { display: false }
  },
  {
    name: 'firstresponsebykpi',
    label: 'First Response By KPI',
    options: { display: false }
  },
  {
    name: 'firstresponsebysla',
    label: 'First Response By SLA',
    options: { display: false }
  },
  { name: 'followupby', label: 'Follow Up By', options: { display: false } },
  {
    name: 'grandparentaccount',
    label: 'Grand Parent Account',
    options: { display: false }
  },
  { name: 'isescalated', label: 'Is Escalated', options: { display: false } },
  { name: 'incidentid', label: 'Incident ID', options: { display: false } },
  { name: 'impact', label: 'Impact' },
  { name: 'issuestart', label: 'Issue Start' },
  {
    name: 'issuereported',
    label: 'Issue Reported',
    options: { display: false }
  },
  {
    name: 'issueresolved',
    label: 'Issue Resolved',
    options: { display: false }
  },
  { name: 'jiraid', label: 'Jira ID', options: { display: false } },
  {
    name: 'lastmodifiedbyname',
    label: 'Last Modified By Name',
    options: { display: false }
  },
  {
    name: 'datatimelastmodified',
    label: 'Modified On',
    options: { display: false }
  },
  { name: 'ownerteam', label: 'Owner Team', options: { display: false } },
  {
    name: 'parentcaseidval',
    label: 'Parent Case Title',
    options: { display: false }
  },
  {
    name: 'parentaccount',
    label: 'Parent Account',
    options: { display: false }
  },
  { name: 'property', label: 'Property' },
  { name: 'prioritycode', label: 'Priority', options: { display: false } },

  { name: 'rootcause', label: 'Root Cause', options: { display: false } },
  {
    name: 'recoverydatetime',
    label: 'Recovery Date Time',
    options: { display: false }
  },
  {
    name: 'resolutiontype',
    label: 'Resolution Action(s)',
    options: { display: false }
  },
  {
    name: 'resolutiondate',
    label: 'Resolution Date',
    options: { display: false }
  },
  // { name: 'realmid', label: 'Realm ID', options: { display: false } },
  { name: 'resolveby', label: 'Resolve By', options: { display: false } },
  {
    name: 'resolvebykpi',
    label: 'Resolve By KPI',
    options: { display: false }
  },
  {
    name: 'resolvebysla',
    label: 'Resolve By SLA',
    options: { display: false }
  },

  { name: 'subject', label: 'Subject' },
  { name: 'servicetype', label: 'Service Type', options: { display: false } },
  { name: 'severity', label: 'Severity', options: { display: false } },
  { name: 'sla', label: 'SLA', options: { display: false } },
  { name: 'status', label: 'Status', options: { display: false } },
  { name: 'statusreason', label: 'Status Reason' },
  { name: 'stagestatus', label: 'Stage Status', options: { display: false } },
  {
    name: 'truckrollneeded',
    label: 'Truck Roll Needed',
    options: { display: false }
  },
  { name: 'vertical', label: 'Vertical', options: { display: false } }
];

export const incidentActivityTableColumns = [
  // { name: 'activityID', label: 'Activity ID', options: { display: false } },
  // { name: 'sla', label: 'SLA Value', options: { display: false } },
  { name: 'statusReason', label: 'Status Code' },
  { name: 'subject', label: 'Subject' },
  {
    name: 'datetimeOpened',
    label: 'Created On',
    options: { sortDirection: 'desc' }
  },
  { name: 'datatimeLastModified', label: 'Modified On' },
  { name: 'status', label: 'State Code' },
  {
    name: 'activityType',
    label: 'Activity Type Code'
  },
  { name: 'priorityCode', label: 'Priority Code' },
  { name: 'actualEnd', label: 'Actual End' },
  { name: 'description', label: 'Description' },
  // {
  //   name: 'deliveryPriorityCode',
  //   label: 'Delivery Priority Code',
  //   options: { display: false }
  // },
  // {
  //   name: 'instanceTypeCode',
  //   label: 'Instance Type Code',
  //   options: { display: false }
  // },
  // {
  //   name: 'regardingObjID',
  //   label: 'Regarding Object ID',
  //   options: { display: false }
  // },
  { name: 'caseOwner', label: 'Case Owner' }
  // {
  //   name: 'lastModifiedbyName',
  //   label: 'Last Modified By Name',
  //   options: { display: false }
  // },
  // {
  //   name: 'caseOpenedbyName',
  //   label: 'Case Opened By Name',
  //   options: { display: false }
  // }
];

// export const byPropertyColumns = [
//   { name: 'zonename', label: 'Property' },
//   { name: 'total', label: '#Incidents' },
//   {
//     name: 'P1',
//     label: 'P1'
//   },
//   {
//     name: 'P2',
//     label: 'P2'
//   },
//   {
//     name: 'P3',
//     label: 'P3'
//   },
//   {
//     name: 'P4',
//     label: 'P4'
//   }
// ];

const possibleDateTimeFormats = [
  moment.ISO_8601,
  'YYYY-MM-DD HH:mm'
  // 'MM/DD/YYYY HH:mm A',
  // 'M/D/YYYY h:mm A'
];
const timezone = 'UTC';
const transformedDateTimeFormat = 'YYYY-MM-DD HH:mm';

const transformDateTime = dateTime => {
  if (isEmpty(dateTime)) {
    return '';
  }

  let formattedDateTime = '';
  if (moment(dateTime).isValid()) {
    formattedDateTime = momentTimezone
      .tz(dateTime, timezone)
      .format(transformedDateTimeFormat);
  } else {
    formattedDateTime = momentTimezone
      .tz(dateTime, possibleDateTimeFormats, timezone)
      .format(transformedDateTimeFormat);
  }
  return formattedDateTime === 'Invalid date' ? dateTime : formattedDateTime;
};

export const incidentSummaryTransform = data => {
  if (!isArray(data) || isEmpty(data)) {
    return [];
  }

  const dateTimeColumnNames = [
    'datetimeopened',
    'issuestart',
    'issuereported',
    'issueresolved',
    'datatimelastmodified',
    'recoverydatetime',
    'resolutiondate'
  ];

  return data.map(d => {
    dateTimeColumnNames.forEach(c => (d[c] = transformDateTime(d[c])));
    return d;
  });
};

export const incidentActivityTransform = data => {
  if (!isArray(data) || isEmpty(data)) {
    return [];
  }

  const dateTimeColumnNames = incidentActivityTableColumns
    .filter(c => c.label.includes('(UTC)'))
    .map(c => c.name);

  return data.map(d => {
    dateTimeColumnNames.forEach(c => (d[c] = transformDateTime(d[c])));
    return d;
  });
};
