/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isAjaxTimeoutError } from 'app/custom-errors';
import { buildApiRequestArgs } from 'app/redux/ajax-saga-utils';
import {
  filterParamsSelector,
  hostMetricFilterSelector
} from 'app/redux/filters';
import {
  createApiBodySelector,
  selectedPathSelector
} from 'app/redux/hierarchy';
import { ajaxPost, debounceSaga } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutFetchState
} from 'app/redux/utils';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchZabbixDataTrendComplete,
  fetchZabbixDataTrendError,
  fetchZabbixElementReportsComplete,
  fetchZabbixElementReportsError,
  updateZabbixDataTrendFetchState,
  updateZabbixElementReportsFetchState,
  zabbixDataTrendSelector,
  zabbixElementReportsSelector
} from './';

const getZabbixElementReportsUrl = '/api/device/getZabbixElementReports';
const getZabbixDataTrendUrl = '/api/device/getZabbixDataTrend';

export function* fetchZabbixElementReportsSaga({ payload }) {
  yield put(updateZabbixElementReportsFetchState(pendingFetchState));

  const currentDataSet = yield select(zabbixElementReportsSelector);
  yield call(debounceSaga, currentDataSet);

  const [{ url, options }] = yield call(
    buildApiRequestArgs,
    [getZabbixElementReportsUrl],
    updateZabbixElementReportsFetchState
  );

  const selectedPath = yield select(selectedPathSelector);
  const body = yield get(
    payload,
    'property.complexApiBody',
    selectedPath.complexApiBody
  );

  try {
    const { data } = yield ajaxPost(url, body, options);
    yield put(fetchZabbixElementReportsComplete(data));
    yield put(updateZabbixElementReportsFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchZabbixElementReportsError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(updateZabbixElementReportsFetchState(updatedFetchState));
  }
}

export function* fetchZabbixDataTrendSaga({ payload }) {
  yield put(updateZabbixDataTrendFetchState(pendingFetchState));

  const currentDataSet = yield select(zabbixDataTrendSelector);
  yield call(debounceSaga, currentDataSet);

  const params = yield select(filterParamsSelector);
  const hostMetricFilter = yield select(hostMetricFilterSelector);

  let body = yield select(createApiBodySelector(getZabbixDataTrendUrl));
  body = yield JSON.parse(body);
  body.children[0] = yield { ...body.children[0], ...hostMetricFilter };
  body = yield JSON.stringify(body);

  try {
    const { data } = yield ajaxPost(getZabbixDataTrendUrl, body, {
      params,
      skipCache: get(payload, 'skipCache', false)
    });
    yield put(fetchZabbixDataTrendComplete(data));
    yield put(updateZabbixDataTrendFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchZabbixDataTrendError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(updateZabbixDataTrendFetchState(updatedFetchState));
  }
}

export default function createFetchZabbixDataTrendSagas(types) {
  return [
    takeLatest(
      [types.fetchZabbixElementReports],
      fetchZabbixElementReportsSaga
    ),
    takeLatest([types.fetchZabbixDataTrend], fetchZabbixDataTrendSaga)
  ];
}
