/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { NoDataToShow } from 'app/components/elements';
import { FaIcon, LoadingIconPlaceholder } from 'app/components/icons';
import { StatefulTable, TableExport } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  MuiTableOptions,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  fetchSwitchDetails,
  switchDetailsFetchStateSelector,
  switchDetailsSelector
} from 'app/redux/inventory';
import { fetchStatePropTypes } from 'app/redux/utils';
import { isEmpty, isEqual, merge, some, toString } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  CloseButton,
  TableActions,
  TableContainer,
  TableHeader,
  TableTitle,
  TableWrapper
} from '.';
import {
  exportSwitchDetailsTableColumns,
  getSwitchDetailsTableColumns,
  sortSwitchDetailsTable
} from '../get-inventory-columns';

class SwitchDetailsTable extends Component {
  constructor(...props) {
    super(...props);
    this.state = { searchValue: '' };
  }

  componentDidMount = () => {
    const { fetchSwitchDetails, selectedSwitch } = this.props;
    if (this.hasSwitchInfo(selectedSwitch)) {
      fetchSwitchDetails(selectedSwitch);
    }
  };

  componentDidUpdate = prevProps => {
    const { selectedSwitch: prevSelectedSwitch } = prevProps;
    const { selectedSwitch, fetchSwitchDetails } = this.props;

    if (
      this.hasSwitchInfo(selectedSwitch) &&
      !isEqual(prevSelectedSwitch, selectedSwitch)
    ) {
      fetchSwitchDetails(selectedSwitch);
    }
  };

  hasSwitchInfo = ({ switchName = '', zone = '' }) =>
    !isEmpty(switchName) && !isEmpty(zone);

  filterBySearch = data =>
    this.state.searchValue !== ''
      ? data.filter(row =>
        some(row, value =>
          toString(value)
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase())
        )
      )
      : data;

  renderSearchBox = () => (
    <Fragment>
      <FaIcon icon="search" classes={['mr-2']} />
      <label className="sr-only">Search...</label>
      <input
        className="p-1"
        id="inventory-switch-details-search"
        onChange={e => this.setState({ searchValue: e.target.value })}
        placeholder="Search..."
        type="text"
        value={this.state.searchValue}
      />
    </Fragment>
  );

  render = () => {
    const {
      selectedPath,
      switchDetails: { data = [] },
      fetchSwitchDetails,
      fetchState,
      toggleSwitchDetailsTable,
      selectedSwitch
    } = this.props;

    const { pending, complete, failed } = fetchState;
    const noDataToShow = !pending && complete && isEmpty(data);
    // const csvTooltip = 'Generate CSV file containing the AP Details table';

    const tableOptions = merge({}, MuiTableOptions, {
      customSort: sortSwitchDetailsTable
    });

    return (
      <MuiThemeProvider
        theme={createMuiTheme(customMuiTableThemeWithDefualtCursor)}
      >
        <TableContainer>
          <TableWrapper>
            <TableHeader>
              <TableTitle>
                Switch Details : {selectedSwitch.switchName}
              </TableTitle>
              <TableActions>
                {!pending && !isEmpty(data) && (
                  <Fragment>
                    {this.renderSearchBox()}
                    <TableExport
                      hidePdfExport={false}
                      exportName="Inventory Switch Details"
                      exportDataDefinitions={exportSwitchDetailsTableColumns(
                        data
                      )}
                    />
                  </Fragment>
                )}
                {/* {!pending && hasData && (
                    <StyledExportButton
                      onClick={() => downloadAPDetails({ columns })}
                      title={csvTooltip}
                    >
                      <ExportCsvIcon className="ml-3" />
                    </StyledExportButton>
                  )} */}
                <CloseButton
                  onClick={() => toggleSwitchDetailsTable()}
                  title="Close"
                >
                  <FaIcon icon="times" />
                </CloseButton>
              </TableActions>
            </TableHeader>
            <FailedFetchStateHandler
              fetchState={fetchState}
              retry={() => fetchSwitchDetails(selectedSwitch)}
            >
              {pending ? (
                <LoadingIconPlaceholder position="relative" height={250} />
              ) : noDataToShow || failed ? (
                <NoDataToShow
                  message={
                    failed
                      ? 'There has been a problem fetching the switch details'
                      : 'No data available'
                  }
                  style={{
                    background: 'none',
                    position: 'relative'
                  }}
                />
              ) : (
                <StatefulTable
                  tableKey={`${selectedPath.id}-switch-details`}
                  columns={getSwitchDetailsTableColumns()}
                  data={this.filterBySearch(data)}
                  options={tableOptions}
                />
              )}
            </FailedFetchStateHandler>
          </TableWrapper>
        </TableContainer>
      </MuiThemeProvider>
    );
  };
}

SwitchDetailsTable.displayName = 'SwitchDetailsTable';
SwitchDetailsTable.propTypes = {
  selectedPath: PropTypes.object,
  fetchSwitchDetails: PropTypes.func,
  switchDetails: PropTypes.object,
  fetchState: fetchStatePropTypes,
  toggleSwitchDetailsTable: PropTypes.func,
  selectedSwitch: PropTypes.shape({
    switchName: PropTypes.string,
    zone: PropTypes.string
  })
};

const mapStateToProps = createSelector(
  selectedPathSelector,
  switchDetailsSelector,
  switchDetailsFetchStateSelector,
  (selectedPath, switchDetails, fetchState) => ({
    selectedPath,
    switchDetails,
    fetchState
  })
);

const mapDispatchToProps = { fetchSwitchDetails };

export default connect(mapStateToProps, mapDispatchToProps)(SwitchDetailsTable);
