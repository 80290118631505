/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { isEmpty, merge } from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  BlockContainer,
  TitleBar,
  NoDataToShow
} from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { StatefulTable } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  MuiTableOptions,
  styleLibrary,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import {
  fetchTopClients,
  maxClientValuesSelectorForLAC,
  topClientsFetchStateSelector,
  top10ClientsSelectorForLAC,
  topClientsSelector
} from 'app/redux/clients';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { fetchStatePropTypes } from 'app/redux/utils';
import { getClientColumnsForLAC, sortTopClientsLac } from './utils';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

export class TopClientForLAC extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortCol: '',
      sortDir: 'desc'
    };
  }

  render() {
    const {
      clients,
      fetchState,
      Info = {},
      maxClientValues,
      dateRangeFilterLabel,
      fetchTopClients
    } = this.props;

    const { pending, complete } = fetchState;
    const { sortCol, sortDir } = this.state;

    const clientsTableOptions = merge({}, MuiTableOptions, {
      customSort: sortTopClientsLac,
      pagination: false,
      rowsPerPageOptions: []
    });

    const hasClients = !isEmpty(clients);
    const topClientColumns = getClientColumnsForLAC(
      maxClientValues,
      sortCol,
      sortDir
    );
    const noDataToShow = !pending && complete && !hasClients;

    // Work out which clients to display based on whether a search value has been provided or not
    const clientsToDisplay = clients.map(c => ({ ...c, ...Info }));

    return (
      <Fragment>
        <div className="row no-gutters mb-4 include-in-pdf">
          <BlockContainer classes={['col']}>
            <TitleBar
              leftChildren="Top 10 Unique Clients"
              rightChildren={
                <span
                  style={{ fontSize: styleLibrary.fontSizes.secondaryTitle }}
                >
                  {dateRangeFilterLabel}
                </span>
              }
            />
            {noDataToShow ? <NoDataToShow /> : null}
            <FailedFetchStateHandler
              fetchState={fetchState}
              retry={fetchTopClients}
            >
              <div
                className={pending || noDataToShow ? 'fetch-state-pending' : ''}
                style={{ minHeight: '300px' }}
              >
                {hasClients && complete && (
                  <MuiThemeProvider
                    theme={createMuiTheme(customMuiTableThemeWithDefualtCursor)}
                  >
                    <StatefulTable
                      tableKey={JSON.stringify(maxClientValues)}
                      columns={topClientColumns}
                      data={clientsToDisplay}
                      options={clientsTableOptions}
                    />
                  </MuiThemeProvider>
                )}
              </div>
              {pending && <LoadingIconPlaceholder />}
            </FailedFetchStateHandler>
          </BlockContainer>
        </div>
      </Fragment>
    );
  }
}

TopClientForLAC.propTypes = {
  clients: PropTypes.array,
  dateRangeFilterLabel: PropTypes.string,
  fetchState: fetchStatePropTypes,
  fetchTopClients: PropTypes.func.isRequired,
  Info: PropTypes.object,
  maxClientValues: PropTypes.object
};
const mapStateToProps = createSelector(
  maxClientValuesSelectorForLAC,
  top10ClientsSelectorForLAC,
  topClientsFetchStateSelector,
  topClientsSelector,
  dateRangeFilterLabelSelector,
  (
    maxClientValues,
    top10Clients,
    fetchState,
    topClients,
    dateRangeFilterLabel
  ) => ({
    maxClientValues,
    clients: top10Clients,
    fetchState,
    Info: topClients.Info,
    dateRangeFilterLabel
  })
);
export default connect(mapStateToProps, { fetchTopClients })(TopClientForLAC);
