/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { filter } from 'lodash';
import { eventChannel } from 'redux-saga';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import { isAjaxTimeoutError } from 'app/custom-errors';
import { inProgressList } from 'app/modules/reports/constants';
import {
  pendingFetchState,
  completeFetchState,
  failedFetchState,
  timeoutNotifyFetchState,
  createErrorAction,
  timeoutFetchState
} from 'app/redux/utils';
import { ajaxGet, debounceSaga } from 'app/redux/util-sagas';
import {
  fetchReportsListComplete,
  fetchReportsListError,
  updateReportsListFetchState,
  reportsListSelector
} from './';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';

const reportsListUrl = '/api/report/listReports';

const scheduleReportsRefresh = (delay, hasInProgress) =>
  eventChannel(emitter => {
    const timeoutId = setTimeout(() => {
      emitter(hasInProgress);
    }, delay);
    return () => clearTimeout(timeoutId);
  });

export function* fetchReportsListSaga({ payload = {} }) {
  const { skipCache = false, spin = true } = payload;

  if (spin) {
    yield put(updateReportsListFetchState(pendingFetchState));
    yield call(waitForHierarchySaga);
  }

  const currentDataSet = yield select(reportsListSelector);
  yield call(debounceSaga, currentDataSet);

  const onTimeout = () =>
    put(updateReportsListFetchState(timeoutNotifyFetchState));

  try {
    const { data } = yield ajaxGet(reportsListUrl, {
      onTimeout,
      skipCache
    });

    yield put(fetchReportsListComplete(data));
    yield put(updateReportsListFetchState(completeFetchState));

    // If we get a "QUEUED" or "IN-PROGRESS" report, automatically refresh
    // after a minute
    const hasInProgress =
      filter(data, x => inProgressList.includes(x.status)).length > 0;
    if (hasInProgress) {
      const timeoutChannel = yield call(
        scheduleReportsRefresh,
        15000,
        hasInProgress
      );
      yield take(timeoutChannel);
      yield call(fetchReportsListSaga, {
        payload: { skipCache: true, spin: false }
      });
    }
  } catch (err) {
    yield put(createErrorAction(fetchReportsListError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(updateReportsListFetchState(updatedFetchState));
  }
}

export default function createFetchReportsListSagas(types) {
  return [takeLatest(types.fetchReportsList, fetchReportsListSaga)];
}
