/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { commonPlotProps, styleLibrary } from 'app/constants';
import { formatApiDateTime } from 'app/utils';
import { get } from 'lodash';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class APUniqueClientTrend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hiddenPlotMap: {}
    };
  }

  isActivePlot = plotKey =>
    this.state.hiddenPlotMap[plotKey] ? 'blank' : plotKey;

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  render = () => {
    const { data, info, dateRage } = this.props;
    const { hiddenPlotMap } = this.state;
    const timestamp = get(info, 'timezone', 'UTC');

    return (
      <ChartFlexContainer width="99%" height={300}>
        <LineChart data={data} margin={{ ...chartMargins, left: 15 }}>
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="label"
            label={{
              value: `Time (${timestamp})`,
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            interval="preserveStartEnd"
            tickFormatter={t => formatApiDateTime(t, dateRage)}
            scale="point"
            tick={tick}
          />
          <YAxis
            yAxisId="clientStatusTrend"
            label={{
              value: 'Count',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            allowDecimals={false}
            interval="preserveStartEnd"
          />
          <Tooltip
            content={
              <MultiLineTooltip
                keys={['Clients', 'Sessions']}
                units={['']}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
          />
          <Legend
            verticalAlign="top"
            inactivePlots={hiddenPlotMap}
            content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
          />
          <Line
            yAxisId="clientStatusTrend"
            dataKey={this.isActivePlot('Clients')}
            name="Clients"
            stroke={styleLibrary.clientsColor}
            {...commonPlotProps}
          />
        </LineChart>
      </ChartFlexContainer>
    );
  };
}

APUniqueClientTrend.propTypes = {
  data: PropTypes.array,
  info: PropTypes.object,
  dateRage: PropTypes.object
};

export default APUniqueClientTrend;
