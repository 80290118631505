/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';

import {
  BasicTooltip,
  ChartFlexContainer,
  ChartLabel,
  ChartLegend,
  ChartModeSwitcher
} from 'app/components/charts';
import { chartMargins } from 'app/components/charts/constants';
import { BlockContainer, NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { chartModeSuffix, styleLibrary } from 'app/constants';
import { chartModesSelector, updateChartDisplayMode } from 'app/redux/app';
import {
  fetchGuestSubscriber,
  guestSubscriberChartDataSelector,
  guestSubscriberFetchStateSelector
} from 'app/redux/clients';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  applyLegendToggles,
  calculateChartHeight,
  createColourFactory
} from 'app/utils';

import { getDisplayModeProperties } from '../../utils';

import { xAxisLabel, xAxisTick } from '../client-os/constants';
import { ChartTitleBar, PieBarChartWrapper } from '../common';

const getFillColour = createColourFactory(60);
const modeKey = `guestPrivate${chartModeSuffix}`;

export class GuestSubscriberChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: []
    };
  }

  componentDidMount() {
    if (!this.hasValidData()) {
      this.props.fetchGuestSubscriber();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dateRangeFilterLabel,
      fetchGuestSubscriber,
      selectedPath = {}
    } = this.props;
    const {
      dateRangeFilterLabel: prevDateRange,
      selectedPath: prevSelectedPath = {}
    } = prevProps;

    const pathChanged = prevSelectedPath.id !== selectedPath.id;
    const dateRangeChanged = prevDateRange !== dateRangeFilterLabel;

    if (pathChanged || dateRangeChanged) {
      fetchGuestSubscriber();
    }
  }

  handleChangeToggled = label => {
    const { toggled } = this.state;

    if (toggled.includes(label)) {
      toggled.splice(toggled.indexOf(label), 1);
    } else {
      toggled.push(label);
    }

    this.setState({ toggled });
  };

  hasValidData() {
    const { chartData } = this.props;
    const [guest, subscriber] = chartData;

    return !isEmpty(chartData) && guest.value !== -1 && subscriber.value !== -1;
  }

  setMode = mode => {
    this.props.updateChartDisplayMode({ key: modeKey, mode });
  };

  render() {
    const {
      chartData,
      chartModes,
      dateRangeFilterLabel,
      fetchGuestSubscriber,
      fetchState
    } = this.props;
    const { toggled } = this.state;

    const { pending, complete, timeout } = fetchState;

    const hasData = this.hasValidData();
    const noDataToShow = !pending && complete && !hasData;
    const dataToRender = timeout || pending ? [] : chartData;

    const mode = chartModes[modeKey] || 'pie';
    const { chartHeight, containerHeight } = calculateChartHeight(
      dataToRender.length,
      400,
      150
    );

    let pieData;
    if (mode === 'pie') {
      pieData = applyLegendToggles(dataToRender, toggled, 'label', 'value');
    }

    let total = 0;
    if (hasData) {
      const [guest, subscriber] = chartData;
      total = guest.value + subscriber.value;
    }

    const {
      padding: containerPadding,
      classes: wrapperClasses
    } = getDisplayModeProperties(mode, true);

    return (
      <BlockContainer data-test-label="guest-subscriber-tile">
        <ChartTitleBar
          dateRange={dateRangeFilterLabel}
          hasData={false}
          title="Guest vs. Subscriber"
        />
        <Fragment>
          {noDataToShow ? <NoDataToShow /> : null}
          <FailedFetchStateHandler
            fetchState={fetchState}
            retry={fetchGuestSubscriber}
          >
            <div
              className={classNames(
                `pb-${containerPadding}`,
                pending || noDataToShow ? 'fetch-state-pending' : ''
              )}
            >
              {!isEmpty(dataToRender) && (
                <Fragment>
                  <ChartModeSwitcher onClick={this.setMode} mode={mode} />
                  <PieBarChartWrapper classes={wrapperClasses}>
                    {mode === 'bar' ? (
                      <ChartFlexContainer width="95%" height={containerHeight}>
                        <ComposedChart
                          margin={chartMargins}
                          height={chartHeight}
                          data={dataToRender}
                          layout="vertical"
                        >
                          <CartesianGrid vertical={true} horizontal={false} />
                          <XAxis
                            type="number"
                            label={xAxisLabel}
                            allowDecimals={false}
                            tick={xAxisTick}
                            tickMargin={1}
                          />
                          <YAxis
                            type="category"
                            yAxisId="count"
                            dataKey="label"
                            stroke="rgb(31, 119, 180)"
                            tick={null}
                          />
                          <Tooltip
                            content={<BasicTooltip maxValue={total} />}
                            contentStyle={{
                              fontSize: styleLibrary.fontSizes.body
                            }}
                            offset={0}
                            wrapperStyle={{ marginLeft: 80 }}
                          />
                          <Bar
                            dataKey="value"
                            name="Clients"
                            yAxisId="count"
                            dot={false}
                            type="monotone"
                            strokeWidth={1.5}
                          >
                            <LabelList
                              content={<ChartLabel />}
                              dataKey="label"
                            />
                            {dataToRender.map(({ label }) => (
                              <Cell key={label} fill={getFillColour(label)} />
                            ))}
                          </Bar>
                        </ComposedChart>
                      </ChartFlexContainer>
                    ) : (
                      <Fragment>
                        <PieChart width={250} height={275}>
                          <Pie
                            data={pieData}
                            dataKey="value"
                            innerRadius="50%"
                            nameKey="label"
                            outerRadius="100%"
                          >
                            <LabelList
                              content={<ChartLabel />}
                              dataKey="label"
                            />
                            {dataToRender.map(({ label }) => (
                              <Cell key={label} fill={getFillColour(label)} />
                            ))}
                          </Pie>
                          <Tooltip
                            content={<BasicTooltip maxValue={total} />}
                            contentStyle={{
                              fontSize: styleLibrary.fontSizes.body
                            }}
                            offset={0}
                            wrapperStyle={{ marginLeft: 80 }}
                          />
                        </PieChart>
                        <ChartLegend
                          data={dataToRender}
                          getFillColour={getFillColour}
                          onToggle={this.handleChangeToggled}
                          textProperty="label"
                          toggled={toggled}
                          countProperty="value"
                          totalCount={total}
                        />
                      </Fragment>
                    )}
                  </PieBarChartWrapper>
                </Fragment>
              )}
            </div>
            {pending && <LoadingIconPlaceholder />}
          </FailedFetchStateHandler>
        </Fragment>
      </BlockContainer>
    );
  }
}

GuestSubscriberChart.propTypes = {
  chartData: PropTypes.array,
  chartModes: PropTypes.object,
  dateRangeFilterLabel: PropTypes.string,
  fetchGuestSubscriber: PropTypes.func.isRequired,
  fetchState: fetchStatePropTypes,
  selectedPath: PropTypes.object,
  updateChartDisplayMode: PropTypes.func.isRequired
};

const mapStateToProps = createSelector(
  chartModesSelector,
  dateRangeFilterLabelSelector,
  guestSubscriberChartDataSelector,
  guestSubscriberFetchStateSelector,
  selectedPathSelector,
  (chartModes, dateRangeFilterLabel, chartData, fetchState, selectedPath) => ({
    chartModes,
    dateRangeFilterLabel,
    chartData,
    fetchState,
    selectedPath
  })
);

export default connect(mapStateToProps, {
  fetchGuestSubscriber,
  updateChartDisplayMode
})(GuestSubscriberChart);
