/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';
import { FaIcon } from './index';

const StyledIcon = styled.div.attrs({
  className: 'd-flex align-items-center justify-content-center loading-spinner'
})`
  position: ${props => props.position || 'absolute'};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: ${props => (props.height !== undefined ? props.height : 200)}px;
  font-size: ${styleLibrary.fontSizes.panelLoadingIcon}px;
  z-index: 1;
`;

export const LoadingIconPlaceholder = props => {
  const { color, height, position } = props;
  const iconColourStyle = color ? { color } : {};

  return (
    <StyledIcon height={height} position={position}>
      <FaIcon icon="circle-notch" spin style={iconColourStyle} />
    </StyledIcon>
  );
};

LoadingIconPlaceholder.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  position: PropTypes.string
};
