/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createShowPanelSelector } from 'app/redux/app';
import { genericAjaxPostSaga } from 'app/redux/util-sagas/generic-ajax-sagas';
import { call, select, takeLatest } from 'redux-saga/effects';
import {
  // clientCountByDevicesSelector,
  clientManufacturersSelector,
  clientOsTypesSelector,
  // fetchClientCountComplete,
  // fetchClientCountError,
  fetchClientManufacturersComplete,
  fetchClientManufacturersError,
  fetchClientOsTypesComplete,
  fetchClientOsTypesError,
  fetchGuestSubscriberComplete,
  fetchGuestSubscriberError,
  guestSubscriberSelector,
  // updateClientCountFetchState,
  updateClientManufacturersFetchState,
  updateClientOsTypesFetchState,
  updateGuestSubscriberFetchState
} from './';

const osRequestUrls = ['/api/device/getSPDeviceTypes'];
const manufacturerRequestUrls = ['/api/device/getSPManufacturerByClient'];
const guestSubscriberRequestUrls = [
  '/api/subscriber/getSPSubscriberCategoryCount'
];
// const clientCountUrls = ['/api/device/getSPClientCountByDevices'];

export function* fetchOsTypesSaga() {
  const sagaOptions = {
    completeAction: fetchClientOsTypesComplete,
    errorAction: fetchClientOsTypesError,
    currentDataSelector: clientOsTypesSelector,
    updateFetchState: updateClientOsTypesFetchState
  };
  yield call(genericAjaxPostSaga, osRequestUrls, sagaOptions);
}

export function* fetchManufacturersSaga() {
  const sagaOptions = {
    completeAction: fetchClientManufacturersComplete,
    errorAction: fetchClientManufacturersError,
    currentDataSelector: clientManufacturersSelector,
    updateFetchState: updateClientManufacturersFetchState
  };
  yield call(genericAjaxPostSaga, manufacturerRequestUrls, sagaOptions);
}

export function* fetchGuestSubscriberSaga() {
  const hasGuestPrivate = yield select(createShowPanelSelector('GuestPrivate'));

  if (hasGuestPrivate) {
    const sagaOptions = {
      completeAction: fetchGuestSubscriberComplete,
      errorAction: fetchGuestSubscriberError,
      currentDataSelector: guestSubscriberSelector,
      updateFetchState: updateGuestSubscriberFetchState
    };
    yield call(genericAjaxPostSaga, guestSubscriberRequestUrls, sagaOptions);
  }
}

// export function* fetchClientCountSaga() {
//   const sagaOptions = {
//     completeAction: fetchClientCountComplete,
//     errorAction: fetchClientCountError,
//     currentDataSelector: clientCountByDevicesSelector,
//     updateFetchState: updateClientCountFetchState
//   };
//   yield call(genericAjaxPostSaga, clientCountUrls, sagaOptions);
// }

export default function createFetchClientStatsSagas(types) {
  return [
    takeLatest(types.fetchClientOsTypes, fetchOsTypesSaga),
    takeLatest(types.fetchClientManufacturers, fetchManufacturersSaga),
    takeLatest(types.fetchGuestSubscriber, fetchGuestSubscriberSaga)
    // takeLatest(types.fetchClientCount, fetchClientCountSaga)
  ];
}
