import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { isArray, isEmpty } from 'lodash';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import {
  sfdcCaseDetailsFetchStateSelector,
  sfdcCaseTypeDataSelector,
  fetchSfdcCaseDetails
} from 'app/redux/incidents';

import {
  ChartFlexContainer,
  ChartLabel,
  ChartLegend,
  ChartModeSwitcher
} from 'app/components/charts';
import { chartMargins } from 'app/components/charts/constants';
import {
  BlockContainer,
  NoDataToShow,
  TitleBar
} from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { chartModeSuffix } from 'app/constants';
import {
  xAxisLabel,
  xAxisTick
} from 'app/modules/overview/components/client-os/constants';
import { PieBarChartWrapper } from 'app/modules/overview/components/common';
import { getDisplayModeProperties } from 'app/modules/overview/utils';
import {
  applyLegendToggles,
  calculateChartHeight,
  createColourFactory
} from 'app/utils';
import { fetchStatePropTypes } from 'app/redux/utils';
import { FailedFetchStateHandler } from 'app/components/utility';
import { noDataMessageStyle } from './utils';

const getFillColour = createColourFactory(10);
const modeKey = `activePrivate${chartModeSuffix}`;

class SfdcCasetypeChart extends Component {
  constructor(props) {
    super(props);

    this.state = { toggled: [], toggleCaseTypeChart: 'pie' };
  }

  handleChangeToggled = label => {
    const { toggled } = this.state;

    if (toggled.includes(label)) {
      toggled.splice(toggled.indexOf(label), 1);
    } else {
      toggled.push(label);
    }
    this.setState({ toggled });
  };

  setMode = mode => {
    this.setState(prevState => ({
      ...prevState,
      toggleCaseTypeChart: mode,
      key: modeKey
    }));
  };

  render = () => {
    const { chartData, fetchState, fetchSfdcCaseDetails } = this.props;
    const { toggled, toggleCaseTypeChart } = this.state;

    const { pending, complete, timeout } = fetchState;

    const hasData = isArray(chartData) && !isEmpty(chartData);
    const noDataToShow = !pending && complete && !hasData;
    const dataToRender = timeout || pending ? [] : chartData;
    const checkCaseData = complete && hasData && !isEmpty(dataToRender);

    const { chartHeight, containerHeight } = calculateChartHeight(
      dataToRender.length,
      400,
      150
    );

    let pieData;
    if (toggleCaseTypeChart === 'pie') {
      pieData = applyLegendToggles(dataToRender, toggled, 'casetype', 'count');
    }

    const {
      padding: containerPadding,
      classes: wrapperClasses
    } = getDisplayModeProperties(toggleCaseTypeChart, true);

    return (
      <BlockContainer>
        <TitleBar leftChildren="By Type" />
        <Fragment>
          <FailedFetchStateHandler
            fetchState={fetchState}
            retry={fetchSfdcCaseDetails}
          >
            {noDataToShow || timeout ? (
              <NoDataToShow
                message={
                  timeout
                    ? 'There has been a problem fetching the incidents property'
                    : 'No incidents available'
                }
                style={noDataMessageStyle}
              />
            ) : (
              <div
                className={classNames(
                  `pb-${containerPadding}`,
                  pending || noDataToShow ? 'fetch-state-pending' : ''
                )}
              >
                {checkCaseData && (
                  <Fragment>
                    <ChartModeSwitcher
                      onClick={this.setMode}
                      mode={toggleCaseTypeChart}
                    />
                    <PieBarChartWrapper classes={wrapperClasses}>
                      {toggleCaseTypeChart === 'bar' ? (
                        <ChartFlexContainer
                          width="95%"
                          height={containerHeight}
                        >
                          <ComposedChart
                            margin={chartMargins}
                            height={chartHeight}
                            data={chartData}
                            layout="vertical"
                          >
                            <CartesianGrid vertical={true} horizontal={false} />
                            <XAxis
                              type="number"
                              label={{ ...xAxisLabel, value: 'Case Type' }}
                              allowDecimals={false}
                              tick={xAxisTick}
                              tickMargin={1}
                            />
                            <YAxis
                              type="category"
                              yAxisId="count"
                              dataKey="casetype"
                              stroke="rgb(31, 119, 180)"
                              tick={null}
                            />
                            <Tooltip />
                            <Bar
                              dataKey="count"
                              nameKey="casetype"
                              yAxisId="count"
                              dot={false}
                              type="monotone"
                              strokeWidth={1.5}
                            >
                              <LabelList
                                content={<ChartLabel />}
                                dataKey="casetype"
                              />
                              {chartData.map(({ casetype }) => (
                                <Cell
                                  key={casetype}
                                  fill={getFillColour(casetype)}
                                />
                              ))}
                            </Bar>
                          </ComposedChart>
                        </ChartFlexContainer>
                      ) : (
                        <Fragment>
                          <PieChart width={350} height={275}>
                            <Pie
                              data={pieData}
                              dataKey="count"
                              innerRadius="50%"
                              nameKey="casetype"
                              outerRadius="100%"
                            >
                              <LabelList
                                content={<ChartLabel />}
                                dataKey="casetype"
                              />
                              {chartData.map(({ casetype }) => (
                                <Cell
                                  key={casetype}
                                  fill={getFillColour(casetype)}
                                />
                              ))}
                            </Pie>
                            <Tooltip />
                          </PieChart>
                          <ChartLegend
                            data={chartData}
                            getFillColour={getFillColour}
                            onToggle={this.handleChangeToggled}
                            textProperty="casetype"
                            toggled={toggled}
                            countProperty="count"
                            percentProperty="percentage"
                            titleLabel="Case Type"
                          />
                        </Fragment>
                      )}
                    </PieBarChartWrapper>
                  </Fragment>
                )}
              </div>
            )}
            {pending && <LoadingIconPlaceholder />}
          </FailedFetchStateHandler>
        </Fragment>
      </BlockContainer>
    );
  };
}

SfdcCasetypeChart.propTypes = {
  fetchSfdcCaseDetails: PropTypes.func,
  chartData: PropTypes.array,
  fetchState: fetchStatePropTypes
};

const mapStateToProps = createSelector(
  sfdcCaseTypeDataSelector,
  sfdcCaseDetailsFetchStateSelector,
  (chartData, fetchState) => ({
    chartData,
    fetchState
  })
);

export default connect(mapStateToProps, { fetchSfdcCaseDetails })(
  SfdcCasetypeChart
);
