/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { FixedTooltipWrapper, TooltipHeader, TooltipItem } from './';

const getPayloadData = (payload, dataKey) =>
  payload.filter(p => p.dataKey === dataKey)[0];

export class MultiLineTooltip extends Component {
  shouldComponentUpdate(prevProps) {
    // Adding this since this component seems to update 10s of times per mouse move
    return prevProps.label !== this.props.label;
  }

  render() {
    const {
      keys,
      label,
      labelFormatter,
      payload,
      units,
      scale,
      showAtSign
    } = this.props;

    return payload && payload.length > 0 ? (
      <Fragment>
        <FixedTooltipWrapper>
          <TooltipHeader data-test-label="data-rate-header">
            {showAtSign ? '@ ' : ''}
            {labelFormatter ? labelFormatter(label) : label}
          </TooltipHeader>
          {keys.map(k => (
            <div className="row" key={k} data-test-label="data-rate-row">
              <TooltipItem
                item={getPayloadData(payload, k)}
                scale={scale}
                units={units}
              />
            </div>
          ))}
        </FixedTooltipWrapper>
      </Fragment>
    ) : (
      <div>&nbsp;</div>
    );
  }
}

MultiLineTooltip.defaultProps = { scale: true, showAtSign: true };

MultiLineTooltip.propTypes = {
  keys: PropTypes.array.isRequired,
  label: PropTypes.any,
  labelFormatter: PropTypes.func,
  payload: PropTypes.array,
  units: PropTypes.array,
  scale: PropTypes.bool,
  showAtSign: PropTypes.bool
};

export default MultiLineTooltip;
