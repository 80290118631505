/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isAjaxTimeoutError } from 'app/custom-errors';
import { filterParamsSelector } from 'app/redux/filters';
import {
  createApiBodySelector,
  hierarchySelector,
  selectedPathSelector
} from 'app/redux/hierarchy';
import { ajaxGet, ajaxPost, debounceSaga } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { getPropertyParent } from 'app/utils';
import { get, merge, orderBy } from 'lodash';
import moment from 'moment';
import Papa from 'papaparse';
import {
  call,
  getContext,
  put,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import {
  downloadRogueSummaryComplete,
  downloadRogueSummaryError,
  downloadingRogueSummary,
  fetchRogueDetailsComplete,
  fetchRogueDetailsError,
  fetchRogueDetailsForRogueMACComplete,
  fetchRogueDetailsForRogueMACError,
  fetchRogueSummaryComplete,
  fetchRogueSummaryError,
  rogueSummaryJSONSelector,
  rogueSummarySelector,
  updateRogueDetailsFetchState,
  updateRogueDetailsForRogueMACFetchState,
  updateRogueSummaryFetchState,
  updateRogueSummaryJSON
} from './';

export function* fetchRogueSummarySaga() {
  const url = '/api/device/getSPRogueSummary';
  yield put(updateRogueSummaryFetchState(pendingFetchState));
  yield put(updateRogueSummaryJSON([]));

  const currentDataSet = yield select(rogueSummarySelector);
  yield call(debounceSaga, currentDataSet);

  const filterParams = yield select(filterParamsSelector);
  const apiBody = yield select(createApiBodySelector(url));

  const onTimeout = () =>
    put(updateRogueSummaryFetchState(timeoutNotifyFetchState));

  try {
    const { data } = yield ajaxPost(url, apiBody, {
      params: { ...filterParams },
      onTimeout
    });

    yield put(fetchRogueSummaryComplete(data));
    yield put(updateRogueSummaryFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchRogueSummaryError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(updateRogueSummaryFetchState(updatedFetchState));
  }
}

export function* downloadRogueSummarySaga({ payload: { columns = [] } }) {
  yield put(downloadingRogueSummary({ inProgress: true, failed: false }));
  const columnLabels = columns.map(c => c.label);

  try {
    const saveFileAs = yield getContext('saveFileAs');
    const path = yield select(selectedPathSelector);
    const data = yield select(rogueSummaryJSONSelector);
    const transformedData = yield orderBy(data, ['timestamp', 'roguemac']).map(
      d => {
        const ret = {};
        columns.forEach(c => (ret[c.label] = d[c.name]));
        return ret;
      }
    );
    const csvDataString = yield Papa.unparse(transformedData, {
      quotes: true,
      header: true,
      skipEmptyLines: false,
      columns: columnLabels
    });
    const csvDataBlob = yield new Blob([csvDataString], {
      type: 'text/plain;charset=utf-8'
    });
    const csvFilename =
      'RogueSummary_' +
      path.name +
      '_' +
      moment().format('YYYYMMDD_hhmm') +
      '.csv';
    yield call(saveFileAs, csvDataBlob, csvFilename);
    yield put(downloadRogueSummaryComplete());
    yield put(downloadingRogueSummary({ inProgress: false }));
  } catch (err) {
    yield put(createErrorAction(downloadRogueSummaryError, err));
    yield put(downloadingRogueSummary({ inProgress: false, failed: true }));
  }
}

export function* fetchRogueDetailsSaga({ payload }) {
  yield put(updateRogueDetailsFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');
  
  const params = yield merge({}, filters, payload, {
    zone: get(payload, 'property.name', selectedPropertyName),
    wlanName: 'all'
  });

  const url = '/api/fastapi/getSPRogueDetails';
  const paramNames = [
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'vertical',
    'wlanName',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updateRogueDetailsFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchRogueDetailsComplete(data));
    yield put(updateRogueDetailsFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchRogueDetailsError, err));
    yield put(updateRogueDetailsFetchState(failedFetchState));
  }
}

export function* fetchRogueDetailsForRogueMACSaga({ payload }) {
  yield put(updateRogueDetailsForRogueMACFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');
  const params = yield merge(
    {},
    filters,
    payload,
    { zone: get(payload, 'property.name', selectedPropertyName) },
    { rogueMAC: get(payload, 'rogueMAC', '') }
  );

  const url = '/api/fastapi/getSPRogueDetailsPerRogueMac';
  const paramNames = [
    'rogueMAC',
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'vertical',
    'wlanName',
    'zone'
  ];

  try {
    const onTimeout = () =>
      put(updateRogueDetailsForRogueMACFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchRogueDetailsForRogueMACComplete(data));
    yield put(updateRogueDetailsForRogueMACFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchRogueDetailsForRogueMACError, err));
    yield put(updateRogueDetailsForRogueMACFetchState(failedFetchState));
  }
}

export default function createFetchRogueSummarySagas(types) {
  return [
    takeLatest(types.fetchRogueSummary, fetchRogueSummarySaga),
    takeEvery(types.downloadRogueSummary, downloadRogueSummarySaga),
    takeLatest(types.fetchRogueDetails, fetchRogueDetailsSaga),
    takeLatest(
      types.fetchRogueDetailsForRogueMAC,
      fetchRogueDetailsForRogueMACSaga
    )
  ];
}
