/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { apiDateFormat } from 'app/constants';
import { inventoryCategories } from 'app/modules/inventory/constants';
import { castToNumber } from 'app/utils';
import {
  defaultTo,
  filter,
  first,
  flow,
  get,
  merge,
  partialRight
} from 'lodash';
import moment from 'moment';

const getByLevel = level => partialRight(filter, item => item.level === level);
const defaultsToEmptyArray = partialRight(defaultTo, []);
const defaultsToEmptyObject = partialRight(defaultTo, {});

const mapAPToInventoryItem = ap => {
  const hasActiveInactive =
    get(ap, 'Active', 0) > 0 || get(ap, 'Inactive', 0) > 0;
  const fields = {
    connected: hasActiveInactive ? 'Active' : 'connected',
    disconnected: hasActiveInactive ? 'Inactive' : 'disconnected'
  };

  return {
    numberConnected: defaultTo(ap[fields.connected], 0),
    numberDisconnected: defaultTo(ap[fields.disconnected], 0),
    total: hasActiveInactive
      ? defaultTo(ap.Active, 0) + defaultTo(ap.Inactive, 0)
      : ap.total,
    refreshTimestamp: ap.refreshTimestamp
  };
};

const reduceAPEntry = (row, ap) => {
  const rowRefresh = moment(row.lastRefreshTime, apiDateFormat).valueOf();
  const apRefresh = moment(ap.refreshTimestamp, apiDateFormat).valueOf();
  const useAP = apRefresh > rowRefresh;

  return {
    type: row.type,
    numberConnected: row.numberConnected + ap.numberConnected,
    numberDisconnected: row.numberDisconnected + ap.numberDisconnected,
    total: row.total + ap.total,
    lastRefreshTime: useAP ? ap.refreshTimestamp : row.lastRefreshTime,
    level: row.level
  };
};

const defaultAPRow = {
  type: 'APs',
  numberConnected: 0,
  numberDisconnected: 0,
  total: 0,
  lastRefreshTime: 0
};

const filterForProperties = getByLevel('Property');
const filterForNetworks = getByLevel('Network');

/**
 * This function appends mapped objects representing the APs in the current inventory.
 *
 * @param {Object} inventory The fully inventory object from the Redux store
 * @returns {Object} A copy of the inventory with the AP nodes appended to the summary
 */
const appendAPsToSummary = inventory => {
  let apsRow = {};

  const { summary, ...rest } = merge({}, inventory);
  const aps = getAPs(inventory);

  if (aps.length > 0) {
    apsRow = aps
      .map(mapAPToInventoryItem)
      .reduce(reduceAPEntry, { ...defaultAPRow, level: 'Property' });
    summary.push(apsRow);
  }

  return {
    ...rest,
    summary
  };
};

export const getSummary = flow(
  appendAPsToSummary,
  partialRight(get, 'summary'),
  defaultsToEmptyArray
);
export const getAPs = flow(
  partialRight(get, 'apsummary'),
  defaultsToEmptyArray
);
export const getNetworkDetails = flow(
  partialRight(get, 'networkdetails'),
  defaultsToEmptyArray
);
export const getApDetails = flow(
  partialRight(get, 'apdetails'),
  defaultsToEmptyArray
);
export const getDetails = flow(
  partialRight(get, 'details'),
  defaultsToEmptyArray
);

export const getAllPropertiesFromSummary = flow(
  getSummary,
  filterForProperties
);
export const getAllNetworksFromSummary = flow(getSummary, filterForNetworks);

export const getFirstPropertyFromSummary = flow(
  getAllPropertiesFromSummary,
  first,
  defaultsToEmptyObject
);

export const getInventoryCalculatedValues = inventory => {
  const summary = getSummary(inventory);
  const apSummary = summary.find(i => i.type === 'APs');

  const latestDate = apSummary
    ? get(apSummary, 'lastRefreshTime')
    : summary.reduce((latestDate, { lastRefreshTime: current }) => {
      const currentMoment = moment(current, apiDateFormat);
      const latestMoment =
          latestDate === '' ? moment(0) : moment(latestDate, apiDateFormat);
      return currentMoment.isAfter(latestMoment) ? current : latestDate;
    }, '');

  const maxNumberOfDevices = castToNumber(
    Math.max(
      ...summary.map(i => Math.max(i.numberConnected, i.numberDisconnected))
    )
  );

  const displayElementsMap = inventoryCategories.reduce(
    (map, category) => ({
      ...map,
      [category]: summary.filter(el => el.level === category)
    }),
    {}
  );

  return {
    latestDate,
    maxNumberOfDevices,
    displayElementsMap
  };
};

export const getPropertyInventoryCount = summary => {
  const networkDetails = getNetworkDetails(summary);

  return networkDetails.map(p => ({
    propertyType: p.vertical,
    propertyCount: p.number
  }));
};
