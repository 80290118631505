/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { takeLatest, call, put, select } from 'redux-saga/effects';

import {
  createMapMarkerDetailsSelector,
  fetchMapMarkerDetailsComplete
} from './';
import { ajaxPost, debounceSaga } from 'app/redux/util-sagas';
import { buildApiRequestArgs } from 'app/redux/ajax-saga-utils';

const summaryRequestUrl = ['/api/network/getServiceProviderDetails'];

export function* fetchMapMarkerDetailsSaga({ payload }) {
  const { name } = payload;

  const currentDataSet = yield select(createMapMarkerDetailsSelector(name));
  yield call(debounceSaga, currentDataSet);

  try {
    const [requestArgs] = yield call(
      buildApiRequestArgs,
      summaryRequestUrl
    );
    const { url, options } = requestArgs;
    let { body } = requestArgs;

    // Narrow down to the selected child only - optimised `getServiceProviderDetails` call
    body = JSON.parse(body);
    body.children = body.children.filter(child => child.name === name);

    const { data } = yield ajaxPost(url, body, options);
    
    yield put(fetchMapMarkerDetailsComplete({ name, ...data }));
  } catch (err) {
    yield put(fetchMapMarkerDetailsComplete({ name }));
  }
}

export default function createMapMarkerSagas(types) {
  return [takeLatest(types.fetchMapMarkerDetails, fetchMapMarkerDetailsSaga)];
}
