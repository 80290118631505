/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { get, isArray, isEmpty } from 'lodash';
import { getTopPlusOther } from './utils';

export const clientOsTransform = data => {
  const { devicetypes: osTypeArray = [] } = data;
  // Transform "Other" to "Others" in the osType array to avoid having two labels in the UI
  const transformedOSType = osTypeArray.map(deviceType => {
    if (deviceType.label === 'Other') {
      return { ...deviceType, label: 'Others' };
    }
    return deviceType;
  });
  const { resultSet, total } = getTopPlusOther(
    transformedOSType,
    9,
    'osType',
    'usability',
    'usability'
  );

  return { clientOS: resultSet, totalUniqueClients: total };
};

export const guestSubscriberTransform = data => {
  const { subscriberCategoryCount = {} } = data;
  const { guestCount = -1, subscriberCount = -1 } = subscriberCategoryCount;

  return [
    { label: 'Guest', value: guestCount },
    { label: 'Subscriber', value: subscriberCount }
  ];
};

export const manufacturerTransform = data => {
  const { manufacturers = [] } = data;
  // Transform "Other" to "Others" in the manufacturers array to avoid having two labels in the UI
  const transformedManufacturers = manufacturers.map(manufacturer => {
    if (manufacturer.label === 'Other') {
      return { ...manufacturer, label: 'Others' };
    }
    return manufacturer;
  });

  const { resultSet, total } = getTopPlusOther(
    transformedManufacturers,
    9,
    'manufacturer',
    'clientCount',
    'clientCount'
  );

  return { clientManufacturers: resultSet, totalClients: total };
};

export const clientStatusTrendTransform = propertyClientStatus => {
  let { clientTrendByAP: data = [], info = {} } = propertyClientStatus;
  if (isArray(data) && !isEmpty(data)) {
    data = data.map(i => ({
      label: i['timestamp'],
      'Client Joins': i['connectedClients'],
      'Client Disconnects': i['disconnectedClients']
    }));
  }
  return { data, info };
};

export const getAPUnqiueclientTrendTransform = propertyAPUniqueClient => {
  let {
    apUniqueClientCountTrend: data = [],
    Info = {}
  } = propertyAPUniqueClient;
  if (isArray(data) && !isEmpty(data)) {
    data = data.map(i => ({
      label: i['timestamp'],
      Clients: i['clientcount'],
      Sessions: i['sessioncount']
    }));
  }
  return { data, Info };
};

export const aleAccClientsTrendTransform = aleAccClientsTrend => {
  const trendArray = get(
    aleAccClientsTrend,
    'aleAccClientsTrend[0].countOfAccClientsInfo',
    []
  );
  let transformedArray = [];
  if (isArray(trendArray) && !isEmpty(trendArray)) {
    transformedArray = trendArray.map(item => ({
      label: item['timestamp'],
      'Total Clients': item['totalClients'],
      'New Clients': item['totalNewClientsAdded'],
      'Total Users': item['totalAccounts'],
      'New Users': item['totalNewAccAdded'],
      'Consumed VLANs': item['consumedVlans'],
      'Consumed VLANs (%)': item['consumedVlansPercent']
    }));
  }
  return {
    data: transformedArray,
    info: get(aleAccClientsTrend, 'aleAccClientsTrend[0].timezone')
  };
};

export const propertyClientBandwidthTrendTransform = data => {
  let {
    clientBandwidthTrend = [],
    clientBandwidthTrendInfo = {},
    clientBandwidthStats = {}
  } = data;
  if (isArray(clientBandwidthTrend) && !isEmpty(clientBandwidthTrend)) {
    clientBandwidthTrend = clientBandwidthTrend.map(i => ({
      label: i['timestamp'],
      download: i['bandwidthRX'],
      upload: i['bandwidthTX'],
      total: i['bandwidthRX'] + i['bandwidthTX']
    }));
  }
  return {
    clientBandwidthTrend,
    clientBandwidthTrendInfo,
    clientBandwidthStats
  };
};

export const propertyUserBandwidthTrendTransform = data => {
  let {
    userBandwidthTrend = [],
    userBandwidthTrendInfo = {},
    userBandwidthStats
  } = data;
  if (isArray(userBandwidthTrend) && !isEmpty(userBandwidthTrend)) {
    userBandwidthTrend = userBandwidthTrend.map(i => ({
      label: i['timestamp'],
      download: i['bandwidthRX'],
      upload: i['bandwidthTX'],
      total: i['bandwidthRX'] + i['bandwidthTX']
    }));
  }
  return { userBandwidthTrend, userBandwidthTrendInfo, userBandwidthStats };
};

export const apStatusTrendTransform = data => {
  if (!isArray(data) || isEmpty(data)) {
    return [];
  }
  return data.map(d => ({
    label: d['datetime'],
    'Connected APs': d['connect'],
    'Disconnected APs': d['disconnect']
  }));
};

export const propertyMqttClientBandwidthTrendTransform = data => {
  let {
    mqttbandwidthTrend = [],
    mqttbandwidthTrendInfo = {},
    mqttbandwidthStats = {}
  } = data;
  if (isArray(mqttbandwidthTrend) && !isEmpty(mqttbandwidthTrend)) {
    mqttbandwidthTrend = mqttbandwidthTrend.map(i => ({
      label: i['timestamp'],
      download: i['bandwidthRX'],
      upload: i['bandwidthTX'],
      total: i['bandwidthRX'] + i['bandwidthTX']
    }));
  }
  return { mqttbandwidthTrend, mqttbandwidthTrendInfo, mqttbandwidthStats };
};
