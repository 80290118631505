/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';

import TreeNode from './tree-node';

const TreeContainer = styled.ul`
  padding: 0;
  list-style: none;
  overflow: auto;
  width: 198px;
  height: calc(100vh - 275px);
  white-space: nowrap;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid ${styleLibrary.lightText};
    background-color: ${styleLibrary.darkBg};
  }

  ul {
    padding: 0;
    list-style: none;
    min-width: 250px;
  }
`;

class HierarchyTree extends Component {
  constructor(props) {
    super(props);

    this.treeRefs = new Map();
  }

  resetTree() {
    this.treeRefs.forEach(tree => {
      if (tree) {
        tree.forceCollapse(true);
      }
    });
  }

  render() {
    const { hierarchy, rootNode } = this.props;
    let nodeArray = rootNode.children;
    const treeNodes = nodeArray.map(child => hierarchy[child]);

    return (
      <TreeContainer id="tree-container" data-test-label="hierarchy-tree">
        {treeNodes &&
          treeNodes.map((node, i) => {
            return (
              <TreeNode
                ref={ref => this.treeRefs.set(i, ref)}
                {...this.props}
                key={node.id}
                autoExpandChildren={false}
                expanded={treeNodes.length === 1}
                node={node}
              />
            );
          })}
      </TreeContainer>
    );
  }
}

HierarchyTree.defaultProps = {
  rootNode: {
    children: []
  }
};

HierarchyTree.propTypes = {
  hierarchy: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  rootNode: PropTypes.object
};

export default HierarchyTree;
