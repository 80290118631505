/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { styleLibrary } from 'app/constants';

const MonthSelectionWrapper = styled.div.attrs({
  className: 'd-flex p-3 flex-column align-items-center'
})`
  min-width: 310px;
`;

const MonthContainer = styled.div.attrs({
  className: 'd-flex flex-wrap mt-3'
})`
  .ie & {
    width: 270px;
  }
`;

const MonthButton = styled.button.attrs(props => ({
  className: classNames(
    'btn btn-default text-center py-2',
    props.selected && 'in-range'
  ),
  disabled: props.future
}))`
  width: 90px;
  font-size: ${styleLibrary.fontSizes.body}px;
  box-shadow: none !important;

  &.in-range {
    background-color: rgb(53, 126, 189);
    color: #fff;
  }
`;

const generateYearRange = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

class MonthsSelection extends Component {
  constructor(props) {
    super(props);

    const { start, end } = props;
    this.state = {
      fromSelection: start,
      selectedYear: moment(start).year(),
      toSelection: end,
      firstSelection: true
    };
  }

  handleAbortSelection = e => {
    e.preventDefault();
    return this.props.handleDateSelection({ selectionAborted: true });
  };

  handleChangeYear = e => {
    const { fromSelection, toSelection } = this.state;
    const year = e.target.value;
    const fromMoment = moment(fromSelection).year(year);
    const toMoment = moment(toSelection).year(year);

    this.setState({
      fromSelection: fromMoment.toDate(),
      toSelection: toMoment.toDate(),
      selectedYear: year
    });
  };

  handleDatePick = monthIdx => {
    const { fromSelection, firstSelection, selectedYear } = this.state;

    const pickedMoment = moment([selectedYear, monthIdx]);
    const fromMoment = moment(fromSelection);

    if (firstSelection || pickedMoment.isBefore(fromMoment)) {
      return this.setState({
        fromSelection: pickedMoment.startOf('month').toDate(),
        toSelection: pickedMoment.endOf('month').toDate(),
        firstSelection: false
      });
    } else if (pickedMoment.isSame(fromMoment, 'month')) {
      return fromSelection;
    } else {
      const endOfRange = pickedMoment
        .clone()
        .add(1, 'months')
        .endOf('month');
      const maxEndOfRangeTwoMonths = fromMoment
        .clone()
        .add(1, 'months')
        .endOf('month');
      const maxEndOfRangeThreeMonths = fromMoment
        .clone()
        .add(2, 'months')
        .endOf('month');

      if (endOfRange.isAfter(maxEndOfRangeThreeMonths)) {
        // If it exceeds three months, reset the selection to the maximum range 3 months
        this.setState({
          toSelection: maxEndOfRangeThreeMonths.toDate()
        });
      } else if (endOfRange.isAfter(maxEndOfRangeTwoMonths)) {
        // If it exceeds two months, but not three, reset the selection to the maximum range 2 months
        this.setState({
          toSelection: maxEndOfRangeTwoMonths.toDate()
        });
      } else {
        this.setState({
          toSelection: endOfRange.toDate()
        });
      }
    }
    return this.setState({
      firstSelection: true
    });
  };

  handleFormSubmit = e => {
    e.preventDefault();
    const { fromSelection: start, toSelection: end } = this.state;
    return this.props.handleDateSelection({ start, end });
  };

  renderMonthGrid = () => {
    const { fromSelection, selectedYear, toSelection } = this.state;

    return (
      <MonthContainer>
        {moment.monthsShort().map((m, idx) => {
          const now = moment();
          const startMonth = moment(fromSelection).month();
          const toMonth = moment(toSelection).month();
          const selected = idx >= startMonth && idx <= toMonth;
          const inFuture = moment([selectedYear, idx]).isAfter(now);

          return (
            <Fragment key={m}>
              <MonthButton
                onClick={() => this.handleDatePick(idx)}
                selected={selected}
                future={inFuture}
              >
                {m}
              </MonthButton>
            </Fragment>
          );
        })}
      </MonthContainer>
    );
  };

  render() {
    const { fromSelection, selectedYear, toSelection } = this.state;
    const fromMoment = moment(fromSelection);
    const toMoment = moment(toSelection);
    const maxDate = moment.min(moment(), toMoment);

    return (
      <Fragment>
        <MonthSelectionWrapper>
          <select
            className="pl-2"
            onChange={this.handleChangeYear}
            value={selectedYear}
          >
            {generateYearRange(2017, moment().year(), 1).map(y => (
              <option key={y}>{y}</option>
            ))}
          </select>
          {this.renderMonthGrid()}
        </MonthSelectionWrapper>
        <form
          className="form-inline justify-content-end"
          onSubmit={this.handleFormSubmit}
        >
          {fromMoment.month() !== maxDate.month() && (
            <Fragment>
              <div className="form-group">
                <label htmlFor="from-date-selection" className="sr-only">
                  From date
                </label>
                <time id="from-date-selection" dateTime={fromSelection}>
                  {fromMoment.startOf('month').format('MMM')}
                </time>
              </div>
              <span className="ml-1 mr-1">&ndash;</span>
            </Fragment>
          )}
          <div className="form-group">
            <label htmlFor="to-date-selection" className="sr-only">
              To date
            </label>
            <time id="to-date-selection" dateTime={maxDate}>
              {maxDate.format('MMM YYYY')}
            </time>
          </div>
          <div className="my-2">
            <button type="submit" className="btn btn-primary btn-sm mx-2">
              OK
            </button>
            <button
              onClick={this.handleAbortSelection}
              className="btn btn-light btn-sm"
            >
              Cancel
            </button>
          </div>
        </form>
      </Fragment>
    );
  }
}

MonthsSelection.propTypes = {
  end: PropTypes.instanceOf(Date),
  handleDateSelection: PropTypes.func.isRequired,
  start: PropTypes.instanceOf(Date)
};

export default MonthsSelection;
