/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { takeEvery, call, put } from 'redux-saga/effects';

import { appMount } from '..';
import { getUserFromStorageSaga } from './user-login-sagas';
import { getChartDisplayModesSaga } from 'app/redux/app/sagas/chart-display-mode-saga';

function* initializeApplicationSaga() {
  yield call(getUserFromStorageSaga);
  yield call(getChartDisplayModesSaga);

  yield put(appMount());
}

export default function createInitSagas(types) {
  return [takeEvery(types.initApp, initializeApplicationSaga)];
}
