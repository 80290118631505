/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isInteger } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { castToNumber, formatNumericValue, zeroProtection } from 'app/utils';
import { BarPart, TotalWrapper } from '.';

export const OnePartBar = props => {
  const { barWidth, display, formatter, max } = props;
  const { value } = display;

  const totalBarWidth = castToNumber(
    Math.ceil(barWidth * (value / zeroProtection(max)))
  );
  const displayValue = formatter
    ? formatter(value)
    : formatNumericValue(value, isInteger(value) ? 0 : 1);

  return (
    <div className="d-flex justify-content-start">
      <div className="d-flex justify-content-start mr-2">
        <BarPart width={totalBarWidth} color={display.color} />
      </div>
      <TotalWrapper>{displayValue}</TotalWrapper>
    </div>
  );
};

OnePartBar.propTypes = {
  barWidth: PropTypes.number.isRequired,
  display: PropTypes.shape({
    value: PropTypes.number,
    color: PropTypes.string
  }).isRequired,
  formatter: PropTypes.func,
  max: PropTypes.number.isRequired
};
