/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { types as appTypes } from 'app/redux/app';
import {
  createAsyncTypes,
  createFetchStatePayloadCreator,
  createTypes,
  pendingFetchState,
  updateFetchStateReducer
} from 'app/redux/utils';
import { castToNumber, ensureIsArray, infinityProtection } from 'app/utils';
import { get, isArray, isEmpty } from 'lodash';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import createClientSessionsSagas from './fetch-client-sessions-saga';
import createFetchClientStatsSagas from './fetch-client-stats-saga';
import createFetchPropertyClientStatusSagas from './fetch-connected-clients-saga';
import createFetchPropertyClientsSagas from './fetch-property-clients-saga';
import createFetchPropertyUsersSagas from './fetch-property-users-saga';
import createFetchTopClientsSagas from './fetch-top-clients-saga';
import createFetchPropertyMqttClientsSagas from './fetch-property-mqtt-clients-saga';
import createFetchCBRsInventorySagas from './fetch-cbrs-inventory-saga';
import createFetchAleSessionsSagas from './fetch-ale-sessions-summary';
import {
  apStatusTrendTransform,
  clientStatusTrendTransform,
  getAPUnqiueclientTrendTransform,
  guestSubscriberTransform,
  propertyClientBandwidthTrendTransform,
  propertyMqttClientBandwidthTrendTransform,
  propertyUserBandwidthTrendTransform
} from './transforms';

export const nameSpace = 'clients';

const types = createTypes(
  [
    ...createAsyncTypes('fetchClientManufacturers'),
    ...createAsyncTypes('fetchClientOsTypes'),
    ...createAsyncTypes('fetchClientSessions'),
    ...createAsyncTypes('fetchGuestSubscriber'),
    ...createAsyncTypes('fetchTopClients'),
    // ...createAsyncTypes('fetchClientCount'),
    ...createAsyncTypes('fetchPropertyClientStatus'),
    ...createAsyncTypes('fetchPropertyAPUniqueClientTrend'),
    // ...createAsyncTypes('fetchTopUsers'),
    ...createAsyncTypes('fetchPropertyClients'),
    ...createAsyncTypes('fetchPropertyUsers'),
    ...createAsyncTypes('fetchPropertyClientBandwidthTrend'),
    ...createAsyncTypes('fetchPropertyUserBandwidthTrend'),
    ...createAsyncTypes('fetchPropertyUniqueClientsTrend'),
    ...createAsyncTypes('fetchPropertyUniqueUsersTrend'),
    ...createAsyncTypes('fetchPropertyUserDataUsage'),
    ...createAsyncTypes('fetchPropertyMqttClientDetails'),
    ...createAsyncTypes('fetchPropertyMqttClientBandwidthTrend'),
    ...createAsyncTypes('fetchCBRsInventorySummary'),
    ...createAsyncTypes('fetchCBRsDruidAPsTable'),
    ...createAsyncTypes('fetchCBRsDruidTrafficTrend'),
    ...createAsyncTypes('fetchAPStatusTrend'),
    ...createAsyncTypes('fetchAleSessionsSummary'),
    ...createAsyncTypes('fetchClientList'),
    ...createAsyncTypes('fetchUserList'),

    'updateClientOsTypesFetchState',
    'updateClientSessionsFetchState',
    'updateGuestSubscriberFetchState',
    'updateTopClientsFetchState',
    // 'updateClientCountFetchState'
    'updatePropertyClientStatusFetchState',
    'updatePropertyAPUniqueClientTrendFetchState',
    // 'updateTopUsersFetchState',
    'updatePropertyClientsFetchState',
    'updatePropertyUsersFetchState',
    'updatePropertyClientBandwidthTrendFetchState',
    'updatePropertyUserBandwidthTrendFetchState',
    'updatePropertyUniqueClientsTrendFetchState',
    'updatePropertyUniqueUsersTrendFetchState',
    'updatePropertyUserDataUsageFetchState',
    'updatePropertyMqttClientDetailsFetchState',
    'updateMqttClientBandwidthTrendFetchState',
    'updateCBRsInventorySummaryFetchState',
    'updateCBRsDruidAPsTableFetchState',
    'updateCBRsDruidTrafficTrendFetchState',
    'updateAPStatusTrendFetchState',
    'updateAleSessionSummaryFetchState',
    'updateClientListFetchState',
    'updateUserListFetchState'
  ],
  nameSpace
);

export const sagas = [
  ...createClientSessionsSagas(types),
  ...createFetchClientStatsSagas(types),
  ...createFetchTopClientsSagas(types),
  ...createFetchPropertyClientStatusSagas(types),
  // ...createFetchTopUsersSagas(types),
  ...createFetchPropertyClientsSagas(types),
  ...createFetchPropertyUsersSagas(types),
  ...createFetchPropertyMqttClientsSagas(types),
  ...createFetchCBRsInventorySagas(types),
  ...createFetchAleSessionsSagas(types)
];

export const fetchClientManufacturers = createAction(
  types.fetchClientManufacturers
);
export const fetchClientManufacturersComplete = createAction(
  types.fetchClientManufacturersComplete
);
export const fetchClientManufacturersError = createAction(
  types.fetchClientManufacturersError
);

export const fetchClientOsTypes = createAction(types.fetchClientOsTypes);
export const fetchClientOsTypesComplete = createAction(
  types.fetchClientOsTypesComplete
);
export const fetchClientOsTypesError = createAction(
  types.fetchClientOsTypesError
);

export const fetchClientSessions = createAction(types.fetchClientSessions);
export const fetchClientSessionsComplete = createAction(
  types.fetchClientSessionsComplete
);
export const fetchClientSessionsError = createAction(
  types.fetchClientSessionsError
);

export const fetchGuestSubscriber = createAction(types.fetchGuestSubscriber);
export const fetchGuestSubscriberComplete = createAction(
  types.fetchGuestSubscriberComplete
);
export const fetchGuestSubscriberError = createAction(
  types.fetchGuestSubscriberError
);

export const fetchTopClients = createAction(types.fetchTopClients);
export const fetchTopClientsComplete = createAction(
  types.fetchTopClientsComplete
);
export const fetchTopClientsError = createAction(types.fetchTopClientsError);

// export const fetchClientCount = createAction(types.fetchClientCount);
// export const fetchClientCountComplete = createAction(
//   types.fetchClientCountComplete
// );
// export const fetchClientCountError = createAction(types.fetchClientCountError);

export const fetchPropertyClientStatus = createAction(
  types.fetchPropertyClientStatus
);
export const fetchPropertyClientStatusComplete = createAction(
  types.fetchPropertyClientStatusComplete
);
export const fetchPropertyClientStatusError = createAction(
  types.fetchPropertyClientStatusError
);

export const fetchPropertyAPUniqueClientTrend = createAction(
  types.fetchPropertyAPUniqueClientTrend
);
export const fetchPropertyAPUniqueClientTrendComplete = createAction(
  types.fetchPropertyAPUniqueClientTrendComplete
);
export const fetchPropertyAPUniqueClientTrendError = createAction(
  types.fetchPropertyAPUniqueClientTrendError
);

// export const fetchTopUsers = createAction(types.fetchTopUsers);
// export const fetchTopUsersComplete = createAction(types.fetchTopUsersComplete);
// export const fetchTopUsersError = createAction(types.fetchTopUsersError);

export const fetchPropertyClients = createAction(types.fetchPropertyClients);
export const fetchPropertyClientsComplete = createAction(
  types.fetchPropertyClientsComplete
);
export const fetchPropertyClientsError = createAction(
  types.fetchPropertyClientsError
);

export const fetchPropertyUsers = createAction(types.fetchPropertyUsers);
export const fetchPropertyUsersComplete = createAction(
  types.fetchPropertyUsersComplete
);
export const fetchPropertyUsersError = createAction(
  types.fetchPropertyUsersError
);

export const fetchPropertyClientBandwidthTrend = createAction(
  types.fetchPropertyClientBandwidthTrend
);
export const fetchPropertyClientBandwidthTrendComplete = createAction(
  types.fetchPropertyClientBandwidthTrendComplete
);
export const fetchPropertyClientBandwidthTrendError = createAction(
  types.fetchPropertyClientBandwidthTrendError
);

export const fetchPropertyUserBandwidthTrend = createAction(
  types.fetchPropertyUserBandwidthTrend
);
export const fetchPropertyUserBandwidthTrendComplete = createAction(
  types.fetchPropertyUserBandwidthTrendComplete
);
export const fetchPropertyUserBandwidthTrendError = createAction(
  types.fetchPropertyUserBandwidthTrendError
);

export const fetchPropertyUniqueClientsTrend = createAction(
  types.fetchPropertyUniqueClientsTrend
);
export const fetchPropertyUniqueClientsTrendComplete = createAction(
  types.fetchPropertyUniqueClientsTrendComplete
);
export const fetchPropertyUniqueClientsTrendError = createAction(
  types.fetchPropertyUniqueClientsTrendError
);

export const fetchPropertyUniqueUsersTrend = createAction(
  types.fetchPropertyUniqueUsersTrend
);
export const fetchPropertyUniqueUsersTrendComplete = createAction(
  types.fetchPropertyUniqueUsersTrendComplete
);
export const fetchPropertyUniqueUsersTrendError = createAction(
  types.fetchPropertyUniqueUsersTrendError
);

export const fetchPropertyUserDataUsage = createAction(
  types.fetchPropertyUserDataUsage
);
export const fetchPropertyUserDataUsageComplete = createAction(
  types.fetchPropertyUserDataUsageComplete
);
export const fetchPropertyUserDataUsageError = createAction(
  types.fetchPropertyUserDataUsageError
);
export const fetchPropertyMqttClientDetails = createAction(
  types.fetchPropertyMqttClientDetails
);
export const fetchPropertyMqttClientDetailsComplete = createAction(
  types.fetchPropertyMqttClientDetailsComplete
);
export const fetchPropertyMqttClientDetailsError = createAction(
  types.fetchPropertyMqttClientDetailsError
);

export const fetchPropertyMqttClientBandwidthTrend = createAction(
  types.fetchPropertyMqttClientBandwidthTrend
);
export const fetchPropertyMqttClientBandwidthTrendComplete = createAction(
  types.fetchPropertyMqttClientBandwidthTrendComplete
);
export const fetchPropertyMqttClientBandwidthTrendError = createAction(
  types.fetchPropertyMqttClientBandwidthTrendError
);

export const fetchCBRsInventorySummary = createAction(
  types.fetchCBRsInventorySummary
);
export const fetchCBRsInventorySummaryComplete = createAction(
  types.fetchCBRsInventorySummaryComplete
);
export const fetchCBRsInventorySummaryError = createAction(
  types.fetchCBRsInventorySummaryError
);

export const fetchCBRsDruidAPsTable = createAction(
  types.fetchCBRsDruidAPsTable
);
export const fetchCBRsDruidAPsTableComplete = createAction(
  types.fetchCBRsDruidAPsTableComplete
);
export const fetchCBRsDruidAPsTableError = createAction(
  types.fetchCBRsDruidAPsTableError
);

export const fetchCBRsDruidTrafficTrend = createAction(
  types.fetchCBRsDruidTrafficTrend
);
export const fetchCBRsDruidTrafficTrendComplete = createAction(
  types.fetchCBRsDruidTrafficTrendComplete
);

export const fetchCBRsDruidTrafficTrendError = createAction(
  types.fetchCBRsDruidTrafficTrendError
);

export const fetchAPStatusTrend = createAction(types.fetchAPStatusTrend);
export const fetchAPStatusTrendComplete = createAction(
  types.fetchAPStatusTrendComplete
);
export const fetchAPStatusTrendError = createAction(
  types.fetchAPStatusTrendError
);

export const fetchAleSessionsSummary = createAction(
  types.fetchAleSessionsSummary
);
export const fetchAleSessionsSummaryComplete = createAction(
  types.fetchAleSessionsSummaryComplete
);
export const fetchAleSessionsSummaryError = createAction(
  types.fetchAleSessionsSummaryError
);

export const fetchClientList = createAction(types.fetchClientList);
export const fetchClientListComplete = createAction(
  types.fetchClientListComplete
);
export const fetchClientListError = createAction(types.fetchClientListError);

export const fetchUserList = createAction(types.fetchUserList);
export const fetchUserListComplete = createAction(types.fetchUserListComplete);
export const fetchUserListError = createAction(types.fetchUserListError);

export const updateClientManufacturersFetchState = createAction(
  types.updateClientSessionsFetchState,
  createFetchStatePayloadCreator('clientManufacturers')
);

export const updateClientOsTypesFetchState = createAction(
  types.updateClientSessionsFetchState,
  createFetchStatePayloadCreator('clientOsTypes')
);

export const updateClientSessionsFetchState = createAction(
  types.updateClientSessionsFetchState,
  createFetchStatePayloadCreator('clientSessions')
);

export const updateGuestSubscriberFetchState = createAction(
  types.updateGuestSubscriberFetchState,
  createFetchStatePayloadCreator('guestSubscriber')
);

export const updateTopClientsFetchState = createAction(
  types.updateTopClientsFetchState,
  createFetchStatePayloadCreator('topClients')
);

export const updatePropertyClientStatusFetchState = createAction(
  types.updatePropertyClientStatusFetchState,
  createFetchStatePayloadCreator('propertyClientStatus')
);
export const updatePropertyAPUniqueClientTrendFetchState = createAction(
  types.updatePropertyAPUniqueClientTrendFetchState,
  createFetchStatePayloadCreator('propertyAPUniqueClient')
);

// export const updateClientCountFetchState = createAction(
//   types.updateClientCountFetchState,
//   createFetchStatePayloadCreator('clientCount')
// );

// export const updateTopUsersFetchState = createAction(
//   types.updateTopUsersFetchState,
//   createFetchStatePayloadCreator('topUsers')
// );

export const updatePropertyClientsFetchState = createAction(
  types.updatePropertyClientsFetchState,
  createFetchStatePayloadCreator('propertyClients')
);

export const updatePropertyUsersFetchState = createAction(
  types.updatePropertyUsersFetchState,
  createFetchStatePayloadCreator('propertyUsers')
);

export const updatePropertyClientBandwidthTrendFetchState = createAction(
  types.updatePropertyClientBandwidthTrendFetchState,
  createFetchStatePayloadCreator('propertyClientBandwidthTrend')
);

export const updatePropertyUserBandwidthTrendFetchState = createAction(
  types.updatePropertyUserBandwidthTrendFetchState,
  createFetchStatePayloadCreator('propertyUserBandwidthTrend')
);

export const updatePropertyUniqueClientsTrendFetchState = createAction(
  types.updatePropertyUniqueClientsTrendFetchState,
  createFetchStatePayloadCreator('propertyUniqueClientsTrend')
);

export const updatePropertyUniqueUsersTrendFetchState = createAction(
  types.updatePropertyUniqueUsersTrendFetchState,
  createFetchStatePayloadCreator('propertyUniqueUsersTrend')
);

export const updatePropertyUserDataUsageFetchState = createAction(
  types.updatePropertyUserDataUsageFetchState,
  createFetchStatePayloadCreator('propertyUserDataUsage')
);
export const updatePropertyMqttClientDetailsFetchState = createAction(
  types.updatePropertyMqttClientDetailsFetchState,
  createFetchStatePayloadCreator('propertyMqttClientDetails')
);

export const updateMqttClientBandwidthTrendFetchState = createAction(
  types.updateMqttClientBandwidthTrendFetchState,
  createFetchStatePayloadCreator('propertyMqttClientBandwidthTrend')
);

export const updateCBRsInventorySummaryFetchState = createAction(
  types.updateCBRsInventorySummaryFetchState,
  createFetchStatePayloadCreator('propertyCBRsInventory')
);

export const updateCBRsDruidAPsTableFetchState = createAction(
  types.updateCBRsDruidAPsTableFetchState,
  createFetchStatePayloadCreator('propertyCBRsDruidAPsData')
);

export const updateCBRsDruidTrafficTrendFetchState = createAction(
  types.updateCBRsDruidTrafficTrendFetchState,
  createFetchStatePayloadCreator('propertyCBRsDruidTrafficTrend')
);

export const updateAPStatusTrendFetchState = createAction(
  types.updateAPStatusTrendFetchState,
  createFetchStatePayloadCreator('apStatusTrend')
);

export const updateAleSessionSummaryFetchState = createAction(
  types.updateAleSessionSummaryFetchState,
  createFetchStatePayloadCreator('aleSessionsSummary')
);

export const updateClientListFetchState = createAction(
  types.updateClientListFetchState,
  createFetchStatePayloadCreator('clientList')
);

export const updateUserListFetchState = createAction(
  types.updateUserListFetchState,
  createFetchStatePayloadCreator('userListData')
);

export const clientManufacturersSelector = state =>
  state[nameSpace].clientManufacturers;
// export const manufacturerChartDataSelector = createSelector(
//   clientManufacturersSelector,
//   (manufacturerData = {}) => manufacturerTransform(manufacturerData)
// );

export const clientOsTypesSelector = state => state[nameSpace].clientOsTypes;
// export const clientOsChartDataSelector = createSelector(
//   clientOsTypesSelector,
//   clientOsTypes => clientOsTransform(clientOsTypes)
// );

export const guestSubscriberSelector = state =>
  state[nameSpace].guestSubscriber;
export const guestSubscriberChartDataSelector = createSelector(
  guestSubscriberSelector,
  guestSubscriber => guestSubscriberTransform(guestSubscriber)
);

export const topClientsSelector = state => state[nameSpace].topClients;
export const top10ClientsSelector = createSelector(
  topClientsSelector,
  clients => {
    return isEmpty(clients.bandwidthDetails)
      ? []
      : clients.bandwidthDetails.slice(0, 10);
  }
);
export const top10ClientsInfoSelector = state => topClientsSelector(state).info;
export const top10ClientsDurationUnitSelector = state =>
  get(top10ClientsInfoSelector(state), 'durationUnit', 'hours').toLowerCase();

export const clientSessionsSelector = state => state[nameSpace].clientSessions;
export const clientSessionsArraySelector = createSelector(
  clientSessionsSelector,
  (sessions = {}) => {
    const { details = [] } = sessions;
    return details;
  }
);
export const maxClientSessionValuesSelector = createSelector(
  clientSessionsArraySelector,
  sessions => {
    return {
      bandwidth: infinityProtection(
        Math.max(...sessions.map(s => s.bandwidthRXInMB + s.bandwidthTXInMB))
      ),
      duration: infinityProtection(
        Math.max(...sessions.map(s => s.sessionDurationInSec))
      )
    };
  }
);

export const maxClientValuesSelector = createSelector(
  top10ClientsSelector,
  clients => {
    return {
      bandwidth: infinityProtection(
        Math.max(
          ...clients.map(
            c =>
              castToNumber(c.bandwidthRXInMB) + castToNumber(c.bandwidthTXInMB)
          )
        )
      ),
      sessions: infinityProtection(
        Math.max(...clients.map(c => castToNumber(c.sessionCount)))
      ),
      duration: infinityProtection(
        Math.max(...clients.map(c => castToNumber(c.sessionDuration)))
      ),
      client: infinityProtection(
        Math.max(...clients.map(c => castToNumber(c.client)))
      )
    };
  }
);
export const totalClientValuesSelector = createSelector(
  topClientsSelector,
  top10ClientsSelector,
  (allClients, top10Clients) => {
    const allClientsBW = isEmpty(allClients)
      ? []
      : ensureIsArray(allClients.bandwidthDetails);
    return {
      bandwidth: allClientsBW.reduce(
        (total, client) =>
          total + client.bandwidthTXInMB + client.bandwidthRXInMB,
        0
      ),
      top10Bandwidth: top10Clients.reduce(
        (total, client) =>
          total + client.bandwidthTXInMB + client.bandwidthRXInMB,
        0
      )
    };
  }
);

export const top10ClientsSelectorForLAC = createSelector(
  topClientsSelector,
  clients => {
    return isEmpty(clients.clientBandwidthDetails)
      ? []
      : clients.clientBandwidthDetails.slice(0, 10);
  }
);
export const topClientsInfoSelector = state => topClientsSelector(state).Info;
export const maxClientValuesSelectorForLAC = createSelector(
  top10ClientsSelectorForLAC,
  clients => {
    return {
      bandwidth: infinityProtection(
        Math.max(
          ...clients.map(
            c =>
              castToNumber(c.bandwidthrx) + castToNumber(c.bandwidthtx)
          )
        )
      )
    };
  }
);

// export const clientCountByDevicesSelector = state =>
//   state[nameSpace].clientCount;
// export const clientCountSelector = createSelector(
//   clientCountByDevicesSelector,
//   clientCount => get(clientCount, 'client', 0)
// );
// export const clientOsChartDataSelector = createSelector(
//   clientCountByDevicesSelector,
//   clientCount => clientOsTransform(clientCount)
// );
// export const manufacturerChartDataSelector = createSelector(
//   clientCountByDevicesSelector,
//   (clientCount = {}) => manufacturerTransform(clientCount)
// );

// export const topUsersSelector = state => {
//   const { bandwidthDetails: data = [], info = {} } = state[nameSpace].topUsers;
//   return { data, info };
// };
// export const maxUserValuesSelector = createSelector(
//   topUsersSelector,
//   ({ data }) => ({
//     bandwidth: infinityProtection(
//       Math.max(...data.map(d => castToNumber(d.bandwidthSumInMB)))
//     ),
//     clients: infinityProtection(
//       Math.max(...data.map(d => size(uniqBy(d.ClientDetails, 'clientMAC'))))
//     )
//   })
// );

export const propertyClientStatusSelector = state =>
  clientStatusTrendTransform(state[nameSpace].propertyClientStatus);

export const propertyAPUniqueClientTrendSelector = state =>
  getAPUnqiueclientTrendTransform(state[nameSpace].propertyAPUniqueClient);

export const propertyClientsSelector = state => {
  const { clientsList: data = [], info = {} } = state[
    nameSpace
  ].propertyClients;
  return { data, info };
};
export const maxPropertyClientValuesSelector = createSelector(
  propertyClientsSelector,
  ({ data }) => {
    if (!isArray(data) || isEmpty(data)) {
      return { bandwidth: 0 };
    }
    return {
      bandwidth: infinityProtection(
        Math.max(
          ...data.map(
            d => castToNumber(d.bandwidthrx) + castToNumber(d.bandwidthtx)
          )
        )
      )
    };
  }
);

export const propertyUsersSelector = state => {
  const { usersList: data = [], info = {} } = state[nameSpace].propertyUsers;
  return { data, info };
};
export const maxPropertyUserValuesSelector = createSelector(
  propertyUsersSelector,
  ({ data }) => {
    if (!isArray(data) || isEmpty(data)) {
      return { bandwidth: 0 };
    }
    return {
      bandwidth: infinityProtection(
        Math.max(
          ...data.map(
            d => castToNumber(d.bandwidthrx) + castToNumber(d.bandwidthtx)
          )
        )
      ),
      clients: infinityProtection(
        Math.max(...data.map(d => castToNumber(d.clientCount)))
      )
    };
  }
);

export const propertyClientBandwidthTrendSelector = state => {
  const {
    clientBandwidthTrend: data = [],
    clientBandwidthStats: stats = {},
    clientBandwidthTrendInfo: info = {}
  } = propertyClientBandwidthTrendTransform(
    state[nameSpace].propertyClientBandwidthTrend
  );
  return { data, stats, info };
};

export const propertyUserBandwidthTrendSelector = state => {
  const {
    userBandwidthTrend: data = [],
    userBandwidthStats: stats = {},
    userBandwidthTrendInfo: info = {}
  } = propertyUserBandwidthTrendTransform(
    state[nameSpace].propertyUserBandwidthTrend
  );
  return { data, stats, info };
};

export const propertyUniqueClientsTrendSelector = state => {
  const {
    uniqueClientTrend: data = [],
    uniqueClientStats: stats = {},
    info = {}
  } = state[nameSpace].propertyUniqueClientsTrend;
  return { data, stats, info };
};

export const propertyUniqueUsersTrendSelector = state => {
  const {
    uniqueUserTrend: data = [],
    unqiueUserStats: stats = {},
    info = {}
  } = state[nameSpace].propertyUniqueUsersTrend;
  return { data, stats, info };
};

export const propertyUserDataUsageSelector = state => {
  const data = get(
    state[nameSpace].propertyUserDataUsage,
    'UserBandwidthDetails.UserClientDetails',
    []
  );
  const info = get(
    state[nameSpace].propertyUserDataUsage,
    'UserBandwidthDetails.UserClientInfo',
    {}
  );
  return { data, info };
};

export const propertyUserDataUsageMaxValuesSelector = createSelector(
  propertyUserDataUsageSelector,
  ({ data }) => {
    if (!isArray(data) || isEmpty(data)) {
      return { bandwidth: 0 };
    }
    return {
      bandwidth: infinityProtection(
        Math.max(
          ...data.map(
            d =>
              castToNumber(d.bandwidthRXInMB) + castToNumber(d.bandwidthTXInMB)
          )
        )
      )
    };
  }
);
export const propertyMqttClientDetailsSelector = state => {
  const { clientsList: data = [], info = {} } = state[
    nameSpace
  ].propertyMqttClientDetails;
  return { data, info };
};

export const propertyMqttClientDetailsMaxValuesSelector = createSelector(
  propertyMqttClientDetailsSelector,
  ({ data }) => {
    if (!isArray(data) || isEmpty(data)) {
      return { bandwidth: 0 };
    }
    return {
      bandwidth: infinityProtection(
        Math.max(
          ...data.map(
            d => castToNumber(d.bandwidthrx) + castToNumber(d.bandwidthtx)
          )
        )
      )
    };
  }
);

export const mqttClientBandwidthTrendSelector = state => {
  const {
    mqttbandwidthTrend: data = [],
    mqttbandwidthTrendInfo: info = {},
    mqttbandwidthStats: stats = {}
  } = propertyMqttClientBandwidthTrendTransform(
    state[nameSpace].propertyMqttClientBandwidthTrend
  );
  return { data, stats, info };
};

export const cbrInventorySummarySelector = state => {
  const { CBRSInventory } = state[nameSpace].propertyCBRsInventory;
  return { CBRSInventory };
};

export const cbrsDruidAPsTableSelector = state => {
  const { CBRSAPS: data = [] } = state[nameSpace].propertyCBRsDruidAPsData;

  return { data };
};

export const cbrsDruidTrafficTrendSelector = state => {
  const { TrafficTrendData } = state[nameSpace].propertyCBRsDruidTrafficTrend;
  return { TrafficTrendData };
};

export const apStatusTrendSelector = state => {
  const { ap_status_trend: data = [], ap_status_trendInfo: info = {} } = state[
    nameSpace
  ].apStatusTrend;
  return { data: apStatusTrendTransform(data), info };
};

export const aleSessionsSummarySelector = state => {
  const { activeALESessions: data = [], aleSessionInfo: info = {} } = state[
    nameSpace
  ].aleSessionsSummary;
  return { data, info };
};

export const clientListDataSelector = state => {
  const { aleDPSKResidentList: data = [], info = {} } = state[
    nameSpace
  ].clientList;
  return { data, info };
};

export const userListDataSelector = state => {
  const { aleListOfUserReport: data = [] } = state[nameSpace].userListData;
  return { data };
};

const fetchStatesSelector = state => state[nameSpace].fetchStates;
export const clientManufacturersFetchStateSelector = state =>
  fetchStatesSelector(state).clientManufacturers;
export const clientOsTypesFetchStateSelector = state =>
  fetchStatesSelector(state).clientOsTypes;
export const clientSessionsFetchStateSelector = state =>
  fetchStatesSelector(state).clientSessions;
export const guestSubscriberFetchStateSelector = state =>
  fetchStatesSelector(state).guestSubscriber;
export const topClientsFetchStateSelector = state =>
  fetchStatesSelector(state).topClients;
// export const clientCountFetchStateSelector = state =>
//   fetchStatesSelector(state).clientCount;
// export const topUsersFetchStateSelector = state =>
//   fetchStatesSelector(state).topUsers;
export const propertyAPUniqueClientTrendFetchStateSelector = state =>
  fetchStatesSelector(state).propertyAPUniqueClient;
export const propertyClientStatusFetchStateSelector = state =>
  fetchStatesSelector(state).propertyClientStatus;
export const propertyClientsFetchStateSelector = state =>
  fetchStatesSelector(state).propertyClients;
export const propertyUsersFetchStateSelector = state =>
  fetchStatesSelector(state).propertyUsers;
export const propertyClientBandwidthTrendFetchStateSelector = state =>
  fetchStatesSelector(state).propertyClientBandwidthTrend;
export const propertyUserBandwidthTrendFetchStateSelector = state =>
  fetchStatesSelector(state).propertyUserBandwidthTrend;
export const propertyUniqueClientsTrendFetchStateSelector = state =>
  fetchStatesSelector(state).propertyUniqueClientsTrend;
export const propertyUniqueUsersTrendFetchStateSelector = state =>
  fetchStatesSelector(state).propertyUniqueUsersTrend;
export const propertyUserDataUsageFetchStateSelector = state =>
  fetchStatesSelector(state).propertyUserDataUsage;
export const propertyMqttClientDetailsFetchStateSelector = state =>
  fetchStatesSelector(state).propertyMqttClientDetails;
export const mqttClientBandwidthTrendFetchStateSelector = state =>
  fetchStatesSelector(state).propertyMqttClientBandwidthTrend;
export const cbrInventorySummaryFetchStateSelector = state =>
  fetchStatesSelector(state).propertyCBRsInventory;
export const cbrsDruidAPsTableFetchStateSelector = state =>
  fetchStatesSelector(state).propertyCBRsDruidAPsData;
export const cbrsDruidTrafficTrendFetchStateSelector = state =>
  fetchStatesSelector(state).propertyCBRsDruidTrafficTrend;
export const apStatusTrendFetchStateSelector = state =>
  fetchStatesSelector(state).apStatusTrend;
export const aleSessionsSummaryFetchStateSelector = state =>
  fetchStatesSelector(state).aleSessionsSummary;
export const clientListFetchStateSelector = state =>
  fetchStatesSelector(state).clientList;
export const userListFetchStateSelector = state =>
  fetchStatesSelector(state).userListData;

const initialState = {
  fetchStates: {
    clientOsTypes: pendingFetchState,
    clientManufacturers: pendingFetchState,
    clientSessions: pendingFetchState,
    guestSubscriber: pendingFetchState,
    topClients: pendingFetchState,
    propertyClientStatus: pendingFetchState,
    // clientCount: pendingFetchState
    // topUsers: pendingFetchState,
    propertyAPUniqueClient: pendingFetchState,
    propertyClients: pendingFetchState,
    propertyUsers: pendingFetchState,
    propertyClientBandwidthTrend: pendingFetchState,
    propertyUserBandwidthTrend: pendingFetchState,
    propertyUniqueClientsTrend: pendingFetchState,
    propertyUniqueUsersTrend: pendingFetchState,
    propertyUserDataUsage: pendingFetchState,
    propertyMqttClientDetails: pendingFetchState,
    propertyMqttClientBandwidthTrend: pendingFetchState,
    propertyCBRsInventory: pendingFetchState,
    propertyCBRsDruidAPsData: pendingFetchState,
    propertyCBRsDruidTrafficTrend: pendingFetchState,
    apStatusTrend: pendingFetchState,
    aleSessionsSummary: pendingFetchState,
    clientList: pendingFetchState,
    userListData: pendingFetchState
  },
  clientManufacturers: {},
  clientOsTypes: {},
  clientSessions: {},
  guestSubscriber: {},
  topClients: {},
  propertyClientStatus: {},
  propertyAPUniqueClient: {},
  // clientCount: {}
  // topUsers: {},
  propertyClients: {},
  propertyUsers: {},
  propertyClientBandwidthTrend: {},
  propertyUserBandwidthTrend: {},
  propertyUniqueClientsTrend: {},
  propertyUniqueUsersTrend: {},
  propertyUserDataUsage: {},
  propertyMqttClientDetails: {},
  propertyMqttClientBandwidthTrend: {},
  propertyCBRsInventory: {},
  propertyCBRsDruidAPsData: {},
  propertyCBRsDruidTrafficTrend: {},
  apStatusTrend: {},
  aleSessionsSummary: {},
  clientList: {},
  userListData: {}
};

export const reducer = handleActions(
  {
    [types.fetchClientManufacturersComplete]: (state, { payload }) => ({
      ...state,
      clientManufacturers: payload
    }),
    [types.fetchClientOsTypesComplete]: (state, { payload }) => ({
      ...state,
      clientOsTypes: payload
    }),
    [types.fetchClientSessionsComplete]: (state, { payload }) => ({
      ...state,
      clientSessions: payload
    }),
    [types.fetchGuestSubscriberComplete]: (state, { payload }) => ({
      ...state,
      guestSubscriber: payload
    }),
    [types.fetchTopClientsComplete]: (state, { payload }) => ({
      ...state,
      topClients: payload
    }),
    // [types.fetchClientCountComplete]: (state, { payload }) => ({
    //   ...state,
    //   clientCount: payload
    // }),
    [types.fetchPropertyClientStatusComplete]: (state, { payload }) => ({
      ...state,
      propertyClientStatus: payload
    }),
    [types.fetchPropertyAPUniqueClientTrendComplete]: (state, { payload }) => ({
      ...state,
      propertyAPUniqueClient: payload
    }),
    // [types.fetchTopUsersComplete]: (state, { payload }) => ({
    //   ...state,
    //   topUsers: payload
    // }),
    [types.fetchPropertyClientsComplete]: (state, { payload }) => ({
      ...state,
      propertyClients: payload
    }),
    [types.fetchPropertyUsersComplete]: (state, { payload }) => ({
      ...state,
      propertyUsers: payload
    }),
    [types.fetchPropertyClientBandwidthTrendComplete]: (
      state,
      { payload }
    ) => ({
      ...state,
      propertyClientBandwidthTrend: payload
    }),
    [types.fetchPropertyUserBandwidthTrendComplete]: (state, { payload }) => ({
      ...state,
      propertyUserBandwidthTrend: payload
    }),
    [types.fetchPropertyUniqueClientsTrendComplete]: (state, { payload }) => ({
      ...state,
      propertyUniqueClientsTrend: payload
    }),
    [types.fetchPropertyUniqueUsersTrendComplete]: (state, { payload }) => ({
      ...state,
      propertyUniqueUsersTrend: payload
    }),
    [types.fetchPropertyUserDataUsageComplete]: (state, { payload }) => ({
      ...state,
      propertyUserDataUsage: payload
    }),
    [types.fetchPropertyMqttClientDetailsComplete]: (state, { payload }) => ({
      ...state,
      propertyMqttClientDetails: payload
    }),
    [types.fetchPropertyMqttClientBandwidthTrendComplete]: (
      state,
      { payload }
    ) => ({
      ...state,
      propertyMqttClientBandwidthTrend: payload
    }),
    [types.fetchCBRsInventorySummaryComplete]: (state, { payload }) => ({
      ...state,
      propertyCBRsInventory: payload
    }),
    [types.fetchCBRsDruidAPsTableComplete]: (state, { payload }) => ({
      ...state,
      propertyCBRsDruidAPsData: payload
    }),
    [types.fetchCBRsDruidTrafficTrendComplete]: (state, { payload }) => ({
      ...state,
      propertyCBRsDruidTrafficTrend: payload
    }),
    [types.fetchAPStatusTrendComplete]: (state, { payload }) => ({
      ...state,
      apStatusTrend: payload
    }),
    [types.fetchAleSessionsSummaryComplete]: (state, { payload }) => ({
      ...state,
      aleSessionsSummary: payload
    }),
    [types.fetchClientListComplete]: (state, { payload }) => ({
      ...state,
      clientList: payload
    }),
    [types.fetchUserListComplete]: (state, { payload }) => ({
      ...state,
      userListData: payload
    }),

    [types.updateClientOsTypesFetchState]: updateFetchStateReducer,
    [types.updateClientSessionsFetchState]: updateFetchStateReducer,
    [types.updateGuestSubscriberFetchState]: updateFetchStateReducer,
    [types.updateTopClientsFetchState]: updateFetchStateReducer,
    [types.updatePropertyClientStatusFetchState]: updateFetchStateReducer,
    // [types.updateClientCountFetchState]: updateFetchStateReducer,
    // [types.updateTopUsersFetchState]: updateFetchStateReducer,
    [types.updatePropertyAPUniqueClientTrendFetchState]: updateFetchStateReducer,
    [types.updatePropertyClientsFetchState]: updateFetchStateReducer,
    [types.updatePropertyUsersFetchState]: updateFetchStateReducer,
    [types.updatePropertyClientBandwidthTrendFetchState]: updateFetchStateReducer,
    [types.updatePropertyUserBandwidthTrendFetchState]: updateFetchStateReducer,
    [types.updatePropertyUniqueClientsTrendFetchState]: updateFetchStateReducer,
    [types.updatePropertyUniqueUsersTrendFetchState]: updateFetchStateReducer,
    [types.updatePropertyUserDataUsageFetchState]: updateFetchStateReducer,
    [types.updatePropertyMqttClientDetailsFetchState]: updateFetchStateReducer,
    [types.updateMqttClientBandwidthTrendFetchState]: updateFetchStateReducer,
    [types.updateCBRsInventorySummaryFetchState]: updateFetchStateReducer,
    [types.updateCBRsDruidAPsTableFetchState]: updateFetchStateReducer,
    [types.updateCBRsDruidTrafficTrendFetchState]: updateFetchStateReducer,
    [types.updateAPStatusTrendFetchState]: updateFetchStateReducer,
    [types.updateAleSessionSummaryFetchState]: updateFetchStateReducer,
    [types.updateClientListFetchState]: updateFetchStateReducer,
    [types.updateUserListFetchState]: updateFetchStateReducer,

    [appTypes.appReset]: () => initialState
  },
  initialState
);
