/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import { Logo } from 'app/components/elements';
import { niVersion, copyright, appBuild, styleLibrary } from 'app/constants';

import { ModalWrapper, ModalBox, Spacer, Link } from './components';

const LicenseWrapper = styled.div.attrs({
  className: 'text-left'
})`
  font-size: 80%;

  & hr {
    border-top: 1px solid ${styleLibrary.containerBorderColor};
  }
`;

class About extends Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleNonModalClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleNonModalClick);
  }

  handleNonModalClick = e => {
    if (!this.modalRef.current.contains(e.target)) {
      this.props.closeModal();
    }
  };

  render() {
    return (
      <ModalWrapper data-test-label="about-modal">
        <div
          className={
            'd-flex justify-content-center align-items-center flex-column h-100'
          }
        >
          <ModalBox ref={this.modalRef} modalWidth="680px">
            <Spacer />
            <Logo className="pt-3" dark style={{ height: 32 }} />
            <Spacer />
            <p style={{ textAlign: 'center' }}>
              {niVersion}
              <br />
              (Build: {appBuild})
            </p>
            <p>{copyright}</p>
            <p>
              <Link
                data-test-label="about-modal-about-link"
                href="https://www.arris.com/solutions/carrier-grade-wi-fi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here for more information
              </Link>
            </p>
            <LicenseWrapper>
              <hr />
              <b>License Information</b>
              <p>
                This product includes software provided by the{' '}
                <Link
                  href="http://www.apache.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Apache Software Foundation
                </Link>
                .
              </p>
              <p>
                This product also includes the following components which are
                covered by the GNU LGPL license:
              </p>
              <ul>
                <li>
                  Jackson JAX RS (
                  <Link
                    href="https://mvnrepository.com/artifact/org.codehaus.jackson/jackson-jaxrs/1.8.3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    1.8.3
                  </Link>
                  )
                </li>
                <li>
                  Jackson XC (
                  <Link
                    href="https://mvnrepository.com/artifact/org.codehaus.jackson/jackson-xc/1.8.3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    1.8.3
                  </Link>
                  )
                </li>{' '}
                <li>
                  Javassist (
                  <Link
                    href="https://github.com/jboss-javassist/javassist"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    3.18.1
                  </Link>
                  )
                </li>
                <li>
                  PyZabbix (
                  <Link
                    href="https://github.com/lukecyca/pyzabbix"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    0.7.5
                  </Link>
                  )
                </li>
              </ul>
              <p>
                This product also contains components written by other third
                parties.
              </p>
              <hr />
            </LicenseWrapper>
            <button
              className="btn btn-primary"
              data-test-label="about-modal-close-button"
              onClick={() => this.props.closeModal()}
            >
              Close
            </button>
            <Spacer />
          </ModalBox>
        </div>
      </ModalWrapper>
    );
  }
}

About.propTypes = { closeModal: PropTypes.func.isRequired };

export default About;
