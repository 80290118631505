/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isInteger, isNaN, isNumber, isString, round } from 'lodash';

/**
 * This function derives the least number of decimal places common to
 * a list of numbers that it is given
 *
 * @method getDecimalPlaceParityNumber
 * @param {Array<Number>} numbers A list of numbers
 * @returns {Number} The lowest number of decimal places common to each number
 *
 * @example
 * const floats = [1.0026, 15.8523625, 200.4563, 0.123456789];
 * const dpParityNo = getDecimalPlaceParityNumber(floats); // returns 4
 */
export const getDecimalPlaceParityNumber = (numbers = [0]) => {
  if (numbers.length === 0) {
    return 0;
  }
  return numbers.reduce((min, current) => {
    const currentDp = countDecimals(current);
    return currentDp < min ? currentDp : min;
  }, Number.MAX_SAFE_INTEGER);
};

const countDecimals = value => {
  if (Math.floor(value) === value) {
    return 0;
  }
  const [, dp] = value.toString().split('.');
  return dp.length;
};
/**
 * This functions formats a number into an "X,XXX,XXX.XX" format, depending on the required number
 * of decimal places.
 *
 * If the value received is not a number, it is simply returned as is.
 *
 * @method formatNumericValue
 * @param {*} value The numeric value we wish to be formatted
 * @param {Number} decimals The number of decimals places we want the value rounded to
 * @returns {String} A formatted number with thousand separators and the spec'ed number of decimal places.
 */
export const formatNumericValue = (value, decimals = 0) => {
  if (typeof value !== 'number' || !Number.isFinite(value)) {
    return value;
  }
  // let result = decimals === 0 ? Math.round(value).toString() : value.toFixed(decimals);
  // const resultAbs = Math.abs(result);
  // const resultDecimal = resultAbs - Math.round(resultAbs);
  // result = resultDecimal === 0 ? value.toFixed(0) : result;

  const result = round(value, decimals);
  if (result < 1000) {
    return result.toString();
  }

  // Return the result with thousands separators included
  // return result.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return new Intl.NumberFormat('en-US').format(result);
};

/**
 * This function returns a sort function to be used on an array of objects, optionally based
 * on descending order.
 *
 * The "property" should reference a numerical value on which to sort.
 *
 * @method sortByProperty
 * @param {String} property The property on which to sort on
 * @param {Boolean} descending Whether to apply descending sort order to the result
 * @returns {Function} A sort function
 */
export const sortByProperty = (property, descending = false) => {
  /**
   * @param {Object<String, Number>} a An array item to compare
   * @param {Object<String, Number>} b An array item to compare
   * @returns {Number} A number used by Array.sort()
   */
  return (a, b) => {
    let val1 = a[property];
    let val2 = b[property];
    let result = 0;

    // If we're dealing with strings, make them lower case so the sorting makes sense to a human!
    if (typeof val1 === 'string' && typeof val2 === 'string') {
      val1 = val1.toLowerCase();
      val2 = val2.toLowerCase();
    }

    if (val1 < val2) {
      result = -1;
    }
    if (val1 > val2) {
      result = 1;
    }

    return result * (descending ? -1 : 1);
  };
};

/**
 * This function returns a sum function to be used on an array of objects
 *
 * The "property" should reference a numerical value on which to sum
 *
 * @method sumByProperty
 * @param {String} property The property on which to sum
 * @returns {sumByProperty~sumFn} A sum function
 */
export const sumByProperty = property => {
  /**
   * @param {Number} total The running total
   * @param {Object<String, Number>} current The current array item to add to the total
   * @returns {Number} The total sum of the array
   */
  const sumFn = (total, current) => total + castToNumber(current[property]);
  return sumFn;
};

/**
 * This function take one parameter of any type and ensures a number is returned
 *
 * @method castToNumber
 * @param {*} maybeNumber The possible numeric value we wish to ensure is a number
 * @returns {Number} A number that defaults to zero.
 */
export function castToNumber(maybeNumber) {
  if (isNaN(maybeNumber)) {
    return 0;
  }
  if (isNumber(maybeNumber)) {
    return maybeNumber;
  }
  if (isString(maybeNumber)) {
    return castToNumber(parseFloat(maybeNumber));
  }
  return 0;
}

/**
 * This functions format ensures the given number is not zero.
 *
 * If the number is found to be zero, one (1) is returned
 *
 * @method zeroProtection
 * @param {Number} number A number we wish NOT to be zero
 * @returns {Number} A non-zero number, defaults to one (1)
 */
export const zeroProtection = number => (number ? number : 1);

/**
 * This functions format ensures the given number is not Infinity.
 *
 * If the number is found to be (+/-)infinity, zero (0) is returned
 *
 * @method infinityProtection
 * @param {Number} number A number we wish NOT to be (+/-)infinity
 * @returns {Number} A non-infinity number, defaults to zero (0)
 */
export const infinityProtection = number => (isFinite(number) ? number : 0);

/**
 * This function converts a given value to the a scaled value based on units e.g. converting MB to GB, etc.
 *
 * @param {Number} value The base value we want to scale
 * @param {Array} units The list of units to be cycled through
 * @param {String} startingUnit The first unit in the array to apply (if we know what it is)
 * @param {Number} decimals The number of decimal places to apply to the final formatted result
 * @returns {Object} Containing the final formatted value and the unit used
 */
/* eslint-disable-next-line max-params */
export const getScaledValue = (
  value,
  units,
  startingUnit,
  decimals = 1,
  factor = 1024
) => {
  let idx = startingUnit ? units.indexOf(startingUnit) : 0;
  idx = idx < 0 ? 0 : idx;
  let unit = units[idx];
  let display = value;

  while (display >= factor && units.length - 1 > idx) {
    idx++;
    unit = units[idx];
    display = display / factor;
  }

  return {
    result: formatNumericValue(display, decimals),
    display: `${formatNumericValue(display, decimals)} ${unit}`,
    unit
  };
};

/**
 * This function converts a given value to the a scaled value based on units e.g. converting MB to GB, etc.
 *
 * @param {*} params The params to the function as outlined in `getScaledValue` above
 * @returns {String} A formatted, scaled value for display
 */
export const getScaledDisplayValue = (...params) => {
  return getScaledValue(...params).display;
};

export const getCustomScaledValue = (
  value,
  units,
  startingUnit,
  factor = 1024,
  endingUnit
) => {
  let display = value;
  let idx = startingUnit ? units.indexOf(startingUnit) : 0;
  const edx = endingUnit ? units.indexOf(endingUnit) : idx;
  const unit = units[edx];

  while (idx < edx) {
    display = display / factor;
    idx++;
  }

  let decimals = 1;
  if (display > 0 && display < 1) {
    let tr = display - Math.trunc(display);
    while (Math.trunc(tr) <= 0) {
      tr = tr * 10;
      decimals++;
    }
    decimals = decimals >= 2 ? decimals : 2;
  }

  return {
    result: castToNumber(formatNumericValue(display, decimals)),
    display: `${formatNumericValue(display, decimals)} ${unit}`,
    unit
  };
};

/**
 * This function enforces unique values in a given range (usually an axis) by
 * appending extra decimal places.
 *
 * This is a slightly quirky way of working around a limitation of Recharts which
 * parses a set of ticks twice. So enforcing uniqueness is a bit fiddly.
 *
 * @method createFormatAxisValue
 * @returns {Function} The formatted value according to function and uniqueness
 */
export const createFormatAxisValue = () => {
  let previousValues = [];

  return (value, formatter, decimals) => {
    let val = formatter(value, decimals);

    if (value > 0 && previousValues.includes(val)) {
      val = formatter(value, ++decimals);
    } else {
      previousValues = [];
    }

    previousValues.push(val);
    return val;
  };
};

/**
 * This function takes the maximum value which can be displayed on any chart axes (X-Axis or Y-Axis)
 * and adjusts it so that all the Axis ticks are properly calculated afterwards
 *
 * @method getAxisDomainMaxValue
 * @param {Number} value The maximum tick value on the chart axes (X-Axis or Y-Axis)
 * @param {Number} factor The dividing factor (default is 1024)
 * @returns {Number} The final adjusted value to be used as the maximum tick value
 */
export const getAxisDomainMaxValue = (value, factor = 1024) => {
  if (value > 0) {
    let d = value;
    let c = 0;

    // This while loop ensures we get the smallest number possible
    while (d >= factor) {
      d = d / factor;
      c++;
    }

    // This if statement will remove the decimal if present
    if (!isInteger(d)) {
      d = Math.ceil(d);
    }

    // This while loop ensures that we have a number which is divisible by 4
    if (c === 0) {
      while (d % 4 !== 0) {
        d++;
      }
    }

    // This while loop ensures we get revert back our adjusted small number to the original big value
    while (c > 0) {
      d = d * factor;
      c--;
    }

    return d;
  } else if (value === 0) {
    return 4;
  } else {
    // return value;
    return 0;
  }
};
