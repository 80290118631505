/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import styled from 'styled-components';
import { styleLibrary } from 'app/constants';

export { BlockContainer } from './block-container';
export { default as Logo } from './logo';
export { default as MessageOverlay } from './message-overlay';
export { default as NoDataToShow } from './no-data-to-show';
export { default as PathComponent } from './path-component';
export { default as TextLink } from './text-link';
export { default as TitleBar } from './title-bar';
export { TabContainer, TabFiller, TabInner, Tabs } from './tabs';

export const SubTitle = styled.div`
  font-size: ${styleLibrary.fontSizes.body}px;
  font-style: italic;
  font-weight: normal;
`;

export const HR = styled.hr`
  border-top: 1px solid ${styleLibrary.containerBorderColor};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem
`;

export const CardDeckWrapper = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center m-3'
})``;

export const CardDeck = styled.div.attrs({ className: 'card-deck' })``;

export const Card = styled.div.attrs({
  className: 'card rounded border-0 shadow text-center text-white mx-1'
})`
  background-color: ${props => props.color};
  width: 130px;
  height: auto;
`;

export const CardBody = styled.div.attrs({ className: 'card-body p-1' })`
  padding: 2px;
  margin-top: 10px;
`;

export const CardTitle = styled.span.attrs({ className: 'card-title mb-0 h2' })``;

export const CardText = styled.p.attrs({ className: 'card-text' })``;