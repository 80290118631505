/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import styled from 'styled-components';
import classNames from 'classnames';
import { styleLibrary } from '../../../../../constants';

export { CompareTrendsChart } from './compare-trends-chart';
export { ComparisonTooltip } from './comparison-tooltip';
export { DisplayRatio } from './display-ratio';
export { ValueBlock } from './value-block';

export const MetricColumn = styled.div.attrs(props => ({
  className: classNames('col py-3 text-center', props.selected && 'selected'),
  style: props.style
}))`
  cursor: pointer;
  font-size: 15px;
  border-bottom: 1px solid ${styleLibrary.containerBorderColor};

  &.selected {
    background-color: ${styleLibrary.usageSummarySelectedBg};
    border-bottom: 1px solid transparent;
  }
`;

export const MetricIcon = styled.div.attrs({
  className: 'pb-2'
})`
  height: 40px;
`;

export const ValueContainer = styled.span.attrs({
  className: 'mr-1'
})`
  font-size: 26px;
  font-weight: bold;
`;
