/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { styleLibrary } from 'app/constants';
import { generatePdfFromDataDefinition, userNameSelector } from 'app/redux/app';
import { getPrettyDurationFormat } from 'app/utils';
import { cloneDeep, has, isArray, isEmpty, sortBy } from 'lodash';
import moment from 'moment';
import Papa from 'papaparse';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import { downloadingAlarms, zabbixAlarmsSelector } from './';

const transformAlarmsData = alarms => {
  const data = cloneDeep(alarms);
  return sortBy(
    data.map(d => {
      d['actions'] =
        has(d, 'actions') && isArray(d['actions']) && !isEmpty(d['actions'])
          ? JSON.stringify(d['actions'])
          : '';

      d['duration'] = getPrettyDurationFormat(d['duration']);
      return d;
    }),
    ['time']
  );
};

export function* downloadAlarmsAsCsvSaga({ payload: { columns = [] } }) {
  yield put(downloadingAlarms({ inProgress: true, failed: false }));
  const csvFilename = 'Alarms_' + moment().format('YYYYMMDD_hhmm') + '.csv';
  const columnLabels = columns.map(c => c.label);

  try {
    const saveFileAs = yield getContext('saveFileAs');

    const { alarms } = yield select(zabbixAlarmsSelector);
    let transformedData = yield transformAlarmsData(alarms);
    transformedData = transformedData.map(d => {
      const ret = {};
      columns.forEach(c => (ret[c.label] = d[c.name]));
      return ret;
    });

    const csvData = yield Papa.unparse(transformedData, {
      quotes: true,
      header: true,
      skipEmptyLines: false,
      columns: columnLabels
    });

    const csvBlob = yield new Blob([csvData], {
      type: 'text/plain;charset=utf-8'
    });
    yield call(saveFileAs, csvBlob, csvFilename);
    yield put(downloadingAlarms({ inProgress: false }));
  } catch (err) {
    yield put(downloadingAlarms({ inProgress: false, failed: true }));
  }
}

export function* downloadAlarmsAsPdfSaga({ payload: { columns = [] } }) {
  yield put(downloadingAlarms({ inProgress: true, failed: false }));
  const userName = yield select(userNameSelector);
  const pdfFilename = 'Alarms_' + moment().format('YYYYMMDD_hhmm') + '.pdf';
  const exportTableStyleDefinitions = {
    theme: 'grid',
    headStyles: {
      cellWidth: 'wrap',
      fillColor: styleLibrary.brand.highlight
    },
    columnStyles: { 9: { cellWidth: 75 } }
  };

  try {
    const { alarms } = yield select(zabbixAlarmsSelector);
    let transformedData = yield transformAlarmsData(alarms);

    transformedData = JSON.parse(
      JSON.stringify(transformedData, [
        'time',
        'alarm_type',
        'property',
        'host',
        'problem',
        'severity',
        'duration',
        'acknowledged',
        'status',
        'actions'
      ])
    );

    transformedData = isArray(transformedData)
      ? transformedData.map(x => Object.values(x))
      : transformedData;

    yield put(
      generatePdfFromDataDefinition({
        exportDataDefinitions: [
          {
            name: 'Alarms',
            data: transformedData,
            columns
          }
        ],
        exportFilename: pdfFilename,
        title: 'Alarms',
        userName,
        exportPdfStyleDefinitions: {},
        exportTableStyleDefinitions
      })
    );

    yield put(downloadingAlarms({ inProgress: false }));
  } catch (err) {
    yield put(downloadingAlarms({ inProgress: false, failed: true }));
  }
}

export default function createDownloadAlarmsSagas(types) {
  return [
    takeLatest([types.downloadAlarmsAsCsv], downloadAlarmsAsCsvSaga),
    takeLatest([types.downloadAlarmsAsPdf], downloadAlarmsAsPdfSaga)
  ];
}
