/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  BlockContainer,
  PathComponent,
  Tabs,
  TitleBar
} from 'app/components/elements';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { styleLibrary } from 'app/constants';
import { createShowPanelSelector, userGroupsSelector } from 'app/redux/app';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchAbuseSessionDetails } from 'app/redux/reports';
import { findIndex, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import ClientmacDetails from './components/clientmac-details';

const TabContainer = styled.div.attrs({
  className: 'px-2 pb-4'
})`
  & .tab-wrapper {
    border: 1px solid ${styleLibrary.containerBorderColor};
    border-top: none;
    padding-top: 0.1rem;
  }
`;

const defaultTabs = [
  { id: 'search-tab', label: 'Search' }
  // { id: 'demo-tab', label: 'Client' }
];

export class Search extends Component {
  constructor(props) {
    super(props);

    this.state = { tabs: defaultTabs, selectedTab: defaultTabs[0].id };
  }
  componentDidMount = () => {
    this.updateTabs();
    this.dispatchAPIRequests();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { dateRangeFilterLabel, selectedPath } = this.props;
    const {
      dateRangeFilterLabel: prevRange,
      selectedPath: prevPath
    } = prevProps;
    const { selectedTab } = this.state;
    const { selectedTab: prevTab } = prevState;

    const pathChanged = get(selectedPath, 'id', '') !== get(prevPath, 'id', '');
    const dateRangeChanged = prevRange !== dateRangeFilterLabel;
    const tabChanged = selectedTab !== prevTab;

    if (pathChanged || dateRangeChanged) {
      this.updateTabs();
      this.dispatchAPIRequests(dateRangeChanged);
    } else if (tabChanged) {
      this.dispatchAPIRequests();
    }
  };

  updateTabs = () => {
    const {
      selectedPath,
      showClientmacDetails,
      // showdemo,
      userGroups = []
    } = this.props;
    let { selectedTab } = this.state;

    const isCustomerLevel = isSpecificNodeType(selectedPath, 'customer');
    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');
    const isAdmin = userGroups.includes('ADMIN');

    const showSearchTab =
      isAdmin && showClientmacDetails && (isCustomerLevel || isPropertyLevel);
    // const showDemotab = showdemo && isPropertyLevel;

    const tabs = defaultTabs.filter(({ id }) => {
      switch (id) {
        case 'search-tab':
          return showSearchTab;
        // case 'demo-tab':
        //   return showDemotab;
        default:
          return true;
      }
    });

    if (tabs.length === 0) {
      return 'Tabs array is empty.';
    }

    let tabChanged = false;
    if (findIndex(tabs, ['id', selectedTab]) < 0) {
      selectedTab = tabs[0].id;
      tabChanged = true;
    }

    this.setState({ tabs, selectedTab }, () => {
      if (tabChanged) {
        this.dispatchAPIRequests();
      }
    });
    return selectedTab;
  };

  dispatchAPIRequests = (dateRangeChanged = false) => {
    const {
      selectedPath,
      fetchAbuseSessionDetails
      // fetchDemo,
      // showdemo
    } = this.props;
    const { selectedTab } = this.state;

    const isCustomerLevel = isSpecificNodeType(selectedPath, 'customer');
    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');

    const dispatchSearch =
      (isCustomerLevel || isPropertyLevel) && dateRangeChanged;
    // const dispatchDemo = showdemo && isPropertyLevel;

    switch (selectedTab) {
      case 'search-tab':
        if (dispatchSearch) {
          fetchAbuseSessionDetails();
        }
        break;
        // case 'demo-tab':
        //   if (dispatchDemo) {
        //     fetchDemo();
        //   }
        //   break;

      default:
        break;
    }
  };

  renderSelectedTabContainer = () => {
    switch (this.state.selectedTab) {
      case 'search-tab':
        return <ClientmacDetails />;
      // case 'demo-tab':
      //   return <h1>Loading...</h1>;
      default:
        return <div />;
    }
  };

  render = () => {
    return (
      <Fragment>
        <TitleBar
          id="reports-title"
          dark
          padUnderTitle={false}
          leftChildren={<PathComponent />}
        />
        <div className="row my-4">
          <div className="col">
            <BlockContainer>
              <Tabs
                tabs={this.state.tabs}
                selected={this.state.selectedTab}
                onTabChanged={id => this.setState({ selectedTab: id })}
              />
              <TabContainer>
                <div className="tab-wrapper">
                  {this.renderSelectedTabContainer()}
                </div>
              </TabContainer>
            </BlockContainer>
          </div>
        </div>
      </Fragment>
    );
  };
}

Search.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  userGroups: PropTypes.array,
  fetchAbuseSessionDetails: PropTypes.func,
  fetchDemo: PropTypes.func,
  showClientmacDetails: PropTypes.bool,
  showdemo: PropTypes.bool
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  userGroupsSelector,
  createShowPanelSelector('SearchIndex'),
  createShowPanelSelector('SearchDemo'),
  (
    dateRangeFilterLabel,
    selectedPath,
    userGroups,
    showClientmacDetails,
    showdemo
  ) => ({
    dateRangeFilterLabel,
    selectedPath,
    userGroups,
    showClientmacDetails,
    showdemo
  })
);
const mapDispatchToProps = { fetchAbuseSessionDetails };
export default connect(mapStateToProps, mapDispatchToProps)(Search);
