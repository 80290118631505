/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { defaultDateFormat } from 'app/constants';
import { dateRangeFilterSelector } from 'app/redux/filters';
import { dateRangeFilterTransform } from 'app/redux/filters/transforms';
import { rootNodeSelector } from 'app/redux/hierarchy';
import { ajaxPost } from 'app/redux/util-sagas';
import { createErrorAction } from 'app/redux/utils';
import { get, merge } from 'lodash';
import moment from 'moment';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchVLANConsumptionReportComplete,
  fetchVLANConsumptionReportError,
  reportError,
  updateVLANConsumptionReportFetchState
} from './';

const url = '/api/report/getALETenantSummary';
const paramNames = ['timeUnit', 'timeUnitFrom', 'timeUnitTo', 'vertical'];

export function* fetchVLANConsumptionReportSaga({ payload }) {
  yield put(
    updateVLANConsumptionReportFetchState({ inProgress: true, failed: false })
  );

  const rootNode = yield select(rootNodeSelector);
  const node = yield get(payload, 'property', rootNode);
  const dateRange = yield select(dateRangeFilterSelector);
  const { timeUnit, timeUnitFrom, timeUnitTo } = yield dateRangeFilterTransform(
    get(payload, 'dateRange', dateRange)
  );
  const params = yield merge({}, payload, {
    timeUnit,
    timeUnitFrom,
    timeUnitTo,
    vertical: 'all'
  });
  const queryStringParams = yield paramNames
    .map(p => `${p}=${params[p]}`)
    .join('&');

  const finalBody = yield JSON.parse(get(node, 'complexApiBody', '{}'));

  const finalOptions = yield {
    headers: { Accept: 'text/csv' },
    responseType: 'blob',
    onTimeout: () =>
      put(updateVLANConsumptionReportFetchState({ failed: true }))
  };

  try {
    const { data } = yield ajaxPost(
      `${url}?${queryStringParams}`,
      finalBody,
      finalOptions
    );

    if (!(data instanceof Blob)) {
      throw yield new Error('No data');
    }

    if (yield get(payload, 'download', false)) {
      const saveFileAs = yield getContext('saveFileAs');
      const filename = yield get(
        node,
        'name',
        'NetworkIntelligence'
      ).toUpperCase() +
        '_VLANConsumptionReport_' +
        moment(timeUnitFrom, defaultDateFormat).format('YYYYMMDD') +
        '-' +
        moment(timeUnitTo, defaultDateFormat).format('YYYYMMDD') +
        '.csv';
      yield call(saveFileAs, data, filename);
    }

    yield put(fetchVLANConsumptionReportComplete(data));
    yield put(updateVLANConsumptionReportFetchState({ failed: false }));
  } catch (err) {
    yield put(createErrorAction(fetchVLANConsumptionReportError, err));
    yield put(updateVLANConsumptionReportFetchState({ failed: true }));
    yield put(
      reportError({
        action: 'generate',
        type: 'vlan-consumption-report',
        report: { ...finalBody },
        error: err
      })
    );
  } finally {
    yield put(updateVLANConsumptionReportFetchState({ inProgress: false }));
  }
}

export default function createFetchVLANConsumptionReportSagas(types) {
  return [
    takeLatest(
      [types.fetchVLANConsumptionReport],
      fetchVLANConsumptionReportSaga
    )
  ];
}
