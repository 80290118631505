/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { NoDataToShow } from 'app/components/elements';
import {
  ExportCsvIcon,
  FaIcon,
  LoadingIconPlaceholder,
  StyledExportButton
} from 'app/components/icons';
import { StatefulTable } from 'app/components/tables';
import {
  customMuiTableThemeWithCursor,
  customTableOptions
} from 'app/modules/incidents/components/utils';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  abuseSessionDataFetchStateSelector,
  abuseSessionDataSelector,
  fetchAbuseSessionDetails
} from 'app/redux/reports';
import { fetchStatePropTypes } from 'app/redux/utils';
import { Parser } from 'json2csv';
import { isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { searchTableColumns, transformSearchTableData } from '../../utils';

export class SearchTableDetails extends Component {
  componentDidMount = () => {
    const { sessionMAC, publicIP, nasPort } = this.props;
    this.props.fetchAbuseSessionDetails({ sessionMAC, publicIP, nasPort });
  };

  componentDidUpdate = prevProps => {
    const {
      dateRangeFilterLabel,
      selectedPath,
      fetchAbuseSessionDetails
    } = this.props;
    const {
      dateRangeFilterLabel: prevDateRange,
      selectedPath: prevSelectedPath
    } = prevProps;

    const pathChanged =
      prevSelectedPath && prevSelectedPath.id !== selectedPath.id;
    const dateRangeChanged = prevDateRange !== dateRangeFilterLabel;
    const { sessionMAC, publicIP, nasPort } = this.props;
    if (pathChanged || dateRangeChanged) {
      fetchAbuseSessionDetails({ sessionMAC, publicIP, nasPort });
    }
  };

  handleCSVExport = () => {
    const { data } = this.props;
    const customHeader = searchTableColumns.map(c => ({
      label: c.label,
      value: row => row[c.name]
    }));
    const parser = new Parser({ fields: customHeader });
    const csv = parser.parse(data);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'SessionDetails.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  renderCustomToolbar = () => (
    <StyledExportButton title="Generate CSV" onClick={this.handleCSVExport}>
      <ExportCsvIcon />
    </StyledExportButton>
  );

  renderCustomSearch = (searchText, handleSearch) => (
    <div className="d-flex justify-content-between align-items-center">
      <h5 className="mb-0">Session Details</h5>
      <div>
        <FaIcon icon="search" classes={['mr-2', 'mt-2']} />
        <label className="sr-only">Search</label>
        <input
          className="p-1"
          id="session-search"
          onChange={e => handleSearch(e.target.value)}
          placeholder="Search..."
          style={{ width: 250 }}
          type="text"
          value={searchText || ''}
        />
      </div>
    </div>
  );

  render = () => {
    const { data = [], fetchState } = this.props;
    const { pending, complete, failed } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    const transformedData =
      complete && hasData ? transformSearchTableData(data) : [];

    return pending ? (
      <LoadingIconPlaceholder position="relative" />
    ) : noDataToShow || failed ? (
      <NoDataToShow
        message="No data available"
        style={{ background: 'none', position: 'relative' }}
      />
    ) : (
      complete &&
      hasData && (
        <MuiThemeProvider theme={createMuiTheme(customMuiTableThemeWithCursor)}>
          <StatefulTable
            tableKey="search-table"
            title="Session Details"
            data={transformedData}
            columns={searchTableColumns}
            options={{
              ...customTableOptions,
              customToolbar: this.renderCustomToolbar,
              customSearchRender: this.renderCustomSearch
            }}
          />
        </MuiThemeProvider>
      )
    );
  };
}

SearchTableDetails.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  fetchAbuseSessionDetails: PropTypes.func,
  data: PropTypes.array,
  fetchState: fetchStatePropTypes,
  sessionMAC: PropTypes.string,
  publicIP: PropTypes.string,
  nasPort: PropTypes.string
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  abuseSessionDataSelector,
  abuseSessionDataFetchStateSelector,
  (dateRangeFilterLabel, selectedPath, data, fetchState) => ({
    dateRangeFilterLabel,
    selectedPath,
    data,
    fetchState
  })
);

const mapDispatchToProps = { fetchAbuseSessionDetails };

export default connect(mapStateToProps, mapDispatchToProps)(SearchTableDetails);
