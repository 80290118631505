/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

// import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { dataRateUnits, formatTraffic } from 'app/constants';
import styled from 'styled-components';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0 8px 0;
`;

const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: ${props => (props.noMargin ? 'none' : '5px')};
  margin-top: ${props => (props.noMargin ? 'none' : '5px')};
  padding: 1px 0 1px 0;
  background-color: #d0d9f2;
  width: auto;
  height: auto;
  max-width: 100%;
`;

const RightArrowShape = styled.div`
  position: absolute;
  right: -14px;
  top: 22%;
  transform: translateY(-50%);
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #d0d9f2;
`;

const ForwardIcon = ({ children }) => (
  <ArrowContainer>
    {children}
    <RightArrowShape />
  </ArrowContainer>
);

ForwardIcon.propTypes = {
  children: PropTypes.any
};

const LeftArrowShape = styled.div`
  position: absolute;
  left: -19px;
  top: 78%;
  transform: translateY(-50%) scaleX(-1);
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 35px solid #d0d9f2;
`;

const BackwardIcon = ({ children }) => (
  <ArrowContainer>
    <LeftArrowShape />
    {children}
  </ArrowContainer>
);

BackwardIcon.propTypes = {
  children: PropTypes.any
};

const StatBox = styled.div`
  flex: 1;
  text-align: center;
  align-items: stretch;
  padding: 5px;
  border-right: ${props => (props.noBorder ? 'none' : '2px solid #ccc')};
`;

const Header = styled.h5`
  margin-top: 0;
  font-weight: bold;
  font-size: 18px;
`;

const SubHeader = styled.h6`
  margin: 5px 0;
  font-weight: bold;
  font-size: 15px;
`;

const ValueHeader = styled.h6`
  font-weight: bold;
  font-size: 14px;
`;

const TrafficIcon = styled.div`
  color: ${props => props.color};
`;

const customSize = { fontSize: '50px' };
const borderStyle = {
  marginBottom: '0px',
  marginTop: '15px',
  border: '1px solid #ccc'
};
const customfontWeight = {
  fontWeight: '600'
};

class CustomUsageSummary extends Component {

  formatDataRate = rate => {
    if (typeof rate !== 'number') {
      return 'N/A'; // Return a default value if rate is not a number
    }

    let value = rate;
    let unit = 'Kbps';

    for (let i = 0; i < dataRateUnits.length; i++) {
      const unitValue = Math.pow(1024, i);
      if (rate >= unitValue) {
        value = rate / unitValue;
        unit = dataRateUnits[i];
      } else {
        break;
      }
    }
    return `${value.toFixed(1)} ${unit}`;
  };

  render = () => {
    const { data } = this.props;
    const clientArray =
      data && data.activeClientCount ? data.activeClientCount : [];
    const clientCount = clientArray.length ? clientArray[0].CDR_MQTT : 0;

    const dataRate = data && data.SPThroughput;
    const downloadRate = dataRate.length ? dataRate.reduce(
      (acc, curr) => acc + curr.throughputRXInKbps,
      0
    ) : 0;
    const uploadRate =  dataRate.length ? dataRate.reduce(
      (acc, curr) => acc + curr.throughputTXInKbps,
      0
    ) : 0;
    // const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');

    const totalTraffic =
      data && data.BandwidthUsage && Object.keys(data.BandwidthUsage).length > 0
        ? data.BandwidthUsage.totalUsageInMB
        : 0;
    const totalRX =
      data && data.BandwidthUsage && Object.keys(data.BandwidthUsage).length > 0
        ? data.BandwidthUsage.bandwidthRXUsageInMB
        : 0;
    const totalTX =
      data && data.BandwidthUsage && Object.keys(data.BandwidthUsage).length > 0
        ? data.BandwidthUsage.bandwidthTXUsageInMB
        : 0;

    return (
      <div className="mt-4">
        <div className="row">
          <div className="col text-center">
            <TrafficIcon color="#0070b2">
              <Header>Total Traffic</Header>
            </TrafficIcon>
            <ValueHeader>{formatTraffic(totalTraffic)}</ValueHeader>
            <span style={{ marginBottom: '10px' }}></span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <IconContainer>
              <TrafficIcon color="#00ADEF">
                <svg
                  height="60"
                  viewBox="0 0 24 24"
                  width="90"
                  xmlns="http://www.w3.org/2000/svg"
                  margintop="5"
                >
                  <path
                    d="m12 8c1.6569 0 3 1.34315 3 3 0 1.3979-.9561 2.5725-2.25 2.9055v2.0945h5.25c1.6569 0 3 1.3431 3 3s-1.3431 3-3 3h-12c-1.65685 0-3-1.3431-3-3s1.34315-3 3-3h5.25v-2.0945c-1.29392-.333-2.25-1.5076-2.25-2.9055 0-1.65685 1.3431-3 3-3zm0-6c4.9706 0 9 4.02944 9 9v.25c0 .4142-.3358.75-.75.75s-.75-.3358-.75-.75v-.25c0-4.14214-3.3579-7.5-7.5-7.5-4.14214 0-7.5 3.35786-7.5 7.5v.25c0 .4142-.33579.75-.75.75s-.75-.3358-.75-.75v-.25c0-4.97056 4.02944-9 9-9zm0 3c3.3137 0 6 2.68629 6 6v.25c0 .4142-.3358.75-.75.75s-.75-.3358-.75-.75v-.25c0-2.48528-2.0147-4.5-4.5-4.5-2.48528 0-4.5 2.01472-4.5 4.5v.25c0 .4142-.33579.75-.75.75s-.75-.3358-.75-.75v-.25c0-3.31371 2.68629-6 6-6z"
                    fill="#00ADEF"
                  />
                </svg>
              </TrafficIcon>
            </IconContainer>
          </div>
          <div className="col">
            <ArrowContainer>
              <ForwardIcon>
                <span style={customfontWeight}>
                  Upload Usage: {formatTraffic(totalTX)}
                </span>
              </ForwardIcon>
            </ArrowContainer>
            <div style={{ marginBottom: '20px' }}></div>
            <ArrowContainer>
              <BackwardIcon>
                <span style={customfontWeight}>
                  Download Usage: {formatTraffic(totalRX)}
                </span>
              </BackwardIcon>
            </ArrowContainer>
          </div>
          <div className="col">
            <IconContainer>
              <i
                className="fa fa-users"
                style={{ fontSize: '45px', marginTop: '10px' }}
              ></i>
            </IconContainer>
          </div>
        </div>
        <hr style={borderStyle} />
        <div className="row">
          <StatBox className="col-lg-4 col-md-4 col-sm-4" noBorder>
            <TrafficIcon color="#3bb1db">
              <IconContainer>
                <CloudDownloadIcon style={customSize} />
              </IconContainer>
              <SubHeader>Download Rate</SubHeader>
            </TrafficIcon>
            <ValueHeader>{this.formatDataRate(downloadRate)}</ValueHeader>
          </StatBox>
          <StatBox className="col-lg-3 col-md-3 col-sm-3">
            <TrafficIcon color="#2e7e93">
              <IconContainer>
                <CloudUploadIcon style={customSize} />
              </IconContainer>
              <SubHeader>Upload Rate</SubHeader>
            </TrafficIcon>
            <ValueHeader>{this.formatDataRate(uploadRate)}</ValueHeader>
          </StatBox>
          <StatBox className="col-lg-5 col-md-5 col-sm-5" noBorder>
            <TrafficIcon color="#000">
              <IconContainer>
                <i
                  className="fa fa-users"
                  style={{ fontSize: '45px', marginTop: '5px' }}
                ></i>
              </IconContainer>
            </TrafficIcon>
            <TrafficIcon color="#BC1A8C">
              <SubHeader>Clients</SubHeader>
            </TrafficIcon>
            <ValueHeader>{clientCount}</ValueHeader>
          </StatBox>
        </div>
      </div>
    );
  };
}

CustomUsageSummary.propTypes = {
  data: PropTypes.object,
  selectedPath: PropTypes.object
};
const mapStateToProps = createSelector(selectedPathSelector, selectedPath => ({
  selectedPath
}));
export default connect(mapStateToProps)(CustomUsageSummary);
