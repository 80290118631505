/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ConnectedIcon,
  DisconnectedIcon,
  FaIcon,
  LoadingIconPlaceholder
} from 'app/components/icons';
import { isPropertyNodeOrUnder } from 'app/components/layout/components/sidebar/utils';
import { styleLibrary } from 'app/constants';
import { dateRangeFilterSelector } from 'app/redux/filters';
import {
  hierarchyNodeSelected,
  selectedPathSelector
} from 'app/redux/hierarchy';
import { mapMarkerDetailsSelector, mapSummarySelector } from 'app/redux/map';
import {
  formatNumericValue,
  sortByProperty,
  toFormattedDateTime
} from 'app/utils';
import classNames from 'classnames';
import { find, get, has, isEmpty, isEqual, startCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { HoverBox, InfoBoxContainer } from './index';

const CloseInfoBox = styled.div.attrs({
  className: ''
})`
  margin: -12px 0 0 -12px;

  & .fa {
    font-size: 16px;
    background-color: ${styleLibrary.tooltipBg};
    padding: 2px 0 0;
    border-radius: 15px !important;
    border: 2px solid ${styleLibrary.darkText};
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;
  }
`;

const SummaryWrapper = styled.div.attrs({
  className: 'd-flex justify-content-between mb-4'
})`
  width: 420px;
  z-index: 100;
`;

const DetailedInfoWrapper = styled.div`
  & .map-row-wrapper:nth-child(even) {
    background-color: ${styleLibrary.lightBg};
  }
`;

const RowWrapper = styled.div.attrs({
  className: 'd-flex justify-content-start py-2 map-row-wrapper'
})`
  width: 100%;
  border-bottom: 1px solid ${styleLibrary.containerBorderColor};

  &:last-of-type {
    border-bottom: 0;
  }
`;

const RowLabel = styled.div.attrs({
  className: 'text-right mr-3'
})`
  width: 90px;
  font-style: italic;
`;

const MultiItemLink = styled.button.attrs({
  className: 'p-0'
})`
  background: #fff;
  border: 0;
  color: ${styleLibrary.primaryButtonBg};
  font-weight: bold;
`;

const InfoName = styled.b.attrs(props => ({
  className: 'd-inline-block',
  ...props
}))`
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RowValue = styled.div`
  width: 100%;
  white-space: nowrap;
`;

const ZoomButton = styled.div.attrs({
  className: 'd-flex justify-content-center align-items-center'
})`
  height: 45px;
`;

class InfoWindow extends Component {
  getRenderValues = provider => {
    const isAP = !!provider.APName;

    // If we're at a PWF property level, we want to display actual AP details, not the property details themselves
    if (isAP) {
      return {
        timestamp: provider.lastRefreshedTime,
        displayName: provider.displayName || provider.APName,
        isConnected: ['Connect', 'Active'].includes(provider.connectionStatus)
      };
    } else {
      return {
        timestamp: provider.refreshTimestamp,
        displayName: provider.displayName || provider.name,
        isConnected: false
      };
    }
  };

  zoomIn = node => {
    const { hierarchyNodeSelected, selectedPath } = this.props;
    if (!isPropertyNodeOrUnder(selectedPath) || node !== 'DOCSIS') {
      hierarchyNodeSelected(`${selectedPath.id}-${node}`);
    }
  };

  getProviderFromProps() {
    const {
      mapMarkerDetails,
      mapSummary,
      providers,
      selectedMultiple
    } = this.props;
    let providerIdx = 0;

    if (selectedMultiple >= 0) {
      providerIdx = selectedMultiple;
    }

    // For the scenario where we're at a property and displaying AP details
    const p = providers[providerIdx];
    if (has(p, 'APName')) {
      const { bandwidthRX, bandwidthTX } = p;
      const { info } = mapSummary;

      const providerWithTotals = {
        ...p,
        type: 'AP Name', // Override the type (since it's displayed) and is misleading as "APMAC"
        bandwidthSum: bandwidthRX + bandwidthTX
      };
      return { provider: providerWithTotals, info };
    }

    // For the scenario where we're at a higher level and have to check the API response details
    const selectedMarkerDetails = mapMarkerDetails[p.name];
    if (!isEmpty(selectedMarkerDetails)) {
      const { ServiceProvider = [{}], info = {} } = selectedMarkerDetails;
      const provider = ServiceProvider[0];
      const displayName = get(
        find(providers, p => p.name === provider.name),
        'displayName',
        ''
      );
      if (displayName) {
        provider.displayName = displayName;
      }
      return { provider, info };
    }

    // No hit - return empty object
    return {
      provider: Object.keys(mapMarkerDetails).includes(p.name),
      info: false
    };
  }

  renderInfoWindow(provider = {}, info) {
    const { dateRangeFilter, onSelectMultiple, providers } = this.props;
    // const { label, start, end } = dateRangeFilter;
    const { label } = dateRangeFilter;
    // const { activeClients = -1 } = provider;

    // const hasClientData = activeClients >= 0;
    const displayType = has(provider, 'type') ? provider.type : 'AP Name';
    const isAP = !!provider.APName;
    const { timestamp, displayName, isConnected } = this.getRenderValues(
      provider
    );
    const statusIconStyle = {
      height: styleLibrary.fontSizes.body
    };
    const bandwidthUnit =
      has(info, 'bandwidthUnit') && !isEqual(info.bandwidthUnit, 'NA')
        ? `(${info.bandwidthUnit})`
        : '';

    // let dateRangePeriodLabel;
    // switch (getTimeUnit(label, start, end)) {
    //   case '24Hours':
    //     dateRangePeriodLabel = 'Hr';
    //     break;

    //   case 'year':
    //     dateRangePeriodLabel = 'Mth';
    //     break;

    //   default:
    //     dateRangePeriodLabel = 'Day';
    //     break;
    // }

    return (
      <DetailedInfoWrapper>
        <SummaryWrapper>
          <div className="d-flex align-items-center">
            {startCase(displayType)}:&nbsp;
            <InfoName title={displayName}>{displayName}</InfoName>
          </div>

          <div className="text-right">
            Updated: <b>{toFormattedDateTime(timestamp)}</b>
            <br />
            {/* {hasClientData && ( */}
            {isAP && (
              <Fragment>
                <span className="mr-2">Data For:</span>
                <b>
                  <i>{label}</i>
                </b>
              </Fragment>
            )}
          </div>
        </SummaryWrapper>
        {isAP ? (
          <Fragment>
            <RowWrapper>
              <RowLabel>Status:</RowLabel>
              <div>
                <span
                  style={{
                    color: isConnected
                      ? styleLibrary.connectedColor
                      : styleLibrary.disconnectedColor
                  }}
                >
                  <b>{provider.connectionStatus}</b>
                </span>
                {/* {' '}
                ({provider.radioType}) */}
              </div>
            </RowWrapper>
            <RowWrapper>
              <RowLabel>AP MAC:</RowLabel>
              <div>
                <b>{provider.name}</b>
              </div>
            </RowWrapper>
          </Fragment>
        ) : (
          <RowWrapper>
            <RowLabel>APs/GWs:</RowLabel>
            <RowValue>
              <ConnectedIcon className="mr-1" style={statusIconStyle} />{' '}
              <b>{formatNumericValue(provider.apConnectedCount)}</b>
            </RowValue>
            <RowValue>
              <DisconnectedIcon className="ml-3 mr-1" style={statusIconStyle} />
              <b>{formatNumericValue(provider.apDisconnectedCount)}</b>
            </RowValue>
            <RowValue>
              <i className="ml-3 mr-1">Total:</i>{' '}
              <b>{formatNumericValue(provider.apCount)}</b>
            </RowValue>
          </RowWrapper>
        )}
        <RowWrapper>
          <RowLabel>Traffic {bandwidthUnit}:</RowLabel>
          <RowValue>
            <FaIcon
              icon="angle-double-down"
              classes={['mr-1']}
              style={{ color: styleLibrary.downloadColor }}
            />{' '}
            <b>{formatNumericValue(provider.bandwidthTX, 1)}</b>
          </RowValue>
          <RowValue>
            <FaIcon
              icon="angle-double-up"
              classes={['ml-3', 'mr-1']}
              style={{ color: styleLibrary.uploadColor }}
            />{' '}
            <b>{formatNumericValue(provider.bandwidthRX, 1)}</b>
          </RowValue>
          <RowValue>
            <i className="ml-3 mr-1">Total:</i>{' '}
            <b>{formatNumericValue(provider.bandwidthSum || 0, 1)}</b>
          </RowValue>
        </RowWrapper>
        {/* {hasClientData && (
          <Fragment>
            <RowWrapper>
              <RowLabel>Clients:</RowLabel>
              <RowValue>
                <i className="mr-1">Total:</i>{' '}
                <b>{formatNumericValue(provider.activeClients)}</b>
              </RowValue>
              <RowValue>
                <i className="ml-3 mr-1">
                  Clients in Busiest {dateRangePeriodLabel}:
                </i>{' '}
                <b>{formatNumericValue(provider.maxActiveClients)}</b>
              </RowValue>
            </RowWrapper>
            <RowWrapper>
              <RowLabel>Sessions:</RowLabel>
              <RowValue>
                <i className="mr-1">Total:</i>{' '}
                <b>{formatNumericValue(provider.activeSessions)}</b>
              </RowValue>
              <RowValue>
                <i className="ml-3 mr-1">Avg. Sessions Per Client:</i>{' '}
                <b>{formatNumericValue(provider.averageSessions)}</b>
              </RowValue>
            </RowWrapper>
          </Fragment>
        )} */}
        <ZoomButton>
          {providers.length > 1 && (
            <button
              className={classNames('btn btn-primary btn-sm', {
                'mr-3': !isAP
              })}
              onClick={() => onSelectMultiple(-1)}
            >
              &laquo; Back
            </button>
          )}
          {!isAP && (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => this.zoomIn(provider.name)}
            >
              Zoom to {displayName}
            </button>
          )}
        </ZoomButton>
      </DetailedInfoWrapper>
    );
  }

  renderMultipleProviderList() {
    const { onSelectMultiple, providers } = this.props;

    return (
      <Fragment>
        {providers.sort(sortByProperty('name')).map((p, idx) => (
          <RowWrapper key={p.name}>
            <FaIcon icon="arrow-right" classes={['mr-2']} />
            <MultiItemLink
              onClick={() => {
                onSelectMultiple(idx);
              }}
            >
              {p.APName && (
                <span className="mr-2">
                  {['Connect', 'Active'].includes(p.connectionStatus) ? (
                    <ConnectedIcon
                      style={{ fontSize: styleLibrary.fontSizes.inlineIcon }}
                    />
                  ) : (
                    <DisconnectedIcon
                      style={{ fontSize: styleLibrary.fontSizes.inlineIcon }}
                    />
                  )}
                </span>
              )}
              {p.APName ? `${p.APName} (MAC: ${p.name})` : p.name}
            </MultiItemLink>
          </RowWrapper>
        ))}
      </Fragment>
    );
  }

  render() {
    const { offset, onCloseClick, providers, selectedMultiple } = this.props;

    const displaySingle = selectedMultiple >= 0 || providers.length === 1;
    const { provider, info } = this.getProviderFromProps();
    const hasData = provider !== false;
    let displayOffset = offset;

    if (displaySingle) {
      if (!hasData) {
        displayOffset = 135;
      } else if (isEmpty(provider)) {
        displayOffset = 95;
      }
    }

    return (
      <InfoBoxContainer offset={displayOffset} zIndex={10}>
        <HoverBox fontSize={styleLibrary.fontSizes.body}>
          {displaySingle ? (
            hasData ? (
              isEmpty(provider) ? (
                <span>No data for this provider.</span>
              ) : (
                this.renderInfoWindow(provider, info)
              )
            ) : (
              <LoadingIconPlaceholder height={0} position="inherit" />
            )
          ) : (
            this.renderMultipleProviderList()
          )}
        </HoverBox>
        {(hasData || !displaySingle) && (
          <CloseInfoBox onClick={onCloseClick}>
            <FaIcon icon="times" />
          </CloseInfoBox>
        )}
      </InfoBoxContainer>
    );
  }
}

InfoWindow.propTypes = {
  dateRangeFilter: PropTypes.object.isRequired,
  hierarchyNodeSelected: PropTypes.func.isRequired,
  mapMarkerDetails: PropTypes.object,
  mapSummary: PropTypes.object,
  offset: PropTypes.number.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onSelectMultiple: PropTypes.func.isRequired,
  providers: PropTypes.array.isRequired,
  selectedMultiple: PropTypes.number.isRequired,
  selectedPath: PropTypes.object
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  mapMarkerDetailsSelector,
  mapSummarySelector,
  selectedPathSelector,
  (dateRangeFilter, mapMarkerDetails, mapSummary, selectedPath) => ({
    dateRangeFilter,
    mapMarkerDetails,
    mapSummary,
    selectedPath
  })
);

export default connect(mapStateToProps, { hierarchyNodeSelected })(InfoWindow);
