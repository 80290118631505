/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';
import { formatNumericValue } from 'app/utils';

const PieChartLegend = styled.div.attrs({
  className: 'align-self-center'
})``;

const PieChartLineWrapper = styled.div.attrs(props => ({
  className: classNames(
    'd-flex my-2 align-items-center',
    props.isActive === false && 'inactive'
  )
}))`
  cursor: pointer;

  &.inactive {
    color: ${styleLibrary.disabledText};
    text-decoration: line-through;
  }
`;
PieChartLineWrapper.displayName = 'PieChartLineWrapper';

const PieChartHeaderWrapper = styled(PieChartLineWrapper)`
  font-style: italic;
`;

const LegendBox = styled.div.attrs({
  className: 'mr-1'
})`
  background-color: ${props => (props.isActive ? props.color : 'transparent')};
  border: ${props => `1px solid ${props.color}`};
  width: 12px;
  height: 12px;
`;

const LegendHeaderText = styled.div.attrs({
  className: 'mx-2'
})`
  width: ${props => props.textWidth || 150}px;
`;

const LegendHeaderCount = styled.div.attrs({
  className: 'text-right mx-2'
})`
  width: ${props => props.countWidth || 50}px;
`;

const LegendHeaderPercent = styled.div.attrs({
  className: 'text-right ml-2'
})`
  width: 35px;
`;

const LegendLineText = styled(LegendHeaderText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LegendLineCount = styled(LegendHeaderCount)`
  font-weight: bold;
`;

const LegendLinePercent = styled(LegendHeaderPercent)``;

class ChartLegend extends Component {
  handleBoxClick = label => {
    this.props.onToggle(label);
  };

  render() {
    const {
      data,
      textProperty,
      countProperty,
      totalCount,
      getFillColour,
      titleLabel,
      percentProperty,
      ...sizes
    } = this.props;

    return (
      <PieChartLegend>
        <PieChartHeaderWrapper>
          <LegendBox />
          <LegendHeaderText {...sizes}>{titleLabel}</LegendHeaderText>
          <LegendHeaderCount {...sizes}>#</LegendHeaderCount>
          <LegendHeaderPercent>%</LegendHeaderPercent>
        </PieChartHeaderWrapper>
        {data.map(d => {
          const { toggled = [] } = this.props;
          const isActive = !toggled.includes(d[textProperty]);

          return (
            <PieChartLineWrapper
              isActive={isActive}
              key={d[textProperty]}
              onClick={() => this.handleBoxClick(d[textProperty])}
            >
              <LegendBox
                isActive={isActive}
                color={getFillColour(d[textProperty])}
              />
              <LegendLineText {...sizes} title={d[textProperty]}>
                {d[textProperty]}
              </LegendLineText>
              <LegendLineCount {...sizes}>{d[countProperty]}</LegendLineCount>
              <LegendLinePercent>
                {percentProperty
                  ? d[percentProperty]
                  : formatNumericValue((d[countProperty] / totalCount) * 100)}
                {/* {formatNumericValue((d[countProperty] / totalCount) * 100)} */}
              </LegendLinePercent>
            </PieChartLineWrapper>
          );
        })}
      </PieChartLegend>
    );
  }
}

ChartLegend.defaultProps = { titleLabel: 'Type' };

ChartLegend.propTypes = {
  countProperty: PropTypes.string.isRequired,
  countWidth: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  getFillColour: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  textProperty: PropTypes.string.isRequired,
  textWidth: PropTypes.number,
  toggled: PropTypes.arrayOf(PropTypes.string),
  totalCount: PropTypes.number,
  titleLabel: PropTypes.string,
  percentProperty: PropTypes.string
};

export default ChartLegend;
