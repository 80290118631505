/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { PathComponent, TitleBar } from 'app/components/elements';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { TableExport } from 'app/components/tables';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { vertSSIDFilterSelector } from 'app/redux/filters';
import {
  fetchInventoryDetails,
  fetchInventorySummary,
  inventoryDetailsFetchStateSelector,
  inventorySelectionSelector,
  inventorySelector,
  updateInventoryFilter
} from 'app/redux/inventory';
import { fetchStatePropTypes } from 'app/redux/utils';
import { saveAs } from 'file-saver';
import {
  findIndex,
  get,
  includes,
  isEmpty,
  isEqual,
  pick,
  toString
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import XLSX from 'xlsx';
import { InventoryTable } from './components';
import { getInventoryTableDetails } from './get-inventory-columns';
import { getSortedSummaryItems } from './utils';
import Filters from 'app/components/filters';

export class Inventory extends Component {
  dispatchAPIRequests = (skipCache = false) => {
    this.props.fetchInventorySummary({ skipCache });
    this.props.fetchInventoryDetails({ skipCache });
  };

  componentDidMount = () => {
    const isPropertyLevel = isSpecificNodeType(this.props.path, 'zonename');
    this.dispatchAPIRequests(isPropertyLevel);
  };

  componentDidUpdate = prevProps => {
    const {
      path: prevPath = {},
      vertSSIDFilter: prevVertSSIDFilter
    } = prevProps;
    const { path = {}, vertSSIDFilter } = this.props;
    const isPropertyLevel = isSpecificNodeType(path, 'zonename');
    const pathChanged = prevPath && prevPath.id !== path.id;
    const verticalChanged = !isEqual(prevVertSSIDFilter, vertSSIDFilter);

    if (pathChanged || verticalChanged) {
      this.dispatchAPIRequests(isPropertyLevel);
    }
  };

  inventoryPathToExportDefinition = (inventory, path) => {
    //Turn Inventory and Path into an array of export definitions
    const tabs = getSortedSummaryItems(inventory, path).map(item =>
      pick(item, ['level', 'type'])
    );

    return tabs.map(item => {
      const { detailsForType, columns } = getInventoryTableDetails(inventory, {
        deviceType: item.type
      });

      return {
        name: item.type,
        columns: columns,
        data: detailsForType
      };
    });
  };

  modifyExcelData = excelData => {
    return excelData.map(s => {
      // This will strip unwanted columns and data
      const excludeFieldIndex = s.columns.reduce((r, c, i) => {
        return !c.label || !get(c, 'options.display', true)
          ? (r.push(i), r)
          : r;
      }, []);
      if (!isEmpty(excludeFieldIndex)) {
        s.columns = s.columns.filter((c, i) => !includes(excludeFieldIndex, i));
        s.data = s.data.map(d =>
          d.filter((x, i) => !includes(excludeFieldIndex, i))
        );
      }

      // This will transform the macAddress array into a String
      const macAddressIndex = findIndex(s.columns, { name: 'macAddress' });
      if (macAddressIndex >= 0) {
        s.data.forEach(
          d => (d[macAddressIndex] = toString(d[macAddressIndex]))
        );
      }

      return s;
    });
  };

  doGenerateExcel = () => {
    const { inventory, path } = this.props;
    const dateString = moment().format('YYYYMMDD_hhmm');
    const fileName = `Inventory_${path ? path.id : ''}_${dateString}.xlsx`;
    const excelData = this.modifyExcelData(
      this.inventoryPathToExportDefinition(inventory, path)
    );
    const wb = XLSX.utils.book_new();
    excelData.forEach(s => {
      const ws = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(ws, [s.columns.map(c => c.label)]);
      XLSX.utils.sheet_add_json(ws, s.data, {
        origin: 'A2',
        skipHeader: true
      });
      XLSX.utils.book_append_sheet(wb, ws, s.name);
    });
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const file = new Blob([excelBuffer], {
      type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });
    saveAs(file, fileName);
  };

  render = () => {
    const {
      fetchState,
      inventory,
      path,
      selected,
      updateInventoryFilter
    } = this.props;

    return (
      <div data-test-label="inventory-module">
        <TitleBar
          dark
          leftChildren={<PathComponent />}
          rightChildren={
            <div className="d-flex justify-content-spread align-items-center">
              <Filters
                showVerticalFilter={true}
                showDateRangeFilter={false}
              />
              <TableExport
                exportDataDefinitions={this.inventoryPathToExportDefinition(
                  inventory,
                  path
                )}
                exportName={'Inventory'}
                hideCsvExport={true}
                hideExcelExport={false}
                generateExcelFromDataDefinition={this.doGenerateExcel}
              />
            </div>
          }
        />
        <InventoryTable
          inventory={inventory}
          fetchState={fetchState}
          dispatchAPIRequests={this.dispatchAPIRequests}
          selected={selected}
          path={path}
          updateInventoryFilter={updateInventoryFilter}
        />
      </div>
    );
  };
}

Inventory.displayName = 'Inventory';
Inventory.propTypes = {
  fetchInventoryDetails: PropTypes.func.isRequired,
  fetchInventorySummary: PropTypes.func,
  fetchState: fetchStatePropTypes,
  selected: PropTypes.shape({
    deviceType: PropTypes.string,
    levelType: PropTypes.string
  }),
  inventory: PropTypes.object,
  path: PropTypes.object,
  vertSSIDFilter: PropTypes.object,
  updateInventoryFilter: PropTypes.func.isRequired
};

const mapStateToProps = createSelector(
  inventoryDetailsFetchStateSelector,
  inventorySelectionSelector,
  inventorySelector,
  selectedPathSelector,
  vertSSIDFilterSelector,
  (fetchState, selected, inventory, path, vertSSIDFilter) => ({
    fetchState,
    selected,
    inventory,
    path,
    vertSSIDFilter
  })
);

const mapDispatchToProps = {
  updateInventoryFilter,
  fetchInventoryDetails,
  fetchInventorySummary
};

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);
