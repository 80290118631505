/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { defaultDateSelection } from 'app/components/filters/constants';
import { dateRangeFilterTransform } from 'app/redux/filters/transforms';
import { filterParamsSelector } from 'app/redux/filters';
import { ajaxGet } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { get, merge } from 'lodash';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchAccClientsTrendComplete,
  fetchAccClientsTrendError,
  updateAccClientsTrendFetchState
} from './';

export function* fetchAccClientsTrendSaga({ payload }) {
  yield put(updateAccClientsTrendFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);

  const zoneParam = { zone: get(payload, 'zone', '') };
  const dateRangeParams = yield dateRangeFilterTransform(
    get(payload, 'dateRange', defaultDateSelection)
  );

  const optionalParams = payload.dateRange
    ? {
      timeUnit: dateRangeParams.timeUnit,
      timeUnitFrom: dateRangeParams.timeUnitFrom,
      timeUnitTo: dateRangeParams.timeUnitTo
    }
    : {};

  const params = yield merge({}, filters, payload, zoneParam, optionalParams);

  const url = '/api/fastapi/getPropertyALETrend';
  const paramNames = [
    'zone',
    'vertical',
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo'
  ];

  try {
    const onTimeout = () =>
      put(updateAccClientsTrendFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchAccClientsTrendComplete(data));
    yield put(updateAccClientsTrendFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchAccClientsTrendError, e));
    yield put(updateAccClientsTrendFetchState(failedFetchState));
  }
}
export default function createfetchAccClientsTrendSagas(types) {
  return [takeLatest(types.fetchAccClientsTrend, fetchAccClientsTrendSaga)];
}
