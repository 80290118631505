/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  IconButton,
  MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { NoDataToShow } from 'app/components/elements';
import {
  ExportCsvIcon,
  FaIcon,
  LoadingIconPlaceholder,
  StyledExportButton
} from 'app/components/icons';
import { StatefulTable } from 'app/components/tables';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  abuseSessionDataFetchStateSelector,
  abuseSessionDataSelector,
  fetchAbuseSessionDetails
} from 'app/redux/reports';
import { fetchStatePropTypes } from 'app/redux/utils';
import { Parser } from 'json2csv';
import { isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  searchTableColumns,
  transformSearchTableData
} from 'app/modules/reports/utils';
import {
  MuiTableOptions,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import styled from 'styled-components';

const ViewColumnsContainer = styled.div`
  position: relative;
`;

const ColumnOptions = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  max-height: 40vh;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #0101;
  padding: 20px;
  margin: 5px;
`;

export class ClientmacSearchTable extends Component {
  constructor(props) {
    super(props);

    const initialColumns = searchTableColumns.map(column => ({
      ...column,
      visible: column.options ? column.options.display !== false : true // If options exist, check display value, otherwise default to true
    }));

    this.state = {
      searchText: '',
      columnVisibility: {},
      showColumnOptions: false,
      searchTableColumns: initialColumns
    };
  }

  componentDidMount = () => {
    const {
      sessionMAC,
      publicIP,
      nasPort,
      timeUnitFrom,
      timeUnitTo
    } = this.props;
    this.props.fetchAbuseSessionDetails({
      sessionMAC,
      publicIP,
      nasPort,
      timeUnitFrom,
      timeUnitTo
    });
  };

  componentDidUpdate = prevProps => {
    const { selectedPath, fetchAbuseSessionDetails } = this.props;
    const { selectedPath: prevSelectedPath } = prevProps;

    const pathChanged =
      prevSelectedPath && prevSelectedPath.id !== selectedPath.id;

    const {
      sessionMAC,
      publicIP,
      nasPort,
      timeUnitFrom,
      timeUnitTo
    } = this.props;
    if (pathChanged) {
      fetchAbuseSessionDetails({
        sessionMAC,
        publicIP,
        nasPort,
        timeUnitFrom,
        timeUnitTo
      });
    }
  };

  filterBySearch = transformedData => {
    const { searchText } = this.state;
    if (isEmpty(searchText)) {
      return transformedData;
    } else {
      return transformedData.filter(row =>
        Object.values(row).some(value =>
          String(value)
            .toLowerCase()
            .includes(searchText.toLowerCase())
        )
      );
    }
  };

  handleCSVExport = () => {
    const { data } = this.props;
    const customHeader = searchTableColumns.map(c => ({
      label: c.label,
      value: row => row[c.name]
    }));
    const parser = new Parser({ fields: customHeader });
    const csv = parser.parse(data);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'SessionDetails.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  renderExportCSV = () => (
    <StyledExportButton title="Generate CSV" onClick={this.handleCSVExport}>
      <ExportCsvIcon />
    </StyledExportButton>
  );

  toggleColumnOptions = () => {
    this.setState(prevState => ({
      showColumnOptions: !prevState.showColumnOptions
    }));
  };

  toggleColumnVisibility = columnName => {
    this.setState(prevState => ({
      searchTableColumns: prevState.searchTableColumns.map(column => {
        if (column.name === columnName) {
          return { ...column, visible: !column.visible };
        }
        return column;
      })
    }));
  };

  renderColumnOptions = () => {
    const { searchTableColumns } = this.state;

    return (
      <ColumnOptions>
        {searchTableColumns.map(column => (
          <div key={column.name}>
            <label>
              <input
                type="checkbox"
                checked={column.visible}
                onChange={() => this.toggleColumnVisibility(column.name)}
              />
              {column.label}
            </label>
          </div>
        ))}
      </ColumnOptions>
    );
  };

  renderViewColumnsButton = () => {
    const { showColumnOptions } = this.state;
    return (
      <Tooltip title="View Columns">
        <ViewColumnsContainer>
          <IconButton
            aria-label="view columns"
            onClick={this.toggleColumnOptions}
          >
            <ViewColumnIcon />
          </IconButton>
          {showColumnOptions && this.renderColumnOptions()}
        </ViewColumnsContainer>
      </Tooltip>
    );
  };

  renderSessionSearch = () => {
    const { searchText } = this.state;

    return (
      <div className="d-flex justify-content-between m-2">
        <div className="d-flex justify-content-start align-items-center">
          <h5 className="mb-0">Session Details</h5>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <FaIcon icon="search" classes={['mr-2', 'mt-2']} />
          <label className="sr-only">Search</label>
          <input
            className="p-1"
            id="session-search"
            onChange={e => this.setState({ searchText: e.target.value })}
            placeholder="Search..."
            style={{ width: 250 }}
            type="text"
            value={searchText || ''}
          />
          <span className="mr-2"></span>
          {this.renderViewColumnsButton()}
          <span className="mr-2"></span>
          {this.renderExportCSV()}
        </div>
      </div>
    );
  };

  render = () => {
    const { data = [], fetchState } = this.props;
    const { pending, complete, failed } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    const transformedData =
      complete && hasData ? transformSearchTableData(data) : [];

    const { searchTableColumns } = this.state;
    const visibleColumns = searchTableColumns.filter(column => column.visible);
    const columns = visibleColumns.map(column => ({
      name: column.name,
      label: column.label
    }));

    return (
      <Fragment>
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow || failed ? (
          <NoDataToShow
            message={
              failed
                ? 'There has been a problem fetching the abuse sessions'
                : 'No data available for the abuse sessions'
            }
            style={{ background: 'none', position: 'relative' }}
          />
        ) : (
          <Fragment>
            {!pending && hasData && this.renderSessionSearch()}
            <div
              className={pending || noDataToShow ? 'fetch-state-pending' : ''}
            >
              {complete && hasData && (
                <MuiThemeProvider
                  theme={createMuiTheme(customMuiTableThemeWithDefualtCursor)}
                >
                  <StatefulTable
                    tableKey="search-table"
                    title="Session Details"
                    data={this.filterBySearch(transformedData)}
                    columns={columns}
                    options={MuiTableOptions}
                  />
                </MuiThemeProvider>
              )}
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  };
}

ClientmacSearchTable.propTypes = {
  selectedPath: PropTypes.object,
  fetchAbuseSessionDetails: PropTypes.func,
  data: PropTypes.array,
  fetchState: fetchStatePropTypes,
  sessionMAC: PropTypes.string,
  publicIP: PropTypes.string,
  nasPort: PropTypes.string,
  timeUnitFrom: PropTypes.any,
  timeUnitTo: PropTypes.any
};

const mapStateToProps = createSelector(
  selectedPathSelector,
  abuseSessionDataSelector,
  abuseSessionDataFetchStateSelector,
  (selectedPath, data, fetchState) => ({
    selectedPath,
    data,
    fetchState
  })
);

const mapDispatchToProps = { fetchAbuseSessionDetails };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientmacSearchTable);
