/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const ModelDropdownFilter = ({
  label,
  id,
  value,
  onChange,
  options,
  minWidth
}) => (
  <Fragment>
    <label className="col-form-label-sm pr-2 mb-0">{label}:</label>
    <select
      className="form-control"
      id={id}
      onChange={onChange}
      value={value}
      style={{ minWidth: minWidth || 'auto' }}
    >
      <option value="all">All</option>
      {options.map((o, i) => (
        <option key={`${id}-${o}-${i}`} value={o}>
          {o}
        </option>
      ))}
    </select>
  </Fragment>
);

ModelDropdownFilter.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  minWidth: PropTypes.string
};

export default ModelDropdownFilter;
