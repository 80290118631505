/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FormTextField } from 'app/components/forms';
import { InlineLoadingIcon } from 'app/components/icons';

const LoginBody = props => {
  const { fields, isPending } = props;

  return (
    <form className={props.className} onSubmit={props.onSubmit}>
      {fields.map(field => (
        <FormTextField
          key={field.id}
          ref={field.ref}
          labelText={field.text}
          fieldId={field.id}
          fieldClasses={field.classes}
          fieldType={field.type}
          handleValueChanged={field.onValueChange}
          value={field.value}
        />
      ))}
      <button
        type="submit"
        className="btn btn-primary btn-block"
        disabled={isPending}
        id="login-button"
      >
        {isPending ? <InlineLoadingIcon /> : 'Login'}
      </button>
    </form>
  );
};

LoginBody.propTypes = {
  className: PropTypes.string.isRequired,
  isPending: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      classes: PropTypes.array,
      ref: PropTypes.object,
      onValueChange: PropTypes.func.isRequired
    })
  )
};

export default styled(LoginBody)`
  width: 300px;
  margin: 30px auto 50px;
`;
