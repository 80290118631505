/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { call, takeLatest } from 'redux-saga/effects';

import { genericAjaxPostSaga } from '../util-sagas/generic-ajax-sagas';

import {
  fetchPropertyInventoryComplete,
  fetchPropertyInventoryError,
  propertyInventorySelector,
  updatePropertyInventoryFetchState
} from './';

const getPropertyInventoryUrls = ['/api/device/getPropertyInventory'];

export function* fetchPropertyInventorySaga() {
  const sagaOptions = {
    completeAction: fetchPropertyInventoryComplete,
    errorAction: fetchPropertyInventoryError,
    currentDataSelector: propertyInventorySelector,
    updateFetchState: updatePropertyInventoryFetchState
  };
  yield call(genericAjaxPostSaga, getPropertyInventoryUrls, sagaOptions);
}

export default function createFetchPropertyInventorySagas(types) {
  return [
    takeLatest([types.fetchPropertyInventory], fetchPropertyInventorySaga)
  ];
}
