/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import moment from 'moment';

export const selectedDateFormat = 'MM/DD/YYYY';

export const defaultStringFilters = {
  wlanName: 'all',
  vertical: 'all',
  verticalLocked: false,
  wlanLocked: false
};

export const dateRangeNames = {
  LAST_24_HOURS: 'Last 24 Hours',
  LAST_7_DAYS: 'Last 7 Days',
  THIS_MONTH: 'This Month',
  LAST_MONTH: 'Last Month',
  LAST_90_DAYS: 'Last 90 Days'
};

export const customDateRangeNames = {
  CUSTOM_DAYS: 'Custom Days',
  CUSTOM_MONTHS: 'Custom Months',
  SHOW_ALL_OPEN_ITEMS: 'All Open Items'
};

const asDateObject = momentDate => momentDate.toDate();

export const getDateRange = label => {
  const ranges = {
    [dateRangeNames.LAST_24_HOURS]: [moment().subtract(1, 'days'), moment()],
    [dateRangeNames.LAST_7_DAYS]: [
      moment().subtract(7, 'days'),
      moment().subtract(1, 'days')
    ],
    [dateRangeNames.THIS_MONTH]: [
      moment().startOf('month'),
      new Date().getDate() === 1 ? moment() : moment().subtract(1, 'days')
    ],
    [dateRangeNames.LAST_MONTH]: [
      moment()
        .subtract(1, 'month')
        .startOf('month')
        .hour(12),
      moment()
        .subtract(1, 'month')
        .endOf('month')
        .hour(12)
    ],
    [dateRangeNames.LAST_90_DAYS]: [
      moment().subtract(90, 'days'),
      moment().subtract(1, 'days')
    ],
    [customDateRangeNames.SHOW_ALL_OPEN_ITEMS]: [
      moment().subtract(1, 'days'),
      moment()
    ]
  };

  return ranges[label].map(asDateObject);
};

export const defaultDateSelection = {
  label: dateRangeNames.LAST_24_HOURS,
  start: moment()
    .subtract(1, 'days')
    .toDate(),
  end: moment().toDate()
};

export const defaultDateSelectionForReports = {
  label: dateRangeNames.LAST_7_DAYS,
  start: moment()
    .subtract(7, 'days')
    .toDate(),
  end: moment()
    .subtract(1, 'days')
    .toDate()
};

export const defaultDateSelectionForAlarms = {
  label: customDateRangeNames.SHOW_ALL_OPEN_ITEMS,
  start: moment()
    .subtract(1, 'days')
    .toDate(),
  end: moment().toDate()
};

export const defaultHostDropdown = [
  { value: '', text: 'Select network appliance...' }
];

export const defaultMetricDropdown = [
  { value: '', text: 'Select report type...' }
];

export const defaultHostMetricSelection = { host: '', metric: '' };

export const granularityDropdownItems = {
  _15MinutesGranularity: { value: '15Mins', text: '15 Minutes' },
  _1HourGranularity: { value: 'Hourly', text: '1 Hour' },
  _1DayGranularity: { value: 'daily', text: '1 Day' }
};
