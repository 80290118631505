/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { HR } from 'app/components/elements';
import { createShowPanelSelector } from 'app/redux/app';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import RogueMAC from './rogue-mac';
import RogueSummaryTable from './rogue-summary-table';

const ReportsRogue = ({ showRogueMACCountTrend, showRogueSummaryTable }) => (
  <Fragment>
    {showRogueMACCountTrend && <RogueMAC />}
    {showRogueMACCountTrend && showRogueSummaryTable && <HR />}
    {showRogueSummaryTable && <RogueSummaryTable />}
  </Fragment>
);

ReportsRogue.propTypes = {
  showRogueMACCountTrend: PropTypes.bool,
  showRogueSummaryTable: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('ReportsRogueMACCountTrend'),
  createShowPanelSelector('ReportsRogueSummaryTable'),
  (showRogueMACCountTrend, showRogueSummaryTable) => ({
    showRogueMACCountTrend,
    showRogueSummaryTable
  })
);

export default connect(mapStateToProps)(ReportsRogue);
