/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { reportUIError } from 'app/redux/app';
import { createErrorAction } from 'app/redux/utils';
import { ReactUIError } from 'app/custom-errors';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasCaughtError: false };
  }

  componentDidCatch(error, info) {
    const { reportUIError } = this.props;
    const uiError = new ReactUIError(this.props.boundaryName, error, info);
    this.setState({ hasCaughtError: true });
    reportUIError(uiError);
  }

  render() {
    const { hasCaughtError } = this.state;
    const { renderOnError, children } = this.props;
    return hasCaughtError ? renderOnError() : children;
  }
}

ErrorBoundary.propTypes = {
  boundaryName: PropTypes.string.isRequired,
  children: PropTypes.any,
  renderOnError: PropTypes.func.isRequired,
  reportUIError: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  reportUIError: error => dispatch(createErrorAction(reportUIError, error))
});

export default connect(
  null,
  mapDispatchToProps
)(ErrorBoundary);
