/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { styleLibrary } from 'app/constants';
import {
  dateRangeFilterSelector,
  updateDateRangeFilter,
  updateVertSSIDFilter,
  vertSSIDFilterSelector
} from 'app/redux/filters';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import DateRange from './components/date-range-filter';
import VerticalSSIDFilter from './components/vertical-ssid-filter';

export const FilterWrapper = styled.div.attrs({
  className: 'd-flex justify-content-between align-items-center mr-3'
})`
  font-size: ${styleLibrary.fontSizes.body}px;

  & .form-group,
  & label {
    margin-bottom: 0;
  }

  & .form-control {
    /* reduce default height value by 0.05rem */
    height: calc(1.5em + 0.7rem);
  }
`;

const allFiltersClosed = {
  isDateOpen: false,
  isVertSSIDOpen: false
};
class Filters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDateOpen: false,
      isVertSSIDOpen: false
    };

    this.updateDateVisibility = this.updateFilterVisibility.bind(
      this,
      'isDateOpen'
    );
    this.updateVertSSIDVisibility = this.updateFilterVisibility.bind(
      this,
      'isVertSSIDOpen'
    );
  }

  updateFilterVisibility(filterName, isOpen) {
    return this.setState({ ...allFiltersClosed, [filterName]: isOpen });
  }

  render() {
    const { isDateOpen, isVertSSIDOpen } = this.state;
    const {
      dateRangeFilter,
      hierarchy,
      selectedPath,
      showDateRangeFilter,
      showVerticalFilter,
      vertSSIDFilter,
      updateVertSSIDFilter,
      updateDateRangeFilter
    } = this.props;

    return (
      <Fragment>
        {showVerticalFilter && (
          <VerticalSSIDFilter
            filter={vertSSIDFilter}
            hierarchy={hierarchy}
            isDropDownOpen={isVertSSIDOpen}
            selectedPath={selectedPath}
            updateFilter={updateVertSSIDFilter}
            updateIsOpen={this.updateVertSSIDVisibility}
          />
        )}
        {showDateRangeFilter && (
          <DateRange
            dateRangeFilter={dateRangeFilter}
            isDropDownOpen={isDateOpen}
            updateDateRangeFilter={updateDateRangeFilter}
            updateIsOpen={this.updateDateVisibility}
            buttonStyle={this.props.applyButtonStyle ? {
              backgroundColor: styleLibrary.containerBg,
              border: '1px solid #ced4da'
            } : null}
          />
        )}
      </Fragment>
    );
  }
}

Filters.propTypes = {
  dateRangeFilter: PropTypes.shape({
    label: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }),
  hierarchy: PropTypes.object,
  selectedPath: PropTypes.object,
  showDateRangeFilter: PropTypes.bool.isRequired,
  showVerticalFilter: PropTypes.bool.isRequired,
  updateDateRangeFilter: PropTypes.func.isRequired,
  updateVertSSIDFilter: PropTypes.func.isRequired,
  vertSSIDFilter: PropTypes.shape({
    vertical: PropTypes.string,
    wlanName: PropTypes.string,
    verticalLocked: PropTypes.bool,
    wlanLocked: PropTypes.bool
  }),
  applyButtonStyle: PropTypes.bool
};

Filters.defaultProps = {
  showDateRangeFilter: true,
  showVerticalFilter: false
};

const mapStateToProps = createSelector(
  hierarchySelector,
  dateRangeFilterSelector,
  selectedPathSelector,
  vertSSIDFilterSelector,
  (hierarchy, dateRangeFilter, selectedPath, vertSSIDFilter) => ({
    hierarchy,
    dateRangeFilter,
    selectedPath,
    vertSSIDFilter
  })
);

const mapDispatchToProps = {
  updateDateRangeFilter,
  updateVertSSIDFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
