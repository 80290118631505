/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isPropertyNodeOrUnder } from 'app/components/layout/components/sidebar/utils';
import { types as appTypes } from 'app/redux/app';
import {
  createAsyncTypes,
  createTypes,
  pendingFetchState
} from 'app/redux/utils';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { simplePayloadAPIs } from './constants';
import createFetchHierarchySagas from './get-hierarchy-saga';
import createHierarchyChangedSagas from './hieararchy-typeahead';

export const nameSpace = 'hierarchy';

export const types = createTypes(
  [
    ...createAsyncTypes('getHierarchy'),
    'updateHierarchyFetchState',
    'hierarchyNodeSelected',
    'hierarchyChangedByTypeahead',
    'resetHierarchy'
  ],
  nameSpace
);

export const sagas = [
  ...createFetchHierarchySagas(types),
  ...createHierarchyChangedSagas(types)
];

export const getHierarchy = createAction(types.getHierarchy);
export const getHierarchyComplete = createAction(types.getHierarchyComplete);
export const getHierarchyError = createAction(types.getHierarchyError);
export const hierarchyNodeSelected = createAction(types.hierarchyNodeSelected);
export const hierarchyChangedByTypeahead = createAction(
  types.hierarchyChangedByTypeahead
);
export const updateHierarchyFetchState = createAction(
  types.updateHierarchyFetchState
);

export const hierarchyFetchStateSelector = state => state[nameSpace].fetchState;
export const hierarchySelector = state => state[nameSpace].hierarchy;
export const createNodeByIdSelector = id =>
  createSelector(hierarchySelector, hierarchy => hierarchy[id]);
export const rootNodeSelector = createSelector(
  hierarchySelector,
  hierarchy => hierarchy[hierarchy.rootNodeId]
);
export const selectedNodeSelector = state => state[nameSpace].selected;
export const selectedPathSelector = createSelector(
  hierarchySelector,
  selectedNodeSelector,
  (hierarchy, selected) => hierarchy && selected ? hierarchy[selected] : undefined
);

const customPayloadExceptions = (api, selected) => {
  if (
    api === '/api/network/getServiceProviderDetails' &&
    isPropertyNodeOrUnder(selected) &&
    selected.id.includes('DOCSIS')
  ) {
    return false;
  }
  return true;
};

export const createApiBodySelector = api =>
  createSelector(selectedPathSelector, (selected = {}) =>
    simplePayloadAPIs.includes(api) && customPayloadExceptions(api, selected)
      ? selected.simpleApiBody
      : selected.complexApiBody
  );

export const initialState = {
  fetchState: pendingFetchState,
  hierarchy: {},
  selected: ''
};

export const reducer = handleActions(
  {
    [types.getHierarchyComplete]: (state, action) => {
      const hierarchy = action.payload;

      return {
        ...state,
        hierarchy,
        selected: hierarchy.rootNodeId
      };
    },

    [types.updateHierarchyFetchState]: (state, { payload }) => ({
      ...state,
      fetchState: payload
    }),

    [types.getHierarchyError]: state => ({
      ...state,
      hierarchy: { error: true }
    }),

    [types.hierarchyNodeSelected]: (state, action) => {
      return {
        ...state,
        selected: action.payload
      };
    },

    [appTypes.appReset]: () => initialState
  },
  initialState
);
