/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { ChartFlexContainer, MultiLineTooltip } from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { NoDataToShow } from 'app/components/elements';
import GranularityFilter from 'app/components/filters/components/granularity-filter';
import { granularityDropdownItems } from 'app/components/filters/constants';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { FailedFetchStateHandler } from 'app/components/utility';
import { commonPlotProps, styleLibrary } from 'app/constants';
import {
  dateRangeFilterSelector,
  granularityListSelector
} from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  fetchRogueTrend,
  rogueTrendFetchStateSelector,
  rogueTrendSelector
} from 'app/redux/rogue';
import { fetchStatePropTypes } from 'app/redux/utils';
import { formatApiDateTime, getGranularityForDateRange } from 'app/utils';
import { get, isArray, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import { createXAxisTicks, formatDataPoints } from '../utils';

const initialState = {
  granularity: granularityDropdownItems._15MinutesGranularity.value
};

export class RogueMACCountTrendChart extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }
  componentDidMount = () => {
    const { fetchRogueTrend, dateRangeFilter } = this.props;
    const newGranularity = getGranularityForDateRange(dateRangeFilter);
    this.setState({ ...initialState, granularity: newGranularity }, () =>
      fetchRogueTrend({
        granularity: newGranularity
      })
    );
  };

  componentDidUpdate = prevProps => {
    const { fetchRogueTrend, selectedPath, dateRangeFilter } = this.props;
    const {
      selectedPath: prevPath,
      dateRangeFilter: prevDateRange
    } = prevProps;

    const propertyChanged =
      isSpecificNodeType(selectedPath, 'zonename') &&
      !isEqual(prevPath.id, selectedPath.id);
    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilter);

    if (propertyChanged || dateRangeChanged) {
      let newGranularity = getGranularityForDateRange(dateRangeFilter);
      this.setState({ ...initialState, granularity: newGranularity }, () =>
        fetchRogueTrend({ granularity: newGranularity })
      );
    }
  };

  renderRogueMACChart = () => {
    const {
      dateRangeFilter,
      chartData: { data, info }
    } = this.props;
    const { granularity } = this.state;

    const timezone = get(info, 'timezone', 'UTC');
    const isTimestampDateAndTime = [
      granularityDropdownItems._15MinutesGranularity.value,
      granularityDropdownItems._1HourGranularity.value
    ].includes(granularity);
    const dataToRender = formatDataPoints(
      data,
      timezone,
      'timestamp',
      isTimestampDateAndTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
    );
    const xAxisTicks = createXAxisTicks(
      data,
      dateRangeFilter,
      timezone,
      'timestamp',
      isTimestampDateAndTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
    );

    return (
      <ChartFlexContainer width="99%" height={250}>
        <LineChart
          data={dataToRender}
          margin={{ ...chartMargins, top: 25, left: 15 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="timestamp"
            label={{
              value: `Time (${timezone})`,
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            tick={tick}
            interval="preserveStartEnd"
            ticks={xAxisTicks}
            tickFormatter={t => formatApiDateTime(t, dateRangeFilter, timezone)}
            scale="time"
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            yAxisId="rogue-count-trend"
            label={{
              value: 'Count',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body,
              fill: styleLibrary.clientsColor
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            // domain={['auto', 'auto']}
            allowDecimals={false}
            interval="preserveStartEnd"
          />
          <Line
            yAxisId="rogue-count-trend"
            dataKey="count"
            name="Count"
            stroke={styleLibrary.clientsColor}
            {...commonPlotProps}
          />
          <Tooltip
            content={
              <MultiLineTooltip
                labelFormatter={label =>
                  formatApiDateTime(
                    label,
                    dateRangeFilter,
                    timezone,
                    isTimestampDateAndTime
                  )
                }
                keys={['count']}
                units={['']}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
          />
        </LineChart>
      </ChartFlexContainer>
    );
  };

  handleGranularityChanged = selectedGranularity => {
    const { fetchRogueTrend } = this.props;
    this.setState({ granularity: selectedGranularity }, () =>
      fetchRogueTrend({ granularity: selectedGranularity })
    );
  };

  renderDropdowns = () => (
    <div className="d-flex justify-content-start align-items-center mx-2 mt-2 mb-0">
      <GranularityFilter
        granularity={this.state.granularity}
        granularityList={this.props.granularityList}
        handleGranularityChanged={this.handleGranularityChanged}
        showLabel={false}
      />
    </div>
  );

  render = () => {
    const { fetchRogueTrend, chartData, fetchState } = this.props;
    const { granularity } = this.state;

    const { data } = chartData;
    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <Fragment>
        {this.renderDropdowns()}
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() => fetchRogueTrend({ granularity })}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message="No data available for the rogue mac count"
              style={{ background: 'none', position: 'relative' }}
            />
          ) : (
            complete && hasData && this.renderRogueMACChart()
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

RogueMACCountTrendChart.propTypes = {
  dateRangeFilter: PropTypes.object,
  selectedPath: PropTypes.object,
  fetchRogueTrend: PropTypes.func,
  chartData: PropTypes.object,
  fetchState: fetchStatePropTypes,
  granularityList: PropTypes.array
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  selectedPathSelector,
  rogueTrendSelector,
  rogueTrendFetchStateSelector,
  granularityListSelector,
  (dateRangeFilter, selectedPath, chartData, fetchState, granularityList) => ({
    dateRangeFilter,
    selectedPath,
    chartData,
    fetchState,
    granularityList
  })
);

const mapDispatchToProps = { fetchRogueTrend };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RogueMACCountTrendChart);
