/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { ExportCsvIcon, ExportPdfIcon, FaIcon } from 'app/components/icons';
import { TableCell } from 'app/components/tables';
import {
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultDisplayDateFormat,
  reportDateFormat,
  styleLibrary
} from 'app/constants';
import { getSortResult } from 'app/utils';
import classNames from 'classnames';
import { cloneDeep, isEmpty, toLower, toNumber, upperFirst } from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const GenerateReportWrapper = styled.div.attrs({
  className: 'm-3 p-2'
})`
  border: 1px solid ${styleLibrary.containerBorderColor};

  & .report-properties label {
    margin-bottom: 0;
  }

  & .title-btn {
    background-color: #ffffff;
    border: 1px solid ${styleLibrary.formControlBorder};
    font-size: ${styleLibrary.fontSizes.body}px;
    padding: 0.375rem 0.75rem !important;
  }
`;

export const GenerateReportExpandCollapse = styled.div.attrs({
  className: 'd-flex align-items-center'
})`
  font-size: ${styleLibrary.fontSizes.secondaryTitle}px;
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
`;

export const ReportFilterWrapper = styled.div.attrs({
  className: 'd-flex align-items-center'
})``;

const ReportDownloadingIcon = ({ classes }) => (
  <div className={classNames('d-inline-block', classes)}>
    <FaIcon
      icon="circle-notch"
      spin
      style={{ fontSize: styleLibrary.fontSizes.inlineIcon }}
    />
  </div>
);
ReportDownloadingIcon.propTypes = {
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

const DeleteButton = styled.div.attrs({
  className: 'd-inline-block ml-4 pl-4'
})`
  border-left: 1px solid ${styleLibrary.containerBorderColor};
`;
DeleteButton.displayName = 'DeleteButton';

const RefreshButton = styled.button.attrs({
  className: 'my-1'
})`
  font-size: ${styleLibrary.fontSizes.titleButton}px;
`;

export const getReportsColumns = (
  isAdmin,
  handleOnClick,
  handleRefresh,
  downloadingReport
) => {
  const hierarchyRenderer = value => {
    const { name, type } = JSON.parse(value);

    return `${upperFirst(type)} - ${name}`;
  };

  const timeRangeRenderer = (value, rowMeta) => {
    const from = moment(value, defaultDateFormat).format(
      defaultDisplayDateFormat
    );
    const to = moment(rowMeta.rowData[4], defaultDateFormat).format(
      defaultDisplayDateFormat
    );
    const unit = rowMeta.rowData[5];

    return `${from} - ${to} (${upperFirst(unit)})`;
  };

  const generatedTimeRenderer = (value, rowMeta) => {
    let formattedDate = moment(value, `${defaultDateFormat} HH:mm:ss`).format(
      defaultDateTimeFormat
    );
    const generatedTime = moment(
      rowMeta.rowData[rowMeta.rowData.length - 1],
      `${defaultDateFormat} HH:mm:ss`
    );

    if (formattedDate === 'Invalid date') {
      formattedDate = (
        <i>
          <b>Started:</b> {generatedTime.format(defaultDateTimeFormat)}
        </i>
      );
    }

    return formattedDate === 'Invalid date' ? '' : formattedDate;
  };

  const actionsRenderer = (value, rowMeta) => {
    const { id = '', type = '' } = downloadingReport;
    const isComplete = rowMeta.rowData[6].toLowerCase() === 'complete';

    return (
      <div className="d-flex justify-content-end report-actions">
        {isComplete &&
          (id === value && type === 'pdf' ? (
            <ReportDownloadingIcon classes="mr-3" />
          ) : (
            <div
              className="d-inline-block mr-3"
              onClick={() => handleOnClick('pdf', value)}
              title="Download PDF of this report"
            >
              <ExportPdfIcon />
            </div>
          ))}
        {isComplete &&
          (id === value && type === 'csv' ? (
            <ReportDownloadingIcon />
          ) : (
            <div
              className="d-inline-block"
              onClick={() => handleOnClick('csv', value)}
              title="Download CSV of this report"
            >
              <ExportCsvIcon />
            </div>
          ))}
        {isAdmin && (
          <DeleteButton
            onClick={() => {
              if (
                // eslint-disable-next-line no-alert, no-restricted-globals
                confirm(
                  'Are you sure you wish to delete this report?\n\nThis action cannot be undone.'
                )
              ) {
                handleOnClick('delete', value);
              }
            }}
            title="Delete this report"
          >
            <FaIcon
              icon="trash-alt"
              mode="far"
              style={{ fontSize: styleLibrary.fontSizes.actionIcon }}
            />
          </DeleteButton>
        )}
      </div>
    );
  };

  const actionsHeadRenderer = colIndex => (
    <TableCell align="right" key={`refresh-cell-${colIndex}`}>
      <RefreshButton onClick={() => handleRefresh(true)}>
        <FaIcon icon="sync-alt" classes={['pr-1 mr-1']} /> Refresh List
      </RefreshButton>
    </TableCell>
  );

  return [
    {
      name: 'hierarchy',
      label: 'Hierarchy',
      options: {
        customBodyRender: hierarchyRenderer
      }
    },
    { name: 'vertical', label: 'Vertical' },
    { name: 'ssid', label: 'SSID' },
    {
      name: 'timeUnitFrom',
      label: 'Dates Covered / Grouped By',
      options: { customBodyRender: timeRangeRenderer }
    },
    { name: 'timeUnitTo', options: { display: 'false' } },
    { name: 'timeUnit', options: { display: 'false' } },
    {
      name: 'status',
      label: 'Status',
      options: { customBodyRender: value => upperFirst(toLower(value)) }
    },
    { name: 'user', label: 'Generated By' },
    {
      name: 'completedTime',
      label: 'Generated @',
      options: {
        customBodyRender: generatedTimeRenderer,
        sortDirection: 'desc'
      }
    },
    {
      name: 'reportUniqueID',
      label: ' ',
      options: {
        customBodyRender: actionsRenderer,
        customHeadRender: actionsHeadRenderer
      }
    },
    { name: 'generatedTime', options: { display: 'false' } }
  ];
};

/**
 * @method sortReportDate
 * @param {Array} data The data to be displayed in the table
 * @param {Number} col The index of the column being processed/sorted against
 * @param {String} order The sort order - either 'asc' or 'desc
 * @returns {Array} An array of data sorted based on the applied criteria
 */
export const sortReportData = (data, col, order) => {
  data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];

    /* eslint-disable no-case-declarations */
    // eslint-disable-next-line default-case
    switch (col) {
      case 0:
      case 1:
      case 2:
        valA = valA.toLowerCase();
        valB = valB.toLowerCase();
        break;

      case 3: // Report start date
        valA = moment(valA, defaultDateFormat).valueOf();
        valB = moment(valB, defaultDateFormat).valueOf();
        break;

      case 8: // Report completed date (or generated date if not yet complete)
        const aDate = valA === '' ? a.data[10] : valA;
        const bDate = valB === '' ? b.data[10] : valB;

        valA = moment(aDate, reportDateFormat).valueOf();
        valB = moment(bDate, reportDateFormat).valueOf();
        break;
    }
    /* eslint-enable no-case-declarations */

    return getSortResult(valA, valB, order);
  });
  return data;
};

export const transformSearchTableData = data => {
  const dateTimeFormat = 'YYYY-MM-DD hh:mm:ss A';
  return data.map(item => {
    if (!isEmpty(item['@timestamp'])) {
      item['@timestamp'] = moment(item['@timestamp'])
        .utc()
        .format(dateTimeFormat);
    }

    if (!isEmpty(item['session_start_time'])) {
      item['session_start_time'] = moment
        .unix(toNumber(item['session_start_time']))
        .utc()
        .format(dateTimeFormat);
    }

    if (!isEmpty(item['session_end_time'])) {
      item['session_end_time'] = moment
        .unix(toNumber(item['session_end_time']))
        .utc()
        .format(dateTimeFormat);
    }

    if (!isEmpty(item['log_timestamp'])) {
      item['log_timestamp'] = momentTimezone
        .tz(item['log_timestamp'], 'YYYY.MMMM.DD.HH:mm:ss', 'UTC')
        .format(dateTimeFormat);
    }

    return item;
  });
};

export const searchTableColumns = [
  { name: '@timestamp', label: 'Timestamp (UTC)' },
  { name: 'acct_input_octets', label: 'Input Octets' },
  { name: 'acct_output_octets', label: 'Output Octets' },
  { name: 'acct_session_id', label: 'Session ID', options: { display: false } },
  {
    name: 'acct_session_time',
    label: 'Session Time (Seconds)',
    options: { display: false }
  },
  {
    name: 'called_station_id',
    label: 'Called Station ID',
    options: { display: false }
  },
  { name: 'dhcp_ip', label: 'DHCP IP' },
  {
    name: 'framed_ip_address',
    label: 'Framed IP Address',
    options: { display: false }
  },
  { name: 'grp', label: 'Group' },
  {
    name: 'log_timestamp',
    label: 'Log Timestamp (UTC)',
    options: { display: false }
  },
  {
    name: 'nas_identifier',
    label: 'NAS Identifier',
    options: { display: false }
  },
  {
    name: 'nas_ip_address',
    label: 'NAS IP Address',
    options: { display: false }
  },
  { name: 'public_IP', label: 'Public IP' },
  { name: 'nas_port_id', label: 'NAS Port ID', options: { display: false } },
  {
    name: 'nas_port_range',
    label: 'NAS Port Range',
    options: { display: false }
  },
  {
    name: 'nas_port_range_start',
    label: 'NAS Port Range Start',
    options: { display: false }
  },
  {
    name: 'nas_port_range_end',
    label: 'NAS Port Range End',
    options: { display: false }
  },
  { name: 'propertyID', label: 'Property ID' },
  { name: 'session_start_time', label: 'Session Start Time (UTC)' },
  {
    name: 'session_end_time',
    label: 'Session End Time (UTC)',
    options: { display: false }
  },
  { name: 'session_mac', label: 'Client Mac' },
  { name: 'type', label: 'Type', options: { display: false } },
  { name: 'vlan_id', label: 'Vlan ID', options: { display: false } }
];

export const clientReportColumnNames = () => [
  { name: 'device', label: 'Client' },
  { name: 'residentAccount', label: 'Resident Account' },
  { name: 'connectionType', label: 'Device Type' },
  { name: 'status', label: 'Client Status' },
  { name: 'switchname', label: 'Switch Name' },
  { name: 'port', label: 'Port' },
  { name: 'firstAccessDate', label: 'First Access Date' },
  { name: 'lastAccessDate', label: 'Last Access Date' }
];

export const createClientsTableExportDataDefinition = filteredData => {
  const exportDataDefinitions = [];
  const columns = clientReportColumnNames();

  let transformedData = cloneDeep(filteredData);
  transformedData = JSON.parse(
    JSON.stringify(
      transformedData,
      columns.map(c => c.name)
    )
  );
  transformedData = transformedData.map(Object.values);

  exportDataDefinitions.push({
    name: 'Client_List_For_Resident_Users',
    data: transformedData,
    columns
  });

  return exportDataDefinitions;
};

export const userReportColumnNames = () => [
  { name: 'email', label: 'User Account' },
  { name: 'firstName', label: 'First Name' },
  { name: 'lastName', label: 'Last Name' },
  { name: 'aleclientcount', label: 'ALE Device Count' },
  { name: 'dpskclientcount', label: 'DPSK Client Count' },
  { name: 'dpskclientcountlimit', label: 'DPSK Client Count Limit' },
  { name: 'aletotalclientcount', label: 'ALE Total Client Count' },
  { name: 'status', label: 'Status' },
  { name: 'accCreationDate', label: 'Account Creation Date' },
  { name: 'accExpiryDate', label: 'Account Expiration Date' },
  { name: 'lastLoginDate', label: 'Last Login Date' }
];

export const createUsersTableExportDataDefinition = filteredData => {
  const exportDataDefinitions = [];
  const columns = userReportColumnNames();

  let transformedData = cloneDeep(filteredData);
  transformedData = JSON.parse(
    JSON.stringify(
      transformedData,
      columns.map(c => c.name)
    )
  );
  transformedData = transformedData.map(Object.values);

  exportDataDefinitions.push({
    name: 'Active_User_List_Report',
    data: transformedData,
    columns
  });

  return exportDataDefinitions;
};