/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { takeEvery, put, select } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';

import { userSelector, userSignOut, validUserFoundInStorage } from '../';

function* verifyJWTSaga() {
  const user = yield select(userSelector);
  const token = getTokenFromAuth(user.auth);

  if (isTokenValid(token)) {
    yield put(validUserFoundInStorage(user));
    return;
  }
  yield put(userSignOut());
}

export function getTokenFromAuth(auth) {
  if (typeof auth !== 'string') {
    return '';
  }
  return auth.replace(/^Bearer[\s\n]*?/, '').trim();
}

export function isTokenValid(token) {
  try {
    const { exp } = jwtDecode(token);
    const expiryInMilliseconds = exp * 1000;
    const now = Date.now();
    return expiryInMilliseconds > now;
  } catch (e) {
    return false;
  }
}

export default function createJWTSagas(types) {
  return [takeEvery(types.userFoundInStorage, verifyJWTSaga)];
}
