/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import axios from 'axios';
import qs from 'query-string';

import { AjaxRequestError, isAjaxRequestError } from 'app/custom-errors';

const binaryMimeTypes = [
  'application/octet-stream',
  'application/pdf',
  'text/csv'
];
let authToken = null;

const { REACT_APP_BACKEND_URL_PREFIX: backendPrefix } = process.env;

const prefixApiUrl = url => `${backendPrefix}${url}`;

export function createAjaxHandler() {
  if ('fetch' in window && 'AbortController' in window) {
    return {
      get: (url, options = {}) =>
        fetch(prefixApiUrl(url), createOptions('GET', options)).then(
          handleFetchResponse
        ),
      post: (url, body, options = {}) =>
        fetch(
          prefixApiUrl(url) +
            (options.params ? `?${qs.stringify(options.params)}` : ''),
          createOptions('POST', options, body)
        ).then(handleFetchResponse)
    };
  }
  return {
    get: (url, options = {}) =>
      axios.get(prefixApiUrl(url), createOptions('GET', options)),
    post: (url, body, options = {}) =>
      axios.post(prefixApiUrl(url), body, createOptions('POST', options))
  };
}

async function handleFetchResponse(resp) {
  const { status, statusText } = resp;
  // Cloning the Response because it will most likely be
  // when we consume the body that an error is thrown
  // We will hopefully be able to get the body text from this clone
  // to help debugging
  const respForErrorHandling = resp.clone();

  // This check ensures we use the correct response handling type depending
  // on whether we're dealing with a JSON API response or a report/file
  // download
  const method = binaryMimeTypes.includes(resp.headers.get('content-type'))
    ? 'blob'
    : 'json';

  let response = null;

  try {
    response = await resp[method]().then(data => ({
      status,
      statusText,
      data,
      headers: resp.headers
    }));

    if (status >= 400) {
      throw new AjaxRequestError(
        `Request failed with a status code of ${status}`,
        response
      );
    }

    return response;
  } catch (err) {
    if (isAjaxRequestError(err)) {
      // We handled this, re throw
      throw err;
    }
    const bodyText = await respForErrorHandling
      .text()
      .catch(e => `Unable to read Response body -> ${e.message}`);
    throw new AjaxRequestError(
      'An Error occurred trying to handle a response',
      response,
      err,
      bodyText
    );
  }
}

function createOptions(method, options, body) {
  return {
    method,
    ...options,
    headers: {
      ...(authToken ? { Authorization: authToken } : {}),
      ...(method === 'POST' ? { 'Content-Type': 'application/json' } : {}),
      cti: 'bml1aV9jbGllbnQ=',
      ...(options.headers || {})
    },
    ...(body
      ? { body: typeof body === 'string' ? body : JSON.stringify(body) }
      : {})
  };
}

export function configureAjaxAuth(newAuthorization) {
  if (newAuthorization) {
    authToken = newAuthorization;
    return;
  }
  authToken = null;
  return;
}
