/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import {
  BlockContainer,
  NoDataToShow,
  TabContainer,
  TabFiller,
  TabInner
} from 'app/components/elements';
import { FaIcon, LoadingIconPlaceholder } from 'app/components/icons';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { StatefulTable, TableExport } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  MuiTableOptions,
  MuiTableTheme,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import { fetchStatePropTypes } from 'app/redux/utils';
import { scrollToBottom, scrollToTop } from 'app/utils';
import classNames from 'classnames';
import { isEmpty, isEqual, merge, pick, toString } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { InventoryTableContainer } from '.';
import { getInventoryTableDetails } from '../get-inventory-columns';
import {
  createTabClickHandler,
  getSortedSummaryItems,
  isActiveTab,
  sortAPInventoryTable,
  sortNonAPInventoryTable
} from '../utils';
import APDetailsTable from './ap-details-table';
import SwitchDetailsTable from './switch-details-table';

class InventoryTable extends Component {
  constructor(...props) {
    super(...props);

    this.state = {
      rowsPerPage: 10,
      locationSearchValue: '',
      modelSearchValue: '',
      selectedAPModels: [],
      rowsSelected: [],
      showAPDetailsTable: false,
      selectedSwitch: {},
      selectedTab: 'Switches'
    };
  }

  componentDidUpdate = prevProps => {
    const { path: prevPath } = prevProps;
    const {
      inventory,
      path,
      selected = {},
      updateInventoryFilter
    } = this.props;
    const { levelType, deviceType } = selected;
    const tabs = getSortedSummaryItems(inventory, path).map(item =>
      pick(item, ['level', 'type'])
    );

    if (
      tabs.length > 0 &&
      !tabs.some(t => t.level === levelType && t.type === deviceType)
    ) {
      const { level: levelType, type: deviceType } = tabs[0];
      updateInventoryFilter({ levelType, deviceType });
    }

    if (prevPath && prevPath.id !== path.id) {
      this.showAPDetailsTable(false);
    }
  };

  handleLocationSearchChange = e => {
    const locationSearchValue = e.target.value;
    return this.setState({ locationSearchValue });
  };

  handleModelSearchChange = e => {
    const modelSearchValue = e.target.value;
    return this.setState({ modelSearchValue });
  };

  handleRowsPerPageChange = rowsPerPage => this.setState({ rowsPerPage });

  filterRows = (offset, search) => row => {
    const [field] = row.slice(offset);
    return toString(field)
      .toLowerCase()
      .includes(search.toLowerCase());
  };

  filterDevicesBySearch = deviceRows => {
    const { locationSearchValue, modelSearchValue } = this.state;

    if (!locationSearchValue && !modelSearchValue) {
      return deviceRows;
    }
    const {
      selected: { deviceType }
    } = this.props;

    if (deviceType.toLowerCase() === 'aps') {
      return !modelSearchValue
        ? deviceRows
        : deviceRows.filter(this.filterRows(0, modelSearchValue));
    }
    return !locationSearchValue
      ? deviceRows
      : deviceRows.filter(this.filterRows(-4, locationSearchValue));
  };

  renderSearchBoxForDeviceType = (type = '') => {
    const { locationSearchValue, modelSearchValue } = this.state;
    if (type.toLowerCase() !== 'aps') {
      return (
        <Fragment>
          <FaIcon icon="search" classes={['mr-2']} />
          <label className="sr-only" htmlFor="location-search">
            Search by Location
          </label>
          <input
            className="p-1"
            id="location-search"
            onChange={this.handleLocationSearchChange}
            placeholder="Search locations..."
            type="text"
            value={locationSearchValue}
          />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <FaIcon icon="search" classes={['mr-2']} />
        <label className="sr-only" htmlFor="model-search">
          Search by Model
        </label>
        <input
          className="p-1"
          id="model-search"
          onChange={this.handleModelSearchChange}
          placeholder="Search models..."
          type="text"
          value={modelSearchValue}
        />
      </Fragment>
    );
  };

  getCompleteState = () => {
    const {
      fetchState: { complete },
      inventory: { summary = [], apsummary = [], apdetails = [] }
    } = this.props;
    return (
      (complete && (!isEmpty(summary) || !isEmpty(apsummary))) ||
      !isEmpty(apdetails)
    );
  };

  showAPDetailsTable = showTable =>
    this.setState({ showAPDetailsTable: showTable }, () =>
      showTable ? scrollToBottom() : scrollToTop()
    );

  toggleSwitchDetailsTable = (selectedSwitch = {}) =>
    this.setState({ selectedSwitch }, () =>
      !isEmpty(selectedSwitch) ? scrollToBottom() : scrollToTop()
    );

  handleSwitchesTableRowClick = rowData => {
    const newSelectedSwitch = {
      switchName: rowData[0],
      zone: rowData[6]
    };
    this.toggleSwitchDetailsTable(
      !isEqual(newSelectedSwitch, this.state.selectedSwitch)
        ? newSelectedSwitch
        : {}
    );
  };

  handleTabChange = (tab, additionalCallback) => {
    this.setState(
      {
        selectedTab: tab,
        locationSearchValue: '',
        modelSearchValue: ''
      },
      () => {
        if (additionalCallback) {
          additionalCallback(tab);
        }
      }
    );
  };

  render() {
    const {
      rowsPerPage,
      selectedAPModels,
      rowsSelected,
      showAPDetailsTable,
      selectedSwitch
    } = this.state;
    const {
      fetchState,
      dispatchAPIRequests,
      inventory,
      path,
      selected
    } = this.props;

    const { pending: isPending } = fetchState;
    const { deviceType } = selected;
    const tabs = getSortedSummaryItems(inventory, path).map(item =>
      pick(item, ['level', 'type'])
    );
    const isAPView = deviceType === 'APs';
    const isSwitchesView = deviceType === 'Switches';
    const isComplete = this.getCompleteState();
    const showTable = (isAPView || !isPending) && isComplete;

    const { detailsForType, columns, uptimes } = getInventoryTableDetails(
      inventory,
      selected
    );

    const isPropertyLevel = isSpecificNodeType(path, 'zonename');

    const tableOptions = merge(
      {},
      MuiTableOptions,
      {
        customSort: isAPView ? sortAPInventoryTable : sortNonAPInventoryTable,
        onChangeRowsPerPage: this.handleRowsPerPageChange,
        rowsPerPage,
        rowsSelected
      },
      isSwitchesView
        ? {
          onRowClick: this.handleSwitchesTableRowClick,
          setRowProps: row => {
            return {
              className:
                  !isEmpty(selectedSwitch) &&
                  classNames(
                    row[0] === selectedSwitch.switchName
                      ? 'row-selected'
                      : 'row-unselected'
                  )
            };
          }
        }
        : {}
    );

    const tableTheme = isSwitchesView
      ? MuiTableTheme
      : customMuiTableThemeWithDefualtCursor;

    return (
      <div className="row my-4" data-test-label="inventory-table">
        <div className="col">
          <FailedFetchStateHandler
            fetchState={fetchState}
            retry={() => dispatchAPIRequests(isPropertyLevel)}
          >
            {isComplete ? (
              <Fragment>
                <BlockContainer>
                  <div className="px-2 pt-3 d-flex">
                    <ul
                      className="nav nav-tabs border-bottom-0"
                      data-test-label="tab-container"
                    >
                      {tabs.map(tab => (
                        <TabContainer
                          isActive={isActiveTab(selected, tab)}
                          level={tab.level}
                          key={`${tab.level}-${tab.type}`}
                          onClick={() =>
                            this.handleTabChange(
                              tab.level,
                              createTabClickHandler(this.props, tab)
                            )
                          }
                        >
                          <button
                            className={`nav-link ${
                              isActiveTab(selected, tab) ? 'active' : ''
                            }`}
                            data-test-label="tab-button"
                          >
                            <TabInner>
                              <span className="tab-level">{tab.level}</span>
                              <span className="tab-type">
                                <strong>{tab.type}</strong>
                              </span>
                            </TabInner>
                          </button>
                        </TabContainer>
                      ))}
                    </ul>
                    <TabFiller />
                    {/* <TabFiller>
                      {isSpecificNodeType(path, 'zonename') && (
                        <div className="d-flex justify-content-end align-items-center">
                          <button
                            className="btn"
                            title="Reload"
                            onClick={() => {
                              fetchInventorySummary({ skipCache: true });
                              fetchInventoryDetails({ skipCache: true });
                            }}
                            style={{ paddingTop: '0.625rem' }}
                          >
                            <MaterialIcon
                              icon="refresh"
                              style={{ fontSize: 30 }}
                            />
                          </button>
                        </div>
                      )}
                    </TabFiller> */}
                    <div />
                  </div>
                  <MuiThemeProvider theme={createMuiTheme(tableTheme)}>
                    <InventoryTableContainer>
                      <div className="inv-table-wrapper">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h3
                              className="pl-2 pt-2"
                              data-test-label="selected-device-header"
                            >
                              {deviceType}
                            </h3>
                            {isAPView && (
                              <p className="px-2 mt-2">
                                This is a summary of the APs at the current
                                hierarchy level grouped by model.
                                {!showAPDetailsTable && (
                                  <span
                                    data-test-label="ap-download-instruction"
                                    className={classNames('link ml-3')}
                                    onClick={() =>
                                      this.showAPDetailsTable(true)
                                    }
                                  >
                                    Show AP details
                                  </span>
                                )}
                              </p>
                            )}
                          </div>
                          <div className="pr-2 d-flex justify-content-space-around align-items-center">
                            {this.renderSearchBoxForDeviceType(deviceType)}
                            <TableExport
                              exportName={deviceType}
                              exportDataDefinitions={[
                                {
                                  name: deviceType,
                                  data: detailsForType,
                                  columns: columns
                                }
                              ]}
                            />
                          </div>
                        </div>
                        <div
                          className={!showTable ? 'fetch-state-pending' : ''}
                        >
                          <StatefulTable
                            tableKey={`${toString(uptimes)}-${toString(
                              selectedAPModels
                            )}`}
                            columns={columns}
                            data={this.filterDevicesBySearch(detailsForType)}
                            options={tableOptions}
                          />
                        </div>
                      </div>
                    </InventoryTableContainer>
                  </MuiThemeProvider>

                  {isAPView && showAPDetailsTable && (
                    <APDetailsTable
                      showAPDetailsTable={this.showAPDetailsTable}
                    />
                  )}
                  {isSwitchesView && !isEmpty(selectedSwitch) && (
                    <SwitchDetailsTable
                      selectedSwitch={selectedSwitch}
                      toggleSwitchDetailsTable={this.toggleSwitchDetailsTable}
                    />
                  )}
                  {!showTable && (
                    <div style={{ marginTop: -250 }}>
                      <LoadingIconPlaceholder position="inherit" height={250} />
                    </div>
                  )}
                </BlockContainer>
              </Fragment>
            ) : (
              <BlockContainer>
                {isPending ? (
                  <LoadingIconPlaceholder />
                ) : (
                  <NoDataToShow
                    style={{ height: 'calc(100% - 5px)', bottom: 'auto' }}
                    message="There are currently no devices at this level."
                  />
                )}
              </BlockContainer>
            )}
          </FailedFetchStateHandler>
        </div>
      </div>
    );
  }
}

InventoryTable.displayName = 'InventoryTable';
InventoryTable.propTypes = {
  dispatchAPIRequests: PropTypes.func,
  fetchState: fetchStatePropTypes,
  inventory: PropTypes.object,
  path: PropTypes.object,
  selected: PropTypes.objectOf(PropTypes.string),
  updateInventoryFilter: PropTypes.func.isRequired
};

export default InventoryTable;
