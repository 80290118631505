/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { call, put, takeLatest, select } from 'redux-saga/effects';
import { userGroupList } from 'app/constants';
import {
  pendingFetchState,
  completeFetchState,
  failedFetchState,
  timeoutFetchState,
  timeoutNotifyFetchState,
  createErrorAction
} from 'app/redux/utils';
import { ajaxPost, debounceSaga } from 'app/redux/util-sagas';
import { isAjaxTimeoutError } from 'app/custom-errors';
import { filterParamsSelector } from 'app/redux/filters';
import { userGroupsSelector } from 'app/redux/app';
import { createApiBodySelector } from 'app/redux/hierarchy';
import waitForHierarchySaga from 'app/redux/hierarchy/wait-for-hierarchy-saga';
import {
  fetchTopClientsComplete,
  fetchTopClientsError,
  updateTopClientsFetchState,
  topClientsSelector
} from './';

const getTopClientsUrl = '/api/network/getSPClientBandwidthInfo';
const getTopClientsUrlForLac = '/api/fastapi/getClientBandwidthInfo';

export function* fetchTopClientsSaga() {
  yield put(updateTopClientsFetchState(pendingFetchState));

  yield call(waitForHierarchySaga);

  const currentDataSet = yield select(topClientsSelector);
  yield call(debounceSaga, currentDataSet);

  const userGroups = yield select(userGroupsSelector);
  const isUserLAC = userGroupList.some(item => userGroups.includes(item));
  // Conditionally pass the url
  const topClientUrls = isUserLAC ? getTopClientsUrlForLac : getTopClientsUrl;

  const filterParams = yield select(filterParamsSelector);
  const apiBody = yield select(createApiBodySelector(topClientUrls));

  // Conditionally set the parameter
  const additionalParams = isUserLAC ? { topNClients: 10 } : { clientUtil: 10 };

  const onTimeout = () =>
    put(updateTopClientsFetchState(timeoutNotifyFetchState));

  try {
    const { data } = yield ajaxPost(topClientUrls, apiBody, {
      params: { ...filterParams, ...additionalParams },
      onTimeout
    });

    yield put(fetchTopClientsComplete(data));
    yield put(updateTopClientsFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchTopClientsError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(updateTopClientsFetchState(updatedFetchState));
  }
}

export default function createFetchTopClientsSagas(types) {
  return [takeLatest(types.fetchTopClients, fetchTopClientsSaga)];
}
