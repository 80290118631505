/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { ssidWLANListSelector } from 'app/redux/filters';
import { isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

export const getWlanList = ssidWLANList => {
  if (Array.isArray(ssidWLANList) && ssidWLANList.length > 0) {
    return ssidWLANList[0].wlan;
  } else {
    return 'all';
  }
};

const SsidWlanFilter = ({
  wlan,
  handleWlanChanged,
  ssidWLANList,
  showLabel,
  showAllOption
}) => {
  const isEmptyList = !Array.isArray(ssidWLANList) || ssidWLANList.length === 0;
  const hasOptions = isArray(ssidWLANList) && !isEmpty(ssidWLANList);
  return (
    <div className="d-flex">
      {showLabel && (
        <label className="col-form-label-sm pr-2 mb-0">SSID:</label>
      )}
      <select
        className="form-control"
        onChange={e => handleWlanChanged(e.target.value)}
        value={wlan}
      >
        {isEmptyList && !showAllOption && (
          <option value="no-ssid">No ssid</option>
        )}
        {showAllOption && <option value="all">All</option>}
        {hasOptions &&
          ssidWLANList.map(({ ssid, wlan }) => (
            <option key={`property-wlan-${wlan}`} value={wlan}>
              {ssid}
            </option>
          ))}
      </select>
    </div>
  );
};

SsidWlanFilter.defaultProps = { showLabel: true, showAllOption: true };

SsidWlanFilter.propTypes = {
  wlan: PropTypes.string,
  handleWlanChanged: PropTypes.func,
  ssidWLANList: PropTypes.array,
  showLabel: PropTypes.bool,
  showAllOption: PropTypes.bool
};

const mapStateToProps = createSelector(ssidWLANListSelector, ssidWLANList => ({
  ssidWLANList
}));

export default connect(mapStateToProps)(SsidWlanFilter);
