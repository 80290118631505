import { PathComponent, TitleBar } from 'app/components/elements';
import SfdcSummary from './components/sfdc-summary';
import Filters from 'app/components/filters';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { createShowPanelSelector } from 'app/redux/app';
import {
  dateRangeFilterLabelSelector,
  vertSSIDFilterSelector
} from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEqual, noop } from 'lodash';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import SfdcPriotyChart from './components/sfdc-priority-chart';
import SfdcCasetypeChart from './components/sfdc-casetype-chart';
import SfdcBypropertyTable from './components/sfdc-by-property-table';
import { fetchSfdcCaseDetails } from 'app/redux/incidents';

export class SfdcIncidents extends Component {

  componentDidMount = () => this.props.fetchSfdcCaseDetails();

  componentDidUpdate = prevProps => {
    const {
      dateRangeFilterLabel,
      fetchSfdcCaseDetails,
      selectedPath,
      vertSSIDFilter
    } = this.props;
    const {
      dateRangeFilterLabel: prevDateRange,
      selectedPath: prevSelectedPath,
      vertSSIDFilter: prevVertSSIDFilter
    } = prevProps;

    const isNodeCustomerOrZone =
      isSpecificNodeType(selectedPath, 'customer') ||
      isSpecificNodeType(selectedPath, 'zonename');
    const pathChanged =
      prevSelectedPath && prevSelectedPath.id !== selectedPath.id;
    const dateRangeChanged = prevDateRange !== dateRangeFilterLabel;
    const vertSSIDFilterChanged = !isEqual(prevVertSSIDFilter, vertSSIDFilter);

    if (
      (isNodeCustomerOrZone && pathChanged) ||
      dateRangeChanged ||
      vertSSIDFilterChanged
    ) {
      fetchSfdcCaseDetails();
    }
  };

  getDynamicRowColClasses = () => {
    const {
      showSfdcChartByPriority,
      showSfdcChartByCaseType,
      showSFDCByPropertyTable
    } = this.props;
    let chartByPriorityClasses = '';
    let chartByCaseTypeClasses = '';
    let incidentByPropertyTableClasses = '';

    if (
      (showSfdcChartByPriority || showSfdcChartByCaseType) &&
      showSFDCByPropertyTable
    ) {
      let pieChartCount = 0;
      [showSfdcChartByPriority, showSfdcChartByCaseType].forEach(c =>
        c ? pieChartCount++ : noop
      );

      if (pieChartCount === 1) {
        chartByPriorityClasses = 'col-xl-4';
        chartByCaseTypeClasses = 'col-xl-4';
        incidentByPropertyTableClasses = 'col-xl';
      } else if (pieChartCount === 3) {
        chartByPriorityClasses = 'col-xxxl';
        chartByCaseTypeClasses = 'col-xxxl';
        incidentByPropertyTableClasses = 'col-xxxl-4';
      }
    }

    return {
      chartByPriorityClasses,
      chartByCaseTypeClasses,
      incidentByPropertyTableClasses
    };
  };
  render = () => {
    const {
      selectedPath,
      showSfdcChartByPriority,
      showSfdcChartByCaseType,
      showSFDCSummaryTable,
      showSFDCByPropertyTable
    } = this.props;

    const showSfdcPieCharts =
      showSfdcChartByPriority || showSfdcChartByCaseType;
    const isCustomerLevel = isSpecificNodeType(selectedPath, 'customer');
    const {
      chartByPriorityClasses,
      chartByCaseTypeClasses,
      incidentByPropertyTableClasses
    } = this.getDynamicRowColClasses();
    return (
      <Fragment>
        <TitleBar
          id="incident-title"
          classes={['include-in-pdf']}
          dark
          leftChildren={<PathComponent />}
          rightChildren={
            <div className="d-flex justify-content-spread align-items-center">
              <Filters showVerticalFilter={true} />
            </div>
          }
        />

        <div className="row mt-4">
          {showSfdcPieCharts && (
            <Fragment>
              {showSfdcChartByPriority && (
                <div className={`col mb-4 ${chartByPriorityClasses}`}>
                  <SfdcPriotyChart />
                </div>
              )}
              {showSfdcChartByCaseType && (
                <div className={`col mb-4 ${chartByCaseTypeClasses}`}>
                  <SfdcCasetypeChart />
                </div>
              )}
            </Fragment>
          )}
          {isCustomerLevel && showSFDCByPropertyTable && (
            <div className={`col mb-4 ${incidentByPropertyTableClasses}`}>
              <SfdcBypropertyTable />
            </div>
          )}
        </div>
        {showSFDCSummaryTable && (
          <div className="row">
            <div className="col">
              <SfdcSummary />
            </div>
          </div>
        )}
      </Fragment>
    );
  };
}

SfdcIncidents.propTypes = {
  data: PropTypes.array,
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  vertSSIDFilter: PropTypes.object,
  fetchSfdcCaseDetails: PropTypes.func,
  showSfdcChartByPriority: PropTypes.bool,
  showSfdcChartByCaseType: PropTypes.bool,
  showSFDCSummaryTable: PropTypes.bool,
  showSFDCByPropertyTable: PropTypes.bool
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  vertSSIDFilterSelector,
  createShowPanelSelector('IncidentsChartByPriority'),
  createShowPanelSelector('IncidentsChartByCaseType'),
  createShowPanelSelector('IncidentsSummaryTable'),
  createShowPanelSelector('IncidentsByPropertyTable'),
  (
    dateRangeFilterLabel,
    selectedPath,
    vertSSIDFilter,
    showSfdcChartByPriority,
    showSfdcChartByCaseType,
    showSFDCSummaryTable,
    showSFDCByPropertyTable
  ) => ({
    dateRangeFilterLabel,
    selectedPath,
    vertSSIDFilter,
    showSfdcChartByPriority,
    showSfdcChartByCaseType,
    showSFDCSummaryTable,
    showSFDCByPropertyTable
  })
);

export default connect(mapStateToProps, { fetchSfdcCaseDetails })(SfdcIncidents);
