/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { PathComponent, TitleBar } from 'app/components/elements';
import Filters from 'app/components/filters';
import React, { Fragment } from 'react';
import { AlarmsTable } from './components';

export const Alarms = () => (
  <Fragment>
    <TitleBar
      id="alarms-title"
      dark
      leftChildren={<PathComponent />}
      rightChildren={
        <div className="d-flex justify-content-spread align-items-center">
          <Filters showVerticalFilter={true} />
        </div>
      }
    />
    <div className="row no-gutters mb-4">
      <AlarmsTable showAlarmsStatistics={true} />
    </div>
  </Fragment>
);

export default Alarms;
