/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import GoogleMapReact from 'google-map-react';
import { isEmpty, isEqual, keys } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { styleLibrary } from 'app/constants';
import { mapOptions } from '../constants';
import { calculateMapDefaults, parseMarkers } from '../utils';
import { MapMarker } from './';

/**
 * This function works out the properties of each map marker depending on how many
 * providers are represented. If one, the lat/lng is easy. If multiple, we calculate
 * the average lat/lng of all contained providers
 *
 * @method getMarkerDisplayProperties
 * @param {Object} marker The list of providers to be displayed at this marker
 * @returns {Object} Contains the label, lat and lng properties of the marker
 */
const getMarkerDisplayProperties = marker => {
  const label =
    marker.length > 1
      ? `${marker.length} items`
      : isEmpty(marker[0].displayName)
        ? marker[0].name
        : marker[0].displayName;
  let lat;
  let lng;

  if (marker.length === 1) {
    lat = marker[0].latitude;
    lng = marker[0].longitude;
  } else {
    lat = marker.reduce((p, c) => c.latitude + p, 0) / marker.length;
    lng = marker.reduce((p, c) => c.longitude + p, 0) / marker.length;
  }

  return { label, lat, lng };
};

class MapContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMarker: null
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({
        activeMarker: null
      });
    }
  }

  handleMarkerClick = marker => {
    if (this.state.activeMarker) {
      this.state.activeMarker.hide();
    }

    this.setState({
      activeMarker: marker
    });
  };

  render() {
    const {
      bounds,
      containerRef,
      data,
      doRender,
      handleTilesLoaded,
      selected,
      hierarchy
    } = this.props;
    const { activeMarker } = this.state;

    // Set map centre as the US at a zoom to fit the whole country
    let position = { center: { lat: 39.828175, lng: -98.5795 }, zoom: 5 };

    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      position = calculateMapDefaults(bounds, {
        width,
        height: styleLibrary.mapHeight
      });
    }

    const markers = parseMarkers(data, position.zoom, selected, hierarchy);
    if (activeMarker) {
      const { latitude: lat, longitude: lng } = activeMarker.props.providers[0];
      position.center = { lat, lng };
    }

    return containerRef ? (
      // This defaults to a height of 1 to force the map to load. If a Google Map renders inside a 0 height
      // element, it won't load and thus none of our logic will fire.
      <div
        id="map-container"
        style={{ opacity: doRender ? 1 : 0.2, height: '100%' }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDj0TNvLEQJcr6GVNnhz8F7kdSFSDNI3UU' }}
          center={position.center}
          onTilesLoaded={handleTilesLoaded}
          options={mapOptions}
          zoom={position.zoom}
        >
          {doRender &&
            keys(markers).map(provider => {
              const marker = markers[provider];
              const { label, lat, lng } = getMarkerDisplayProperties(marker);

              return (
                <MapMarker
                  key={provider}
                  lat={lat}
                  lng={lng}
                  onMarkerClick={this.handleMarkerClick}
                  providers={marker}
                  text={label}
                />
              );
            })}
        </GoogleMapReact>
      </div>
    ) : (
      <div>&nbsp;</div>
    );
  }
}

MapContainer.defaultProps = {
  doRender: false
};

MapContainer.propTypes = {
  bounds: PropTypes.object.isRequired,
  containerRef: PropTypes.object.isRequired,
  data: PropTypes.array,
  doRender: PropTypes.bool,
  handleTilesLoaded: PropTypes.func.isRequired,
  selected: PropTypes.object,
  hierarchy: PropTypes.object
};

export default MapContainer;
