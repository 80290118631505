/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  BasicTooltip,
  ChartFlexContainer,
  ChartLabel,
  ChartLegend,
  ChartModeSwitcher
} from 'app/components/charts';
import { chartMargins } from 'app/components/charts/constants';
import { BlockContainer, NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { chartModeSuffix, styleLibrary } from 'app/constants';
import {
  chartModesSelector,
  createShowPanelSelector,
  updateChartDisplayMode
} from 'app/redux/app';
import {
  networkSummaryFetchStateSelector,
  clientOsChartDataSelector,
  fetchNetworkSummary
} from 'app/redux/network';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  applyLegendToggles,
  calculateChartHeight,
  createColourFactory
} from 'app/utils';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import { getDisplayModeProperties } from '../../utils';
import { ChartTitleBar, PieBarChartWrapper } from '../common';
import { xAxisLabel, xAxisTick } from './constants';

const getFillColour = createColourFactory(30);
const modeKey = `clientOS${chartModeSuffix}`;

export class ClientOS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: []
    };
  }

  handleChangeToggled = label => {
    const { toggled } = this.state;

    if (toggled.includes(label)) {
      toggled.splice(toggled.indexOf(label), 1);
    } else {
      toggled.push(label);
    }

    this.setState({ toggled });
  };

  setMode = mode => {
    this.props.updateChartDisplayMode({ key: modeKey, mode });
  };

  render() {
    const {
      chartData = {},
      chartModes = {},
      dateRangeFilterLabel,
      fetchNetworkSummary,
      fetchState,
      showGuestPrivatePanel,
      windowWidth
    } = this.props;
    const { toggled } = this.state;

    const { pending, complete, timeout } = fetchState;
    const { clientOS = [], totalUniqueClients = 0 } = chartData;
    const hasData = !isEmpty(clientOS);
    const noDataToShow = !pending && complete && !hasData;
    const dataToRender = timeout || pending ? [] : clientOS;
    const mode = chartModes[modeKey] || 'pie';
    const { chartHeight, containerHeight } = calculateChartHeight(
      dataToRender.length,
      400,
      150
    );

    let pieData;
    if (mode === 'pie') {
      pieData = applyLegendToggles(dataToRender, toggled, 'osType', 'count');
    }

    const {
      padding: containerPadding,
      classes: wrapperClasses
    } = getDisplayModeProperties(mode, showGuestPrivatePanel, windowWidth);

    return (
      <Fragment>
        <BlockContainer>
          <ChartTitleBar
            dateRange={dateRangeFilterLabel}
            hasData={!!dataToRender.length}
            total={totalUniqueClients}
            title="Client OS"
          />
          <Fragment>
            {noDataToShow ? <NoDataToShow /> : null}
            <FailedFetchStateHandler
              fetchState={fetchState}
              retry={fetchNetworkSummary}
            >
              <div
                className={classNames(
                  `pb-${containerPadding}`,
                  pending || noDataToShow ? 'fetch-state-pending' : ''
                )}
              >
                {!isEmpty(dataToRender) && (
                  <Fragment>
                    <ChartModeSwitcher onClick={this.setMode} mode={mode} />
                    <PieBarChartWrapper classes={wrapperClasses}>
                      {mode === 'bar' ? (
                        <ChartFlexContainer
                          width="95%"
                          height={containerHeight}
                        >
                          <ComposedChart
                            margin={chartMargins}
                            height={chartHeight}
                            data={dataToRender}
                            layout="vertical"
                          >
                            <CartesianGrid vertical={true} horizontal={false} />
                            <XAxis
                              type="number"
                              label={xAxisLabel}
                              allowDecimals={false}
                              tick={xAxisTick}
                              tickMargin={1}
                            />
                            <YAxis
                              type="category"
                              yAxisId="clientCount"
                              dataKey="osType"
                              stroke="rgb(31, 119, 180)"
                              tick={null}
                            />
                            <Tooltip
                              content={
                                <BasicTooltip maxValue={totalUniqueClients} />
                              }
                              contentStyle={{
                                fontSize: styleLibrary.fontSizes.body
                              }}
                              offset={0}
                              wrapperStyle={{ marginLeft: 80 }}
                            />
                            <Bar
                              dataKey="count"
                              name="Clients"
                              yAxisId="clientCount"
                              dot={false}
                              type="monotone"
                              strokeWidth={1.5}
                            >
                              <LabelList
                                content={<ChartLabel />}
                                dataKey="osType"
                              />
                              {dataToRender.map(({ osType }) => (
                                <Cell
                                  key={osType}
                                  fill={getFillColour(osType)}
                                />
                              ))}
                            </Bar>
                          </ComposedChart>
                        </ChartFlexContainer>
                      ) : (
                        <Fragment>
                          <PieChart width={250} height={250}>
                            <Pie
                              data={pieData}
                              dataKey="count"
                              innerRadius="50%"
                              nameKey="osType"
                              outerRadius="100%"
                            >
                              <LabelList
                                content={<ChartLabel />}
                                dataKey="osType"
                              />
                              {dataToRender.map(({ osType }) => (
                                <Cell
                                  key={osType}
                                  fill={getFillColour(osType)}
                                />
                              ))}
                            </Pie>
                            <Tooltip
                              content={
                                <BasicTooltip maxValue={totalUniqueClients} />
                              }
                              contentStyle={{
                                fontSize: styleLibrary.fontSizes.body
                              }}
                              offset={0}
                              wrapperStyle={{ marginLeft: 80 }}
                            />
                          </PieChart>
                          <ChartLegend
                            data={dataToRender}
                            getFillColour={getFillColour}
                            onToggle={this.handleChangeToggled}
                            textProperty="osType"
                            countProperty="count"
                            toggled={toggled}
                            totalCount={totalUniqueClients}
                          />
                        </Fragment>
                      )}
                    </PieBarChartWrapper>
                  </Fragment>
                )}
              </div>
              {pending && <LoadingIconPlaceholder />}
            </FailedFetchStateHandler>
          </Fragment>
        </BlockContainer>
      </Fragment>
    );
  }
}

ClientOS.propTypes = {
  chartData: PropTypes.shape({
    clientOS: PropTypes.arrayOf(PropTypes.object),
    topTen: PropTypes.arrayOf(
      PropTypes.shape({
        osType: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired
      })
    ),
    totalUniqueClients: PropTypes.number
  }),
  chartModes: PropTypes.object,
  dateRangeFilterLabel: PropTypes.string,
  fetchNetworkSummary: PropTypes.func,
  fetchState: fetchStatePropTypes,
  showGuestPrivatePanel: PropTypes.bool,
  updateChartDisplayMode: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired
};

const mapStateToProps = createSelector(
  chartModesSelector,
  clientOsChartDataSelector,
  networkSummaryFetchStateSelector,
  dateRangeFilterLabelSelector,
  createShowPanelSelector('GuestPrivate'),
  (
    chartModes,
    chartData,
    fetchState,
    dateRangeFilterLabel,
    showGuestPrivatePanel
  ) => ({
    chartModes,
    chartData,
    fetchState,
    dateRangeFilterLabel,
    showGuestPrivatePanel
  })
);

export default connect(mapStateToProps, {
  fetchNetworkSummary,
  updateChartDisplayMode
})(ClientOS);
