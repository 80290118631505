/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { styleLibrary } from 'app/constants';
import { getReadableTimeFormat } from 'app/utils';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

class AleClientsTrendChart extends Component {
  constructor(props) {
    super(props);
    this.state = { hiddenPlotMap: {} };
  }

  isActivePlot = plotKey =>
    this.state.hiddenPlotMap[plotKey] ? 'blank' : plotKey;

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  render() {
    const { data } = this.props;

    return (
      <Fragment>
        <ChartFlexContainer width="99%" height={300}>
          <LineChart data={data} margin={{ ...chartMargins, left: 15 }}>
            <CartesianGrid vertical={false} />
            <XAxis
              xAxisId="time"
              dataKey="label"
              label={{
                value: 'Date',
                position: 'bottom',
                offset: 5,
                fontWeight: 'bold',
                fontSize: styleLibrary.fontSizes.body
              }}
              interval="preserveStartEnd"
              tick={tick}
              tickFormatter={t => getReadableTimeFormat(t)}
              scale="point"
              type="category"
              domain={['dataMin', 'dataMax']}
            />
            <YAxis
              yAxisId="aleUsers"
              label={{
                value: 'Count',
                angle: -90,
                position: 'insideLeft',
                offset: 10,
                dy: 35,
                fontSize: styleLibrary.fontSizes.body
              }}
              stroke="rgb(31, 119, 180)"
              tick={tick}
              allowDecimals={false}
              interval="preserveStartEnd"
            />
            <Tooltip
              content={
                <MultiLineTooltip
                  labelFormatter={label => getReadableTimeFormat(label)}
                  keys={['Total Clients', 'New Clients']}
                  units={['']}
                  showAtSign={false}
                />
              }
              contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
              offset={0}
              wrapperStyle={{ marginLeft: 80 }}
            />
            <Legend
              verticalAlign="top"
              height={36}
              inactivePlots={this.state.hiddenPlotMap}
              content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
            />
            <Line
              yAxisId="aleUsers"
              xAxisId="time"
              dataKey={this.isActivePlot('Total Clients')}
              name="Total Clients"
              stroke={'#82ca9d'}
              dot={false}
              type="monotone"
            />
            <Line
              yAxisId="aleUsers"
              xAxisId="time"
              dataKey={this.isActivePlot('New Clients')}
              name="New Clients"
              stroke={'#8884d8'}
              dot={false}
              type="monotone"
            />
          </LineChart>
        </ChartFlexContainer>
      </Fragment>
    );
  }
}

AleClientsTrendChart.propTypes = { data: PropTypes.array };

export default AleClientsTrendChart;
