/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { FaIcon } from 'app/components/icons';
import { styleLibrary } from 'app/constants';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const StyledExportButton = styled.span.attrs({
  className: 'ml-2 hide-in-pdf'
})`
  cursor: pointer;
`;

export const ExportPdfIcon = ({ className, style }) => (
  <FaIcon
    icon="file-pdf"
    classes={[className]}
    style={{
      color: 'red',
      fontSize: styleLibrary.fontSizes.title,
      ...style
    }}
  />
);
ExportPdfIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export const ExportCsvIcon = ({ className, style }) => (
  <FaIcon
    icon="file-csv"
    classes={[className]}
    style={{
      color: 'green',
      fontSize: styleLibrary.fontSizes.title,
      ...style
    }}
  />
);
ExportCsvIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export const ExportExcelIcon = ({ className, style }) => (
  <FaIcon
    icon="file-excel"
    classes={[className]}
    style={{
      color: 'green',
      fontSize: styleLibrary.fontSizes.title,
      ...style
    }}
  />
);
ExportExcelIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};
