/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { getUniqueVerticals } from 'app/utils/misc';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FilterWrapper } from '../';

class VerticalSSIDFilter extends Component {
  handleFieldChange = filterName => event => {
    const { filter, updateFilter, updateIsOpen } = this.props;
    updateFilter({ ...filter, [filterName]: event.target.value });
    updateIsOpen(false);
  };

  render() {
    const {
      filter: { vertical, verticalLocked },
      hierarchy,
      selectedPath,
      updateIsOpen
    } = this.props;

    const ddVerticals = getUniqueVerticals(hierarchy, selectedPath);

    return (
      <FilterWrapper data-test-label="vert-filter-wrapper">
        <label className="col-form-label-sm pr-2" htmlFor="vertical-filter">
          Vertical:{' '}
        </label>
        <select
          className="form-control"
          disabled={verticalLocked}
          id="vertical-filter"
          onChange={this.handleFieldChange('vertical')}
          onClick={() => updateIsOpen(true)}
          value={vertical}
        >
          {ddVerticals.map(({ value, text }) => (
            <option key={`vertical-${value}`} value={value}>
              {text}
            </option>
          ))}
        </select>
      </FilterWrapper>
    );
  }
}

VerticalSSIDFilter.defaultProps = {
  vertical: 'all',
  wlan: 'all'
};

VerticalSSIDFilter.propTypes = {
  isDropDownOpen: PropTypes.bool,
  hierarchy: PropTypes.object,
  selectedPath: PropTypes.object,
  updateFilter: PropTypes.func.isRequired,
  updateIsOpen: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    vertical: PropTypes.string,
    verticalLocked: PropTypes.bool,
    wlanName: PropTypes.string,
    wlanLocked: PropTypes.bool
  })
};

export default VerticalSSIDFilter;
