/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

import { InlineLoadingIcon } from 'app/components/icons';
import { styleLibrary } from 'app/constants';
import { breadcrumbPathSelector } from './selectors';

const PathElement = styled.span`
  &:last-child {
    color: ${styleLibrary.brand.highlight};
    font-weight: bold;
  }
`;

export const PathComponent = props => {
  const { renderPath } = props;

  if (isEmpty(renderPath)) {
    return <InlineLoadingIcon data-test-label="path-inline-loading" />;
  }

  return renderPath.map(({ name, displayName }, index) => (
    <Fragment key={name}>
      {index === 0 ? null : (
        <span data-test-label="path-element-separator"> / </span>
      )}
      <PathElement data-test-label="path-element">
        {isEmpty(displayName) ? name : displayName}
      </PathElement>
    </Fragment>
  ));
};

PathComponent.propTypes = {
  path: PropTypes.array,
  selectedPath: PropTypes.object
};

const mapStateToProps = createSelector(breadcrumbPathSelector, renderPath => ({
  renderPath
}));

export default connect(mapStateToProps)(PathComponent);
