/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';

const TextLink = props => {
  const { children, className, onClick, style, title, to = '#' } = props;

  return (
    <a
      className={className}
      data-test-label="text-link"
      href={to}
      onClick={onClick}
      style={style}
      title={title}
    >
      {children}
    </a>
  );
};

TextLink.propTypes = {
  children: PropTypes.string,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  title: PropTypes.string,
  to: PropTypes.string
};

export default styled(TextLink)`
  display: inline-block;
  margin: 4px 0;
  cursor: pointer;
  color: ${styleLibrary.lightText};
  text-decoration: none;

  &:hover {
    color: ${styleLibrary.lightText};
    text-decoration: none;
  }

  &.selected {
    color: ${styleLibrary.brand.highlight} !important;
    font-weight: bold;
  }
`;
