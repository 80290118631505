/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { styleLibrary } from 'app/constants';
import styled from 'styled-components';

export { default as InventoryTable } from './inventory-table';

export const InventoryTableContainer = styled.div.attrs({
  className: 'px-2 pb-4'
})`
  & .inv-table-wrapper {
    border: 1px solid ${styleLibrary.containerBorderColor};
    border-top: none;
  }
`;

export const TableContainer = styled.div.attrs({
  className: 'px-2 pb-4'
})``;

export const TableWrapper = styled.div`
  border: 1px solid ${styleLibrary.containerBorderColor};
`;

export const TableHeader = styled.div.attrs({
  className: 'd-flex justify-content-between align-items-center'
})``;

export const TableTitle = styled.h3.attrs({
  className: 'pl-2 pt-2'
})``;

export const TableSubtitle = styled.p.attrs({ className: 'px-2 mt-2' })``;

export const TableActions = styled.div.attrs({
  className: 'pr-2 d-flex justify-content-space-around align-items-center'
})``;

export const CloseButton = styled.button.attrs({
  className: 'btn btn-outline-dark my-1 ml-3'
})`
  font-size: ${styleLibrary.fontSizes.titleButton}px;
`;
