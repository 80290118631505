/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { defaultHostMetricSelection } from 'app/components/filters/constants';
import { FormDropDown } from 'app/components/forms';
import { MaterialIcon } from 'app/components/icons';
import { TableExport } from 'app/components/tables';
import { userNameSelector } from 'app/redux/app';
import {
  hostDropdownSelector,
  hostMetricFilterSelector,
  metricDropdownSelector,
  updateHostDropdown,
  updateHostMetricFilter
} from 'app/redux/filters';
import {
  fetchZabbixDataTrend,
  zabbixDataTrendSelector
} from 'app/redux/inventory';
import classNames from 'classnames';
import { has, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  createTrendExportDataDefinition,
  createTrendHtmlExportDefinition
} from '../../utils';

export class HostMetricDropdowns extends Component {
  handleTypeChanged = (field, value) => {
    const {
      populateMetricDropdown,
      fetchZabbixDataTrend,
      updateHostMetricFilter
    } = this.props;

    if (field === 'host') {
      updateHostMetricFilter({ host: value, metric: '' });
      populateMetricDropdown(value);
    } else if (field === 'metric') {
      updateHostMetricFilter({ metric: value });
    }
    if (value !== '' && field === 'metric') {
      fetchZabbixDataTrend();
    }
  };

  render = () => {
    const {
      fetchZabbixDataTrend,
      hostMetricFilter: { host, metric },
      hostDropdown,
      metricDropdown,
      trendChartData: { DataTrend: trend = [] },
      property,
      userName
    } = this.props;
    const hasTrendData = !isEmpty(trend) && !has(trend[0], 'errorCode');

    return (
      <div className="row mb-0 no-gutters form-group">
        <div className="px-3 pt-3 col-sm-5">
          <FormDropDown
            dataset={hostDropdown}
            fieldId="host"
            handleValueChanged={this.handleTypeChanged}
            small
            value={host}
          />
        </div>
        <div
          className={classNames(
            'px-3 pt-3',
            hasTrendData ? 'col-sm-5' : 'col-sm-6'
          )}
        >
          <FormDropDown
            dataset={metricDropdown}
            fieldId="metric"
            handleValueChanged={this.handleTypeChanged}
            small
            value={metric}
          />
        </div>
        <div
          className={classNames(
            'pr-3 d-flex justify-content-between align-items-center',
            hasTrendData ? 'col-sm-2' : 'col-sm-1'
          )}
        >
          <button
            className="btn"
            title="Reload"
            onClick={() => fetchZabbixDataTrend({ skipCache: true })}
            style={{ paddingTop: '0.625rem' }}
          >
            <MaterialIcon icon="refresh" style={{ fontSize: 30 }} />
          </button>
          {hasTrendData && (
            <TableExport
              className="hide-in-pdf"
              exportName="Property Data Trend"
              exportDataDefinitions={createTrendExportDataDefinition(
                host,
                metric,
                trend
              )}
              htmlExportDefinition={createTrendHtmlExportDefinition(
                property,
                userName
              )}
            />
          )}
        </div>
      </div>
    );
  };
}

HostMetricDropdowns.defaultProps = {
  hostMetricFilter: defaultHostMetricSelection
};

HostMetricDropdowns.propTypes = {
  property: PropTypes.object,
  userName: PropTypes.string,
  populateMetricDropdown: PropTypes.func,
  fetchZabbixDataTrend: PropTypes.func,
  updateHostMetricFilter: PropTypes.func,
  updateHostDropdown: PropTypes.func,
  hostMetricFilter: PropTypes.object,
  hostDropdown: PropTypes.array,
  metricDropdown: PropTypes.array,
  trendChartData: PropTypes.object
};

const mapStateToProps = createSelector(
  userNameSelector,
  hostMetricFilterSelector,
  hostDropdownSelector,
  metricDropdownSelector,
  zabbixDataTrendSelector,
  (
    userName,
    hostMetricFilter,
    hostDropdown,
    metricDropdown,
    trendChartData
  ) => ({
    userName,
    hostMetricFilter,
    hostDropdown,
    metricDropdown,
    trendChartData
  })
);

const mapDispatchToProps = {
  fetchZabbixDataTrend,
  updateHostMetricFilter,
  updateHostDropdown
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostMetricDropdowns);
