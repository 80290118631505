/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  defaultDateSelection,
  defaultHostDropdown,
  defaultHostMetricSelection,
  defaultMetricDropdown,
  defaultStringFilters,
  granularityDropdownItems
} from 'app/components/filters/constants';
import { defaultDateFormat } from 'app/constants';
import { types as appTypes } from 'app/redux/app';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { createTypes } from 'app/redux/utils';
import { get } from 'lodash';
import moment from 'moment';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import createFilterSagas from './filter-sagas';
import { dateRangeFilterTransform } from './transforms';
import createWatchForChangesSagas from './watch-for-changes-saga';

export const nameSpace = 'filters';

export const types = createTypes(
  [
    'updateDateRangeFilter',
    'updateVertSSIDFilter',
    'updateHostMetricFilter',
    'updateHostDropdown',
    'updateMetricDropdown',
    'updateSSIDWLANList',
    'updateGranularityList'
  ],
  nameSpace
);

export const sagas = [
  ...createFilterSagas(types),
  ...createWatchForChangesSagas(types)
];

export const updateDateRangeFilter = createAction(types.updateDateRangeFilter);
export const updateVertSSIDFilter = createAction(types.updateVertSSIDFilter);
export const updateHostMetricFilter = createAction(
  types.updateHostMetricFilter
);
export const updateHostDropdown = createAction(types.updateHostDropdown);
export const updateMetricDropdown = createAction(types.updateMetricDropdown);

export const updateSSIDWLANList = createAction(types.updateSSIDWLANList);

export const updateGranularityList = createAction(types.updateGranularityList);

export const dateRangeFilterSelector = state => state[nameSpace].dateRange;
export const dateRangeFilterLabelSelector = state =>
  dateRangeFilterSelector(state).label;

export const vertSSIDFilterSelector = state => state[nameSpace].dropDownFilters;
export const hostMetricFilterSelector = state =>
  state[nameSpace].hostMetricFilter;
export const hostDropdownSelector = state => state[nameSpace].hostDropdown;
export const metricDropdownSelector = state => state[nameSpace].metricDropdown;

export const filterParamsSelector = createSelector(
  dateRangeFilterSelector,
  vertSSIDFilterSelector,
  selectedPathSelector,
  (dateRange, { wlanName, vertical }, selected) => {
    const { timeUnit, timeUnitFrom, timeUnitTo } = dateRangeFilterTransform(
      dateRange
    );

    // If the current node in the tree is a WLAN, force it's name as the WLAN query string parameter.
    // Otherwise use whatever is stored in the filter.
    const requiredWlan =
      selected && selected.type === 'wlan' ? selected.name : wlanName;

    return {
      timeUnit,
      timeUnitFrom,
      timeUnitTo,
      wlanName: requiredWlan,
      vertical
    };
  }
);
export const offsetFilterParamsSelector = offset =>
  createSelector(filterParamsSelector, filterParams => {
    const { timeUnit, timeUnitFrom, timeUnitTo, ...rest } = filterParams;
    const offsetFrom = moment(timeUnitFrom, defaultDateFormat).subtract(
            offset,
            'days'
          ),
          offsetTo = moment(timeUnitTo, defaultDateFormat).subtract(offset, 'days');

    return {
      ...rest,
      timeUnit: timeUnit === '24Hours' ? 'day' : timeUnit,
      timeUnitFrom: offsetFrom.format(defaultDateFormat),
      timeUnitTo: offsetTo.format(defaultDateFormat)
    };
  });

export const ssidWLANListSelector = state => state[nameSpace].ssidWLANList;

export const granularityListSelector = state =>
  get(state[nameSpace], 'granularityList', initialState.granularityList);

const initialState = {
  dropDownFilters: defaultStringFilters,
  dateRange: defaultDateSelection,
  hostMetricFilter: defaultHostMetricSelection,
  hostDropdown: defaultHostDropdown,
  metricDropdown: defaultMetricDropdown,
  ssidWLANList: [],
  granularityList: [
    granularityDropdownItems._15MinutesGranularity,
    granularityDropdownItems._1HourGranularity
  ]
};

export const reducer = handleActions(
  {
    [appTypes.userLoginComplete]: () => ({
      dropDownFilters: defaultStringFilters,
      dateRange: defaultDateSelection
    }),

    [types.updateDateRangeFilter]: (state, { payload }) => ({
      ...state,
      dateRange: payload
    }),

    [types.updateVertSSIDFilter]: (state, { payload }) => ({
      ...state,
      dropDownFilters: payload
    }),

    [types.updateHostMetricFilter]: (state, { payload }) => ({
      ...state,
      hostMetricFilter: { ...state.hostMetricFilter, ...payload }
    }),

    [types.updateHostDropdown]: (state, { payload }) => ({
      ...state,
      hostDropdown: [...defaultHostDropdown, ...payload]
    }),

    [types.updateMetricDropdown]: (state, { payload }) => ({
      ...state,
      metricDropdown: [...defaultMetricDropdown, ...payload]
    }),

    [types.updateSSIDWLANList]: (state, { payload }) => ({
      ...state,
      ssidWLANList: payload
    }),

    [types.updateGranularityList]: (state, { payload }) => ({
      ...state,
      granularityList: payload
    }),

    [appTypes.appReset]: () => initialState
  },
  initialState
);
