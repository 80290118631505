/* eslint-disable default-case */
/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { findIndex, merge } from 'lodash';
import moment from 'moment';
import React from 'react';
import { FaIcon } from 'app/components/icons';
import {
  CustomTableHeader,
  OnePartBar,
  TwoPartBar
} from 'app/components/tables';
import { bandwidthUnits, dataRateUnits, styleLibrary } from 'app/constants';
import {
  castToNumber,
  getPrettyTimeFormat,
  getScaledDisplayValue,
  getSortResult,
  toFormattedDateTime
} from 'app/utils';

import { HeaderColorBlock, HeaderBlockLabel } from './components';

export const getClientColumns = (
  maxClientValues,
  sortName,
  sortDir,
  durationUnit = 'hours'
) => {
  const {
    bandwidth: maxBandwidth,
    sessions: maxSessions,
    duration: maxDuration
  } = maxClientValues;

  const bandwidthHeader = (column, sortFn) => {
    const isSorted = column.sortDirection !== 'none';
    const dir = isSorted ? column.sortDirection : 'desc';

    return (
      <CustomTableHeader
        key={column.name}
        columnIndex={column.index}
        isSorted={isSorted}
        sortDirection={dir}
        sortFn={sortFn}
      >
        <span className="mr-4">{column.label}</span>
        <HeaderColorBlock color={styleLibrary.downloadColor} />
        <HeaderBlockLabel>Down</HeaderBlockLabel>
        <HeaderColorBlock color={styleLibrary.uploadColor} />
        <HeaderBlockLabel>Up</HeaderBlockLabel>
      </CustomTableHeader>
    );
  };

  const bandwidthColumn = (value, rowMeta) => {
    return (
      <TwoPartBar
        barWidth={250}
        left={{
          value: rowMeta.rowData[5],
          color: styleLibrary.downloadColor,
          indicator: (
            <FaIcon
              icon="angle-double-down"
              style={{ color: styleLibrary.downloadColor }}
            />
          )
        }}
        max={maxBandwidth}
        right={{
          value: rowMeta.rowData[6],
          color: styleLibrary.uploadColor,
          indicator: (
            <FaIcon
              icon="angle-double-up"
              style={{ color: styleLibrary.uploadColor }}
            />
          )
        }}
        totalFormatter={val => getScaledDisplayValue(val, bandwidthUnits, 0, 1)}
      />
    );
  };

  const numberOfSessionsColumn = value => (
    <OnePartBar
      barWidth={60}
      display={{
        value: castToNumber(value),
        color: styleLibrary.sessionsColor
      }}
      max={maxSessions}
    />
  );

  const sessionDurationColumn = value => {
    const durationValue = castToNumber(value);

    return (
      <OnePartBar
        barWidth={60}
        display={{
          value: durationValue,
          color: styleLibrary.sessionDurationColor
        }}
        formatter={value => {
          const seconds = moment.duration(value, durationUnit).as('seconds');
          return getPrettyTimeFormat(seconds);
        }}
        max={maxDuration}
      />
    );
  };

  const columns = [
    {
      name: 'clientMAC',
      label: 'Client MAC'
    },
    {
      name: 'osType',
      label: 'OS'
    },
    {
      name: 'zoneName',
      label: 'Property'
    },
    {
      name: 'vertical',
      label: 'Vertical'
    },
    {
      name: 'bandwidthSumInMB',
      label: 'Total Traffic',
      options: {
        customHeadRender: bandwidthHeader,
        customBodyRender: bandwidthColumn,
        sortDirection: 'desc'
      }
    },
    {
      name: 'bandwidthRXInMB',
      options: {
        display: 'false'
      }
    },
    {
      name: 'bandwidthTXInMB',
      options: {
        display: 'false'
      }
    },
    {
      name: 'sessionCount',
      label: '# Sessions',
      options: {
        customBodyRender: numberOfSessionsColumn
      }
    },
    {
      name: 'sessionDuration',
      label: 'Total Time',
      options: {
        customBodyRender: sessionDurationColumn
      }
    },
    {
      name: 'bandwidthUnit',
      options: {
        display: 'false'
      }
    }
  ];

  if (sortName) {
    const col = columns[findIndex(columns, { name: sortName })];
    merge(col, { options: { sortDirection: sortDir } });
  }

  return columns;
};

export const getClientColumnsForLAC = (maxClientValues, sortName, sortDir) => {
  const { bandwidth: maxBandwidth } = maxClientValues;

  const bandwidthHeader = (column, sortFn) => {
    const isSorted = column.sortDirection !== 'none';
    const dir = isSorted ? column.sortDirection : 'desc';

    return (
      <CustomTableHeader
        key={column.name}
        columnIndex={column.index}
        isSorted={isSorted}
        sortDirection={dir}
        sortFn={sortFn}
      >
        <span className="mr-4">{column.label}</span>
        <HeaderColorBlock color={styleLibrary.downloadColor} />
        <HeaderBlockLabel>Down</HeaderBlockLabel>
        <HeaderColorBlock color={styleLibrary.uploadColor} />
        <HeaderBlockLabel>Up</HeaderBlockLabel>
      </CustomTableHeader>
    );
  };

  const bandwidthColumn = (value, rowMeta) => {
    return (
      <TwoPartBar
        barWidth={250}
        left={{
          value: rowMeta.rowData[6],
          color: styleLibrary.downloadColor,
          indicator: (
            <FaIcon
              icon="angle-double-down"
              style={{ color: styleLibrary.downloadColor }}
            />
          )
        }}
        max={maxBandwidth}
        right={{
          value: rowMeta.rowData[7],
          color: styleLibrary.uploadColor,
          indicator: (
            <FaIcon
              icon="angle-double-up"
              style={{ color: styleLibrary.uploadColor }}
            />
          )
        }}
        totalFormatter={val => getScaledDisplayValue(val, bandwidthUnits, 0, 1)}
      />
    );
  };

  const columns = [
    {
      name: 'client',
      label: 'Client MAC'
    },
    {
      name: 'zonename',
      label: 'Property'
    },
    {
      name: 'manufacturer',
      label: 'Manufacturer'
    },
    {
      name: 'port',
      label: 'Port'
    },
    {
      name: 'switch',
      label: 'Switch'
    },
    {
      name: 'vertical',
      label: 'Vertical'
    },
    {
      name: 'total_traffic',
      label: 'Total Traffic',
      options: {
        customHeadRender: bandwidthHeader,
        customBodyRender: bandwidthColumn,
        sortDirection: 'desc'
      }
    },
    {
      name: 'bandwidthrx',
      options: {
        display: 'false'
      }
    },
    {
      name: 'bandwidthtx',
      options: {
        display: 'false'
      }
    }
  ];

  if (sortName) {
    const col = columns[findIndex(columns, { name: sortName })];
    merge(col, { options: { sortDirection: sortDir } });
  }

  return columns;
};

export const getSessionColumns = maxSessionValues => {
  const bandwidthHeader = (column, sortFn) => {
    const isSorted = column.sortDirection !== null;
    const dir = isSorted ? column.sortDirection : 'asc';

    return (
      <CustomTableHeader
        key={column.name}
        columnIndex={column.index}
        isSorted={isSorted}
        sortDirection={dir}
        sortFn={sortFn}
      >
        <span className="mr-4">{column.label}</span>
        <HeaderColorBlock color={styleLibrary.downloadColor} />
        <HeaderBlockLabel>Down</HeaderBlockLabel>
        <HeaderColorBlock color={styleLibrary.uploadColor} />
        <HeaderBlockLabel>Up</HeaderBlockLabel>
      </CustomTableHeader>
    );
  };

  const bandwidthColumn = (value, rowMeta) => (
    <TwoPartBar
      barWidth={250}
      left={{
        value: rowMeta.rowData[4],
        color: styleLibrary.downloadColor,
        indicator: (
          <FaIcon
            icon="angle-double-down"
            style={{ color: styleLibrary.downloadColor }}
          />
        )
      }}
      max={maxSessionValues.bandwidth}
      right={{
        value: rowMeta.rowData[5],
        color: styleLibrary.uploadColor,
        indicator: (
          <FaIcon
            icon="angle-double-up"
            style={{ color: styleLibrary.uploadColor }}
          />
        )
      }}
      suffix={rowMeta.rowData[rowMeta.rowData.length - 2]}
      totalFormatter={val => getScaledDisplayValue(val, bandwidthUnits, 0, 1)}
    />
  );

  const sessionDurationColumn = value => (
    <OnePartBar
      barWidth={60}
      display={{
        value,
        color: styleLibrary.sessionDurationColor
      }}
      formatter={v => getPrettyTimeFormat(v, true)}
      max={maxSessionValues.duration}
    />
  );

  const dateColumn = value => {
    return toFormattedDateTime(value);
  };

  const dataRateColumn = value => {
    return getScaledDisplayValue(value, dataRateUnits);
  };

  return [
    {
      name: 'apMAC',
      label: 'Station'
    },
    {
      name: 'datarateRXInKbps',
      label: 'Rate Down',
      options: {
        customBodyRender: dataRateColumn
      }
    },
    {
      name: 'datarateTXInKbps',
      label: 'Rate Up',
      options: {
        customBodyRender: dataRateColumn
      }
    },
    {
      name: 'bandwidthSumInMB',
      label: 'Total Traffic',
      options: {
        customHeadRender: bandwidthHeader,
        customBodyRender: bandwidthColumn,
        sortDirection: 'desc'
      }
    },
    {
      name: 'bandwidthRXInMB',
      options: {
        display: 'false'
      }
    },
    {
      name: 'bandwidthTXInMB',
      options: {
        display: 'false'
      }
    },
    {
      name: 'sessionStart',
      label: 'Start',
      options: {
        customBodyRender: dateColumn
      }
    },
    {
      name: 'sessionEnd',
      label: 'End',
      options: {
        customBodyRender: dateColumn
      }
    },
    {
      name: 'sessionDurationInSec',
      label: 'Duration',
      options: {
        customBodyRender: sessionDurationColumn
      }
    },
    {
      name: 'bandwidthUnit',
      options: {
        display: 'false'
      }
    },
    {
      name: 'dataRateUnit',
      options: {
        display: 'false'
      }
    }
  ];
};

export const sortTopClients = (data, col, order) => {
  return data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];

    // eslint-disable-next-line default-case
    switch (col) {
      case 1:
      case 2:
        valA = a.data[col].toLowerCase();
        valB = b.data[col].toLowerCase();
        break;
    }

    return getSortResult(valA, valB, order);
  });
};

export const sortClientSessions = (data, col, order) => {
  return data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];

    return getSortResult(valA, valB, order);
  });
};

export const sortTopClientsLac = (data, col, order) => {
  return data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];

    // Sort the traffic column based on the 'total_traffic' value
    if (col === 6) {
      valA = a.data[6] + a.data[7];
      valB = b.data[6] + b.data[7];
    }
  
    // Convert to lowercase for string comparison
    if (typeof valA === 'string' && typeof valB === 'string') {
      valA = valA.toLowerCase();
      valB = valB.toLowerCase();
    }
  
    // Convert to numbers if they are not already
    if (!isNaN(valA)) {
      valA = parseFloat(valA);
    }
    if (!isNaN(valB)) {
      valB = parseFloat(valB);
    }

    return getSortResult(valA, valB, order);
  });
};
