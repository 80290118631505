/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';

const ClientmacSearchForm = ({
  id,
  value,
  onChange,
  placeholder,
  label,
  helperText,
  isValidInput
}) => (
  <div className="form-group align-items-center row">
    <label className="col-sm-3 col-form-label text-right" htmlFor={id}>
      {label}
    </label>
    <div className="col-sm-4">
      <input
        id={id}
        className={`form-control ${!isValidInput && 'is-invalid'}`}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <small className="invalid-feedback">Please provide a valid {label}</small>
    </div>
    <small
      className={`col-sm form-text ${
        isValidInput ? 'text-muted' : 'text-danger'
      }`}
    >
      {helperText}
    </small>
  </div>
);

ClientmacSearchForm.defaultProps = { isValidInput: true };
ClientmacSearchForm.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  isValidInput: PropTypes.bool
};

export default ClientmacSearchForm;
