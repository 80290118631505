/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { call, put, race, take, takeLatest } from 'redux-saga/effects';

import { buildUIHierarchy } from 'app/components/layout/components/sidebar/utils';
import {
  pendingFetchState,
  completeFetchState,
  failedFetchState,
  timeoutFetchState,
  createErrorAction
} from 'app/redux/utils';
import { isAjaxTimeoutError } from 'app/custom-errors';
import { types as appTypes } from 'app/redux/app';
import { ajaxPost } from 'app/redux/util-sagas';

import {
  getHierarchyComplete,
  getHierarchyError,
  hierarchyNodeSelected,
  updateHierarchyFetchState
} from '.';

export const getHierarchyUrl = '/api/network/getServiceProviderHierarchy';
const emptyBody = {};

export function* getHierarchySaga() {
  yield put(updateHierarchyFetchState(pendingFetchState));

  try {
    const { data } = yield ajaxPost(getHierarchyUrl, emptyBody);
    const uiHierarchy = buildUIHierarchy(data);

    yield put(getHierarchyComplete(uiHierarchy));
    yield put(updateHierarchyFetchState(completeFetchState));
    yield put(hierarchyNodeSelected(uiHierarchy.rootNodeId));
  } catch (err) {
    yield put(createErrorAction(getHierarchyError, err));
    const updatedFetchState = isAjaxTimeoutError(err)
      ? timeoutFetchState
      : failedFetchState;
    yield put(updateHierarchyFetchState(updatedFetchState));
  }
}

function* cancelableGetHierarchySaga(...args) {
  yield race([call(getHierarchySaga, ...args), take(appTypes.userSignOut)]);
}

export default function createFetchHierarchySagas(types) {
  return [takeLatest(types.getHierarchy, cancelableGetHierarchySaga)];
}
