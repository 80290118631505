/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

// import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { userGroupsSelector } from 'app/redux/app';
// import { selectedPathSelector } from 'app/redux/hierarchy';
import { genericAjaxPostSaga } from 'app/redux/util-sagas/generic-ajax-sagas';
import { call, select, takeLatest } from 'redux-saga/effects';
import {
  fetchNetworkDetailsComplete,
  fetchNetworkDetailsError,
  fetchNetworkSummaryComplete,
  fetchNetworkSummaryError,
  fetchThroughputComplete,
  fetchThroughputError,
  networkDetailsSelector,
  networkSummarySelector,
  throughputSelector,
  updateNetworkDetailsFetchState,
  updateNetworkSummaryFetchState,
  updateThroughputFetchState
} from './';
import { userGroupList } from 'app/constants';

const detailsRequestUrls = [
  // '/api/network/getSPBandwidthTrend',
  // '/api/session/getSPSessionsAndClientsTrend',
  // '/api/network/getSPThroughputTrend'
  '/api/network/getSPSessionBandwidthTrend'
];

const throughputRequestUrls = ['/api/device/getDeviceBandwidthTrend'];

export function* fetchDetailsSaga() {
  const sagaOptions = {
    completeAction: fetchNetworkDetailsComplete,
    errorAction: fetchNetworkDetailsError,
    currentDataSelector: networkDetailsSelector,
    updateFetchState: updateNetworkDetailsFetchState
  };
  yield call(genericAjaxPostSaga, detailsRequestUrls, sagaOptions);
}

export function* fetchSummarySaga() {
  const sagaOptions = {
    completeAction: fetchNetworkSummaryComplete,
    errorAction: fetchNetworkSummaryError,
    currentDataSelector: networkSummarySelector,
    updateFetchState: updateNetworkSummaryFetchState
  };

  //Moving summaryRequestUrls Inside the Saga & Initialization with let to ensures a fresh list is created every time the saga is invoked based on matched conditions.

  let summaryRequestUrls = [
    // '/api/network/getSPUsage',
    // '/api/session/getSPSessionDuration',
    // '/api/network/getSPThroughput',
    // '/api/session/getSPClientSessionDetails'
    '/api/session/getSPSessionCount',
    '/api/network/getSPUsageByBandwidth'
  ];

  const userGroups = yield select(userGroupsSelector);
  const isUserLAC = userGroupList.some(item => userGroups.includes(item));

  // const selected = yield select(selectedPathSelector);
  // const isPropertyLevel = yield isSpecificNodeType(selected, 'zonename');

  if (isUserLAC) {
    summaryRequestUrls = [
      '/api/network/getSPThroughput',
      '/api/fastapi/getBandwidthTrend',
      '/api/fastapi/getActiveClientCount',
      '/api/fastapi/getUniqueClientsTrend'
    ];
  } else {
    yield summaryRequestUrls;
  }

  yield call(genericAjaxPostSaga, summaryRequestUrls, sagaOptions);
}

export function* fetchThroughputSaga(actions) {
  const property = actions.payload || {};
  const sagaOptions = {
    completeAction: fetchThroughputComplete,
    errorAction: fetchThroughputError,
    currentDataSelector: throughputSelector,
    updateFetchState: updateThroughputFetchState
  };

  const { complexApiBody } = property;

  yield call(genericAjaxPostSaga, throughputRequestUrls, sagaOptions, {
    customBody: complexApiBody
  });
}

export default function createFetchNetworkStatsSagas(types) {
  return [
    takeLatest(types.fetchDetails, fetchDetailsSaga),
    takeLatest(types.fetchSummary, fetchSummarySaga),
    takeLatest(types.fetchThroughput, fetchThroughputSaga)
  ];
}
