/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';
import { CommScopeLogo } from './components';

export const LogoContainer = styled.div.attrs({
  className: 'text-center'
})`
  width: ${styleLibrary.sidebarWidth};
`;

const Logo = ({ style, dark, ...restProps }) => (
  <LogoContainer {...restProps}>
    <CommScopeLogo dark={dark} style={style} />
  </LogoContainer>
);

Logo.propTypes = {
  dark: PropTypes.bool,
  style: PropTypes.object
};

export default Logo;
