/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import classNames from 'classnames';
import styled from 'styled-components';

export { ChartTitleBar } from './chart-title-bar';
export { WindowWidthWrapper } from './window-width-wrapper';

export const PieBarChartWrapper = styled.div.attrs(props => ({
  className: classNames('d-flex', props.classes)
}))`
  &.display-in-rows {
    margin-top: -50px;
  }
`;
PieBarChartWrapper.displayName = 'PieBarChartWrapper';
