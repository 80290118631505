import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardBody, CardText, CardTitle } from 'app/components/elements';

export class CardSummary extends Component {
  render() {
    const { name, color, count } = this.props;

    return (
      <Card color={color}>
        <CardBody>
          <CardTitle>{count}</CardTitle>
          <CardText>{name}</CardText>
        </CardBody>
      </Card>
    );
  }
}
CardSummary.defaultProps = { name: '', color: '#6c757d', count: 0 };
CardSummary.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  count: PropTypes.number
};
