/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
// Import any third-party CSS files here - this ensure CSS is ordered in
// such a way that we can override
// anything inside our app without having to use `!important` everywhere
import 'react-bootstrap-typeahead/css/Typeahead.css';

import AppErrorBoundary from './app-error-boundary';
import GlobalStyles from 'app/global-styles';
import { initApp, isSignedInSelector } from 'app/redux/app';
import Router from './routes';

const StyledApp = styled.div`
  min-height: 100vh;

  .ie & {
    height: 100vh;
  }
`;

export class App extends Component {
  componentDidMount() {
    this.props.initApp();
  }
  render() {
    const { isSignedIn } = this.props;
    const isIE = !!document.documentMode;
    const appClasses = classNames('main-body', {
      'no-background': !isSignedIn,
      ie: isIE
    });

    return (
      <AppErrorBoundary>
        <StyledApp className={appClasses} data-test-label="app-root">
          <GlobalStyles />
          <Router />
        </StyledApp>
      </AppErrorBoundary>
    );
  }
}
App.propTypes = {
  initApp: PropTypes.func.isRequired,
  isSignedIn: PropTypes.bool
};

const mapStateToProps = createSelector(
  isSignedInSelector,
  isSignedIn => ({ isSignedIn })
);

export default connect(
  mapStateToProps,
  { initApp }
)(App);
