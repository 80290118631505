/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { getVerticalParent } from 'app/utils';
import { showUserTabForVerticals } from 'app/modules/properties/constants';
import { ChartLabel, ChartLegend } from 'app/components/charts';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import {
  MuiTableOptions,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import { createColourFactory } from 'app/utils';
import { FaIcon } from 'app/components/icons';
import { selectedPathSelector, hierarchySelector } from 'app/redux/hierarchy';
import { ReportFilterWrapper } from 'app/modules/reports/utils';
import { fetchStatePropTypes } from 'app/redux/utils';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { FailedFetchStateHandler } from 'app/components/utility';
import { StatefulTable, TableExport } from 'app/components/tables';
import { includes, isArray, isEmpty, isEqual, toLower } from 'lodash';
import { typeDropdown, mduTypeDropdown } from 'app/constants';
import {
  clientReportColumnNames,
  createClientsTableExportDataDefinition
} from '../../utils';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  clientListFetchStateSelector,
  clientListDataSelector,
  fetchClientList
} from 'app/redux/clients';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Cell, LabelList, Pie, PieChart, Tooltip } from 'recharts';

const getFillColour = createColourFactory(40);

export class PropertyClientList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'all',
      mduType: 'all',
      filteredData: this.processDeviceList(props.data),
      searchValue: '',
      toggled: []
    };
  }

  componentDidMount = () => {
    this.props.fetchClientList();
  };

  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilterLabel,
      fetchClientList,
      data,
      hierarchy
    } = this.props;
    const {
      selectedPath: prevselectedPath,
      dateRangeFilterLabel: prevDateRange,
      data: prevData
    } = prevProps;

    let propertyChanged = false;
    // Ensure selectedPath and prevselectedPath are defined before accessing properties
    if (selectedPath && prevselectedPath) {
      propertyChanged = !isEqual(prevselectedPath.id, selectedPath.id);
    }
    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilterLabel);
    const nodeVertical = getVerticalParent(
      hierarchy,
      hierarchy[selectedPath.id]
    );
    const isMDUVert = showUserTabForVerticals.includes(nodeVertical.name);

    if (isMDUVert && (propertyChanged || dateRangeChanged)) {
      fetchClientList();
    }

    if (data !== prevData) {
      const deviceList = this.processDeviceList(data);
      this.setState({ filteredData: deviceList, type: 'all' }, () => {
        this.filterData();
      });
    }
  };

  processDeviceList = data => {
    let deviceList = [];
    if (data && data.data && Array.isArray(data.data)) {
      data.data.forEach(realm => {
        if (realm.deviceList && Array.isArray(realm.deviceList)) {
          const devicesWithZoneName = realm.deviceList.map(device => ({
            ...device,
            zonename: realm.zonename,
            mduType: realm.mduType
          }));
          deviceList = deviceList.concat(devicesWithZoneName);
        }
      });
    }
    return deviceList;
  };

  handleFieldChange = field => e =>
    this.setState({ [field]: e.target.value }, () => {
      this.filterData();
    });

  handleMduTypeChange = field => e => {
    const selectedValue = e.target.value;
    this.setState({ [field]: e.target.value, mduType: selectedValue }, () => {
      this.filterData();
      this.props.fetchClientList({ mduType: selectedValue });
    });
  };

  filterData = () => {
    const { type, mduType } = this.state;
    const { data } = this.props;
    let filteredData = this.processDeviceList(data);

    if (mduType !== 'all') {
      filteredData = filteredData.filter(item => item.mduType === mduType);
    }

    if (type !== 'all') {
      filteredData = filteredData.filter(item => {
        if (type === 'wired') {
          return item.connectionType.toLowerCase() === 'wired';
        } else if (type === 'wireless') {
          return item.connectionType.toLowerCase() === 'wireless';
        }
        return true;
      });
    }
    this.setState({ filteredData });
  };

  filterBySearch = filteredData => {
    const { searchValue } = this.state;

    if (isEmpty(searchValue)) {
      return filteredData;
    }

    const searchValueWithoutSpecialChars = searchValue.replace(/[.:@-]/g, '');
    const lowerSearchValue = toLower(searchValueWithoutSpecialChars);

    return filteredData.filter(row =>
      Object.values(row).some(value => {
        // Remove special characters from the value being compared
        const valueWithoutSpecialChars = String(value).replace(/[.:@-]/g, '');
        return includes(toLower(valueWithoutSpecialChars), lowerSearchValue);
      })
    );
  };

  renderActions = (filteredData, columns) => {
    return (
      <div className="d-flex justify-content-between m-2">
        <div className="d-flex justify-content-start align-items-center">
          <h3>Clients Summary</h3>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <FaIcon icon="search" classes={['mr-2']} />
          <label className="sr-only">Search...</label>
          <input
            className="p-1"
            id="property-cbrs-table-search"
            onChange={e => this.setState({ searchValue: e.target.value })}
            placeholder="Search..."
            style={{ width: 250 }}
            type="text"
            value={this.state.searchValue}
          />
          <TableExport
            hidePdfExport={false}
            exportName="Client List Report"
            exportDataDefinitions={createClientsTableExportDataDefinition(
              filteredData,
              columns
            )}
          />
        </div>
      </div>
    );
  };

  renderDropdownAtLowerLevel = () => {
    const { type } = this.state;
    return (
      <div className="d-flex justify-content-end align-items-center my-3 report-properties">
        <ReportFilterWrapper>
          <label
            className="col-form-label-sm mr-2 ml-2"
            style={{ whiteSpace: 'pre' }}
          >
            Device Type:
          </label>
          <select
            className="form-control"
            id="type-filter"
            onChange={this.handleFieldChange('type')}
            value={type}
          >
            {typeDropdown.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </ReportFilterWrapper>
      </div>
    );
  };

  renderDropdownAtHigherLevel = () => {
    const { mduType } = this.state;
    return (
      <div className="d-flex justify-content-end align-items-center my-3 report-properties">
        <ReportFilterWrapper>
          <label
            className="col-form-label-sm pr-2"
            htmlFor="mduType-filter"
            style={{ whiteSpace: 'pre' }}
          >
            MDU Type:
          </label>
          <select
            className="form-control"
            id="mduType-filter"
            onChange={this.handleMduTypeChange('mduType')}
            value={mduType}
          >
            {mduTypeDropdown.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </ReportFilterWrapper>
        <span className="mr-4" />
        {this.renderDropdownAtLowerLevel()}
        <span className="mr-4" />
      </div>
    );
  };

  getDeviceStatusChartData = () => {
    const { filteredData } = this.state;
    const activeCount = filteredData.filter(
      item => item.status.toLowerCase() === 'active'
    ).length;
    const inactiveCount = filteredData.filter(
      item => item.status.toLowerCase() === 'inactive'
    ).length;

    const totalCount = activeCount + inactiveCount;

    return [
      {
        name: 'Active',
        value: activeCount,
        count: activeCount,
        percentage: ((activeCount / totalCount) * 100).toFixed(2)
      },
      {
        name: 'Inactive',
        value: inactiveCount,
        count: inactiveCount,
        percentage: ((inactiveCount / totalCount) * 100).toFixed(2)
      }
    ];
  };

  handleTypeChange = e => {
    this.setState({ type: e.target.value });
  };

  getFillColour = name => {
    const COLORS = ['#003F5C', '#66b1e8', '#FAA43A', '#4682B4', '#FF6F61'];

    const chartData = Object.keys(this.props.data.info).map((key, index) => ({
      name: key,
      value: this.props.data.info[key],
      color: COLORS[index % COLORS.length]
    }));
    const item = chartData.find(item => item.name === name);
    return item ? item.color : '#8884d8';
  };

  handleChangeToggled = label => {
    this.setState(prevState => {
      const { toggled } = prevState;
      if (toggled.includes(label)) {
        return { toggled: toggled.filter(item => item !== label) };
      } else {
        return { toggled: [...toggled, label] };
      }
    });
  };

  renderDeviceStatusChart = () => {
    const activeInactiveData = this.getDeviceStatusChartData();
    const { toggled } = this.state;

    // Filter out the toggled items
    const filteredData = activeInactiveData.filter(
      item => !toggled.includes(item.name)
    );

    return (
      <div className="d-flex justify-content-center">
        <PieChart width={350} height={350}>
          <Pie
            data={filteredData}
            cx="50%"
            cy="50%"
            innerRadius="50%"
            outerRadius="90%"
            labelLine={false}
            fill="#8884d8"
            dataKey="value"
          >
            <LabelList content={<ChartLabel />} dataKey="value" />
            {filteredData.map(({ name }) => (
              <Cell key={name} fill={getFillColour(name)} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
        <ChartLegend
          data={activeInactiveData}
          getFillColour={getFillColour}
          onToggle={this.handleChangeToggled}
          textProperty="name"
          toggled={toggled}
          countProperty="count"
          percentProperty="percentage"
          titleLabel="Status"
          textWidth={60}
        />
      </div>
    );
  };

  renderMDUTypeChart = () => {
    const { data } = this.props;
    const { toggled } = this.state;

    const totalCount = Object.values(data.info).reduce(
      (sum, value) => sum + value,
      0
    );

    const chartData = Object.keys(data.info).map(key => ({
      name: key,
      value: data.info[key],
      count: data.info[key],
      percentage: ((data.info[key] / totalCount) * 100).toFixed(2)
    }));

    // Filter out the toggled items
    const filteredData = chartData.filter(item => !toggled.includes(item.name));

    return (
      <div className="d-flex justify-content-center">
        <PieChart width={350} height={350}>
          <Pie
            data={filteredData}
            cx="50%"
            cy="50%"
            innerRadius="50%"
            outerRadius="90%"
            labelLine={false}
            fill="#8884d8"
            dataKey="value"
          >
            <LabelList content={<ChartLabel />} dataKey="value" />
            {filteredData.map(({ name }) => (
              <Cell key={name} fill={this.getFillColour(name)} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
        <ChartLegend
          data={chartData}
          getFillColour={this.getFillColour}
          onToggle={this.handleChangeToggled}
          textProperty="name"
          toggled={toggled}
          countProperty="count"
          percentProperty="percentage"
          titleLabel="Device Type"
          textWidth={90}
        />
      </div>
    );
  };

  renderTrendGraphs = () => {
    return (
      <div className="d-flex justify-content-around align-items-center my-3 report-properties">
        {this.renderDeviceStatusChart()}
        {this.renderMDUTypeChart()}
      </div>
    );
  };

  render = () => {
    const { fetchState, fetchClientList, selectedPath } = this.props;
    const { filteredData } = this.state;

    const { pending, complete, failed } = fetchState;
    const hasData = isArray(filteredData) && !isEmpty(filteredData);
    const noDataToShow = !pending && complete && !hasData;
    const isCustomerLevel = isSpecificNodeType(selectedPath, 'customer');
    const columns = clientReportColumnNames();
    if (isCustomerLevel) {
      columns.unshift({ name: 'zonename', label: 'Property' });
    }

    return (
      <Fragment>
        <Fragment>
          {isCustomerLevel
            ? this.renderDropdownAtHigherLevel()
            : this.renderDropdownAtLowerLevel()}
        </Fragment>
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() => fetchClientList()}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message={
                failed
                  ? 'There has been a problem fetching client summary'
                  : 'No data available for the client summary'
              }
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            <Fragment>
              {!pending && hasData && (
                <>
                  {this.renderTrendGraphs()}
                  <hr />
                  {this.renderActions(filteredData, clientReportColumnNames)}
                </>
              )}
              <div
                className={pending || noDataToShow ? 'fetch-state-pending' : ''}
              >
                {complete && (
                  <MuiThemeProvider
                    theme={createMuiTheme(customMuiTableThemeWithDefualtCursor)}
                  >
                    <StatefulTable
                      tableKey={'property-client-list'}
                      columns={columns}
                      data={this.filterBySearch(filteredData)}
                      options={MuiTableOptions}
                    />
                  </MuiThemeProvider>
                )}
              </div>
            </Fragment>
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

PropertyClientList.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  data: PropTypes.object,
  selectedPath: PropTypes.object,
  hierarchy: PropTypes.object,
  fetchState: fetchStatePropTypes,
  fetchClientList: PropTypes.func.isRequired
};

const mapStateToProps = createSelector(
  hierarchySelector,
  selectedPathSelector,
  dateRangeFilterLabelSelector,
  clientListFetchStateSelector,
  clientListDataSelector,
  (hierarchy, selectedPath, dateRangeFilterLabel, fetchState, data) => ({
    hierarchy,
    selectedPath,
    dateRangeFilterLabel,
    fetchState,
    data
  })
);

export default connect(mapStateToProps, { fetchClientList })(
  PropertyClientList
);
