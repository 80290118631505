/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { getVerticalParent } from 'app/utils';
import { showUserTabForVerticals } from 'app/modules/properties/constants';
import { ChartLabel, ChartLegend } from 'app/components/charts';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import {
  MuiTableOptions,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import { createColourFactory } from 'app/utils';
import { FaIcon } from 'app/components/icons';
import { selectedPathSelector, hierarchySelector } from 'app/redux/hierarchy';
import { ReportFilterWrapper } from 'app/modules/reports/utils';
import { fetchStatePropTypes } from 'app/redux/utils';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { FailedFetchStateHandler } from 'app/components/utility';
import { StatefulTable, TableExport } from 'app/components/tables';
import { includes, isArray, isEmpty, isEqual, toLower } from 'lodash';
import { mduTypeDropdown, lastAccessDaysDropdown } from 'app/constants';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import {
  userListFetchStateSelector,
  userListDataSelector,
  fetchUserList
} from 'app/redux/clients';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  createUsersTableExportDataDefinition,
  userReportColumnNames
} from '../../utils';
import { Cell, LabelList, Pie, PieChart, Tooltip } from 'recharts';

const getFillColour = createColourFactory(30);

export class PropertyUserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mduType: 'all',
      lastAccessDays: '30',
      filteredData: this.processUserList(props.userListData),
      searchValue: '',
      toggled: []
    };
  }

  componentDidMount = () => {
    const { fetchUserList } = this.props;
    fetchUserList();
  };

  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilterLabel,
      userListData,
      fetchUserList,
      hierarchy
    } = this.props;
    const {
      selectedPath: prevselectedPath,
      dateRangeFilterLabel: prevDateRange,
      userListData: prevData
    } = prevProps;

    let propertyChanged = false;
    // Ensure selectedPath and prevselectedPath are defined before accessing properties
    if (selectedPath && prevselectedPath) {
      propertyChanged = !isEqual(prevselectedPath.id, selectedPath.id);
    }
    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilterLabel);

    const nodeVertical = getVerticalParent(
      hierarchy,
      hierarchy[selectedPath.id]
    );
    const isMDUVert = showUserTabForVerticals.includes(nodeVertical.name);

    if (isMDUVert && (propertyChanged || dateRangeChanged)) {
      fetchUserList();
    }

    if (userListData !== prevData) {
      const userList = this.processUserList(userListData);
      this.setState({ filteredData: userList }, () => {
        this.filterData();
      });
    }
  };

  processUserList = userListData => {
    let processedList = [];
    if (userListData && userListData.data) {
      userListData.data.forEach(realm => {
        if (realm.userList && Array.isArray(realm.userList)) {
          const usersWithZoneName = realm.userList.map(user => ({
            ...user,
            zonename: realm.zonename,
            mduType: realm.mduType
          }));
          processedList = processedList.concat(usersWithZoneName);
        }
      });
    }
    return processedList;
  };

  handleFieldChange = field => e =>
    this.setState({ [field]: e.target.value }, () => {
      this.filterData();
    });

  handleMduTypeChange = field => e => {
    const { lastAccessDays } = this.state;
    const selectedValue = e.target.value;

    this.setState({ [field]: e.target.value, mduType: selectedValue }, () => {
      this.filterData();
      this.props.fetchUserList({ mduType: selectedValue, lastAccessDays });
    });
  };

  filterData = () => {
    const { lastAccessDays, mduType } = this.state;
    const { userListData } = this.props;
    let filteredData = this.processUserList(userListData);

    if (mduType !== 'all') {
      filteredData = filteredData.filter(item => item.mduType === mduType);
    }
    if (lastAccessDays !== '30') {
      const days = parseInt(lastAccessDays, 10);
      const now = new Date();
      const cutoffDate = new Date(now.setDate(now.getDate() - days));

      filteredData = filteredData.filter(item => {
        if (item.lastLoginDate) {
          const lastLoginDate = new Date(item.lastLoginDate);
          return lastLoginDate >= cutoffDate;
        }
        return false;
      });
    }
    this.setState({ filteredData });
  };

  filterBySearch = filteredData => {
    const { searchValue } = this.state;

    if (isEmpty(searchValue)) {
      return filteredData;
    }

    const searchValueWithoutSpecialChars = searchValue.replace(/[.:@-]/g, '');
    const lowerSearchValue = toLower(searchValueWithoutSpecialChars);

    return filteredData.filter(row =>
      Object.values(row).some(value => {
        // Remove special characters from the value being compared
        const valueWithoutSpecialChars = String(value).replace(/[.:@-]/g, '');
        return includes(toLower(valueWithoutSpecialChars), lowerSearchValue);
      })
    );
  };

  renderActions = (filteredData, columns) => {
    return (
      <div className="d-flex justify-content-between m-2">
        <div className="d-flex justify-content-start align-items-center">
          <h3>Users Summary</h3>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <FaIcon icon="search" classes={['mr-2']} />
          <label className="sr-only">Search...</label>
          <input
            className="p-1"
            id="property-cbrs-table-search"
            onChange={e => this.setState({ searchValue: e.target.value })}
            placeholder="Search..."
            style={{ width: 250 }}
            status="text"
            value={this.state.searchValue}
          />
          <TableExport
            hidePdfExport={false}
            exportName="Property Active Users"
            exportDataDefinitions={createUsersTableExportDataDefinition(
              filteredData,
              columns
            )}
          />
        </div>
      </div>
    );
  };

  renderDropdownAtLowerLevel = () => {
    const { lastAccessDays } = this.state;
    return (
      <div className="d-flex justify-content-end align-items-center my-3 report-properties">
        <ReportFilterWrapper>
          <label
            className="col-form-label-sm mr-2 ml-2"
            style={{ whiteSpace: 'pre' }}
          >
            Last Acccess Days:
          </label>
          <select
            className="form-control"
            id="lastaccess-filter"
            onChange={this.handleFieldChange('lastAccessDays')}
            value={lastAccessDays}
          >
            {lastAccessDaysDropdown.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </ReportFilterWrapper>
      </div>
    );
  };

  renderDropdownAtHigherLevel = () => {
    const { mduType } = this.state;
    return (
      <div className="d-flex justify-content-end align-items-center my-3 report-properties">
        <ReportFilterWrapper>
          <label
            className="col-form-label-sm pr-2"
            htmlFor="mduType-filter"
            style={{ whiteSpace: 'pre' }}
          >
            MDU Type:
          </label>
          <select
            className="form-control"
            id="mduType-filter"
            onChange={this.handleMduTypeChange('mduType')}
            value={mduType}
          >
            {mduTypeDropdown.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </ReportFilterWrapper>
        <span className="mr-4" />
        {this.renderDropdownAtLowerLevel()}
        <span className="mr-4" />
      </div>
    );
  };

  handleChangeToggled = label => {
    this.setState(prevState => {
      const { toggled } = prevState;
      if (toggled.includes(label)) {
        return { toggled: toggled.filter(item => item !== label) };
      } else {
        return { toggled: [...toggled, label] };
      }
    });
  };

  getDeviceStatusChartData = () => {
    const { filteredData } = this.state;
    const activeCount = filteredData.filter(
      item => item.status.toLowerCase() === 'active'
    ).length;
    const inactiveCount = filteredData.filter(
      item => item.status.toLowerCase() === 'inactive'
    ).length;

    const totalCount = activeCount + inactiveCount;

    return [
      {
        name: 'Active',
        value: activeCount,
        count: activeCount,
        percentage: ((activeCount / totalCount) * 100).toFixed(2)
      },
      {
        name: 'Inactive',
        value: inactiveCount,
        count: inactiveCount,
        percentage: ((inactiveCount / totalCount) * 100).toFixed(2)
      }
    ];
  };

  renderDeviceStatusChart = () => {
    const activeInactiveData = this.getDeviceStatusChartData();
    const { toggled } = this.state;

    // Filter out the toggled items
    const filteredData = activeInactiveData.filter(
      item => !toggled.includes(item.name)
    );

    return (
      <div className="d-flex justify-content-center">
        <PieChart width={350} height={350}>
          <Pie
            data={filteredData}
            cx="50%"
            cy="50%"
            innerRadius="50%"
            outerRadius="90%"
            labelLine={false}
            fill="#8884d8"
            dataKey="value"
          >
            <LabelList content={<ChartLabel />} dataKey="value" />
            {filteredData.map(({ name }) => (
              <Cell key={name} fill={getFillColour(name)} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
        <ChartLegend
          data={activeInactiveData}
          getFillColour={getFillColour}
          onToggle={this.handleChangeToggled}
          textProperty="name"
          toggled={toggled}
          countProperty="count"
          percentProperty="percentage"
          titleLabel="Status"
          textWidth={60}
        />
      </div>
    );
  };

  render = () => {
    const { fetchState, fetchUserList, selectedPath } = this.props;
    let { filteredData } = this.state;

    const { pending, complete, failed } = fetchState;
    const hasData = isArray(filteredData) && !isEmpty(filteredData);
    const noDataToShow = !pending && complete && !hasData;
    const isCustomerLevel = isSpecificNodeType(selectedPath, 'customer');
    const columns = userReportColumnNames();
    if (isCustomerLevel) {
      columns.unshift({ name: 'zonename', label: 'Property' });
    }

    return (
      <Fragment>
        <Fragment>
          {isCustomerLevel
            ? this.renderDropdownAtHigherLevel()
            : this.renderDropdownAtLowerLevel()}
        </Fragment>

        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() => fetchUserList()}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message={
                failed
                  ? 'There has been a problem fetching users summary'
                  : 'No data available for the users summary'
              }
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            <Fragment>
              {!pending && hasData && (
                <>
                  {this.renderDeviceStatusChart()}
                  <hr />
                  {this.renderActions(filteredData, userReportColumnNames)}
                </>
              )}
              <div
                className={pending || noDataToShow ? 'fetch-state-pending' : ''}
              >
                {complete && (
                  <MuiThemeProvider
                    theme={createMuiTheme(customMuiTableThemeWithDefualtCursor)}
                  >
                    <StatefulTable
                      tableKey="property-user-list"
                      columns={columns}
                      data={this.filterBySearch(filteredData)}
                      options={MuiTableOptions}
                    />
                  </MuiThemeProvider>
                )}
              </div>
            </Fragment>
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

PropertyUserList.propTypes = {
  userListData: PropTypes.object,
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  hierarchy: PropTypes.object,
  fetchState: fetchStatePropTypes,
  fetchUserList: PropTypes.func.isRequired
};

const mapStateToProps = createSelector(
  hierarchySelector,
  selectedPathSelector,
  dateRangeFilterLabelSelector,
  userListFetchStateSelector,
  userListDataSelector,
  (
    hierarchy,
    selectedPath,
    dateRangeFilterLabel,
    fetchState,
    userListData
  ) => ({
    hierarchy,
    selectedPath,
    dateRangeFilterLabel,
    fetchState,
    userListData
  })
);

const mapDispatchToProps = { fetchUserList };

export default connect(mapStateToProps, mapDispatchToProps)(PropertyUserList);
