/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { HR } from 'app/components/elements';
import { createShowPanelSelector } from 'app/redux/app';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import RogueDetailsTable from './rogue-details-table';
import RogueMACCountTrendChart from './rogue-mac-count-trend-chart';
import RogueApDetailsTable from './rogue-ap-details-table';
import styled from 'styled-components';
import { styleLibrary } from 'app/constants';
import { Tabs } from 'app/components/elements';

const TabsContainer = styled.div.attrs({
  className: 'px-2 pb-4'
})`
  & .tab-wrapper {
    border: 1px solid ${styleLibrary.containerBorderColor};
    border-top: none;
    padding-top: 0.1rem;
  }
`;

const defaultTabs = [
  { id: 'rogue-summary', label: 'Rogue Summary' },
  { id: 'ap-rogue-summary', label: 'AP Rogue Summary' }
];
export class PropertyRogue extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: defaultTabs,
      selectedTab: defaultTabs[0].id
    };
  }

  handleTabChange = tabIndex => {
    this.setState({ selectedTab: tabIndex });
  };

  renderSelectedTabContainer = () => {
    const { selectedTab } = this.state;
    const { showRogueDetailsTable, showRogueApdetailsTable } = this.props;

    switch (selectedTab) {
      case 'rogue-summary':
        return showRogueDetailsTable && <RogueDetailsTable />;
      case 'ap-rogue-summary':
        return showRogueApdetailsTable && <RogueApDetailsTable />;
      default:
        return '';
    }
  };

  render = () => {
    const {
      showRogueMACCountTrend,
      showRogueApdetailsTable,
      showApRogueSummaryTab,
      showRogueChildTab
    } = this.props;
    const { selectedTab } = this.state;

    const tabs = defaultTabs.filter(tab => {
      if (tab.id === 'ap-rogue-summary') {
        return showApRogueSummaryTab && showRogueApdetailsTable;
      }
      return true;
    });

    const selectedDefaultTab = showApRogueSummaryTab
      ? 'ap-rogue-summary'
      : 'rogue-summary';

    if (!tabs.some(tab => tab.id === selectedTab)) {
      this.setState({ selectedTab: selectedDefaultTab });
    }

    return (
      <Fragment>
        {showRogueMACCountTrend && <RogueMACCountTrendChart />}
        {showRogueMACCountTrend && showRogueChildTab && <HR />}
        {showRogueChildTab && (
          <div className="row">
            <div className="col">
              <Tabs
                selected={selectedTab}
                onTabChanged={this.handleTabChange}
                tabs={tabs}
              />
              <TabsContainer>
                <div className="tab-wrapper">
                  {this.renderSelectedTabContainer()}
                </div>
              </TabsContainer>
            </div>
          </div>
        )}
      </Fragment>
    );
  };
}

PropertyRogue.propTypes = {
  showRogueMACCountTrend: PropTypes.bool,
  showRogueDetailsTable: PropTypes.bool,
  showRogueApdetailsTable: PropTypes.bool,
  showApRogueSummaryTab: PropTypes.bool,
  showRogueChildTab: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('PropertyRogueMACCountTrend'),
  createShowPanelSelector('PropertyRogueDetailsTable'),
  createShowPanelSelector('PropertyRogueApDetailsTable'),
  createShowPanelSelector('PropertyRogueApSummaryTab'),
  createShowPanelSelector('PropertyRogueChildTab'),
  (
    showRogueMACCountTrend,
    showRogueDetailsTable,
    showRogueApdetailsTable,
    showApRogueSummaryTab,
    showRogueChildTab
  ) => ({
    showRogueMACCountTrend,
    showRogueDetailsTable,
    showRogueApdetailsTable,
    showApRogueSummaryTab,
    showRogueChildTab
  })
);

export default connect(mapStateToProps)(PropertyRogue);
