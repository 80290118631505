/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { granularityDropdownItems } from 'app/components/filters/constants';
import { getGranularityListForDateRange } from 'app/utils';
import PropTypes from 'prop-types';
import React from 'react';

const GranularityFilter = ({
  granularity,
  granularityList,
  handleGranularityChanged,
  showLabel,
  customDateRange
}) => {
  if (customDateRange) {
    granularityList = getGranularityListForDateRange(customDateRange);
  }

  return (
    <div className="d-flex">
      {showLabel && (
        <label className="col-form-label-sm pr-2 mb-0">Granularity:</label>
      )}
      <select
        className="form-control"
        onChange={e => handleGranularityChanged(e.target.value)}
        value={granularity}
      >
        {granularityList.map(({ value, text }) => (
          <option key={`granularity-${value}`} value={value}>
            {text}
          </option>
        ))}
      </select>
    </div>
  );
};

GranularityFilter.defaultProps = {
  granularity: granularityDropdownItems._15MinutesGranularity.value,
  granularityList: [
    granularityDropdownItems._15MinutesGranularity,
    granularityDropdownItems._1HourGranularity
  ],
  showLabel: true
};

GranularityFilter.propTypes = {
  granularity: PropTypes.string,
  granularityList: PropTypes.array,
  handleGranularityChanged: PropTypes.func,
  showLabel: PropTypes.bool,
  customDateRange: PropTypes.object
};

export default GranularityFilter;
