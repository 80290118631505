/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { StatefulTable, TableExport } from 'app/components/tables';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import {
  MuiTableOptions,
  customMuiTableThemeWithDefualtCursor
} from 'app/constants';
import { cloneDeep, includes, isArray, isEmpty, merge, toLower } from 'lodash';
import { fetchStatePropTypes } from 'app/redux/utils';
import { FaIcon } from 'app/components/icons';
import { NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  aleSessionsSummaryFetchStateSelector,
  aleSessionsSummarySelector,
  fetchAleSessionsSummary
} from 'app/redux/clients';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

const columnsNames = () => [
  { name: 'aaausername', label: 'User Name' },
  { name: 'adapterip', label: 'ALE IP' },
  { name: 'device', label: 'Device' },
  { name: 'gwnasipaddr', label: 'GWNAS IP' },
  { name: 'nasportid', label: 'NAS Port ID' },
  { name: 'privatevlan', label: 'Private VLAN' },
  {
    name: 'sessionip',
    label: 'Session IP',
    options: {}
  },
  {
    name: 'sessionstarttime',
    label: 'Session Start Time',
    options: {}
  }
];

export class AleSessionDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: ''
    };
  }

  componentDidMount = () => {
    this.props.fetchAleSessionsSummary();
  };

  filterBySearch = data => {
    const { searchValue } = this.state;

    if (isEmpty(searchValue)) {
      return data;
    }

    const searchValueWithoutSpecialChars = searchValue.replace(/[.:@-]/g, '');
    const lowerSearchValue = toLower(searchValueWithoutSpecialChars);

    return data.filter(row =>
      Object.values(row).some(value => {
        // Remove special characters from the value being compared
        const valueWithoutSpecialChars = String(value).replace(/[.:@-]/g, '');
        return includes(toLower(valueWithoutSpecialChars), lowerSearchValue);
      })
    );
  };

  renderActions = (data, columns) => {
    return (
      <div className="d-flex justify-content-between m-2">
        <div className="d-flex justify-content-start align-items-center">
          <h3>Sessions Summary</h3>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          <FaIcon icon="search" classes={['mr-2']} />
          <label className="sr-only">Search...</label>
          <input
            className="p-1"
            id="property-cbrs-table-search"
            onChange={e => this.setState({ searchValue: e.target.value })}
            placeholder="Search..."
            style={{ width: 250 }}
            type="text"
            value={this.state.searchValue}
          />
          <TableExport
            hidePdfExport={false}
            exportName="Property Sessions Summary"
            exportDataDefinitions={this.createSessionsSummaryExxportDataDefinition(
              data,
              columns
            )}
          />
        </div>
      </div>
    );
  };

  createSessionsSummaryExxportDataDefinition = data => {
    const exportDataDefinitions = [];
    const columns = columnsNames();

    let transformedData = cloneDeep(data);
    transformedData = transformedData.map(row => ({
      ...row,
      nasportid: `'${row.nasportid}'`
    }));
    transformedData = transformedData.map(Object.values);

    exportDataDefinitions.push({
      name: 'ALE Sessions Summary',
      data: transformedData,
      columns
    });

    return exportDataDefinitions;
  };

  render = () => {
    const {
      fetchState,
      aleSessionsSummary,
      fetchAleSessionsSummary
    } = this.props;
    let data = aleSessionsSummary.data;

    const { pending, complete, failed } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    const columns = columnsNames();
    const customOptions = merge({}, MuiTableOptions, {
      responsive: 'stacked',
      fixedHeaderOptions: { yAxis: true }
    });

    return (
      <Fragment>
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() => fetchAleSessionsSummary()}
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message={
                failed
                  ? 'There has been a problem fetching ale sessions'
                  : 'No data available for the ale sessions'
              }
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            <>
              {!pending && hasData && this.renderActions(data, columns)}
              <div
                className={pending || noDataToShow ? 'fetch-state-pending' : ''}
              >
                {complete && (
                  <MuiThemeProvider
                    theme={createMuiTheme(customMuiTableThemeWithDefualtCursor)}
                  >
                    <StatefulTable
                      tableKey={'ale-session'}
                      columns={columns}
                      data={this.filterBySearch(data)}
                      options={customOptions}
                    />
                  </MuiThemeProvider>
                )}
              </div>
            </>
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

AleSessionDetails.propTypes = {
  aleSessionsSummary: PropTypes.object,
  fetchState: fetchStatePropTypes,
  fetchAleSessionsSummary: PropTypes.func
};

const mapStateToProps = createSelector(
  aleSessionsSummaryFetchStateSelector,
  aleSessionsSummarySelector,
  (fetchState, aleSessionsSummary) => ({
    fetchState,
    aleSessionsSummary
  })
);
const mapDispatchToProps = {
  fetchAleSessionsSummary
};

export default connect(mapStateToProps, mapDispatchToProps)(AleSessionDetails);
