/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { HR } from 'app/components/elements';
import { createShowPanelSelector } from 'app/redux/app';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import PropertyClientList from './client-list-report';
import PropertyUserList from './user-list-report';

export const PropertyUserClientReport = ({
  showResidentClientList,
  showResidentUserList
}) => (
  <Fragment>
    {showResidentUserList && <PropertyUserList />}
    {showResidentUserList && <HR />}
    {showResidentClientList && <PropertyClientList />}
  </Fragment>
);

PropertyUserClientReport.propTypes = {
  showResidentClientList: PropTypes.bool,
  showResidentUserList: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('PropertyClientList'),
  createShowPanelSelector('PropertyUserList'),
  (showResidentClientList, showResidentUserList) => ({
    showResidentClientList,
    showResidentUserList
  })
);
export default connect(mapStateToProps)(PropertyUserClientReport);
