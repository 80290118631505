/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { FaIcon } from 'app/components/icons';
import { AboutModal, LoadingModal } from 'app/components/modals';
import { styleLibrary } from 'app/constants';
import { userGroupsSelector } from 'app/redux/app';
import {
  auditInfoDownloadStatusSelector,
  closeModal,
  createIsModalOpenSelector,
  downloadAuditInfo,
  downloadUserGuide,
  openModal,
  setAuditInfoDownloadStatus,
  updateUserGuideDownloadStatus,
  userGuideDownloadStatusSelector
} from 'app/redux/ui';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';

const StyledUserMenu = styled.button.attrs({
  className: 'd-flex align-items-center btn px-0 mr-3'
})`
  background-color: transparent;
  color: ${styleLibrary.lightText};
  font-weight: normal !important;

  &:hover {
    color: ${styleLibrary.lightText};
  }

  & i.fa-user {
    font-size: ${styleLibrary.fontSizes.navIcon}px;
  }
`;
StyledUserMenu.displayName = 'StyledUserMenu';

const UsernameField = styled.div`
  font-size: ${styleLibrary.fontSizes.body}px;
`;

const DropDownWrapper = styled.div.attrs(({ isUserMenuOpen }) => ({
  className: classNames(
    'dropdown-menu dropdown-menu-right mr-3 z-index-level-2',
    isUserMenuOpen && 'show'
  )
}))`
  position: absolute;
  top: ${styleLibrary.headerHeight - 8}px;
`;
DropDownWrapper.displayName = 'DropDownWrapper';

const aboutModalKey = 'user-menu-about-modal';
const auditInfoModalKey = 'audit-info-error-modal';
const userGuideModalKey = 'user-guide-modal';

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { isUserMenuOpen: false };
    this.dropDownRef = React.createRef();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { isUserMenuOpen: prevIsUserMenuOpen } = prevState;
    const { isUserMenuOpen } = this.state;
    if (!prevIsUserMenuOpen && isUserMenuOpen) {
      document.addEventListener('click', this.handleDocumentClick);
    }
    if (prevIsUserMenuOpen && !isUserMenuOpen) {
      document.removeEventListener('click', this.handleDocumentClick);
    }
  };

  componentWillUnmount = () =>
    document.removeEventListener('click', this.handleDocumentClick);

  closeErrorModal = modalKey => {
    this.props.closeModal(modalKey);
    if (modalKey === auditInfoModalKey) {
      this.props.setAuditInfoDownloadStatus({
        inProgress: false,
        failed: false
      });
    } else if (modalKey === userGuideModalKey) {
      this.props.updateUserGuideDownloadStatus({
        inProgress: false,
        failed: false
      });
    }
  };

  handleAboutClick = e => {
    e.preventDefault();
    this.toggleUserMenu();
    this.props.openModal(aboutModalKey);
  };

  handleAuditInfoClick = e => {
    e.preventDefault();
    this.toggleUserMenu();
    this.props.downloadAuditInfo();
    this.props.openModal(auditInfoModalKey);
  };

  handleUserGuideClick = e => {
    e.preventDefault();
    this.toggleUserMenu();
    this.props.downloadUserGuide();
    this.props.openModal(userGuideModalKey);
  };

  handleDocumentClick = e => {
    const ddRef = this.dropDownRef.current;
    if (ddRef && !ddRef.contains(e.target)) {
      return this.toggleUserMenu();
    }
    return null;
  };

  toggleUserMenu = () =>
    this.setState(prevState => ({ isUserMenuOpen: !prevState.isUserMenuOpen }));

  render = () => {
    const { isUserMenuOpen } = this.state;
    const {
      isAuditInfoModalOpen,
      auditInfoDownloadStatus: {
        inProgress: auditInfoDownloadInProgress,
        failed: auditInfoDownloadFailed
      },
      closeModal,
      isAboutModalOpen,
      logout,
      userName,
      userGroups = [],
      isUserGuideModalOpen,
      userGuideDownloadStatus: {
        inProgress: userGuideDownloadInProgress,
        failed: userGuideDownloadFailed
      }
    } = this.props;
    const isAdmin = userGroups.includes('ADMIN');

    return (
      <div>
        <StyledUserMenu
          className="dropdown"
          data-test-label="user-menu-trigger"
          id="user-menu"
          onClick={this.toggleUserMenu}
        >
          <div className="mr-2">
            <FaIcon icon="caret-down" />
          </div>
          <div>
            <FaIcon icon="user" />
            <UsernameField>{userName}</UsernameField>
          </div>
        </StyledUserMenu>
        {isUserMenuOpen && (
          <DropDownWrapper
            isUserMenuOpen={isUserMenuOpen}
            ref={this.dropDownRef}
            data-test-label="user-menu-dropdown"
          >
            {isAdmin && (
              <button
                className="dropdown-item"
                data-test-label="user-menu-audit-info"
                onClick={this.handleAuditInfoClick}
              >
                Audit Info
              </button>
            )}
            <button
              className="dropdown-item"
              data-test-label="user-menu-about"
              onClick={this.handleAboutClick}
            >
              About
            </button>
            <a
              className="dropdown-item"
              data-test-label="user-menu-support"
              href="https://www.commscope.com/support/arris-support/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Support
            </a>
            <button
              className="dropdown-item"
              data-test-label="user-menu-about"
              onClick={this.handleUserGuideClick}
            >
              User Guide
            </button>
            <hr style={{ margin: '5px 0' }} />
            <button
              id="logout-link"
              className="dropdown-item"
              data-test-label="user-menu-logout"
              onClick={() => logout()}
            >
              Log Out
            </button>
          </DropDownWrapper>
        )}
        {isAboutModalOpen && (
          <AboutModal closeModal={() => closeModal(aboutModalKey)} />
        )}
        {isAuditInfoModalOpen && (
          <LoadingModal
            closeModal={() => this.closeErrorModal(auditInfoModalKey)}
            isLoading={auditInfoDownloadInProgress}
            isError={auditInfoDownloadFailed}
            loadingMessage="Downloading Audit Info..."
            errorMessage="Sorry, but we have been unable to download the Audit Info at this time."
          />
        )}
        {isUserGuideModalOpen && (
          <LoadingModal
            closeModal={() => this.closeErrorModal(userGuideModalKey)}
            isLoading={userGuideDownloadInProgress}
            isError={userGuideDownloadFailed}
            loadingMessage="Downloading User Guide..."
            errorMessage="Sorry, but we have been unable to download the User Guide at this time."
          />
        )}
      </div>
    );
  };
}

UserMenu.propTypes = {
  auditInfoDownloadStatus: PropTypes.shape({
    inProgress: PropTypes.bool,
    failed: PropTypes.bool
  }),
  closeModal: PropTypes.func.isRequired,
  downloadAuditInfo: PropTypes.func.isRequired,
  setAuditInfoDownloadStatus: PropTypes.func,
  isAuditInfoModalOpen: PropTypes.bool,
  isAboutModalOpen: PropTypes.bool,
  logout: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  userGroups: PropTypes.array,
  downloadUserGuide: PropTypes.func,
  updateUserGuideDownloadStatus: PropTypes.func,
  userGuideDownloadStatus: PropTypes.object,
  isUserGuideModalOpen: PropTypes.bool
};

const mapStateToProps = createSelector(
  createIsModalOpenSelector(aboutModalKey),
  createIsModalOpenSelector(auditInfoModalKey),
  createIsModalOpenSelector(userGuideModalKey),
  auditInfoDownloadStatusSelector,
  userGroupsSelector,
  userGuideDownloadStatusSelector,
  (
    isAboutModalOpen,
    isAuditInfoModalOpen,
    isUserGuideModalOpen,
    auditInfoDownloadStatus,
    userGroups,
    userGuideDownloadStatus
  ) => ({
    isAboutModalOpen,
    isAuditInfoModalOpen,
    isUserGuideModalOpen,
    auditInfoDownloadStatus,
    userGroups,
    userGuideDownloadStatus
  })
);

const mapDispatchToProps = {
  openModal,
  closeModal,
  downloadAuditInfo,
  setAuditInfoDownloadStatus,
  downloadUserGuide,
  updateUserGuideDownloadStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
