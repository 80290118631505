/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';

import { styleLibrary } from 'app/constants';
import { Logo } from 'app/components/elements';
import { userNameSelector, userSignOut } from 'app/redux/app';
import { navigationPages } from 'app/routes';

import UserMenu from './components/user-menu';
import HeaderButton from './components/header-button';

const MainHeader = styled.header.attrs({
  className: 'row no-gutters align-items-center justify-content-between'
})`
  background: ${styleLibrary.gradientBg};
  color: ${styleLibrary.lightText};
  height: ${styleLibrary.headerHeight}px;
`;

const Header = ({ logout, userName }) => (
  <MainHeader data-test-label="main-header-container">
    <Logo style={{ height: 26 }} />
    <div className="d-flex align-items-center">
      {navigationPages.map(p => (
        <HeaderButton key={p.path} icon={p.icon} title={p.title} to={p.path} />
      ))}
    </div>
    <UserMenu logout={logout} userName={userName} />
  </MainHeader>
);

Header.propTypes = {
  logout: PropTypes.func,
  userName: PropTypes.string
};

const mapStateToProps = createSelector(
  userNameSelector,
  userName => ({
    userName
  })
);

export default withRouter(
  connect(
    mapStateToProps,
    { logout: () => userSignOut({ isManualLogOut: true }) }
  )(Header)
);
