/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { dateRangeNames } from 'app/components/filters/constants';
import { FaIcon } from 'app/components/icons';
import {
  CustomTableHeader,
  OnePartBar,
  TwoPartBar
} from 'app/components/tables';
import {
  MuiTableOptions,
  MuiTableTheme,
  bandwidthUnits,
  dataRateUnits,
  styleLibrary
} from 'app/constants';
import {
  HeaderBlockLabel,
  HeaderColorBlock
} from 'app/modules/overview/components/top-clients/components';
import {
  getCustomScaledValue,
  getScaledDisplayValue,
  getSortResult
} from 'app/utils';
import {
  cloneDeep,
  concat,
  every,
  findIndex,
  get,
  has,
  includes,
  isEmpty,
  isString,
  keys,
  map,
  merge,
  size,
  split,
  startsWith,
  upperFirst
} from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { DATA_RATE_API_DATE_FORMAT } from '../constants';

// const oneHour = 60;
// const dayGroupingRules = [
//   { min: 1, max: 5, gran: oneHour },
//   { min: 5, max: 10, gran: oneHour * 2 },
//   { min: 10, max: 25, gran: oneHour * 6 },
//   { min: 25, max: 31, gran: oneHour * 8 }
// ];
// const monthGroupingRules = [
//   { min: 0, max: 1, gran: oneHour * 8 },
//   { min: 1, max: 2, gran: oneHour * 16 },
//   { min: 2, max: 3, gran: oneHour * 24 },
//   { min: 3, max: 4, gran: oneHour * 36 },
//   { min: 4, max: 5, gran: oneHour * 48 },
//   { min: 5, max: 6, gran: oneHour * 60 },
//   { min: 6, max: 9, gran: oneHour * 72 },
//   { min: 9, max: 12, gran: oneHour * 96 }
// ];

export const defaultHostRegex = '.*AC.*';
export const defaultMetricRegex = '.*connected.*user.*';
const trendChartMap = [
  {
    metricRegex: '.*connected.*user.*',
    metricLabel: 'Connected Users (Clients)',
    units: [''],
    labels: [
      {
        labelRegex: '.*connected.*user.*',
        displayLabel: 'Connected Users (Clients)'
      }
    ]
  },
  {
    metricRegex: '.*Bandwidth.*',
    metricLabel: 'Transfer Rate',
    units: ['bps', ...dataRateUnits],
    labels: [
      {
        labelRegex: '.*received.*',
        displayLabel: 'Download Rate'
      },
      {
        labelRegex: '.*transmitted.*',
        displayLabel: 'Upload Rate'
      }
    ]
  },
  {
    metricRegex: '.*CPU load.*',
    metricLabel: 'CPU load',
    units: ['%']
  },
  {
    metricRegex: '.*Switch Temperature.*',
    metricLabel: 'Internal Chassis Temperature',
    units: [String.fromCharCode(8451)]
  },
  {
    metricRegex: '.*Temperature.*',
    metricLabel: 'Temperature',
    units: [String.fromCharCode(8451)]
  },
  {
    metricRegex: '.*CPU jumps.*',
    metricLabel: 'CPU jumps',
    units: ['']
  },
  {
    metricRegex: '.*CPU utilization.*',
    metricLabel: 'CPU utilization',
    units: ['%']
  },
  {
    metricRegex: '.*Disk space.*',
    metricLabel: 'Disk space',
    units: ['KB', ...bandwidthUnits]
  },
  {
    metricRegex: '.*Memory usage.*',
    metricLabel: 'Memory',
    units: ['B', 'KB', ...bandwidthUnits]
  },
  {
    metricRegex: '.*Memory.*bytes.*',
    metricLabel: 'Memory',
    units: ['B', 'KB', ...bandwidthUnits]
  },
  {
    metricRegex: '.*Memory.*percent.*',
    metricLabel: 'Memory',
    units: ['%']
  },
  {
    metricRegex: '.*Processor: Memory utilization.*',
    metricLabel: 'Processor: Memory utilization',
    units: ['%']
  },
  {
    metricRegex: '.*I/O: Memory utilization.*',
    metricLabel: 'I/O: Memory utilization',
    units: ['%']
  },
  {
    metricRegex: '.*Driver text: Memory utilization.*',
    metricLabel: 'Driver text: Memory utilization',
    units: ['%']
  },
  {
    metricRegex: '.*Memory Utilization.*',
    metricLabel: 'Memory Utilization',
    units: ['B', 'KB', ...bandwidthUnits],
    labels: [
      {
        labelRegex: '.*percent.*',
        units: ['%']
      }
    ]
  },
  {
    metricRegex: '.*Swap usage.*',
    metricLabel: 'Swap Memory',
    units: ['KB', ...bandwidthUnits]
  },
  {
    metricRegex: '.*Network traffic.*',
    metricLabel: 'Traffic',
    units: ['bps', ...dataRateUnits],
    labels: [
      {
        labelRegex: '.*incoming.*traffic.*',
        displayLabel: 'Incoming Traffic'
      },
      {
        labelRegex: '.*outgoing.*traffic.*',
        displayLabel: 'Outgoing Traffic'
      }
    ]
  },
  {
    metricRegex: '.*Traffic on interface.*',
    metricLabel: 'Traffic',
    units: ['bps', ...dataRateUnits],
    labels: [
      {
        labelRegex: '.*incoming.*traffic.*',
        displayLabel: 'Incoming Traffic'
      },
      {
        labelRegex: '.*outgoing.*traffic.*',
        displayLabel: 'Outgoing Traffic'
      }
    ]
  },
  {
    metricRegex: '.*latency.*',
    metricLabel: 'Latency',
    units: ['ms']
  },
  {
    metricRegex: '.*Discards.*Errors.*',
    metricLabel: 'Packets: Discards & Errors',
    units: [''],
    labels: [
      {
        labelRegex: '.*Inbound.*discarded.*',
        displayLabel: 'Inbound packets discarded'
      },
      {
        labelRegex: '.*Inbound.*errors.*',
        displayLabel: 'Inbound packets with errors'
      },
      {
        labelRegex: '.*Outbound.*discarded.*',
        displayLabel: 'Outbound packets discarded'
      },
      {
        labelRegex: '.*Outbound.*errors.*',
        displayLabel: 'Outbound packets with errors'
      }
    ]
  },
  {
    metricRegex: '.*Light.*Levels.*',
    metricLabel: 'Light Level',
    units: ['']
  },
  {
    metricRegex: '.*Receive.*Optical.*Level.*Status.*',
    metricLabel: 'Light Level',
    units: [''],
    labels: [
      {
        labelRegex: '.*High.*Critical.*Threshold.*',
        displayLabel: 'High Critical Threshold'
      },
      {
        labelRegex: '.*High.*Warn.*Threshold.*',
        displayLabel: 'High Warn Threshold'
      },
      {
        labelRegex: '.*Light.*Level.*',
        displayLabel: 'Light Level'
      },
      {
        labelRegex: '.*Low.*Critical.*Threshold.*',
        displayLabel: 'Low Critical Threshold'
      },
      {
        labelRegex: '.*Low.*Warn.*Threshold.*',
        displayLabel: 'Low Warn Threshold'
      }
    ]
  }
];

/**
 * This function gets the time (in minutes) by which data points should be grouped given
 * the difference between the start and end dates.
 *
 * @method getDataGranularity
 * @param {Number} diff The current difference (in milliseconds) between the start and end dates
 * @returns {Number} The number of minutes to use for grouping data points
 */
// const getDataGranularity = diff => {
//   const duration = moment.duration(diff);
//   const daysDiff = duration.days();
//   const monthsDiff = duration.months();

//   // Handle the drop down options and custom day range
//   if (daysDiff === 6) {
//     // "Last 7 days" queries have a 6 day difference :shrug:
//     return oneHour * 1.5;
//   }

//   const dayMatches = dayGroupingRules.filter(
//     x => x.min < daysDiff && x.max >= daysDiff
//   );
//   if (dayMatches.length > 0) {
//     return dayMatches[0].gran;
//   }

//   const monthMatches = monthGroupingRules.filter(
//     x => x.min < monthsDiff && x.max >= monthsDiff
//   );
//   if (monthMatches.length > 0) {
//     return monthMatches[0].gran;
//   }

//   return 1; // The shortest unit - ensuring all data points are included
// };

/**
 * This function gets the time (in minutes) by which chart labels should be grouped given
 * the difference between the start and end dates.
 *
 * @method getLabelGranularity
 * @param {String} startDate The current selected filter start date
 * @param {String} endDate The current selected filter end date
 * @returns {Number} The number of minutes to use for grouping labels
 */
// const getLabelGranularity = (startDate, endDate) => {
//   const start = moment(startDate);
//   const end = moment(endDate);
//   const duration = moment.duration(end.diff(start));
//   const daysDiff = duration.days();
//   return (daysDiff > 1 ? oneHour * 24 : oneHour) - 1; // The minus one is necessary to force the hourly/daily breaks
// };

/**
 * This actual does the parsing of the data points and applies the necessary grouping
 * based on the time difference between the start and end dates.
 *
 * @method applyGrouping
 * @param {Array} data The data to be grouped
 * @param {Number} diff The difference (in days) between the start and end dates
 * @returns {Array} An array of consolidated data points
 */
// const applyGrouping = data => {
//   const groups = [];
//   // const granularity = getDataGranularity(diff);
//   const granularity = 1;

//   if (data.length > 0) {
//     let dp = data[0];
//     // let uploadKey = has(dp, 'uploadrate');
//     const dataKeys = keys(dp).filter(y => y !== 'label');
//     let processingDate = moment(dp.label, DATA_RATE_API_DATE_FORMAT);
//     let currentGroup = [];

//     const parseCurrentGroup = () => {
//       if (currentGroup.length > 0) {
//         let d = {
//           label: currentGroup[0].label
//         };
//         dataKeys.forEach(x => {
//           d = {
//             ...d,
//             [x]: Math.max(...currentGroup.map(y => y[x]))
//           };
//         });
//         groups.push(d);
//         // if (uploadKey) {
//         //   groups.push({
//         //     downloadrate: Math.max(...currentGroup.map(x => x.downloadrate)),
//         //     uploadrate: Math.max(...currentGroup.map(x => x.uploadrate)),
//         //     label: currentGroup[0].label
//         //   });
//         // } else {
//         //   groups.push({
//         //     downloadrate: Math.max(...currentGroup.map(x => x.downloadrate)),
//         //     label: currentGroup[0].label
//         //   });
//         // }
//         currentGroup = [];
//       }
//     };

//     data.forEach(point => {
//       const pointDate = moment(point.label, DATA_RATE_API_DATE_FORMAT);

//       if (pointDate.diff(processingDate, 'minutes') > granularity) {
//         parseCurrentGroup();
//         processingDate = pointDate;
//       }

//       currentGroup.push(point);
//     });

//     // Re-parse the current group to capture all remaining, unprocessed data points
//     parseCurrentGroup();
//   }

//   return groups;
// };

/**
 * This function filters the x-axis labels to ensure they are unique on any given chart.
 * The filtering is based on the difference between the start and end dates of the data range.
 *
 * @method filterLabels
 * @param {Array} labels The full set of labels from the data set
 * @param {Number} diff The difference (in days) between the start and end dates
 * @returns {Array} An array of filtered labels to ensure values on the x-axis are unique
 */
// const filterLabels = (labels, diff) => {
//   const granularity = getLabelGranularity(diff);
//   const momentUnit = granularity > oneHour ? 'day' : 'hour';
//   const filtered = [];
//   let processingLbl = labels[0];

//   labels.forEach(lbl => {
//     const lblDate = moment(lbl, DATA_RATE_API_DATE_FORMAT).startOf(momentUnit);

//     if (
//       lblDate.diff(
//         moment(processingLbl, DATA_RATE_API_DATE_FORMAT).startOf(momentUnit),
//         'minutes'
//       ) > granularity
//     ) {
//       filtered.push(processingLbl);
//       processingLbl = lbl;
//     }
//   });

//   filtered.push(processingLbl);
//   return filtered;
// };

/**
 * This function formats the incoming data points
 *
 * @method formatDataPoints
 * @param {Array} data The full set of API data points
 * @param {String} timezone The timezone of the incoming data
 * @param {String} dataKey The name of the key which represents the timestamp
 * @param {String} dateTimeFormat A momentJS supported format
 * @returns {Array} An array of the formatted data
 */

export const formatDataPoints = (
  data = [],
  timezone = 'UTC',
  dataKey = 'label',
  dateTimeFormat = DATA_RATE_API_DATE_FORMAT
) => {
  if (!Array.isArray(data)) {
    return [];
  }
  let dataToRender = cloneDeep(data);
  dataToRender.forEach(d => {
    d[dataKey] = momentTimezone
      .tz(d[dataKey], dateTimeFormat, timezone)
      .valueOf();
  });
  return dataToRender;
};

/**
 * This function creates the x-axis ticks.
 * The creation is based on the difference between the start and end dates of the data range.
 *
 * @method createXAxisTicks
 * @param {Array} data The full set of API data points
 * @param {String} dateRange One of our supported date range names
 * @param {String} timezone The timezone of the incoming data
 * @param {String} dataKey The name of the key which represents the timestamp
 * @param {String} dateTimeFormat A momentJS supported format
 * @returns {Array} An array of X-Axis ticks to ensure values on the X-axis are unique
 */
export const createXAxisTicks = (
  data = [],
  dateRange,
  timezone = 'UTC',
  dataKey = 'label',
  dateTimeFormat = DATA_RATE_API_DATE_FORMAT
) => {
  const { label, start = undefined, end = undefined } = dateRange;
  const ticks = [];
  let momentUnit;
  let includeEndTick = false;
  // let start;
  // let end;

  switch (label) {
    case dateRangeNames.LAST_24_HOURS:
      momentUnit = 'hour';
      includeEndTick = true;
      // start = momentTimezone()
      //   .tz(timezone)
      //   .subtract(1, 'day');
      // end = momentTimezone().tz(timezone);
      break;

    case dateRangeNames.LAST_7_DAYS:
      momentUnit = 'day';
      // start = momentTimezone()
      //   .tz(timezone)
      //   .subtract(7, 'day')
      //   .startOf('day');
      // end = momentTimezone()
      //   .tz(timezone)
      //   .subtract(1, 'day')
      //   .endOf('day');
      break;

    case dateRangeNames.LAST_MONTH:
      momentUnit = 'day';
      // start = momentTimezone()
      //   .tz(timezone)
      //   .subtract(1, 'month')
      //   .startOf('month');
      // end = momentTimezone()
      //   .tz(timezone)
      //   .subtract(1, 'month')
      //   .endOf('month');
      break;

    case dateRangeNames.THIS_MONTH:
      momentUnit = 'day';
      // start = momentTimezone()
      //   .tz(timezone)
      //   .startOf('month');
      // end = momentTimezone()
      //   .tz(timezone)
      //   .endOf('day');
      break;

    default:
      if (start && end) {
        if (moment(end).diff(moment(start), 'days') === 0) {
          momentUnit = 'hour';
        } else {
          momentUnit = 'day';
        }
      } else {
        momentUnit = 'day';
      }
      // start = momentTimezone
      //   .tz(moment(startDate).format('YYYY-MM-DD'), timezone)
      //   .startOf('day');
      // endDate = moment().isBefore(moment(endDate))
      //   ? moment().toString()
      //   : endDate;
      // end = momentTimezone
      //   .tz(moment(endDate).format('YYYY-MM-DD'), timezone)
      //   .endOf('day');
      break;
  }

  if (data.length > 0 && data[0] && data[0][dataKey]) {
    const startDataPointTick = momentTimezone.tz(
      data[0][dataKey],
      dateTimeFormat,
      timezone
    );
    const endDataPointTick = momentTimezone.tz(
      data[data.length - 1][dataKey],
      dateTimeFormat,
      timezone
    );

    ticks.push(startDataPointTick.valueOf());
    const tick = startDataPointTick.add(1, momentUnit).startOf(momentUnit);

    while (tick.isBefore(endDataPointTick)) {
      ticks.push(tick.valueOf());
      tick.add(1, momentUnit);
    }

    if (includeEndTick || tick.isSame(endDataPointTick)) {
      ticks.push(endDataPointTick.valueOf());
    }
  }
  return ticks;
};

/**
 * This function groups the data points from the Zabbix bandwidth API into a more appropriate
 * collection for charting. The API returns data points at 15 minute intervals, but displaying
 * all of those on a chart can be unwieldy and un-user-friendly.
 *
 * @method groupDataPoints
 * @param {Array} data The full set of API data points
 * @param {String} startDate The current selected filter start date
 * @param {String} endDate The current selected filter end date
 * @returns {Object} An array of consolidated data points based on defined rules and labels for display
 */
// export const groupDataPoints = (data = [], startDate, endDate) => {
//   // const start = moment(startDate);
//   // const end = moment(endDate);
//   // const dateDiff = end.diff(start);
//   // const dataPoints = applyGrouping(data);
//   // const labels = filterLabels(
//   //   dataPoints.map(x => x.label),
//   //   dateDiff
//   // );

//   // return [labels, dataPoints];
//   return [createXAxisTicks(startDate, endDate), data];
// };

/**
 * Helper function for TableExport component for exporting PDF and CSV
 *
 * @method createTrendHtmlExportDefinition
 * @param {Object} property The selected property
 * @param {String} userName The username of the logged in user
 * @returns {Object} The HTML definition
 */
export const createTrendHtmlExportDefinition = (property, userName) => {
  const fileName = `${property.id}_${moment().format('MM-DD-YYYY_HHmm')}.pdf`;

  return {
    title: 'Property Data Trend',
    userName,
    selector: '.include-in-pdf',
    options: {
      filename: fileName,
      canvas: {
        styles: [
          {
            blockClass: 'block-container',
            styles: [{ attribute: 'border', value: 0 }]
          }
        ]
      }
    }
  };
};

/**
 * Helper function for TableExport component for exporting PDF and CSV
 *
 * @method createTrendExportDataDefinition
 * @param {String} host The selected host
 * @param {String} metric The selected metric
 * @param {Array} trend The trend data for the selected host and metric
 * @returns {Array} The Data definition
 */
export const createTrendExportDataDefinition = (host, metric, trend) => {
  const exportDataDefinitions = [];
  const columns = [];
  let name = 'Property Data Trend';
  let data = cloneDeep(trend);

  if (
    host !== '' &&
    metric !== '' &&
    !isEmpty(data) &&
    !has(data[0], 'errorCode')
  ) {
    name = 'Property Data Trend :: ' + host + ' :: ' + metric;

    data = data.map(d => {
      let ret = {};
      Object.entries(d).forEach(([key, value]) => {
        ret[key.replaceAll(':', '')] = value;
      });
      return ret;
    });

    const dataKeys = keys(data[0]).filter(y => y !== 'date');
    const chartType = trendChartMap.find(x =>
      new RegExp(x.metricRegex, 'i').test(metric)
    );
    const labels = get(chartType, 'labels', []);

    if (!isEmpty(labels)) {
      dataKeys.forEach(k => {
        const nl = labels.find(l => new RegExp(l.labelRegex, 'i').test(k));
        columns.push({
          label:
            k === 'time' ? 'Date/Time' : get(nl, 'displayLabel', upperFirst(k)),
          name: k
        });
      });
    } else {
      dataKeys.forEach(k =>
        columns.push({
          label: k === 'time' ? 'Date/Time' : upperFirst(k),
          name: k
        })
      );
    }

    exportDataDefinitions.push({ name, columns, data });
  }

  return exportDataDefinitions;
};

/**
 * This method transforms the data received from the API response into
 * chart readable datapoints for populating the trend chart
 *
 * @method populateTrendChart
 * @param {Object} props Gather all the raw data from the component props
 * @returns {Array} An array of chart data points
 */
export const populateTrendChart = props => {
  const {
    dateRangeFilter,
    trendChartData: { DataTrend: trend = [], DataTrendInfo: info = {} },
    hostMetricFilter: { host, metric }
  } = props;
  const isReadyAndHasData =
    host !== '' &&
    metric !== '' &&
    !isEmpty(trend) &&
    !has(trend[0], 'errorCode');

  let yAxisLabel = '';
  let dataToRender = [];
  let xAxisTicks = [];
  let tooltipUnits = [''];
  let tooltipKeys = [];
  let reversed = false;

  if (isReadyAndHasData) {
    const dataKeys = keys(trend[0]).filter(y => y !== 'date' && y !== 'time');
    const chartType = trendChartMap.find(x =>
      new RegExp(x.metricRegex, 'i').test(metric)
    );
    yAxisLabel = metric;
    tooltipKeys = cloneDeep(dataKeys);

    if (!isEmpty(chartType)) {
      const { metricLabel, units, labels = [] } = chartType;
      yAxisLabel = metricLabel;
      tooltipUnits = units;
      if (!isEmpty(labels)) {
        dataKeys.forEach((k, i) => {
          const nl = labels.find(l => new RegExp(l.labelRegex, 'i').test(k));
          tooltipKeys[i] = get(nl, 'displayLabel', k);
          tooltipUnits = get(nl, 'units', units);
        });
      }
    }

    const data = trend.map(x => {
      const dataPoint = { label: x['time'] };
      dataKeys.forEach((y, i) => (dataPoint[tooltipKeys[i]] = x[y]));
      return dataPoint;
    });
    dataToRender = formatDataPoints(data, info.timezone);
    xAxisTicks = createXAxisTicks(data, dateRangeFilter, info.timezone);
    reversed = every(data, d => every(tooltipKeys, t => d[t] < 0));
  }

  return [
    yAxisLabel,
    xAxisTicks,
    dataToRender,
    tooltipUnits,
    tooltipKeys,
    reversed
  ];
};

export const SubChartsTitle = styled.div.attrs({
  className: 'd-flex justify-content-center my-2 py-2'
})`
  font-size: ${styleLibrary.fontSizes.sectionTitle}px;
  border: solid ${styleLibrary.containerBorderColor};
  border-width: 1px 0;
`;

const sortTopUsers = (data, col, order) => {
  return data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];
    if (col === 5) {
      valA = size(a.data[col]);
      valB = size(b.data[col]);
    }
    return getSortResult(valA, valB, order);
  });
};

export const top20UsersTableOptions = merge({}, MuiTableOptions, {
  customSort: sortTopUsers
});

const bandwidthHeader = (column, sortFn) => {
  const isSorted = column.sortDirection !== 'none';
  const dir = isSorted ? column.sortDirection : 'desc';

  return (
    <CustomTableHeader
      key={column.name}
      columnIndex={column.index}
      isSorted={isSorted}
      sortDirection={dir}
      sortFn={sortFn}
    >
      <span className="mr-4">{column.label}</span>
      <HeaderColorBlock color={styleLibrary.downloadColor} />
      <HeaderBlockLabel>Down</HeaderBlockLabel>
      <HeaderColorBlock color={styleLibrary.uploadColor} />
      <HeaderBlockLabel>Up</HeaderBlockLabel>
    </CustomTableHeader>
  );
};

const bandwidthColumn = (
  downloadValue,
  uploadValue,
  maxBandwidth,
  unit = 0,
  downloadValueInKB,
  uploadValueInKB
) => {
  const customScaler = val =>
    getCustomScaledValue(val, ['KB', ...bandwidthUnits], 'KB', 1024, unit)
      .result;

  return (
    <TwoPartBar
      barWidth={250}
      left={{
        value:
          downloadValue > 0 ? downloadValue : customScaler(downloadValueInKB),
        color: styleLibrary.downloadColor,
        indicator: (
          <FaIcon
            icon="angle-double-down"
            style={{ color: styleLibrary.downloadColor }}
          />
        )
      }}
      max={maxBandwidth}
      right={{
        value: uploadValue > 0 ? uploadValue : customScaler(uploadValueInKB),
        color: styleLibrary.uploadColor,
        indicator: (
          <FaIcon
            icon="angle-double-up"
            style={{ color: styleLibrary.uploadColor }}
          />
        )
      }}
      suffix={unit}
      totalFormatter={val => getScaledDisplayValue(val, bandwidthUnits, unit)}
    />
  );
};

const numberOfClientsColumn = (value, maxClients) => (
  <OnePartBar
    barWidth={60}
    display={{ value, color: styleLibrary.clientsColor }}
    max={maxClients}
  />
);

export const getTop20UsersTableColumns = maxUserValues => {
  const { bandwidth: maxBandwidth, clients: maxClients } = maxUserValues;

  return [
    { name: 'userName', label: 'User' },
    { name: 'zoneName', label: 'Location', options: { display: false } },
    {
      name: 'bandwidthSum',
      label: 'Total Traffic',
      options: {
        customHeadRender: bandwidthHeader,
        customBodyRender: (value, rowMeta) =>
          bandwidthColumn(
            rowMeta.rowData[rowMeta.columnIndex + 1],
            rowMeta.rowData[rowMeta.columnIndex + 2],
            maxBandwidth
          ),
        sortDirection: 'desc'
      }
    },
    { name: 'bandwidthRXInMB', options: { display: false } },
    { name: 'bandwidthTXInMB', options: { display: false } },
    {
      name: 'ClientDetails',
      label: '# Clients',
      options: {
        customBodyRender: value => numberOfClientsColumn(value, maxClients)
      }
    }
  ];
};

export const sortPropertyUsers = (data, col, order, columns) => {
  const bandwidthSumColumnIndex = findIndex(columns, ['name', 'bandwidthSum']);
  return data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];
    if (col === bandwidthSumColumnIndex) {
      const bandwidthRXInKBColumnIndex = findIndex(columns, [
        'name',
        'bandwidthRXInKB'
      ]);
      const bandwidthTXInKBColumnIndex = findIndex(columns, [
        'name',
        'bandwidthTXInKB'
      ]);
      valA =
        a.data[bandwidthRXInKBColumnIndex] + a.data[bandwidthTXInKBColumnIndex];
      valB =
        b.data[bandwidthRXInKBColumnIndex] + b.data[bandwidthTXInKBColumnIndex];
    }
    return getSortResult(valA, valB, order);
  });
};

export const getPropertyUsersTableColumns = (info, maxValues) => {
  const unit = get(info, 'unit', 'MB');
  const { bandwidth: maxBandwidth, clients: maxClients } = maxValues;

  return [
    { name: 'name', label: 'Name' },
    { name: 'user', label: 'User' },
    { name: 'address', label: 'Address' },
    { name: 'unit', label: 'UnitNo' },
    { name: 'realmid', label: 'Realm ID', options: { display: false } },
    { name: 'addedDateTime', label: 'Added On' },
    { name: 'vlanid', label: 'VLAN ID' },
    {
      name: 'bandwidthSum',
      label: 'Total Traffic',
      options: {
        customHeadRender: bandwidthHeader,
        customBodyRender: (value, rowMeta) =>
          bandwidthColumn(
            rowMeta.rowData[rowMeta.columnIndex + 1],
            rowMeta.rowData[rowMeta.columnIndex + 2],
            maxBandwidth,
            unit,
            rowMeta.rowData[rowMeta.columnIndex + 6],
            rowMeta.rowData[rowMeta.columnIndex + 7]
          ),
        sortDirection: 'desc'
      }
    },
    {
      name: 'bandwidthrx',
      label: `Download (${unit})`,
      options: { display: false }
    },
    {
      name: 'bandwidthtx',
      label: `Upload (${unit})`,
      options: { display: false }
    },
    {
      name: 'bandwidthRXInKB',
      label: 'Download (KB)',
      options: { display: false }
    },
    {
      name: 'bandwidthTXInKB',
      label: 'Upload (KB)',
      options: { display: false }
    },
    {
      name: 'clientCount',
      label: '# Clients',
      options: {
        customBodyRender: value => numberOfClientsColumn(value, maxClients)
      }
    }
  ];
};

export const exportPropertyUsersTableColumns = (data, columns) => {
  const filteredColumns = columns.filter(
    c => startsWith(c.name, 'bandwidth') || get(c, 'options.display', true)
  );

  let transformedData = cloneDeep(data);
  transformedData = transformedData.map(d => {
    d['bandwidthSum'] = getScaledDisplayValue(
      d['bandwidthRXInKB'] + d['bandwidthTXInKB'],
      ['KB', ...bandwidthUnits],
      'KB'
    );
    return d;
  });
  transformedData = JSON.parse(
    JSON.stringify(
      transformedData,
      filteredColumns.map(c => c.name)
    )
  );
  transformedData = transformedData.map(Object.values);

  return [
    {
      name: 'Property User Details',
      data: transformedData,
      columns: filteredColumns
    }
  ];
};

export const sortPropertyClients = (data, col, order, columns) => {
  const bandwidthSumColumnIndex = findIndex(columns, ['name', 'bandwidthSum']);
  return data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];
    if (col === bandwidthSumColumnIndex) {
      const bandwidthRXInKBColumnIndex = findIndex(columns, [
        'name',
        'bandwidthRXInKB'
      ]);
      const bandwidthTXInKBColumnIndex = findIndex(columns, [
        'name',
        'bandwidthTXInKB'
      ]);
      valA =
        a.data[bandwidthRXInKBColumnIndex] + a.data[bandwidthTXInKBColumnIndex];
      valB =
        b.data[bandwidthRXInKBColumnIndex] + b.data[bandwidthTXInKBColumnIndex];
    }
    return getSortResult(valA, valB, order);
  });
};

export const getPropertyClientsTableColumns = (
  info,
  nodeVertical,
  maxValues
) => {
  const unit = get(info, 'unit', 'MB');
  const timezone = momentTimezone.tz(get(info, 'timezone', 'UTC')).format('z');
  const isVerticalMDU = get(nodeVertical, 'name', '') === 'MDU';
  const { bandwidth: maxBandwidth } = maxValues;

  const columns = [
    { name: 'realmid', label: 'Realm ID', options: { display: false } },
    {
      name: 'bandwidthSum',
      label: 'Total Traffic',
      options: {
        customHeadRender: bandwidthHeader,
        customBodyRender: (value, rowMeta) =>
          bandwidthColumn(
            rowMeta.rowData[rowMeta.columnIndex + 1],
            rowMeta.rowData[rowMeta.columnIndex + 2],
            maxBandwidth,
            unit,
            rowMeta.rowData[rowMeta.columnIndex + 3],
            rowMeta.rowData[rowMeta.columnIndex + 4]
          ),
        sortDirection: 'desc'
      }
    },
    {
      name: 'bandwidthrx',
      label: `Download (${unit})`,
      options: { display: false }
    },
    {
      name: 'bandwidthtx',
      label: `Upload (${unit})`,
      options: { display: false }
    },
    {
      name: 'bandwidthRXInKB',
      label: 'Download (KB)',
      options: { display: false }
    },
    {
      name: 'bandwidthTXInKB',
      label: 'Upload (KB)',
      options: { display: false }
    },
    { name: 'switch', label: 'Switch Name' },
    { name: 'port', label: 'Switch Port' }
  ];

  const mduColumns = [
    {
      name: 'validFrom',
      label: `Valid From (${timezone})`,
      options: { display: false }
    },
    {
      name: 'validTo',
      label: `Valid Until (${timezone})`,
      options: { display: false }
    },
    { name: 'firstAccess', label: `First Access (${timezone})` },
    { name: 'lastAccess', label: `Last Access (${timezone})` }
  ];

  const clientMacColumn = [{ name: 'client', label: 'Device' }];

  const userNameColumn = [{ name: 'name', label: 'Username' }];

  const manufacturerNameColumn = [
    { name: 'manufacturer', label: 'Manufacturer' }
  ];

  const ssidColumn = [{ name: 'ssid', label: 'SSID' }];

  return isVerticalMDU
    ? concat(
      [],
      clientMacColumn,
      userNameColumn,
      ssidColumn,
      columns,
      mduColumns,
      manufacturerNameColumn
    )
    : concat([], clientMacColumn, ssidColumn, columns, manufacturerNameColumn);
};

export const exportPropertyClientsTableColumns = (data, columns) => {
  const filteredColumns = columns.filter(
    c => startsWith(c.name, 'bandwidth') || get(c, 'options.display', true)
  );

  let transformedData = cloneDeep(data);
  transformedData = transformedData.map(d => {
    d['bandwidthSum'] = getScaledDisplayValue(
      d['bandwidthRXInKB'] + d['bandwidthTXInKB'],
      ['KB', ...bandwidthUnits],
      'KB'
    );
    d['switch'] = isEmpty(d['switch']) ? '' : `'${d['switch']}'`;
    d['port'] = isEmpty(d['port']) ? '' : `'${d['port']}'`;
    return d;
  });
  transformedData = JSON.parse(
    JSON.stringify(
      transformedData,
      filteredColumns.map(c => c.name)
    )
  );
  transformedData = transformedData.map(Object.values);

  return [
    {
      name: 'Property Client Details',
      data: transformedData,
      columns: filteredColumns
    }
  ];
};

export const customMuiTableTheme = merge({}, MuiTableTheme, {
  overrides: {
    MuiTableCell: { head: { borderWidth: '0 0 1px 0' } },
    MUIDataTableBodyRow: {
      root: { '&:nth-child(odd)': { backgroundColor: '#ffffff' } }
    }
  }
});

export const sortPropertyUserDataUsageByClientTable = (
  data,
  col,
  order,
  columns
) => {
  const bandwidthSumColumnIndex = findIndex(columns, ['name', 'bandwidthSum']);
  return data.sort((a, b) => {
    let valA = a.data[col];
    let valB = b.data[col];
    if (col === bandwidthSumColumnIndex) {
      const bandwidthRXInKBColumnIndex = findIndex(columns, [
        'name',
        'bandwidthrx'
      ]);
      const bandwidthTXInKBColumnIndex = findIndex(columns, [
        'name',
        'bandwidthtx'
      ]);
      valA =
        a.data[bandwidthRXInKBColumnIndex] + a.data[bandwidthTXInKBColumnIndex];
      valB =
        b.data[bandwidthRXInKBColumnIndex] + b.data[bandwidthTXInKBColumnIndex];
    }
    return getSortResult(valA, valB, order);
  });
};

export const getPropertyUserDataUsageByClientTableColumns = (
  info,
  maxValues
) => {
  const unit = get(info, 'unit', 'MB');
  const { bandwidth: maxBandwidth } = maxValues;

  return [
    { name: 'clientmac', label: 'Clients' },
    { name: 'manufacturer', label: 'Manufacturer' },
    { name: 'ssid', label: 'SSID' },
    {
      name: 'bandwidthSum',
      label: 'Total Traffic',
      options: {
        customHeadRender: bandwidthHeader,
        customBodyRender: (value, rowMeta) =>
          bandwidthColumn(
            rowMeta.rowData[rowMeta.columnIndex + 1],
            rowMeta.rowData[rowMeta.columnIndex + 2],
            maxBandwidth,
            unit,
            rowMeta.rowData[rowMeta.columnIndex + 3],
            rowMeta.rowData[rowMeta.columnIndex + 4]
          ),
        sortDirection: 'desc'
      }
    },
    {
      name: 'bandwidthRXInMB',
      label: `Download (${unit})`,
      options: { display: false }
    },
    {
      name: 'bandwidthTXInMB',
      label: `Upload (${unit})`,
      options: { display: false }
    },
    {
      name: 'bandwidthrx',
      label: 'Download (KB)',
      options: { display: false }
    },
    {
      name: 'bandwidthtx',
      label: 'Upload (KB)',
      options: { display: false }
    }
  ];
};

const CommaSeparatedValuesBodyRenderer = values => {
  if (isEmpty(values) || !isString(values) || !includes(values, ',')) {
    return values;
  }
  return map(split(values, ','), (v, i) => (
    <Fragment key={`${v}-${i}`}>
      {i === 0 ? null : <br />}
      <span>{v}</span>
    </Fragment>
  ));
};

export const getRogueDetailsTableColumns = () => [
  { name: 'roguemac', label: 'Rogue MAC' },
  { name: 'manufacturer', label: 'Manufacturer' },
  { name: 'ssid', label: 'SSID' },
  { name: 'avg_snr', label: 'Avg SNR' },
  { name: 'max_snr', label: 'Max SNR' },
  {
    name: 'rogueapmac',
    label: 'Detecting AP MAC',
    options: { customBodyRender: CommaSeparatedValuesBodyRenderer }
  },
  { name: 'apcount', label: 'AP Count' },
  { name: 'roguemaccount', label: 'Rogue MAC Count' }
];

export const createRogueDetailsExportDataDefinition = data => {
  const exportDataDefinitions = [];
  const columns = getRogueDetailsTableColumns().filter(c =>
    get(c, 'options.display', true)
  );

  let transformedData = cloneDeep(data);
  transformedData = JSON.parse(
    JSON.stringify(
      transformedData,
      columns.map(c => c.name)
    )
  );
  transformedData = transformedData.map(Object.values);

  exportDataDefinitions.push({
    name: 'Rogue Summary',
    data: transformedData,
    columns
  });

  return exportDataDefinitions;
};

export const getRogueTimelineAPTableColumns = [
  { name: 'apname', label: 'AP Name', options: { sortDirection: 'asc' } },
  { name: 'aplocation', label: 'AP Location' },
  { name: 'switch', label: 'Switch Name' },
  { name: 'port', label: 'Switch Port' }
];

export const getRogueTimelineDetailsTableColumns = info => {
  const timezone = get(info, 'timezone', undefined);
  return [
    {
      name: 'timestamp',
      label: `Date/Time${timezone ? ' (' + timezone + ')' : ''}`,
      options: { sortDirection: 'desc' }
    },
    { name: 'radio', label: 'Radio' },
    { name: 'snr', label: 'SNR' },
    { name: 'channel', label: 'Channel' },
    { name: 'apname', label: 'AP Name', options: { display: false } },
    { name: 'aplocation', label: 'AP Location', options: { display: false } },
    { name: 'switch', label: 'Switch Name', options: { display: false } },
    { name: 'port', label: 'Switch Port', options: { display: false } },
    { name: 'roguepolicyname', label: 'Rogue Policy' }
  ];
};

export const createRogueDetailsForRogueMACExportDataDefinition = (
  data,
  info
) => {
  const exportDataDefinitions = [];
  const columns = getRogueTimelineDetailsTableColumns(info);

  let transformedData = cloneDeep(data);
  transformedData.forEach(d => {
    d['port'] = isEmpty(d['port']) ? '' : `'${d['port']}'`;
  });
  transformedData = JSON.parse(
    JSON.stringify(
      transformedData,
      columns.map(c => c.name)
    )
  );
  transformedData = transformedData.map(Object.values);

  exportDataDefinitions.push({
    name: 'Rogue Summary',
    data: transformedData,
    columns
  });

  return exportDataDefinitions;
};

export const getPropertyMqttClientsTableColumns = (info, maxValues) => {
  const unit = get(info, 'unit', 'MB');
  const { bandwidth: maxBandwidth } = maxValues;

  return [
    { name: 'realmid', label: 'Realm ID', options: { display: false } },
    { name: 'client', label: 'Device' },
    {
      name: 'bandwidthSum',
      label: 'Total Traffic',
      options: {
        customHeadRender: bandwidthHeader,
        customBodyRender: (value, rowMeta) =>
          bandwidthColumn(
            rowMeta.rowData[rowMeta.columnIndex + 1],
            rowMeta.rowData[rowMeta.columnIndex + 2],
            maxBandwidth,
            unit,
            rowMeta.rowData[rowMeta.columnIndex + 3],
            rowMeta.rowData[rowMeta.columnIndex + 4]
          ),
        sortDirection: 'desc'
      }
    },
    {
      name: 'bandwidthrx',
      label: `Download (${unit})`,
      options: { display: false }
    },
    {
      name: 'bandwidthtx',
      label: `Upload (${unit})`,
      options: { display: false }
    },
    {
      name: 'bandwidthRXInKB',
      label: 'Download (KB)',
      options: { display: false }
    },
    {
      name: 'bandwidthTXInKB',
      label: 'Upload (KB)',
      options: { display: false }
    },
    { name: 'switch', label: 'Switch Name' },
    { name: 'port', label: 'Switch Port' }
  ];
};

export const exportPropertyMqttClientsDetailsColumns = (data, columns) => {
  const filteredColumns = columns.filter(
    c => startsWith(c.name, 'bandwidth') || get(c, 'options.display', true)
  );

  let transformedData = cloneDeep(data);
  transformedData = transformedData.map(d => {
    d['bandwidthSum'] = getScaledDisplayValue(
      d['bandwidthRXInKB'] + d['bandwidthTXInKB'],
      ['KB', ...bandwidthUnits],
      'KB'
    );
    d['port'] = isEmpty(d['port']) ? '' : `'${d['port']}'`;
    return d;
  });
  transformedData = JSON.parse(
    JSON.stringify(
      transformedData,
      filteredColumns.map(c => c.name)
    )
  );
  transformedData = transformedData.map(Object.values);

  return [
    {
      name: 'Property MQTT Client Details',
      data: transformedData,
      columns: filteredColumns
    }
  ];
};

export const getRogueApmacSummaryTableColumns = () => [
  { name: 'apname', label: 'AP Name' },
  { name: 'apmac', label: 'AP MAC' },
  { name: 'aplocation', label: 'AP Location' },
  { name: 'roguecount', label: 'Rogue Count' },
  { name: 'avg_snr', label: 'Avg SNR' },
  { name: 'max_snr', label: 'Max SNR' },
  { name: 'switch', label: 'Switch Name' },
  { name: 'port', label: 'Switch Port' },
  {
    name: 'roguemaccount',
    label: 'Rogue MAC Count',
    options: { display: false }
  },
  { name: 'zonename', label: 'Rogue MAC Count', options: { display: false } }
];

export const createRogueApmacSummaryExportDataDefinition = data => {
  const exportDataDefinitions = [];
  const columns = getRogueApmacSummaryTableColumns().filter(c =>
    get(c, 'options.display', true)
  );

  let transformedData = cloneDeep(data);
  transformedData = JSON.parse(
    JSON.stringify(
      transformedData,
      columns.map(c => c.name)
    )
  );
  transformedData = transformedData.map(row => {
    row.port = `'${row.port}'`;
    return Object.values(row);
  });

  exportDataDefinitions.push({
    name: 'Rogue Apmac Summary',
    data: transformedData,
    columns
  });

  return exportDataDefinitions;
};
