/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';

export const BlockContainer = styled.div.attrs(props => ({
  className: classNames('block-container', props.classes),
  style: props.style
}))`
  background-color: ${props =>
    props.bgColour ? props.bgColour : styleLibrary.containerBg};
  height: 100%;
  min-height: ${props => props.minHeight || '160px'};
  font-size: ${styleLibrary.fontSizes.body}px;

  & .btn {
    box-shadow: none !important;
  }
`;

BlockContainer.propTypes = {
  bgColour: PropTypes.string,
  classes: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.object
};
