/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FormDropDown } from 'app/components/forms';
import { HierarchyFilterTypes } from 'app/redux/hierarchy/constants';

class FilterByType extends Component {
  handleTypeChanged = (field, value) => {
    this.props.handleChange(value);
  };

  render() {
    const { disabled, hasSubCustomers = false, value } = this.props;
    const displayFilters = [...HierarchyFilterTypes];

    if (!hasSubCustomers) {
      displayFilters.splice(2, 1);
    }

    return (
      <div className="form-group">
        <FormDropDown
          dataset={displayFilters.map((x, i) => {
            const padding = new Array(i).fill('&nbsp;').join('');
            return {
              ...x,
              text: `${padding}${i > 0 ? '> ' : ''}${x.text}`
            };
          })}
          disabled={disabled}
          fieldClasses={['mt-3']}
          fieldId="hierarchyFilterBy"
          handleValueChanged={this.handleTypeChanged}
          small
          value={value}
        />
      </div>
    );
  }
}

FilterByType.propTypes = {
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  hasSubCustomers: PropTypes.bool,
  value: PropTypes.string
};

export default FilterByType;
