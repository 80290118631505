/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { call, put, select } from 'redux-saga/effects';

import { appCacheSelector, updateAppCache } from 'app/redux/app';
import {
  getFromCache,
  setToCache,
  getValidCacheEntryData,
  createCacheKey
} from './app-cache-helpers';

const oneMinute = 1000 * 60;
const defaultCacheTime = 2 * oneMinute;

/**
 * @method getFromAppCacheSaga
 * @param {Object<String, any>} appCacheRequest An object representing a unique data request
 * @returns {* | null} a valid cache entry or null
 */

export function* getFromAppCacheSaga(appCacheRequest) {
  const cache = yield select(appCacheSelector);
  const cacheEntry = getFromCache(cache, appCacheRequest);
  const cacheValue = getValidCacheEntryData(cacheEntry);

  if (!cacheValue) {
    yield call(deleteEntryFromCache, appCacheRequest);
  }

  return cacheValue;
}

/**
 * @method setAppCacheSaga
 * @param {Object<String, any>} appCacheRequest An object representing a unique data request
 * @param {*} data A piece of data to be stored in the cache
 * @param {Number} [validForMilliseconds] A period in milliseconds the cache entry should be valid for
 * @returns {undefined}
 */
export function* setToAppCacheSaga(
  appCacheRequest,
  data,
  validForMilliseconds = defaultCacheTime
) {
  const cache = yield select(appCacheSelector);
  const updatedCache = setToCache(
    cache,
    appCacheRequest,
    data,
    validForMilliseconds
  );

  yield put(updateAppCache(updatedCache));
  return;
}

function* deleteEntryFromCache(appCacheRequest) {
  const cache = yield select(appCacheSelector);
  const cacheKey = createCacheKey(appCacheRequest);

  if (cacheKey in cache) {
    const updatedCache = { ...cache };
    delete updatedCache[cacheKey];
    yield put(updateAppCache(updatedCache));
  }
  return;
}
