/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';
import { FaIcon } from 'app/components/icons';

import {
  getHierarchy,
  hierarchyChangedByTypeahead,
  hierarchyNodeSelected,
  hierarchySelector,
  rootNodeSelector,
  selectedPathSelector
} from 'app/redux/hierarchy';

import Breadcrumbs from './components/hierarchy/breadcrumbs';
import HierarchyTree from './components/hierarchy';
import HierarchySearch from './components/search';

const SidebarDiv = styled.div.attrs({
  id: 'sidebar',
  className: 'px-3'
})`
  background: ${styleLibrary.gradientBg};
  color: ${styleLibrary.lightText};
  width: 230px;
`;

const ResetButton = styled.button.attrs({
  className: 'btn btn-primary btn-sm btn-block'
})``;
ResetButton.displayName = 'ResetButton';

export class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.hierarchy = React.createRef();
    this.search = React.createRef();
  }

  componentDidMount() {
    if (isEmpty(this.props.hierarchy)) {
      this.props.getHierarchy();
    }
  }

  handleTypeahead = selected => {
    const { hierarchyChangedByTypeahead, hierarchyNodeSelected } = this.props;

    hierarchyNodeSelected(selected.id);
    hierarchyChangedByTypeahead();
  };

  handleReset = () => {
    const { hierarchy, hierarchyNodeSelected } = this.props;
    hierarchyNodeSelected(hierarchy.rootNodeId);

    if (this.hierarchy.current) {
      this.hierarchy.current.resetTree();
    }

    if (this.search.current) {
      this.search.current.resetSearch();
    }
  };

  render() {
    const { hierarchy = {}, hierarchyNodeSelected } = this.props;
    const { error = false, customers = [] } = hierarchy;

    // This is slightly rudimentary error handling for a failed API call... need to make this something more robust
    if (error) {
      return (
        <SidebarDiv>
          <p data-test-label="hierarchy-error">Unable to retrieve customer hierarchy.</p>
        </SidebarDiv>
      );
    }

    if (customers.length > 1) {
      return (
        <SidebarDiv>
          <p data-test-label="multiple-customers">Hierarchy misconfiguration: multiple customers defined.</p>
        </SidebarDiv>
      );
    }

    return (
      <SidebarDiv>
        <HierarchySearch
          onTypeahead={this.handleTypeahead}
          ref={this.search}
          hierarchy={hierarchy}
        />
        <div className="form-group">
          <ResetButton onClick={this.handleReset}>Reset</ResetButton>
        </div>
        {Object.keys(hierarchy).length > 0 ? (
          <Fragment>
            <Breadcrumbs
              data-test-label="hierarchy-breadcrumbs"
              {...this.props}
              onClick={hierarchyNodeSelected}
            />
            <HierarchyTree
              ref={this.hierarchy}
              {...this.props}
              onClick={hierarchyNodeSelected}
            />
          </Fragment>
        ) : (
          <FaIcon
            icon="circle-notch"
            spin
            style={{
              fontSize: styleLibrary.fontSizes.inlineIcon,
              marginTop: 8,
              marginLeft: 8
            }}
          />
        )}
      </SidebarDiv>
    );
  }
}

Sidebar.propTypes = {
  getHierarchy: PropTypes.func.isRequired,
  hierarchy: PropTypes.object.isRequired,
  hierarchyChangedByTypeahead: PropTypes.func.isRequired,
  hierarchyNodeSelected: PropTypes.func.isRequired,
  rootNode: PropTypes.object,
  selected: PropTypes.object
};

const mapStateToProps = createSelector(
  hierarchySelector,
  rootNodeSelector,
  selectedPathSelector,
  (hierarchy, rootNode, selected) => ({
    hierarchy,
    rootNode,
    selected
  })
);

const mapDispatchToProps = {
  getHierarchy,
  hierarchyChangedByTypeahead,
  hierarchyNodeSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
