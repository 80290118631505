/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';

import { getErrorMeta } from 'app/custom-errors';

export function createTypes(types = [], ns = 'anon') {
  return types.reduce(
    (namespacedTypes, action) => ({
      ...namespacedTypes,
      [action]: `${ns}.${action}`
    }),
    {}
  );
}

export const createAsyncTypes = action => [
  `${action}`,
  `${action}Complete`,
  `${action}Error`
];

export const createErrorAction = (actionCreator, error) => ({
  ...actionCreator(error),
  error
});

export const isErrorAction = action => 'error' in action;

export const isAuthError = error => {
  if ('status' in error) {
    return error.status === 401;
  }

  if ('response' in error && error.response && 'status' in error.response) {
    return error.response.status === 401;
  }

  const errorMeta = getErrorMeta(error);
  if (errorMeta) {
    return isAuthError(errorMeta);
  }

  return false;
};

export const is401ErrorAction = action =>
  isErrorAction(action) && isAuthError(action.error);

const baseFetchState = {
  pending: false,
  complete: false,
  failed: false,
  timeout: false
};

export const pendingFetchState = {
  ...baseFetchState,
  pending: true
};
export const completeFetchState = {
  ...baseFetchState,
  complete: true
};
export const failedFetchState = {
  ...baseFetchState,
  failed: true
};

export const timeoutFetchState = {
  ...failedFetchState,
  timeout: true
};

export const timeoutNotifyFetchState = {
  ...baseFetchState,
  timeout: true
};

export const fetchStatePropTypes = PropTypes.objectOf(PropTypes.bool);

export const createFetchStatePayloadCreator = fetchStateKey => fetchState => ({
  fetchStateKey,
  fetchState
});

export function updateFetchStateReducer(
  state,
  { payload: { fetchState, fetchStateKey } }
) {
  return {
    ...state,
    fetchStates: {
      ...state.fetchStates,
      [fetchStateKey]: fetchState
    }
  };
}
