/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { types as appTypes } from 'app/redux/app';
import {
  createAsyncTypes,
  createFetchStatePayloadCreator,
  createTypes,
  pendingFetchState,
  updateFetchStateReducer
} from 'app/redux/utils';
import { createAction, handleActions } from 'redux-actions';
import createFetchRogueMACCountSagas from './rogue-mac-count-saga';
import createFetchRogueSummarySagas from './rogue-summary-saga';

export const nameSpace = 'rogue';

export const types = createTypes(
  [
    ...createAsyncTypes('fetchRogueMACCount'),
    // ...createAsyncTypes('fetchRogueMACPerAPMAC'),
    // ...createAsyncTypes('fetchRogueMACByType'),
    ...createAsyncTypes('fetchRogueSummary'),
    ...createAsyncTypes('downloadRogueSummary'),
    ...createAsyncTypes('fetchRogueDetails'),
    ...createAsyncTypes('fetchRogueDetailsForRogueMAC'),
    ...createAsyncTypes('fetchRogueTrend'),
    'updateRogueMACCountFetchState',
    // 'updateRogueMACPerAPMACFetchState',
    // 'updateRogueMACByTypeFetchState',
    'updateRogueSummaryFetchState',
    'updateRogueSummaryJSON',
    'downloadingRogueSummary',
    'updateRogueDetailsFetchState',
    'updateRogueDetailsForRogueMACFetchState',
    'updateRogueTrendFetchState'
  ],
  nameSpace
);

export const sagas = [
  ...createFetchRogueMACCountSagas(types),
  // ...createFetchRogueMACPerAPMACSagas(types),
  // ...createFetchRogueMACByTypeSagas(types),
  ...createFetchRogueSummarySagas(types)
];

export const fetchRogueMACCount = createAction(types.fetchRogueMACCount);
export const fetchRogueMACCountComplete = createAction(
  types.fetchRogueMACCountComplete
);
export const fetchRogueMACCountError = createAction(
  types.fetchRogueMACCountError
);
export const updateRogueMACCountFetchState = createAction(
  types.updateRogueMACCountFetchState,
  createFetchStatePayloadCreator('rogueMACCount')
);

// export const fetchRogueMACPerAPMAC = createAction(types.fetchRogueMACPerAPMAC);
// export const fetchRogueMACPerAPMACComplete = createAction(
//   types.fetchRogueMACPerAPMACComplete
// );
// export const fetchRogueMACPerAPMACError = createAction(
//   types.fetchRogueMACPerAPMACError
// );
// export const updateRogueMACPerAPMACFetchState = createAction(
//   types.updateRogueMACPerAPMACFetchState,
//   createFetchStatePayloadCreator('rogueMACPerAPMAC')
// );

// export const fetchRogueMACByType = createAction(types.fetchRogueMACByType);
// export const fetchRogueMACByTypeComplete = createAction(
//   types.fetchRogueMACByTypeComplete
// );
// export const fetchRogueMACByTypeError = createAction(
//   types.fetchRogueMACByTypeError
// );
// export const updateRogueMACByTypeFetchState = createAction(
//   types.updateRogueMACByTypeFetchState,
//   createFetchStatePayloadCreator('rogueMACByType')
// );

export const fetchRogueSummary = createAction(types.fetchRogueSummary);
export const fetchRogueSummaryComplete = createAction(
  types.fetchRogueSummaryComplete
);
export const fetchRogueSummaryError = createAction(
  types.fetchRogueSummaryError
);
export const updateRogueSummaryFetchState = createAction(
  types.updateRogueSummaryFetchState,
  createFetchStatePayloadCreator('rogueSummary')
);
export const updateRogueSummaryJSON = createAction(
  types.updateRogueSummaryJSON
);

export const downloadRogueSummary = createAction(types.downloadRogueSummary);
export const downloadRogueSummaryComplete = createAction(
  types.downloadRogueSummaryComplete
);
export const downloadRogueSummaryError = createAction(
  types.downloadRogueSummaryError
);
export const downloadingRogueSummary = createAction(
  types.downloadingRogueSummary
);

export const fetchRogueDetails = createAction(types.fetchRogueDetails);
export const fetchRogueDetailsComplete = createAction(
  types.fetchRogueDetailsComplete
);
export const fetchRogueDetailsError = createAction(
  types.fetchRogueDetailsError
);
export const updateRogueDetailsFetchState = createAction(
  types.updateRogueDetailsFetchState,
  createFetchStatePayloadCreator('rogueDetails')
);

export const fetchRogueDetailsForRogueMAC = createAction(
  types.fetchRogueDetailsForRogueMAC
);
export const fetchRogueDetailsForRogueMACComplete = createAction(
  types.fetchRogueDetailsForRogueMACComplete
);
export const fetchRogueDetailsForRogueMACError = createAction(
  types.fetchRogueDetailsForRogueMACError
);
export const updateRogueDetailsForRogueMACFetchState = createAction(
  types.updateRogueDetailsForRogueMACFetchState,
  createFetchStatePayloadCreator('rogueDetailsForRogueMAC')
);

export const fetchRogueTrend = createAction(types.fetchRogueTrend);
export const fetchRogueTrendComplete = createAction(
  types.fetchRogueTrendComplete
);
export const fetchRogueTrendError = createAction(types.fetchRogueTrendError);
export const updateRogueTrendFetchState = createAction(
  types.updateRogueTrendFetchState,
  createFetchStatePayloadCreator('rogueTrend')
);

const fetchStatesSelector = state => state[nameSpace].fetchStates;
export const rogueMACCountFetchStateSelector = state =>
  fetchStatesSelector(state).rogueMACCount;
// export const rogueMACPerAPMACFetchStateSelector = state =>
//   fetchStatesSelector(state).rogueMACPerAPMAC;
// export const rogueMACByTypeFetchStateSelector = state =>
//   fetchStatesSelector(state).rogueMACByType;
export const rogueSummaryFetchStateSelector = state =>
  fetchStatesSelector(state).rogueSummary;
export const downloadingRogueSummarySelector = state =>
  fetchStatesSelector(state).downloadingRogueSummary;
export const rogueDetailsFetchStateSelector = state =>
  fetchStatesSelector(state).rogueDetails;
export const rogueDetailsForRogueMACFetchStateSelector = state =>
  fetchStatesSelector(state).rogueDetailsForRogueMAC;
export const rogueTrendFetchStateSelector = state =>
  fetchStatesSelector(state).rogueTrend;

export const rogueMACCountSelector = state => {
  const { rogueMACCount: data = [], rogueMACCountInfo: info = {} } = state[
    nameSpace
  ];
  return { data, info };
};
export const rogueTrendSelector = state => {
  const { rogueTrend: data = [], rogueTrendInfo: info = {} } = state[nameSpace];
  return { data, info };
};
// export const rogueMACPerAPMACSelector = state => {
//   const {
//     rogueMACPerAPMAC: data = [],
//     rogueMACPerAPMACInfo: info = {}
//   } = state[nameSpace];
//   return { data, info };
// };
// export const apListSelector = state => {
//   const { rogueMACPerAPMAC: data = [] } = state[nameSpace];
//   let apList = [];
//   if (isArray(data) && !isEmpty(data)) {
//     apList = sortBy(
//       data.map(d => ({
//         id: d.apmac,
//         label: d.apname + ' :: ' + d.apmac
//       })),
//       ['label']
//     );
//   }
//   return apList;
// };
// export const rogueMACByTypeSelector = state => {
//   const { rogueMACByType: data = [], rogueMACByTypeInfo: info = {} } = state[
//     nameSpace
//   ];
//   return { data, info };
// };
// export const rogueTypesSelector = state => {
//   const { rogueMACByType: data = [] } = state[nameSpace];
//   let rogueTypes = [];
//   if (isArray(data) && !isEmpty(data)) {
//     rogueTypes = data.map(d => d.roguetype);
//   }
//   return rogueTypes;
// };
export const rogueSummarySelector = state => state[nameSpace].rogueSummary;
export const rogueSummaryJSONSelector = state =>
  state[nameSpace].rogueSummaryJSON;

export const rogueDetailsSelector = state => {
  const { rogueSummary: data = [], info = {} } = state[nameSpace].rogueDetails;
  return { data, info };
};

export const rogueApmacSummarySelector = state => {
  const { apmacSummary: data = [], apinfo = {} } = state[nameSpace].rogueDetails;
  return { data, apinfo };
};

export const rogueDetailsForRogueMACSelector = state => {
  const { rogueDetailSummary: data = [], info = {} } = state[
    nameSpace
  ].rogueDetailsForRogueMAC;
  return { data, info };
};

export const initialState = {
  fetchStates: {
    rogueMACCount: pendingFetchState,
    // rogueMACPerAPMAC: pendingFetchState,
    // rogueMACByType: pendingFetchState,
    rogueSummary: pendingFetchState,
    rogueDetails: pendingFetchState,
    rogueDetailsForRogueMAC: pendingFetchState,
    downloadingRogueSummary: { inProgress: false, failed: false },
    rogueTrend: pendingFetchState
  },

  rogueMACCount: [],
  rogueMACCountInfo: {},

  rogueTrend: [],
  rogueTrendInfo: {},

  // rogueMACPerAPMAC: [],
  // rogueMACPerAPMACInfo: {},

  // rogueMACByType: [],
  // rogueMACByTypeInfo: {},

  rogueSummary: {},
  rogueSummaryJSON: [],

  rogueDetails: {},
  rogueDetailsForRogueMAC: {}
};

export const reducer = handleActions(
  {
    [types.fetchRogueMACCountComplete]: (state, { payload }) => ({
      ...state,
      rogueMACCount: payload.rogueMACCount,
      rogueMACCountInfo: payload.info
    }),

    [types.fetchRogueTrendComplete]: (state, { payload }) => ({
      ...state,
      rogueTrend: payload.rogueMACCount,
      rogueTrendInfo: payload.info
    }),

    // [types.fetchRogueMACPerAPMACComplete]: (state, { payload }) => ({
    //   ...state,
    //   rogueMACPerAPMAC: payload.rogueMACPerAPMAC,
    //   rogueMACPerAPMACInfo: payload.info
    // }),

    // [types.fetchRogueMACByTypeComplete]: (state, { payload }) => ({
    //   ...state,
    //   rogueMACByType: payload.rogueMACByType,
    //   rogueMACByTypeInfo: payload.info
    // }),

    [types.fetchRogueSummaryComplete]: (state, { payload }) => ({
      ...state,
      rogueSummary: payload
    }),

    [types.updateRogueSummaryJSON]: (state, { payload }) => ({
      ...state,
      rogueSummaryJSON: payload
    }),

    [types.fetchRogueDetailsComplete]: (state, { payload }) => ({
      ...state,
      rogueDetails: payload
    }),

    [types.fetchRogueDetailsForRogueMACComplete]: (state, { payload }) => ({
      ...state,
      rogueDetailsForRogueMAC: payload
    }),

    [types.updateRogueMACCountFetchState]: updateFetchStateReducer,
    // [types.updateRogueMACPerAPMACFetchState]: updateFetchStateReducer,
    // [types.updateRogueMACByTypeFetchState]: updateFetchStateReducer,
    [types.updateRogueSummaryFetchState]: updateFetchStateReducer,
    [types.updateRogueDetailsFetchState]: updateFetchStateReducer,
    [types.updateRogueDetailsForRogueMACFetchState]: updateFetchStateReducer,
    [types.updateRogueTrendFetchState]: updateFetchStateReducer,

    [types.downloadingRogueSummary]: (state, { payload }) => ({
      ...state,
      fetchStates: {
        ...state.fetchStates,
        downloadingRogueSummary: {
          ...state.fetchStates.downloadingRogueSummary,
          ...payload
        }
      }
    }),

    [appTypes.appReset]: () => initialState
  },
  initialState
);
