import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TimeRangeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromTime: '12:00 AM',
      toTime: '12:00 AM'
    };
  }

  handleTimeChange = (field, value) => {
    this.setState({ [field]: value });
    this.props.onChange(field, value);
    this.setState({ [field]: value }, () => {
      const { fromTime, toTime } = this.state;
      const { dateRange } = this.props;
      const timeRange = {
        timeUnitFrom: dateRange.fromTime + 'T' + fromTime,
        timeUnitTo: dateRange.toTime + 'T' + toTime
      };
      this.props.onChange(timeRange);
    });
  };
  

  render() {
    const hours = Array.from({ length: 12 }, (_, i) => (i === 0 ? 12 : i));
    const minutes = Array.from({ length: 60 }, (_, i) =>
      i.toString().padStart(2, '0')
    );
    const ampm = ['AM', 'PM'];

    const timeOptions = [];
    hours.forEach(hour => {
      minutes.forEach(minute => {
        ampm.forEach(period => {
          timeOptions.push(`${hour}:${minute} ${period}`);
        });
      });
    });

    return (
      <>
        <div className="form-group align-items-center row">
          <label className="col-sm-3 col-form-label text-right">Time Range</label>
          <div className="col-sm-4">
            <select
              className="form-control"
              value={this.state.fromTime}
              onChange={e => this.handleTimeChange('fromTime', e.target.value)}
            >
              {timeOptions.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-group align-items-center row">
          <label className="col-sm-3 col-form-label text-right">To</label>
          <div className="col-sm-4">
            <select
              className="form-control"
              value={this.state.toTime}
              onChange={e => this.handleTimeChange('toTime', e.target.value)}
            >
              {timeOptions.map((time, index) => (
                <option key={index} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
        </div>
      </>
    );
  }
}

TimeRangeInput.propTypes = {
  dateRange: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TimeRangeInput;
