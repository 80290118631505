/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { FaIcon, MaterialIcon } from 'app/components/icons';
import { styleLibrary } from 'app/constants';

import { MetricColumn, MetricIcon, ValueContainer } from '../components';

export class ValueBlock extends Component {
  onBlockClick = () => {
    const { metric, onClick } = this.props;
    onClick(metric);
  };

  render() {
    const {
      className,
      color,
      displayFn,
      icon,
      iconType = 'fontawesome',
      selected,
      source,
      title,
      valueFn
    } = this.props;
    const iconFontSize = 30;

    let sourceValue = valueFn(source);
    const displayValue = displayFn(sourceValue);

    return (
      <MetricColumn
        className={className}
        onClick={this.onBlockClick}
        selected={selected}
        style={{ color }}
      >
        <MetricIcon>
          {iconType === 'fontawesome' ? (
            <FaIcon icon={icon} style={{ fontSize: iconFontSize }} />
          ) : (
            <MaterialIcon icon={icon} style={{ fontSize: iconFontSize }} />
          )}
        </MetricIcon>
        <div className="display-label">{title}</div>
        <div className="display-value">
          <ValueContainer>{displayValue.result}</ValueContainer>
          <span style={{ fontSize: styleLibrary.fontSizes.smallBody }}>
            {displayValue.unit}
          </span>
        </div>
      </MetricColumn>
    );
  }
}

ValueBlock.defaultProps = {
  displayFn: x => ({ display: x, result: x, unit: '' })
};

ValueBlock.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
  displayFn: PropTypes.func,
  icon: PropTypes.string.isRequired,
  iconType: PropTypes.oneOf(['fontawesome', 'material']),
  metric: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  source: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  valueFn: PropTypes.func.isRequired
};
