/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isEmpty } from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';

import { ajaxGet } from '../../util-sagas';
import { setConfig } from '../';

const getConfigUrl = '/api/config/getConfig';

export function* getConfigSaga(action, headerAuth) {
  try {
    const { key, storeAs } = action;
    const response = yield call(
      ajaxGet,
      `${getConfigUrl}?key=${key}`,
      headerAuth
    );

    if (!isEmpty(response)) {
      yield put(setConfig({ key: storeAs, config: response.data }));
    }
  } catch (e) {
    // noop - we're not overly concerned about a failed `getConfig` call
    // no further action necessary at this point - the requested config
    // will just be empty
  }
}

export default function createConfigSagas(types) {
  return [takeLatest(types.getConfig, getConfigSaga)];
}
