/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { noop } from 'lodash';
import {
  call,
  cancel,
  cancelled,
  delay,
  fork,
  getContext,
  put,
  race,
  select
} from 'redux-saga/effects';

import { AjaxTimeoutError, AjaxRequestError } from 'app/custom-errors';
import {
  updateUserToken,
  userSignOut,
  isSignedInSelector
} from 'app/redux/app';
import { oneSecondMS, oneMinuteMS } from 'app/utils';

import { getFromAppCacheSaga, setToAppCacheSaga } from './app-cache-saga';
import { isAuthError } from '../utils';

export const defaultTimeoutNotify = 15 * oneSecondMS;
export const defaultTimeout = 10 * oneMinuteMS;

function* timeoutSaga(timeout) {
  yield delay(timeout);
  return true;
}

function* timeoutNotificationSaga(timeout, notifyFn) {
  yield delay(timeout);
  yield notifyFn();
}

const checkForOptionExists = (name, src) => {
  let ret = false;
  if (src.length > 0) {
    ret = src.some(s => {
      if (s[name] === true) {
        return true;
      } else {
        return false;
      }
    });
  }
  return ret;
};

export function* ajaxSaga(method, url, ...sagaOptions) {
  // debounce the application calling due to empty data structures
  // waiting for the next event loop tick is usually enough
  // same as: setTimeout(() => {}, 0)
  yield delay(0);

  const requestParams = { method, url, options: sagaOptions };
  const skipCache = checkForOptionExists('skipCache', sagaOptions);

  if (!skipCache) {
    const cachedValue = yield call(getFromAppCacheSaga, requestParams);
    if (cachedValue) {
      return cachedValue;
    }
  }

  const window = yield getContext('window');
  const ajax = yield getContext('ajax');
  let abortController = null;
  let signal = null;
  let notifySaga;

  try {
    if ('fetch' in window && 'AbortController' in window) {
      abortController = new AbortController();
      signal = abortController.signal;
    }
    const {
      config: { timeout, onTimeout, timeoutNotify },
      options: ajaxOptions
    } = createRequestOptions(method, sagaOptions, signal);

    notifySaga = yield fork(timeoutNotificationSaga, timeoutNotify, onTimeout);

    const [response, requestTimeout] = yield race([
      call(ajax[method], url, ...ajaxOptions),
      timeoutSaga(timeout)
    ]);

    if (requestTimeout) {
      const timeoutInSeconds = timeout / 1000;
      throw new AjaxTimeoutError(
        `The ${method.toUpperCase()} request to ${url} timed out after ${timeoutInSeconds} seconds`,
        {
          url,
          method,
          options: sagaOptions,
          timeoutValue: defaultTimeout,
          timestamp: Date.now()
        }
      );
    }

    if (!response) {
      throw new AjaxRequestError(
        `No response was received for ${method.toUpperCase()} ${url}`
      );
    }

    // If the response has an "authorization" header, the user's token has been
    // refreshed so we need to update that and use the new one from now on
    if (response.headers && response.headers.has('authorization')) {
      const isSignedIn = yield select(isSignedInSelector);

      // If the user has signed out before the request completes, don't re-add the user token
      if (isSignedIn) {
        yield put(updateUserToken(response.headers.get('authorization')));
      }
    }

    yield call(setToAppCacheSaga, requestParams, response);

    return response;
  } catch (err) {
    if (isAuthError(err)) {
      yield put(userSignOut());
    }
    throw err;
  } finally {
    const isCancelled = yield cancelled();
    if (notifySaga) {
      yield cancel(notifySaga);
    }
    if (abortController && isCancelled) {
      abortController.abort();
    }
  }
}

function createRequestOptions(method, requestOptions, abortSignal) {
  if (method === 'get') {
    const [reqOptions = {}] = requestOptions;
    const { config, options } = extractConfigFromOptions(reqOptions);
    return {
      config,
      options: [{ ...options, signal: abortSignal }]
    };
  }
  if (method === 'post') {
    const [body, reqOptions = {}] = requestOptions;
    const { config, options } = extractConfigFromOptions(reqOptions);
    return {
      config,
      options: [body, { ...options, signal: abortSignal }]
    };
  }
  return extractConfigFromOptions(requestOptions);
}

export function extractConfigFromOptions(requestOptions = {}) {
  const {
    timeout = defaultTimeout,
    timeoutNotify = defaultTimeoutNotify,
    onTimeout = noop,
    ...options
  } = requestOptions;
  const config = { timeout, onTimeout, timeoutNotify };

  return { config, options };
}
