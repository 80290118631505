/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { createShowPanelSelector } from 'app/redux/app';
import { filterParamsSelector } from 'app/redux/filters';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import { ajaxGet } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { getPropertyParent } from 'app/utils';
import { concat, get, isEmpty, merge } from 'lodash';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchApUsageDetailsComplete,
  fetchApUsageDetailsError,
  updateApUsageDetailsFetchState
} from './';

export function* fetchApUsageDetailsSaga({ payload }) {
  const hasDataRatePerAP = yield select(
    createShowPanelSelector('PropertyAPDataRateTrend')
  );
  const hasUsagePerAP = yield select(
    createShowPanelSelector('PropertyAPUsageTrend')
  );
  const apUsageUrls = concat(
    [],
    hasDataRatePerAP ? ['/api/fastapi/getAPThroughPutTrend'] : [],
    hasUsagePerAP ? ['/api/fastapi/getAPBandwidthUsageTrend'] : []
  );
  if (isEmpty(apUsageUrls)) {
    yield put(updateApUsageDetailsFetchState(completeFetchState));
    return;
  }

  yield put(updateApUsageDetailsFetchState(pendingFetchState));

  const filters = yield select(filterParamsSelector);
  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);
  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');
  const params = yield merge(
    {},
    filters,
    payload,
    { zone: get(payload, 'zone', selectedPropertyName) },
    { apMAC: get(payload, 'apMAC', '') }
  );
  const paramNames = [
    'apMAC',
    'zone',
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'wlanName'
  ];

  try {
    const onTimeout = () =>
      put(updateApUsageDetailsFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const requests = yield apUsageUrls.map(url =>
      ajaxGet(`${url}?${queryStringParams}`, { onTimeout })
    );

    const responses = yield all(requests);

    const retrievedData = yield responses.reduce((allData, { data }) => {
      const { info, ...rest } = data;
      let infoObject = {};
      if (info) {
        const infoKey = `${Object.keys(rest)[0]}Info`;
        infoObject = { [infoKey]: info };
      }
      return { ...allData, ...rest, ...infoObject };
    }, {});

    yield put(fetchApUsageDetailsComplete(retrievedData));
    yield put(updateApUsageDetailsFetchState(completeFetchState));
  } catch (err) {
    yield put(createErrorAction(fetchApUsageDetailsError, err));
    yield put(updateApUsageDetailsFetchState(failedFetchState));
  }
}

export default function createFetchApStatsSagas(types) {
  return [takeLatest(types.fetchApUsageDetails, fetchApUsageDetailsSaga)];
}
