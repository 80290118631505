/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class FaIcon extends Component {
  render() {
    const {
      classes,
      mode,
      icon,
      style = {},
      spin,
      onClick,
      ...restProps
    } = this.props;

    return (
      <i
        className={classNames(mode, `fa-${icon}`, { 'fa-spin': spin }, classes)}
        onClick={onClick}
        style={{
          ...style,
          cursor: typeof onClick === 'function' ? 'pointer' : style.cursor || ''
        }}
        {...restProps}
      />
    );
  }
}

FaIcon.defaultProps = {
  mode: 'fa'
};

FaIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.string.isRequired,
  mode: PropTypes.string,
  onClick: PropTypes.func,
  spin: PropTypes.bool,
  style: PropTypes.object
};

export default FaIcon;
