/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import {
  TooltipHeader,
  TooltipItem,
  TooltipWrapper
} from 'app/components/charts';
import { bandwidthUnits, styleLibrary } from 'app/constants';
import { getReadableTimeFormat } from 'app/utils';

const FixedTooltipWrapper = styled(TooltipWrapper)`
  width: 380px;
`;

const getPayloadData = (payload, dataKey) =>
  payload.filter(p => p.dataKey === dataKey)[0];

class TrafficSummaryTooltip extends Component {
  shouldComponentUpdate(prevProps) {
    // Adding this since this component seems to update 10s of times per mouse move
    return prevProps.label !== this.props.label;
  }

  render() {
    const { label, payload } = this.props;

    return payload && payload.length > 0 ? (
      <Fragment>
        <FixedTooltipWrapper>
          <TooltipHeader>@ {getReadableTimeFormat(label)}</TooltipHeader>
          <div className="row">
            <TooltipItem
              item={getPayloadData(payload, 'total')}
              scale
              units={bandwidthUnits}
            />
            <TooltipItem item={getPayloadData(payload, 'sessions') || getPayloadData(payload, 'cdr')} />
          </div>
          <div className="row">
            <TooltipItem
              item={getPayloadData(payload, 'download')}
              textColorOverride={styleLibrary.darkText}
              scale
              units={bandwidthUnits}
            />
            <TooltipItem item={getPayloadData(payload, 'clients') || getPayloadData(payload, 'mqtt')} />
          </div>
          <div className="row">
            <TooltipItem
              item={getPayloadData(payload, 'upload')}
              scale
              units={bandwidthUnits}
            />
          </div>
        </FixedTooltipWrapper>
      </Fragment>
    ) : (
      <div>&nbsp;</div>
    );
  }
}

TrafficSummaryTooltip.propTypes = {
  label: PropTypes.string,
  payload: PropTypes.array
};

export default TrafficSummaryTooltip;
