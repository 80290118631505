/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import PropTypes from 'prop-types';
import qs from 'query-string';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { prefixNavigationPath } from 'app/utils';
import Login from 'app/modules/login';

export const getRedirect = (location = {}) => {
  const { redirect = prefixNavigationPath('/overview') } = qs.parse(
    location.search || ''
  );
  return decodeURIComponent(redirect);
};

const LoginRoute = ({ isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isLoggedIn ? (
        <Login {...props} />
      ) : (
        <Redirect to={getRedirect(rest.location)} />
      )
    }
  />
);

LoginRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.object
};

export default LoginRoute;
