/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React from 'react';

import { getPrettyTimeFormat, toFormattedDateTime } from 'app/utils';
import { ConnectedIcon, DisconnectedIcon } from 'app/components/icons';
import { CustomTableHeader, OnePartBar } from 'app/components/tables';
import { styleLibrary } from 'app/constants';

import { getSeconds } from '../utils';
import { isEqual, toLower, toString } from 'lodash';

export const StatusBodyRenderer = value =>
  value === 'Connected' ? (
    <ConnectedIcon height={styleLibrary.inlineIconHeight} />
  ) : (
    <DisconnectedIcon height={styleLibrary.inlineIconHeight} />
  );

export const LastRefreshTimeBodyRenderer = value => {
  let momentTime = toFormattedDateTime(value);

  return <time dateTime={momentTime}>{momentTime}</time>;
};

// This is a slightly odd function signature, but it's eslint compliant and works
export const statusHeadRenderer = totals =>
  function statusHeader(column, sortFn) {
    const { sortDirection } = column;
    const isSorted = sortDirection !== 'none';
    const dir = isSorted ? sortDirection : 'asc';

    return (
      <CustomTableHeader
        key={column.name}
        columnIndex={column.index}
        isSorted={isSorted}
        sortDirection={dir}
        sortFn={sortFn}
      >
        <span className="mr-4">{column.label}</span>
        <ConnectedIcon height={styleLibrary.inlineIconHeight} />
        <span className="ml-2 mr-3">{totals.connected}</span>
        <DisconnectedIcon
          height={styleLibrary.inlineIconHeight}
          className="ml-2"
        />
        <span className="ml-2 mr-3">{totals.disconnected}</span>
        <span>
          <i style={{ fontWeight: 'normal' }}>Total:</i>
        </span>
        <span className="ml-2">{totals.totals}</span>
      </CustomTableHeader>
    );
  };

export const createUptimeBodyRenderer = maxUptime =>
  function UptimeBodyRenderer(value, rowMeta) {
    const isConnected = isEqual(
      toLower(toString(rowMeta.rowData[7])),
      'connected'
    );
    if (zeroUptimeRE.test(value)) {
      if (isConnected) {
        return '0m';
      } else {
        return (
          <span style={{ color: styleLibrary.disconnectedColor }}>0m</span>
        );
      }
    }
    const displayValue = getSeconds(value);
    if (displayValue < 0) {
      const formattedValue = getPrettyTimeFormat(Math.abs(displayValue));
      return (
        <span style={{ color: styleLibrary.disconnectedColor }}>
          {formattedValue}
        </span>
      );
    }
    return (
      <OnePartBar
        barWidth={60}
        display={{
          value: displayValue,
          color: styleLibrary.uptimeColor
        }}
        formatter={getPrettyTimeFormat}
        max={maxUptime}
      />
    );
  };

const zeroUptimeRE = /^0?0:00:00$/;
