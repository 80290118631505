/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import moment from 'moment';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { defaultDateFormat } from 'app/constants';

import {
  downloadReport,
  fetchReportsList,
  generateInProgress,
  reportError
} from './';
import { userSelector } from 'app/redux/app';
import { ajaxPost } from 'app/redux/util-sagas';

const deactivateReportUrl = '/api/report/deactivateReports';
const getReportFileUrl = '/api/report/getReportFile';
const generateReportUrl = '/api/report/generateReport';

const defaultReportEndpoints = [
  '/api/device/getInventorySummary',
  '/api/device/getInventoryDetails',
  '/api/device/getInventoryApDetails',
  '/api/device/getPropertyInventory',
  // '/api/network/getServiceProviderDetails',
  '/api/network/getSPUsage',
  '/api/network/getSPBandwidthBusyHour',
  '/api/network/getSPThroughput',
  '/api/network/getSPClientBandwidthUtil',
  '/api/session/getSPSessionDuration',
  '/api/session/getSPClientSessionDetails',
  '/api/subscriber/getSPSubscriberCategoryCount',
  '/api/subscriber/getSPSubscriberTypeCount',
  '/api/device/getSPDeviceTypes',
  '/api/device/getSPPoESwitches',
  '/api/network/getSPUsageByAP',
  '/api/network/getSPClientByAP',
  '/api/network/getSPThroughputByAP',
  '/api/session/getSPSessionDurationByAP',
  '/api/network/getSPBandwidthTrend',
  '/api/network/getSPThroughputTrend',
  '/api/session/getSPSessionsAndClientsTrend',
  '/api/session/getSPSessionDurationTrend',
  '/api/device/getSPPoETrend',
  '/api/network/getSLADetails'
];

export function* reportActionSaga({ payload }) {
  const { action, report } = payload;

  try {
    if (action === 'delete') {
      yield ajaxPost(deactivateReportUrl, [report]);
      yield put(fetchReportsList({ skipCache: true }));
    } else {
      const { reportUniqueID } = report;

      yield put(downloadReport({ id: reportUniqueID, type: action }));

      const saveFileAs = yield getContext('saveFileAs');
      const { data } = yield ajaxPost(getReportFileUrl, report, {
        headers: {
          Accept: action === 'pdf' ? 'application/pdf' : 'text/csv'
        },
        responseType: 'blob'
      });
      const filename = `${JSON.parse(report.hierarchy).type}_Report.${action}`;

      yield call(saveFileAs, data, filename);
      yield put(downloadReport({}));
    }
  } catch (err) {
    yield put(downloadReport({}));
    yield put(reportError({ ...payload }));
  }
}

export function* generateReportSaga({ payload }) {
  const user = yield select(userSelector);
  const { end, selectedPath, start, unit, vertical, wlan } = payload;

  let apiBody = {
    hierarchy: selectedPath.simpleApiBody,
    vertical,
    ssid: wlan,
    timeUnit: unit,
    timeUnitFrom: moment(start).format(defaultDateFormat),
    timeUnitTo: moment(end).format(defaultDateFormat),
    type: 'executive-report',
    user: user.username,
    endpoints: defaultReportEndpoints
  };

  try {
    yield put(generateInProgress(true));
    yield ajaxPost(generateReportUrl, apiBody);
    yield put(fetchReportsList({ skipCache: true }));
  } catch (err) {
    yield put(
      reportError({
        action: 'generate',
        type: 'executive-report',
        report: { ...apiBody },
        error: err
      })
    );
  } finally {
    yield put(generateInProgress(false));
  }
}

export default function createReportActionSagas(types) {
  return [
    takeLatest(types.getReportFile, reportActionSaga),
    takeLatest(types.generateReport, generateReportSaga)
  ];
}
