/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { FaIcon } from 'app/components/icons';
import { styleLibrary } from 'app/constants';
import { castToNumber, formatNumericValue } from 'app/utils';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  CompareTrendsChart,
  DisplayRatio,
  MetricColumn,
  MetricIcon,
  ValueBlock,
  ValueContainer
} from './components';
import {
  averageDurationValueBlock,
  comparisonDataSets,
  valueBlocks
} from './constants';

const BlocksContainer = styled.div.attrs({
  className: 'row no-gutters'
})`
  & > div:nth-child(3) {
    border-right: 1px solid ${styleLibrary.containerBorderColor};
  }
`;

const UsageSummaryChartContainer = styled.div.attrs({
  className: 'px-4 pt-4 d-flex justify-content-center align-items-center'
})`
  background-color: ${styleLibrary.usageSummarySelectedBg};
  height: 100%;
`;

const RatioContainer = styled.div`
  color: ${styleLibrary.darkText};
  font-size: 16px;
`;

const UsageSummaryChart = props => {
  const {
    handleMetricSelection,
    summary = {},
    selectedMetric,
    usageSummaryTrends
  } = props;
  const { clientCount, sessionCount, sessionRatio } = summary;

  const [leftOfSessionRatio, rightOfSessionRatio] = (
    sessionRatio || '0:0'
  ).split(':');

  return (
    <Fragment>
      <BlocksContainer>
        {valueBlocks.map(v => (
          <ValueBlock
            {...v}
            key={`value-block-${v.metric}`}
            onClick={handleMetricSelection}
            selected={selectedMetric === v.metric}
            source={summary}
          />
        ))}
        <MetricColumn
          className="col-6"
          onClick={() => handleMetricSelection('ratio')}
          selected={selectedMetric === 'ratio'}
          style={{ color: styleLibrary.sessionsColor }}
        >
          <div className="row no-gutters">
            <div className="col-8">
              <MetricIcon>
                <FaIcon icon="users" style={{ fontSize: 30 }} />
              </MetricIcon>
              <div className="display-label">
                Sessions :{' '}
                <span style={{ color: styleLibrary.clientsColor }}>
                  Clients
                </span>
              </div>
              <div className="display-value">
                <ValueContainer>
                  <DisplayRatio
                    left={formatNumericValue(castToNumber(sessionCount))}
                    right={formatNumericValue(castToNumber(clientCount))}
                  />
                </ValueContainer>
                <RatioContainer data-test-label="session-client-ratio-container">
                  ({sessionCount % clientCount !== 0 ? '~ ' : ''}
                  <DisplayRatio
                    left={leftOfSessionRatio}
                    right={rightOfSessionRatio}
                  />
                  )
                </RatioContainer>
              </div>
            </div>
            <ValueBlock
              {...averageDurationValueBlock}
              key={'value-block-duration'}
              onClick={noop}
              source={summary}
            />
          </div>
        </MetricColumn>
      </BlocksContainer>
      <UsageSummaryChartContainer>
        {usageSummaryTrends ? (
          <CompareTrendsChart
            data={usageSummaryTrends}
            metric={selectedMetric}
            lineColor={comparisonDataSets[selectedMetric].color}
          />
        ) : (
          <p>
            There is currently no trend chart to be displayed for{' '}
            {selectedMetric}.
          </p>
        )}
      </UsageSummaryChartContainer>
    </Fragment>
  );
};

UsageSummaryChart.propTypes = {
  handleMetricSelection: PropTypes.func.isRequired,
  selectedMetric: PropTypes.string.isRequired,
  summary: PropTypes.object.isRequired,
  usageSummaryTrends: PropTypes.object
};

export default UsageSummaryChart;
