/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { takeEvery, call, getContext } from 'redux-saga/effects';

import { isAuthError, isErrorAction } from 'app/redux/utils';
import {
  isAjaxTimeoutError,
  isAjaxRequestError,
  getErrorMeta
} from 'app/custom-errors';

export function* handleErrorSaga(action) {
  const { error } = action;

  if (isAuthError(error)) {
    return;
  }

  if (isAjaxTimeoutError(error)) {
    yield call(reportWarningSaga, error);
    return;
  }

  if (isAjaxRequestError(error)) {
    // Something went wrong whilst parsing a response
    // and the response status code was < 400
    const meta = getErrorMeta(error);
    yield call(reportErrorSaga, error);
    yield call(reportErrorDebugInfoSaga, error, meta);
    return;
  }

  yield call(reportErrorSaga, error);
}

function* reportWarningSaga(error) {
  const reporter = yield getContext('reporter');
  try {
    yield call(reporter.warn, error);
  } catch (e) {
    // noop
  }
}

function* reportErrorSaga(error) {
  const reporter = yield getContext('reporter');
  try {
    yield call(reporter.error, error);
  } catch (e) {
    // noop
  }
}

function* reportErrorDebugInfoSaga(error, errorMeta = {}) {
  const reporter = yield getContext('reporter');

  const {
    bodyText = '<EMPTY>',
    originalError = { message: '<EMPTY>' }
  } = errorMeta;
  try {
    yield call(
      reporter.info,
      `      ###################
          Start Debug
      ###################

        Body Text: "${bodyText}"
        Original Error Message: "${originalError.message}"
        Error: ${error.message}
      
      ###################
           End Debug
      ###################`
    );
  } catch (e) {
    // noop
  }
}

export default function createErrorSagas() {
  return [takeEvery(isErrorAction, handleErrorSaga)];
}
