/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { userGroupList } from 'app/constants';
import {
  BlockContainer,
  NoDataToShow,
  SubTitle,
  TitleBar
} from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { styleLibrary } from 'app/constants';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import {
  fetchNetworkDetails,
  fetchNetworkSummary,
  networkDetailsFetchStateSelector,
  networkSummaryFetchStateSelector,
  networkSummarySelector,
  usageSummaryTrendsSelector
} from 'app/redux/network';
import { userGroupsSelector, createShowPanelSelector } from 'app/redux/app';
import { createSummary } from 'app/redux/network/summary-transformers';
import { fetchStatePropTypes } from 'app/redux/utils';
import { contrastColour } from 'app/utils';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import UsageSummaryChart from './usage-summary-chart';
import CustomUsageSummary from './custom-usage-summary';

const failedFetchStateOverlayStyles = {
  width: '96%',
  right: '2%',
  height: '82%'
};

const failedFetchStateRetryBtnStyles = {
  backgroundColor: `${contrastColour(styleLibrary.brand.primary)}`,
  color: `${styleLibrary.brand.primary}`
};

const summaryProperties = ['SPUsage', 'SPSessionDuration', 'SPThroughput'];

const isSummaryEmpty = summary =>
  summaryProperties.every(prop => isEmpty(summary[prop]));

const getSessionClientRatio = (session, client) =>
  '' + Math.round(session / client) + ':1';

const noDataStyles = { height: 'calc(100% - 70px)' };

export class UsageSummaryChartTile extends Component {
  constructor() {
    super();

    this.state = { selectedMetric: 'bandwidth' };
  }

  render = () => {
    const {
      dateRangeFilterLabel,
      fetchNetworkSummary,
      fetchNetworkDetails,
      networkSummaryFetchState,
      networkDetailsFetchState,
      summary,
      usageSummaryTrends,
      userGroups = [],
      showUsageSummaryForLAC
    } = this.props;
    const { selectedMetric } = this.state;

    const combinedFetchState = {
      pending:
        networkSummaryFetchState.pending || networkDetailsFetchState.pending,
      complete:
        networkSummaryFetchState.complete && networkDetailsFetchState.complete,
      failed:
        networkSummaryFetchState.failed || networkDetailsFetchState.failed,
      timeout:
        networkSummaryFetchState.timeout || networkDetailsFetchState.timeout
    };
    const hasSummary = !isSummaryEmpty(summary);
    const noDataToShow =
      !combinedFetchState.pending && combinedFetchState.complete && !hasSummary;
    let displaySummary = {};

    const isUserLAC = userGroupList.some(item => userGroups.includes(item));
    const displayCustomUsage = showUsageSummaryForLAC && isUserLAC;

    if (hasSummary) {
      summary['SPUsage'] = get(summary, 'SPTrafficUsage.SPUsage', []);
      summary['SPUsageInfo'] = get(summary, 'SPTrafficUsage.info', {});
      let throughputData = displayCustomUsage
        ? 'SPThroughput'
        : 'SPThroughputUsage.SPThroughput';

      summary['SPThroughput'] = get(summary, throughputData, []);
      summary['SPThroughputInfo'] = get(summary, 'SPThroughputUsage.info', {});
      const client = get(summary, 'SPClientDetails.client', 0);
      displaySummary = createSummary(summary);
      const ratio = client
        ? getSessionClientRatio(displaySummary.sessionCount, client)
        : '1:1';
      displaySummary = {
        ...displaySummary,
        clientCount: client,
        sessionRatio: ratio
      };
    }

    return (
      <BlockContainer classes={['d-flex', 'flex-column']}>
        <TitleBar
          leftChildren={
            <Fragment>
              <span data-test-label="usage-tile-title">Usage Summary</span>
              <SubTitle data-test-label="last-refresh-text">&nbsp;</SubTitle>
            </Fragment>
          }
          padUnderTitle={false}
          rightChildren={
            <span
              data-test-label="usage-tile-date-range"
              style={{ fontSize: styleLibrary.fontSizes.secondaryTitle }}
            >
              {dateRangeFilterLabel}
            </span>
          }
        />
        <FailedFetchStateHandler
          fetchState={combinedFetchState}
          overlayStyle={failedFetchStateOverlayStyles}
          retry={() => {
            fetchNetworkSummary();
            fetchNetworkDetails();
          }}
          retryButtonStyle={failedFetchStateRetryBtnStyles}
        >
          {combinedFetchState.pending ? (
            <LoadingIconPlaceholder />
          ) : noDataToShow ? (
            <NoDataToShow style={noDataStyles} />
          ) : combinedFetchState.complete &&
            hasSummary &&
            displayCustomUsage ? (
              <CustomUsageSummary data={summary} />
            ) : (
              <UsageSummaryChart
                handleMetricSelection={selectedMetric =>
                  this.setState({ selectedMetric })
                }
                selectedMetric={selectedMetric}
                summary={displaySummary}
                usageSummaryTrends={usageSummaryTrends}
              />
            )}
        </FailedFetchStateHandler>
      </BlockContainer>
    );
  };
}

UsageSummaryChartTile.defaultProps = { summary: {} };

UsageSummaryChartTile.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  fetchNetworkSummary: PropTypes.func,
  fetchNetworkDetails: PropTypes.func,
  networkSummaryFetchState: fetchStatePropTypes,
  networkDetailsFetchState: fetchStatePropTypes,
  summary: PropTypes.object,
  usageSummaryTrends: PropTypes.object,
  userGroups: PropTypes.array,
  showUsageSummaryForLAC: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('OverviewUsageSummary'),
  dateRangeFilterLabelSelector,
  networkSummaryFetchStateSelector,
  networkDetailsFetchStateSelector,
  networkSummarySelector,
  usageSummaryTrendsSelector,
  userGroupsSelector,
  (
    showUsageSummaryForLAC,
    dateRangeFilterLabel,
    networkSummaryFetchState,
    networkDetailsFetchState,
    summary,
    usageSummaryTrends,
    userGroups
  ) => ({
    showUsageSummaryForLAC,
    dateRangeFilterLabel,
    networkSummaryFetchState,
    networkDetailsFetchState,
    summary,
    usageSummaryTrends,
    userGroups
  })
);
const mapDispatchToProps = { fetchNetworkSummary, fetchNetworkDetails };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsageSummaryChartTile);
