/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';
import { niCopyrightMessage } from 'app/constants';

const LoginCopyright = props => (
  <div className={classNames(props.className, 'text-center')}>
    {niCopyrightMessage}
  </div>
);

LoginCopyright.propTypes = {
  className: PropTypes.string.isRequired
};

export default styled(LoginCopyright)`
  margin: 10px 0;
  font-size: ${styleLibrary.fontSizes.body}px;

  @media (max-width: 767px) {
    font-size: ${styleLibrary.fontSizes.smallBody}px;
  }
`;
