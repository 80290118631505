/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { NoDataToShow } from 'app/components/elements';
import GranularityFilter from 'app/components/filters/components/granularity-filter';
import SsidWlanFilter from 'app/components/filters/components/ssid-wlan-filter';
import { granularityDropdownItems } from 'app/components/filters/constants';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { FailedFetchStateHandler } from 'app/components/utility';
import { commonPlotProps, styleLibrary } from 'app/constants';
import {
  fetchPropertyUniqueUsersTrend,
  propertyUniqueUsersTrendFetchStateSelector,
  propertyUniqueUsersTrendSelector
} from 'app/redux/clients';
import {
  dateRangeFilterSelector,
  granularityListSelector,
  ssidWLANListSelector
} from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import { formatApiDateTime, getGranularityForDateRange } from 'app/utils';
import { get, isArray, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import Statistics from '../statistics';
import { createXAxisTicks, formatDataPoints } from '../utils';
import { getWlanList } from 'app/components/filters/components/ssid-wlan-filter';

const plotKeyLabelMap = { cdr: 'Unique Users (CDR: Authenticated)' };

const initialState = {
  granularity: granularityDropdownItems._15MinutesGranularity.value
};

export class UniqueUsersTrendChart extends Component {
  constructor(props) {
    super(props);
    const { ssidWLANList } = this.props;
    this.state = { ...initialState, wlan: getWlanList(ssidWLANList) };
  }

  componentDidMount() {
    const { dateRangeFilter, fetchPropertyUniqueUsersTrend } = this.props;
    const { wlan } = this.state;
    const newGranularity = getGranularityForDateRange(dateRangeFilter);

    this.setState({ ...initialState, granularity: newGranularity }, () =>
      fetchPropertyUniqueUsersTrend({
        wlanName: wlan,
        granularity: newGranularity
      })
    );
  }

  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilter,
      fetchPropertyUniqueUsersTrend
    } = this.props;
    const {
      selectedPath: prevPath,
      dateRangeFilter: prevDateRange
    } = prevProps;

    const propertyChanged =
      isSpecificNodeType(selectedPath, 'zonename') &&
      !isEqual(prevPath.id, selectedPath.id);
    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilter);
    const { wlan } = this.state;

    if (propertyChanged || dateRangeChanged) {
      let newGranularity = getGranularityForDateRange(dateRangeFilter);
      this.setState({ ...initialState, granularity: newGranularity }, () =>
        fetchPropertyUniqueUsersTrend({
          wlanName: wlan,
          granularity: newGranularity
        })
      );
    }
  };

  renderUniqueUsersTrendChart = () => {
    const {
      dateRangeFilter,
      propertyUniqueUsersTrend: { data = [], info = {} }
    } = this.props;
    const { granularity } = this.state;

    const timezone = get(info, 'timezone', 'UTC');
    const isTimestampDateAndTime = [
      granularityDropdownItems._15MinutesGranularity.value,
      granularityDropdownItems._1HourGranularity.value
    ].includes(granularity);
    const dataToRender = formatDataPoints(
      data,
      timezone,
      'timestamp',
      isTimestampDateAndTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
    );
    const xAxisTicks = createXAxisTicks(
      data,
      dateRangeFilter,
      timezone,
      'timestamp',
      isTimestampDateAndTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'
    );

    return (
      <ChartFlexContainer width="99%" height={300}>
        <LineChart data={dataToRender} margin={{ ...chartMargins, left: 15 }}>
          <CartesianGrid vertical={false} />
          <Legend verticalAlign="top" content={<TrendChartLegend />} />
          <XAxis
            dataKey="timestamp"
            label={{
              value: `Time (${timezone})`,
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            interval="preserveStartEnd"
            tick={tick}
            ticks={xAxisTicks}
            tickFormatter={t => formatApiDateTime(t, dateRangeFilter, timezone)}
            scale="time"
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            yAxisId="uniqueUsersTrend"
            label={{
              value: 'Count',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            // domain={[0, 'dataMax']}
            // scale="linear"
            allowDecimals={false}
            interval="preserveStartEnd"
          />
          <Line
            yAxisId="uniqueUsersTrend"
            dataKey={Object.keys(plotKeyLabelMap)[0]}
            name={Object.values(plotKeyLabelMap)[0]}
            stroke={styleLibrary.uniqueUsersColor}
            {...commonPlotProps}
          />
          <Tooltip
            content={
              <MultiLineTooltip
                labelFormatter={label =>
                  formatApiDateTime(
                    label,
                    dateRangeFilter,
                    timezone,
                    isTimestampDateAndTime
                  )
                }
                keys={Object.keys(plotKeyLabelMap)}
                units={['']}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
          />
        </LineChart>
      </ChartFlexContainer>
    );
  };

  handleGranularityChanged = selectedGranularity => {
    const { fetchPropertyUniqueUsersTrend } = this.props;
    const { wlan } = this.state;

    this.setState({ granularity: selectedGranularity }, () =>
      fetchPropertyUniqueUsersTrend({
        wlanName: wlan,
        granularity: selectedGranularity
      })
    );
  };

  handleWlanChanged = selectedWlan => {
    const { fetchPropertyUniqueUsersTrend } = this.props;
    const { granularity } = this.state;
    this.setState({ wlan: selectedWlan }, () =>
      fetchPropertyUniqueUsersTrend({ wlanName: selectedWlan, granularity })
    );
  };

  renderDropdowns = () => {
    const { granularity, wlan } = this.state;
    return (
      <div className="d-flex justify-content-between align-items-center mx-2 mt-2 mb-0">
        <GranularityFilter
          granularity={granularity}
          granularityList={this.props.granularityList}
          handleGranularityChanged={this.handleGranularityChanged}
          showLabel={false}
        />
        <SsidWlanFilter
          wlan={wlan}
          handleWlanChanged={this.handleWlanChanged}
          showAllOption={false}
        />
      </div>
    );
  };

  render = () => {
    const {
      fetchState,
      propertyUniqueUsersTrend: { data = [], stats = {} },
      fetchPropertyUniqueUsersTrend
    } = this.props;
    const { granularity, wlan } = this.state;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    const statsArray = [
      {
        title: Object.values(plotKeyLabelMap)[0],
        max: get(stats, 'maxCDRUser', 0),
        min: get(stats, 'minCDRUser', 0),
        avg: get(stats, 'avgCDRUser', 0)
      }
    ];

    return (
      <Fragment>
        {this.renderDropdowns()}
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() =>
            fetchPropertyUniqueUsersTrend({
              wlanName: wlan,
              granularity
            })
          }
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message="No data available"
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            complete &&
            hasData && (
              <Fragment>
                {this.renderUniqueUsersTrendChart()}
                <Statistics
                  data={statsArray}
                  colors={[styleLibrary.uniqueUsersColor]}
                />
              </Fragment>
            )
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

UniqueUsersTrendChart.propTypes = {
  dateRangeFilter: PropTypes.object,
  selectedPath: PropTypes.object,
  fetchPropertyUniqueUsersTrend: PropTypes.func,
  propertyUniqueUsersTrend: PropTypes.object,
  fetchState: fetchStatePropTypes,
  granularityList: PropTypes.array,
  ssidWLANList: PropTypes.array
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  selectedPathSelector,
  propertyUniqueUsersTrendFetchStateSelector,
  propertyUniqueUsersTrendSelector,
  granularityListSelector,
  ssidWLANListSelector,
  (
    dateRangeFilter,
    selectedPath,
    fetchState,
    propertyUniqueUsersTrend,
    granularityList,
    ssidWLANList
  ) => ({
    dateRangeFilter,
    selectedPath,
    fetchState,
    propertyUniqueUsersTrend,
    granularityList,
    ssidWLANList
  })
);

const mapDispatchToProps = { fetchPropertyUniqueUsersTrend };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UniqueUsersTrendChart);
