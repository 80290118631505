/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { styleLibrary } from 'app/constants';

const StyledTitle = styled.div.attrs(props => ({
  id: props.id,
  className: classNames(
    'py-2 px-3',
    props.padUnderTitle && 'mb-3',
    props.classes
  )
}))`
  background: ${props =>
    props.dark ? styleLibrary.darkBg : styleLibrary.brand.primary};
  color: ${props =>
    props.dark ? styleLibrary.lightText : styleLibrary.lightText};
  font-size: ${styleLibrary.fontSizes.sectionTitle}px;
  font-weight: bold;

  & span[data-has-click] {
    line-height: 1;
    cursor: pointer;
  }
`;
StyledTitle.displayName = 'StyledTitle';

const TitleBar = props => {
  const { leftChildren, rightChildren } = props;

  return (
    <StyledTitle {...props}>
      <div className="d-flex justify-content-between">
        <div>{leftChildren}</div>
        <div className="text-right">{rightChildren}</div>
      </div>
    </StyledTitle>
  );
};

TitleBar.defaultProps = {
  padUnderTitle: true
};

TitleBar.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string),
  dark: PropTypes.bool,
  leftChildren: PropTypes.node,
  padUnderTitle: PropTypes.bool,
  rightChildren: PropTypes.node
};

export default TitleBar;
