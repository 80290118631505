/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  ChartFlexContainer,
  MultiLineTooltip,
  TrendChartLegend
} from 'app/components/charts';
import { chartMargins, tick } from 'app/components/charts/constants';
import { NoDataToShow } from 'app/components/elements';
import DateRangeFilter from 'app/components/filters/components/date-range-filter';
import GranularityFilter from 'app/components/filters/components/granularity-filter';
import SsidWlanFilter from 'app/components/filters/components/ssid-wlan-filter';
import {
  defaultDateSelection,
  granularityDropdownItems
} from 'app/components/filters/constants';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { FailedFetchStateHandler } from 'app/components/utility';
import { bandwidthUnits, commonPlotProps, styleLibrary } from 'app/constants';
import {
  fetchPropertyMqttClientBandwidthTrend,
  mqttClientBandwidthTrendFetchStateSelector,
  mqttClientBandwidthTrendSelector
} from 'app/redux/clients';
import {
  dateRangeFilterLabelSelector,
  dateRangeFilterSelector,
  granularityListSelector
} from 'app/redux/filters';
import { fetchStatePropTypes } from 'app/redux/utils';
import {
  createFormatAxisValue,
  formatApiDateTime,
  getGranularityForDateRange,
  getScaledDisplayValue
} from 'app/utils';
import { get, isArray, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { createSelector } from 'reselect';
import { createXAxisTicks, formatDataPoints } from '../utils';

const initialState = {
  isDateOpen: false,
  dateRange: defaultDateSelection,
  wlan: 'all',
  granularity: granularityDropdownItems._15MinutesGranularity.value
};

class MqttClientBandwidthTrend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hiddenPlotMap: {},
      ...initialState,
      dateRange: props.dateRangeFilter || initialState.dateRange
    };
  }

  componentDidMount = () => {
    const { fetchPropertyMqttClientBandwidthTrend, clientMAC } = this.props;
    const { wlan, dateRange } = this.state;
    const newGranularity = getGranularityForDateRange(dateRange);

    this.setState({ granularity: newGranularity }, () =>
      fetchPropertyMqttClientBandwidthTrend({
        clientMAC,
        dateRange,
        granularity: newGranularity,
        wlanName: wlan
      })
    );
  };

  componentDidUpdate = prevProps => {
    const {
      fetchPropertyMqttClientBandwidthTrend,
      clientMAC,
      dateRangeFilter
    } = this.props;
    const { clientMAC: prevClientMAC } = prevProps;
    const { wlan } = this.state;

    const clientChanged = clientMAC !== '' && clientMAC !== prevClientMAC;

    if (clientChanged) {
      const dateRange = dateRangeFilter || initialState.dateRange;
      const newGranularity = getGranularityForDateRange(dateRangeFilter);
      this.setState(
        { ...initialState, dateRange, granularity: newGranularity },
        () =>
          fetchPropertyMqttClientBandwidthTrend({
            clientMAC,
            dateRange,
            granularity: newGranularity,
            wlanName: wlan
          })
      );
    }
  };

  isActivePlot = plotKey => {
    return this.state.hiddenPlotMap[plotKey]
      ? 'blank'
      : plotKey.replace(' ', '').toLowerCase();
  };

  togglePlotVisibility = key => {
    this.setState(({ hiddenPlotMap }) => ({
      hiddenPlotMap: {
        ...hiddenPlotMap,
        [key]: !hiddenPlotMap[key]
      }
    }));
  };

  renderMqttClientBandwidthTrend = () => {
    const {
      propertyMqttClientBandwidthTrend: { data = [], info = {} }
    } = this.props;
    const { dateRange } = this.state;

    const timezone = get(info, 'timezone', 'UTC');
    const dataToRender = formatDataPoints(data, timezone);

    const xAxisTicks = createXAxisTicks(data, dateRange, timezone);
    const formatAxisValue = createFormatAxisValue();

    return (
      <ChartFlexContainer width="99%" height={300}>
        <ComposedChart
          data={dataToRender}
          margin={{ ...chartMargins, left: 15 }}
        >
          <CartesianGrid vertical={false} />
          <Legend
            content={<TrendChartLegend onClick={this.togglePlotVisibility} />}
            inactivePlots={this.state.hiddenPlotMap}
            verticalAlign="top"
          />
          <XAxis
            dataKey="label"
            label={{
              value: `Time (${timezone})`,
              position: 'bottom',
              offset: 5,
              fontWeight: 'bold',
              fontSize: styleLibrary.fontSizes.body
            }}
            interval="preserveStartEnd"
            tick={tick}
            ticks={xAxisTicks}
            tickFormatter={t => formatApiDateTime(t, dateRange, timezone)}
            scale="time"
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            yAxisId="mqttClientBandwidthTrend"
            label={{
              value: 'Traffic',
              angle: -90,
              position: 'insideLeft',
              offset: 10,
              dy: 35,
              fontSize: styleLibrary.fontSizes.body,
              fill: styleLibrary.trafficColor
            }}
            stroke="rgb(31, 119, 180)"
            tick={tick}
            tickFormatter={value =>
              formatAxisValue(
                value,
                (val, dec) =>
                  getScaledDisplayValue(val, bandwidthUnits, null, dec),
                1
              )
            }
          />
          <Area
            dataKey={this.isActivePlot('Download')}
            name="Download"
            stroke={styleLibrary.downloadColor}
            fill={styleLibrary.downloadColor}
            yAxisId="mqttClientBandwidthTrend"
            {...commonPlotProps}
          />
          <Area
            dataKey={this.isActivePlot('Upload')}
            name="Upload"
            stroke={styleLibrary.uploadColor}
            fill={styleLibrary.uploadColor}
            yAxisId="mqttClientBandwidthTrend"
            {...commonPlotProps}
          />
          <Line
            dataKey={this.isActivePlot('Total')}
            name="Total"
            stroke="rgb(31, 119, 180)"
            yAxisId="mqttClientBandwidthTrend"
            {...commonPlotProps}
          />
          <Tooltip
            content={
              <MultiLineTooltip
                labelFormatter={label =>
                  formatApiDateTime(label, dateRange, timezone, true)
                }
                keys={['download', 'upload', 'total']}
                units={bandwidthUnits}
              />
            }
            contentStyle={{ fontSize: styleLibrary.fontSizes.body }}
            offset={0}
            wrapperStyle={{ marginLeft: 80 }}
          />
        </ComposedChart>
      </ChartFlexContainer>
    );
  };

  handleDateChanged = dateRange => {
    const { fetchPropertyMqttClientBandwidthTrend, clientMAC } = this.props;
    const { wlan } = this.state;
    const granularityChange = getGranularityForDateRange(dateRange);

    this.setState(
      { isDateOpen: false, dateRange, granularity: granularityChange },
      () =>
        fetchPropertyMqttClientBandwidthTrend({
          clientMAC,
          dateRange,
          granularity: granularityChange,
          wlanName: wlan
        })
    );
  };

  handleWlanChanged = selectedWlan => {
    const { fetchPropertyMqttClientBandwidthTrend, clientMAC } = this.props;
    const { dateRange, granularity } = this.state;

    this.setState({ wlan: selectedWlan }, () =>
      fetchPropertyMqttClientBandwidthTrend({
        clientMAC,
        dateRange,
        granularity,
        wlanName: selectedWlan
      })
    );
  };

  handleGranularityChanged = selectedGranularity => {
    const { fetchPropertyMqttClientBandwidthTrend, clientMAC } = this.props;
    const { wlan, dateRange } = this.state;

    this.setState({ granularity: selectedGranularity }, () =>
      fetchPropertyMqttClientBandwidthTrend({
        clientMAC,
        dateRange,
        granularity: selectedGranularity,
        wlanName: wlan
      })
    );
  };

  renderDropdowns = () => (
    <div className="d-flex justify-content-between align-items-center mx-2">
      <div className="d-flex align-items-center">
        <GranularityFilter
          granularity={this.state.granularity}
          granularityList={this.props.granularityList}
          handleGranularityChanged={this.handleGranularityChanged}
          showLabel={false}
          customDateRange={this.state.dateRange}
        />
      </div>
      <div className="d-flex align-items-center">
        <div className="mr-2">
          <SsidWlanFilter
            wlan={this.state.wlan}
            handleWlanChanged={this.handleWlanChanged}
          />
        </div>
        <DateRangeFilter
          dateRangeFilter={this.state.dateRange}
          isDropDownOpen={this.state.isDateOpen}
          updateDateRangeFilter={this.handleDateChanged}
          updateIsOpen={isDateOpen => this.setState({ isDateOpen })}
          buttonStyle={{
            backgroundColor: styleLibrary.containerBg,
            border: '1px solid #ced4da'
          }}
        />
      </div>
    </div>
  );

  render = () => {
    const {
      fetchState,
      propertyMqttClientBandwidthTrend: { data = [] },
      fetchPropertyMqttClientBandwidthTrend,
      clientMAC
    } = this.props;
    const { dateRange, wlan, granularity } = this.state;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <Fragment>
        {this.renderDropdowns()}
        <FailedFetchStateHandler
          fetchState={fetchState}
          retry={() =>
            fetchPropertyMqttClientBandwidthTrend({
              clientMAC,
              dateRange,
              granularity,
              wlanName: wlan
            })
          }
        >
          {pending ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              icon="error_outline"
              message="No data available"
              style={{
                background: 'none',
                position: 'relative',
                height: 'auto'
              }}
            />
          ) : (
            complete && hasData && this.renderMqttClientBandwidthTrend()
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

MqttClientBandwidthTrend.propTypes = {
  dateRangeFilter: PropTypes.object,
  dateRangeFilterLabel: PropTypes.string,
  propertyMqttClientBandwidthTrend: PropTypes.object,
  fetchState: fetchStatePropTypes,
  fetchPropertyMqttClientBandwidthTrend: PropTypes.func,
  clientMAC: PropTypes.string,
  granularityList: PropTypes.array
};

const mapStateToProps = createSelector(
  dateRangeFilterSelector,
  dateRangeFilterLabelSelector,
  mqttClientBandwidthTrendFetchStateSelector,
  mqttClientBandwidthTrendSelector,
  granularityListSelector,
  (
    dateRangeFilter,
    dateRangeFilterLabel,
    fetchState,
    propertyMqttClientBandwidthTrend,
    granularityList
  ) => ({
    dateRangeFilter,
    dateRangeFilterLabel,
    fetchState,
    propertyMqttClientBandwidthTrend,
    granularityList
  })
);

const mapDispatchToProps = { fetchPropertyMqttClientBandwidthTrend };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MqttClientBandwidthTrend);
