/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { isEqual } from 'lodash';
import { call, take, cancelled, select, put } from 'redux-saga/effects';

import { appCacheSelector, updateAppCache } from 'app/redux/app';
import { interval } from 'app/redux/util-sagas';
import { isValidCacheEntry } from 'app/redux/util-sagas/app-cache-helpers';

import { thirtySecondsMS } from 'app/utils';

export default function* pruneAppCache(intervalTime = thirtySecondsMS) {
  const intervalChannel = yield call(interval, intervalTime);
  try {
    while (true) {
      yield take(intervalChannel);
      const cache = yield select(appCacheSelector);

      const updatedCache = Object.keys(cache).reduce((newCache, key) => {
        const cacheEntry = cache[key];
        return {
          ...newCache,
          ...(isValidCacheEntry(cacheEntry) ? { [key]: cacheEntry } : {})
        };
      }, {});

      if (isEqual(cache, updatedCache)) {
        continue;
      }
      yield put(updateAppCache(updatedCache));
    }
  } finally {
    if (yield cancelled()) {
      intervalChannel.close();
    }
  }
}
