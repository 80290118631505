/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { HR, NoDataToShow } from 'app/components/elements';
import { LoadingIconPlaceholder } from 'app/components/icons';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { FailedFetchStateHandler } from 'app/components/utility';
import { createShowPanelSelector } from 'app/redux/app';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import {
  accClientsTrendFetchStateSelector,
  accClientsTrendSelectorTransform,
  fetchAccClientsTrend
} from 'app/redux/network';
import { fetchStatePropTypes } from 'app/redux/utils';
import { isArray, isEmpty, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import AleClientsTrendChart from './ale-clients-trend-chart';
import AleUsersTrendChart from './ale-users-trend-chart';

class AleClientsUsersTrend extends Component {
  componentDidUpdate = prevProps => {
    const {
      selectedPath,
      dateRangeFilterLabel,
      fetchAccClientsTrend
    } = this.props;
    const {
      selectedPath: prevSelectedPath,
      dateRangeFilterLabel: prevDateRange
    } = prevProps;

    const propertyChanged =
      isSpecificNodeType(selectedPath, 'zonename') &&
      !isEqual(prevSelectedPath.id, selectedPath.id);
    const dateRangeChanged = !isEqual(prevDateRange, dateRangeFilterLabel);
    if (propertyChanged || dateRangeChanged) {
      fetchAccClientsTrend({ zone: selectedPath.name });
    }
  };

  render() {
    const {
      fetchState,
      aleAccClientsTrend: { data = [], info = {} },
      fetchAccClientsTrend,
      selectedPath,
      showAleUsersTrend,
      showAleClientsTrend
    } = this.props;

    const { pending, complete } = fetchState;
    const hasData = isArray(data) && !isEmpty(data);
    const noDataToShow = !pending && complete && !hasData;

    return (
      <Fragment>
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow ? (
          <NoDataToShow
            icon="error_outline"
            message="No data available"
            style={{ background: 'none', position: 'relative', height: 'auto' }}
          />
        ) : (
          <FailedFetchStateHandler
            fetchState={fetchState}
            retry={() => fetchAccClientsTrend({ zone: selectedPath.name })}
          >
            <div
              className={pending || noDataToShow ? 'fetch-state-pending' : ''}
            />
            {complete && hasData && (
              <Fragment>
                {showAleUsersTrend && (
                  <AleUsersTrendChart data={data} info={info} />
                )}
                {showAleUsersTrend && showAleClientsTrend && <HR />}
                {showAleClientsTrend && (
                  <AleClientsTrendChart data={data} info={info} />
                )}
              </Fragment>
            )}
          </FailedFetchStateHandler>
        )}
      </Fragment>
    );
  }
}

AleClientsUsersTrend.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  fetchAccClientsTrend: PropTypes.func,
  selectedPath: PropTypes.object,
  fetchState: fetchStatePropTypes,
  aleAccClientsTrend: PropTypes.object,
  showAleUsersTrend: PropTypes.bool,
  showAleClientsTrend: PropTypes.bool
};

const mapStateToProps = createSelector(
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  accClientsTrendFetchStateSelector,
  accClientsTrendSelectorTransform,
  createShowPanelSelector('ReportsAleUsersTrend'),
  createShowPanelSelector('ReportsAleClientsTrend'),
  (
    dateRangeFilterLabel,
    selectedPath,
    fetchState,
    aleAccClientsTrend,
    showAleUsersTrend,
    showAleClientsTrend
  ) => ({
    dateRangeFilterLabel,
    selectedPath,
    fetchState,
    aleAccClientsTrend,
    showAleUsersTrend,
    showAleClientsTrend
  })
);

const mapDispatchToProps = { fetchAccClientsTrend };

export default connect(mapStateToProps, mapDispatchToProps)(AleClientsUsersTrend);
