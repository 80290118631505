/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import {
  customDateRangeNames,
  dateRangeNames,
  granularityDropdownItems
} from 'app/components/filters/constants';
import {
  apiDateFormat,
  defaultDateFormat,
  defaultDateTimeFormat
} from 'app/constants';
import { concat } from 'lodash';
import moment from 'moment';
import momentTimezone from 'moment-timezone';

export const oneSecondMS = 1000;
export const thirtySecondsMS = 30 * oneSecondMS;
export const oneMinuteMS = 60 * oneSecondMS;

/**
 * This function formats an API date time value into something meaningful for the end user. We have so many different
 * date formats coming from the different APIs (any many of them non-standard), we're trying to handle all the
 * possibilities here.
 *
 * This function manually caches the timezone appended to a date/time (if it's present) and re-adds it once all the
 * formatting has been done.
 *
 * @method toFormattedDateTime
 * @param {String} apiDate The date as returned by the API
 * @param {Boolean} [dateTime] An option to force on the date part to be returned - this defaults to true
 * @returns {String} A properly formatted date time, with timezone if we can get it
 */
export const toFormattedDateTime = (apiDate, dateTime = true) => {
  if (!apiDate) {
    return '';
  }

  let momentTime = moment(apiDate, apiDateFormat);
  let timezone = '';

  const dateParts = apiDate.split(' ');
  if (dateParts.length > 1) {
    timezone = apiDate.split(' ').slice(-1);
  }

  if (!momentTime.isValid()) {
    momentTime = moment(apiDate);
  }

  let displayDateTime = momentTime.format(defaultDateTimeFormat);

  if (timezone !== '') {
    displayDateTime += ` ${timezone}`;
  }

  return dateTime ? displayDateTime : momentTime.format(defaultDateFormat);
};

/**
 * @method getPrettyTimeFormat
 * @param {Number} timeInSeconds a duration in seconds to format
 * @param {Boolean} includeSeconds denotes whether to include the seconds in generated display
 * @returns {String} A formatted expanding string from 0m to 1w 0d 0h 0m
 */

export const getPrettyTimeFormat = (
  timeInSeconds = 0,
  includeSeconds = false
) => {
  const duration = moment.duration(timeInSeconds, 'seconds');
  const minuteFunc = includeSeconds ? 'floor' : 'ceil';

  let weeks = Math.floor(duration.as('weeks'));
  duration.subtract(weeks, 'w');
  let days = Math.floor(duration.as('days'));
  duration.subtract(days, 'd');
  let hours = Math.floor(duration.as('hours'));
  duration.subtract(hours, 'h');
  let minutes = Math[minuteFunc](duration.as('minutes'));
  duration.subtract(minutes, 'm');
  let seconds = Math.ceil(duration.as('seconds'));

  while (seconds > 59) {
    minutes++;
    seconds -= 60;
  }
  while (minutes > 59) {
    hours++;
    minutes -= 60;
  }
  while (hours > 23) {
    days++;
    hours -= 24;
  }
  while (days > 6) {
    weeks++;
    days -= 7;
  }

  const isZeroIfEmpty = weeks > 0 || days > 0;

  return `${weeks > 0 ? weeks + 'w' : ''} ${isZeroIfEmpty ? days + 'd' : ''} ${
    isZeroIfEmpty || hours > 0 ? hours + 'h' : ''
  } ${minutes}m ${includeSeconds && seconds > 0 ? seconds + 's' : ''}`.trim();
};

/**
 * Formats an API date/time label in to a more readable/friendly date/time string
 *
 * @param {String} dateTimeString The date/time string normally provided from the API
 * @param {Object} options formatting options.
 *
 * @returns {String} A more readable date/time string
 */
export const getReadableTimeFormat = (
  dateTimeString = '',
  options = { timePrefixes: false }
) => {
  const is12HourTime = is12HourTimeStringRE.test(dateTimeString);
  const is24HourTime = is24HourTimeStringRE.test(dateTimeString);
  const isDateAnd24HourTime = isDateAnd24HourTimeStringRE.test(dateTimeString);
  const isDateAnd24HourTimeSecond = isDateAnd24HourTimeSecondStringRE.test(
    dateTimeString
  );
  const isDayOfMonthAnd24HourTime = isDayOfMonthAnd24HourTimeStringRE.test(
    dateTimeString
  );

  if (
    is12HourTime ||
    is24HourTime ||
    isDateAnd24HourTime ||
    isDateAnd24HourTimeSecond ||
    isDayOfMonthAnd24HourTime
  ) {
    let queryHour;
    let queryMinute;
    let queryMeridiem;
    if (is12HourTime) {
      [, queryHour, queryMinute, queryMeridiem] = dateTimeString.match(
        is12HourTimeStringRE
      );
    } else if (is24HourTime) {
      [, queryHour, queryMinute] = dateTimeString.match(is24HourTimeStringRE);
    } else if (isDateAnd24HourTime) {
      [, , , , queryHour, queryMinute] = dateTimeString.match(
        isDateAnd24HourTimeStringRE
      );
    } else if (isDateAnd24HourTimeSecond) {
      [, , , , queryHour, queryMinute] = dateTimeString.match(
        isDateAnd24HourTimeSecondStringRE
      );
    } else if (isDayOfMonthAnd24HourTime) {
      [, , , , queryHour, queryMinute] = dateTimeString.match(
        isDayOfMonthAnd24HourTimeStringRE
      );
    }

    const { timePrefixes } = options;
    const currentTime = moment(new Date());
    const queryTime = queryMeridiem
      ? moment(dateTimeString, 'h:mma')
      : moment()
        .hour(parseInt(queryHour, 10))
        .minute(parseInt(queryMinute, 10));
    const formattedTime = queryTime.format(queryMinute > 0 ? 'h:mma' : 'ha');

    if (!timePrefixes) {
      return formattedTime;
    }

    return queryTime.isAfter(currentTime, 'hour')
      ? `Yesterday, ${formattedTime}`
      : `Today, ${formattedTime}`;
  }

  if (isDayOfMonthRE.test(dateTimeString)) {
    const [, day, month, year] = dateTimeString.match(isDayOfMonthRE);
    return moment(getISODate(day, month, year)).format('ddd Do');
  }

  return dateTimeString;
};

const is12HourTimeStringRE = /^(\d{1,2}):(\d{2})(\w{2})$/;
const is24HourTimeStringRE = /^(\d{2}):(\d{2})$/;
const isDateAnd24HourTimeStringRE = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})$/;
const isDateAnd24HourTimeSecondStringRE = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
const isDayOfMonthRE = /^(\d{2})-(\w{3})-(\d{4})$/;
const isDayOfMonthAnd24HourTimeStringRE = /^(\d{2})-(\w{3})-(\d{4}) (\d{2}):(\d{2})$/;

const monthToISOMap = {
  jan: '01',
  feb: '02',
  mar: '03',
  apr: '04',
  may: '05',
  jun: '06',
  jul: '07',
  aug: '08',
  sep: '09',
  oct: '10',
  nov: '11',
  dec: '12'
};

// We use this conversion to help `moment` with multiple browser environments
// http://www.ecma-international.org/ecma-262/5.1/#sec-15.9.1.15
const getISODate = (day, month, year) =>
  `${year}-${monthToISOMap[month.toLowerCase()]}-${day}`;

/**
 * This function returns the appropriate API time unit given a set of dates.
 *
 * @method getTimeUnit
 * @param {String} label The currently selected date label which will dictate
 *                       the rules for the return value
 * @param {moment.Moment} startDate The start date of our range
 * @param {moment.Moment} endDate The end date of our range
 * @returns {String} The appropriate grouping unit for the specified date range
 */
export const getTimeUnit = (label, startDate, endDate) => {
  const oneDay = 24;
  const fromMoment = moment(startDate);
  const toMoment = moment(endDate);
  const hoursDifference = toMoment.diff(fromMoment, 'hours');
  const daysDifference = toMoment.diff(fromMoment, 'days');
  const endBeforeToday = toMoment.isBefore(moment(), 'days');

  const sameMonth = fromMoment.month() === toMoment.month();
  const fullMonth =
    sameMonth &&
    fromMoment.date() === 1 &&
    toMoment.date() === toMoment.daysInMonth();

  switch (label) {
    case dateRangeNames.LAST_24_HOURS:
      return '24Hours';

    case dateRangeNames.LAST_7_DAYS:
      return 'week';

    case dateRangeNames.THIS_MONTH:
    case dateRangeNames.LAST_MONTH:
      return 'month';

    case customDateRangeNames.SHOW_ALL_OPEN_ITEMS:
      return 'all';

    default:
      if (fullMonth) {
        return 'month';
      } else if (!endBeforeToday && hoursDifference <= oneDay) {
        return '24Hours';
      } else if (hoursDifference <= oneDay) {
        return 'day';
      } else if (daysDifference <= 31) {
        return 'day';
      } else {
        return 'year';
      }
  }
};

/**
 * This function returns the timezone offset which is relative to UTC for a given timezone
 *
 * @method calculateTimeZoneOffset
 * @param {String} timeZone The timezone string which is present in the API response
 * @returns {Number} The timezone offset value relative to UTC
 */
export const calculateTimeZoneOffset = timeZone => {
  const formattedTimeZone = moment.tz(timeZone).format('Z');
  let offset = parseInt(formattedTimeZone, 10);
  if (formattedTimeZone.includes(':30')) {
    if (offset < 0) {
      offset -= 0.5;
    } else {
      offset += 0.5;
    }
  }
  return offset;
};

/**
 * Formats an API date/time label in to a simple hh:mm date/time string
 *
 * @param {String} dateTimeString The date/time string normally provided from the API
 *
 * @returns {String} A more readable date/time string
 */
export const getHourMinuteTimeFormat = (dateTimeString = '') => {
  const is24HourTime = is24HourTimeStringRE.test(dateTimeString);
  const isDateAnd24HourTime = isDateAnd24HourTimeStringRE.test(dateTimeString);
  const isDateAnd24HourTimeSecond = isDateAnd24HourTimeSecondStringRE.test(
    dateTimeString
  );

  if (is24HourTime) {
    return dateTimeString;
  }

  if (isDateAnd24HourTime || isDateAnd24HourTimeSecond) {
    let queryHour;
    let queryMinute;
    if (isDateAnd24HourTime) {
      [, , , , queryHour, queryMinute] = dateTimeString.match(
        isDateAnd24HourTimeStringRE
      );
    } else if (isDateAnd24HourTimeSecond) {
      [, , , , queryHour, queryMinute] = dateTimeString.match(
        isDateAnd24HourTimeSecondStringRE
      );
    }
    return moment()
      .hour(parseInt(queryHour, 10))
      .minute(parseInt(queryMinute, 10))
      .format('HH:mm');
  }

  return dateTimeString;
};

/**
 * This function formats a response from the API according to the currently
 * selected date range. It supports the option to use an extended format for
 * certain ranges when we know the data supports it, but it must be manually
 * triggered.
 *
 * @param {String} time The actual time from the API response/data set
 * @param {String} dateRange One of our supported date range names
 * @param {String} timezone The timezone of the incoming data
 * @param {Boolean} [extended] True if we want to use an extended format, false (default) otherwise
 * @returns {String} A formatted value according to the date range
 */
export const formatApiDateTime = (
  time,
  dateRange,
  timezone = 'UTC',
  extended = false
) => {
  let dateFormat;
  let makeTimeReadable = false;
  const { label, start = undefined, end = undefined } = dateRange;

  switch (label) {
    case dateRangeNames.LAST_24_HOURS:
      // dateFormat = extended ? 'h:mma' : 'ha';
      dateFormat = 'h:mma';
      makeTimeReadable = true;
      break;

    case dateRangeNames.LAST_7_DAYS:
      // dateFormat = extended ? 'ddd, h:mma' : 'ddd';
      dateFormat = extended ? 'ddd, Do h:mma' : 'ddd, Do';
      break;

    case dateRangeNames.LAST_MONTH:
    case dateRangeNames.THIS_MONTH:
      dateFormat = extended ? 'ddd, Do h:mma' : 'ddd, Do';
      break;

    default:
      if (start && end) {
        if (moment(end).diff(moment(start), 'days') === 0) {
          dateFormat = 'ddd, Do h:mma';
        } else {
          dateFormat = extended ? 'ddd, Do h:mma' : 'MM/DD';
        }
      } else {
        dateFormat = extended ? 'ddd, Do h:mma' : 'MM/DD';
      }
      break;
  }

  let formatResult = momentTimezone.tz(time, timezone).format(dateFormat);
  formatResult = makeTimeReadable
    ? getReadableTimeFormat(formatResult)
    : formatResult;
  return formatResult === 'Invalid date' ? '-' : formatResult;
};

/**
 * @method getPrettyDurationFormat
 * @param {String} value a duration to format
 * @returns {String} A formatted minimized duration string
 */
export const getPrettyDurationFormat = value => {
  if (typeof value === 'string') {
    value = value.replaceAll(' years', 'y');
    value = value.replaceAll(' months', 'm');
    value = value.replaceAll(' days', 'd');
    value = value.replaceAll(' hours', 'h');
    value = value.replaceAll(' minutes', 'm');
    value = value.replaceAll(' seconds', 's');
    value = value.replaceAll(' and', '');
    value = value.replaceAll(',', '');
    value = value.trim();
  }
  return value;
};

/**
 * @method isDateRangeMoreThan24Hours
 * @param {Object} dateRange The current user selected dateRange
 * @returns {Boolean} Check if the dateRange is more than 24 hours long or not
 */
export const isDateRangeMoreThan24Hours = dateRange => {
  const { label, start, end } = dateRange;
  switch (label) {
    case dateRangeNames.LAST_24_HOURS:
    case customDateRangeNames.SHOW_ALL_OPEN_ITEMS:
      return false;

    case dateRangeNames.LAST_7_DAYS:
    case dateRangeNames.THIS_MONTH:
    case dateRangeNames.LAST_MONTH:
      return true;

    default: {
      const fromMoment = moment(start);
      const toMoment = moment(end);
      const hoursDifference = toMoment.diff(fromMoment, 'hours');
      const daysDifference = toMoment.diff(fromMoment, 'days');
      if (hoursDifference > 24 || daysDifference >= 1) {
        return true;
      }
      return false;
    }
  }
};

/**
 * @method getGranularityForDateRange
 * @param {Object} dateRange The current user selected dateRange
 * @returns {String} The default granularity for the selected dateRange
 */
export const getGranularityForDateRange = dateRange => {
  if (!dateRange || !dateRange.label) {
    return granularityDropdownItems._15MinutesGranularity.value;
  }
  const { label, start, end } = dateRange;

  switch (label) {
    case dateRangeNames.LAST_24_HOURS:
      return granularityDropdownItems._15MinutesGranularity.value;

    case dateRangeNames.LAST_7_DAYS:
      return granularityDropdownItems._1HourGranularity.value;

    case dateRangeNames.THIS_MONTH: {
      const fromMoment = moment(start);
      const toMoment = moment(end);
      const daysDifference = toMoment.diff(fromMoment, 'days');
      return daysDifference <= 7
        ? granularityDropdownItems._1HourGranularity.value
        : granularityDropdownItems._1DayGranularity.value;
    }

    case dateRangeNames.LAST_MONTH:
      return granularityDropdownItems._1DayGranularity.value;

    default: {
      const fromMoment = moment(start);
      const toMoment = moment(end);
      const daysDifference = toMoment.diff(fromMoment, 'days');

      return daysDifference <= 1
        ? granularityDropdownItems._15MinutesGranularity.value
        : daysDifference <= 7
          ? granularityDropdownItems._1HourGranularity.value
          : granularityDropdownItems._1DayGranularity.value;

      // const isCustomDays = moment(end).diff(moment(start), 'days', true) > 0;
      // let isCustomMonths = moment(end).diff(moment(start), 'months', true) >= 1;

      // if (!isCustomMonths) {
      //   const year = new Date(start).getFullYear();
      //   const month = new Date(start).getMonth() + 1;
      //   const daysInMonth = new Date(year, month, 0).getDate();
      //   if (
      //     daysInMonth ===
      //     Math.ceil(moment(end).diff(moment(start), 'days'), true) + 1
      //   ) {
      //     isCustomMonths = true;
      //   }
      // }

      // if (isCustomMonths) {
      //   return granularityDropdownItems._1DayGranularity.value;
      // } else if (isCustomDays) {
      //   return granularityDropdownItems._1HourGranularity.value;
      // }
      // return granularityDropdownItems._15MinutesGranularity.value;
    }
  }
};

/**
 * @method getGranularityListForDateRange
 * @param {Object} dateRange The current user selected dateRange
 * @returns {Array} The default granularity list dropdown for the selected dateRange
 */
export const getGranularityListForDateRange = dateRange => {
  let granularityList = [
    granularityDropdownItems._15MinutesGranularity,
    granularityDropdownItems._1HourGranularity
  ];

  if (!dateRange) {
    return granularityList;
  }

  const { label, start, end } = dateRange;
  switch (label) {
    case dateRangeNames.LAST_24_HOURS:
      break;

    case dateRangeNames.LAST_7_DAYS:
    case dateRangeNames.THIS_MONTH:
    case dateRangeNames.LAST_MONTH:
      granularityList = concat(granularityList, [
        granularityDropdownItems._1DayGranularity
      ]);
      break;

    default: {
      const fromMoment = moment(start);
      const toMoment = moment(end);
      const daysDifference = toMoment.diff(fromMoment, 'days');
      granularityList = concat(
        granularityList,
        daysDifference >= 1 ? [granularityDropdownItems._1DayGranularity] : []
      );
      break;
    }
  }

  return granularityList;
};
